import { createSlice } from '@reduxjs/toolkit'
import { apiSlice } from './api/apiSlice'

const initialState = {
  data: [],
  status: 'idle',
  error: null
}

export const teamMortgagesSlice = createSlice({
  name: 'teamMortgages',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addMatcher(apiSlice.endpoints.initialLoad.matchFulfilled, (state, {payload}) => {
      if (payload.data) {
        state.data = payload.data.mortgages
      }
    })
    .addMatcher(apiSlice.endpoints.addMortgageNote.matchFulfilled, (state, {payload}) => {
      let thisMortgage = state.data.find(mortgage => mortgage._id === payload.data.newMortgage._id)
      let thisIndex = state.data.indexOf(thisMortgage)
      state.data[thisIndex] = payload.data.newMortgage
    })
    .addMatcher(apiSlice.endpoints.updateMortgageDetails.matchFulfilled, (state, {payload}) => {
      let thisMortgage = state.data.find(mortgage => mortgage._id === payload.data.newMortgage._id)
      let thisIndex = state.data.indexOf(thisMortgage)
      state.data[thisIndex] = payload.data.newMortgage
    })
    .addMatcher(apiSlice.endpoints.setLeadStatusInvestigating.matchFulfilled, (state, {payload}) => {
      let thisMortgage = state.data.find(mortgage => mortgage._id === payload.data.newMortgage._id)
      let thisIndex = state.data.indexOf(thisMortgage)
      state.data[thisIndex] = payload.data.newMortgage
    })
    .addMatcher(apiSlice.endpoints.setLeadStatusClosing.matchFulfilled, (state, {payload}) => {
      let thisMortgage = state.data.find(mortgage => mortgage._id === payload.data.newMortgage._id)
      let thisIndex = state.data.indexOf(thisMortgage)
      state.data[thisIndex] = payload.data.newMortgage
    })
    .addMatcher(apiSlice.endpoints.setLeadStatusFinalized.matchFulfilled, (state, {payload}) => {
      console.log(payload.data)
      let thisMortgage = state.data.find(mortgage => mortgage._id === payload.data.newMortgage._id)
      let thisIndex = state.data.indexOf(thisMortgage)
      state.data[thisIndex] = payload.data.newMortgage
    })
    .addMatcher(apiSlice.endpoints.confirmPublicRecords.matchFulfilled, (state, {payload}) => {
      console.log(payload.data)
      let thisMortgage = state.data.find(mortgage => mortgage._id === payload.data.newMortgage._id)
      let thisIndex = state.data.indexOf(thisMortgage)
      state.data[thisIndex] = payload.data.newMortgage
    })
    .addMatcher(apiSlice.endpoints.deleteMortgage.matchFulfilled, (state, {payload}) => {
      let thisMortgage = state.data.find(mortgage => mortgage._id === payload.data.mortgageId)
      let thisIndex = state.data.indexOf(thisMortgage)
      state.data.splice(thisIndex, 1)
    })
    .addMatcher(apiSlice.endpoints.dismissLead.matchFulfilled, (state, {payload}) => {
      let thisMortgage = state.data.find(mortgage => mortgage._id === payload.data.mortgage._id)
      let thisIndex = state.data.indexOf(thisMortgage)
      state.data[thisIndex] = payload.data.newMortgage
    })
    .addMatcher(apiSlice.endpoints.addLeadTag.matchFulfilled, (state, {payload}) => {
      let thisMortgage = state.data.find(mortgage => mortgage._id === payload.data.newMortgage._id)
      let thisIndex = state.data.indexOf(thisMortgage)
      state.data[thisIndex] = payload.data.newMortgage
    })
    .addMatcher(apiSlice.endpoints.addAssignees.matchFulfilled, (state, {payload}) => {
      let thisMortgage = state.data.find(mortgage => mortgage._id === payload.data.newMortgage._id)
      let thisIndex = state.data.indexOf(thisMortgage)
      state.data[thisIndex] = payload.data.newMortgage
    })
    .addMatcher(apiSlice.endpoints.saveLeadTargetUpdates.matchFulfilled, (state, {payload}) => {
      let thisMortgage = state.data.find(mortgage => mortgage._id === payload.data.newMortgage._id)
      let thisIndex = state.data.indexOf(thisMortgage)
      state.data[thisIndex] = payload.data.newMortgage
    })
    .addMatcher(apiSlice.endpoints.changeTargetOutcome.matchFulfilled, (state, {payload}) => {
      let thisMortgage = state.data.find(mortgage => mortgage._id === payload.data.newMortgage._id)
      let thisIndex = state.data.indexOf(thisMortgage)
      state.data[thisIndex] = payload.data.newMortgage
    })
    .addMatcher(apiSlice.endpoints.uploadCSVMortgages.matchFulfilled, (state, {payload}) => {
      state.data.unshift(...payload.data.newMortgages)
    })
    .addMatcher(apiSlice.endpoints.recordSweep.matchFulfilled, (state, {payload}) => {
      for (let i = 0; i < payload.data.newMortgages.length; i++) {
        let thisMortgage = state.data.find(mortgage => mortgage._id === payload.data.newMortgages[i]._id)
        let thisIndex = state.data.indexOf(thisMortgage)
        state.data[thisIndex] = payload.data.newMortgages[i]
      }
    })
    .addMatcher(apiSlice.endpoints.resolveAllLeadDiscrepancies.matchFulfilled, (state, {payload}) => {
      console.log(payload.data)
      for (let i = 0; i < payload.data.newMortgages.length; i++) {
        let thisMortgage = state.data.find(mortgage => mortgage._id === payload.data.newMortgages[i]._id)
        let thisIndex = state.data.indexOf(thisMortgage)
        state.data[thisIndex] = payload.data.newMortgages[i]
      }
      console.log('here')
    })
    .addMatcher(apiSlice.endpoints.addMortgageTag.matchFulfilled, (state, {payload}) => {
      let thisMortgage = state.data.find(mortgage => mortgage._id === payload.data.newMortgage._id)
      let thisIndex = state.data.indexOf(thisMortgage)
      state.data[thisIndex] = payload.data.newMortgage
    })
  }
})

export default teamMortgagesSlice.reducer

export const selectTeamMortgages = state => state.teamMortgages.data
import React from "react"

const WelcomeModal = () => {

  return (
    <>
    </>
  )
}

export default WelcomeModal
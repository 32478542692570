import React, { useState } from "react";  
import Radio from '@mui/material/Radio';
import { Dialog } from "@mui/material";
import RadioGroup from '@mui/material/RadioGroup';
import CSVUpload1 from "./UploadTypes/CSVUpload1";
import CSVUpload2 from "./UploadTypes/CSVUpload2";
import CSVUpload3 from "./UploadTypes/CSVUpload3";
import CSVUpload4 from "./UploadTypes/CSVUpload4";
import CSVUpload5 from "./UploadTypes/CSVUpload5";
import CSVUpload6 from "./UploadTypes/CSVUpload6";
import CSVUpload7 from "./UploadTypes/CSVUpload7";
import CSVUpload8 from "./UploadTypes/CSVUpload8";
import CSVUpload9 from "./UploadTypes/CSVUpload9";
import CSVUpload10 from "./UploadTypes/CSVUpload10";
import CSVUpload11 from "./UploadTypes/CSVUpload11";
import { useTheme } from "@mui/styles";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { useSelector } from "react-redux";
import FormControlLabel from '@mui/material/FormControlLabel';
import { selectUserFullName } from "../../../../../../slices/userInfoSlice.js";
import { grayUnselectedRadioColorProps } from "../../../../../../componentThemes.js";
import { useUploadCSVMortgagesMutation } from "../../../../../../slices/api/apiSlice";
import { Button, ColumnContent, ComponentBorder, ComponentContainer, ComponentSectionRow, FlexWrapper, RowsContainer, SubHeader } from "../../../../../../StyledComponents";

const Upload = ({ userId, teamId }) => {
  const theme = useTheme()

  const userFullName = useSelector(selectUserFullName)

  const [uploadType, setUploadType] = useState(false)
  const [openCSVUpload, setOpenCSVUpload] = useState(false)

  const handleUploadSelection = (selection) => {
    setUploadType(selection)
    setOpenCSVUpload(true)
  }
  const handleCloseUploadModal = () => {
    setOpenCSVUpload(false)
  }

  const [financialsPresent, setFinancialsPresent] = useState('false')
  const financialsPresentSelectionChange = async (event) => {
    setFinancialsPresent(event.target.value)
  }
  const financialsPresentControlProps = (item) => ({
    checked: financialsPresent === item,
    onChange: financialsPresentSelectionChange,
    value: item,
    label: item,
    labelPlacement: "start",
    sx: {
      color: `${theme.palette.common.black}`,
      margin: '0 1rem 0 0'
    }
  })

  const [uploadCSV, { isFetching, isSuccess, isError }] = useUploadCSVMortgagesMutation()
  const handleUpload = async (rows, type) => {
    let requestObj = {
      userId: userId,
      teamId: teamId,
      rows: rows,
      uploadType: type,
      userFullName: userFullName,
      financialsPresent: financialsPresent,
    }
    await uploadCSV(requestObj)
  }


  return (
    <>
      <ComponentContainer className="fitWindow">
        <FlexWrapper className="column" style={{gridRow: '2', gridColumn: '2'}}>
          <ComponentSectionRow className="header">
            <ColumnContent className="header" style={{gridColumn: '1'}}>
              <span style={{fontWeight: '400'}}>Additional Formats:</span>
              <span style={{margin: '0 auto'}}>mortgageTerm,originationDate,loanType</span>
            </ColumnContent>
          </ComponentSectionRow>
          <ComponentSectionRow className="header">
            <ColumnContent className="header" style={{gridColumn: '1'}}>
              <span style={{fontWeight: '400'}}>Financials:</span>
              <span style={{margin: '0 auto'}}>loanAmount,originalInterestRate</span>
              <div style={{minWidth: 'fit-content'}}>
                <SubHeader style={{padding: '0.5rem 0 0 0'}}>Financials Present</SubHeader>
                <RadioGroup row>
                  <FormControlLabel
                    control={<Radio {...grayUnselectedRadioColorProps()} />}
                    {...financialsPresentControlProps('true')} 
                  />
                  <FormControlLabel
                    control={<Radio {...grayUnselectedRadioColorProps()} />}
                    {...financialsPresentControlProps('false')} 
                  />
                </RadioGroup>
              </div>
            </ColumnContent>
          </ComponentSectionRow>
          <RowsContainer style={{height: '100%', padding: '1rem 0'}}>
            <ComponentSectionRow style={{padding: '0 0 0 1.5rem'}}>
              <Button className="primary round" onClick={() => handleUploadSelection('type1')}><UploadFileIcon /></Button>
              <FlexWrapper>
                <FlexWrapper className="column justifyCenter" style={{maxWidth: 'fit-content', padding: '0 0.3rem 0 0'}}>
                  <span style={{fontWeight: '600', fontSize: '1.2rem'}}>Simple</span>
                  <span>type 1</span>
                </FlexWrapper>
                <span style={{width: '100%', margin: 'auto 0 auto 0.5rem', textWrap: 'balance'}}>{`{Street Number} {Street Name}`}</span>
              </FlexWrapper>
            </ComponentSectionRow>
            <ComponentSectionRow style={{padding: '0 0 0 1.5rem'}}>
              <Button className="primary round" onClick={() => handleUploadSelection('type2')}><UploadFileIcon /></Button>
              <FlexWrapper>
                <FlexWrapper className="column justifyCenter" style={{maxWidth: 'fit-content', padding: '0 0.3rem 0 0'}}>
                  <span style={{fontWeight: '600', fontSize: '1.2rem'}}>Street Suffix</span>
                  <span>type 2</span>
                </FlexWrapper>
                <span style={{width: '100%', margin: 'auto 0 auto 0.5rem', textWrap: 'balance'}}>{`{Street Number} {Street Name} {Street Suffix}`}</span>
              </FlexWrapper>
            </ComponentSectionRow>
            <ComponentSectionRow style={{padding: '0 0 0 1.5rem'}}>
              <span style={{fontWeight: '600', fontSize: '1.2rem', minWidth: 'fit-content'}}>Street Directions</span>
            </ComponentSectionRow>
            <ComponentSectionRow style={{padding: '0 0 0 1.5rem'}}>
              <Button className="primary round" onClick={() => handleUploadSelection('type3')}><UploadFileIcon /></Button>
              <FlexWrapper>
                <FlexWrapper className="column justifyCenter" style={{maxWidth: 'fit-content', padding: '0 0.3rem 0 0'}}>
                  <span style={{fontWeight: '600', fontSize: '1.2rem'}}>Prefix</span>
                  <span>type 3</span>
                </FlexWrapper>
                <span style={{width: '100%', margin: 'auto 0 auto 0.5rem', textWrap: 'balance'}}>{`{Street Number} {Street Dir Prefix} {Street Name}`}</span>
              </FlexWrapper>
            </ComponentSectionRow>
            <ComponentSectionRow style={{padding: '0 0 0 1.5rem'}}>
              <Button className="primary round" onClick={() => handleUploadSelection('type4')}><UploadFileIcon /></Button>
              <FlexWrapper>
                <FlexWrapper className="column justifyCenter" style={{maxWidth: 'fit-content', padding: '0 0.3rem 0 0'}}>
                  <span style={{fontWeight: '600', fontSize: '1.2rem'}}>Prefix & Street Suffix</span>
                  <span>type 4</span>
                </FlexWrapper>
                <span style={{width: '100%', margin: 'auto 0 auto 0.5rem', textWrap: 'balance'}}>{`{Street Number} {Street Dir Prefix} {Street Name} {Street Suffix}`}</span>
              </FlexWrapper>
            </ComponentSectionRow>
            <ComponentSectionRow style={{padding: '0 0 0 1.5rem'}}>
              <Button className="primary round" onClick={() => handleUploadSelection('type5')}><UploadFileIcon /></Button>
              <FlexWrapper>
                <FlexWrapper className="column justifyCenter" style={{maxWidth: 'fit-content', padding: '0 0.3rem 0 0'}}>
                  <span style={{fontWeight: '600', fontSize: '1.2rem'}}>Suffix</span>
                  <span>type 5</span>
                </FlexWrapper>
                <span style={{width: '100%', margin: 'auto 0 auto 0.5rem', textWrap: 'balance'}}>{`{Street Number} {Street Name} {Street Dir Suffix}`}</span>
              </FlexWrapper>
            </ComponentSectionRow>
            <ComponentSectionRow style={{padding: '0 0 0 1.5rem'}}>
              <Button className="primary round" onClick={() => handleUploadSelection('type6')}><UploadFileIcon /></Button>
              <FlexWrapper>
                <FlexWrapper className="column justifyCenter" style={{maxWidth: 'fit-content', padding: '0 0.3rem 0 0'}}>
                  <span style={{fontWeight: '600', fontSize: '1.2rem'}}>Suffix & Street Suffix</span>
                  <span>type 6</span>
                </FlexWrapper>
                <span style={{width: '100%', margin: 'auto 0 auto 0.5rem', textOverflow: 'pretty'}}>{`{Street Number} {Street Name} {Street Dir Suffix} {Street Suffix}`}</span>
              </FlexWrapper>
            </ComponentSectionRow>
            <ComponentSectionRow style={{padding: '0 0 0 1.5rem'}}>
              <span style={{fontWeight: '600', fontSize: '1.2rem', minWidth: 'fit-content'}}>Unit Numbers</span>
            </ComponentSectionRow>
            <ComponentSectionRow style={{padding: '0 0 0 1.5rem'}}>
              <Button className="primary round" onClick={() => handleUploadSelection('type7')}><UploadFileIcon /></Button>
              <FlexWrapper>
                <FlexWrapper className="column justifyCenter" style={{maxWidth: 'fit-content', padding: '0 0.3rem 0 0'}}>
                  <span style={{fontWeight: '600', fontSize: '1.2rem'}}>Simple</span>
                  <span>type 7</span>
                </FlexWrapper>
                <span style={{width: '100%', margin: 'auto 0 auto 0.5rem', textWrap: 'balance'}}>{`{Street Number} {Street Name} {Unit Prefix}{Unit Number}`}</span>
              </FlexWrapper>
            </ComponentSectionRow>
            <ComponentSectionRow style={{padding: '0 0 0 1.5rem'}}>
              <Button className="primary round" onClick={() => handleUploadSelection('type8')}><UploadFileIcon /></Button>
              <FlexWrapper>
                <FlexWrapper className="column justifyCenter" style={{maxWidth: 'fit-content', padding: '0 0.3rem 0 0'}}>
                  <span style={{fontWeight: '600', fontSize: '1.2rem'}}>Street Suffix</span>
                  <span>type 8</span>
                </FlexWrapper>
                <span style={{width: '100%', margin: 'auto 0 auto 0.5rem', textWrap: 'balance'}}>{`{Street Number} {Street Name} {Street Suffix} {Unit Prefix}{Unit Number}`}</span>
              </FlexWrapper>
            </ComponentSectionRow>
            <ComponentSectionRow style={{padding: '0 0 0 1.5rem'}}>
              <Button className="primary round" onClick={() => handleUploadSelection('type9')}><UploadFileIcon /></Button>
              <FlexWrapper>
                <FlexWrapper className="column justifyCenter" style={{maxWidth: 'fit-content', padding: '0 0.3rem 0 0'}}>
                  <span style={{fontWeight: '600', fontSize: '1.2rem'}}>Street Dir Prefix</span>
                  <span>type 9</span>
                </FlexWrapper>
                <span style={{width: '100%', margin: 'auto 0 auto 0.5rem', textWrap: 'balance'}}>{`{Street Number} {Street Dir Prefix} {Street Name} {Unit Prefix}{Unit Number}`}</span>
              </FlexWrapper>
            </ComponentSectionRow>
            <ComponentSectionRow style={{padding: '0 0 0 1.5rem'}}>
              <Button className="primary round" onClick={() => handleUploadSelection('type10')}><UploadFileIcon /></Button>
              <FlexWrapper>
                <FlexWrapper className="column justifyCenter" style={{maxWidth: 'fit-content', padding: '0 0.3rem 0 0'}}>
                  <span style={{fontWeight: '600', fontSize: '1.2rem'}}>Street Dir Prefix & Street Suffix</span>
                  <span>type 10</span>
                </FlexWrapper>
                <span style={{width: '100%', margin: 'auto 0 auto 0.5rem', textWrap: 'balance'}}>{`{Street Number} {Street Dir Prefix} {Street Name} {Street Suffix} {Unit Prefix}{Unit Number}`}</span>
              </FlexWrapper>
            </ComponentSectionRow>
            <ComponentSectionRow style={{padding: '0 0 0 1.5rem'}}>
              <Button className="primary round" onClick={() => handleUploadSelection('type11')}><UploadFileIcon /></Button>
              <FlexWrapper>
                <FlexWrapper className="column justifyCenter" style={{maxWidth: 'fit-content', padding: '0 0.3rem 0 0'}}>
                  <span style={{fontWeight: '600', fontSize: '1.2rem'}}>Street Dir Suffix & Street Suffix</span>
                  <span>type 11</span>
                </FlexWrapper>
                <span style={{width: '100%', margin: 'auto 0 auto 0.5rem', textWrap: 'balance'}}>{`{Street Number} {Street Name} {Street Dir Suffix} {Street Suffix} {Unit Prefix}{Unit Number}`}</span>
              </FlexWrapper>
            </ComponentSectionRow>
          </RowsContainer>
        </FlexWrapper>

        <ComponentBorder className="top" style={{gridRow:'1', gridColumn: '1/4'}} />
        <ComponentBorder className="left" style={{gridRow:'2', gridColumn: '1'}} />
        <ComponentBorder className="right"  style={{gridRow:'2', gridColumn: '3'}} />
        <ComponentBorder className="bottom" style={{gridRow:'3', gridColumn: '1/4'}} />
      </ComponentContainer>

      <Dialog
        open={openCSVUpload} onClose={handleCloseUploadModal}
        PaperProps={{ sx: {width: "110rem", minWidth: "110rem", maxWidth: "110rem", height: "fit-content", minHeight: "20rem", maxHeight: "95%"}}}
      >
        {uploadType === 'type1' ?
          <CSVUpload1 handleCloseUploadModal={handleCloseUploadModal} handleUpload={handleUpload} isFetching={isFetching} isSuccess={isSuccess} isError={isError} />
        : uploadType === 'type2' ?
          <CSVUpload2 handleCloseUploadModal={handleCloseUploadModal} handleUpload={handleUpload} isFetching={isFetching} isSuccess={isSuccess} isError={isError} />
        : uploadType === 'type3' ?
          <CSVUpload3 handleCloseUploadModal={handleCloseUploadModal} handleUpload={handleUpload} isFetching={isFetching} isSuccess={isSuccess} isError={isError} />
        : uploadType === 'type4' ?
          <CSVUpload4 handleCloseUploadModal={handleCloseUploadModal} handleUpload={handleUpload} isFetching={isFetching} isSuccess={isSuccess} isError={isError} />
        : uploadType === 'type5' ?
          <CSVUpload5 handleCloseUploadModal={handleCloseUploadModal} handleUpload={handleUpload} isFetching={isFetching} isSuccess={isSuccess} isError={isError} />
        : uploadType === 'type6' ?
          <CSVUpload6 handleCloseUploadModal={handleCloseUploadModal} handleUpload={handleUpload} isFetching={isFetching} isSuccess={isSuccess} isError={isError} />
        : uploadType === 'type7' ?
          <CSVUpload7 handleCloseUploadModal={handleCloseUploadModal} handleUpload={handleUpload} isFetching={isFetching} isSuccess={isSuccess} isError={isError} />
        : uploadType === 'type8' ?
          <CSVUpload8 handleCloseUploadModal={handleCloseUploadModal} handleUpload={handleUpload} isFetching={isFetching} isSuccess={isSuccess} isError={isError} />
        : uploadType === 'type9' ?
          <CSVUpload9 handleCloseUploadModal={handleCloseUploadModal} handleUpload={handleUpload} isFetching={isFetching} isSuccess={isSuccess} isError={isError} />
        : uploadType === 'type10' ?
          <CSVUpload10 handleCloseUploadModal={handleCloseUploadModal} handleUpload={handleUpload} isFetching={isFetching} isSuccess={isSuccess} isError={isError} />
        :
          <CSVUpload11 handleCloseUploadModal={handleCloseUploadModal} handleUpload={handleUpload} isFetching={isFetching} isSuccess={isSuccess} isError={isError} />
        }
      </Dialog>
    </>
  )
}

export default Upload;
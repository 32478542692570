import React from "react";
import { ComponentHeader, ComponentSectionRow, StatusAndLabelWrapper, Button, ButtonWrapper, Label, InputWrapper, ModalContentContainer, AccentInfo, DetailText, Divider } from "../../../../StyledComponents";

const PropertyDetails = ({ handleCloseLeadActionsModal, mortgageData }) => {

  return (
    <>
      <ComponentHeader className="modal">Property Details</ComponentHeader>
      <ModalContentContainer className="column leadActions">
        <div style={{width: '80%', margin: '0 auto'}}>
          <DetailText>Address</DetailText>
          <Divider className="gray" />
          <ComponentSectionRow style={{margin: '1rem 0'}}>
            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Street Number</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.address.StreetNumber.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Street Name</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.address.StreetName.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Street Direction Prefix</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.address.StreetDirPrefix.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Street Direction Suffix</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.address.StreetDirSuffix.currentValue}
              </DetailText>
            </InputWrapper>

          </ComponentSectionRow>
          <ComponentSectionRow style={{margin: '1rem 0'}}>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Street Suffix</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.address.StreetSuffix.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Unit Prefix</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.address.UnitPrefix.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Unit Number</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.address.UnitNumber.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Street Address Line 1</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.address.StreetAddressLine1.currentValue}
              </DetailText>
            </InputWrapper>

          </ComponentSectionRow>
          <ComponentSectionRow style={{margin: '1rem 0'}}>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Street Address Line 2</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.address.StreetAddressLine2.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Street Address One Line</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.address.StreetAddressOneLine.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>City</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.address.City.currentValue}
              </DetailText>
            </InputWrapper>

          </ComponentSectionRow>
          <ComponentSectionRow style={{margin: '1rem 0'}}>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>State Or Province</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.address.StateOrProvince.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Postal Code</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.address.PostalCode.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Postal Code Plus 4</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.address.PostalCodePlus4.currentValue}
              </DetailText>
            </InputWrapper>

          </ComponentSectionRow>

          <DetailText>Living</DetailText>
          <Divider className="gray" />
          <ComponentSectionRow style={{margin: '1rem 0'}}>
            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Pool Type</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.living.PoolType.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Stories Description</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.living.StoriesDescription.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Stories</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.living.Stories.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Cooling</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.living.Cooling.currentValue}
              </DetailText>
            </InputWrapper>

          </ComponentSectionRow>
          <ComponentSectionRow style={{margin: '1rem 0'}}>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Fireplaces</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.living.FireplacesTotal.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Bedrooms Total</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.living.BedroomsTotal.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Rooms Total</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.living.RoomsTotal.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Bathrooms Total</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.living.BathroomsTotalInteger.currentValue}
              </DetailText>
            </InputWrapper>

          </ComponentSectionRow>
          <ComponentSectionRow style={{margin: '1rem 0'}}>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Bathrooms Full</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.living.BathroomsFull.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Building Area Total</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.living.BuildingAreaTotal.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Gross Area</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.living.GrossArea.currentValue}
              </DetailText>
            </InputWrapper>

          </ComponentSectionRow>
          <ComponentSectionRow style={{margin: '1rem 0'}}>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Living Area</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.living.LivingArea.currentValue}
              </DetailText>
            </InputWrapper>

          </ComponentSectionRow>

          <DetailText>Building</DetailText>
          <Divider className="gray" />
          <ComponentSectionRow style={{margin: '1rem 0'}}>
            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Building Quality Score</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.building.BuildingQualityScore.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Number Of Units Total</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.building.NumberOfUnitsTotal.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Number Of Buildings</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.building.NumberOfBuildings.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Architectural Style</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.building.ArchitecturalStyle.currentValue}
              </DetailText>
            </InputWrapper>

          </ComponentSectionRow>
          <ComponentSectionRow style={{margin: '1rem 0'}}>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Year Built</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.building.YearBuilt.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Construction Type</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.building.ConstructionType.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Frame Type</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.building.FrameType.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Exterior Walls Type</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.building.ExteriorWallsType.currentValue}
              </DetailText>
            </InputWrapper>

          </ComponentSectionRow>
          <ComponentSectionRow style={{margin: '1rem 0'}}>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Year Built Effective</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.building.YearBuiltEffective.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Heating</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.living.Heating.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Roof Cover Type</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.building.RoofCoverType.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Roof Type</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.building.RoofType.currentValue}
              </DetailText>
            </InputWrapper>

          </ComponentSectionRow>
          <ComponentSectionRow style={{margin: '1rem 0'}}>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Building Condition</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.building.BuildingCondition.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Interior Walls Type</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.building.InteriorWallsType.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Location Features</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.lot.LocationFeatures.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Car Storage Type</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.building.CarStorageType.currentValue}
              </DetailText>
            </InputWrapper>

          </ComponentSectionRow>
          <ComponentSectionRow style={{margin: '1rem 0'}}>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Parking Spaces</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.building.ParkingSpaces.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Garage Spaces</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.building.GarageSpaces.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Garage Area</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.building.GarageArea.currentValue}
              </DetailText>
            </InputWrapper>

          </ComponentSectionRow>

          <DetailText>Summary</DetailText>
          <Divider className="gray" />
          <ComponentSectionRow style={{margin: '1rem 0'}}>
            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Owner Occupied</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.tax.OwnerOccupied.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Water Source</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.summary.WaterSource.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Sewer Type</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.summary.SewerType.currentValue}
              </DetailText>
            </InputWrapper>

          </ComponentSectionRow>
          <ComponentSectionRow style={{margin: '1rem 0'}}>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>School District</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.summary.SchoolDistrict.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Property Type</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.summary.PropertyType.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Property Sub Type</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.summary.PropertySubType.currentValue}
              </DetailText>
            </InputWrapper>

          </ComponentSectionRow>
          <ComponentSectionRow style={{margin: '1rem 0'}}>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Tax Legal Description</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.lot.TaxLegalDescription.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Quit Claim Flag</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.summary.QuitClaimFlag.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Foreclosure Flag</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.summary.REOflag.currentValue}
              </DetailText>
            </InputWrapper>

          </ComponentSectionRow>
          <ComponentSectionRow style={{margin: '1rem 0'}}>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Distress</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.summary.DistressYN.currentValue}
              </DetailText>
            </InputWrapper>

          </ComponentSectionRow>

          <DetailText>Lot Details</DetailText>
          <Divider className="gray" />
          <ComponentSectionRow style={{margin: '1rem 0'}}>
            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Tax Lot</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.lot.TaxLot.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Lot Size Acres</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.lot.LotSizeAcres.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Lot Size Square Feet</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.lot.LotSizeSquareFeet.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Lot Size Dimensions</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.lot.LotSizeDimensions.currentValue}
              </DetailText>
            </InputWrapper>

          </ComponentSectionRow>
          <ComponentSectionRow style={{margin: '1rem 0'}}>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Zoning</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.lot.Zoning.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>County Land Use Code</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.lot.CountyLandUseCode.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>State Land Use Code</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.lot.StateLandUseCode.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Latitude</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.lot.Latitude.currentValue}
              </DetailText>
            </InputWrapper>

          </ComponentSectionRow>
          <ComponentSectionRow style={{margin: '1rem 0'}}>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Adjusted Area</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.lot.AdjustedArea.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Below Grade Total Area</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.lot.BelowGradeTotalArea.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Number Of Lots Code</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.lot.NumberOfLotsCode.currentValue}
              </DetailText>
            </InputWrapper>

          </ComponentSectionRow>
          <ComponentSectionRow style={{margin: '1rem 0'}}>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Below Grade Finished Area</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.lot.BelowGradeFinishedArea.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Land Lot</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.lot.LandLot.currentValue}
              </DetailText>
            </InputWrapper>
          </ComponentSectionRow>       

          <DetailText>Area Details</DetailText>
          <Divider className="gray" />
          <ComponentSectionRow style={{margin: '1rem 0'}}>
            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Area</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.lot.View.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Land Use Code</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.lot.LandUseCode.currentValue}
              </DetailText>
            </InputWrapper>

          </ComponentSectionRow>
          <ComponentSectionRow style={{margin: '1rem 0'}}>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>County Use Code</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.lot.CountyUseCode.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Legal Subdivision Name</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.lot.LegalSubdivisionName.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Tax Code Area</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.lot.TaxCodeArea.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Census Tract ID</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.lot.CensusTractId.currentValue}
              </DetailText>
            </InputWrapper>

          </ComponentSectionRow>
          <ComponentSectionRow style={{margin: '1rem 0'}}>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Tax Block</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.lot.TaxBlock.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Tax Section</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.lot.TaxSection.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Tax District</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.lot.TaxDistrict.currentValue}
              </DetailText>
            </InputWrapper>

          </ComponentSectionRow>
          <ComponentSectionRow style={{margin: '1rem 0'}}>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Tax Legal Unit</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.lot.TaxLegalUnit.currentValue}
              </DetailText>
            </InputWrapper>

          </ComponentSectionRow>          

        </div>
        
        <ButtonWrapper className="selfAlignEnd marginAuto">
          <Button className="gray" onClick={() => handleCloseLeadActionsModal()}>Close</Button>
        </ButtonWrapper>
      </ModalContentContainer>
    </>
  )
}

export default PropertyDetails;
import React from "react";
import Menu from "./components/Menu";
import TeamDropdown from "../../../../components/DropDowns/TeamDropdown";
import { useTheme } from "@mui/styles";
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { selectLoadData } from "../../../../slices/loadDataSlice";
import { selectNavSelection } from "../../../../slices/navSelectionSlice";
import { selectPageSelection } from "../../../../slices/pageSelectionSlice";
import { selectDashPerspective } from "../../../../slices/dashPerspectiveSlice";
import { FlexWrapper, LoadingWrapper, NavBarContainer } from "../../../../StyledComponents";
 
const NavBar = () => {
  const theme = useTheme()

  const dashPerspective = useSelector(selectDashPerspective)
  const navSelection = useSelector(selectNavSelection)
  const pageSelection = useSelector(selectPageSelection)
  const dataLoaded = useSelector(selectLoadData)

  return (
    <NavBarContainer>
      {dashPerspective && navSelection === 'activeLeads' && pageSelection === 'dashboard' ?
        <TeamDropdown dashPerspective={dashPerspective} />
      :
        <></>
      }
      {dataLoaded ?
        <></>
      :
        <FlexWrapper className="alignCenter" style={{margin: '0 auto', width: 'fit-content', gridColumn: '2'}}>
          <LoadingWrapper>
            <CircularProgress style={{height: '2.6rem', width: '2.6rem', color: `${theme.palette.common.grayOpaque}`}} />
            <LoadingWrapper style={{fontSize: '2rem', marginLeft: '0.6rem'}}>Loading...</LoadingWrapper>
          </LoadingWrapper>
        </FlexWrapper>
      }
      <div style={{display: 'flex', justifyContent: 'center', width: '100%', gridColumn: '3'}}>
        <Menu dataLoaded={dataLoaded} />
      </div>
    </NavBarContainer>
  )
}

export default NavBar;
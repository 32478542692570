import React from "react";
import CancelIcon from '@mui/icons-material/Cancel';
import NotesModal from "../LeadActions/components/Notes";
import ReportsModal from "../LeadActions/components/ReportsModal";
import { useSelector } from "react-redux";
import InvestigateLead from "../LeadActions/components/LeadStatus/InvestigateLead";
import MortgageDetails from "../LeadActions/components/OwnerDetails";
import MortgageActionsNav from "./MortgageActionsNav";
import { selectViewingMortgage } from "../../../slices/viewingMortgageSlice";
import { selectMortgageActionsNavSelection } from "../../../slices/navMenuSelectionsSlice";
import { ActionIconWrapper, ModalContainer, ComponentBorder, TimelineContainer, ComponentHeader } from "../../../StyledComponents";
import PropertyDetails from "../LeadActions/components/PropertyDetails";
import CompareRecords from "../LeadActions/components/CompareRecords";
import Timeline from "../../Timeline/Timeline";
import MortgageTags from "../LeadActions/components/MortgageTags";

const MortgageActionsModal = ({ handleCloseMortgageActionsModal, userId, teamId, userFullName, memberData, dashPerspective, reports, leadData }) => {

  const mortgageData = useSelector(selectViewingMortgage)
  const navSelection = useSelector(selectMortgageActionsNavSelection)

  return (
    <ModalContainer className="threePanel">
      <ComponentBorder className="top" style={{gridColumn: '1/6'}}>
        <ActionIconWrapper className="medium grayText circle hoverBorder hover" style={{margin: 'auto 2rem auto 0'}}>
          <CancelIcon onClick={() => handleCloseMortgageActionsModal()} style={{height: '100%', width: '100%'}} />  
        </ActionIconWrapper>
      </ComponentBorder>
      <MortgageActionsNav mortgageData={mortgageData} reportsNumber={mortgageData.reports.length} />
      {navSelection === 'reports' ?
        <ReportsModal reports={reports} />
      : navSelection === 'notes' ?
        <NotesModal handleCloseNotesModal={handleCloseMortgageActionsModal} userId={userId} userFullName={userFullName} leadData={leadData} dashPerspective={dashPerspective} mortgageData={mortgageData} />
      : navSelection === 'compareRecords' ?
        <CompareRecords mortgageData={mortgageData} dashPerspective={dashPerspective} userFullName={userFullName} leadData={leadData} teamId={teamId} location={'repository'} leadUserNotifications={0} />
      : navSelection === 'propertyDetails' ?
        <PropertyDetails handleCloseLeadActionsModal={handleCloseMortgageActionsModal} mortgageData={mortgageData} />
      : navSelection === 'mortgageDetails' ?
        <MortgageDetails handleCloseLeadActionsModal={handleCloseMortgageActionsModal} mortgageData={mortgageData} />
      : navSelection === 'investigateLead' ?
        <InvestigateLead handleCloseInvestigateLead={handleCloseMortgageActionsModal} userId={userId} teamId={teamId} userFullName={userFullName} memberData={memberData} mortgageId={mortgageData._id} leadData={{assigneeIds: []}} dashPerspective={dashPerspective} requestOrigin={'repository'} />
      : navSelection === 'editTags' ?
        <MortgageTags mortgageData={mortgageData} handleCloseModal={handleCloseMortgageActionsModal} userId={userId} dashPerspective={dashPerspective} teamId={teamId} userFullName={userFullName} />
      :
        <></>
      }
      <ComponentHeader className="modal timeline">Timeline</ComponentHeader>
      <TimelineContainer className="actionsModal">
        <Timeline mortgageTimeline={mortgageData.timeline} />
      </TimelineContainer>
      <ComponentBorder className="left" style={{gridRow:'2/5', gridColumn: '1'}}></ComponentBorder>
      <ComponentBorder className="right"  style={{gridRow:'2/5', gridColumn: '5'}}></ComponentBorder>
      <ComponentBorder className="bottom" style={{gridRow:'5', gridColumn: '1/6'}}></ComponentBorder>
    </ModalContainer>
  )
}

export default MortgageActionsModal;
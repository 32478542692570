export default async function handleLeadTagFilterChanges (teamLeadTagIds, eventValue, leadsAwaitingAction, teamInvestigatingLeads, teamClosingLeads, leadNewFilter, leadTypeFilter) {
    let awaitingAction = await handleAwaitingAction(teamLeadTagIds, eventValue, leadsAwaitingAction, leadNewFilter, leadTypeFilter);
    let investigating = await handleInvestigating(teamLeadTagIds, eventValue, teamInvestigatingLeads, leadNewFilter, leadTypeFilter);
    let closing = await handleClosing(teamLeadTagIds, eventValue, teamClosingLeads, leadNewFilter, leadTypeFilter);
    return {awaitingAction, investigating, closing}
  }
    
  let handleAwaitingAction = async (teamLeadTagIds, eventValue, leadsAwaitingAction, leadNewFilter, leadTypeFilter) => {
    let filteredLeadsAwaitingAction = leadsAwaitingAction
    if (eventValue === 'All') {
      let filterByTagSet = new Set(teamLeadTagIds)
      filteredLeadsAwaitingAction = leadsAwaitingAction.filter((lead) => 
        lead.tagIds.some((tag) => filterByTagSet.has(tag))
      )
    } else {
      let filterByTagSet = new Set([eventValue])
      filteredLeadsAwaitingAction = leadsAwaitingAction.filter((lead) => 
        lead.tagIds.some((tag) => filterByTagSet.has(tag))
      )
    } 
    // let filteredLeadsAwaitingAction = leadsAwaitingAction
    // if (leadNewFilter !== 'false' && !leadNewFilter) {
    //   filteredLeadsAwaitingAction = leadsAwaitingAction.filter((lead) => lead.newLeadLabel === true)
    // }
    // if (leadTypeFilter === 'Renegotiation') {
    //   filteredLeadsAwaitingAction = leadsAwaitingAction.filter((lead) => lead.targetOutcome === 'renegotiation')
    // } else if (leadTypeFilter === 'Refinance') {
    //   filteredLeadsAwaitingAction = leadsAwaitingAction.filter((lead) => lead.targetOutcome === 'refinance')
    // }

    // if (leadNewFilter === 'false' || !leadNewFilter) {
    //   if (leadTypeFilter === 'All') {
    //     if (leadTagFilter === 'All' || !leadTagFilter) {
    //       let filterByTagSet = new Set(teamLeadTagIds)
    //       filteredLeadsAwaitingAction = leadsAwaitingAction.filter((lead) => 
    //         lead.tagIds.some((tag) => filterByTagSet.has(tag))
    //       )
    //     } else {
    //       let filterByTagSet = new Set([eventValue])
    //       filteredLeadsAwaitingAction = leadsAwaitingAction.filter((lead) => 
    //         lead.tagIds.some((tag) => filterByTagSet.has(tag))
    //       )
    //     } 
    //   } else if (leadTypeFilter === 'Renegotiation') {
    //     if (leadTagFilter === 'All' || !leadTagFilter) {
    //       let filterByTagSet = new Set(teamLeadTagIds)
    //       filteredLeadsAwaitingAction = leadsAwaitingAction.filter((lead) => 
    //         lead.tagIds.some((tag) => filterByTagSet.has(tag)) && lead.targetOutcome === 'renegotiation'
    //       )
    //     } else {
    //       let filterByTagSet = new Set([eventValue])
    //       filteredLeadsAwaitingAction = leadsAwaitingAction.filter((lead) => 
    //         lead.tagIds.some((tag) => filterByTagSet.has(tag)) && lead.targetOutcome === 'renegotiation'
    //       )
    //     } 
    //   } else {
    //     if (leadTagFilter === 'All' || !leadTagFilter) {
    //       let filterByTagSet = new Set(teamLeadTagIds)
    //       filteredLeadsAwaitingAction = leadsAwaitingAction.filter((lead) => 
    //         lead.tagIds.some((tag) => filterByTagSet.has(tag)) && lead.targetOutcome === 'refinance'
    //       )
    //     } else {
    //       let filterByTagSet = new Set([eventValue])
    //       filteredLeadsAwaitingAction = leadsAwaitingAction.filter((lead) => 
    //         lead.tagIds.some((tag) => filterByTagSet.has(tag)) && lead.targetOutcome === 'refinance'
    //       )
    //     } 
    //   }
    // } else {
    //   if (leadTypeFilter === 'All') {
    //     if (leadTagFilter === 'All' || !leadTagFilter) {
    //       let filterByTagSet = new Set(teamLeadTagIds)
    //       filteredLeadsAwaitingAction = leadsAwaitingAction.filter((lead) => 
    //         lead.tagIds.some((tag) => filterByTagSet.has(tag)) && lead.newLeadLabel === true
    //       )
    //     } else {
    //       let filterByTagSet = new Set([eventValue])
    //       filteredLeadsAwaitingAction = leadsAwaitingAction.filter((lead) => 
    //         lead.tagIds.some((tag) => filterByTagSet.has(tag)) && lead.newLeadLabel === true
    //       )
    //     } 
    //   } else if (leadTypeFilter === 'Renegotiation') {
    //     if (leadTagFilter === 'All' || !leadTagFilter) {
    //       let filterByTagSet = new Set(teamLeadTagIds)
    //       filteredLeadsAwaitingAction = leadsAwaitingAction.filter((lead) => 
    //         lead.tagIds.some((tag) => filterByTagSet.has(tag)) && lead.targetOutcome === 'renegotiation' && lead.newLeadLabel === true
    //       )
    //     } else {
    //       let filterByTagSet = new Set([eventValue])
    //       filteredLeadsAwaitingAction = leadsAwaitingAction.filter((lead) => 
    //         lead.tagIds.some((tag) => filterByTagSet.has(tag)) && lead.targetOutcome === 'renegotiation' && lead.newLeadLabel === true
    //       )
    //     } 
    //   } else {
    //     if (leadTagFilter === 'All' || !leadTagFilter) {
    //       let filterByTagSet = new Set(teamLeadTagIds)
    //       filteredLeadsAwaitingAction = leadsAwaitingAction.filter((lead) => 
    //         lead.tagIds.some((tag) => filterByTagSet.has(tag)) && lead.targetOutcome === 'refinance' && lead.newLeadLabel === true
    //       )
    //     } else {
    //       let filterByTagSet = new Set([eventValue])
    //       filteredLeadsAwaitingAction = leadsAwaitingAction.filter((lead) => 
    //         lead.tagIds.some((tag) => filterByTagSet.has(tag)) && lead.targetOutcome === 'refinance' && lead.newLeadLabel === true
    //       )
    //     } 
    //   }
    // }

    return filteredLeadsAwaitingAction
  }
  let handleInvestigating = async (teamLeadTagIds, eventValue, teamInvestigatingLeads, leadTagFilter, leadNewFilter) => {
    let filteredInvestigatingLeads = teamInvestigatingLeads
    if (leadNewFilter === 'false') {
      if (eventValue === 'Manual') {
        if (leadTagFilter === 'All' || !leadTagFilter) {
          filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.tier === 0)
        } else if (leadTagFilter === 'Renegotiation') {
          filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.tier === 0 && lead.targetOutcome === 'renegotiation')
        } else {
          filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.tier === 0 && lead.targetOutcome === 'refinance')
        } 
      } else if (eventValue === 'Tier 1') {
        if (leadTagFilter === 'All' || !leadTagFilter) {
          filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.tier === 1)
        } else if (leadTagFilter === 'Renegotiation') {
          filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.tier === 1 && lead.targetOutcome === 'renegotiation')
        } else {
          filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.tier === 1 && lead.targetOutcome === 'refinance')
        }
      } else if (eventValue === 'Tier 2') {
        if (leadTagFilter === 'All' || !leadTagFilter) {
          filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.tier === 2)
        } else if (leadTagFilter === 'Renegotiation') {
          filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.tier === 2 && lead.targetOutcome === 'renegotiation')
        } else {
          filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.tier === 2 && lead.targetOutcome === 'refinance')
        }
      } else if (eventValue === 'Tier 3') {
        if (leadTagFilter === 'All' || !leadTagFilter) {
          filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.tier === 3)
        } else if (leadTagFilter === 'Renegotiation') {
          filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.tier === 3 && lead.targetOutcome === 'renegotiation')
        } else {
          filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.tier === 3 && lead.targetOutcome === 'refinance')
        }
      } else {
        if (leadTagFilter === 'All' || !leadTagFilter) {
    
        } else if (leadTagFilter === 'Renegotiation') {
          filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.targetOutcome === 'renegotiation')
        } else {
          filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.targetOutcome === 'refinance')
        }
      }
    } else {
      if (eventValue === 'Manual') {
        if (leadTagFilter === 'All' || !leadTagFilter) {
          filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.tier === 0 && lead.newAssignmentNotification === true)
        } else if (leadTagFilter === 'Renegotiation') {
          filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.tier === 0 && lead.newAssignmentNotification === true && lead.targetOutcome === 'renegotiation')
        } else {
          filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.tier === 0 && lead.newAssignmentNotification === true && lead.targetOutcome === 'refinance')
        } 
      } else if (eventValue === 'Tier 1') {
        if (leadTagFilter === 'All' || !leadTagFilter) {
          filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.tier === 1 && lead.newAssignmentNotification === true)
        } else if (leadTagFilter === 'Renegotiation') {
          filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.tier === 1 && lead.newAssignmentNotification === true && lead.targetOutcome === 'renegotiation')
        } else {
          filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.tier === 1 && lead.newAssignmentNotification === true && lead.targetOutcome === 'refinance')
        }
      } else if (eventValue === 'Tier 2') {
        if (leadTagFilter === 'All' || !leadTagFilter) {
          filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.tier === 2 && lead.newAssignmentNotification === true)
        } else if (leadTagFilter === 'Renegotiation') {
          filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.tier === 2 && lead.newAssignmentNotification === true && lead.targetOutcome === 'renegotiation')
        } else {
          filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.tier === 2 && lead.newAssignmentNotification === true && lead.targetOutcome === 'refinance')
        }
      } else if (eventValue === 'Tier 3') {
        if (leadTagFilter === 'All' || !leadTagFilter) {
          filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.tier === 3 && lead.newAssignmentNotification === true)
        } else if (leadTagFilter === 'Renegotiation') {
          filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.tier === 3 && lead.newAssignmentNotification === true && lead.targetOutcome === 'renegotiation')
        } else {
          filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.tier === 3 && lead.newAssignmentNotification === true && lead.targetOutcome === 'refinance')
        }
      } else {
        if (leadTagFilter === 'All' || !leadTagFilter) {
          filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.newAssignmentNotification === true)
        } else if (leadTagFilter === 'Renegotiation') {
          filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.newAssignmentNotification === true && lead.targetOutcome === 'renegotiation')
        } else {
          filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.newAssignmentNotification === true && lead.targetOutcome === 'refinance')
        }
      }
    }
    return filteredInvestigatingLeads
  }
  let handleClosing = async (teamLeadTagIds, eventValue, teamClosingLeads, leadTagFilter, leadNewFilter) => {
    let filteredClosingLeads = teamClosingLeads
    if (leadNewFilter === 'false') {
      if (eventValue === 'Manual') {
        if (leadTagFilter === 'All' || !leadTagFilter) {
          filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.tier === 0)
        } else if (leadTagFilter === 'Renegotiation') {
          filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.tier === 0 && lead.targetOutcome === 'renegotiation')
        } else {
          filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.tier === 0 && lead.targetOutcome === 'refinance')
        } 
      } else if (eventValue === 'Tier 1') {
        if (leadTagFilter === 'All' || !leadTagFilter) {
          filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.tier === 1)
        } else if (leadTagFilter === 'Renegotiation') {
          filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.tier === 1 && lead.targetOutcome === 'renegotiation')
        } else {
          filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.tier === 1 && lead.targetOutcome === 'refinance')
        }
      } else if (eventValue === 'Tier 2') {
        if (leadTagFilter === 'All' || !leadTagFilter) {
          filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.tier === 2)
        } else if (leadTagFilter === 'Renegotiation') {
          filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.tier === 2 && lead.targetOutcome === 'renegotiation')
        } else {
          filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.tier === 2 && lead.targetOutcome === 'refinance')
        }
      } else if (eventValue === 'Tier 3') {
        if (leadTagFilter === 'All' || !leadTagFilter) {
          filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.tier === 3)
        } else if (leadTagFilter === 'Renegotiation') {
          filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.tier === 3 && lead.targetOutcome === 'renegotiation')
        } else {
          filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.tier === 3 && lead.targetOutcome === 'refinance')
        }
      } else {
        if (leadTagFilter === 'All' || !leadTagFilter) {
    
        } else if (leadTagFilter === 'Renegotiation') {
          filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.targetOutcome === 'renegotiation')
        } else {
          filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.targetOutcome === 'refinance')
        }
      }
    } else {
      if (eventValue === 'Manual') {
        if (leadTagFilter === 'All' || !leadTagFilter) {
          filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.tier === 0 && lead.newAssignmentNotification === true)
        } else if (leadTagFilter === 'Renegotiation') {
          filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.tier === 0 && lead.newAssignmentNotification === true && lead.targetOutcome === 'renegotiation')
        } else {
          filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.tier === 0 && lead.newAssignmentNotification === true && lead.targetOutcome === 'refinance')
        } 
      } else if (eventValue === 'Tier 1') {
        if (leadTagFilter === 'All' || !leadTagFilter) {
          filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.tier === 1 && lead.newAssignmentNotification === true)
        } else if (leadTagFilter === 'Renegotiation') {
          filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.tier === 1 && lead.newAssignmentNotification === true && lead.targetOutcome === 'renegotiation')
        } else {
          filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.tier === 1 && lead.newAssignmentNotification === true && lead.targetOutcome === 'refinance')
        }
      } else if (eventValue === 'Tier 2') {
        if (leadTagFilter === 'All' || !leadTagFilter) {
          filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.tier === 2 && lead.newAssignmentNotification === true)
        } else if (leadTagFilter === 'Renegotiation') {
          filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.tier === 2 && lead.newAssignmentNotification === true && lead.targetOutcome === 'renegotiation')
        } else {
          filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.tier === 2 && lead.newAssignmentNotification === true && lead.targetOutcome === 'refinance')
        }
      } else if (eventValue === 'Tier 3') {
        if (leadTagFilter === 'All' || !leadTagFilter) {
          filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.tier === 3 && lead.newAssignmentNotification === true)
        } else if (leadTagFilter === 'Renegotiation') {
          filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.tier === 3 && lead.newAssignmentNotification === true && lead.targetOutcome === 'renegotiation')
        } else {
          filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.tier === 3 && lead.newAssignmentNotification === true && lead.targetOutcome === 'refinance')
        }
      } else {
        if (leadTagFilter === 'All' || !leadTagFilter) {
          filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.newAssignmentNotification === true)
        } else if (leadTagFilter === 'Renegotiation') {
          filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.newAssignmentNotification === true && lead.targetOutcome === 'renegotiation')
        } else {
          filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.newAssignmentNotification === true && lead.targetOutcome === 'refinance')
        }
      }
    }
    return filteredClosingLeads
  }
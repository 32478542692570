import React, { useState } from "react";
import { useTheme } from "@mui/styles";
import CircularProgress from "@mui/material/CircularProgress";
import { useDeleteMortgageMutation } from "../../../../slices/api/apiSlice";
import { Button, ButtonWrapper, FlexWrapper, ModalContentContainer, SubHeader } from "../../../../StyledComponents";

const DeleteMortgage = ({ handleCloseRepositoryModal, mortgageId, userId, teamId }) => {
  const theme = useTheme()

  const [deleteMortgage, { isFetching, isSuccess, isError }] = useDeleteMortgageMutation()

  const [mortgageIdToDelete, setMortgageIdToDelete] = useState(mortgageId)
  const [mortgageNotFound, setMortgageNotFound] = useState(false)

  const handleDelete = async () => {
    if (mortgageIdToDelete) {
      let response = await deleteMortgage({
        mortgageId: mortgageIdToDelete,
        userId: userId,
        teamId: teamId,
      })
      if (response.data.data.mortgageNotFound) {
        setMortgageNotFound(true)
      } else {
        setMortgageNotFound(false)
      }
    } else {
      setMortgageNotFound(true)
    }
    setMortgageIdToDelete(null)
  }

  return (
    <ModalContentContainer className="topColumn" style={{gridRow: '2/5', padding: '1rem 0.5rem 0 1rem'}}>
      <FlexWrapper className="column alignCenter justifyCenter">
        {mortgageNotFound ?
          <SubHeader className="modal">
            Mortgage not found.
          </SubHeader>
        : isSuccess ?
          <SubHeader className="modal">
            Mortgage records successfully removed.
          </SubHeader>
        : isError ?
          <SubHeader className="modal">
            A problem was encountered.
          </SubHeader>
        :
          <SubHeader className="modal">
            Remove all records for this mortgage?
          </SubHeader>
        }
        {isFetching ?
          <ButtonWrapper>
            <Button disabled className="deleteLoading">
              <CircularProgress
                sx={{ color: `${theme.palette.primary.buttonColor}` }}
                size={12}
              />
            </Button>
              <Button disabled onClick={() => handleCloseRepositoryModal()} className="secondary">
              Close
            </Button>
          </ButtonWrapper>
        : isSuccess || mortgageNotFound ?
          <Button onClick={() => handleCloseRepositoryModal()} className="secondary">
            Close
          </Button>
        :
          <ButtonWrapper>
            <Button onClick={() => handleDelete()} className="delete">
              Delete
            </Button>
            <Button onClick={() => handleCloseRepositoryModal()} className="secondary">
              Close
            </Button>
          </ButtonWrapper>
        }
      </FlexWrapper>
    </ModalContentContainer>
  )
}

export default DeleteMortgage;
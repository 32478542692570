import React, { useState, useRef } from "react";
import IconLogo from "../../icon.logo.black.png";
import ErrorIcon from "@mui/icons-material/Error";
import CityScape from "../../cityscape.jpg";
import FmdBadIcon from '@mui/icons-material/FmdBad';
import LoadingLogo from '../../icon.logo.green.png';
import { useTheme } from "@emotion/react";
import { useNavigate } from 'react-router-dom';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import { CircularProgress } from "@mui/material";
import { useLaunchTeamMutation } from "../../slices/api/apiSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectLaunchTeamEnabled, setLaunchTeamEnabled } from "../../slices/functionAvailabilitySlice";
import { AuthContainer, Button, Label, InputWrapper, PageContainer, StatusAndLabelWrapper, StyledInputElement, VerticalContainer, ScreenSaverContainer, AlertWrapper, ImageElements, ScannerOverlay, FlexWrapper, Typeography } from "../../StyledComponents";

const Launch = ({ setLaunchRequired }) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [launchTeam, {}] = useLaunchTeamMutation()

  const launchEnabled = useSelector(selectLaunchTeamEnabled)

  const newToken = useRef()

  const [launchSuccess, setLaunchSuccess] = useState(null);
  const [isFetching, setIsFetching] = useState(null);
  const [statusNewToken, setStatusNewToken] = useState(null);
  const [statusLaunched, setStatusLaunched] = useState(null);
  const [requestError, setRequestError] = useState(null);

  const handleInputChange = () => {
    if (statusNewToken === "invalid") {
      setStatusNewToken(null)
    }
    if (statusLaunched) {
      setStatusLaunched(null)
    }
    if (requestError) {
      setRequestError(null)
    }
    if (newToken.current.value.length === 0) {
      dispatch( setLaunchTeamEnabled(false) )
    } else if (newToken.current.value.length === 1) {
      dispatch( setLaunchTeamEnabled(true) )
    }
  }

  const handleSubmit = async () => {
    setIsFetching(true)
    if (newToken.current.value.includes('<') || newToken.current.value.includes('>') || newToken.current.value.length > 13) {
      setStatusNewToken('invalid')
    } else {
      let launchAttempt = await launchTeam({
        registrationToken: newToken.current.value,
      })
      if (launchAttempt.data.message.includes('launched')) {
        setStatusLaunched(true)
        setLaunchRequired(false)
        dispatch( setLaunchTeamEnabled(false) )
      } else if (launchAttempt.data.message.includes('Invalid')) {
        setStatusNewToken('invalid')
      } else {
        setLaunchSuccess(true)
        setLaunchRequired(false)
        dispatch( setLaunchTeamEnabled(false) )
      }
    }
    setIsFetching(false)
  }

  return (
    <PageContainer className="login">
      <ImageElements src={CityScape} className="loginBackground" />

      <ScreenSaverContainer className="login fadeOut">
        <ImageElements src={IconLogo} className="centerLogo opaque" />
        <ImageElements src={LoadingLogo} className="centerLogo loaded one" style={{right: '10rem'}} />
        <ImageElements src={LoadingLogo} className="centerLogo loaded two" style={{right: '10rem'}} />
        <ImageElements src={LoadingLogo} className="centerLogo loaded three" style={{right: '5rem'}} />
        <ImageElements src={LoadingLogo} className="centerLogo loaded four" style={{right: '5rem'}} />
        <ImageElements src={LoadingLogo} className="centerLogo loaded five" style={{}} />
        <ImageElements src={LoadingLogo} className="centerLogo loaded six" style={{}} />
        <ImageElements src={LoadingLogo} className="centerLogo loaded seven" style={{left: '5rem'}} />
        <ImageElements src={LoadingLogo} className="centerLogo loaded eight" style={{left: '5rem'}} />
        <ImageElements src={LoadingLogo} className="centerLogo loaded nine" style={{left: '10rem'}} />
        <ImageElements src={LoadingLogo} className="centerLogo loaded ten" style={{left: '10rem'}} />
      </ScreenSaverContainer>

      <ScreenSaverContainer className="login" >
        <ImageElements src={IconLogo} className="centerLogo opaque" />
      </ScreenSaverContainer>

      <ScannerOverlay className="radial" style={{gridRow: '1', gridColumn: '1/4'}} />
      <ScannerOverlay className="fadeOverlay" style={{gridRow: '1', gridColumn: '1/4'}} />
      <ScannerOverlay className="linearScan" style={{gridRow: '1', gridColumn: '1/4'}} />
      <ScannerOverlay className="linearScan thin" style={{gridRow: '1', gridColumn: '1/4', animation: 'bg 0.15s linear infinite'}} />
      <ScannerOverlay className="linearScan thin" style={{gridRow: '1', gridColumn: '1/4', animation: 'bg 0.45s linear infinite'}} />
      <ScannerOverlay className="linearScan thin" style={{gridRow: '1', gridColumn: '1/4', animation: 'bg 0.75s linear infinite'}} />

      <AuthContainer style={{gridColumn: '3', gridRow: '1', width: '40rem'}}>
        <VerticalContainer>
          <FlexWrapper className="column" style={{margin: '3rem auto 0 auto', height: 'fit-content', width: 'fit-content'}}>
            {statusLaunched || launchSuccess ?
              <></>
            :
              <Typeography className="fader exoItalic dark medium">
                Awaiting launch instructions...
              </Typeography>
            }
            <FlexWrapper>
              {statusLaunched && (
                <AlertWrapper className="caution twoLine">
                  <FlexWrapper className="column">
                    <FlexWrapper>
                      <FmdBadIcon />
                      Launch has occurred.
                    </FlexWrapper>
                    Navigate to login to proceed.
                  </FlexWrapper>
                </AlertWrapper>
              )}
              {launchSuccess && (
                <AlertWrapper className="success">
                  <RocketLaunchIcon />
                  Launch Success
                </AlertWrapper>
              )}
            </FlexWrapper>
          </FlexWrapper>
          <InputWrapper style={{margin: '2rem auto 4rem auto'}}>
            <StatusAndLabelWrapper>
              <Label className="colorLight" htmlFor="registrationToken">Registration Token</Label>
              {statusNewToken === "invalid" && (
                <AlertWrapper className="error">
                  <ErrorIcon />
                  Invalid
                </AlertWrapper>
              )}
              {requestError && (
                <AlertWrapper className="error">
                  <ErrorIcon />
                  Something went wrong
                </AlertWrapper>
              )}
            </StatusAndLabelWrapper>
            <StyledInputElement 
              autoComplete="off"
              type="password"
              name="registrationToken"
              id="registrationToken"
              className="auth"
              ref={newToken}
              onChange={() => handleInputChange()}
            />
          </InputWrapper>
          {statusLaunched || launchSuccess ?
            <Button
              className='secondary'
              onClick={() => navigate("/login")}
              style={{marginBottom: '2.5rem'}}
            >
              login
            </Button>
          :
            <Button
              className={isFetching ? 'primaryLoading' : (!launchEnabled || launchSuccess) ? 'primaryDisabled ' : 'primary'}
              disabled={isFetching || !launchEnabled || launchSuccess} 
              onClick={handleSubmit}
              style={{marginBottom: '2.5rem'}}
            >
              {isFetching ? (
                <CircularProgress 
                  sx={{color: `${theme.palette.common.white}`}}
                  size={12}
                />
              ) : (
                "Launch"
              )}
            </Button>
          }
        </VerticalContainer>
      </AuthContainer>
    </PageContainer>
  );
};

export default Launch;


export default async function handleLeadSortCategoryChanges (eventValue, leadMethodSort, leadsAwaitingAction, teamInvestigatingLeads, teamClosingLeads) {
  let awaitingAction = await handleAwaitingAction(eventValue, leadMethodSort, leadsAwaitingAction);
  let investigating = await handleInvestigating(eventValue, leadMethodSort, teamInvestigatingLeads);
  let closing = await handleClosing(eventValue, leadMethodSort, teamClosingLeads);
  return {awaitingAction, investigating, closing}
}

let handleAwaitingAction = async (eventValue, leadMethodSort, leadsAwaitingAction) => {
  if (eventValue === 'Date Discovered') {
    if (leadMethodSort === 'High-Low') {
      leadsAwaitingAction = [...leadsAwaitingAction].sort((a, b) => (a.dateDiscovered < b.dateDiscovered) ? 1 : (a.dateDiscovered > b.dateDiscovered) ? -1 : 0);
    } else {
      leadsAwaitingAction = [...leadsAwaitingAction].sort((a, b) => (a.dateDiscovered > b.dateDiscovered) ? 1 : (a.dateDiscovered < b.dateDiscovered) ? -1 : 0);
    }
  } else if (eventValue === 'Tier') {
    if (leadMethodSort === 'High-Low') {
      leadsAwaitingAction = [...leadsAwaitingAction].sort((a, b) => (a.tier > b.tier) ? 1 : (a.tier < b.tier) ? -1 : 0);
    } else {
      leadsAwaitingAction = [...leadsAwaitingAction].sort((a, b) => (a.tier < b.tier) ? 1 : (a.tier > b.tier) ? -1 : 0);
    }
  } else if (eventValue === 'Interest Increase') {
    if (leadMethodSort === 'High-Low') {
      leadsAwaitingAction = [...leadsAwaitingAction].sort((a, b) => (a.targetProfitNumber < b.targetProfitNumber) ? 1 : (a.targetProfitNumber > b.targetProfitNumber) ? -1 : 0);
    } else {
      leadsAwaitingAction = [...leadsAwaitingAction].sort((a, b) => (a.targetProfitNumber > b.targetProfitNumber) ? 1 : (a.targetProfitNumber < b.targetProfitNumber) ? -1 : 0);
    }
  } else if (eventValue === 'Percent Difference') {
    if (leadMethodSort === 'High-Low') {
      leadsAwaitingAction = [...leadsAwaitingAction].sort((a, b) => (a.targetProfitPercent < b.targetProfitPercent) ? 1 : (a.targetProfitPercent > b.targetProfitPercent) ? -1 : 0);
    } else {
      leadsAwaitingAction = [...leadsAwaitingAction].sort((a, b) => (a.targetProfitPercent > b.targetProfitPercent) ? 1 : (a.targetProfitPercent < b.targetProfitPercent) ? -1 : 0);
    }
  } else if (eventValue === 'Months Remaining') {
    if (leadMethodSort === 'High-Low') {
      leadsAwaitingAction = [...leadsAwaitingAction].sort((a, b) => (a.remainingMonths < b.remainingMonths) ? 1 : (a.remainingMonths > b.remainingMonths) ? -1 : 0);
    } else {
      leadsAwaitingAction = [...leadsAwaitingAction].sort((a, b) => (a.remainingMonths > b.remainingMonths) ? 1 : (a.remainingMonths < b.remainingMonths) ? -1 : 0);
    }
  }
  return leadsAwaitingAction
}
let handleInvestigating = async (eventValue, leadMethodSort, teamInvestigatingLeads) => {
  if (eventValue === 'Date Discovered') {
    if (leadMethodSort === 'High-Low') {
      teamInvestigatingLeads = [...teamInvestigatingLeads].sort((a, b) => (a.dateDiscovered < b.dateDiscovered) ? 1 : (a.dateDiscovered > b.dateDiscovered) ? -1 : 0);
    } else {
      teamInvestigatingLeads = [...teamInvestigatingLeads].sort((a, b) => (a.dateDiscovered > b.dateDiscovered) ? 1 : (a.dateDiscovered < b.dateDiscovered) ? -1 : 0);
    }
  } else if (eventValue === 'Tier') {
    if (leadMethodSort === 'High-Low') {
      teamInvestigatingLeads = [...teamInvestigatingLeads].sort((a, b) => (a.tier > b.tier) ? 1 : (a.tier < b.tier) ? -1 : 0);
    } else {
      teamInvestigatingLeads = [...teamInvestigatingLeads].sort((a, b) => (a.tier < b.tier) ? 1 : (a.tier > b.tier) ? -1 : 0);
    }
  } else if (eventValue === 'Interest Increase') {
    if (leadMethodSort === 'High-Low') {
      teamInvestigatingLeads = [...teamInvestigatingLeads].sort((a, b) => (a.targetProfitNumber < b.targetProfitNumber) ? 1 : (a.targetProfitNumber > b.targetProfitNumber) ? -1 : 0);
    } else {
      teamInvestigatingLeads = [...teamInvestigatingLeads].sort((a, b) => (a.targetProfitNumber > b.targetProfitNumber) ? 1 : (a.targetProfitNumber < b.targetProfitNumber) ? -1 : 0);
    }
  } else if (eventValue === 'Percent Difference') {
    if (leadMethodSort === 'High-Low') {
      teamInvestigatingLeads = [...teamInvestigatingLeads].sort((a, b) => (a.targetProfitPercent < b.targetProfitPercent) ? 1 : (a.targetProfitPercent > b.targetProfitPercent) ? -1 : 0);
    } else {
      teamInvestigatingLeads = [...teamInvestigatingLeads].sort((a, b) => (a.targetProfitPercent > b.targetProfitPercent) ? 1 : (a.targetProfitPercent < b.targetProfitPercent) ? -1 : 0);
    }
  } else if (eventValue === 'Months Remaining') {
    if (leadMethodSort === 'High-Low') {
      teamInvestigatingLeads = [...teamInvestigatingLeads].sort((a, b) => (a.remainingMonths < b.remainingMonths) ? 1 : (a.remainingMonths > b.remainingMonths) ? -1 : 0);
    } else {
      teamInvestigatingLeads = [...teamInvestigatingLeads].sort((a, b) => (a.remainingMonths > b.remainingMonths) ? 1 : (a.remainingMonths < b.remainingMonths) ? -1 : 0);
    }
  }
  return teamInvestigatingLeads
}
let handleClosing = async (eventValue, leadMethodSort, teamClosingLeads) => {
  if (eventValue === 'Date Discovered') {
    if (leadMethodSort === 'High-Low') {
        teamClosingLeads = [...teamClosingLeads].sort((a, b) => (a.dateDiscovered < b.dateDiscovered) ? 1 : (a.dateDiscovered > b.dateDiscovered) ? -1 : 0);
    } else {
        teamClosingLeads = [...teamClosingLeads].sort((a, b) => (a.dateDiscovered > b.dateDiscovered) ? 1 : (a.dateDiscovered < b.dateDiscovered) ? -1 : 0);
    }
  } else if (eventValue === 'Tier') {
    if (leadMethodSort === 'High-Low') {
      teamClosingLeads = [...teamClosingLeads].sort((a, b) => (a.tier > b.tier) ? 1 : (a.tier < b.tier) ? -1 : 0);
    } else {
      teamClosingLeads = [...teamClosingLeads].sort((a, b) => (a.tier < b.tier) ? 1 : (a.tier > b.tier) ? -1 : 0);
    }
  } else if (eventValue === 'Interest Increase') {
    if (leadMethodSort === 'High-Low') {
        teamClosingLeads = [...teamClosingLeads].sort((a, b) => (a.targetProfitNumber < b.targetProfitNumber) ? 1 : (a.targetProfitNumber > b.targetProfitNumber) ? -1 : 0);
    } else {
        teamClosingLeads = [...teamClosingLeads].sort((a, b) => (a.targetProfitNumber > b.targetProfitNumber) ? 1 : (a.targetProfitNumber < b.targetProfitNumber) ? -1 : 0);
    }
  } else if (eventValue === 'Percent Difference') {
    if (leadMethodSort === 'High-Low') {
        teamClosingLeads = [...teamClosingLeads].sort((a, b) => (a.targetProfitPercent < b.targetProfitPercent) ? 1 : (a.targetProfitPercent > b.targetProfitPercent) ? -1 : 0);
    } else {
        teamClosingLeads = [...teamClosingLeads].sort((a, b) => (a.targetProfitPercent > b.targetProfitPercent) ? 1 : (a.targetProfitPercent < b.targetProfitPercent) ? -1 : 0);
    }
  } else if (eventValue === 'Months Remaining') {
    if (leadMethodSort === 'High-Low') {
        teamClosingLeads = [...teamClosingLeads].sort((a, b) => (a.remainingMonths < b.remainingMonths) ? 1 : (a.remainingMonths > b.remainingMonths) ? -1 : 0);
    } else {
        teamClosingLeads = [...teamClosingLeads].sort((a, b) => (a.remainingMonths > b.remainingMonths) ? 1 : (a.remainingMonths < b.remainingMonths) ? -1 : 0);
    }
  }
  return teamClosingLeads
}
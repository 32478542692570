import React, { PureComponent } from 'react';
import { useTheme } from "@mui/styles";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const CustomTooltip = ({ active, payload }) => {
    const theme = useTheme();

    if (active && payload && payload.length) {
      return (
        <div style={{backgroundColor: `${theme.palette.common.cardBackground}`, padding: "10px", width: "25rem", borderRadius: ".5rem", border: `0.1rem solid ${theme.palette.common.black}`}}>
          <div style={{margin: "0", display: "flex", justifyContent: "space-between"}}>
            {`Date: `}
            <span style={{color: `${theme.palette.common.black}`}}>
              {`${payload[0].payload.closeDateLabel}`}
            </span>
          </div>
          <div style={{margin: "0.5rem 0 0 0", display: "flex", justifyContent: "space-between", fontWeight: "600"}}>
            {`Original Mortgage`}
          </div>
          <div style={{margin: "0", display: "flex", justifyContent: "space-between"}}>
            {`Origination Date: `}
            <span style={{color: `${theme.palette.common.black}`}}>
              {`${payload[0].payload.originalOriginationDate}`}
            </span>
          </div>
          <div style={{margin: "0", display: "flex", justifyContent: "space-between"}}>
            {`End Date: `}
            <span style={{color: `${theme.palette.common.black}`}}>
              {`${payload[0].payload.originalEndDate}`}
            </span>
          </div>
          <div style={{margin: "0.5rem 0 0 0", display: "flex", justifyContent: "space-between"}}>
            {`Remaining Term: `}
            <div style={{margin: "0", color: `${theme.palette.common.black}`, display: "flex", flexDirection: 'column', alignItems: "flex-end"}}>
              <span>
                {`${payload[0].payload.remainingTerm}`}
              </span>
            </div>
          </div>
          <div style={{margin: "0", display: "flex", justifyContent: "space-between"}}>
            {`Loan Amount: `}
            <span style={{color: `${theme.palette.common.black}`}}>
              {`$ ${payload[0].payload.originalPrincipalDue.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}`}
            </span>
          </div>
          <div style={{margin: "0", display: "flex", justifyContent: "space-between"}}>
            {`Rate: `}
            <span style={{color: `${theme.palette.common.black}`}}>
              {`${payload[0].payload.originalInterestRate.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})} %`}
            </span>
          </div>
          <div style={{margin: "0", display: "flex", justifyContent: "space-between"}}>
            {`Principal Remaining: `}
            <span style={{color: `${theme.palette.common.black}`}}>
              {`$ ${payload[0].payload.remainingPrincipal.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}`}
            </span>
          </div>
          <div style={{margin: "0", display: "flex", justifyContent: "space-between"}}>
            {`Interest Remaining: `}
            <span style={{color: `${theme.palette.common.black}`}}>
              {`$ ${payload[0].payload.remainingInterest.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}`}
            </span>
          </div>
          <div style={{margin: "0.5rem 0 0 0", display: "flex", justifyContent: "space-between", fontWeight: "600"}}>
            {`New Mortgage:`}
          </div>
          <div style={{margin: "0", display: "flex", justifyContent: "space-between"}}>
            {`Origination Date: `}
            <span style={{color: `${theme.palette.common.black}`}}>
              {`${payload[0].payload.newOriginationDate}`}
            </span>
          </div>
          <div style={{margin: "0", display: "flex", justifyContent: "space-between"}}>
            {`End Date: `}
            <span style={{color: `${theme.palette.common.black}`}}>
              {`${payload[0].payload.newEndDate}`}
            </span>
          </div>
          <div style={{margin: "0", display: "flex", justifyContent: "space-between"}}>
            {`Loan Amount: `}
            <span style={{color: `${theme.palette.common.black}`}}>
              {`$ ${payload[0].payload.newPrincipal.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}`}
            </span>
          </div>
          <div style={{margin: "0", display: "flex", justifyContent: "space-between"}}>
            {`Rate: `}
            <span style={{color: `${theme.palette.common.black}`}}>
              {`${payload[0].payload.newInterestRate.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})} %`}
            </span>
          </div>
          <div style={{margin: "0", display: "flex", justifyContent: "space-between"}}>
            {`Interest Due: `}
            <span style={{color: `${theme.palette.common.black}`}}>
              {`$ ${payload[0].payload.newInterestDue.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}`}
            </span>
          </div>
          <div style={{margin: "0.5rem 0 0 0", display: "flex", justifyContent: "space-between"}}>
            {`Profit Amount: `}
            <span style={{color: `${theme.palette.primary.main}`}}>
              {`$ ${payload[0].payload.profitAmount.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}`}
            </span>
          </div>
          <div style={{margin: "0", display: "flex", justifyContent: "space-between"}}>
            {`Revenue Increase: `}
            <span>
              {`${payload[0].payload.profitPercent.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})} %`}
            </span>
          </div>
          <div style={{margin: "0.5rem 0 0 0", display: "flex", justifyContent: "space-between", fontWeight: "600"}}>
            {`Profit Totals:`}
          </div>
          <div style={{margin: "0", display: "flex", justifyContent: "space-between"}}>
            {`Team: `}
            <div style={{margin: "0", color: `${theme.palette.common.black}`, display: "flex", flexDirection: 'column', alignItems: "flex-end"}}>
              <span style={{color: `${theme.palette.secondary.main}`}}>
                {`$ ${payload[0].payload.teamTotalProfitAmount.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}`}
              </span>
              <span>
                {`${payload[0].payload.teamTotalProfitPercent.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})} %`}
              </span>
            </div>
          </div>
        </div>
      );
    }

    return null;
  };

export default class ProfitTrackerChart extends PureComponent {

  render() {
    return (
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          width={500}
          height={300}
          data={this.props.closures}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <defs>
            <linearGradient id="team" x1="0" y1="0" x2="0" y2="1">
              <stop offset="1%" stopColor="#B486BF" stopOpacity={0.1}/>
              <stop offset="95%" stopColor="#000" stopOpacity={0}/>
            </linearGradient>
            <linearGradient id="closure" x1="0" y1="0" x2="0" y2="1">
              <stop offset="1%" stopColor="#00940F" stopOpacity={0.1}/>
              <stop offset="95%" stopColor="#000" stopOpacity={0}/>
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="1 20" />
          <XAxis dataKey="-" />
          <YAxis 
            yAxisId='left'
            type='number'
            domain={[0, dataMax => (Math.round((dataMax * 2)/10000)*10000)]}
            tickFormatter={tick => {
              return `$${tick.toLocaleString('en-US')}`
            }}
            />
          <YAxis 
            yAxisId='right'
            orientation='right'
            tickFormatter={tick => {
              return `$${tick.toLocaleString('en-US')}`
            }}
          />
          <Tooltip 
            content={<CustomTooltip />} 
            wrapperStyle={{outline: "none"}}
          />
          <Legend />
          <Area yAxisId='left' type="monotone" dataKey="profitAmount" name="Profit Increase per Closure" stroke="#00940F" fillOpacity={1} fill="url(#closure)" />
          <Area yAxisId='right' type="monotone" dataKey="teamTotalProfitAmount" name="Team Total" stroke="#B486BF" fillOpacity={1} fill="url(#team)" />
        </AreaChart>
      </ResponsiveContainer>
    );
  }
}

import React from "react";
import Tooltip from '@mui/material/Tooltip';
import EmailIcon from '@mui/icons-material/Email';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTheme } from "@mui/styles";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { CardIndex, CompareLink, Detail, DetailContainer, DetailInfoContainer, DetailListContainer } from "../../../../../../StyledComponents";

const Finalized = ({ notification, handleStatusChange, handleOpenDeleteModal, handleOpenNotesModal, cardIndex }) => {
  const theme = useTheme();

  return (
    <>
      <div style={{display: 'flex'}}>
        <CardIndex>
          {cardIndex+1}
        </CardIndex>
        <DetailContainer className="notificationTitle">
          <div style={{width: '6rem', margin: '0 0 0 0.5rem', display: 'flex', justifyContent: 'space-between'}}>
            {cardIndex === 0 ?
              <CompareLink autoFocus onClick={() => handleOpenDeleteModal()} style={{margin: '0'}} className="notification">
                <DeleteIcon style={{height: '1rem'}} />
              </CompareLink>
            :
              <CompareLink onClick={() => handleOpenDeleteModal()} style={{margin: '0'}} className="notification">
                <DeleteIcon style={{height: '1rem'}} />
              </CompareLink>
            }
            {notification.status !== "new" ?
              <Tooltip
                componentsProps={{tooltip: {sx: {color: `${theme.palette.common.white}`, backgroundColor: `${theme.palette.common.black}`}}}}
                title={<div><p style={{margin: "0.2rem"}}>Mark as "New"</p></div>} 
                placement="top"
                arrow
              >
                <CompareLink onClick={() => handleStatusChange(notification.status, true)} style={{margin: '0'}} className="notification">
                  <EmailIcon style={{height: '1rem'}} />
                </CompareLink>
              </Tooltip>
            :
              <></>
            }
          </div>
          <div style={{display: 'flex', margin: '0.5rem 0 0 0'}}>
            <DetailInfoContainer className="notificationTitle">
              <Detail className="subject">subject:</Detail>
            </DetailInfoContainer>
            <DetailInfoContainer className="notificationTitle">
              <Detail className="notificationTitle">Investigation Finalized</Detail>
            </DetailInfoContainer>
          </div>
          <div style={{display: 'flex'}}>
            <DetailInfoContainer className="notificationTitle">
              <Detail className="date">initiated by:</Detail>
            </DetailInfoContainer>
            <DetailInfoContainer className="notificationTitle">
              <Detail className="date">{notification.updatedBy}</Detail>
            </DetailInfoContainer>
          </div>
          <div style={{display: 'flex'}}>
            <DetailInfoContainer className="notificationTitle">
              <Detail className="date">date:</Detail>
            </DetailInfoContainer>
            <DetailInfoContainer className="notificationTitle">
              <Detail className="date">{notification.date}</Detail>
            </DetailInfoContainer>
          </div>
          <CompareLink onClick={() => handleOpenNotesModal(notification.belongsToMortgage)} style={{margin: '0'}} >
            View Notes <OpenInNewIcon style={{height: '1rem'}} />
          </CompareLink>
        </DetailContainer>
      </div>
        <DetailContainer style={{width: 'fit-content', minWidth: '20rem'}}>
          <div style={{height: 'fit-content'}}>
            <Detail className="header">Mortgage</Detail>
          </div>
          <DetailListContainer>
            <DetailInfoContainer className="item">
              {notification.belongsToMortgage.Owner2FullName ?
                <>
                  <Detail className="item">Borrowers:</Detail>
                  <Detail></Detail>
                </>
              :
                <Detail className="item">Borrower:</Detail>
              }
              <Detail className="item">Parcel Number:</Detail>
            </DetailInfoContainer>
            <DetailInfoContainer>
              <Detail>{notification.belongsToMortgage.Owner1FullName}</Detail>
              {notification.belongsToMortgage.Owner2FullName ?
                <Detail>{notification.belongsToMortgage.Owner2FullName}</Detail>
                :
                <></>
              }
              <Detail>{notification.belongsToMortgage.ParcelNumber}</Detail>
            </DetailInfoContainer>
          </DetailListContainer>
        </DetailContainer>
      {notification.targetOutcome === 'Refinance' ?
        <>
          <DetailContainer style={{minWidth: '20rem'}}>
            <div style={{height: 'fit-content'}}>
              <Detail className="header">Details</Detail>
            </div>
            <DetailListContainer>
              <DetailInfoContainer className="item">
                <Detail className="item">Target Outcome:</Detail>
                <Detail className="item">Assignees:</Detail>
              </DetailInfoContainer>
              <DetailInfoContainer>
                <Detail>{notification.targetOutcome}</Detail>
                {notification.assignees.map((fullName, index) => (
                  <Detail key={index}>{fullName}</Detail>
                ))}
              </DetailInfoContainer>
            </DetailListContainer>
          </DetailContainer>
          <DetailContainer className="target" style={{minWidth: '20rem'}}>
            <DetailListContainer>
              <DetailInfoContainer className="potential item">
                <Detail className="potential">Finalized interest owed:</Detail>
                <Detail className="potential">Percent increase:</Detail>
              </DetailInfoContainer>
              <DetailInfoContainer>
                <Detail className="target">${notification.finalizedProfit.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}</Detail>
                <Detail className="target">{notification.finalizedProfitPercent.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}%</Detail>
              </DetailInfoContainer>
            </DetailListContainer>
          </DetailContainer>
        </>
      :
        <DetailContainer style={{minWidth: '20rem', width: '45%', justifyContent: 'flex-start'}}>
          <div style={{height: 'fit-content'}}>
            <Detail className="header">Details</Detail>
          </div>
          <DetailListContainer>
            <DetailInfoContainer className="item">
              <Detail className="item">Target Outcome:</Detail>
              <Detail className="item">Assignees:</Detail>
            </DetailInfoContainer>
            <DetailInfoContainer>
              <Detail>{notification.targetOutcome}</Detail>
              {notification.assignees.map((fullName, index) => (
                <Detail key={index}>{fullName}</Detail>
              ))}
            </DetailInfoContainer>
          </DetailListContainer>
        </DetailContainer>
      }
    </>
  )
}

export default Finalized;
import React, { useState } from "react"
import InfoIcon from '@mui/icons-material/Info';
import DeleteIcon from '@mui/icons-material/Delete';
import { Dialog } from "@mui/material";
import DeleteModal from "./DeleteModal/DeleteModal";
import UploadReportsModal from "./ReportModal/UploadReportsModal";
import { selectTeamUploadReports } from "../../../../../../slices/teamAdminDataSlice";
import { useSelector, useDispatch } from "react-redux";
import { setUploadReportNavMenuSelection } from "../../../../../../slices/navMenuSelectionsSlice";
import { ActionIconWrapper, ColumnContent, ComponentBorder, ComponentContainer, ComponentSectionRow, FlexWrapper, RowsContainer } from "../../../../../../StyledComponents";

const Reports = ({ userId, teamId }) => {
  const dispatch = useDispatch()

  const teamUploadReports = useSelector(selectTeamUploadReports)

  const [viewingReport, setViewingReport] = useState(false)
  const [openUploadReportModal, setOpenUploadReportModal] = useState(false)
  const [openDeleteUploadReportModal, setOpenDeleteUploadReportModal] = useState(false)

  const handleOpenUploadReportModal = (report) => {
    setViewingReport(report)
    dispatch( setUploadReportNavMenuSelection('summary'))
    setOpenUploadReportModal(true)
  }
  const handleCloseUploadReportModal = () => {
    setOpenUploadReportModal(false)
  }

  const handleOpenDeleteUploadReportModal = (report) => {
    setViewingReport(report)
    dispatch( setUploadReportNavMenuSelection('summary'))
    setOpenDeleteUploadReportModal(true)
  }
  const handleCloseDeleteUploadReportModal = () => {
    setOpenDeleteUploadReportModal(false)
  }

  return (
    <>
      <ComponentContainer style={{height: '49%'}}>
        <FlexWrapper className="column" style={{gridRow: '2', gridColumn: '2'}}>
          <ComponentSectionRow className="uploadReports header">
            <ColumnContent className="header" style={{gridColumn: '1'}}>
              Index
            </ColumnContent>
            <ColumnContent className="header" style={{gridColumn: '2'}}>
              Activity
            </ColumnContent>
            <ColumnContent className="header" style={{gridColumn: '3'}}>
              Type
            </ColumnContent>
            <ColumnContent className="header" style={{gridColumn: '4'}}>
              Date
            </ColumnContent>
            <ColumnContent className="header" style={{gridColumn: '5'}}>
              Successful
            </ColumnContent>
            <ColumnContent className="header" style={{gridColumn: '6'}}>
              Errored
            </ColumnContent>
          </ComponentSectionRow>
          <RowsContainer style={{height: '100%'}}>
            {teamUploadReports.toReversed().map((row, index) => (
              <ComponentSectionRow key={index} className={(index%2 === 1 ? 'even ' : '') + 'uploadReports'}>
                <ColumnContent className="detail small" style={{gridColumn: '1'}}>
                  {index + 1}
                </ColumnContent>
                <FlexWrapper className="justifyCenter" style={{gridColumn: '2'}}>
                  <ActionIconWrapper className={(index%2 === 1 ? 'even ' : '') + 'hover'}>
                    <InfoIcon onClick={() => handleOpenUploadReportModal(row)} style={{height: '2rem', width: '2rem'}} />
                  </ActionIconWrapper>
                  <ActionIconWrapper className={(index%2 === 1 ? 'even ' : '') + 'hover'}>
                    <DeleteIcon onClick={() => handleOpenDeleteUploadReportModal(row)} style={{height: '2rem', width: '2rem'}} />
                  </ActionIconWrapper>
                </FlexWrapper>
                <ColumnContent className="detail small" style={{gridColumn: '3'}}>
                  {row.uploadType}
                </ColumnContent>
                <ColumnContent className="detail small" style={{gridColumn: '4'}}>
                  {row.date}
                </ColumnContent>
                <ColumnContent className="detail small" style={{gridColumn: '5'}}>
                  {row.numberSuccessess}
                </ColumnContent>
                <ColumnContent className="detail small" style={{gridColumn: '6'}}>
                  {row.numberErrors}
                </ColumnContent>
              </ComponentSectionRow>
            ))}
          </RowsContainer>
        </FlexWrapper>

        <ComponentBorder className="top" style={{gridRow:'1', gridColumn: '1/4'}} />
        <ComponentBorder className="left" style={{gridRow:'2', gridColumn: '1'}} />
        <ComponentBorder className="right"  style={{gridRow:'2', gridColumn: '3'}} />
        <ComponentBorder className="bottom" style={{gridRow:'3', gridColumn: '1/4'}} />
      </ComponentContainer>

      <Dialog open={openUploadReportModal} onClose={handleCloseUploadReportModal} 
        PaperProps={{ sx: {width: "110rem", minWidth: "110rem", maxWidth: "110rem", height: "50rem", minHeight: "fit-content", maxHeight: "95%"}}}
      >
        <UploadReportsModal handleCloseUploadReportModal={handleCloseUploadReportModal} viewingReport={viewingReport} />
      </Dialog>
      <Dialog open={openDeleteUploadReportModal} onClose={handleCloseDeleteUploadReportModal} 
        PaperProps={{ sx: {width: "40rem", minWidth: "40rem", maxWidth: "40rem", height: "15rem", minHeight: "15rem", maxHeight: "15rem"}}}
      >
        <DeleteModal closeModal={handleCloseDeleteUploadReportModal} viewingReport={viewingReport} userId={userId} teamId={teamId} />
      </Dialog>
    </>
  )
}

export default Reports;
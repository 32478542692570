import React from "react";
import { useTheme } from "@mui/styles";
import AssignOrCreateTag from "./AssignOrCreateTag";
import { AccentInfo, ComponentSectionRow, ModalContentContainer, FlexWrapper, CardButton, ListRow, CompareDetail, ScrollWindow } from "../../../../../StyledComponents";

const EditTags = ({ leadData, userFullName, teamId, dashPerspective, userId, activeLead, mortgageData }) => {
  const theme = useTheme()

  return (
    <ModalContentContainer className="topColumn" style={{gridRow: '2/5', overflow: 'hidden'}}>
      <ComponentSectionRow className="header gray">
        <ul style={{padding: '0.125rem 0', marginLeft: '3rem'}}>
          <li>"<span style={{fontWeight: '600'}}>violating fields</span>" are the specific fields where detected discrepancies triggered the assignment of a tag</li>
        </ul>
      </ComponentSectionRow>
      <ScrollWindow className="verticalOnly" style={{width: "100%"}}>
        <ListRow style={{marginBottom: '1rem'}}>
          <CompareDetail className="section header" style={{gridColumn: '1/7'}}>
            Scan Assigned Lead Tags
          </CompareDetail>
        </ListRow>
        {leadData.tags.length > 0 &&
          <>
            {leadData.tags.map((tag, index) => (tag.status === 'query' &&
              <ComponentSectionRow key={index} style={{width: '90%', margin: '0 auto'}}>
                <FlexWrapper className="column" style={{margin: '0 0 0 1rem'}}>
                  <FlexWrapper className="alignCenter">
                    <div style={{marginRight: '1rem', fontWeight: '600'}}>
                      <CardButton className="horizontal noHover" style={{width: 'fit-content'}}>
                        <span>{tag.label}</span>
                      </CardButton>
                    </div>
                    <span style={{fontWeight: '600'}}>{tag.description}</span>
                  </FlexWrapper>
                  <FlexWrapper style={{width: '60rem', flexWrap: 'wrap', margin: '0.5rem 0 1rem 3rem'}}>
                    <span style={{fontWeight: '600'}}>violating fields:</span>
                    {tag.discrepancyFields.map((field, index) => (
                      index === (tag.discrepancyFields.length - 1) ?
                        <div key={index} style={{margin: '0 0 0 0.6rem', fontWeight: '300'}}>
                          {field}
                        </div>
                      :
                        <div key={index} style={{margin: '0 0 0 0.6rem', fontWeight: '300'}}>
                          {field},
                        </div>
                    ))}
                  </FlexWrapper>
                </FlexWrapper>
              </ComponentSectionRow>
            ))}
            {leadData.tagAdded &&
              <>
                <ListRow style={{margin: '0.3rem 0 1rem 0'}}>
                  <CompareDetail className="section header" style={{gridColumn: '1/7'}}>
                    Manually Assigned Lead Tags
                  </CompareDetail>
                </ListRow>
                {leadData.tags.map((tag, index) => (tag.status === 'manual' &&
                  <ComponentSectionRow key={index} style={{width: '90%', margin: '0 auto'}}>
                    <FlexWrapper className="column" style={{margin: '0 0 0 1rem'}}>
                      <FlexWrapper className="alignCenter" style={{margin: '0 0 1rem 0'}}>
                        <div style={{marginRight: '1rem', fontWeight: '600'}}>
                          <CardButton className="horizontal noHover" style={{width: 'fit-content'}}>
                            <span>{tag.label}</span>
                          </CardButton>
                        </div>
                        <span style={{fontWeight: '600'}}>{tag.description}</span>
                      </FlexWrapper>
                      <FlexWrapper style={{width: '60rem', flexWrap: 'wrap', margin: '0.5rem 0 1rem 3rem'}}>
                        <span style={{fontWeight: '600'}}>violating fields:</span>
                        {tag.discrepancyFields.map((field, index) => (
                          index === (tag.discrepancyFields.length - 1) ?
                            <div key={index} style={{margin: '0 0 0 0.6rem', fontWeight: '300'}}>
                              {field}
                            </div>
                          :
                            <div key={index} style={{margin: '0 0 0 0.6rem', fontWeight: '300'}}>
                              {field},
                            </div>
                        ))}
                      </FlexWrapper>
                    </FlexWrapper>
                  </ComponentSectionRow>
                ))}
              </>
            }
          </>
        }
        {leadData.tags.length === 0 ?
          <ComponentSectionRow>
            <AccentInfo style={{margin: '1rem 0 0 3rem'}}>-no lead tags-</AccentInfo>
          </ComponentSectionRow>
        :
          <></>
        }
        {mortgageData.tags.length > 0 &&
          <>
            <ListRow style={{margin: '0.3rem 0 1rem 0'}}>
              <CompareDetail className="section header" style={{gridColumn: '1/7'}}>
                Automatically Assigned Mortgage Tags 
              </CompareDetail>
            </ListRow>
            {mortgageData.tags.map((tag, index) => (
            tag.status === 'query' &&
              <ComponentSectionRow key={index} style={{width: '90%', margin: '0 auto'}}>
                <FlexWrapper className="column" style={{margin: '0 0 0 1rem'}}>
                  <FlexWrapper className="alignCenter">
                    <div style={{marginRight: '1rem', fontWeight: '600'}}>
                      <CardButton className="horizontal noHover" style={{width: 'fit-content'}}>
                        <span>{tag.label}</span>
                      </CardButton>
                    </div>
                    <span style={{fontWeight: '600'}}>{tag.description}</span>
                  </FlexWrapper>
                  <FlexWrapper style={{width: '60rem', flexWrap: 'wrap', margin: '0.5rem 0 1rem 3rem'}}>
                    <span style={{fontWeight: '600'}}>violating fields:</span>
                    {tag.discrepancyFields.map((field, index) => (
                      index === (tag.discrepancyFields.length - 1) ?
                        <div key={index} style={{margin: '0 0 0 0.6rem', fontWeight: '300'}}>
                          {field}
                        </div>
                      :
                        <div key={index} style={{margin: '0 0 0 0.6rem', fontWeight: '300'}}>
                          {field},
                        </div>
                    ))}
                  </FlexWrapper>
                </FlexWrapper>
              </ComponentSectionRow>
            ))}
            {mortgageData.tagAdded &&
              <>
                <ListRow style={{margin: '0.3rem 0 1rem 0'}}>
                  <CompareDetail className="section header" style={{gridColumn: '1/7'}}>
                    Manually Assigned Mortgage Tags
                  </CompareDetail>
                </ListRow>
                {mortgageData.tags.map((tag, index) => (
                tag.status === 'manual' &&
                  <ComponentSectionRow key={index} style={{width: '90%', margin: '0 auto'}}>
                    <FlexWrapper className="column" style={{margin: '0 0 0 1rem'}}>
                      <FlexWrapper className="alignCenter" style={{margin: '0 0 1rem 0'}}>
                        <div style={{marginRight: '1rem', fontWeight: '600'}}>
                          <CardButton className="horizontal noHover" style={{width: 'fit-content'}}>
                            <span>{tag.label}</span>
                          </CardButton>
                        </div>
                        <span style={{fontWeight: '600'}}>{tag.description}</span>
                      </FlexWrapper>
                      <FlexWrapper style={{width: '60rem', flexWrap: 'wrap', margin: '0.5rem 0 1rem 3rem'}}>
                        <span style={{fontWeight: '600'}}>violating fields:</span>
                        {tag.discrepancyFields.map((field, index) => (
                          index === (tag.discrepancyFields.length - 1) ?
                            <div key={index} style={{margin: '0 0 0 0.6rem', fontWeight: '300'}}>
                              {field}
                            </div>
                          :
                            <div key={index} style={{margin: '0 0 0 0.6rem', fontWeight: '300'}}>
                              {field},
                            </div>
                        ))}
                      </FlexWrapper>
                    </FlexWrapper>
                  </ComponentSectionRow>
                ))}
              </>
            }
          </>
        }
        {mortgageData.tags.length === 0 ?
          <ComponentSectionRow>
            <AccentInfo style={{margin: '1rem 0 0 3rem'}}>-no mortgage tags-</AccentInfo>
          </ComponentSectionRow>
        :
          <div style={{height: '3rem'}} />
        }
      </ScrollWindow>
      <AssignOrCreateTag leadData={leadData} userFullName={userFullName} teamId={teamId} dashPerspective={dashPerspective} userId={userId} activeLead={activeLead} mortgageData={mortgageData} />
    </ModalContentContainer>
  )
}

export default EditTags;
import React from "react";
import { makeStyles } from "@mui/styles";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { useTheme } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  formControl: {
    gridColumn: 2,
    padding: '0 0 0 1rem',
    "& .MuiInputBase-root": {
        borderRadius: "1rem",
        border: `0.1rem solid ${theme.palette.common.grayBorder}`,
        color: `${theme.palette.common.black}`,
        width: "12rem",
        height: "3rem",
      },
      "& .Mui-disabled svg": {
        color: `${theme.palette.common.grayBorder}`,
      },
      "& .MuiSelect-select.MuiSelect-select": {
        minHeight: "3rem",
        display: "flex",
        alignItems: "center",
        padding: '0 4rem 0 0'
    },
    "& .Mui-focused": {
    },
    "& .MuiInputBase-root:hover": {
      border: `0.1rem solid ${theme.palette.primary.main}`,
    },
    "& .MuiInputBase-root:hover svg": {
    },
    "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
    }
  },
  select: {
    fontSize: "1.0rem",
    color: `${theme.palette.common.black}`,
    padding: "0",
    display: "flex",
    justifyContent: "center"
  },
  selectIcon: {
    color: `${theme.palette.primary.main}`,
  },
  paper: {
    background: `${theme.palette.common.white}`,
    marginTop: "0.5rem",
  },
  list: {
    maxHeight: "20rem",
    "& li": {
    background: `${theme.palette.common.white}`,
    borderBottom: `0.2rem solid ${theme.palette.common.grayBorder}`,
    borderTop: `0.2rem solid ${theme.palette.common.grayBorder}`,
    color: `${theme.palette.common.black}`,
    justifyContent: "center",
    overflow: "scroll"
    },
    "& li:hover": {
      background: `${theme.palette.common.grayHover}`,
    },
    "& li.Mui-selected:hover": {
      background: `${theme.palette.secondary.light}`,
    },
    "& li.Mui-selected": {
      background: `${theme.palette.secondary.lightest}`,
    },
  }
}));

const FilterTierDropdown = ({ value, handleChange }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const menuProps = {
    classes: {
      list: classes.list,
      paper: classes.paper
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center"
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center"
    },
  };

  return (
    <FormControl className={classes.formControl}>
      <Select
        value={value}
        onChange={handleChange}
        MenuProps={menuProps}
        classes={{
          select: classes.select,
          icon: classes.selectIcon
        }}
      >
        <MenuItem
          disabled={value === 'All'}
          value={'All'}
        >
          All
        </MenuItem>
        <MenuItem
          disabled={value === 'Manual'}
          value={'Manual'}
        >
          Manual
        </MenuItem>
        <MenuItem
          disabled={value === 'Tier 1'}
          value={'Tier 1'}
        >
          Tier 1
        </MenuItem>
        <MenuItem
          disabled={value === 'Tier 2'}
          value={'Tier 2'}
        >
          Tier 2
        </MenuItem>
        <MenuItem
          disabled={value === 'Tier 3'}
          value={'Tier 3'}
        >
          Tier 3
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default FilterTierDropdown;
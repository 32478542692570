import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import 'react-csv-importer/dist/index.css';
import "../../../../../styles.css";
import Tooltip from '@mui/material/Tooltip';
import ErrorIcon from "@mui/icons-material/Error";
import CheckIcon from '@mui/icons-material/Check';
import { useTheme } from "@mui/styles";
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import CircularProgress from "@mui/material/CircularProgress";
import { tooltipComponentProps, tooltipPopperProps } from "../../../../../componentThemes";
import { useResolveAllLeadDiscrepanciesMutation, useConfirmPublicRecordsMutation } from "../../../../../slices/api/apiSlice";
import { ComponentHeader, Button, ButtonWrapper, ModalContentContainer, SubHeader, FlexWrapper, DetailWrapper } from "../../../../../StyledComponents";

const ConfirmUpdate = ({ handleCloseLeadActionsModal, leadData, teamId, userId, userFullName, dashPerspective, mortgageData }) => {
  const theme = useTheme()

  const [resolveAllDiscrepancies, {isFetching: resolveIsFetching, isSuccess: resolveIsSuccess, isError: resolveIsError}] = useResolveAllLeadDiscrepanciesMutation()
  const [confirmPublicRecords, {isFetching: confirmIsFetching, isSuccess: confirmIsSuccess, isError: confirmIsError}] = useConfirmPublicRecordsMutation()

  const [resolveError, setResolveError] = useState(false)

  const handleResolveAll = async () => {
    let resolve = await resolveAllDiscrepancies({
      userId: userId,
      userFullName: userFullName,
      teamId: teamId,
      dashPerspective: dashPerspective,
      mortgageId: mortgageData._id,
      mission: 'confirmUpdate'
    })
    if (resolve.data.message.includes('resolved')) {
      console.log(resolve.data.data)
      await confirmPublicRecords({
        timeline: resolve.data.data.newLeads[0].timeline,
        userFullName: userFullName,
        teamId: teamId,
        leadId: leadData._id,
        dashPerspective: dashPerspective,
        mortgageId: mortgageData._id,
      })
      setResolveError(false)
    } else {
      setResolveError(true)
    }
  }

  const handleConfirmRecords = async () => {
    await confirmPublicRecords({
      userFullName: userFullName,
      teamId: teamId,
      leadId: leadData._id,
      dashPerspective: dashPerspective,
      mortgageId: mortgageData._id,
    })
  }

  return (
    <>
      {confirmIsSuccess || resolveIsSuccess ?
        <ComponentHeader className="modal green"><CheckIcon style={{height: '2.5rem', width: '2.5rem', margin: '0 0.5rem 0.2rem 0'}} />
          Success
        </ComponentHeader>
      : confirmIsError || resolveIsError || resolveError ?
        <ComponentHeader className="modal red">
          <FlexWrapper style={{border: `0.2rem solid ${theme.palette.common.redDark}`, padding: '0.5rem', width: '2.7rem', background: `${theme.palette.common.redBright}`}}>
            <ErrorIcon style={{height: '2.5rem', width: '2.5rem', color: `${theme.palette.common.white}`}} />
          </FlexWrapper>
          <span style={{padding: '0 0.5rem', fontSize: '1.4rem'}}>
            Failed... try again or contact support@lancastersweep.com
          </span>
        </ComponentHeader>
      :
        <ComponentHeader className="modal">Confirm Public Records</ComponentHeader>
      }

      <ModalContentContainer className="centerColumn leadActions">
          {leadData.activeDiscrepancies > 0 && !confirmIsSuccess ?
            <FlexWrapper className="heightFitContent" style={{marginTop: '5rem', height: 'fit-content'}}>
              <FlexWrapper className="alignCenter" style={{height: 'fit-content', paddingRight: '2rem', margin: '0.25rem 3rem', width: '60rem'}}>
                <FlexWrapper style={{border: `0.2rem solid ${theme.palette.common.redDark}`, padding: '0.5rem', width: '2.7rem', borderRadius: '1rem 0 0 1rem', background: `${theme.palette.common.redBright}`, height: '6.8rem'}}>
                  <ErrorIcon style={{height: '2.5rem', width: '2.5rem', color: `${theme.palette.common.white}`}} />
                </FlexWrapper>
                <div style={{border: `0.1rem solid ${theme.palette.common.redDark}`, padding: '0.3rem 5rem 0.3rem 1rem', borderRadius: '0 1rem 1rem 0', background: `${theme.palette.common.redTransparent}`, borderLeft: 'none', color: `${theme.palette.common.redDark}`}}>
                  The lead currently has active discrepencies. Confirm they have been resolved before continuing.<br/>
                  <DetailWrapper className="addButton" onClick={() => handleResolveAll()} style={{width: 'fit-content', fontSize: '0.8rem', textWrap: 'nowrap', gridColumn: '2', margin: '1.5rem auto 1rem auto'}}>
                    Resolve All
                    <AutoFixHighIcon style={{height: '1.4rem', width: '1.4rem', color: `${theme.palette.primary.main}`, margin: '0 0 0 0.3rem'}} /> 
                  </DetailWrapper>
                </div>
              </FlexWrapper>
            </FlexWrapper>
          : 
            <div style={{height: '2.7rem'}}></div>
          }
        <FlexWrapper className="column justifyCenter">
          {confirmIsSuccess ?
            <SubHeader className="modal" style={{margin: '0 auto 1rem auto'}}>
              <span style={{margin: 'auto'}}>The mortgage status has been confirmed.</span>
              <span style={{height: '1.5rem'}}></span>
              <span style={{height: '1.5rem'}}></span>
            </SubHeader>
          :
            <SubHeader className="modal" style={{margin: '0 auto 1rem auto'}}>
              <span>Confirm that the Public Records reflect the expected state of the mortgage and property arrangements.</span>
              <span>The mortgage will then return to the "inactive" pool for continued monitoring.</span>
              <span style={{height: '1.5rem'}}></span>
            </SubHeader>
          }
          {leadData.activeDiscrepancies > 0 && !confirmIsSuccess ?
            <ButtonWrapper>
              <Tooltip
                componentsProps={tooltipComponentProps}
                PopperProps={tooltipPopperProps}
                title={<FlexWrapper><span style={{padding: '0', margin: '0', fontWeight: '600'}}>must resolve all discrepancies</span></FlexWrapper>} 
                placement="top"
                arrow
              >
                <Button className="primaryDisabled">Confirm</Button>
              </Tooltip>
              <Button onClick={() => handleCloseLeadActionsModal()} className="gray">Close</Button>
            </ButtonWrapper>
          : confirmIsFetching || resolveIsFetching ?
            <ButtonWrapper>
              <Button className="primaryLoading">
                <CircularProgress sx={{ color: `${theme.palette.common.cardBackground}` }} size={13} />
              </Button>
              <Button onClick={() => handleCloseLeadActionsModal()} className="gray">Close</Button>
            </ButtonWrapper>
          : confirmIsSuccess || resolveIsSuccess ?
            <ButtonWrapper>
              <Button onClick={() => handleCloseLeadActionsModal()} className="gray">Close</Button>
            </ButtonWrapper>
          :
            <ButtonWrapper>
              <Button onClick={() => handleConfirmRecords()} className="primary">Confirm</Button>
              <Button onClick={() => handleCloseLeadActionsModal()} className="gray">Close</Button>
            </ButtonWrapper>
          }         
        </FlexWrapper>
      </ModalContentContainer>
    </>
  )
}

export default ConfirmUpdate;
import React, { useEffect, useState } from "react";
import TreeItem from "./components/TreeItem";
import ReportPDF from "./components/ReportPDF";
import ReportView from "./components/ReportView";
import { useTheme } from "@mui/styles";
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress"
import { PDFDownloadLink } from "@react-pdf/renderer";
import { selectAllTeamReports } from "../../../../slices/teamReportsSlice";
import { useRemoveReportNotifyUserMutation } from "../../../../slices/api/apiSlice";
import { CardListContainer, CardListHeader, FlexWrapper, HeaderText, PageContent, ReportTreeListContainer, TreeContainer } from "../../../../StyledComponents";

const Reports = ({ userId }) => {
  const theme = useTheme();

  const teamReports = useSelector(selectAllTeamReports)
  const [removeUserNotification] = useRemoveReportNotifyUserMutation() 

  const [selectedReport, setSelectedReport] = useState(teamReports[0])
  const [initialIsNew, setInitialIsNew] = useState(false)
  const [selectedReportId, setSelectedReportId] = useState(null)

  const selectReport = (reportId) => {
    if (initialIsNew) {
      removeUserNotification({
        userId: userId,
        reportId: selectedReportId
      })
      setInitialIsNew(false)
    }
    let thisReport = teamReports.find(report => report._id === reportId)
    setSelectedReport(thisReport)
    setSelectedReportId(thisReport._id)
    if (thisReport.notifyUser) {
      removeUserNotification({
        userId: userId,
        reportId: thisReport._id
      })
    }
  }

  useEffect(() => {
    if (teamReports.length > 0) {
      setSelectedReportId(teamReports[0]._id)
      if (teamReports[0].notifyUser) {
        setInitialIsNew(true)
      }
    }
  }, [teamReports])

  return (
    <>
      <PageContent className="grid reports">
        <FlexWrapper className="column">
          <CardListHeader className="reports">
            <HeaderText className="reports">Reports</HeaderText>
          </CardListHeader>
        </FlexWrapper>
            {teamReports !== undefined ?
              <>
                {teamReports.length > 0 ?
                  <>
                    <TreeContainer style={{width: '34rem', margin: '1rem 0 0 1rem'}}>
                      <span style={{padding: '0.5rem 0 0 3rem', width: '100%', color: `${theme.palette.common.grayText}`}}>
                        Total: {teamReports.length}
                      </span>
                      <ReportTreeListContainer>
                        {teamReports.map((report, index) => (
                          <TreeItem key={index} itemIndex={index} borrower={report.Owner1FullName} selectReport={selectReport} reportId={report._id} selectedReportId={selectedReportId} date={report.dateGenerated} isNew={report.notifyUser} address={report.streetAddress} city={report.City} />
                        ))}
                      </ReportTreeListContainer>
                    </TreeContainer>
                  </>
                :
                  <>
                    <TreeContainer>
                      <span style={{padding: '0.5rem 0 0 3rem', width: '100%', color: `${theme.palette.common.grayText}`}}>
                        Total: 0
                      </span>
                      <ReportTreeListContainer style={{height: '10rem', justifyContent: 'center', color: `${theme.palette.common.grayText}`}}>
                        no reports
                      </ReportTreeListContainer>
                    </TreeContainer>
                  </>
                }
              </>
            :
              <CardListContainer className="reportsLoading">
                <CircularProgress
                  sx={{ color: `${theme.palette.primary.main}`}}
                  size={48}
                />
              </CardListContainer>
            }
          {/* <PDFDownloadLink
            document={<ReportPDF report={selectedReport} />}
            fileName={`${selectedReport.outcome}-report-${selectedReport.dateGeneratedFileLabel}.pdf`}
            style={{
              textDecoration: "none",
              color: "white",
              backgroundColor: "purple",
              borderRadius: 10,
              display: "flex",
              height: 50,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {({ loading }) => {
              return loading ? "Generating report..." : "Download your Report";
            }}
          </PDFDownloadLink> */}
          {selectedReport ?
            <ReportView report={selectedReport} />
          :
            <></>
          }
      </PageContent>
    </>
  )
}

export default Reports;
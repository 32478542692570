import React from "react";
import AdminRow from "./AdminRow";
import { useTheme } from "@mui/styles";
import { AccentInfo, Badge, ColumnContent, ComponentSectionRow, DetailWrapper, FlexWrapper, ListRow, ModalContentContainer } from "../../../../../StyledComponents";

const CompareRecords = ({ viewingMortgage }) => {
  const theme = useTheme()
  
  return (
    <>
      <FlexWrapper style={{gridRow: '2/4', height: '100%'}}>
        <FlexWrapper className="column" style={{background: `${theme.palette.common.grayBorder}`}}>

          <FlexWrapper className="alignCenter" style={{width: 'fit-content', margin: '0 auto'}}>
            <Badge className="two" style={{height: '2rem', width: '2rem'}}>{viewingMortgage.recordScans}</Badge>
            {viewingMortgage.recordScans === 1 ?
              <div>Record Scan performed on this mortgage</div>
            :
              <div>Record Scans performed on this mortgage</div>
            }
          </FlexWrapper>

          <FlexWrapper style={{maxWidth: '70rem', margin: '0 auto'}}>
            <FlexWrapper className="alignCenter justifyCenter">
              <DetailWrapper className="initial">Detail</DetailWrapper>
              <span style={{padding: '0 0 0 1rem'}}>Details from First Scan</span>
            </FlexWrapper>
            <FlexWrapper className="alignCenter justifyCenter">
              <DetailWrapper className="one">Detail</DetailWrapper>
              <span style={{padding: '0 0 0 1rem'}}>Tier 1 Discrepancy</span>
            </FlexWrapper>
            <FlexWrapper className="alignCenter justifyCenter">
              <DetailWrapper className="two">Detail</DetailWrapper>
              <span style={{padding: '0 0 0 1rem'}}>Tier 2 Discrepancy</span>
            </FlexWrapper>
            <FlexWrapper className="alignCenter justifyCenter">
              <DetailWrapper className="three">Detail</DetailWrapper>
              <span style={{padding: '0 0 0 1rem'}}>Tier 3 Discrepancy</span>
            </FlexWrapper>
          </FlexWrapper>

          <ComponentSectionRow className="adminRepository header">
            <ColumnContent className="header" style={{gridColumn: '2', textAlign: 'center'}}>
              Record Field
            </ColumnContent>
            <ColumnContent className="header" style={{gridColumn: '3', textAlign: 'center'}}>
              Current Value
            </ColumnContent>
            <ColumnContent className="header" style={{gridColumn: '4', textAlign: 'center'}}>
              Public Record Value
            </ColumnContent>
          </ComponentSectionRow>

        </FlexWrapper>
      </FlexWrapper>

      <ModalContentContainer className="topColumn" style={{gridRow: '4', padding: '1rem 0.5rem 1rem 1rem'}}>
        <ComponentSectionRow className="adminRepository header section">
          <ColumnContent className="section header">
            Identifiers
          </ColumnContent>
        </ComponentSectionRow>
        {viewingMortgage.recordDetails.identifiers.AlternatePropertyIdentifier &&
          <AdminRow background={'none'} record={viewingMortgage.recordDetails.identifiers.AlternatePropertyIdentifier} />
        }
        {viewingMortgage.recordDetails.identifiers.FIPS &&
          <AdminRow background={'gray'} record={viewingMortgage.recordDetails.identifiers.FIPS} />
        }
        {viewingMortgage.recordDetails.identifiers.PMXPropertyId &&
          <AdminRow background={'none'} record={viewingMortgage.recordDetails.identifiers.PMXPropertyId} />
        }
        {viewingMortgage.recordDetails.identifiers.AttomId &&
          <AdminRow background={'gray'} record={viewingMortgage.recordDetails.identifiers.AttomId} />
        }
        <ComponentSectionRow className="adminRepository header section">
          <ColumnContent className="section header">
            Address
          </ColumnContent>
        </ComponentSectionRow>
        {viewingMortgage.recordDetails.address.StreetAddressOneLine &&
          <AdminRow background={'none'} record={viewingMortgage.recordDetails.address.StreetAddressOneLine} />
        }
        {viewingMortgage.recordDetails.address.StreetAddressLine1 &&
          <AdminRow background={'gray'} record={viewingMortgage.recordDetails.address.StreetAddressLine1} />
        }
        {viewingMortgage.recordDetails.address.StreetAddressLine2 &&
          <AdminRow background={'none'} record={viewingMortgage.recordDetails.address.StreetAddressLine2} />
        }
        {viewingMortgage.recordDetails.address.StreetNumber &&
          <AdminRow background={'gray'} record={viewingMortgage.recordDetails.address.StreetNumber} />
        }
        {viewingMortgage.recordDetails.address.UnitPrefix &&
          <AdminRow background={'none'} record={viewingMortgage.recordDetails.address.UnitPrefix} />
        }
        {viewingMortgage.recordDetails.address.UnitNumber &&
          <AdminRow background={'gray'} record={viewingMortgage.recordDetails.address.UnitNumber} />
        }
        {viewingMortgage.recordDetails.address.StreetDirPrefix &&
          <AdminRow background={'none'} record={viewingMortgage.recordDetails.address.StreetDirPrefix} />
        }
        {viewingMortgage.recordDetails.address.StreetName &&
          <AdminRow background={'gray'} record={viewingMortgage.recordDetails.address.StreetName} />
        }
        {viewingMortgage.recordDetails.address.StreetSuffix &&
          <AdminRow background={'none'} record={viewingMortgage.recordDetails.address.StreetSuffix} />
        }
        {viewingMortgage.recordDetails.address.StreetDirSuffix &&
          <AdminRow background={'gray'} record={viewingMortgage.recordDetails.address.StreetDirSuffix} />
        }
        {viewingMortgage.recordDetails.address.City &&
          <AdminRow background={'none'} record={viewingMortgage.recordDetails.address.City} />
        }
        {viewingMortgage.recordDetails.address.StateOrProvince &&
          <AdminRow background={'gray'} record={viewingMortgage.recordDetails.address.StateOrProvince} />
        }
        {viewingMortgage.recordDetails.address.PostalCode &&
          <AdminRow background={'none'} record={viewingMortgage.recordDetails.address.PostalCode} />
        }
        {viewingMortgage.recordDetails.address.PostalCodePlus4 &&
          <AdminRow background={'gray'} record={viewingMortgage.recordDetails.address.PostalCodePlus4} />
        }
        <ComponentSectionRow className="adminRepository header section">
          <ColumnContent className="section header">
            Primary Owner
          </ColumnContent>
        </ComponentSectionRow>
        {viewingMortgage.recordDetails.owner1.Owner1OwnershipRights &&
          <AdminRow background={'none'} record={viewingMortgage.recordDetails.owner1.Owner1OwnershipRights} />
        }
        {viewingMortgage.recordDetails.owner1.Owner1IsCorporation &&
          <AdminRow background={'gray'} record={viewingMortgage.recordDetails.owner1.Owner1IsCorporation} />
        }
        {viewingMortgage.recordDetails.owner1.CorporateIndicator &&
          <AdminRow background={'none'} record={viewingMortgage.recordDetails.owner1.CorporateIndicator} />
        }
        {viewingMortgage.recordDetails.owner1.CorporateType &&
          <AdminRow background={'gray'} record={viewingMortgage.recordDetails.owner1.CorporateType} />
        }
        {viewingMortgage.recordDetails.owner1.CorporateDescription &&
          <AdminRow background={'none'} record={viewingMortgage.recordDetails.owner1.CorporateDescription} />
        }
        {viewingMortgage.recordDetails.owner1.Owner1FullName &&
          <AdminRow background={'gray'} record={viewingMortgage.recordDetails.owner1.Owner1FullName} />
        }
        {viewingMortgage.recordDetails.owner1.Owner1LastName &&
          <AdminRow background={'none'} record={viewingMortgage.recordDetails.owner1.Owner1LastName} />
        }
        {viewingMortgage.recordDetails.owner1.Owner1FirstName &&
          <AdminRow background={'gray'} record={viewingMortgage.recordDetails.owner1.Owner1FirstName} />
        }
        <ComponentSectionRow className="adminRepository header section">
          <ColumnContent className="section header">
            Secondary Owner
          </ColumnContent>
        </ComponentSectionRow>
        {viewingMortgage.recordDetails.owner2.Owner2IsCorporation &&
          <AdminRow background={'none'} record={viewingMortgage.recordDetails.owner2.Owner2IsCorporation} />
        }
        {viewingMortgage.recordDetails.owner2.Owner2FullName &&
          <AdminRow background={'gray'} record={viewingMortgage.recordDetails.owner2.Owner2FullName} />
        }
        {viewingMortgage.recordDetails.owner2.Owner2LastName &&
          <AdminRow background={'none'} record={viewingMortgage.recordDetails.owner2.Owner2LastName} />
        }
        {viewingMortgage.recordDetails.owner2.Owner2FirstName &&
          <AdminRow background={'gray'} record={viewingMortgage.recordDetails.owner2.Owner2FirstName} />
        }
        <ComponentSectionRow className="adminRepository header section">
          <ColumnContent className="section header">
            Sale
          </ColumnContent>
        </ComponentSectionRow>
        {viewingMortgage.recordDetails.sale.LeaseholdOrFeeSimple &&
          <AdminRow background={'none'} record={viewingMortgage.recordDetails.sale.LeaseholdOrFeeSimple}  />
        }
        {viewingMortgage.recordDetails.sale.LastSaleRecordingDate &&
          <AdminRow background={'gray'} record={viewingMortgage.recordDetails.sale.LastSaleRecordingDate} />
        }
        {viewingMortgage.recordDetails.sale.LastSaleContractDate &&
          <AdminRow background={'none'} record={viewingMortgage.recordDetails.sale.LastSaleContractDate} />
        }
        {viewingMortgage.recordDetails.sale.LastSaleDate &&
          <AdminRow background={'gray'} record={viewingMortgage.recordDetails.sale.LastSaleDate} />
        }
        {viewingMortgage.recordDetails.sale.LastSalePrice &&
          <AdminRow background={'none'} record={viewingMortgage.recordDetails.sale.LastSalePrice} />
        }
        {viewingMortgage.recordDetails.sale.ClosePrice &&
          <AdminRow background={'gray'} record={viewingMortgage.recordDetails.sale.ClosePrice} />
        }
        {viewingMortgage.recordDetails.sale.ClosePriceDescription &&
          <AdminRow background={'none'} record={viewingMortgage.recordDetails.sale.ClosePriceDescription} />
        }
        {viewingMortgage.recordDetails.sale.LastSaleType &&
          <AdminRow background={'gray'} record={viewingMortgage.recordDetails.sale.LastSaleType}  />
        }
        {viewingMortgage.recordDetails.sale.SaleTransType &&
          <AdminRow background={'none'} record={viewingMortgage.recordDetails.sale.SaleTransType} />
        }
        {viewingMortgage.recordDetails.sale.LastSaleTransactionId &&
          <AdminRow background={'gray'} record={viewingMortgage.recordDetails.sale.LastSaleTransactionId} />
        }
        {viewingMortgage.recordDetails.sale.LastSaleBuyerName &&
          <AdminRow background={'none'} record={viewingMortgage.recordDetails.sale.LastSaleBuyerName}  />
        }
        {viewingMortgage.recordDetails.sale.SaleDocType &&
          <AdminRow background={'gray'} record={viewingMortgage.recordDetails.sale.SaleDocType} />
        }
        {viewingMortgage.recordDetails.sale.LastSaleRecordingDocumentId &&
          <AdminRow background={'none'} record={viewingMortgage.recordDetails.sale.LastSaleRecordingDocumentId} />
        }
        {viewingMortgage.recordDetails.sale.LastSaleDocumentType &&
          <AdminRow background={'gray'} record={viewingMortgage.recordDetails.sale.LastSaleDocumentType} />
        }
        {viewingMortgage.recordDetails.sale.LastSaleSeller1FullName &&
          <AdminRow background={'none'} record={viewingMortgage.recordDetails.sale.LastSaleSeller1FullName} />
        }
        {viewingMortgage.recordDetails.sale.LastSaleSeller2FullName &&
          <AdminRow background={'gray'} record={viewingMortgage.recordDetails.sale.LastSaleSeller2FullName} />
        }
        <ComponentSectionRow className="adminRepository header section">
          <ColumnContent className="section header">
            Building
          </ColumnContent>
        </ComponentSectionRow>
        {viewingMortgage.recordDetails.building.BuildingQualityScore &&
          <AdminRow background={'none'} record={viewingMortgage.recordDetails.building.BuildingQualityScore} />
        }
        {viewingMortgage.recordDetails.building.BuildingCondition &&
          <AdminRow background={'gray'} record={viewingMortgage.recordDetails.building.BuildingCondition} />
        }
        {viewingMortgage.recordDetails.building.YearBuilt &&
          <AdminRow background={'none'} record={viewingMortgage.recordDetails.building.YearBuilt} />
        }
        {viewingMortgage.recordDetails.building.YearBuiltEffective &&
          <AdminRow background={'gray'} record={viewingMortgage.recordDetails.building.YearBuiltEffective} />
        }
        {viewingMortgage.recordDetails.building.NumberOfBuildings &&
          <AdminRow background={'none'} record={viewingMortgage.recordDetails.building.NumberOfBuildings} />
        }
        {viewingMortgage.recordDetails.building.NumberOfUnitsTotal &&
          <AdminRow background={'gray'} record={viewingMortgage.recordDetails.building.NumberOfUnitsTotal} />
        }
        {viewingMortgage.recordDetails.building.ArchitecturalStyle &&
          <AdminRow background={'none'} record={viewingMortgage.recordDetails.building.ArchitecturalStyle} />
        }
        {viewingMortgage.recordDetails.building.ConstructionType &&
          <AdminRow background={'gray'} record={viewingMortgage.recordDetails.building.ConstructionType} />
        }
        {viewingMortgage.recordDetails.building.FrameType &&
          <AdminRow background={'none'} record={viewingMortgage.recordDetails.building.FrameType} />
        }
        {viewingMortgage.recordDetails.building.ExteriorWallsType &&
          <AdminRow background={'gray'} record={viewingMortgage.recordDetails.building.ExteriorWallsType} />
        }
        {viewingMortgage.recordDetails.building.InteriorWallsType &&
          <AdminRow background={'none'} record={viewingMortgage.recordDetails.building.InteriorWallsType} />
        }
        {viewingMortgage.recordDetails.building.RoofType &&
          <AdminRow background={'gray'} record={viewingMortgage.recordDetails.building.RoofType} />
        }
        {viewingMortgage.recordDetails.building.RoofCoverType &&
          <AdminRow background={'none'} record={viewingMortgage.recordDetails.building.RoofCoverType} />
        }
        {viewingMortgage.recordDetails.building.CarStorageType &&
          <AdminRow background={'gray'} record={viewingMortgage.recordDetails.building.CarStorageType} />
        }
        {viewingMortgage.recordDetails.building.GarageSpaces &&
          <AdminRow background={'none'} record={viewingMortgage.recordDetails.building.GarageSpaces} />
        }
        {viewingMortgage.recordDetails.building.GarageArea &&
          <AdminRow background={'gray'} record={viewingMortgage.recordDetails.building.GarageArea} />
        }
        {viewingMortgage.recordDetails.building.ParkingSpaces &&
          <AdminRow background={'none'} record={viewingMortgage.recordDetails.building.ParkingSpaces} />
        }
        <ComponentSectionRow className="adminRepository header section">
          <ColumnContent className="section header">
            Living
          </ColumnContent>
        </ComponentSectionRow>
        {viewingMortgage.recordDetails.living.BuildingAreaTotal &&
          <AdminRow background={'none'} record={viewingMortgage.recordDetails.living.BuildingAreaTotal} />
        }
        {viewingMortgage.recordDetails.living.GrossArea &&
          <AdminRow background={'gray'} record={viewingMortgage.recordDetails.living.GrossArea} />
        }
        {viewingMortgage.recordDetails.living.LivingArea &&
          <AdminRow background={'none'} record={viewingMortgage.recordDetails.living.LivingArea} />
        }
        {viewingMortgage.recordDetails.living.Stories &&
          <AdminRow background={'gray'} record={viewingMortgage.recordDetails.living.Stories} />
        }
        {viewingMortgage.recordDetails.living.StoriesDescription &&
          <AdminRow background={'none'} record={viewingMortgage.recordDetails.living.StoriesDescription} />
        }
        {viewingMortgage.recordDetails.living.RoomsTotal &&
          <AdminRow background={'gray'} record={viewingMortgage.recordDetails.living.RoomsTotal} />
        }
        {viewingMortgage.recordDetails.living.BedroomsTotal &&
          <AdminRow background={'none'} record={viewingMortgage.recordDetails.living.BedroomsTotal} />
        }
        {viewingMortgage.recordDetails.living.BathroomsFull &&
          <AdminRow background={'gray'} record={viewingMortgage.recordDetails.living.BathroomsFull} />
        }
        {viewingMortgage.recordDetails.living.BathroomsTotalInteger &&
          <AdminRow background={'none'} record={viewingMortgage.recordDetails.living.BathroomsTotalInteger} />
        }
        {viewingMortgage.recordDetails.living.FireplacesTotal &&
          <AdminRow background={'gray'} record={viewingMortgage.recordDetails.living.FireplacesTotal} />
        }
        {viewingMortgage.recordDetails.living.Heating &&
          <AdminRow background={'none'} record={viewingMortgage.recordDetails.living.Heating} />
        }
        {viewingMortgage.recordDetails.living.Cooling &&
          <AdminRow background={'gray'} record={viewingMortgage.recordDetails.living.Cooling} />
        }
        {viewingMortgage.recordDetails.living.PoolType &&
          <AdminRow background={'none'} record={viewingMortgage.recordDetails.living.PoolType} />
        }
        <ComponentSectionRow className="adminRepository header section">
          <ColumnContent className="section header">
            Assessment
          </ColumnContent>
        </ComponentSectionRow>
        {viewingMortgage.recordDetails.assessment.AssessedYear &&
          <AdminRow background={'none'} record={viewingMortgage.recordDetails.assessment.AssessedYear} />
        }
        {viewingMortgage.recordDetails.assessment.AssessedValue &&
          <AdminRow background={'gray'} record={viewingMortgage.recordDetails.assessment.AssessedValue} />
        }
        {viewingMortgage.recordDetails.assessment.AssessedLandValue &&
          <AdminRow background={'none'} record={viewingMortgage.recordDetails.assessment.AssessedLandValue} />
        }
        {viewingMortgage.recordDetails.assessment.AssessedImprovementValue &&
          <AdminRow background={'gray'} record={viewingMortgage.recordDetails.assessment.AssessedImprovementValue} />
        }
        {viewingMortgage.recordDetails.assessment.MarketValue &&
          <AdminRow background={'none'} record={viewingMortgage.recordDetails.assessment.MarketValue} />
        }
        {viewingMortgage.recordDetails.assessment.MarketLandValue &&
          <AdminRow background={'gray'} record={viewingMortgage.recordDetails.assessment.MarketLandValue} />
        }
        {viewingMortgage.recordDetails.assessment.MarketImprovementValue &&
          <AdminRow background={'none'} record={viewingMortgage.recordDetails.assessment.MarketImprovementValue} />
        }
        {viewingMortgage.recordDetails.assessment.LandValue &&
          <AdminRow background={'gray'} record={viewingMortgage.recordDetails.assessment.LandValue} />
        }
        {viewingMortgage.recordDetails.assessment.ImprovementsValue &&
          <AdminRow background={'none'} record={viewingMortgage.recordDetails.assessment.ImprovementsValue}  />
        }
        {viewingMortgage.recordDetails.assessment.AssessorsMapReference &&
          <AdminRow background={'gray'} record={viewingMortgage.recordDetails.assessment.AssessorsMapReference} />
        }
        <ComponentSectionRow className="adminRepository header section">
          <ColumnContent className="section header">
            Lot
          </ColumnContent>
        </ComponentSectionRow>
        {viewingMortgage.recordDetails.lot.County &&
          <AdminRow background={'none'} record={viewingMortgage.recordDetails.lot.County} />
        }
        {viewingMortgage.recordDetails.lot.Municipality &&
          <AdminRow background={'gray'} record={viewingMortgage.recordDetails.lot.Municipality} />
        }
        {viewingMortgage.recordDetails.lot.LegalSubdivisionName &&
          <AdminRow background={'none'} record={viewingMortgage.recordDetails.lot.LegalSubdivisionName} />
        }
        {viewingMortgage.recordDetails.lot.ParcelNumber &&
          <AdminRow background={'gray'} record={viewingMortgage.recordDetails.lot.ParcelNumber} />
        }
        {viewingMortgage.recordDetails.lot.Latitude &&
          <AdminRow background={'none'} record={viewingMortgage.recordDetails.lot.Latitude} />
        }
        {viewingMortgage.recordDetails.lot.Longitude &&
          <AdminRow background={'gray'} record={viewingMortgage.recordDetails.lot.Longitude} />
        }
        {viewingMortgage.recordDetails.lot.LotSizeAcres &&
          <AdminRow background={'none'} record={viewingMortgage.recordDetails.lot.LotSizeAcres} />
        }
        {viewingMortgage.recordDetails.lot.LotSizeSquareFeet &&
          <AdminRow background={'gray'} record={viewingMortgage.recordDetails.lot.LotSizeSquareFeet} />
        }
        {viewingMortgage.recordDetails.lot.LotSizeDimensions &&
          <AdminRow background={'none'} record={viewingMortgage.recordDetails.lot.LotSizeDimensions} />
        }
        {viewingMortgage.recordDetails.lot.Zoning &&
          <AdminRow background={'gray'} record={viewingMortgage.recordDetails.lot.Zoning} />
        }
        {viewingMortgage.recordDetails.lot.NumberOfLotsCode &&
          <AdminRow background={'none'} record={viewingMortgage.recordDetails.lot.NumberOfLotsCode} />
        }
        {viewingMortgage.recordDetails.lot.StateLandUseCode &&
          <AdminRow background={'gray'} record={viewingMortgage.recordDetails.lot.StateLandUseCode} />
        }
        {viewingMortgage.recordDetails.lot.CountyLandUseCode &&
          <AdminRow background={'none'} record={viewingMortgage.recordDetails.lot.CountyLandUseCode} />
        }
        {viewingMortgage.recordDetails.lot.AdjustedArea &&
          <AdminRow background={'gray'} record={viewingMortgage.recordDetails.lot.AdjustedArea} />
        }
        {viewingMortgage.recordDetails.lot.BelowGradeTotalArea &&
          <AdminRow background={'none'} record={viewingMortgage.recordDetails.lot.BelowGradeTotalArea} />
        }
        {viewingMortgage.recordDetails.lot.BelowGradeFinishedArea &&
          <AdminRow background={'gray'} record={viewingMortgage.recordDetails.lot.BelowGradeFinishedArea} />
        }
        {viewingMortgage.recordDetails.lot.LandLot &&
          <AdminRow background={'none'} record={viewingMortgage.recordDetails.lot.LandLot} />
        }
        {viewingMortgage.recordDetails.lot.LandUseCode &&
          <AdminRow background={'gray'} record={viewingMortgage.recordDetails.lot.LandUseCode} />
        }
        {viewingMortgage.recordDetails.lot.CountyUseCode &&
          <AdminRow background={'none'} record={viewingMortgage.recordDetails.lot.CountyUseCode} />
        }
        {viewingMortgage.recordDetails.lot.TaxLot &&
          <AdminRow background={'gray'} record={viewingMortgage.recordDetails.lot.TaxLot} />
        }
        {viewingMortgage.recordDetails.lot.TaxBlock &&
          <AdminRow background={'none'} record={viewingMortgage.recordDetails.lot.TaxBlock} />
        }
        {viewingMortgage.recordDetails.lot.TaxSection &&
          <AdminRow background={'gray'} record={viewingMortgage.recordDetails.lot.TaxSection} />
        }
        {viewingMortgage.recordDetails.lot.TaxDistrict &&
          <AdminRow background={'none'} record={viewingMortgage.recordDetails.lot.TaxDistrict} />
        }
        {viewingMortgage.recordDetails.lot.TaxLegalUnit &&
          <AdminRow background={'gray'} record={viewingMortgage.recordDetails.lot.TaxLegalUnit} />
        }
        {viewingMortgage.recordDetails.lot.TaxCodeArea &&
          <AdminRow background={'none'} record={viewingMortgage.recordDetails.lot.TaxCodeArea} />
        }
        {viewingMortgage.recordDetails.lot.TaxLegalDescription &&
          <AdminRow background={'gray'} record={viewingMortgage.recordDetails.lot.TaxLegalDescription} />
        }
        {viewingMortgage.recordDetails.lot.View &&
          <AdminRow background={'none'} record={viewingMortgage.recordDetails.lot.View} />
        }
        {viewingMortgage.recordDetails.lot.LocationFeatures &&
          <AdminRow background={'gray'} record={viewingMortgage.recordDetails.lot.LocationFeatures} />
        }
        {viewingMortgage.recordDetails.lot.CensusTractId &&
          <AdminRow background={'none'} record={viewingMortgage.recordDetails.lot.CensusTractId} />
        }
        <ComponentSectionRow className="adminRepository header section">
          <ColumnContent className="section header">
            Summary
          </ColumnContent>
        </ComponentSectionRow>
        {viewingMortgage.recordDetails.summary.WaterSource &&
          <AdminRow background={'none'} record={viewingMortgage.recordDetails.summary.WaterSource} />
        }
        {viewingMortgage.recordDetails.summary.SewerType &&
          <AdminRow background={'gray'} record={viewingMortgage.recordDetails.summary.SewerType} />
        }
        {viewingMortgage.recordDetails.summary.SchoolDistrict &&
          <AdminRow background={'none'} record={viewingMortgage.recordDetails.summary.SchoolDistrict} />
        }
        {viewingMortgage.recordDetails.summary.PropertyType &&
          <AdminRow background={'gray'} record={viewingMortgage.recordDetails.summary.PropertyType} />
        }
        {viewingMortgage.recordDetails.summary.PropertySubType &&
          <AdminRow background={'none'} record={viewingMortgage.recordDetails.summary.PropertySubType} />
        }
        {viewingMortgage.recordDetails.summary.QuitClaimFlag &&
          <AdminRow background={'gray'} record={viewingMortgage.recordDetails.summary.QuitClaimFlag} />
        }
        {viewingMortgage.recordDetails.summary.REOflag &&
          <AdminRow background={'none'} record={viewingMortgage.recordDetails.summary.REOflag} />
        }
        {viewingMortgage.recordDetails.summary.DistressYN &&
          <AdminRow background={'gray'} record={viewingMortgage.recordDetails.summary.DistressYN} />
        }
        <ComponentSectionRow className="adminRepository header section">
          <ColumnContent className="section header">
            Tax
          </ColumnContent>
        </ComponentSectionRow>
        {viewingMortgage.recordDetails.tax.TaxYear &&
          <AdminRow background={'none'} record={viewingMortgage.recordDetails.tax.TaxYear} />
        }
        {viewingMortgage.recordDetails.tax.TaxAnnualAmount &&
          <AdminRow background={'gray'} record={viewingMortgage.recordDetails.tax.TaxAnnualAmount} />
        }    
        {viewingMortgage.recordDetails.tax.OwnerOccupied &&
          <AdminRow background={'none'} record={viewingMortgage.recordDetails.tax.OwnerOccupied} />
        }    
        {viewingMortgage.recordDetails.tax.TaxExemptionHomestead &&
          <AdminRow background={'gray'} record={viewingMortgage.recordDetails.tax.TaxExemptionHomestead} />
        }
        {viewingMortgage.recordDetails.tax.TaxExemptionVeteran &&
          <AdminRow background={'none'} record={viewingMortgage.recordDetails.tax.TaxExemptionVeteran} />
        }
        {viewingMortgage.recordDetails.tax.TaxExemptionDisabled &&
          <AdminRow background={'gray'} record={viewingMortgage.recordDetails.tax.TaxExemptionDisabled} />
        }
        {viewingMortgage.recordDetails.tax.TaxExemptionWidow &&
          <AdminRow background={'none'} record={viewingMortgage.recordDetails.tax.TaxExemptionWidow} />
        }
        {viewingMortgage.recordDetails.tax.TaxExemptionSenior &&
          <AdminRow background={'gray'} record={viewingMortgage.recordDetails.tax.TaxExemptionSenior} />
        }
        {viewingMortgage.recordDetails.tax.TaxExemptionSchoolCollege &&
          <AdminRow background={'none'} record={viewingMortgage.recordDetails.tax.TaxExemptionSchoolCollege} />
        }
        {viewingMortgage.recordDetails.tax.TaxExemptionReligious &&
          <AdminRow background={'gray'} record={viewingMortgage.recordDetails.tax.TaxExemptionReligious} />
        }
        {viewingMortgage.recordDetails.tax.TaxExemptionWelfare &&
          <AdminRow background={'none'} record={viewingMortgage.recordDetails.tax.TaxExemptionWelfare} />
        }
        {viewingMortgage.recordDetails.tax.TaxExemptionPublicUtility &&
          <AdminRow background={'gray'} record={viewingMortgage.recordDetails.tax.TaxExemptionPublicUtility} />
        }
        {viewingMortgage.recordDetails.tax.TaxExemptionCemetery &&
          <AdminRow background={'none'} record={viewingMortgage.recordDetails.tax.TaxExemptionCemetery} />
        }
        {viewingMortgage.recordDetails.tax.TaxExemptionHospital &&
          <AdminRow background={'gray'} record={viewingMortgage.recordDetails.tax.TaxExemptionHospital} />
        }
        {viewingMortgage.recordDetails.tax.TaxExemptionLibrary &&
          <AdminRow background={'none'} record={viewingMortgage.recordDetails.tax.TaxExemptionLibrary} />
        }
        {viewingMortgage.recordDetails.tax.TaxInitialDeliquencyYear &&
          <AdminRow background={'gray'} record={viewingMortgage.recordDetails.tax.TaxInitialDeliquencyYear} />
        }        
        <ComponentSectionRow className="adminRepository header section">
          <ColumnContent className="section header">
            {viewingMortgage.recordDetails.secondaryMortgage.SecondaryMortgageAmount && viewingMortgage.recordDetails.secondaryMortgage.SecondaryMortgageAmount.currentValue > 0 ?
              `Primary Mortgage`
            :
              `Current Mortgage`
            }
          </ColumnContent>
        </ComponentSectionRow>
        {viewingMortgage.recordDetails.primaryMortgage.PrimaryMortgageAmount && viewingMortgage.recordDetails.primaryMortgage.PrimaryMortgageAmount.currentValue > 0 ?
          <>
            {viewingMortgage.recordDetails.primaryMortgage.PrimaryMortgageAmount &&
              <AdminRow background={'none'} record={viewingMortgage.recordDetails.primaryMortgage.PrimaryMortgageAmount} />
            }
            {viewingMortgage.recordDetails.primaryMortgage.PrimaryLenderName &&
              <AdminRow background={'gray'} record={viewingMortgage.recordDetails.primaryMortgage.PrimaryLenderName} />
            }
            {viewingMortgage.recordDetails.primaryMortgage.PrimaryMortgageRecordingDate &&
              <AdminRow background={'gray'} record={viewingMortgage.recordDetails.primaryMortgage.PrimaryMortgageRecordingDate} />
            }
            {viewingMortgage.recordDetails.primaryMortgage.PrimaryLoanType &&
              <AdminRow background={'none'} record={viewingMortgage.recordDetails.primaryMortgage.PrimaryLoanType} />
            }
            {viewingMortgage.recordDetails.primaryMortgage.PrimaryMortgageTerm &&
              <AdminRow background={'none'} record={viewingMortgage.recordDetails.primaryMortgage.PrimaryMortgageTerm} />
            }
            {viewingMortgage.recordDetails.primaryMortgage.PrimaryMortgageDueDate &&
              <AdminRow background={'gray'} record={viewingMortgage.recordDetails.primaryMortgage.PrimaryMortgageDueDate} />
            }
            {viewingMortgage.recordDetails.primaryMortgage.TitleCompany &&
              <AdminRow background={'none'} record={viewingMortgage.recordDetails.primaryMortgage.TitleCompany} />
            }
            {viewingMortgage.recordDetails.primaryMortgage.OwnershipRights &&
              <AdminRow background={'gray'} record={viewingMortgage.recordDetails.primaryMortgage.OwnershipRights} />
            }
            {viewingMortgage.recordDetails.primaryMortgage.BorrowerRelationship &&
              <AdminRow background={'none'} record={viewingMortgage.recordDetails.primaryMortgage.BorrowerRelationship} />
            }
          </>
        :
          <ListRow>
            <AccentInfo style={{gridColumn: '4', height: '50px'}}>
              -not available-
            </AccentInfo>
          </ListRow>
        }
        {viewingMortgage.recordDetails.secondaryMortgage.SecondaryMortgageAmount && viewingMortgage.recordDetails.secondaryMortgage.SecondaryMortgageAmount.currentValue > 0 ?
          <>
            <ComponentSectionRow className="adminRepository header section">
              <ColumnContent className="section header">
                Secondary Mortgage
              </ColumnContent>
            </ComponentSectionRow>
            {viewingMortgage.recordDetails.secondaryMortgage.SecondaryMortgageAmount &&
              <AdminRow background={'none'} record={viewingMortgage.recordDetails.secondaryMortgage.SecondaryMortgageAmount} />
            }
            {viewingMortgage.recordDetails.secondaryMortgage.SecondaryLenderName &&
              <AdminRow background={'gray'} record={viewingMortgage.recordDetails.secondaryMortgage.SecondaryLenderName} />
            }
            {viewingMortgage.recordDetails.secondaryMortgage.SecondaryMortgageRecordingDate &&
              <AdminRow background={'gray'} record={viewingMortgage.recordDetails.secondaryMortgage.SecondaryMortgageRecordingDate} />
            }
            {viewingMortgage.recordDetails.secondaryMortgage.SecondaryLoanType &&
              <AdminRow background={'none'} record={viewingMortgage.recordDetails.secondaryMortgage.SecondaryLoanType} />
            }
            {viewingMortgage.recordDetails.secondaryMortgage.SecondaryMortgageTerm &&
              <AdminRow background={'gray'} record={viewingMortgage.recordDetails.secondaryMortgage.SecondaryMortgageTerm} />
            }
            {viewingMortgage.recordDetails.secondaryMortgage.SecondaryMortgageDueDate &&
              <AdminRow background={'none'} record={viewingMortgage.recordDetails.secondaryMortgage.SecondaryMortgageDueDate} />
            }
          </>
        :
          <></>
        }
      </ModalContentContainer>
    </>
  )
}

export default CompareRecords
import { createSlice } from '@reduxjs/toolkit'
import { apiSlice } from './api/apiSlice'

const initialState = {
  data: {
    awaitingActionLeads: [],
    investigatingLeads: [],
    closingLeads: [],
    awaitingUpdateLeads: [],
    filteredAwaitingActionLeads: [],
    filteredInvestigatingLeads: [],
    filteredClosingLeads: [],
    filteredAwaitingUpdateLeads: [],
  },
  status: 'idle',
  error: null
}

export const teamActiveLeadsSlice = createSlice({
  name: 'teamActiveLeads',
  initialState,
  reducers: {
    setSortedTeamAwaitingActionLeads(state, action) { 
      state.data.awaitingActionLeads = action.payload 
    },
    setSortedTeamInvestigatingLeads(state, action) { 
      state.data.investigatingLeads = action.payload 
    },
    setSortedTeamClosingLeads(state, action) { 
      state.data.closingLeads = action.payload 
    },
    setSortedTeamAwaitingUpdateLeads(state, action) { 
      state.data.awaitingUpdateLeads = action.payload 
    },
    setFilteredTeamAwaitingActionLeads(state, action) { 
      state.data.filteredAwaitingActionLeads = action.payload 
    },
    setFilteredTeamInvestigatingLeads(state, action) { 
      state.data.filteredInvestigatingLeads = action.payload 
    },
    setFilteredTeamClosingLeads(state, action) { 
      state.data.filteredClosingLeads = action.payload 
    },
    setFilteredTeamAwaitingUpdateLeads(state, action) { 
      state.data.filteredAwaitingUpdateLeads = action.payload 
    },
  },
  extraReducers: (builder) => {
    builder
    .addMatcher(apiSlice.endpoints.initialLoad.matchFulfilled, (state, {payload}) => {
      if (payload.data) {
        state.data.awaitingActionLeads = payload.data.teamAwaitingActionLeads
        state.data.investigatingLeads = payload.data.teamInvestigatingLeads
        state.data.closingLeads = payload.data.teamClosingLeads
        state.data.awaitingUpdateLeads = payload.data.teamAwaitingUpdateLeads
      }
    })
    .addMatcher(apiSlice.endpoints.setLeadStatusInvestigating.matchFulfilled, (state, {payload}) => {
      state.data.investigatingLeads.unshift(payload.data.newLead)
      let thisLead = state.data.awaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
      let thisIndex = state.data.awaitingActionLeads.indexOf(thisLead)
      state.data.awaitingActionLeads.splice(thisIndex, 1)
      state.data.filteredInvestigatingLeads.unshift(payload.data.newLead)
      let thisLeadFiltered = state.data.filteredAwaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
      let thisIndexFiltered = state.data.filteredAwaitingActionLeads.indexOf(thisLeadFiltered)
      state.data.filteredAwaitingActionLeads.splice(thisIndexFiltered, 1)
    })
    .addMatcher(apiSlice.endpoints.setLeadStatusClosing.matchFulfilled, (state, {payload}) => {
      state.data.closingLeads.unshift(payload.data.newLead)
      let thisLead = state.data.investigatingLeads.find(lead => lead._id === payload.data.newLead._id)
      let thisIndex = state.data.investigatingLeads.indexOf(thisLead)
      state.data.investigatingLeads.splice(thisIndex, 1)
      state.data.filteredClosingLeads.unshift(payload.data.newLead)
      let thisLeadFiltered = state.data.filteredInvestigatingLeads.find(lead => lead._id === payload.data.newLead._id)
      let thisIndexFiltered = state.data.filteredInvestigatingLeads.indexOf(thisLeadFiltered)
      state.data.filteredInvestigatingLeads.splice(thisIndexFiltered, 1)
    })
    .addMatcher(apiSlice.endpoints.setLeadStatusFinalized.matchFulfilled, (state, {payload}) => {
      console.log(payload.data)
      let thisLead = state.data.closingLeads.find(lead => lead._id === payload.data.leadId)
      let thisIndex = state.data.closingLeads.indexOf(thisLead)
      state.data.closingLeads.splice(thisIndex, 1)
      let thisLeadFiltered = state.data.filteredClosingLeads.find(lead => lead._id === payload.data.leadId)
      let thisIndexFiltered = state.data.filteredClosingLeads.indexOf(thisLeadFiltered)
      state.data.filteredClosingLeads.splice(thisIndexFiltered, 1)
      if (payload.data.activeDiscrepancies > 0) {
        state.data.awaitingUpdateLeads.push(payload.data.leadId)
        state.data.filteredAwaitingUpdateLeads.push(payload.data.leadId)
      }
    })
    .addMatcher(apiSlice.endpoints.confirmPublicRecords.matchFulfilled, (state, {payload}) => {
      console.log(payload.data)
      let thisLead = state.data.awaitingUpdateLeads.find(lead => lead._id === payload.data.leadId)
      let thisIndex = state.data.awaitingUpdateLeads.indexOf(thisLead)
      state.data.awaitingUpdateLeads.splice(thisIndex, 1)
      let thisLeadFiltered = state.data.filteredAwaitingUpdateLeads.find(lead => lead._id === payload.data.leadId)
      let thisIndexFiltered = state.data.filteredAwaitingUpdateLeads.indexOf(thisLeadFiltered)
      state.data.filteredAwaitingUpdateLeads.splice(thisIndexFiltered, 1)
    })
    .addMatcher(apiSlice.endpoints.addMortgageNote.matchFulfilled, (state, {payload}) => {
      if (payload.data.leadStatus === 'investigating') {
        let thisLead = state.data.investigatingLeads.find(lead => lead._id === payload.data.leadId)
        let thisIndex = state.data.investigatingLeads.indexOf(thisLead)
        state.data.investigatingLeads[thisIndex] = payload.data.newLead
        let thisLeadFiltered = state.data.filteredInvestigatingLeads.find(lead => lead._id === payload.data.leadId)
        let thisIndexFiltered = state.data.filteredInvestigatingLeads.indexOf(thisLeadFiltered)
        state.data.filteredInvestigatingLeads[thisIndexFiltered] = payload.data.newLead
      } else if (payload.data.leadStatus === 'closing') {
        let thisLead = state.data.closingLeads.find(lead => lead._id === payload.data.leadId)
        let thisIndex = state.data.closingLeads.indexOf(thisLead)
        state.data.closingLeads[thisIndex] = payload.data.newLead
        let thisLeadFiltered = state.data.filteredClosingLeads.find(lead => lead._id === payload.data.leadId)
        let thisIndexFiltered = state.data.filteredClosingLeads.indexOf(thisLeadFiltered)
        state.data.filteredClosingLeads[thisIndexFiltered] = payload.data.newLead
      } else {
        let thisLead = state.data.awaitingActionLeads.find(lead => lead._id === payload.data.leadId)
        let thisIndex = state.data.awaitingActionLeads.indexOf(thisLead)
        state.data.awaitingActionLeads[thisIndex] = payload.data.newLead
        let thisLeadFiltered = state.data.filteredAwaitingActionLeads.find(lead => lead._id === payload.data.leadId)
        let thisIndexFiltered = state.data.filteredAwaitingActionLeads.indexOf(thisLeadFiltered)
        state.data.filteredAwaitingActionLeads[thisIndexFiltered] = payload.data.newLead
      }
    })
    .addMatcher(apiSlice.endpoints.saveLeadTargetUpdates.matchFulfilled, (state, {payload}) => {
      if (payload.data.leadStatus === 'investigating') {
        let thisLead = state.data.investigatingLeads.find(lead => lead._id === payload.data.newLead._id)
        let thisIndex = state.data.investigatingLeads.indexOf(thisLead)
        state.data.investigatingLeads[thisIndex] = payload.data.newLead
        let thisLeadFiltered = state.data.filteredInvestigatingLeads.find(lead => lead._id === payload.data.newLead._id)
        let thisIndexFiltered = state.data.filteredInvestigatingLeads.indexOf(thisLeadFiltered)
        state.data.filteredInvestigatingLeads[thisIndexFiltered] = payload.data.newLead
      } else if (payload.data.leadStatus === 'closing') {
        let thisLead = state.data.closingLeads.find(lead => lead._id === payload.data.newLead._id)
        let thisIndex = state.data.closingLeads.indexOf(thisLead)
        state.data.closingLeads[thisIndex] = payload.data.newLead
        let thisLeadFiltered = state.data.filteredClosingLeads.find(lead => lead._id === payload.data.newLead._id)
        let thisIndexFiltered = state.data.filteredClosingLeads.indexOf(thisLeadFiltered)
        state.data.filteredClosingLeads[thisIndexFiltered] = payload.data.newLead
      } else {
        let thisLead = state.data.awaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
        let thisIndex = state.data.awaitingActionLeads.indexOf(thisLead)
        state.data.awaitingActionLeads[thisIndex] = payload.data.newLead
        let thisLeadFiltered = state.data.filteredAwaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
        let thisIndexFiltered = state.data.filteredAwaitingActionLeads.indexOf(thisLeadFiltered)
        state.data.filteredAwaitingActionLeads[thisIndexFiltered] = payload.data.newLead
      }
    })
    .addMatcher(apiSlice.endpoints.addAssignees.matchFulfilled, (state, {payload}) => {
      if (payload.data.leadStatus === 'investigating') {
        let thisLead = state.data.investigatingLeads.find(lead => lead._id === payload.data.newLead._id)
        let thisIndex = state.data.investigatingLeads.indexOf(thisLead)
        state.data.investigatingLeads[thisIndex] = payload.data.newLead
        let thisLeadFiltered = state.data.filteredInvestigatingLeads.find(lead => lead._id === payload.data.newLead._id)
        let thisIndexFiltered = state.data.filteredInvestigatingLeads.indexOf(thisLeadFiltered)
        state.data.filteredInvestigatingLeads[thisIndexFiltered] = payload.data.newLead
      } else if (payload.data.leadStatus === 'closing') {
        let thisLead = state.data.closingLeads.find(lead => lead._id === payload.data.newLead._id)
        let thisIndex = state.data.closingLeads.indexOf(thisLead)
        state.data.closingLeads[thisIndex] = payload.data.newLead
        let thisLeadFiltered = state.data.filteredClosingLeads.find(lead => lead._id === payload.data.newLead._id)
        let thisIndexFiltered = state.data.filteredClosingLeads.indexOf(thisLeadFiltered)
        state.data.filteredClosingLeads[thisIndexFiltered] = payload.data.newLead
      } else {
        let thisLead = state.data.awaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
        let thisIndex = state.data.awaitingActionLeads.indexOf(thisLead)
        state.data.awaitingActionLeads[thisIndex] = payload.data.newLead
        let thisLeadFiltered = state.data.filteredAwaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
        let thisIndexFiltered = state.data.filteredAwaitingActionLeads.indexOf(thisLeadFiltered)
        state.data.filteredAwaitingActionLeads[thisIndexFiltered] = payload.data.newLead
      }
    })
    .addMatcher(apiSlice.endpoints.resolveAllLeadDiscrepancies.matchFulfilled, (state, {payload}) => {
      console.log(payload.data)
      for (let i = 0; i < payload.data.newLeads.length; i++) {
        if (payload.data.newLeads[i].status === 'investigating') {
          let thisLead = state.data.investigatingLeads.find(lead => lead._id === payload.data.newLeads[i]._id)
          let thisIndex = state.data.investigatingLeads.indexOf(thisLead)
          state.data.investigatingLeads.splice(thisIndex, 1)
          let thisLeadFiltered = state.data.filteredInvestigatingLeads.find(lead => lead._id === payload.data.newLeads[i]._id)
          let thisIndexFiltered = state.data.filteredInvestigatingLeads.indexOf(thisLeadFiltered)
          state.data.filteredInvestigatingLeads.splice(thisIndexFiltered, 1)
        } else if (payload.data.newLeads[i].status === 'closing') {
          let thisLead = state.data.closingLeads.find(lead => lead._id === payload.data.newLeads[i]._id)
          let thisIndex = state.data.closingLeads.indexOf(thisLead)
          state.data.closingLeads.splice(thisIndex, 1)
          let thisLeadFiltered = state.data.filteredClosingLeads.find(lead => lead._id === payload.data.newLeads[i]._id)
          let thisIndexFiltered = state.data.filteredClosingLeads.indexOf(thisLeadFiltered)
          state.data.filteredClosingLeads.splice(thisIndexFiltered, 1)
        } else {
          let thisLead = state.data.awaitingActionLeads.find(lead => lead._id.toString() === payload.data.newLeads[i]._id.toString())
          let thisIndex = state.data.awaitingActionLeads.indexOf(thisLead)
          state.data.awaitingActionLeads[thisIndex] = payload.data.newLeads[i]
          let thisLeadFiltered = state.data.filteredAwaitingActionLeads.find(lead => lead._id === payload.data.newLeads[i]._id)
          let thisIndexFiltered = state.data.filteredAwaitingActionLeads.indexOf(thisLeadFiltered)
          state.data.filteredAwaitingActionLeads[thisIndexFiltered] = payload.data.newLeads[i]
        }
      }
      console.log('here')
    })
    .addMatcher(apiSlice.endpoints.deleteMortgage.matchFulfilled, (state, {payload}) => {
      if (payload.data.updateState) {
        if (payload.data.leadStatus === 'investigating') {
          let thisLead = state.data.investigatingLeads.find(lead => lead._id === payload.data.leadId)
          let thisIndex = state.data.investigatingLeads.indexOf(thisLead)
          state.data.investigatingLeads.splice(thisIndex, 1)
          let thisLeadFiltered = state.data.filteredInvestigatingLeads.find(lead => lead._id === payload.data.leadId)
          let thisIndexFiltered = state.data.filteredInvestigatingLeads.indexOf(thisLeadFiltered)
          state.data.filteredInvestigatingLeads.splice(thisIndexFiltered, 1)
        } else if (payload.data.leadStatus === 'closing') {
          let thisLead = state.data.closingLeads.find(lead => lead._id === payload.data.leadId)
          let thisIndex = state.data.closingLeads.indexOf(thisLead)
          state.data.closingLeads.splice(thisIndex, 1)
          let thisLeadFiltered = state.data.filteredClosingLeads.find(lead => lead._id === payload.data.leadId)
          let thisIndexFiltered = state.data.filteredClosingLeads.indexOf(thisLeadFiltered)
          state.data.filteredClosingLeads.splice(thisIndexFiltered, 1)
        } else {
          let thisLead = state.data.awaitingActionLeads.find(lead => lead._id === payload.data.leadId)
          let thisIndex = state.data.awaitingActionLeads.indexOf(thisLead)
          state.data.awaitingActionLeads.splice(thisIndex, 1)
          let thisLeadFiltered = state.data.filteredAwaitingActionLeads.find(lead => lead._id === payload.data.leadId)
          let thisIndexFiltered = state.data.filteredAwaitingActionLeads.indexOf(thisLeadFiltered)
          state.data.filteredAwaitingActionLeads.splice(thisIndexFiltered, 1)
        }
      }
    })
    .addMatcher(apiSlice.endpoints.dismissLead.matchFulfilled, (state, {payload}) => {
      if (payload.data.leadStatus === 'investigating') {
        let thisLead = state.data.investigatingLeads.find(lead => lead._id === payload.data.leadId)
        let thisIndex = state.data.investigatingLeads.indexOf(thisLead)
        state.data.investigatingLeads.splice(thisIndex, 1)
        let thisLeadFiltered = state.data.filteredInvestigatingLeads.find(lead => lead._id === payload.data.leadId)
        let thisIndexFiltered = state.data.filteredInvestigatingLeads.indexOf(thisLeadFiltered)
        state.data.filteredInvestigatingLeads.splice(thisIndexFiltered, 1)
      } else if (payload.data.leadStatus === 'closing') {
        let thisLead = state.data.closingLeads.find(lead => lead._id === payload.data.leadId)
        let thisIndex = state.data.closingLeads.indexOf(thisLead)
        state.data.closingLeads.splice(thisIndex, 1)
        let thisLeadFiltered = state.data.filteredClosingLeads.find(lead => lead._id === payload.data.leadId)
        let thisIndexFiltered = state.data.filteredClosingLeads.indexOf(thisLeadFiltered)
        state.data.filteredClosingLeads.splice(thisIndexFiltered, 1)
      } else {
        let thisLead = state.data.awaitingActionLeads.find(lead => lead._id === payload.data.leadId)
        let thisIndex = state.data.awaitingActionLeads.indexOf(thisLead)
        state.data.awaitingActionLeads.splice(thisIndex, 1)
        let thisLeadFiltered = state.data.filteredAwaitingActionLeads.find(lead => lead._id === payload.data.leadId)
        let thisIndexFiltered = state.data.filteredAwaitingActionLeads.indexOf(thisLeadFiltered)
        state.data.filteredAwaitingActionLeads.splice(thisIndexFiltered, 1)
      }
    })
    .addMatcher(apiSlice.endpoints.updateMortgageDetails.matchFulfilled, (state, {payload}) => {
      if (payload.data.leadStatus === 'investigating') {
        let thisLead = state.data.investigatingLeads.find(lead => lead._id === payload.data.newLead._id)
        let thisIndex = state.data.investigatingLeads.indexOf(thisLead)
        state.data.investigatingLeads[thisIndex] = payload.data.newLead
        let thisLeadFiltered = state.data.filteredInvestigatingLeads.find(lead => lead._id === payload.data.newLead._id)
        let thisIndexFiltered = state.data.filteredInvestigatingLeads.indexOf(thisLeadFiltered)
        state.data.filteredInvestigatingLeads[thisIndexFiltered] = payload.data.newLead
      } else if (payload.data.leadStatus === 'closing') {
        let thisLead = state.data.closingLeads.find(lead => lead._id === payload.data.newLead._id)
        let thisIndex = state.data.closingLeads.indexOf(thisLead)
        state.data.closingLeads[thisIndex] = payload.data.newLead
        let thisLeadFiltered = state.data.filteredClosingLeads.find(lead => lead._id === payload.data.newLead._id)
        let thisIndexFiltered = state.data.filteredClosingLeads.indexOf(thisLeadFiltered)
        state.data.filteredClosingLeads[thisIndexFiltered] = payload.data.newLead
      } else {
        let thisLead = state.data.awaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
        let thisIndex = state.data.awaitingActionLeads.indexOf(thisLead)
        state.data.awaitingActionLeads[thisIndex] = payload.data.newLead
        let thisLeadFiltered = state.data.filteredAwaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
        let thisIndexFiltered = state.data.filteredAwaitingActionLeads.indexOf(thisLeadFiltered)
        state.data.filteredAwaitingActionLeads[thisIndexFiltered] = payload.data.newLead
      }
    })
    .addMatcher(apiSlice.endpoints.changeTargetOutcome.matchFulfilled, (state, {payload}) => {
      if (payload.data.leadStatus === 'investigating') {
        let thisLead = state.data.investigatingLeads.find(lead => lead._id === payload.data.newLead._id)
        let thisIndex = state.data.investigatingLeads.indexOf(thisLead)
        state.data.investigatingLeads[thisIndex] = payload.data.newLead
        let thisLeadFiltered = state.data.filteredInvestigatingLeads.find(lead => lead._id === payload.data.newLead._id)
        let thisIndexFiltered = state.data.filteredInvestigatingLeads.indexOf(thisLeadFiltered)
        state.data.filteredInvestigatingLeads[thisIndexFiltered] = payload.data.newLead
      } else if (payload.data.leadStatus === 'closing') {
        let thisLead = state.data.closingLeads.find(lead => lead._id === payload.data.newLead._id)
        let thisIndex = state.data.closingLeads.indexOf(thisLead)
        state.data.closingLeads[thisIndex] = payload.data.newLead
        let thisLeadFiltered = state.data.filteredClosingLeads.find(lead => lead._id === payload.data.newLead._id)
        let thisIndexFiltered = state.data.filteredClosingLeads.indexOf(thisLeadFiltered)
        state.data.filteredClosingLeads[thisIndexFiltered] = payload.data.newLead
      } else {
        let thisLead = state.data.awaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
        let thisIndex = state.data.awaitingActionLeads.indexOf(thisLead)
        state.data.awaitingActionLeads[thisIndex] = payload.data.newLead
        let thisLeadFiltered = state.data.filteredAwaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
        let thisIndexFiltered = state.data.filteredAwaitingActionLeads.indexOf(thisLeadFiltered)
        state.data.filteredAwaitingActionLeads[thisIndexFiltered] = payload.data.newLead
      }
    })
    .addMatcher(apiSlice.endpoints.addLeadTag.matchFulfilled, (state, {payload}) => {
      if (payload.data.leadStatus === 'investigating') {
        let thisLead = state.data.investigatingLeads.find(lead => lead._id === payload.data.newLead._id)
        let thisIndex = state.data.investigatingLeads.indexOf(thisLead)
        state.data.investigatingLeads[thisIndex] = payload.data.newLead
        let thisLeadFiltered = state.data.filteredInvestigatingLeads.find(lead => lead._id === payload.data.newLead._id)
        let thisIndexFiltered = state.data.filteredInvestigatingLeads.indexOf(thisLeadFiltered)
        state.data.filteredInvestigatingLeads[thisIndexFiltered] = payload.data.newLead
      } else if (payload.data.leadStatus === 'closing') {
        let thisLead = state.data.closingLeads.find(lead => lead._id === payload.data.newLead._id)
        let thisIndex = state.data.closingLeads.indexOf(thisLead)
        state.data.closingLeads[thisIndex] = payload.data.newLead
        let thisLeadFiltered = state.data.filteredClosingLeads.find(lead => lead._id === payload.data.newLead._id)
        let thisIndexFiltered = state.data.filteredClosingLeads.indexOf(thisLeadFiltered)
        state.data.filteredClosingLeads[thisIndexFiltered] = payload.data.newLead
      } else {
        let thisLead = state.data.awaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
        let thisIndex = state.data.awaitingActionLeads.indexOf(thisLead)
        state.data.awaitingActionLeads[thisIndex] = payload.data.newLead
        let thisLeadFiltered = state.data.filteredAwaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
        let thisIndexFiltered = state.data.filteredAwaitingActionLeads.indexOf(thisLeadFiltered)
        state.data.filteredAwaitingActionLeads[thisIndexFiltered] = payload.data.newLead
      }
    })
    .addMatcher(apiSlice.endpoints.recordSweep.matchFulfilled, (state, {payload}) => {
      for (let i = 0; i < payload.data.newLeads.length; i++) {
        let thisLead = state.data.awaitingActionLeads.find(lead => lead._id === payload.data.newLeads[i])
        if (thisLead) {
          let thisIndex = state.data.awaitingActionLeads.indexOf(thisLead)
          let deletedUser = state.data.awaitingActionLeads[thisIndex].assigneeIds.find(assignee => assignee.toString() === payload.data.deleteUserId) 
          let thisUserIndex = state.data.awaitingActionLeads[thisIndex].assigneeIds.indexOf(deletedUser)
          state.data.awaitingActionLeads[thisIndex].assigneeIds.splice(thisUserIndex, 1)
        } else {
          let thisLead = state.data.investigatingLeads.find(lead => lead._id === payload.data.newLeads[i])
          if (thisLead) {
            let thisIndex = state.data.investigatingLeads.indexOf(thisLead)
            let deletedUser = state.data.investigatingLeads[thisIndex].assigneeIds.find(assignee => assignee.toString() === payload.data.deleteUserId) 
            let thisUserIndex = state.data.investigatingLeads[thisIndex].assigneeIds.indexOf(deletedUser)
            state.data.investigatingLeads[thisIndex].assigneeIds.splice(thisUserIndex, 1)
          } else {
            let thisLead = state.data.closingLeads.find(lead => lead._id === payload.data.newLeads[i])
            if (thisLead) {
              let thisIndex = state.data.closingLeads.indexOf(thisLead)
              let deletedUser = state.data.closingLeads[thisIndex].assigneeIds.find(assignee => assignee.toString() === payload.data.deleteUserId) 
              let thisUserIndex = state.data.closingLeads[thisIndex].assigneeIds.indexOf(deletedUser)
              state.data.closingLeads[thisIndex].assigneeIds.splice(thisUserIndex, 1)
            } else {
              state.data.awaitingActionLeads.unshift(payload.data.newLeads[i])
            }
          }
        }
        let thisFilteredLead = state.data.filteredAwaitingActionLeads.find(lead => lead._id === payload.data.newLeads[i])
        if (thisLead) {
          let thisIndex = state.data.filteredAwaitingActionLeads.indexOf(thisFilteredLead)
          let deletedUser = state.data.filteredAwaitingActionLeads[thisIndex].assigneeIds.find(assignee => assignee.toString() === payload.data.deleteUserId) 
          let thisUserIndex = state.data.filteredAwaitingActionLeads[thisIndex].assigneeIds.indexOf(deletedUser)
          state.data.filteredAwaitingActionLeads[thisIndex].assigneeIds.splice(thisUserIndex, 1)
        } else {
          let thisLead = state.data.filteredInvestigatingLeads.find(lead => lead._id === payload.data.newLeads[i])
          if (thisLead) {
            let thisIndex = state.data.filteredInvestigatingLeads.indexOf(thisLead)
            let deletedUser = state.data.filteredInvestigatingLeads[thisIndex].assigneeIds.find(assignee => assignee.toString() === payload.data.deleteUserId) 
            let thisUserIndex = state.data.filteredInvestigatingLeads[thisIndex].assigneeIds.indexOf(deletedUser)
            state.data.filteredInvestigatingLeads[thisIndex].assigneeIds.splice(thisUserIndex, 1)
          } else {
            let thisLead = state.data.filteredClosingLeads.find(lead => lead._id === payload.data.newLeads[i])
            if (thisLead) {
              let thisIndex = state.data.filteredClosingLeads.indexOf(thisLead)
              let deletedUser = state.data.filteredClosingLeads[thisIndex].assigneeIds.find(assignee => assignee.toString() === payload.data.deleteUserId) 
              let thisUserIndex = state.data.filteredClosingLeads[thisIndex].assigneeIds.indexOf(deletedUser)
              state.data.filteredClosingLeads[thisIndex].assigneeIds.splice(thisUserIndex, 1)
            } else {
              state.data.filteredAwaitingActionLeads.unshift(payload.data.newLeads[i])
            }
          }
        }
      }
    })
  }
})

export const { setSortedTeamAwaitingActionLeads, setFilteredTeamAwaitingActionLeads, setSortedTeamInvestigatingLeads, setSortedTeamClosingLeads, setFilteredTeamInvestigatingLeads, setFilteredTeamClosingLeads, setSortedTeamAwaitingUpdateLeads, setFilteredTeamAwaitingUpdateLeads } = teamActiveLeadsSlice.actions

export default teamActiveLeadsSlice.reducer

//* ActiveLeads
export const selectTeamAwaitingActionLeads = state => state.teamActiveLeads.data.awaitingActionLeads
export const selectTeamInvestigatingLeads = state => state.teamActiveLeads.data.investigatingLeads
export const selectTeamClosingLeads = state => state.teamActiveLeads.data.closingLeads
export const selectTeamAwaitingUpdateLeads = state => state.teamActiveLeads.data.awaitingUpdateLeads
export const selectFilteredTeamAwaitingActionLeads = state => state.teamActiveLeads.data.filteredAwaitingActionLeads
export const selectFilteredTeamInvestigatingLeads = state => state.teamActiveLeads.data.filteredInvestigatingLeads
export const selectFilteredTeamClosingLeads = state => state.teamActiveLeads.data.filteredClosingLeads
export const selectFilteredTeamAwaitingUpdateLeads = state => state.teamActiveLeads.data.filteredAwaitingUpdateLeads
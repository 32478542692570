import React from "react";
import styled from "@emotion/styled";
import ListIcon from '@mui/icons-material/List';
import FullLogo from '../../../../Full.logo.white.png';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { useTheme } from "@mui/styles";
import DataObjectIcon from '@mui/icons-material/DataObject';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import EngineeringIcon from '@mui/icons-material/Engineering';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import { selectLoadData } from "../../../../slices/loadDataSlice";
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import { useSelector, useDispatch } from "react-redux";
import { selectAdminNavSelection, setAdminNavSelection } from "../../../../slices/adminNavSelectionSlice";
import { SideBarContainer, TitleContainer, NavigationContainer, NavText, NavIndex, SideBarNavHeader  } from "../../../../StyledComponents";

const SideBar = () => {
  const theme = useTheme()
  const dispatch = useDispatch()

  const dataLoaded = useSelector(selectLoadData)
  const adminNavSelection = useSelector(selectAdminNavSelection)

  const changeSelection = (content) => {
    dispatch( setAdminNavSelection(content) )
  }

  return (
    <SideBarContainer>
      <TitleContainer>
        <img src={FullLogo} style={{width: '100%', height: 'auto'}} />
      </TitleContainer>
      <NavigationContainer style={{gridRow: '2/4'}}>
        <SideBarNavHeader>Navigation</SideBarNavHeader>
        {dataLoaded ?
          <>
            {adminNavSelection === 'uploads' ?
              <ListItemNav selected>
                <NavIndex>
                  <UploadFileIcon fontSize="medium" />
                </NavIndex>
                <NavText className="main">Uploads</NavText>
              </ListItemNav>
            :
              <ListItemNav onClick={() => changeSelection('uploads')}>
                <NavIndex>
                  <UploadFileIcon fontSize="medium" />
                </NavIndex>
                <NavText className="main">Uploads</NavText>
              </ListItemNav>
            }
            {adminNavSelection === 'repository' ?
              <ListItemNav selected>
                <NavIndex>
                  <ListIcon fontSize="medium" />
                </NavIndex>
                <NavText className="main">Repository</NavText>
              </ListItemNav>
            :
              <ListItemNav onClick={() => changeSelection('repository')}>
                <NavIndex>
                  <ListIcon fontSize="medium" />
                </NavIndex>
                <NavText className="main">Repository</NavText>
              </ListItemNav>
            }
            {adminNavSelection === 'recordSweeps' ?
              <ListItemNav selected>
                <NavIndex>
                  <DocumentScannerIcon fontSize="medium" />
                </NavIndex>
                <NavText className="main">Record Sweeps</NavText>
              </ListItemNav>
            :
              <ListItemNav onClick={() => changeSelection('recordSweeps')}>
                <NavIndex>
                  <DocumentScannerIcon fontSize="medium" />
                </NavIndex>
                <NavText className="main">Record Sweeps</NavText>
              </ListItemNav>
            }
            {adminNavSelection === 'log' ?
              <ListItemNav selected>
                <NavIndex>
                  <ListAltIcon fontSize="medium" />
                </NavIndex>
                <NavText className="main">Actions Log</NavText>
              </ListItemNav>
            :
              <ListItemNav onClick={() => changeSelection('log')}>
                <NavIndex>
                  <ListAltIcon fontSize="medium" />
                </NavIndex>
                <NavText className="main">Actions Log</NavText>
              </ListItemNav>
            }
            {adminNavSelection === 'database' ?
              <ListItemNav selected>
                <NavIndex>
                  <AccountTreeIcon fontSize="medium" />
                </NavIndex>
                <NavText className="main">Database</NavText>
              </ListItemNav>
            :
              <ListItemNav onClick={() => changeSelection('database')}>
                <NavIndex>
                  <AccountTreeIcon fontSize="medium" />
                </NavIndex>
                <NavText className="main">Database</NavText>
              </ListItemNav>
            }
            {adminNavSelection === 'scheduledTasks' ?
              <ListItemNav selected>
                <NavIndex>
                  <ScheduleSendIcon fontSize="medium" />
                </NavIndex>
                <NavText className="main">Scheduled Tasks</NavText>
              </ListItemNav>
            :
              <ListItemNav onClick={() => changeSelection('scheduledTasks')}>
                <NavIndex>
                  <ScheduleSendIcon fontSize="medium" />
                </NavIndex>
                <NavText className="main">Scheduled Tasks</NavText>
              </ListItemNav>
            }
            {adminNavSelection === 'teamDetails' ?
              <ListItemNav selected>
                <NavIndex>
                  <EngineeringIcon fontSize="medium" />
                </NavIndex>
                <NavText className="main">Team Details</NavText>
              </ListItemNav>
            :
              <ListItemNav onClick={() => changeSelection('teamDetails')}>
                <NavIndex>
                  <EngineeringIcon fontSize="medium" />
                </NavIndex>
                <NavText className="main">Team Details</NavText>
              </ListItemNav>
            }
            {adminNavSelection === 'dataModel' ?
              <ListItemNav selected>
                <NavIndex>
                  <DataObjectIcon fontSize="medium" />
                </NavIndex>
                <NavText className="main">Data Model</NavText>
              </ListItemNav>
            :
              <ListItemNav onClick={() => changeSelection('dataModel')}>
                <NavIndex>
                  <DataObjectIcon fontSize="medium" />
                </NavIndex>
                <NavText className="main">Data Model</NavText>
              </ListItemNav>
            }
          </>
        :
          <>
            <ListItemNav disabled>
              <NavIndex>
                <UploadFileIcon fontSize="medium" />
              </NavIndex>
              <NavText className="disabled">Uploads</NavText>
            </ListItemNav>
            <ListItemNav disabled>
              <NavIndex>
                <ListIcon fontSize="medium" />
              </NavIndex>
              <NavText className="disabled">Repository</NavText>
            </ListItemNav>
            <ListItemNav disabled>
              <NavIndex>
                <DocumentScannerIcon fontSize="medium" />
              </NavIndex>
              <NavText className="disabled">Record Sweeps</NavText>
            </ListItemNav>
            <ListItemNav disabled>
              <NavIndex>
                <ListAltIcon fontSize="medium" />
              </NavIndex>
              <NavText className="disabled">Actions Log</NavText>
            </ListItemNav>
            <ListItemNav disabled>
              <NavIndex>
                <AccountTreeIcon fontSize="medium" />
              </NavIndex>
              <NavText className="disabled">Database</NavText>
            </ListItemNav>
            <ListItemNav disabled>
              <NavIndex>
                <ScheduleSendIcon fontSize="medium" />
              </NavIndex>
              <NavText className="disabled">Scheduled Tasls</NavText>
            </ListItemNav>
            <ListItemNav disabled>
              <NavIndex>
                <EngineeringIcon fontSize="medium" />
              </NavIndex>
              <NavText className="disabled">Team Details</NavText>
            </ListItemNav>
            <ListItemNav disabled>
              <NavIndex>
                <DataObjectIcon fontSize="medium" />
              </NavIndex>
              <NavText className="disabled">Data Model</NavText>
            </ListItemNav>
          </>
        }
      <div style={{gridRow: '3', height: '100%', borderRight: `0.1rem solid ${theme.light.panel.secondaryBorder}`}} />
      </NavigationContainer>
    </SideBarContainer>
  )
}

const ListItemNav = styled.a`
  display: flex;
  align-items: center;
  font-weight: 400;
  border-radius: 0;
  height: ${(props) => props.selected ? "3.3rem" : "3.5rem"};
  border-right: ${(props) => props.selected ? "0.1rem solid #F7F7F7" : "0.1rem solid #c8cbcf"};
  border-top: ${(props) => props.selected ? "0.1rem solid #c8cbcf" : "none"};
  border-bottom: ${(props) => props.selected ? "0.1rem solid #c8cbcf" : "none"};
  padding: 0.5rem 0 0.5rem 1.2rem;
  margin: 0;
  background-color: ${(props) => props.selected && !props.subCat ? "#F7F7F7" : props.subCat || props.disabled ? "none" : "none"};
  cursor: ${(props) => props.selected || props.noCursor || props.disabled ? "default" : "pointer"};
  color: ${(props) => props.disabled ? '#939599' : '#00B812'};
  &:hover {
    background-color: ${(props) => props.selected && !props.subCat ? "#c8cbcf" : props.subCat && !props.selected ? "#4B14752B" : props.subCat && (props.selected || props.disabled) ? "#4B14752B" : "#4B14752B"};
  }
`

export default SideBar;
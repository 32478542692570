import React, { useRef, useState } from "react";
import ErrorIcon from "@mui/icons-material/Error";
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from "@emotion/react";
import CircularProgress from "@mui/material/CircularProgress";
import LoanTypeDropdown from "../../../../../components/DropDowns/LoanTypeDropdown";
import { useDispatch, useSelector } from "react-redux";
import { tooltipComponentProps, tooltipPopperProps } from "../../../../../componentThemes";
import { useUpdateTeamDefaultTargetParametersMutation } from "../../../../../slices/api/apiSlice";
import { selectTeamDefaultTargets, selectTeamLoanTypes } from "../../../../../slices/teamAndUserSettingsSlice";
import { selectUpdateDefaultTargetsEnabled, setUpdateDefaultTargetsEnabled } from "../../../../../slices/functionAvailabilitySlice";
import { ErrorWrapper, Label, InputWrapper, StatusAndLabelWrapper, StyledInputElement, Button, ModalHeaderMessage, ComponentContainer, ComponentBorder, FlexWrapper, ButtonWrapper, ComponentSectionRow, ColumnContent } from "../../../../../StyledComponents";

const DefaultTargets = ({ teamId }) => {
  const theme = useTheme()
  const dispatch = useDispatch()

  const teamLoanTypes = useSelector(selectTeamLoanTypes)
  const teamDefaultTargets = useSelector(selectTeamDefaultTargets)
  const updateDefaultTargetsEnabled = useSelector(selectUpdateDefaultTargetsEnabled)

  const [updateTeamDefaultTargetParameters, {isFetching, isSuccess, isError}] = useUpdateTeamDefaultTargetParametersMutation()

  const [defaultTermTarget, setDefaultTermTarget] = useState(parseInt(teamDefaultTargets.defaultTargetTerm))
  const [statusDefaultTerm, setStatusDefaultTerm] = useState(null)
  const defaultTerm = useRef()
  const [defaultInterestTarget, setDefaultInterestTarget] = useState(parseFloat(teamDefaultTargets.defaultTargetInterestRate))
  const [statusDefaultInterest, setStatusDefaultInterest] = useState(null)
  const defaultInterest = useRef()
  const [defaultLoanTypeTarget, setDefaultLoanTypeTarget] = useState(teamDefaultTargets.defaultLoanTypeTarget)

  const handleTermTargetChange = () => {
    let newValue = parseInt(defaultTerm.current.value)
    let negativeTerm = Math.sign(newValue)
    if (newValue === teamDefaultTargets.defaultTargetTerm && parseFloat(defaultInterest.current.value) === teamDefaultTargets.defaultTargetInterestRate && defaultLoanTypeTarget === teamDefaultTargets.defaultLoanTypeTarget) {
      dispatch( setUpdateDefaultTargetsEnabled(false) )
    } else {
      dispatch( setUpdateDefaultTargetsEnabled(true) )
    }
    if (defaultTerm.current.value.length === 0 || isNaN(newValue) || newValue > 999 || negativeTerm === -1 || !Number.isInteger(newValue)) {
      if (!Number.isInteger(newValue)) {
        setStatusDefaultTerm("float")
      }
      if (isNaN(newValue)) {
        setStatusDefaultTerm("nonNumber")
      }
      if (newValue > 999) {
        setStatusDefaultTerm("tooLarge")
      }
      if (negativeTerm === -1) {
        setStatusDefaultTerm("negative")
      }
      if (defaultTerm.current.value.length === 0) {
        setStatusDefaultTerm("missing")
      }
      dispatch( setUpdateDefaultTargetsEnabled(false) )
    } else {
      setStatusDefaultTerm(null)
    }
    if (isNaN(parseInt(defaultTerm.current.value))) {
      setDefaultTermTarget(defaultTerm.current.value)
    } else {
      setDefaultTermTarget(parseInt(defaultTerm.current.value))
    }
  }
  const handleInterestTargetChange = () => {
    let newValue = parseFloat(defaultInterest.current.value)
    let negativeTerm = Math.sign(newValue)
    if (newValue === teamDefaultTargets.defaultTargetInterestRate && parseInt(defaultTerm.current.value) === teamDefaultTargets.defaultTargetTerm && defaultLoanTypeTarget === teamDefaultTargets.defaultLoanTypeTarget) {
      dispatch( setUpdateDefaultTargetsEnabled(false) )
    } else {
      dispatch( setUpdateDefaultTargetsEnabled(true) )
    }
    if (defaultInterest.current.value.length === 0 || isNaN(newValue) || newValue > 100 || negativeTerm === -1 || !Number.isInteger(newValue) || newValue === 0) {
      if (isNaN(newValue)) {
        setStatusDefaultInterest("nonNumber")
      }
      if (newValue > 100) {
        setStatusDefaultInterest("tooLarge")
      }
      if (negativeTerm === -1) {
        setStatusDefaultInterest("negative")
      }
      if (newValue === 0) {
        setStatusDefaultInterest("zero")
      }
      if (defaultInterest.current.value.length === 0) {
        setStatusDefaultInterest("missing")
      }
      dispatch( setUpdateDefaultTargetsEnabled(false) )
    } else {
      setStatusDefaultInterest(null)
    }
    if (isNaN(parseFloat(defaultInterest.current.value))) {
      setDefaultInterestTarget(defaultInterest.current.value)
    } else {
      setDefaultInterestTarget(parseFloat(defaultInterest.current.value))
    }
  }
  const handleLoanTypeTargetChange = (event) => {
    if (event.target.value === teamDefaultTargets.defaultLoanTypeTarget && parseInt(defaultTerm.current.value) === teamDefaultTargets.defaultTargetTerm && parseFloat(defaultInterest.current.value) === teamDefaultTargets.defaultTargetInterestRate) {
      dispatch( setUpdateDefaultTargetsEnabled(false) )
    } else if (statusDefaultTerm === null && statusDefaultInterest === null) {
      dispatch( setUpdateDefaultTargetsEnabled(true) )
    }
    setDefaultLoanTypeTarget(event.target.value)
  }

  const handleSubmit = async () => {
    await updateTeamDefaultTargetParameters({
      teamId: teamId,
      term: defaultTermTarget,
      type: defaultLoanTypeTarget,
      interest: defaultInterestTarget,
    })
    dispatch( setUpdateDefaultTargetsEnabled(false) )
  }

  return (
    <ComponentContainer className="justifyStart" style={{gridRow: '1', gridColumn: '3', margin: 'auto'}}>
      <FlexWrapper className="column" style={{gridRow: '2', gridColumn: '2'}}>
        {isSuccess ?
          <ComponentSectionRow className="header">
            <ColumnContent className="header large" style={{padding: '0.9rem 1.3rem'}}>
              Default Target Parameters
              <ModalHeaderMessage className="success">success!</ModalHeaderMessage>
            </ColumnContent>
          </ComponentSectionRow>
        : isError ?
          <ComponentSectionRow className="header">
            <ColumnContent className="header large" style={{padding: '0.9rem 1.3rem'}}>
              Default Target Parameters
              <ModalHeaderMessage className="error">update failed...</ModalHeaderMessage>
            </ColumnContent>
          </ComponentSectionRow>
        :
          <ComponentSectionRow className="header">
            <ColumnContent className="header large" style={{padding: '0.9rem 1.3rem'}}>
              Default Target Parameters
            </ColumnContent>
          </ComponentSectionRow>
        }
        <ComponentSectionRow style={{padding: '1rem 0 0.5rem 0'}}>
          <ColumnContent className="detail small lowercase thin column">
            <span>Refinance Targets are used to calculate new loan and profit calculations</span>
            <span>when the 'refinance' target outcome is applied to a lead.</span>
            <span>These targets can be adjusted on any individual lead.</span>
          </ColumnContent>
        </ComponentSectionRow>
        <FlexWrapper style={{padding: '0.5rem 3rem 4rem 4rem'}}>
          <InputWrapper style={{width: '8rem'}}>
            <StatusAndLabelWrapper className="column">
              {statusDefaultTerm === "missing" && (
                <ErrorWrapper>
                  <ErrorIcon />
                  Missing
                </ErrorWrapper>
              )}
              {statusDefaultTerm === "nonNumber" && (
                <ErrorWrapper>
                  <ErrorIcon />
                  Non-number
                </ErrorWrapper>
              )}
              {statusDefaultTerm === "tooLarge" && (
                <ErrorWrapper>
                  <ErrorIcon />
                  Too Large
                </ErrorWrapper>
              )}
              {statusDefaultTerm === "negative" && (
                <ErrorWrapper>
                  <ErrorIcon />
                  Negative
                </ErrorWrapper>
              )}
              {statusDefaultTerm === "float" && (
                <ErrorWrapper>
                  <ErrorIcon />
                  Decimal Value
                </ErrorWrapper>
              )}
              <Label htmlFor="defaultTerm">Term (years)</Label>
            </StatusAndLabelWrapper>
            <StyledInputElement
              style={{width: '8rem'}}
              value={defaultTermTarget}
              disabled={isFetching}
              autoComplete="off"
              type="text"
              name="defaultTerm"
              id="defaultTerm"
              ref={defaultTerm}
              onChange={() => handleTermTargetChange()}
            />
          </InputWrapper>
          <InputWrapper style={{width: '8rem'}}>
            <StatusAndLabelWrapper className="column">
              {statusDefaultInterest === "missing" && (
                <ErrorWrapper>
                  <ErrorIcon />
                  Missing
                </ErrorWrapper>
              )}
              {statusDefaultInterest === "nonNumber" && (
                <ErrorWrapper>
                  <ErrorIcon />
                  Non-number
                </ErrorWrapper>
              )}
              {statusDefaultInterest === "tooLarge" && (
                <ErrorWrapper>
                  <ErrorIcon />
                  Too Large
                </ErrorWrapper>
              )}
              {statusDefaultInterest === "negative" && (
                <ErrorWrapper>
                  <ErrorIcon />
                  Negative
                </ErrorWrapper>
              )}
              {statusDefaultInterest === "zero" && (
                <ErrorWrapper>
                  <ErrorIcon />
                  Negative
                </ErrorWrapper>
              )}
              <Label htmlFor="defaultInterest">Interest (%)</Label>
            </StatusAndLabelWrapper>
            <StyledInputElement
              style={{width: '8rem'}}
              value={defaultInterestTarget}
              disabled={isFetching}
              autoComplete="off"
              type="text"
              name="defaultInterest"
              id="defaultInterest"
              ref={defaultInterest}
              onChange={() => handleInterestTargetChange()}
            />
          </InputWrapper>
          <div style={{width: '15rem', margin: 'auto 0 0.7rem 0'}}>
            <StatusAndLabelWrapper>
              <Label htmlFor="defaultType">Loan Type</Label>
            </StatusAndLabelWrapper>
            <LoanTypeDropdown value={defaultLoanTypeTarget} handleChange={handleLoanTypeTargetChange} items={teamLoanTypes} />
          </div>
          <ButtonWrapper style={{margin: 'auto auto 0.7rem auto'}}>
            {isFetching ? (
              <Button className="primaryLoading" disabled style={{height: "2.5rem", gridRow: '1', gridColumn: '3', alignSelf: 'flex-end', margin: '0'}}>
                <CircularProgress 
                  sx={{color: `${theme.palette.common.white}`}}
                  size={12}
                />
              </Button>
            ) : statusDefaultInterest !== null && statusDefaultTerm !== null ? (
              <Tooltip
                componentsProps={tooltipComponentProps}
                PopperProps={tooltipPopperProps}
                title={<FlexWrapper><span style={{padding: '0', margin: '0', fontWeight: '600'}}>selection errors</span></FlexWrapper>} 
                placement="top"
                arrow
              >
                <Button className="secondaryDisabled thin" style={{height: "2.5rem", gridRow: '1', gridColumn: '3', alignSelf: 'flex-end', margin: '0'}}>
                  save changes
                </Button>
              </Tooltip>
            ) : !updateDefaultTargetsEnabled ? (
              <Tooltip
                componentsProps={tooltipComponentProps}
                PopperProps={tooltipPopperProps}
                title={<FlexWrapper><span style={{padding: '0', margin: '0', fontWeight: '600'}}>no changes made</span></FlexWrapper>} 
                placement="top"
                arrow
              >
                <Button className="secondaryDisabled thin" style={{height: "2.5rem", gridRow: '1', gridColumn: '3', alignSelf: 'flex-end', margin: '0'}}>
                  save changes
                </Button>
              </Tooltip>
            ) : (
              <Button className="secondary thin" onClick={handleSubmit} style={{height: "2.5rem", gridRow: '1', gridColumn: '3', alignSelf: 'flex-end', margin: '0'}}>
                save changes
              </Button>
            )}
          </ButtonWrapper>
        </FlexWrapper>
      </FlexWrapper>
      <ComponentBorder className="top" style={{gridRow:'1', gridColumn: '1/4'}} />
      <ComponentBorder className="left" style={{gridRow:'2', gridColumn: '1'}} />
      <ComponentBorder className="right"  style={{gridRow:'2', gridColumn: '3'}} />
      <ComponentBorder className="bottom" style={{gridRow:'3', gridColumn: '1/4'}} />
    </ComponentContainer>
  )
}

export default DefaultTargets;
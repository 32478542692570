import { createSlice } from '@reduxjs/toolkit'
import { apiSlice } from './api/apiSlice'

const initialState = {
  data: {
    reports: [],
  },
  status: 'idle',
  error: null
}

export const teamReportsSlice = createSlice({
  name: 'teamReports',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addMatcher(apiSlice.endpoints.initialLoad.matchFulfilled, (state, {payload}) => {
      if (payload.data) {
        state.data.reports = payload.data.teamReports
      }
    })
    .addMatcher(apiSlice.endpoints.setLeadStatusFinalized.matchFulfilled, (state, {payload}) => {
      console.log(payload.data)
      state.data.reports.unshift(payload.data.newReport)
    })
    .addMatcher(apiSlice.endpoints.deleteMortgage.matchFulfilled, (state, {payload}) => {
      for (let i = 0; i < payload.data.reportIds; i++) {
        let thisReport = state.data.reports.find(report => report._id === payload.data.reportId);
        let thisIndex = state.data.reports.indexOf(thisReport)
        state.data.reports.splice(thisIndex, 1)
      }
    })
    .addMatcher(apiSlice.endpoints.dismissLead.matchFulfilled, (state, {payload}) => {
      if (payload.data.updateNewReports) {
        payload.data.newReport.notifyUser = true
        state.data.reports.unshift(payload.data.newReport)
      }
    })
  }
})

export default teamReportsSlice.reducer

export const selectAllTeamReports = state => state.teamReports.data.reports
export const selectFirstTeamReport = state => state.teamReports.data.reports[0]
import { createSlice } from '@reduxjs/toolkit'
import { apiSlice } from './api/apiSlice'

const initialState = {
  data: {
    memberId: '',
    awaitingActionLeads: [],
    investigatingLeads: [],
    closingLeads: [],
    awaitingUpdateLeads: [],
    filteredAwaitingActionLeads: [],
    filteredInvestigatingLeads: [],
    filteredClosingLeads: [],
    filteredAwaitingUpdateLeads: [],
  },
  status: 'idle',
  error: null
}

export const memberActiveLeadsSlice = createSlice({
  name: 'memberActiveLeads',
  initialState,
  reducers: {
    setMemberActiveLeads(state, action) {
      state.data.memberId = action.payload.memberId
      state.data.awaitingActionLeads = action.payload.awaitingActionLeads
      state.data.investigatingLeads = action.payload.investigatingLeads
      state.data.closingLeads = action.payload.closingLeads
    },
    setSortedMemberAwaitingActionLeads(state, action) { 
      state.data.awaitingActionLeads = action.payload 
    },
    setSortedMemberInvestigatingLeads(state, action) { 
      state.data.investigatingLeads = action.payload 
    },
    setSortedMemberClosingLeads(state, action) { 
      state.data.closingLeads = action.payload 
    },
    setSortedMemberAwaitingUpdateLeads(state, action) { 
      state.data.awaitingUpdateLeads = action.payload 
    },
    setFilteredMemberAwaitingActionLeads(state, action) { 
      state.data.filteredAwaitingActionLeads = action.payload 
    },
    setFilteredMemberInvestigatingLeads(state, action) { 
      state.data.filteredInvestigatingLeads = action.payload 
    },
    setFilteredMemberClosingLeads(state, action) { 
      state.data.filteredClosingLeads = action.payload 
    },
    setFilteredMemberAwaitingUpdateLeads(state, action) { 
      state.data.filteredAwaitingUpdateLeads = action.payload 
    },
  },
  extraReducers: (builder) => {
    builder
    .addMatcher(apiSlice.endpoints.resolveAllLeadDiscrepancies.matchFulfilled, (state, {payload}) => {
      console.log(payload.data)
      if (payload.data.updateState) {
        for (let i = 0; i < payload.data.newLeads.length; i++) {
          if (payload.data.newLeads[i].status === 'investigating') {
            let thisLead = state.data.investigatingLeads.find(lead => lead._id === payload.data.newLeads[i]._id)
            let thisIndex = state.data.investigatingLeads.indexOf(thisLead)
            state.data.investigatingLeads.splice(thisIndex, 1)
            let thisLeadFiltered = state.data.filteredInvestigatingLeads.find(lead => lead._id === payload.data.newLeads[i]._id)
            let thisIndexFiltered = state.data.filteredInvestigatingLeads.indexOf(thisLeadFiltered)
            state.data.filteredInvestigatingLeads.splice(thisIndexFiltered, 1)
          } else if (payload.data.newLeads[i].status === 'closing') {
            let thisLead = state.data.closingLeads.find(lead => lead._id === payload.data.newLeads[i]._id)
            let thisIndex = state.data.closingLeads.indexOf(thisLead)
            state.data.closingLeads.splice(thisIndex, 1)
            let thisLeadFiltered = state.data.filteredClosingLeads.find(lead => lead._id === payload.data.newLeads[i]._id)
            let thisIndexFiltered = state.data.filteredClosingLeads.indexOf(thisLeadFiltered)
            state.data.filteredClosingLeads.splice(thisIndexFiltered, 1)
          } else {
            let thisLead = state.data.awaitingActionLeads.find(lead => lead._id === payload.data.newLeads[i]._id)
            let thisIndex = state.data.awaitingActionLeads.indexOf(thisLead)
            state.data.awaitingActionLeads[thisIndex] = payload.data.newLeads[i]
            let thisLeadFiltered = state.data.filteredAwaitingActionLeads.find(lead => lead._id === payload.data.newLeads[i]._id)
            let thisIndexFiltered = state.data.filteredAwaitingActionLeads.indexOf(thisLeadFiltered)
            state.data.filteredAwaitingActionLeads[thisIndexFiltered] = payload.data.newLeads[i]
          }
        }
      }
      console.log('here')
    })
    .addMatcher(apiSlice.endpoints.deleteMortgage.matchFulfilled, (state, {payload}) => {
      if (payload.data.updateState) {
        if (payload.data.leadStatus === 'investigating') {
          let thisLead = state.data.investigatingLeads.find(lead => lead._id === payload.data.leadId)
          let thisIndex = state.data.investigatingLeads.indexOf(thisLead)
          state.data.investigatingLeads.splice(thisIndex, 1)
          let thisLeadFiltered = state.data.filteredInvestigatingLeads.find(lead => lead._id === payload.data.leadId)
          let thisIndexFiltered = state.data.filteredInvestigatingLeads.indexOf(thisLeadFiltered)
          state.data.filteredInvestigatingLeads.splice(thisIndexFiltered, 1)
        } else if (payload.data.leadStatus === 'closing') {
          let thisLead = state.data.closingLeads.find(lead => lead._id === payload.data.leadId)
          let thisIndex = state.data.closingLeads.indexOf(thisLead)
          state.data.closingLeads.splice(thisIndex, 1)
          let thisLeadFiltered = state.data.filteredClosingLeads.find(lead => lead._id === payload.data.leadId)
          let thisIndexFiltered = state.data.filteredClosingLeads.indexOf(thisLeadFiltered)
          state.data.filteredClosingLeads.splice(thisIndexFiltered, 1)
        } else {
          let thisLead = state.data.awaitingActionLeads.find(lead => lead._id === payload.data.leadId)
          let thisIndex = state.data.awaitingActionLeads.indexOf(thisLead)
          state.data.awaitingActionLeads.splice(thisIndex, 1)
          let thisLeadFiltered = state.data.filteredAwaitingActionLeads.find(lead => lead._id === payload.data.leadId)
          let thisIndexFiltered = state.data.filteredAwaitingActionLeads.indexOf(thisLeadFiltered)
          state.data.filteredAwaitingActionLeads.splice(thisIndexFiltered, 1)
        }
      }
    })
    .addMatcher(apiSlice.endpoints.addLeadTag.matchFulfilled, (state, {payload}) => {
      if (payload.data.updateState) {
        if (payload.data.leadStatus === 'investigating') {
          let thisLead = state.data.investigatingLeads.find(lead => lead._id.toString() === payload.data.newLead._id.toString())
          let thisIndex = state.data.investigatingLeads.indexOf(thisLead)
          state.data.investigatingLeads.splice(thisIndex, 1)
          let thisLeadFiltered = state.data.filteredInvestigatingLeads.find(lead => lead._id.toString() === payload.data.newLead._id.toString())
          let thisIndexFiltered = state.data.filteredInvestigatingLeads.indexOf(thisLeadFiltered)
          state.data.filteredInvestigatingLeads.splice(thisIndexFiltered, 1)
        } else if (payload.data.leadStatus === 'closing') {
          let thisLead = state.data.closingLeads.find(lead => lead._id.toString() === payload.data.newLead._id.toString())
          let thisIndex = state.data.closingLeads.indexOf(thisLead)
          state.data.closingLeads.splice(thisIndex, 1)
          let thisLeadFiltered = state.data.filteredClosingLeads.find(lead => lead._id.toString() === payload.data.newLead._id.toString())
          let thisIndexFiltered = state.data.filteredClosingLeads.indexOf(thisLeadFiltered)
          state.data.filteredClosingLeads.splice(thisIndexFiltered, 1)
        } else {
          let thisLead = state.data.awaitingActionLeads.find(lead => lead._id.toString() === payload.data.newLead._id.toString())
          let thisIndex = state.data.awaitingActionLeads.indexOf(thisLead)
          state.data.awaitingActionLeads.splice(thisIndex, 1)
          let thisLeadFiltered = state.data.filteredAwaitingActionLeads.find(lead => lead._id.toString() === payload.data.newLead._id.toString())
          let thisIndexFiltered = state.data.filteredAwaitingActionLeads.indexOf(thisLeadFiltered)
          state.data.filteredAwaitingActionLeads.splice(thisIndexFiltered, 1)
        }
      }
    })
    .addMatcher(apiSlice.endpoints.dismissLead.matchFulfilled, (state, {payload}) => {
      if (payload.data.updateState) {
        if (payload.data.leadStatus === 'investigating') {
          let thisLead = state.data.investigatingLeads.find(lead => lead._id === payload.data.leadId)
          let thisIndex = state.data.investigatingLeads.indexOf(thisLead)
          state.data.investigatingLeads.splice(thisIndex, 1)
          let thisLeadFiltered = state.data.filteredInvestigatingLeads.find(lead => lead._id === payload.data.leadId)
          let thisIndexFiltered = state.data.filteredInvestigatingLeads.indexOf(thisLeadFiltered)
          state.data.filteredInvestigatingLeads.splice(thisIndexFiltered, 1)
        } else if (payload.data.leadStatus === 'closing') {
          let thisLead = state.data.closingLeads.find(lead => lead._id === payload.data.leadId)
          let thisIndex = state.data.closingLeads.indexOf(thisLead)
          state.data.closingLeads.splice(thisIndex, 1)
          let thisLeadFiltered = state.data.filteredClosingLeads.find(lead => lead._id === payload.data.leadId)
          let thisIndexFiltered = state.data.filteredClosingLeads.indexOf(thisLeadFiltered)
          state.data.filteredClosingLeads.splice(thisIndexFiltered, 1)
        } else {
          let thisLead = state.data.awaitingActionLeads.find(lead => lead._id === payload.data.leadId)
          let thisIndex = state.data.awaitingActionLeads.indexOf(thisLead)
          state.data.awaitingActionLeads.splice(thisIndex, 1)
          let thisLeadFiltered = state.data.filteredAwaitingActionLeads.find(lead => lead._id === payload.data.leadId)
          let thisIndexFiltered = state.data.filteredAwaitingActionLeads.indexOf(thisLeadFiltered)
          state.data.filteredAwaitingActionLeads.splice(thisIndexFiltered, 1)
        }
      }
    })
    .addMatcher(apiSlice.endpoints.setLeadStatusInvestigating.matchFulfilled, (state, {payload}) => {
      if (payload.data.updateState) {
        let thisLead = state.data.awaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
        let thisIndex = state.data.awaitingActionLeads.indexOf(thisLead)
        state.data.awaitingActionLeads.splice(thisIndex, 1)
        state.data.investigatingLeads.unshift(payload.data.newLead)
        let thisLeadFiltered = state.data.filteredAwaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
        let thisIndexFiltered = state.data.filteredAwaitingActionLeads.indexOf(thisLeadFiltered)
        state.data.filteredAwaitingActionLeads.splice(thisIndexFiltered, 1)
        state.data.filteredInvestigatingLeads.unshift(payload.data.newLead)
      }
    })
    .addMatcher(apiSlice.endpoints.setLeadStatusClosing.matchFulfilled, (state, {payload}) => {
      if (payload.data.updateState) {
        let thisLead = state.data.investigatingLeads.find(lead => lead._id === payload.data.newLead._id)
        let thisIndex = state.data.investigatingLeads.indexOf(thisLead)
        state.data.investigatingLeads.splice(thisIndex, 1)
        state.data.closingLeads.unshift(payload.data.newLead)
        let thisLeadFiltered = state.data.filteredInvestigatingLeads.find(lead => lead._id === payload.data.newLead._id)
        let thisIndexFiltered = state.data.filteredInvestigatingLeads.indexOf(thisLeadFiltered)
        state.data.filteredInvestigatingLeads.splice(thisIndexFiltered, 1)
        state.data.filteredClosingLeads.unshift(payload.data.newLead)
      }
    })
    .addMatcher(apiSlice.endpoints.setLeadStatusFinalized.matchFulfilled, (state, {payload}) => {
      console.log(payload.data)
      if (payload.data.updateState) {
        let thisLead = state.data.investigatingLeads.find(lead => lead._id === payload.data.newLead._id)
        let thisIndex = state.data.investigatingLeads.indexOf(thisLead)
        state.data.closingLeads.splice(thisIndex, 1)
        let thisLeadFiltered = state.data.filteredInvestigatingLeads.find(lead => lead._id === payload.data.newLead._id)
        let thisIndexFiltered = state.data.filteredInvestigatingLeads.indexOf(thisLeadFiltered)
        state.data.filteredClosingLeads.splice(thisIndexFiltered, 1)
        if (payload.data.activeDiscrepancies > 0) {
          state.data.awaitingUpdateLeads.push(payload.data.leadId)
          state.data.filteredAwaitingUpdateLeads.push(payload.data.leadId)
        }
      }
    })
    .addMatcher(apiSlice.endpoints.confirmPublicRecords.matchFulfilled, (state, {payload}) => {
      console.log(payload.data)
      if (payload.data.updateState) {
        let thisLead = state.data.awaitingUpdateLeads.find(lead => lead._id === payload.data.leadId)
        let thisIndex = state.data.awaitingUpdateLeads.indexOf(thisLead)
        state.data.awaitingUpdateLeads.splice(thisIndex, 1)
        let thisLeadFiltered = state.data.filteredAwaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
        let thisIndexFiltered = state.data.filteredAwaitingUpdateLeads.indexOf(thisLeadFiltered)
        state.data.filteredAwaitingUpdateLeads.splice(thisIndexFiltered, 1)
      }
      console.log('here')
    })
    .addMatcher(apiSlice.endpoints.addMortgageNote.matchFulfilled, (state, {payload}) => {
      if (payload.data.updateState) {
        if (payload.data.leadStatus === 'investigating') {
          let thisLead = state.data.investigatingLeads.find(lead => lead._id === payload.data.newLead._id)
          let thisIndex = state.data.investigatingLeads.indexOf(thisLead)
          state.data.investigatingLeads[thisIndex] = payload.data.newLead
          let thisLeadFiltered = state.data.filteredInvestigatingLeads.find(lead => lead._id === payload.data.newLead._id)
          let thisIndexFiltered = state.data.filteredInvestigatingLeads.indexOf(thisLeadFiltered)
          state.data.filteredInvestigatingLeads[thisIndexFiltered] = payload.data.newLead
        } else if (payload.data.leadStatus === 'closing') {
          let thisLead = state.data.closingLeads.find(lead => lead._id === payload.data.newLead._id)
          let thisIndex = state.data.closingLeads.indexOf(thisLead)
          state.data.closingLeads[thisIndex] = payload.data.newLead
          let thisLeadFiltered = state.data.filteredClosingLeads.find(lead => lead._id === payload.data.newLead._id)
          let thisIndexFiltered = state.data.filteredClosingLeads.indexOf(thisLeadFiltered)
          state.data.filteredClosingLeads[thisIndexFiltered] = payload.data.newLead
        } else {
          let thisLead = state.data.awaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
          let thisIndex = state.data.awaitingActionLeads.indexOf(thisLead)
          state.data.awaitingActionLeads[thisIndex] = payload.data.newLead
          let thisLeadFiltered = state.data.filteredAwaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
          let thisIndexFiltered = state.data.filteredAwaitingActionLeads.indexOf(thisLeadFiltered)
          state.data.filteredAwaitingActionLeads[thisIndexFiltered] = payload.data.newLead
        }
      }
    })
    .addMatcher(apiSlice.endpoints.addAssignees.matchFulfilled, (state, {payload}) => {
      if (payload.data.updateState) {
        if (payload.data.leadStatus === 'investigating') {
          let thisLead = state.data.investigatingLeads.find(lead => lead._id === payload.data.newLead._id)
          let thisIndex = state.data.investigatingLeads.indexOf(thisLead)
          state.data.investigatingLeads[thisIndex] = payload.data.newLead
          let thisLeadFiltered = state.data.filteredInvestigatingLeads.find(lead => lead._id === payload.data.newLead._id)
          let thisIndexFiltered = state.data.filteredInvestigatingLeads.indexOf(thisLeadFiltered)
          state.data.filteredInvestigatingLeads[thisIndexFiltered] = payload.data.newLead
        } else if (payload.data.leadStatus === 'closing') {
          let thisLead = state.data.closingLeads.find(lead => lead._id === payload.data.newLead._id)
          let thisIndex = state.data.closingLeads.indexOf(thisLead)
          state.data.closingLeads[thisIndex] = payload.data.newLead
          let thisLeadFiltered = state.data.filteredClosingLeads.find(lead => lead._id === payload.data.newLead._id)
          let thisIndexFiltered = state.data.filteredClosingLeads.indexOf(thisLeadFiltered)
          state.data.filteredClosingLeads[thisIndexFiltered] = payload.data.newLead
        } else {
          let thisLead = state.data.awaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
          let thisIndex = state.data.awaitingActionLeads.indexOf(thisLead)
          state.data.awaitingActionLeads[thisIndex] = payload.data.newLead
          let thisLeadFiltered = state.data.filteredAwaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
          let thisIndexFiltered = state.data.filteredAwaitingActionLeads.indexOf(thisLeadFiltered)
          state.data.filteredAwaitingActionLeads[thisIndexFiltered] = payload.data.newLead
        }
      }
    })
    .addMatcher(apiSlice.endpoints.updateMortgageDetails.matchFulfilled, (state, {payload}) => {
      if (payload.data.updateState) {
        if (payload.data.leadStatus === 'investigating') {
          //* investigatingLeads
          let thisInvestigatingLead = state.data.investigatingLeads.find(lead => lead._id === payload.data.newLead._id)
          let thisInvestigatingIndex = state.data.investigatingLeads.indexOf(thisInvestigatingLead)
          state.data.investigatingLeads[thisInvestigatingIndex] = payload.data.newLead
          let thisInvestigatingLeadFiltered = state.data.filteredInvestigatingLeads.find(lead => lead._id === payload.data.newLead._id)
          let thisInvestigatingIndexFiltered = state.data.filteredInvestigatingLeads.indexOf(thisInvestigatingLeadFiltered)
          state.data.filteredInvestigatingLeads[thisInvestigatingIndexFiltered] = payload.data.newLead
        } else if (payload.data.leadStatus === 'closing') {
          //* closingLeads
          let thisClosingLead = state.data.closingLeads.find(lead => lead._id === payload.data.newLead._id)
          let thisClosingIndex = state.data.closingLeads.indexOf(thisClosingLead)
          state.data.closingLeads[thisClosingIndex] = payload.data.newLead
          let thisClosingLeadFiltered = state.data.filteredClosingLeads.find(lead => lead._id === payload.data.newLead._id)
          let thisClosingIndexFiltered = state.data.filteredClosingLeads.indexOf(thisClosingLeadFiltered)
          state.data.filteredClosingLeads[thisClosingIndexFiltered] = payload.data.newLead
        } else {
          //* awaitingAction
          let thisClosingLead = state.data.awaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
          let thisClosingIndex = state.data.awaitingActionLeads.indexOf(thisClosingLead)
          state.data.awaitingActionLeads[thisClosingIndex] = payload.data.newLead
          let thisClosingLeadFiltered = state.data.filteredAwaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
          let thisClosingIndexFiltered = state.data.filteredAwaitingActionLeads.indexOf(thisClosingLeadFiltered)
          state.data.filteredAwaitingActionLeads[thisClosingIndexFiltered] = payload.data.newLead
        }
      }
    })
    .addMatcher(apiSlice.endpoints.saveLeadTargetUpdates.matchFulfilled, (state, {payload}) => {
      if (payload.data.updateState) {
        if (payload.data.leadStatus === 'investigating') {
          let thisLead = state.data.investigatingLeads.find(lead => lead._id === payload.data.newLead._id)
          let thisIndex = state.data.investigatingLeads.indexOf(thisLead)
          state.data.investigatingLeads[thisIndex] = payload.data.newLead
          let thisLeadFiltered = state.data.filteredInvestigatingLeads.find(lead => lead._id === payload.data.newLead._id)
          let thisIndexFiltered = state.data.filteredInvestigatingLeads.indexOf(thisLeadFiltered)
          state.data.filteredInvestigatingLeads[thisIndexFiltered] = payload.data.newLead
        } else if (payload.data.leadStatus === 'closing') {
          let thisLead = state.data.closingLeads.find(lead => lead._id === payload.data.newLead._id)
          let thisIndex = state.data.closingLeads.indexOf(thisLead)
          state.data.closingLeads[thisIndex] = payload.data.newLead
          let thisLeadFiltered = state.data.filteredClosingLeads.find(lead => lead._id === payload.data.newLead._id)
          let thisIndexFiltered = state.data.filteredClosingLeads.indexOf(thisLeadFiltered)
          state.data.filteredClosingLeads[thisIndexFiltered] = payload.data.newLead
        } else {
          let thisLead = state.data.awaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
          let thisIndex = state.data.awaitingActionLeads.indexOf(thisLead)
          state.data.awaitingActionLeads[thisIndex] = payload.data.newLead
          let thisLeadFiltered = state.data.filteredAwaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
          let thisIndexFiltered = state.data.filteredAwaitingActionLeads.indexOf(thisLeadFiltered)
          state.data.filteredAwaitingActionLeads[thisIndexFiltered] = payload.data.newLead
        }
      }
    })
    .addMatcher(apiSlice.endpoints.changeTargetOutcome.matchFulfilled, (state, {payload}) => {
      if (payload.data.updateState) {
        if (payload.data.leadStatus === 'investigating') {
          let thisLead = state.data.investigatingLeads.find(lead => lead._id === payload.data.newLead._id)
          let thisIndex = state.data.investigatingLeads.indexOf(thisLead)
          state.data.investigatingLeads[thisIndex] = payload.data.newLead
          let thisLeadFiltered = state.data.filteredInvestigatingLeads.find(lead => lead._id === payload.data.newLead._id)
          let thisIndexFiltered = state.data.filteredInvestigatingLeads.indexOf(thisLeadFiltered)
          state.data.filteredInvestigatingLeads[thisIndexFiltered] = payload.data.newLead
        } else if (payload.data.leadStatus === 'closing') {
          let thisLead = state.data.closingLeads.find(lead => lead._id === payload.data.newLead._id)
          let thisIndex = state.data.closingLeads.indexOf(thisLead)
          state.data.closingLeads[thisIndex] = payload.data.newLead
          let thisLeadFiltered = state.data.filteredClosingLeads.find(lead => lead._id === payload.data.newLead._id)
          let thisIndexFiltered = state.data.filteredClosingLeads.indexOf(thisLeadFiltered)
          state.data.filteredClosingLeads[thisIndexFiltered] = payload.data.newLead
        } else {
          let thisLead = state.data.awaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
          let thisIndex = state.data.awaitingActionLeads.indexOf(thisLead)
          state.data.awaitingActionLeads[thisIndex] = payload.data.newLead
          let thisLeadFiltered = state.data.filteredAwaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
          let thisIndexFiltered = state.data.filteredAwaitingActionLeads.indexOf(thisLeadFiltered)
          state.data.filteredAwaitingActionLeads[thisIndexFiltered] = payload.data.newLead
        }
      }
    })
    .addMatcher(apiSlice.endpoints.recordSweep.matchFulfilled, (state, {payload}) => {
      for (let i = 0; i < payload.data.updatedLeads.length; i++) {
        let thisLead = state.data.awaitingActionLeads.find(lead => lead._id === payload.data.updatedLeads[i])
        if (thisLead) {
          let thisIndex = state.data.awaitingActionLeads.indexOf(thisLead)
          let deletedUser = state.data.awaitingActionLeads[thisIndex].assigneeIds.find(assignee => assignee.toString() === payload.data.deleteUserId) 
          let thisUserIndex = state.data.awaitingActionLeads[thisIndex].assigneeIds.indexOf(deletedUser)
          state.data.awaitingActionLeads[thisIndex].assigneeIds.splice(thisUserIndex, 1)
        } else {
          let thisLead = state.data.investigatingLeads.find(lead => lead._id === payload.data.updatedLeads[i])
          if (thisLead) {
            let thisIndex = state.data.investigatingLeads.indexOf(thisLead)
            let deletedUser = state.data.investigatingLeads[thisIndex].assigneeIds.find(assignee => assignee.toString() === payload.data.deleteUserId) 
            let thisUserIndex = state.data.investigatingLeads[thisIndex].assigneeIds.indexOf(deletedUser)
            state.data.investigatingLeads[thisIndex].assigneeIds.splice(thisUserIndex, 1)
          } else {
            let thisLead = state.data.closingLeads.find(lead => lead._id === payload.data.updatedLeads[i])
            if (thisLead) {
              let thisIndex = state.data.closingLeads.indexOf(thisLead)
              let deletedUser = state.data.closingLeads[thisIndex].assigneeIds.find(assignee => assignee.toString() === payload.data.deleteUserId) 
              let thisUserIndex = state.data.closingLeads[thisIndex].assigneeIds.indexOf(deletedUser)
              state.data.closingLeads[thisIndex].assigneeIds.splice(thisUserIndex, 1)
            }
          }
        }
        let thisFilteredLead = state.data.filteredAwaitingActionLeads.find(lead => lead._id === payload.data.updatedLeads[i])
        if (thisLead) {
          let thisIndex = state.data.filteredAwaitingActionLeads.indexOf(thisFilteredLead)
          let deletedUser = state.data.filteredAwaitingActionLeads[thisIndex].assigneeIds.find(assignee => assignee.toString() === payload.data.deleteUserId) 
          let thisUserIndex = state.data.filteredAwaitingActionLeads[thisIndex].assigneeIds.indexOf(deletedUser)
          state.data.filteredAwaitingActionLeads[thisIndex].assigneeIds.splice(thisUserIndex, 1)
        } else {
          let thisLead = state.data.filteredInvestigatingLeads.find(lead => lead._id === payload.data.updatedLeads[i])
          if (thisLead) {
            let thisIndex = state.data.filteredInvestigatingLeads.indexOf(thisLead)
            let deletedUser = state.data.filteredInvestigatingLeads[thisIndex].assigneeIds.find(assignee => assignee.toString() === payload.data.deleteUserId) 
            let thisUserIndex = state.data.filteredInvestigatingLeads[thisIndex].assigneeIds.indexOf(deletedUser)
            state.data.filteredInvestigatingLeads[thisIndex].assigneeIds.splice(thisUserIndex, 1)
          } else {
            let thisLead = state.data.filteredClosingLeads.find(lead => lead._id === payload.data.updatedLeads[i])
            if (thisLead) {
              let thisIndex = state.data.filteredClosingLeads.indexOf(thisLead)
              let deletedUser = state.data.filteredClosingLeads[thisIndex].assigneeIds.find(assignee => assignee.toString() === payload.data.deleteUserId) 
              let thisUserIndex = state.data.filteredClosingLeads[thisIndex].assigneeIds.indexOf(deletedUser)
              state.data.filteredClosingLeads[thisIndex].assigneeIds.splice(thisUserIndex, 1)
            }
          }
        }
        for (let i = 0; i < payload.data.newLeads.length; i++) {
          state.data.awaitingActionLeads.unshift(payload.data.newLeads[i])
          state.data.filteredAwaitingActionLeads.unshift(payload.data.newLeads[i])
        }
      }
    })
  }
})

export const { setMemberActiveLeads, setSortedMemberInvestigatingLeads, setSortedMemberAwaitingActionLeads, setFilteredMemberAwaitingActionLeads, setSortedMemberClosingLeads, setFilteredMemberInvestigatingLeads, setFilteredMemberClosingLeads, setSortedMemberAwaitingUpdateLeads, setFilteredMemberAwaitingUpdateLeads } = memberActiveLeadsSlice.actions

export default memberActiveLeadsSlice.reducer

export const selectMemberAwaitingActionLeads = state => state.memberActiveLeads.data.awaitingActionLeads
export const selectMemberInvestigatingLeads = state => state.memberActiveLeads.data.investigatingLeads
export const selectMemberClosingLeads = state => state.memberActiveLeads.data.closingLeads
export const selectMemberAwaitingUpdateLeads = state => state.memberActiveLeads.data.awaitingUpdateLeads
export const selectFilteredMemberAwaitingActionLeads = state => state.memberActiveLeads.data.filteredAwaitingActionLeads
export const selectFilteredMemberInvestigatingLeads = state => state.memberActiveLeads.data.filteredInvestigatingLeads
export const selectFilteredMemberClosingLeads = state => state.memberActiveLeads.data.filteredClosingLeads
export const selectFilteredMemberAwaitingUpdateLeads = state => state.memberActiveLeads.data.filteredAwaitingUpdateLeads
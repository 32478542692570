import React from "react";
import { ColumnContent, HorizontalContainer, MainContent, ModalContentContainer, VerticalContainer } from "../../../../../../../StyledComponents";

const View = ({ data }) => {

  return (
    <ModalContentContainer className="topColumn">
      <HorizontalContainer className='fitContent'>
        <VerticalContainer className="fitContent">
          <ColumnContent className="bottomBorder">
            ID
          </ColumnContent>
          <ColumnContent>
            <MainContent>{data._id}</MainContent>
          </ColumnContent>
        </VerticalContainer>
        <VerticalContainer className="fitContent">
          <ColumnContent className="bottomBorder">
            Name
          </ColumnContent>
          <ColumnContent>
            <MainContent>{data.fullName}</MainContent>
          </ColumnContent>
        </VerticalContainer>
        <VerticalContainer className="fitContent">
          <ColumnContent className="bottomBorder">
            Date Created
          </ColumnContent>
          <ColumnContent>
            <MainContent>{data.dateCreated}</MainContent>
          </ColumnContent>
        </VerticalContainer>
      </HorizontalContainer>
    </ModalContentContainer>
  )
}

export default View;
import React from "react";
import View from "./components/View";
import Delete from "./components/Delete";
import CancelIcon from '@mui/icons-material/Cancel';
import { useSelector } from "react-redux";
import DataBaseModalNav from "./DataBaseModalNav";
import { selectDatabaseActionsNavSelection } from "../../../../../../slices/navMenuSelectionsSlice";
import { ActionIconWrapper, ComponentBorder, ModalContainer } from "../../../../../../StyledComponents";

const DataBaseModal = ({ handleCloseActionsModal, data, userId }) => {

  const navSelection = useSelector(selectDatabaseActionsNavSelection)

  return (
    <ModalContainer className="twoPanel">
      <ComponentBorder className="top">
        <ActionIconWrapper className="medium grayText circle hoverBorder hover" style={{margin: 'auto 2rem auto 0'}}>
          <CancelIcon onClick={() => handleCloseActionsModal()} style={{height: '100%', width: '100%'}} />  
        </ActionIconWrapper>
      </ComponentBorder>
      <DataBaseModalNav />
      {navSelection === 'view' ?
        <View data={data} />
      : navSelection === 'delete' ?
        <Delete handleCloseActionsModal={handleCloseActionsModal} data={data} userId={userId} />
      :
        <></>
      }
      <ComponentBorder className="left"></ComponentBorder>
      <ComponentBorder className="right" style={{gridColumn: '9'}}></ComponentBorder>
      <ComponentBorder className="bottom"></ComponentBorder>
    </ModalContainer>
  )
}

export default DataBaseModal;
import React, { useState } from "react";
import EditIcon from '@mui/icons-material/Edit';
import { useSelector } from "react-redux";
import NotificationScheduleSelection from "../../../../../components/CheckBoxSelections/NotificationScheduleSelection";
import { selectUserEmailScheduleLabel } from "../../../../../slices/teamAndUserSettingsSlice";
import { ActionContainer, ComponentContainer, FlexWrapper, ComponentSectionRow, ColumnContent, ComponentBorder, RowsContainer } from "../../../../../StyledComponents";

const ListMembers = ({ memberData, userId, userFullName }) => {

  const userEmailScheduleLabel = useSelector(selectUserEmailScheduleLabel)

  const [editEmailSchedule, setEditEmailSchedule] = useState(false)
  const [currentSchedule, setCurrentSchedule] = useState({})

  const handleEditSchedule = () => {
    if (userEmailScheduleLabel === 'Receive All Emails') {
      setCurrentSchedule({leadsUpdated: true, weeklyPerformance: true, monthlyScans: true})
    } else if (userEmailScheduleLabel === 'No Lead Emails') {
      setCurrentSchedule({leadsUpdated: false, weeklyPerformance: true, monthlyScans: true})
    } else if (userEmailScheduleLabel === 'No Performance Emails') {
      setCurrentSchedule({leadsUpdated: true, weeklyPerformance: false, monthlyScans: true})
    } else if (userEmailScheduleLabel === 'No Scan Emails') {
      setCurrentSchedule({leadsUpdated: true, weeklyPerformance: true, monthlyScans: false})
    } else if (userEmailScheduleLabel === 'Only Scan Emails') {
      setCurrentSchedule({leadsUpdated: false, weeklyPerformance: false, monthlyScans: true})
    } else if (userEmailScheduleLabel === 'Only Performance Emails') {
      setCurrentSchedule({leadsUpdated: false, weeklyPerformance: true, monthlyScans: false})
    } else if (userEmailScheduleLabel === 'Only Lead Emails') {
      setCurrentSchedule({leadsUpdated: true, weeklyPerformance: false, monthlyScans: false})
    } else if (userEmailScheduleLabel === 'No Emails') {
      setCurrentSchedule({leadsUpdated: false, weeklyPerformance: false, monthlyScans: false})
    }
    setEditEmailSchedule(true)
  }

  return (
    <ComponentContainer className="justifyStart" style={{gridRow: '3', gridColumn: '1/4', margin: 'auto'}}>
      <FlexWrapper className="column" style={{gridRow: '2', gridColumn: '2'}}>
        <ComponentSectionRow className="listMembers header">
          <ColumnContent className="header" style={{gridColumn: '1'}}>
            Role
          </ColumnContent>
          <ColumnContent className="header" style={{gridColumn: '2'}}>
            Name
          </ColumnContent>
          <ColumnContent className="header" style={{gridColumn: '3'}}>
            eMail
          </ColumnContent>
          <ColumnContent className="header" style={{gridColumn: '4'}}>
            Email Notification Settings
          </ColumnContent>
        </ComponentSectionRow>
        <RowsContainer style={{height: '100%'}}>
          {memberData.map((teamMember, index) => (
            <>
              <ComponentSectionRow key={index} className={(index%2 === 1 ? 'even ' : '') + ((editEmailSchedule && teamMember.memberId === userId) ? 'closed open ' : 'closed ') + 'listMembers'} style={{padding: '0.5rem 0'}}>
                <ColumnContent className="detail small" style={{gridColumn: '1'}}>
                  {teamMember.role === 'super' ?
                    'App Admin'
                  :
                    'Manager'
                  }
                </ColumnContent>
                <ColumnContent className="detail small" style={{gridColumn: '2'}}>
                  {teamMember.fullName}
                </ColumnContent>
                <ColumnContent className="detail small lowercase" style={{gridColumn: '3'}}>
                  {teamMember.email}
                </ColumnContent>
                <ColumnContent className="detail small lowercase" style={{gridColumn: '4', alignItems: 'center'}}>
                  {teamMember.memberId === userId && !editEmailSchedule ?
                    <FlexWrapper className="alignCenter justifyCenter" style={{marginLeft: '3.5rem'}}>
                      {teamMember.emailScheduleLabel}
                        <div style={{display: 'flex', justifyContent: 'center', marginLeft: '1rem'}}>
                          <ActionContainer className="edit" onClick={() => handleEditSchedule()}>
                            <EditIcon style={{height: "1.5rem", width: "1.5rem"}} />
                          </ActionContainer>
                        </div>
                    </FlexWrapper>
                  :
                    <FlexWrapper className="alignCenter justifyCenter" style={{marginLeft: '3.5rem'}}>
                      {teamMember.emailScheduleLabel}
                      <div style={{height: '2rem', width: '2.5rem', marginLeft: '1rem'}} />
                    </FlexWrapper>
                  }
                </ColumnContent>
                <ComponentSectionRow className={(editEmailSchedule && teamMember.memberId === userId) ? 'onDisplay' : 'hidden'} style={{gridRow: '2', gridColumn: '4', width: 'fit-content'}}>
                  <NotificationScheduleSelection currentSchedule={currentSchedule} setEditEmailSchedule={setEditEmailSchedule} userFullName={userFullName} userId={userId} />
                </ComponentSectionRow>
              </ComponentSectionRow>
            </>
          ))}
        </RowsContainer>
      </FlexWrapper>
      <ComponentBorder className="top" style={{gridRow:'1', gridColumn: '1/4'}} />
      <ComponentBorder className="left" style={{gridRow:'2', gridColumn: '1'}} />
      <ComponentBorder className="right"  style={{gridRow:'2', gridColumn: '3'}} />
      <ComponentBorder className="bottom" style={{gridRow:'3', gridColumn: '1/4'}} />
    </ComponentContainer>
  )
}

export default ListMembers;
import React from "react";
import { useTheme } from "@mui/styles";
import { Detail, DetailContainer, DetailInfoContainer, DetailListContainer } from "../../../StyledComponents";

const UnderInvestigation = ({milestone}) => {
  const theme = useTheme()

  return (
    <DetailContainer className="fitContent">
      <Detail className="timelineHeader">{milestone.milestone}{milestone.notify ? <span style={{color: `${theme.palette.primary.main}`, margin: '0.25rem 0 0 0.5rem', fontWeight: '400'}}>new</span> : <></>}</Detail>
      <DetailListContainer className='timeline'>
        <DetailInfoContainer className="item timeline">
          <Detail className="timelineDetail">initiated by:</Detail>
          <Detail className="timelineDetail">date:</Detail>
          {milestone.details.length === 0 ?
            <></>
          : milestone.details.length === 1 ?
            <Detail className="timelineDetail">assignee:</Detail>
          :
            <Detail className="timelineDetail">assignees:</Detail>
          }
        </DetailInfoContainer>
        <DetailInfoContainer className="space">
          <Detail className="timelineItem">{milestone.contributor}</Detail>
          <Detail className="timelineItem">{milestone.date}</Detail>
          {milestone.details.map((detail, index) => (
            <Detail className="timelineItem" key={index}>{detail}</Detail>
          ))}
        </DetailInfoContainer>
      </DetailListContainer>
    </DetailContainer>
    )
}

export default UnderInvestigation
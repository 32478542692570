import React, { useState } from "react";
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { useTheme } from "@mui/styles";
import GenericDropdown from "../DropDowns/GenericDropdown.js";
import CircularProgress from "@mui/material/CircularProgress";
import { selectLoadData, setDataIsLoaded } from "../../slices/loadDataSlice.js";
import { selectUserFullName } from "../../slices/userInfoSlice.js";
import { useRecordSweepMutation } from "../../slices/api/apiSlice.js";
import { useDispatch, useSelector } from "react-redux";
import { Badge, Button, ButtonWrapper, FlexWrapper, SubHeader } from "../../StyledComponents.js";

const SingleScanControls = ({ teamId, userId, mortgageId, existingScans }) => {
  const theme = useTheme()
  const dispatch = useDispatch()

  const dataIsLoaded = useSelector(selectLoadData)
  const userFullName = useSelector(selectUserFullName)

  const [sweepTypeSelection, setSweepTypeSelection] = useState('Initial Match')
  const [retainLastSalePriceSelection, setRetainLastSalePriceSelection] = useState('true')
  const [createLeadSelection, setCreateLeadSelection] = useState('false')
  const [recordMonthlyStatsSelection, setRecordMonthlyStatsSelection] = useState('false')
  const [recordQueryPerformanceSelection, setRecordQueryPerformanceSelection] = useState('true')
  const [notifyAssigneesSelection, setNotifyAssigneesSelection] = useState('false')
  const [matchPublicRecordsAllSelection, setMatchPublicRecordsAllSelection] = useState('true')
  const [matchPublicRecordsNonParametersSelection, setMatchPublicRecordsNonParametersSelection] = useState('true')
  const [recordTeamStatsSelection, setRecordTeamStatsSelection] = useState('false')
  const [updateLastQuerySelection, setUpdateLastQuerySelection] = useState('false')

  const handleSweepTypeChange = async (event) => {
    if (event.target.value === 'Initial Match') {
      setNotifyAssigneesSelection('false')
      setRecordMonthlyStatsSelection('false')
      setRecordTeamStatsSelection('false')
      setUpdateLastQuerySelection('false')
      setCreateLeadSelection('false')
      setRecordQueryPerformanceSelection('true')
      setMatchPublicRecordsAllSelection('true')
      setMatchPublicRecordsNonParametersSelection('true')
      setRetainLastSalePriceSelection('true')
    } else if (event.target.value === 'Check All Discrepancies') {
      setNotifyAssigneesSelection('false')
      setRecordMonthlyStatsSelection('false')
      setRecordTeamStatsSelection('false')
      setUpdateLastQuerySelection('false')
      setCreateLeadSelection('true')
      setRecordQueryPerformanceSelection('true')
      setMatchPublicRecordsAllSelection('false')
      setMatchPublicRecordsNonParametersSelection('false')
      setRetainLastSalePriceSelection('true')
    } else if (event.target.value === 'Check Parameter Discrepancies') {
      setNotifyAssigneesSelection('false')
      setRecordMonthlyStatsSelection('false')
      setRecordTeamStatsSelection('false')
      setUpdateLastQuerySelection('false')
      setCreateLeadSelection('true')
      setRecordQueryPerformanceSelection('true')
      setMatchPublicRecordsAllSelection('false')
      setMatchPublicRecordsNonParametersSelection('true')
      setRetainLastSalePriceSelection('true')
    } else if (event.target.value === 'Standard + Retain Price') {
      setNotifyAssigneesSelection('true')
      setRecordMonthlyStatsSelection('true')
      setRecordTeamStatsSelection('true')
      setUpdateLastQuerySelection('true')
      setCreateLeadSelection('true')
      setRecordQueryPerformanceSelection('true')
      setMatchPublicRecordsAllSelection('false')
      setMatchPublicRecordsNonParametersSelection('false')
      setRetainLastSalePriceSelection('true')
    } else {
      setNotifyAssigneesSelection('true')
      setRecordMonthlyStatsSelection('true')
      setRecordTeamStatsSelection('true')
      setUpdateLastQuerySelection('true')
      setCreateLeadSelection('true')
      setRecordQueryPerformanceSelection('true')
      setMatchPublicRecordsAllSelection('false')
      setMatchPublicRecordsNonParametersSelection('false')
      setRetainLastSalePriceSelection('false')
    }
    setSweepTypeSelection(event.target.value)
  }

  const [workflowStage, setWorkflowStage] = useState(null)
  const [recordSweep, {}] = useRecordSweepMutation()
  const handleClick = async (stage) => {
    if (stage === 'initiate') {
      setWorkflowStage('initiate')
    } else if (stage === 'submit') {
      dispatch( setDataIsLoaded(false) )
      setWorkflowStage(null)
      let requestObj = {
        teamId: teamId,
        userId: userId,
        userFullName: userFullName,
        mortgageId: mortgageId,
        querySettings: {
          sweepType: sweepTypeSelection,
          sweepSize: '1',
          existingScans: 'Any',
          createLead: createLeadSelection,
          recordNewMonthlyStats: recordMonthlyStatsSelection,
          recordPerformance: recordQueryPerformanceSelection,
          notifyAssignees: notifyAssigneesSelection,
          matchPublicRecordsAll: matchPublicRecordsAllSelection,
          matchPublicRecordsNonParameters: matchPublicRecordsNonParametersSelection,
          retainLastSalePrice: retainLastSalePriceSelection,
          updateTeamStats: recordTeamStatsSelection,
          updateLastQuery: updateLastQuerySelection,
          varietyMortgages: 'false',
        }
      }
      await recordSweep(requestObj)
      dispatch( setDataIsLoaded(true) )
    } else if (stage === 'cancel') {
      setWorkflowStage(null)
    }
  }

  return (
    <FlexWrapper style={{gridRow: '2/5', gridColumn: '3', width: '100%', height: '100%'}}>
      <FlexWrapper className="column" style={{height: 'fit-content', margin: 'auto 0'}}>
        <FlexWrapper className='column' style={{width: '20rem', margin: '0 auto', height: 'fit-content'}}>
          <SubHeader style={{padding: '0.5rem 0 0 0'}}>Scan Type</SubHeader>
          <GenericDropdown 
            value={sweepTypeSelection}
            handleChange={handleSweepTypeChange}
            disabled={false}
            items={[
              {label: 'Initial Match'},
              {label: 'Check All Discrepancies'},
              {label: 'Check Parameter Discrepancies'},
              {label: 'Standard'},
              {label: 'Standard + Retain Price'},
            ]} 
          />
          <FlexWrapper className="alignCenter" style={{width: 'fit-content', margin: '2rem auto 0 auto'}}>
            <Badge className="two" style={{height: '2rem', width: '2rem'}}>{existingScans}</Badge>
            {existingScans === 1 ?
              <div>Record Scan performed on this mortgage</div>
            :
              <div>Record Scans performed on this mortgage</div>
            }
          </FlexWrapper>
        </FlexWrapper>
        <FlexWrapper className="column" style={{width: '40rem', height: 'fit-content', margin: '5rem auto'}}>
          <FlexWrapper className='justifyBetween' style={{margin: '2rem 0 0 0'}}>
            {matchPublicRecordsAllSelection === 'true' ?
              <SubHeader className="secondary">Match PR All</SubHeader>
            :
              <SubHeader>Match PR All</SubHeader>
            }
            {matchPublicRecordsNonParametersSelection === 'true' ?
              <SubHeader className="secondary">Match PR Parameters</SubHeader>
            :
              <SubHeader>Match PR Parameters</SubHeader>
            }
            {createLeadSelection === 'true' ?
              <SubHeader className="secondary">Create Leads</SubHeader>
            :
              <SubHeader>Create Leads</SubHeader>
            }
          </FlexWrapper>
          <FlexWrapper className='justifyBetween' style={{margin: '2rem 0 0 0'}}>
            {recordQueryPerformanceSelection === 'true' ?
              <SubHeader className="secondary">Record Sweep Performance</SubHeader>
            :
              <SubHeader>Record Sweep Performance</SubHeader>
            }
            {retainLastSalePriceSelection === 'true' ?
              <SubHeader className="secondary">Retain Last Sale Price</SubHeader>
            :
              <SubHeader>Retain Last Sale Price</SubHeader>
            }
            {notifyAssigneesSelection === 'true' ?
              <SubHeader className="secondary">Notify Assignees</SubHeader>
            :
              <SubHeader>Notify Assignees</SubHeader>
            }
          </FlexWrapper>
          <FlexWrapper className='justifyBetween' style={{margin: '2rem 0 0 0'}}>
            {recordMonthlyStatsSelection === 'true' ?
              <SubHeader className="secondary">Record Monthly Stats</SubHeader>
            :
              <SubHeader>Record Monthly Stats</SubHeader>
            }
            {recordTeamStatsSelection === 'true' ?
              <SubHeader className="secondary">Record Team Stats</SubHeader>
            :
              <SubHeader>Record Team Stats</SubHeader>
            }
            {updateLastQuerySelection === 'true' ?
              <SubHeader className="secondary">Record Last Query</SubHeader>
            :
              <SubHeader>Record Last Query</SubHeader>
            }
          </FlexWrapper>
        </FlexWrapper>
        {workflowStage === 'initiate' ?
          <ButtonWrapper className="justifyCenter" style={{width: '11rem', margin: '0 auto'}}>
            <Button className="primary prompt" onClick={() => handleClick('submit')} style={{marginRight: '0.75rem'}}>
              {!dataIsLoaded ?
                <CircularProgress
                  sx={{color: `${theme.palette.common.white}`}}
                  size={12}
                />
              :
                <CheckIcon />
              }
            </Button>
            <Button className="secondary prompt" onClick={() => handleClick('cancel')} style={{marginLeft: '0.75rem'}}>
              <ClearIcon />
            </Button>
          </ButtonWrapper>
        :
          <ButtonWrapper style={{width: '11rem', margin: '0 auto'}}>
            <Button className="secondary" onClick={() => handleClick('initiate')} style={{width: '10rem', margin: '0'}}>Run Scan</Button>
          </ButtonWrapper>
        }
      </FlexWrapper>
    </FlexWrapper>
  )
}

export default SingleScanControls
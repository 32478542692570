export default async function handleLeadNewFilterChanges(eventValue, leadsAwaitingAction, investigatingLeads, closingLeads, leadTierFilter, leadTypeFilter) {
    let awaitingAction = await handleAwaitingAction(eventValue, leadsAwaitingAction, leadTierFilter);
    let investigating = await handleInvestigating(eventValue, investigatingLeads, leadTierFilter, leadTypeFilter);
    let closing = await handleClosing(eventValue, closingLeads, leadTierFilter, leadTypeFilter);
    return {awaitingAction, investigating, closing}
  }

  let handleAwaitingAction = async (eventValue, leadsAwaitingAction, leadTierFilter) => {
    let filteredLeadsAwaitingAction = leadsAwaitingAction
    if (eventValue === 'false') {
      if (leadTierFilter === 'All' || !leadTierFilter) {

      } else if (leadTierFilter === 'Manual') {
        filteredLeadsAwaitingAction = [...leadsAwaitingAction].filter((lead) => lead.tier === 0)
      } else if (leadTierFilter === 'Tier 1') {
        filteredLeadsAwaitingAction = [...leadsAwaitingAction].filter((lead) => lead.tier === 1)
      } else if (leadTierFilter === 'Tier 2') {
        filteredLeadsAwaitingAction = [...leadsAwaitingAction].filter((lead) => lead.tier === 2)
      } else {
        filteredLeadsAwaitingAction = [...leadsAwaitingAction].filter((lead) => lead.tier === 3)
      }
    } else {
      if (leadTierFilter === 'All' || !leadTierFilter) {
        filteredLeadsAwaitingAction = [...leadsAwaitingAction].filter((lead) => lead.newLeadLabel === true)
      } else if (leadTierFilter === 'Manual') {
        filteredLeadsAwaitingAction = [...leadsAwaitingAction].filter((lead) => lead.tier === 0 && lead.newLeadLabel === true)
      } else if (leadTierFilter === 'Tier 1') {
        filteredLeadsAwaitingAction = [...leadsAwaitingAction].filter((lead) => lead.tier === 1 && lead.newLeadLabel === true)
      } else if (leadTierFilter === 'Tier 2') {
        filteredLeadsAwaitingAction = [...leadsAwaitingAction].filter((lead) => lead.tier === 2 && lead.newLeadLabel === true)
      } else {
        filteredLeadsAwaitingAction = [...leadsAwaitingAction].filter((lead) => lead.tier === 3 && lead.newLeadLabel === true)
      }
    }
    return filteredLeadsAwaitingAction
  }
  let handleInvestigating = async (eventValue, investigatingLeads, leadTierFilter, leadTypeFilter) => {
    let filteredInvestigatingLeads = investigatingLeads
    if (eventValue === 'true') {
      if (leadTypeFilter === 'All' || !leadTypeFilter) {
        if (leadTierFilter === 'All' || !leadTierFilter) {
          filteredInvestigatingLeads = [...investigatingLeads].filter((lead) => lead.newAssignmentNotification === true)
        } else if (leadTierFilter === 'Manual') {
          filteredInvestigatingLeads = [...investigatingLeads].filter((lead) => lead.newAssignmentNotification === true && lead.tier === 0)
        } else if (leadTierFilter === 'Tier 1') {
          filteredInvestigatingLeads = [...investigatingLeads].filter((lead) => lead.newAssignmentNotification === true && lead.tier === 1)
        } else if (leadTierFilter === 'Tier 2') {
          filteredInvestigatingLeads = [...investigatingLeads].filter((lead) => lead.newAssignmentNotification === true && lead.tier === 2)
        } else {
          filteredInvestigatingLeads = [...investigatingLeads].filter((lead) => lead.newAssignmentNotification === true && lead.tier === 3)
        }
      } else if (leadTypeFilter === 'Renegotiation') {
        if (leadTierFilter === 'All' || !leadTierFilter) {
          filteredInvestigatingLeads = [...investigatingLeads].filter((lead) => lead.newAssignmentNotification === true)
        } else if (leadTierFilter === 'Manual') {
          filteredInvestigatingLeads = [...investigatingLeads].filter((lead) => lead.newAssignmentNotification === true && lead.targetOutcome === 'renegotiation' && lead.tier === 0)
        } else if (leadTierFilter === 'Tier 1') {
          filteredInvestigatingLeads = [...investigatingLeads].filter((lead) => lead.newAssignmentNotification === true && lead.targetOutcome === 'renegotiation' && lead.tier === 1)
        } else if (leadTierFilter === 'Tier 2') {
          filteredInvestigatingLeads = [...investigatingLeads].filter((lead) => lead.newAssignmentNotification === true && lead.targetOutcome === 'renegotiation' && lead.tier === 2)
        } else {
          filteredInvestigatingLeads = [...investigatingLeads].filter((lead) => lead.newAssignmentNotification === true && lead.targetOutcome === 'renegotiation' && lead.tier === 3)
        }
      } else {
        if (leadTierFilter === 'All' || !leadTierFilter) {
          filteredInvestigatingLeads = [...investigatingLeads].filter((lead) => lead.newAssignmentNotification === true)
        } else if (leadTierFilter === 'Manual') {
          filteredInvestigatingLeads = [...investigatingLeads].filter((lead) => lead.newAssignmentNotification === true && lead.targetOutcome === 'refinance' && lead.tier === 0)
        } else if (leadTierFilter === 'Tier 1') {
          filteredInvestigatingLeads = [...investigatingLeads].filter((lead) => lead.newAssignmentNotification === true && lead.targetOutcome === 'refinance' && lead.tier === 1)
        } else if (leadTierFilter === 'Tier 2') {
          filteredInvestigatingLeads = [...investigatingLeads].filter((lead) => lead.newAssignmentNotification === true && lead.targetOutcome === 'refinance' && lead.tier === 2)
        } else {
          filteredInvestigatingLeads = [...investigatingLeads].filter((lead) => lead.newAssignmentNotification === true && lead.targetOutcome === 'refinance' && lead.tier === 3)
        }
      }
    } else {
      if (leadTypeFilter === 'All' || !leadTypeFilter) {
        if (leadTierFilter === 'All' || !leadTierFilter) {

        } else if (leadTierFilter === 'Manual') {
          filteredInvestigatingLeads = [...investigatingLeads].filter((lead) => lead.tier === 0)
        } else if (leadTierFilter === 'Tier 1') {
          filteredInvestigatingLeads = [...investigatingLeads].filter((lead) => lead.tier === 1)
        } else if (leadTierFilter === 'Tier 2') {
          filteredInvestigatingLeads = [...investigatingLeads].filter((lead) => lead.tier === 2)
        } else {
          filteredInvestigatingLeads = [...investigatingLeads].filter((lead) => lead.tier === 3)
        }
      } else if (leadTypeFilter === 'Renegotiation') {
        if (leadTierFilter === 'All' || !leadTierFilter) {

        } else if (leadTierFilter === 'Manual') {
          filteredInvestigatingLeads = [...investigatingLeads].filter((lead) => lead.targetOutcome === 'renegotiation' && lead.tier === 0)
        } else if (leadTierFilter === 'Tier 1') {
          filteredInvestigatingLeads = [...investigatingLeads].filter((lead) => lead.targetOutcome === 'renegotiation' && lead.tier === 1)
        } else if (leadTierFilter === 'Tier 2') {
          filteredInvestigatingLeads = [...investigatingLeads].filter((lead) => lead.targetOutcome === 'renegotiation' && lead.tier === 2)
        } else {
          filteredInvestigatingLeads = [...investigatingLeads].filter((lead) => lead.targetOutcome === 'renegotiation' && lead.tier === 3)
        }
      } else {
        if (leadTierFilter === 'All' || !leadTierFilter) {

        } else if (leadTierFilter === 'Manual') {
          filteredInvestigatingLeads = [...investigatingLeads].filter((lead) => lead.targetOutcome === 'refinance' && lead.tier === 0)
        } else if (leadTierFilter === 'Tier 1') {
          filteredInvestigatingLeads = [...investigatingLeads].filter((lead) => lead.targetOutcome === 'refinance' && lead.tier === 1)
        } else if (leadTierFilter === 'Tier 2') {
          filteredInvestigatingLeads = [...investigatingLeads].filter((lead) => lead.targetOutcome === 'refinance' && lead.tier === 2)
        } else {
          filteredInvestigatingLeads = [...investigatingLeads].filter((lead) => lead.targetOutcome === 'refinance' && lead.tier === 3)
        }
      }
    }
    return filteredInvestigatingLeads
  }
  let handleClosing = async (eventValue, closingLeads, leadTierFilter, leadTypeFilter) => {
    let filteredClosingLeads = closingLeads
    if (eventValue === 'true') {
      if (leadTypeFilter === 'All' || !leadTypeFilter) {
        if (leadTierFilter === 'All' || !leadTierFilter) {
          filteredClosingLeads = [...closingLeads].filter((lead) => lead.newAssignmentNotification === true)
        } else if (leadTierFilter === 'Manual') {
          filteredClosingLeads = [...closingLeads].filter((lead) => lead.newAssignmentNotification === true && lead.tier === 0)
        } else if (leadTierFilter === 'Tier 1') {
          filteredClosingLeads = [...closingLeads].filter((lead) => lead.newAssignmentNotification === true && lead.tier === 1)
        } else if (leadTierFilter === 'Tier 2') {
          filteredClosingLeads = [...closingLeads].filter((lead) => lead.newAssignmentNotification === true && lead.tier === 2)
        } else {
          filteredClosingLeads = [...closingLeads].filter((lead) => lead.newAssignmentNotification === true && lead.tier === 3)
        }
      } else if (leadTypeFilter === 'Renegotiation') {
        if (leadTierFilter === 'All' || !leadTierFilter) {
          filteredClosingLeads = [...closingLeads].filter((lead) => lead.newAssignmentNotification === true)
        } else if (leadTierFilter === 'Manual') {
          filteredClosingLeads = [...closingLeads].filter((lead) => lead.newAssignmentNotification === true && lead.targetOutcome === 'renegotiation' && lead.tier === 0)
        } else if (leadTierFilter === 'Tier 1') {
          filteredClosingLeads = [...closingLeads].filter((lead) => lead.newAssignmentNotification === true && lead.targetOutcome === 'renegotiation' && lead.tier === 1)
        } else if (leadTierFilter === 'Tier 2') {
          filteredClosingLeads = [...closingLeads].filter((lead) => lead.newAssignmentNotification === true && lead.targetOutcome === 'renegotiation' && lead.tier === 2)
        } else {
          filteredClosingLeads = [...closingLeads].filter((lead) => lead.newAssignmentNotification === true && lead.targetOutcome === 'renegotiation' && lead.tier === 3)
        }
      } else {
        if (leadTierFilter === 'All' || !leadTierFilter) {
          filteredClosingLeads = [...closingLeads].filter((lead) => lead.newAssignmentNotification === true)
        } else if (leadTierFilter === 'Manual') {
          filteredClosingLeads = [...closingLeads].filter((lead) => lead.newAssignmentNotification === true && lead.targetOutcome === 'refinance' && lead.tier === 0)
        } else if (leadTierFilter === 'Tier 1') {
          filteredClosingLeads = [...closingLeads].filter((lead) => lead.newAssignmentNotification === true && lead.targetOutcome === 'refinance' && lead.tier === 1)
        } else if (leadTierFilter === 'Tier 2') {
          filteredClosingLeads = [...closingLeads].filter((lead) => lead.newAssignmentNotification === true && lead.targetOutcome === 'refinance' && lead.tier === 2)
        } else {
          filteredClosingLeads = [...closingLeads].filter((lead) => lead.newAssignmentNotification === true && lead.targetOutcome === 'refinance' && lead.tier === 3)
        }
      }
    } else {
      if (leadTypeFilter === 'All' || !leadTypeFilter) {
        if (leadTierFilter === 'All' || !leadTierFilter) {

        } else if (leadTierFilter === 'Manual') {
          filteredClosingLeads = [...closingLeads].filter((lead) => lead.tier === 0)
        } else if (leadTierFilter === 'Tier 1') {
          filteredClosingLeads = [...closingLeads].filter((lead) => lead.tier === 1)
        } else if (leadTierFilter === 'Tier 2') {
          filteredClosingLeads = [...closingLeads].filter((lead) => lead.tier === 2)
        } else {
          filteredClosingLeads = [...closingLeads].filter((lead) => lead.tier === 3)
        }
      } else if (leadTypeFilter === 'Renegotiation') {
        if (leadTierFilter === 'All' || !leadTierFilter) {

        } else if (leadTierFilter === 'Manual') {
          filteredClosingLeads = [...closingLeads].filter((lead) => lead.targetOutcome === 'renegotiation' && lead.tier === 0)
        } else if (leadTierFilter === 'Tier 1') {
          filteredClosingLeads = [...closingLeads].filter((lead) => lead.targetOutcome === 'renegotiation' && lead.tier === 1)
        } else if (leadTierFilter === 'Tier 2') {
          filteredClosingLeads = [...closingLeads].filter((lead) => lead.targetOutcome === 'renegotiation' && lead.tier === 2)
        } else {
          filteredClosingLeads = [...closingLeads].filter((lead) => lead.targetOutcome === 'renegotiation' && lead.tier === 3)
        }
      } else {
        if (leadTierFilter === 'All' || !leadTierFilter) {

        } else if (leadTierFilter === 'Manual') {
          filteredClosingLeads = [...closingLeads].filter((lead) => lead.targetOutcome === 'refinance' && lead.tier === 0)
        } else if (leadTierFilter === 'Tier 1') {
          filteredClosingLeads = [...closingLeads].filter((lead) => lead.targetOutcome === 'refinance' && lead.tier === 1)
        } else if (leadTierFilter === 'Tier 2') {
          filteredClosingLeads = [...closingLeads].filter((lead) => lead.targetOutcome === 'refinance' && lead.tier === 2)
        } else {
          filteredClosingLeads = [...closingLeads].filter((lead) => lead.targetOutcome === 'refinance' && lead.tier === 3)
        }
      }
    }
    return filteredClosingLeads
  }
import React, { useReducer, useRef, useState } from "react";
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import UndoIcon from '@mui/icons-material/Undo';
import ErrorIcon from "@mui/icons-material/Error";
import { useTheme } from "@mui/styles";
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { useSelector } from "react-redux";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { selectViewingLeadTimeline } from "../../../../../slices/viewingLeadSlice";
import { useUpdateMortgageDetailsMutation } from "../../../../../slices/api/apiSlice";
import { AccentInfo, CompareDetail, CompareLink, ListRow, DetailWrapper, Button, ButtonWrapper, ErrorWrapper, Label, InputWrapper, StatusAndLabelWrapper, StyledInputElement } from "../../../../../StyledComponents";

const RecordRow = ({ recordFieldRoot, recordField, leadId, userNotifications, dashPerspective, valueType, handleWorkFlow, userId, userFullName, record, mortgageId, setLeadUserNotifications, workflowStage, fieldEditing, activeDiscrepancies, noChanges, setNoChanges, resolvedDiscrepancies, rejectedDiscrepancies, activeLead, location }) => {
  const theme = useTheme()

  const timeline = useSelector(selectViewingLeadTimeline)

  const [updateDetails] = useUpdateMortgageDetailsMutation()

  const [statusCustomEdit, setStatusCustomEdit] = useState(false)
  const [customEditValue, setCustomEditValue] = useState()
  const [customEditValueStr, setCustomEditValueStr] = useState();
  const customEditInput = useRef();

  const handleSubmitEdit = (selection, boolean, stringBoolean) => {
    if (stringBoolean) {
      if (stringBoolean === 'TRUE') {
        boolean = true
      } else if (stringBoolean === 'FALSE') {
        boolean = false
      }
    }
    let submit = true
    if (selection === 'custom') {
      if (valueType === 'string') {
        if (customEditValue.length === 0 || customEditValue.length > 50 || customEditValue.includes('<') || customEditValue.includes('>')) {
          if (customEditValue.length === 0) {
            setStatusCustomEdit('blank')
          }
          if (customEditValue.length > 50) {
            setStatusCustomEdit('tooLong')
          }
          if (customEditValue.includes('<')) {
            setStatusCustomEdit('character')
          }
          if (customEditValue.includes('>')) {
            setStatusCustomEdit('character')
          }
          submit = false
        }
      } else {
        let negativeAmount = Math.sign(customEditValue)
        let zeroAmount = Math.sign(customEditValue)
        if (negativeAmount === -1 || zeroAmount === 0 || isNaN(customEditValue) || customEditValue > 100000000) {
          if (negativeAmount === -1) {
            setStatusCustomEdit('negative')
          }
          if (zeroAmount === 0) {
            setStatusCustomEdit('zero')
          }
          if (isNaN(customEditValue)) {
            setStatusCustomEdit('nonNumber')
          }
          if (customEditValue > 100000000) {
            setStatusCustomEdit('large')
          }
          submit = false
        }
      }
    }
    if (submit) {
      let breakThis = true
      if (selection === 'custom') {
        if (customEditValue.trim() !== record.currentValue) {
          breakThis = false
        } else {
          setNoChanges(true)
        }
      } else {
        breakThis = false
      }
      if (!breakThis) {
        updateDetails({
          selection: selection,
          userId: userId,
          userFullName: userFullName,
          userNotifications: userNotifications,
          dashPerspective: dashPerspective,
          mortgageId: mortgageId,
          leadId: leadId,
          recordField: recordField,
          customValue: customEditValue,
          activeDiscrepancies: activeDiscrepancies,
          resolvedDiscrepancies: resolvedDiscrepancies,
          rejectedDiscrepancies: rejectedDiscrepancies,
          oldBoolean: boolean,
          oldDiscrepancy: record.discrepancy,
          timeline: timeline,
          recordFieldRoot: recordFieldRoot,
        })
        setCustomEditValueStr()
        setCustomEditValue()
        handleWorkFlow('', '')
        if (location === 'activeLead') {
          setLeadUserNotifications(0)
        }
      }
    }
  }

  useReducer(() => {
    if (valueType === 'string' || valueType === 'number') {
      setCustomEditValue(`${record.currentValue}`)
    } else {
      setCustomEditValue(`${record.currentValue.toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2})}`)
    }
  }, [record.currentValue, valueType])

  return (
    <ListRow className={fieldEditing === recordField ? 'resolve' : ''}>
      <div style={{gridColumn: '1', width: '100%'}}></div>
      {fieldEditing === '' && (record.status === 'edited' || record.status === 'inactive' || record.status === 'initial') ?
        <CompareDetail style={{gridColumn: '2', justifyContent: 'flex-end'}}>
          {record.status === 'edited' && activeLead ?
            <CompareLink className="gray small" style={{height: '1.7rem', fontSize: '1rem', margin: '0 0 0 0', width: '1.7rem'}} onClick={() => handleWorkFlow('undo', recordField)}>
              <UndoIcon style={{height: '1rem', width: '1rem'}} />
            </CompareLink>
          : record.status === 'edited' ?
            <CompareLink className="gray small disabled" style={{height: '1.7rem', fontSize: '1rem', margin: '0 0 0 0', width: '1.7rem'}}>
              <UndoIcon style={{height: '1rem', width: '1rem'}} />
            </CompareLink>
          :
            <></>
          }
          {valueType === 'boolean' && activeLead ?
            <CompareLink className="gray small" style={{height: '1.7rem', fontSize: '1rem', margin: '0 0 0 0', width: '1.7rem', padding: '0'}} onClick={() => handleWorkFlow('custom', recordField)}>
              <SwapHorizIcon style={{height: '1.3rem', width: '1.3rem'}} />
            </CompareLink>
          : valueType === 'boolean' ?
            <CompareLink className="gray small disabled" style={{height: '1.7rem', fontSize: '1rem', margin: '0 0 0 0', width: '1.7rem', padding: '0'}}>
              <SwapHorizIcon style={{height: '1.3rem', width: '1.3rem'}} />
            </CompareLink>
          : activeLead ?
            <CompareLink className="gray small" style={{height: '1.7rem', fontSize: '1rem', margin: '0 0 0 0', width: '1.7rem'}} onClick={() => handleWorkFlow('custom', recordField)}>
              <EditIcon style={{height: '1rem', width: '1rem'}} />
            </CompareLink>
          :
            <CompareLink className="gray small disabled" style={{height: '1.7rem', fontSize: '1rem', margin: '0 0 0 0', width: '1.7rem'}}>
              <EditIcon style={{height: '1rem', width: '1rem'}} />
            </CompareLink>
          }
        </CompareDetail>
      : fieldEditing === '' && record.status === 'discrepancy' && activeLead ?
        <CompareDetail style={{gridColumn: '2', justifyContent: 'flex-end'}}>
          <CompareLink className="gray" style={{height: '1.3rem', fontSize: '1rem', margin: '0 0 0 0', width: '100%', maxWidth: '13rem'}} onClick={() => handleWorkFlow('discrepancy', recordField)}>
            resolve
          </CompareLink>
        </CompareDetail>
      : fieldEditing === '' && record.status === 'discrepancy' ?
        <CompareDetail style={{gridColumn: '2', justifyContent: 'flex-end'}}>
          <CompareLink className="gray disabled" style={{height: '1.3rem', fontSize: '1rem', margin: '0 0 0 0', width: '100%', maxWidth: '13rem'}}>
            resolve
          </CompareLink>
        </CompareDetail>
      : fieldEditing === recordField && workflowStage === 'discrepancy' ?
        <CompareDetail className="resolve" style={{gridColumn: '2', justifyContent: 'flex-end'}}>
          <CompareLink className="gray" style={{fontSize: '1rem', margin: '0 0 0 0', width: '100%', maxWidth: '13rem'}} onClick={() => handleWorkFlow('', '')}>
            cancel
          </CompareLink>
          <CompareLink className='gray' style={{fontSize: '1rem', margin: '0.2rem 0 0 0', width: '100%', maxWidth: '13rem'}} onClick={() => handleSubmitEdit('keep')}>
            keep current value
          </CompareLink>
          <AccentInfo style={{margin: '0', width: '100%', maxWidth: '13rem', alignSelf: 'flex-end'}}>- or -</AccentInfo>
          <CompareLink className='gray' style={{fontSize: '1rem', margin: '0 0 0 0', width: '100%', maxWidth: '13rem'}} onClick={() => handleSubmitEdit('match')}>
            match public record
          </CompareLink>
          {valueType !== 'boolean' ?
            <>
              <AccentInfo style={{margin: '0', width: '100%', maxWidth: '13rem', alignSelf: 'flex-end'}}>- or -</AccentInfo>
              <CompareLink className='gray' style={{fontSize: '1rem', margin: '0 0 0 0', width: '100%', maxWidth: '13rem'}} onClick={() => handleWorkFlow('custom', recordField)}>
                enter other value
              </CompareLink>
            </>
          :
            <></>
          }
        </CompareDetail>
      : fieldEditing === recordField && workflowStage === 'undo' ?
        <CompareDetail style={{gridColumn: '2', justifyContent: 'flex-end'}}>
          <div style={{margin: '0 0.3rem 0 0'}}>
            {record.lastValue ?
              record.lastValue === record.currentValue || (record.discrepancy === 'resolved' && record.lastValue === record.originalValue) ?
                <>
                  reactivate the discrepancy?
                </>
              : record.discrepancy === 'resolved' && record.lastValue !== record.originalValue ?
                <>
                  reject the discrepancy?
                </>
              : record.discrepancy === 'rejected' && record.lastValue === record.publicRecordValue && record.originalDiscrepancy ?
                <>
                  resolve the discrepancy?
                </>
              : record.discrepancy === 'rejected' && record.lastValue === record.publicRecordValue && !record.originalDiscrepancy ?
                <>
                  return to match?
                </>
              :
                <>
                  revert to <span style={{fontStyle: 'italic'}}>{record.lastValue}</span> ?
                </>
            :
              <>
                revert to <span style={{fontStyle: 'italic', color: `${theme.palette.common.grayText}`}}>-empty-</span> ?
              </>
            }
          </div>
          <CompareLink className="small green" onClick={() => handleSubmitEdit('undo')} style={{height: '1.7rem', fontSize: '1rem', margin: '0 0 0 0', width: '1.7rem'}}>
            <CheckIcon style={{height: '1rem', width: '1rem'}} />
          </CompareLink>
          <CompareLink className="small gray" onClick={() => handleWorkFlow('', '')} style={{height: '1.7rem', fontSize: '1rem', margin: '0 0 0 0', width: '1.7rem'}}>
            <ClearIcon style={{height: '1rem', width: '1rem'}} />
          </CompareLink>
        </CompareDetail>
      : fieldEditing === recordField && workflowStage === 'custom' && !noChanges && valueType !== 'boolean' ?
        <CompareDetail className="resolve" style={{gridColumn: '2', justifyContent: 'flex-end', top: '-4.2rem', height: '0px', margin: '0'}}>
          <InputWrapper style={{width: '100%', background: 'white', margin: '0', padding: '0 1rem 0 1rem', alignItems: 'flex-start'}}>
            <StatusAndLabelWrapper className="small">
              <Label>{record.label}</Label>
            </StatusAndLabelWrapper>
            <StyledInputElement style={{width: '10rem'}}
              value={customEditValueStr}
              type="text"
              name="customEdit"
              id="customEdit"
              ref={customEditInput}
              autoFocus
              onChange={() => {
                if (statusCustomEdit === 'blank' || statusCustomEdit === 'character' || statusCustomEdit === 'tooLong' || statusCustomEdit === "nonNumber" || statusCustomEdit === "negative" || statusCustomEdit === "zero" || statusCustomEdit === "large") {
                  setStatusCustomEdit(null)
                }
                if (valueType === 'number') {
                  setCustomEditValueStr(customEditInput.current.value)
                  setCustomEditValue(customEditInput.current.value.replace(/[^0-9-.]/g, ''))
                } else {
                  setCustomEditValue(customEditInput.current.value)
                }
              }}
            />
            <ButtonWrapper className="justifyStart" style={{margin: '0.3rem 0 0 0'}}>
              <Button className="primary notes" style={{marginRight: '0.5rem'}} onClick={() => handleSubmitEdit('custom')}>
                <SaveIcon />
              </Button>
              <Button className="gray notes" onClick={() => handleWorkFlow('', '')}>
                <ClearIcon />
              </Button>
            </ButtonWrapper>
            <StatusAndLabelWrapper style={{height: 'fit-content'}}>
              {statusCustomEdit === "blank" && (
                <ErrorWrapper>
                  <ErrorIcon />
                  Can't be blank
                </ErrorWrapper>
              )}
              {statusCustomEdit === "character" && (
                <ErrorWrapper>
                  <ErrorIcon />
                  {`Can't contain the characters '<' or '>'`}
                </ErrorWrapper>
              )}
              {statusCustomEdit === "tooLong" && (
                <ErrorWrapper>
                  <ErrorIcon />
                  Can't contain more than 50 characters
                </ErrorWrapper>
              )}
              {statusCustomEdit === "nonNumber" && (
                <ErrorWrapper>
                  <ErrorIcon />
                  Must be a number
                </ErrorWrapper>
              )}
              {statusCustomEdit === "negative" && (
                <ErrorWrapper>
                  <ErrorIcon />
                  Can't be negative
                </ErrorWrapper>
              )}
              {statusCustomEdit === "zero" && (
                <ErrorWrapper>
                  <ErrorIcon />
                  Can't be zero
                </ErrorWrapper>
              )}
              {statusCustomEdit === "large" && (
                <ErrorWrapper>
                  <ErrorIcon />
                  {`Must be < 99`}
                </ErrorWrapper>
              )}
            </StatusAndLabelWrapper>
          </InputWrapper>
        </CompareDetail>
      : fieldEditing === recordField && workflowStage === 'custom' && noChanges && valueType !== 'boolean' ?
        <CompareDetail style={{gridColumn: '2', justifyContent: 'flex-end'}}>
          <div style={{margin: '0 0.3rem 0 0'}}>
            no changes made
          </div>
          <CompareLink className="small gray" onClick={() => handleWorkFlow('custom', recordField)} style={{height: '1.7rem', fontSize: '1rem', margin: '0 0 0 0', width: '1.7rem'}}>
            <KeyboardBackspaceIcon style={{height: '1rem', width: '1rem'}} />
          </CompareLink>
          <CompareLink className="small gray" onClick={() => handleWorkFlow('', '')} style={{height: '1.7rem', fontSize: '1rem', margin: '0 0 0 0', width: '1.7rem'}}>
            <ClearIcon style={{height: '1rem', width: '1rem'}} />
          </CompareLink>
        </CompareDetail>
      : fieldEditing === recordField && workflowStage === 'custom' && !noChanges && valueType === 'boolean' && record.discrepancy === 'resolved' ?
        <CompareDetail style={{gridColumn: '2', justifyContent: 'flex-end'}}>
          <div style={{margin: '0 0.3rem 0 0'}}>
            reactivate the discrepancy?
          </div>
          <CompareLink className="small gray" onClick={() => handleSubmitEdit('switch', null, record.currentValue)} style={{height: '1.7rem', fontSize: '1rem', margin: '0 0 0 0', width: '1.7rem'}}>
            <CheckIcon style={{height: '1rem', width: '1rem'}} />
          </CompareLink>
          <CompareLink className="small gray" onClick={() => handleWorkFlow('', '')} style={{height: '1.7rem', fontSize: '1rem', margin: '0 0 0 0', width: '1.7rem'}}>
            <ClearIcon style={{height: '1rem', width: '1rem'}} />
          </CompareLink>
        </CompareDetail>
      : fieldEditing === recordField && workflowStage === 'custom' && !noChanges && valueType === 'boolean' && record.discrepancy === 'rejected' ?
        <CompareDetail style={{gridColumn: '2', justifyContent: 'flex-end'}}>
          <div style={{margin: '0 0.3rem 0 0'}}>
            resolve the discrepancy?
          </div>
          <CompareLink className="small gray" onClick={() => handleSubmitEdit('switch', null, record.currentValue)} style={{height: '1.7rem', fontSize: '1rem', margin: '0 0 0 0', width: '1.7rem'}}>
            <CheckIcon style={{height: '1rem', width: '1rem'}} />
          </CompareLink>
          <CompareLink className="small gray" onClick={() => handleWorkFlow('', '')} style={{height: '1.7rem', fontSize: '1rem', margin: '0 0 0 0', width: '1.7rem'}}>
            <ClearIcon style={{height: '1rem', width: '1rem'}} />
          </CompareLink>
        </CompareDetail>
      : fieldEditing === recordField && workflowStage === 'custom' && !noChanges && valueType === 'boolean' && record.currentValue === 'TRUE' ?
        <CompareDetail style={{gridColumn: '2', justifyContent: 'flex-end'}}>
          <div style={{margin: '0 0.3rem 0 0'}}>
            switch to <span style={{fontStyle: 'italic'}}>FALSE</span> ?
          </div>
          <CompareLink className="small gray" onClick={() => handleSubmitEdit('switch', true)} style={{height: '1.7rem', fontSize: '1rem', margin: '0 0 0 0', width: '1.7rem'}}>
            <CheckIcon style={{height: '1rem', width: '1rem'}} />
          </CompareLink>
          <CompareLink className="small gray" onClick={() => handleWorkFlow('', '')} style={{height: '1.7rem', fontSize: '1rem', margin: '0 0 0 0', width: '1.7rem'}}>
            <ClearIcon style={{height: '1rem', width: '1rem'}} />
          </CompareLink>
        </CompareDetail>
      : fieldEditing === recordField && workflowStage === 'custom' && !noChanges && valueType === 'boolean' && record.currentValue === 'FALSE' ?
        <CompareDetail style={{gridColumn: '2', justifyContent: 'flex-end'}}>
          <div style={{margin: '0 0.3rem 0 0'}}>
            switch to <span style={{fontStyle: 'italic'}}>TRUE</span> ?
          </div>
          <CompareLink className="small gray" onClick={() => handleSubmitEdit('switch', false)} style={{height: '1.7rem', fontSize: '1rem', margin: '0 0 0 0', width: '1.7rem'}}>
            <CheckIcon style={{height: '1rem', width: '1rem'}} />
          </CompareLink>
          <CompareLink className="small gray" onClick={() => handleWorkFlow('', '')} style={{height: '1.7rem', fontSize: '1rem', margin: '0 0 0 0', width: '1.7rem'}}>
            <ClearIcon style={{height: '1rem', width: '1rem'}} />
          </CompareLink>
        </CompareDetail>
      :
        <></>
      }
      <CompareDetail className='recordRow' style={{gridColumn: '3', justifyContent: 'flex-end'}}>
        <DetailWrapper className={record.status === 'initial' ? 'initial' : record.discrepancy === 'active' ? `${record.assignedTier}` : ''}>
          {record.label}
        </DetailWrapper>
      </CompareDetail>
      <CompareDetail className="recordRow" style={{gridColumn: '4', justifyContent: 'center'}}>
        {record.currentValue ?
          <DetailWrapper className={record.discrepancy === 'active' ? "active" : record.discrepancy === 'rejected' ? "rejected" : record.discrepancy === 'resolved' ? 'resolved' : record.discrepancy === 'inactive' ? 'inactive' : ''}>
            {record.label === 'Assessed Value' || record.label === 'Land Value' || record.label === 'Improvements Value' || record.label === 'Close Price' || record.label === 'Last Sale Price' || record.label === 'Tax Annual Amount' || record.label === 'Assessed Land Value' || record.label === 'Assessed Improvement Value' ?
              <>
                ${parseFloat(record.currentValue).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}
              </>
            : record.label === 'Tax Annual Amount' ?
              <>
                ${parseFloat(record.currentValue.substring(1)).toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2})}
              </>
            : record.label === 'Latitude' || record.label === 'Longitude' || record.label === 'Rooms' || record.label === 'Bedrooms' || record.label === 'Bathrooms Full' || record.label === 'Bathrooms Total' ?
              <>
                {parseFloat(record.currentValue)}
              </>
            : record.label === 'Living Area' || record.label === 'Garage Area' || record.label === 'Building Area Total' || record.label === 'Gross Area' || record.label === 'Lot Size Square Feet' ?
              <>
                {parseFloat(record.currentValue).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})} sq ft
              </>
            :
              record.currentValue
            }
          </DetailWrapper>
        :
          <DetailWrapper className={record.discrepancy === 'active' ? "active" : record.discrepancy === 'rejected' ? "rejected" : record.discrepancy === 'resolved' ? 'resolved' : record.discrepancy === 'inactive' ? 'inactive' : ''} style={{fontWeight: '400', color: `${theme.palette.common.grayText}`}}>
            -empty-
          </DetailWrapper>
        }
      </CompareDetail>
      {record.publicRecordValue ?
        <CompareDetail className="recordRow" style={{gridColumn: '5', justifyContent: 'center'}}>
          {record.label === 'Assessed Value' || record.label === 'Land Value' || record.label === 'Improvements Value' || record.label === 'Close Price' || record.label === 'Last Sale Price' || record.label === 'Assessed Land Value' || record.label === 'Assessed Improvement Value' ?
            <>
              ${parseFloat(record.publicRecordValue).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}
            </>
          : record.label === 'Tax Annual Amount' ?
            <>
              ${parseFloat(record.publicRecordValue).toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2})}
            </>
          : record.label === 'Latitude' || record.label === 'Longitude' || record.label === 'Rooms' || record.label === 'Bedrooms' || record.label === 'Bathrooms Full' || record.label === 'Bathrooms Total' ?
              <>
              {parseFloat(record.publicRecordValue)}
            </>
          : record.label === 'Living Area' || record.label === 'Garage Area' || record.label === 'Building Area Total' || record.label === 'Gross Area' || record.label === 'Lot Size Square Feet' ?
            <>
              {parseFloat(record.publicRecordValue).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})} sq ft
            </>
          :
            record.publicRecordValue
          }
        </CompareDetail>
      :
        <CompareDetail className="recordRow" style={{gridColumn: '5', justifyContent: 'center'}}>
          <AccentInfo>-empty-</AccentInfo>
        </CompareDetail>
      }
    </ListRow>
  )
}

export default RecordRow;
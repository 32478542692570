import React from "react";
import TeamDetails from "./components/TeamDetails";
import ListMembers from "./components/ListMembers";
import DefaultTargets from "./components/DefaultTargets";
import { useSelector } from "react-redux";
import { PageContent } from "../../../../StyledComponents";
import { selectUserRole } from "../../../../slices/userInfoSlice";
import { selectMembersDataArray } from "../../../../slices/membersDataArraySlice";

const Settings = ({ teamId, userId, userFullName }) => {

  const userRole = useSelector(selectUserRole)
  const memberData = useSelector(selectMembersDataArray)

  return (
    <PageContent className="grid settings">
      <TeamDetails />
      <DefaultTargets teamId={teamId} />
      <ListMembers memberData={memberData} userId={userId} userFullName={userFullName} />
    </PageContent>
  )    
}

export default Settings;
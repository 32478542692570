export default async function handleLeadSortMethodChanges (eventValue, leadCategorySort, leadsAwaitingAction, teamInvestigatingLeads, teamClosingLeads) {
  let awaitingAction = await handleAwaitingAction(eventValue, leadCategorySort, leadsAwaitingAction);
  let investigating = await handleInvestigating(eventValue, leadCategorySort, teamInvestigatingLeads);
  let closing = await handleClosing(eventValue, leadCategorySort, teamClosingLeads);
  return {awaitingAction, investigating, closing}
}

let handleAwaitingAction = async (eventValue, leadCategorySort, leadsAwaitingAction) => {
  if (eventValue === 'High-Low') {
    if (leadCategorySort === 'Date Discovered') {
      leadsAwaitingAction = [...leadsAwaitingAction].sort((a, b) => (a.dateInvestigating < b.dateInvestigating) ? 1 : (a.dateInvestigating > b.dateInvestigating) ? -1 : 0);
    } else if (leadCategorySort === 'Tier') {
      leadsAwaitingAction = [...leadsAwaitingAction].sort((a, b) => (a.tier > b.tier) ? 1 : (a.tier < b.tier) ? -1 : 0);
    } else if (leadCategorySort === 'Interest Increase') {
      leadsAwaitingAction = [...leadsAwaitingAction].sort((a, b) => (a.targetProfitNumber < b.targetProfitNumber) ? 1 : (a.targetProfitNumber > b.targetProfitNumber) ? -1 : 0);
    } else if (leadCategorySort === 'Percent Difference') {
      leadsAwaitingAction = [...leadsAwaitingAction].sort((a, b) => (a.targetProfitPercent < b.targetProfitPercent) ? 1 : (a.targetProfitPercent > b.targetProfitPercent) ? -1 : 0);
    } else if (leadCategorySort === 'Months Remaining') {
      leadsAwaitingAction = [...leadsAwaitingAction].sort((a, b) => (a.remainingMonths < b.remainingMonths) ? 1 : (a.remainingMonths > b.remainingMonths) ? -1 : 0);
    }
  } else {
    if (leadCategorySort === 'Date Discovered') {
      leadsAwaitingAction = [...leadsAwaitingAction].sort((a, b) => (a.dateInvestigating > b.dateInvestigating) ? 1 : (a.dateInvestigating < b.dateInvestigating) ? -1 : 0);
    } else if (leadCategorySort === 'Tier') {
      leadsAwaitingAction = [...leadsAwaitingAction].sort((a, b) => (a.tier < b.tier) ? 1 : (a.tier > b.tier) ? -1 : 0);
    } else if (leadCategorySort === 'Interest Increase') {
      leadsAwaitingAction = [...leadsAwaitingAction].sort((a, b) => (a.targetProfitNumber > b.targetProfitNumber) ? 1 : (a.targetProfitNumber < b.targetProfitNumber) ? -1 : 0);
    } else if (leadCategorySort === 'Percent Difference') {
      leadsAwaitingAction = [...leadsAwaitingAction].sort((a, b) => (a.targetProfitPercent > b.targetProfitPercent) ? 1 : (a.targetProfitPercent < b.targetProfitPercent) ? -1 : 0);
    } else if (leadCategorySort === 'Months Remaining') {
      leadsAwaitingAction = [...leadsAwaitingAction].sort((a, b) => (a.remainingMonths > b.remainingMonths) ? 1 : (a.remainingMonths < b.remainingMonths) ? -1 : 0);
    }
  }
  return leadsAwaitingAction
}
let handleInvestigating = async (eventValue, leadCategorySort, teamInvestigatingLeads) => {
  if (eventValue === 'High-Low') {
    if (leadCategorySort === 'Date Discovered') {
      teamInvestigatingLeads = [...teamInvestigatingLeads].sort((a, b) => (a.dateInvestigating < b.dateInvestigating) ? 1 : (a.dateInvestigating > b.dateInvestigating) ? -1 : 0);
    } else if (leadCategorySort === 'Tier') {
      teamInvestigatingLeads = [...teamInvestigatingLeads].sort((a, b) => (a.tier > b.tier) ? 1 : (a.tier < b.tier) ? -1 : 0);
    } else if (leadCategorySort === 'Interest Increase') {
      teamInvestigatingLeads = [...teamInvestigatingLeads].sort((a, b) => (a.targetProfitNumber < b.targetProfitNumber) ? 1 : (a.targetProfitNumber > b.targetProfitNumber) ? -1 : 0);
    } else if (leadCategorySort === 'Percent Difference') {
      teamInvestigatingLeads = [...teamInvestigatingLeads].sort((a, b) => (a.targetProfitPercent < b.targetProfitPercent) ? 1 : (a.targetProfitPercent > b.targetProfitPercent) ? -1 : 0);
    } else if (leadCategorySort === 'Months Remaining') {
      teamInvestigatingLeads = [...teamInvestigatingLeads].sort((a, b) => (a.remainingMonths < b.remainingMonths) ? 1 : (a.remainingMonths > b.remainingMonths) ? -1 : 0);
    }
  } else {
    if (leadCategorySort === 'Date Discovered') {
      teamInvestigatingLeads = [...teamInvestigatingLeads].sort((a, b) => (a.dateInvestigating > b.dateInvestigating) ? 1 : (a.dateInvestigating < b.dateInvestigating) ? -1 : 0);
    } else if (leadCategorySort === 'Tier') {
      teamInvestigatingLeads = [...teamInvestigatingLeads].sort((a, b) => (a.tier < b.tier) ? 1 : (a.tier > b.tier) ? -1 : 0);
    } else if (leadCategorySort === 'Interest Increase') {
      teamInvestigatingLeads = [...teamInvestigatingLeads].sort((a, b) => (a.targetProfitNumber > b.targetProfitNumber) ? 1 : (a.targetProfitNumber < b.targetProfitNumber) ? -1 : 0);
    } else if (leadCategorySort === 'Percent Difference') {
      teamInvestigatingLeads = [...teamInvestigatingLeads].sort((a, b) => (a.targetProfitPercent > b.targetProfitPercent) ? 1 : (a.targetProfitPercent < b.targetProfitPercent) ? -1 : 0);
    } else if (leadCategorySort === 'Months Remaining') {
      teamInvestigatingLeads = [...teamInvestigatingLeads].sort((a, b) => (a.remainingMonths > b.remainingMonths) ? 1 : (a.remainingMonths < b.remainingMonths) ? -1 : 0);
    }
  }
  return teamInvestigatingLeads
}
let handleClosing = async (eventValue, leadCategorySort, teamClosingLeads) => {
  if (eventValue === 'High-Low') {
    if (leadCategorySort === 'Date Discovered') {
      teamClosingLeads = [...teamClosingLeads].sort((a, b) => (a.dateInvestigating < b.dateInvestigating) ? 1 : (a.dateInvestigating > b.dateInvestigating) ? -1 : 0);
    } else if (leadCategorySort === 'Tier') {
      teamClosingLeads = [...teamClosingLeads].sort((a, b) => (a.tier > b.tier) ? 1 : (a.tier < b.tier) ? -1 : 0);
    } else if (leadCategorySort === 'Interest Increase') {
      teamClosingLeads = [...teamClosingLeads].sort((a, b) => (a.targetProfitNumber < b.targetProfitNumber) ? 1 : (a.targetProfitNumber > b.targetProfitNumber) ? -1 : 0);
    } else if (leadCategorySort === 'Percent Difference') {
        teamClosingLeads = [...teamClosingLeads].sort((a, b) => (a.targetProfitPercent < b.targetProfitPercent) ? 1 : (a.targetProfitPercent > b.targetProfitPercent) ? -1 : 0);
    } else if (leadCategorySort === 'Months Remaining') {
        teamClosingLeads = [...teamClosingLeads].sort((a, b) => (a.remainingMonths < b.remainingMonths) ? 1 : (a.remainingMonths > b.remainingMonths) ? -1 : 0);
    }
  } else {
    if (leadCategorySort === 'Date Discovered') {
      teamClosingLeads = [...teamClosingLeads].sort((a, b) => (a.dateInvestigating > b.dateInvestigating) ? 1 : (a.dateInvestigating < b.dateInvestigating) ? -1 : 0);
    } else if (leadCategorySort === 'Tier') {
      teamClosingLeads = [...teamClosingLeads].sort((a, b) => (a.tier < b.tier) ? 1 : (a.tier > b.tier) ? -1 : 0);
    } else if (leadCategorySort === 'Interest Increase') {
      teamClosingLeads = [...teamClosingLeads].sort((a, b) => (a.targetProfitNumber > b.targetProfitNumber) ? 1 : (a.targetProfitNumber < b.targetProfitNumber) ? -1 : 0);
    } else if (leadCategorySort === 'Percent Difference') {
        teamClosingLeads = [...teamClosingLeads].sort((a, b) => (a.targetProfitPercent > b.targetProfitPercent) ? 1 : (a.targetProfitPercent < b.targetProfitPercent) ? -1 : 0);
    } else if (leadCategorySort === 'Months Remaining') {
        teamClosingLeads = [...teamClosingLeads].sort((a, b) => (a.remainingMonths > b.remainingMonths) ? 1 : (a.remainingMonths < b.remainingMonths) ? -1 : 0);
    }
  }
  return teamClosingLeads
}
import { apiSlice } from './slices/api/apiSlice'
import { combineReducers } from '@reduxjs/toolkit'
import loadDataReducer from './slices/loadDataSlice'
import userInfoReducer from './slices/userInfoSlice'
import teamStatsReducer from './slices/teamStatsSlice'
import viewingLeadReducer from './slices/viewingLeadSlice'
import sessionDataReducer from './slices/sessionDataSlice'
import teamReportsReducer from './slices/teamReportsSlice'
import navSelectionReducer from './slices/navSelectionSlice'
import teamClosuresReducer from './slices/teamClosuresSlice'
import teamAdminDataReducer from './slices/teamAdminDataSlice'
import teamMortgagesReducer from './slices/teamMortgagesSlice'
import pageSelectionReducer from './slices/pageSelectionSlice'
import mortgageTargetsReducer from './slices/mortgageTargetsSlice'
import teamActiveLeadsReducer from './slices/teamActiveLeadsSlice'
import viewingMortgageReducer from './slices/viewingMortgageSlice'
import dashPerspectiveReducer from './slices/dashPerspectiveSlice'
import membersDataArrayReducer from './slices/membersDataArraySlice'
import teamMonthlyStatsReducer from './slices/teamMonthlyStatsSlice'
import userNotificationReducer from './slices/userNotificationsSlice'
import navMenuSelectionReducer from './slices/navMenuSelectionsSlice'
import mortgageDataModelReducer from './slices/mortgageDataModelSlice'
import adminNavSelectionReducer from './slices/adminNavSelectionSlice'
import memberActiveLeadsReducer from './slices/memberActiveLeadsSlice'
import viewingLeadReportReducer from './slices/viewingLeadReportsSlice'
import guideNavSelectionReducer from './slices/guideNavSelectionSlice'
import teamAndUserSettingsReducer from './slices/teamAndUserSettingsSlice'
import teamSweepParametersReducer from './slices/teamSweepParametersSlice'
import functionAvailabilityReducer from './slices/functionAvailabilitySlice'
import viewingSweepParameterReducer from './slices/viewingSweepParameterSlice'

const rootReducer = combineReducers({
  loadData: loadDataReducer,
  userInfo: userInfoReducer,
  teamStats: teamStatsReducer,
  sessionData: sessionDataReducer,
  teamReports: teamReportsReducer,
  viewingLead: viewingLeadReducer,
  navSelection: navSelectionReducer,
  teamClosures: teamClosuresReducer,
  pageSelection: pageSelectionReducer,
  teamAdminData: teamAdminDataReducer,
  teamMortgages: teamMortgagesReducer,
  dashPerspective: dashPerspectiveReducer,
  teamActiveLeads: teamActiveLeadsReducer,
  mortgageTargets: mortgageTargetsReducer,
  viewingMortgage: viewingMortgageReducer,
  navMenuSelection: navMenuSelectionReducer,
  teamMonthlyStats: teamMonthlyStatsReducer,
  membersDataArray: membersDataArrayReducer,
  userNotifications: userNotificationReducer,
  mortgageDataModel: mortgageDataModelReducer,
  guideNavSelection: guideNavSelectionReducer,
  memberActiveLeads: memberActiveLeadsReducer,
  adminNavSelection: adminNavSelectionReducer,
  viewingLeadReports: viewingLeadReportReducer,
  teamAndUserSettings: teamAndUserSettingsReducer,
  teamSweepParameters: teamSweepParametersReducer,
  functionAvailability: functionAvailabilityReducer,
  viewingSweepParameter: viewingSweepParameterReducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
})

export default rootReducer;
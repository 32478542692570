import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: {
    userId: '',
    email: '',
    role: '',
    firstName: '',
    fullName: '',
    initials: '',
    teamId: '',
    reqCredentials: {
      protocol: '',
      location: '',
      isp: '',
      mobile: '',
      proxy: '',
    },
  },
  status: 'idle',
  error: null
}

export const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState,
  reducers: {
    setUserInfo(state, action) {
      state.data.userId = action.payload.userId
      state.data.role = action.payload.role
      state.data.firstName = action.payload.firstName
      state.data.fullName = action.payload.fullName
      state.data.initials = action.payload.initials
      state.data.teamId = action.payload.teamId
    },
    setTeamId (state, action) { state.data.teamId = action.payload },
    setUserId (state, action) { state.data.userId = action.payload },
    setUserEmail(state, action) { state.data.email = action.payload },
    setUserFirstName(state, action) { state.data.firstName = action.payload },
    setUserReqCredentials(state, action) { 
      state.data.reqCredentials.protocol = action.payload.protocol
      state.data.reqCredentials.location = action.payload.location
      state.data.reqCredentials.isp = action.payload.isp
      state.data.reqCredentials.mobile = action.payload.mobile
      state.data.reqCredentials.proxy = action.payload.proxy
    },
  },
})

export const { setUserInfo, setUserFirstName, setUserReqCredentials, setUserEmail, setUserId, setTeamId } = userInfoSlice.actions

export default userInfoSlice.reducer

export const selectUserId = state => state.userInfo.data.userId
export const selectTeamId = state => state.userInfo.data.teamId
export const selectUserFullName = state => state.userInfo.data.fullName
export const selectUserFirstName = state => state.userInfo.data.firstName
export const selectUserRole = state => state.userInfo.data.role
export const selectUserInitials = state => state.userInfo.data.initials
export const selectUserRequestCredentials = state => state.userInfo.data.reqCredentials
export const selectUserEmail = state => state.userInfo.data.email
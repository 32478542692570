import React from "react";
import styled from "@emotion/styled";
import { NewLabel } from "../../../../../StyledComponents";

const TreeItem = ({ borrower, selectReport, reportId, selectedReportId, date, itemIndex, isNew, city, address }) => {
  
  return (
    <>
      {selectedReportId === reportId && isNew ?
        <TreeItemContainer new selected onClick={() => selectReport(reportId)}>
          <NewLabel className="reportTree">New</NewLabel>
          <div style={{width: 'fit-content', padding: '0', gridColumn: '4', margin: '0 auto'}}>
            {itemIndex + 1} 
          </div>
          <TreeItemWrapper selected new >
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <span>{date}</span>
              <span>{borrower}</span>
              <span>{address}</span>
              <span>{city}</span>
            </div>
          </TreeItemWrapper>
        </TreeItemContainer>
      : selectedReportId === reportId ?
        <TreeItemContainer selected onClick={() => selectReport(reportId)}>
          <div style={{width: 'fit-content', padding: '0', gridColumn: '2', margin: '0 auto'}}>
            {itemIndex + 1} 
          </div>
          <TreeItemWrapper selected >
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <span>{date}</span>
              <span>{borrower}</span>
              <span>{address}</span>
              <span>{city}</span>
            </div>
          </TreeItemWrapper>
        </TreeItemContainer>
      : isNew ?
        <TreeItemContainer new onClick={() => selectReport(reportId)}>
          <NewLabel className="reportTree">New</NewLabel>
          <div style={{width: 'fit-content', padding: '0', gridColumn: '4', margin: '0 auto'}}>
            {itemIndex + 1} 
          </div>
          <TreeItemWrapper new >
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <span>{date}</span>
              <span>{borrower}</span>
              <span>{address}</span>
              <span>{city}</span>
            </div>
          </TreeItemWrapper>
        </TreeItemContainer>
      :
        <TreeItemContainer onClick={() => selectReport(reportId)}>
          <div style={{width: 'fit-content', padding: '0', gridColumn: '2', margin: '0 auto'}}>
            {itemIndex + 1} 
          </div>
          <TreeItemWrapper>
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <span>{date}</span>
              <span>{borrower}</span>
              <span>{address}</span>
              <span>{city}</span>
            </div>
          </TreeItemWrapper>
        </TreeItemContainer>
      }
    </>
  )
}

const TreeItemWrapper = styled.div`
  grid-column: ${(props) => props.new ? '6' : '4'};
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${(props) => props.selected ? '0.1rem solid #fff' : '0.1rem solid #E3E5EB'};
  border-radius: 0.5rem;
  padding: 0.2rem 0;
`
const TreeItemContainer = styled.button`
  height: fit-content;
  width: 100%;
  display: grid;
  grid-template-columns: ${(props) => props.new ? '0.5rem 3.6rem 0.5rem minmax(0, 1fr) 0.5rem minmax(0, 20rem) 1rem' : '4.6rem minmax(0, 1fr) 0.5rem minmax(0, 20rem) 1rem'};
  justify-content: center;
  align-items: center;
  padding: 0.4rem 0;
  border-bottom: 0.05rem solid #E3E5EB;
  border-top: 0.05rem solid #E3E5EB;
  border-left: none;
  border-right: none;
  background-color: ${(props) => props.selected ? `#4B14752B` : `#ffffff`};

  &:hover {
    cursor: ${(props) => props.selected ? 'default' : 'pointer'};
    background-color: ${(props) => props.selected ? '#4B147533' : '#F2F2F2'};
  }
`

export default TreeItem;
import React, { useState } from "react";
import Radio from '@mui/material/Radio';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import RadioGroup from '@mui/material/RadioGroup';
import { useTheme } from "@mui/styles";
import GenericDropdown from "../DropDowns/GenericDropdown.js";
import CircularProgress from "@mui/material/CircularProgress";
import FormControlLabel from '@mui/material/FormControlLabel';
import { selectUserFullName } from "../../slices/userInfoSlice.js";
import { useRecordSweepMutation, useSendSweepResultsEmailsMutation } from "../../slices/api/apiSlice.js";
import { useDispatch, useSelector } from "react-redux";
import { selectLoadData, setDataIsLoaded } from "../../slices/loadDataSlice.js";
import { Button, ButtonWrapper, FlexWrapper, ScrollWindow, SubHeader } from "../../StyledComponents.js";

const BulkScanControls = ({ teamId, userId, mortgageId }) => {
  const theme = useTheme()
  const dispatch = useDispatch()

  const dataIsLoaded = useSelector(selectLoadData)
  const userFullName = useSelector(selectUserFullName)

  const [sendSweepResultsEmail] = useSendSweepResultsEmailsMutation()

  const [sweepTypeSelection, setSweepTypeSelection] = useState('Initial Match')

  const primaryColorProps = () => ({
    sx: {
      padding: "0.5rem 0 0.5rem 0.5rem",
      color: `${theme.palette.common.grayBorder}`,
      '&.Mui-checked': {
        color: `${theme.palette.primary.main}`,
      },
    }
  })

  const [sweepSizeSelection, setSweepSizeSelection] = useState('10')
  const handleSweepSizeSelectionChange = async (event) => {
    setSweepSizeSelection(event.target.value)
  }
  const [existingScansSelection, setExistingScansSelection] = useState('Any')
  const handleExistingScansSelectionChange = async (event) => {
    setExistingScansSelection(event.target.value)
  }
  const [varietyMortgagesSelection, setVarietyMortgagesSelection] = useState('false')
  const handleVarietyMortgagesSelectionChange = async (event) => {
    setVarietyMortgagesSelection(event.target.value)
  }
  const varietyMortgagesControlProp = (item) => ({
    checked: varietyMortgagesSelection === item,
    onChange: handleVarietyMortgagesSelectionChange,
    value: item,
    label: item,
    labelPlacement: "start",
    sx: {
      color: `${theme.palette.common.black}`,
      margin: '0 1rem 0 0'
    }
  })
  const [retainLastSalePriceSelection, setRetainLastSalePriceSelection] = useState('true')
  const [createLeadSelection, setCreateLeadSelection] = useState('false')
  const [recordMonthlyStatsSelection, setRecordMonthlyStatsSelection] = useState('false')
  const [recordQueryPerformanceSelection, setRecordQueryPerformanceSelection] = useState('true')
  const [notifyAssigneesSelection, setNotifyAssigneesSelection] = useState('false')
  const [matchPublicRecordsAllSelection, setMatchPublicRecordsAllSelection] = useState('true')
  const [matchPublicRecordsNonParametersSelection, setMatchPublicRecordsNonParametersSelection] = useState('true')
  const [recordTeamStatsSelection, setRecordTeamStatsSelection] = useState('false')
  const [updateLastQuerySelection, setUpdateLastQuerySelection] = useState('false')

  const handleSweepTypeChange = async (event) => {
    if (event.target.value === 'Initial Match') {
      setNotifyAssigneesSelection('false')
      setRecordMonthlyStatsSelection('false')
      setRecordTeamStatsSelection('false')
      setUpdateLastQuerySelection('false')
      setCreateLeadSelection('false')
      setRecordQueryPerformanceSelection('true')
      setMatchPublicRecordsAllSelection('true')
      setMatchPublicRecordsNonParametersSelection('true')
      setRetainLastSalePriceSelection('true')
    } else if (event.target.value === 'Check All Discrepancies') {
      setNotifyAssigneesSelection('false')
      setRecordMonthlyStatsSelection('false')
      setRecordTeamStatsSelection('false')
      setUpdateLastQuerySelection('false')
      setCreateLeadSelection('true')
      setRecordQueryPerformanceSelection('true')
      setMatchPublicRecordsAllSelection('false')
      setMatchPublicRecordsNonParametersSelection('false')
      setRetainLastSalePriceSelection('true')
    } else if (event.target.value === 'Check Parameter Discrepancies') {
      setNotifyAssigneesSelection('false')
      setRecordMonthlyStatsSelection('false')
      setRecordTeamStatsSelection('false')
      setUpdateLastQuerySelection('false')
      setCreateLeadSelection('true')
      setRecordQueryPerformanceSelection('true')
      setMatchPublicRecordsAllSelection('false')
      setMatchPublicRecordsNonParametersSelection('true')
      setRetainLastSalePriceSelection('true')
    } else if (event.target.value === 'Standard + Retain Price') {
      setNotifyAssigneesSelection('true')
      setRecordMonthlyStatsSelection('true')
      setRecordTeamStatsSelection('true')
      setUpdateLastQuerySelection('true')
      setCreateLeadSelection('true')
      setRecordQueryPerformanceSelection('true')
      setMatchPublicRecordsAllSelection('false')
      setMatchPublicRecordsNonParametersSelection('false')
      setRetainLastSalePriceSelection('true')
    } else {
      setNotifyAssigneesSelection('true')
      setRecordMonthlyStatsSelection('true')
      setRecordTeamStatsSelection('true')
      setUpdateLastQuerySelection('true')
      setCreateLeadSelection('true')
      setRecordQueryPerformanceSelection('true')
      setMatchPublicRecordsAllSelection('false')
      setMatchPublicRecordsNonParametersSelection('false')
      setRetainLastSalePriceSelection('false')
    }
    setSweepTypeSelection(event.target.value)
  }

  const [workflowStage, setWorkflowStage] = useState(null)
  const [recordSweep, { isLoading }] = useRecordSweepMutation()
  const handleClick = async (stage) => {
    if (stage === 'initiate') {
      setWorkflowStage('initiate')
    } else if (stage === 'submit') {
      dispatch( setDataIsLoaded(false) )
      setWorkflowStage(null)
      let requestObj = {
        teamId: teamId,
        userId: userId,
        userFullName: userFullName,
        mortgageId: mortgageId,
        querySettings: {
          sweepType: sweepTypeSelection,
          sweepSize: sweepSizeSelection,
          existingScans: existingScansSelection,
          createLead: createLeadSelection,
          recordNewMonthlyStats: recordMonthlyStatsSelection,
          recordPerformance: recordQueryPerformanceSelection,
          notifyAssignees: notifyAssigneesSelection,
          matchPublicRecordsAll: matchPublicRecordsAllSelection,
          matchPublicRecordsNonParameters: matchPublicRecordsNonParametersSelection,
          retainLastSalePrice: retainLastSalePriceSelection,
          updateTeamStats: recordTeamStatsSelection,
          updateLastQuery: updateLastQuerySelection,
          varietyMortgages: varietyMortgagesSelection,
        }
      }
      await recordSweep(requestObj)
      dispatch( setDataIsLoaded(true) )
    } else if (stage === 'cancel') {
      setWorkflowStage(null)
    }
  }

  return (
    <ScrollWindow className="horizontalOnly" style={{maxWidth: 'calc(100vw - 23.3rem)', borderTop: `0.1rem solid ${theme.palette.common.grayText}`, height: 'fit-content', minHeight: '9.5rem'}}>
      <FlexWrapper style={{justifyContent: 'center', height: 'fit-content', minWidth: '80rem'}}>
        <div style={{width: '20rem', padding: '0 1rem 0 0'}}>
          <SubHeader style={{padding: '0.5rem 0 0 0'}}>Sweep Type</SubHeader>
          <GenericDropdown 
            value={sweepTypeSelection}
            handleChange={handleSweepTypeChange}
            disabled={false}
            items={[
              {label: 'Initial Match'},
              {label: 'Check All Discrepancies'},
              {label: 'Check Parameter Discrepancies'},
              {label: 'Standard'},
              {label: 'Standard + Retain Price'},
            ]} 
          />
        </div>
        <div style={{width: 'fit-content', padding: '0 1rem 0 0'}}>
          <SubHeader style={{padding: '0.5rem 0 0 0'}}>Sweep Size</SubHeader>
            <GenericDropdown 
              value={sweepSizeSelection}
              handleChange={handleSweepSizeSelectionChange}
              disabled={false}
              items={[
                {label: '1'},
                {label: '2'},
                {label: '3'},
                {label: '5'},
                {label: '10'},
              ]} 
            />
        </div>
        <div style={{width: 'fit-content', padding: '0 2rem 0 0'}}>
          <SubHeader style={{padding: '0.5rem 0 0 0'}}>Existing Scans</SubHeader>
          <GenericDropdown 
              value={existingScansSelection}
              handleChange={handleExistingScansSelectionChange}
              disabled={false}
              items={[
                {label: 'Any'},
                {label: '0'},
                {label: '1'},
                {label: '2'},
                {label: '3'},
              ]} 
            />
        </div>
        <div style={{width: 'fit-content'}}>
          <SubHeader style={{padding: '0.5rem 0 0 0'}}>Variety Mortgages</SubHeader>
          <RadioGroup row>
            <FormControlLabel
              control={<Radio {...primaryColorProps()} />}
              {...varietyMortgagesControlProp('true')} 
            />
            <FormControlLabel
              control={<Radio {...primaryColorProps()} />}
              {...varietyMortgagesControlProp('false')} 
            />
          </RadioGroup>
        </div>
        {workflowStage === 'initiate' ?
          <ButtonWrapper className="justifyCenter" style={{width: '11rem', margin: '0'}}>
            <Button className="primary prompt" onClick={() => handleClick('submit')} style={{margin: 'auto 0.5rem'}}>
              {!dataIsLoaded ?
                <CircularProgress
                  sx={{color: `${theme.palette.common.white}`}}
                  size={12}
                />
              :
                <CheckIcon />
              }
            </Button>
            <Button className="secondary prompt" onClick={() => handleClick('cancel')} style={{margin: 'auto 0.5rem'}}>
              <ClearIcon />
            </Button>
          </ButtonWrapper>
        :
          <ButtonWrapper style={{width: '11rem', margin: '0'}}>
            <Button className="primary" onClick={() => handleClick('initiate')} style={{width: '10rem', margin: '1rem'}}>Run Sweep</Button>
          </ButtonWrapper>
        }
        <ButtonWrapper style={{width: '11rem', margin: '0'}}>
          <Button className="primary" onClick={() => sendSweepResultsEmail()} style={{width: '10rem', margin: '1rem'}}>Send Email</Button>
        </ButtonWrapper>
      </FlexWrapper>
      <FlexWrapper className='justifyBetween' style={{margin: '2rem auto 1rem auto', width: '100rem', minWidth: '80rem', maxWidth: '100%', height: 'fit-content'}}>
        {matchPublicRecordsAllSelection === 'true' ?
          <SubHeader className="secondary">Match PR All</SubHeader>
        :
          <SubHeader>Match PR All</SubHeader>
        }
        {matchPublicRecordsNonParametersSelection === 'true' ?
          <SubHeader className="secondary">Match PR Parameters</SubHeader>
        :
          <SubHeader>Match PR Parameters</SubHeader>
        }
        {createLeadSelection === 'true' ?
          <SubHeader className="secondary">Create Leads</SubHeader>
        :
          <SubHeader>Create Leads</SubHeader>
        }
        {recordQueryPerformanceSelection === 'true' ?
          <SubHeader className="secondary">Record Sweep Performance</SubHeader>
        :
          <SubHeader>Record Sweep Performance</SubHeader>
        }
        {retainLastSalePriceSelection === 'true' ?
          <SubHeader className="secondary">Retain Last Sale Price</SubHeader>
        :
          <SubHeader>Retain Last Sale Price</SubHeader>
        }
        {notifyAssigneesSelection === 'true' ?
          <SubHeader className="secondary">Notify Assignees</SubHeader>
        :
          <SubHeader>Notify Assignees</SubHeader>
        }
        {recordMonthlyStatsSelection === 'true' ?
          <SubHeader className="secondary">Record Monthly Stats</SubHeader>
        :
          <SubHeader>Record Monthly Stats</SubHeader>
        }
        {recordTeamStatsSelection === 'true' ?
          <SubHeader className="secondary">Record Team Stats</SubHeader>
        :
          <SubHeader>Record Team Stats</SubHeader>
        }
        {updateLastQuerySelection === 'true' ?
          <SubHeader className="secondary">Record Last Query</SubHeader>
        :
          <SubHeader>Record Last Query</SubHeader>
        }
      </FlexWrapper>
    </ScrollWindow>
  )
}

export default BulkScanControls
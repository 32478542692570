import React, { useContext, useState, useRef } from "react"
import axios from 'axios'
import ErrorIcon from "@mui/icons-material/Error";
import FmdBadIcon from '@mui/icons-material/FmdBad';
import CompanyLogo from "../../../icon.logo.white.png";
import { useTheme } from "@mui/styles";
import { AppState } from "../../../AppState";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from 'react-router-dom';
import CircularProgress from "@mui/material/CircularProgress";
import { setDashPerspective } from "../../../slices/dashPerspectiveSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectLoginEnabled, selectNewcomerFormEnabled, setLoginEnabled, setNewcomerFormEnabled } from "../../../slices/functionAvailabilitySlice";
import { selectUserEmail, selectUserFirstName, setTeamId, setUserId, setUserInfo } from "../../../slices/userInfoSlice";
import { useEstablishRequestLocationMutation, useInitialLoadMutation, useLoginExistingSuperMutation, useLoginReturningUserMutation, useValidateAccessTokenMutation } from "../../../slices/api/apiSlice";
import { AlertWrapper, Button, FlexWrapper, InlineLink, InputWrapper, Label, StatusAndLabelWrapper, StyledInputElement, VerticalContainer } from "../../../StyledComponents"
import { setNavSelection } from "../../../slices/navSelectionSlice";
import { setAdminNavSelection } from "../../../slices/adminNavSelectionSlice";
import { setShowWelcomeModal } from "../../../slices/sessionDataSlice";

const AccessForm = ({ userHistory, isFetching, setIsFetching, setShowNewcomerForm, setIPAquired, setIPError, setUserConfirmed }) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const userFirstName = useSelector(selectUserFirstName)
  const userEmail = useSelector(selectUserEmail)
  const loginEnabled = useSelector(selectLoginEnabled)
  const newcomerFormEnabled = useSelector(selectNewcomerFormEnabled)
  const [handleLoginExistingSuper, {}] = useLoginExistingSuperMutation()
  const [handleLoginReturningUser, {}] = useLoginReturningUserMutation()
  const [establishRequestLocation, {}] = useEstablishRequestLocationMutation()
  const [initialLoad, {}] = useInitialLoadMutation()


  const [validateAccessToken, {}] = useValidateAccessTokenMutation()
  const { saveUserState } = useContext(AppState)

  const [viewPassword, setViewPassword] = useState(true)
  const [statusReqIP, setStatusReqIP] = useState(null)
  const [requestError, setRequestError] = useState(null)

  const [statusAccessToken, setStatusAccessToken] = useState(null)
  const accessToken = useRef();

  const handleTokenValidation = async () => {
    setIsFetching(true)
    if (accessToken.current.value.includes('<') || accessToken.current.value.includes('>') || accessToken.current.value.length > 13) {
      setStatusAccessToken("invalid")
    } else {
      const reqIP = await axios.get("https://api.ipify.org/?format=json")
      if (reqIP.data.ip.includes('<') || reqIP.data.ip.includes('>') || reqIP.data.ip.length > 30) {
        setStatusReqIP("invalid")
      } else if (reqIP.status === 200) {
        let reqLocation = 'N/A'
        let reqISP = 'N/A'
        let reqProxy = 'N/A'
        let reqMobile = 'N/A'
        const ipLocation = await establishRequestLocation({reqIP: reqIP.data.ip})
        if (ipLocation.data.message.includes('success')) {
          reqLocation = ipLocation.data.data.reqLocation
          reqISP = ipLocation.data.data.reqISP
          reqMobile = ipLocation.data.data.reqMobile
          reqProxy = ipLocation.data.data.reqProxy
          const resp = await validateAccessToken({
            accessToken: accessToken.current.value,
            reqIP: reqIP.data.ip,
            reqLocation: reqLocation,
            reqISP: reqISP,
            reqProxy: reqProxy,
            reqMobile: reqMobile,
          })
          if (!resp.error) {
            if (resp.data.message.includes('Validated')) {
              if (resp.data.data.accessType === 'guest') {
                if (userHistory === 'newcomer') {
                  dispatch( setNewcomerFormEnabled(true) )
                  setShowNewcomerForm(true)
                } else if (userHistory === 'existing') {
                  let login = await handleLoginReturningUser({
                    userEmail: userEmail,
                    reqIP: reqIP.data.ip,
                    reqLocation: reqLocation,
                    reqISP: reqISP,
                    reqProxy: reqProxy,
                    reqUserAgent: window.navigator.userAgent,
                    reqUserAgentData: window.navigator.userAgentData,
                  })
                  if (login.data.message.includes('No')) {
                    dispatch( setNewcomerFormEnabled(true) )
                    setShowNewcomerForm(true)
                  } else {
                    setUserConfirmed(true)
                    await initialLoad({userId: login.data.data.user.userId, authToken: login.data.data.accessToken})
                    await saveUserState({userId: login.data.data.user.userId, accessToken: login.data.data.accessToken})
                    dispatch( setLoginEnabled(false) )
                    dispatch( setUserInfo({
                      userId: login.data.data.user.userId,
                      role: login.data.data.user.role,
                      firstName: login.data.data.user.firstName,
                      fullName: login.data.data.user.fullName,
                      initials: login.data.data.user.initials,
                      teamId: login.data.data.user.teamId,
                    }) )
                    dispatch( setDashPerspective('team') )
                    dispatch( setUserId(login.data.data.user.userId) )
                    dispatch( setTeamId(login.data.data.user.team) )
                    dispatch( setNavSelection('guide') )
                    dispatch( setAdminNavSelection(null) )
                    dispatch( setShowWelcomeModal(true) )
                    navigate("/dashboard")
                  }
                }
              } else {
                setUserConfirmed(true)
                let login = await handleLoginExistingSuper({ 
                  reqIP: reqIP.data.ip,
                  reqLocation: reqLocation,
                  reqISP: reqISP,
                  reqProxy: reqProxy,
                  reqUserAgent: window.navigator.userAgent,
                  reqUserAgentData: window.navigator.userAgentData,
                })
                await initialLoad({userId: login.data.data.user.userId, authToken: login.data.data.accessToken})
                await saveUserState({userId: login.data.data.user.userId, accessToken: login.data.data.accessToken})
                dispatch( setLoginEnabled(false) )
                dispatch( setUserInfo({
                  userId: login.data.data.user.userId,
                  role: login.data.data.user.role,
                  firstName: login.data.data.user.firstName,
                  fullName: login.data.data.user.fullName,
                  initials: login.data.data.user.initials,
                  teamId: login.data.data.user.team,
                }) )
                dispatch( setDashPerspective('team') )
                dispatch( setUserId(login.data.data.user.userId) )
                dispatch( setTeamId(login.data.data.user.team) )
                dispatch( setNavSelection('activeLeads') )
                dispatch( setAdminNavSelection('uploads') )
                navigate("/admin")
              }
            }  else if (resp.data.message.includes('Invalid')) {
              setStatusAccessToken("invalid")
            } else {
              setRequestError(true)
            }
          } else {
            setRequestError(true)
          }
        } else {
          setIPAquired(false)
          setIPError(true)
        }
      } else {
        setIPAquired(false)
        setIPError(true)
      }
    }
    setIsFetching(false)
  }

  return (
    <FlexWrapper style={{justifyContent: 'space-around'}}>
      <VerticalContainer style={{width: '30rem', margin: '0'}}>
        {userHistory === 'existing' ?
          <FlexWrapper className="column" style={{margin: '3rem auto', height: 'fit-content', width: 'fit-content'}}>
            <FlexWrapper style={{margin: '1rem 0 4.5rem 0'}}>
              <img style={{width: '4.5rem', height: '4.5rem', opacity: '0.7'}} src={CompanyLogo} />
              <span style={{opacity: '0.8', fontSize: '3rem', color: `${theme.palette.common.grayBorderDark}`, fontWeight: '300', fontFamily: 'exo-bold', borderBottom: `0.1rem solid ${theme.light.accent.primary}`, height: '3.7rem', borderRadius: '1rem'}}>
                Lancaster Sweep
              </span>
            </FlexWrapper>
            <span style={{fontSize: '2rem', fontFamily: 'exo-bold', fontWeight: '600', color: `${theme.palette.common.grayBorderDark}`}}>
              {`Welcome back ${userFirstName}`}
            </span>
            <span style={{fontSize: '1.4rem', fontFamily: 'exo-mediumItalic', fontWeight: '600', color: `${theme.palette.common.grayBorderDark}`, fontWeight: '300'}}>
              sweeps are running on schedule...
            </span>
          </FlexWrapper>
        :
          <FlexWrapper className="column" style={{margin: '3rem auto', height: 'fit-content', width: 'fit-content'}}>
            <span style={{fontSize: '2rem', fontFamily: 'exo-bold', fontWeight: '600', color: `${theme.palette.common.grayBorderDark}`}}>
              Welcome to
            </span>
            <FlexWrapper style={{margin: '1rem 0 4.5rem 0'}}>
              <img style={{width: '4.5rem', height: '4.5rem', opacity: '0.7'}} src={CompanyLogo} />
              <span style={{opacity: '0.8', fontSize: '3rem', color: `${theme.palette.common.grayBorderDark}`, fontWeight: '300', fontFamily: 'exo-bold', borderBottom: `0.1rem solid ${theme.light.accent.primary}`, height: '3.7rem', borderRadius: '1rem'}}>
                Lancaster Sweep
              </span>
            </FlexWrapper>
            <span style={{fontSize: '1.4rem', fontFamily: 'exo-mediumItalic', fontWeight: '600', color: `${theme.palette.common.grayBorderDark}`, fontWeight: '300'}}>
              provide a token to access the dashboard...
            </span>
          </FlexWrapper>
        }
        <InputWrapper style={{margin: '0rem auto 3rem auto'}}>
          <StatusAndLabelWrapper>
            <Label className="colorLight" htmlFor="accessToken">Access Token</Label>
            {statusAccessToken === "invalid" && (
              <AlertWrapper className="error">
                <ErrorIcon />
                Invalid Token
              </AlertWrapper>
            )}
            {statusReqIP === "invalid" && (
              <AlertWrapper className="caution">
                <FmdBadIcon />
                Invalid Request
              </AlertWrapper>
            )}
            {requestError && (
              <AlertWrapper className="error">
                <ErrorIcon />
                Something went wrong
              </AlertWrapper>
            )}
          </StatusAndLabelWrapper>
          <FlexWrapper>
            <InlineLink style={{marginBottom: '1.5rem'}}>
              <VisibilityIcon onClick={() => setViewPassword(!viewPassword)} style={{color: viewPassword ? `${theme.palette.common.grayText}` : `${theme.palette.common.blackLight}`, width: '1.5rem', height: '1.5rem', margin: 'auto', position: 'absolute', right: '16rem'}} />
            </InlineLink>
            <StyledInputElement
              autoFocus
              autoComplete="off"
              type={viewPassword ? 'text' : 'password'}
              name="accessToken"
              id="accessToken"
              className="auth"
              ref={accessToken}
              onChange={() => {
                if (statusAccessToken === "invalid") {
                  setStatusAccessToken(null)
                }
                if (statusReqIP) {
                  setStatusReqIP(null)
                }
                if (requestError) {
                  setRequestError(null)
                }
              }}
            />
          </FlexWrapper>
        </InputWrapper>
        <Button 
          className={isFetching ? 'primaryLoading' : (!loginEnabled && !newcomerFormEnabled) ? "primaryDisabled" : "primary"}
          disabled={isFetching || (!loginEnabled && !newcomerFormEnabled)}
          onClick={handleTokenValidation}
          style={{marginBottom: '2.5rem'}}
        >
          {isFetching ? (
            <CircularProgress
              sx={{color: `${theme.palette.common.white}`}}
              size={12}
            />
          ) : (
            "Login"
          )}
        </Button>
      </VerticalContainer>
    </FlexWrapper>
  )
}

export default AccessForm
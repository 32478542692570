import React from "react";
import SellIcon from '@mui/icons-material/Sell';
import AdjustIcon from '@mui/icons-material/Adjust';
import CircleIcon from '@mui/icons-material/Circle';
import { useTheme } from "@mui/styles";
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import EditNoteIcon from '@mui/icons-material/EditNote';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import SummarizeIcon from '@mui/icons-material/Summarize';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import { FlexWrapper, GuideText } from "../../../../../StyledComponents";

const ActiveLeadGuide = () => {
  const theme = useTheme()

  return (
    <>
      <FlexWrapper className="column" style={{padding: '0 3rem'}}>
        <GuideText className="header large" style={{padding: '2rem 0 0 0'}}>
          "Active Leads" Component
        </GuideText>
        <GuideText className="medium" style={{padding: '1rem 0 0 0'}}>
          This is where your team will manage the workflow of addressing leads discovered by the system.
        </GuideText>
        <GuideText className="small" style={{padding: '0.5rem 0 0 0'}}>
          You can filter, sort, group, and assign leads to streamline the process.
        </GuideText>
      </FlexWrapper>

      <FlexWrapper className="column" style={{padding: '3rem 3rem 2.5rem 3rem'}}>
        <GuideText className="header large" style={{padding: '1rem 0 0 0'}}>
          Standard Workflow
        </GuideText>
        <GuideText className="small" style={{padding: '1rem 0 0 0'}}>
          * Leads discovered by the system are given an "awaiting action" status.
        </GuideText>
        <GuideText className="small">
          * Once examined, the user determines if the lead can be dismissed or if it requires further investigation.
        </GuideText>
        <GuideText className="small">
          * A lead can be assigned to any user in the system so that they can receive relevent notifications directly in the email.
        </GuideText>
        <GuideText className="small">
          * Once a resolution is established, a lead can be given a "closing" status as it awaits the input of the finalized details.
        </GuideText>
        <GuideText className="small">
          * Once finalized, a downloadable report is generated to record the details of the investigation and the new arrangements.
        </GuideText>
        <GuideText className="sub" style={{padding: '0 0 0 3rem'}}>
          - Contact your App Admin to customize the default report layout or even add details to a specific report.
        </GuideText>
        <GuideText className="small" style={{padding: '1.5rem 0 0 0'}}>
          Metrics are kept on the outcome of every lead in order to assess performance over time.
        </GuideText>
        <GuideText className="small">
          Snapshots of these statistics can be seen in the "Performance" components.
        </GuideText>
        <GuideText className="medium">
        </GuideText>
      </FlexWrapper>

      <div style={{width: '80%', margin: 'auto', minHeight: '0.1rem', background: `${theme.palette.common.grayBorder}`}} />

      <FlexWrapper className="column" style={{padding: '1rem 3rem 0 3rem'}}>
        <GuideText className="header large" style={{padding: '1rem 0 0 0'}}>
          Lead Actions
        </GuideText>
        <FlexWrapper className="alignCenter" style={{padding: '1rem 0 0 0'}}>
          <SummarizeIcon style={{color: `${theme.palette.primary.main}`, width: '2rem', height: '2rem', margin: '0 0.7rem'}} />
          <GuideText className="medium">
            View Reports
          </GuideText>
        </FlexWrapper>
        <GuideText className="small">
          Any reports previously generated for this mortgage can be viewed here.
        </GuideText>
        <FlexWrapper className="alignCenter" style={{padding: '2rem 0 0 0'}}>
          <EditNoteIcon style={{color: `${theme.palette.primary.main}`, width: '2rem', height: '2rem', margin: '0 0.7rem'}} />
          <GuideText className="medium">
            Add Notes
          </GuideText>
        </FlexWrapper>
        <GuideText className="small">
          Keep details about this mortgage and the investigation here.
        </GuideText>
        <FlexWrapper className="alignCenter" style={{padding: '2rem 0 0 0'}}>
          <FileCopyIcon style={{color: `${theme.palette.primary.main}`, width: '2rem', height: '2rem', margin: '0 0.7rem'}} />
          <GuideText className="medium">
            Compare Records
          </GuideText>
        </FlexWrapper>
        <GuideText className="small">
          Comparing the mortgage's old details with the current public records gives a detailed perspective of the situation. 
        </GuideText>
        <GuideText className="small">
          The user can easily update the mortgage details, which is required in order to close the lead with a new agreement. 
        </GuideText>
        <FlexWrapper className="alignCenter" style={{padding: '2rem 0 0 0'}}>
          <HistoryEduIcon style={{color: `${theme.palette.primary.main}`, width: '2rem', height: '2rem', margin: '0 0.7rem'}} />
          <GuideText className="medium">
            Mortgage Details
          </GuideText>
        </FlexWrapper>
        <GuideText className="small">
          Detailed mortgage information. User-editable.
        </GuideText>
        <GuideText className="small">
          The system maintains an estimation of up-to-date loan figures, including principal and interest breakdowns and amount remaining.
        </GuideText>
        <FlexWrapper className="alignCenter" style={{padding: '2rem 0 0 0'}}>
          <HomeWorkIcon style={{color: `${theme.palette.primary.main}`, width: '2rem', height: '2rem', margin: '0 0.7rem'}} />
          <GuideText className="medium">
            Property Details
          </GuideText>
        </FlexWrapper>
        <GuideText className="small">
          Detailed property information. Updated automatically to match public records.
        </GuideText>
        <FlexWrapper className="alignCenter" style={{padding: '2rem 0 0 0'}}>
          <AdjustIcon style={{color: `${theme.palette.primary.main}`, width: '2rem', height: '2rem', margin: '0 0.7rem'}} />
          <GuideText className="medium">
            Change Refinance Targets
          </GuideText>
        </FlexWrapper>
        <GuideText className="small">
          A user can set a refinance as the intended outcome of a lead.
        </GuideText>
        <GuideText className="small">
          The term, principal, and interest rate can be defined here, which the system uses to present the forecasted profit numbers. 
        </GuideText>
        <FlexWrapper className="alignCenter" style={{padding: '2rem 0 0 0'}}>
          <SellIcon style={{color: `${theme.palette.primary.main}`, width: '2rem', height: '2rem', margin: '0 0.7rem'}} />
          <GuideText className="medium">
            Assign Tags
          </GuideText>
        </FlexWrapper>
        <GuideText className="small">
          Tags are used to group, sort, and easily identify the scenario behind each lead.
        </GuideText>
        <GuideText className="small">
          While tags are initially assigned by the system, the user can create additional tags for further control and organization.
        </GuideText>
        <FlexWrapper className="alignCenter" style={{padding: '2rem 0 0 0'}}>
          <PersonAddIcon style={{color: `${theme.palette.primary.main}`, width: '2rem', height: '2rem', margin: '0 0.7rem'}} />
          <GuideText className="medium">
            Add Assignees
          </GuideText>
        </FlexWrapper>
        <GuideText className="small">
          When a user is assigned to a lead, the system will send them email notifications when there are relevent developments or actions taken.
        </GuideText>
        <FlexWrapper className="alignCenter" style={{padding: '2rem 0 0 0'}}>
          <CircleIcon style={{color: `${theme.palette.primary.main}`, width: '2rem', height: '2rem', margin: '0 0.7rem'}} />
          <GuideText className="medium">
            Advance Lead
          </GuideText>
        </FlexWrapper>
        <GuideText className="small">
          Leads are moved from "awaiting action" to "investigating" to "closing" throughout the duration of resolving a violation. 
        </GuideText>
        <GuideText className="small">
          Metrics are kept on the discrepancies that lead to a resolution in order to increase performance over time. 
        </GuideText>
        <FlexWrapper className="alignCenter" style={{padding: '2rem 0 0 0'}}>
          <CircleIcon style={{color: `${theme.palette.secondary.main}`, width: '2rem', height: '2rem', margin: '0 0.7rem'}} />
          <GuideText className="medium">
            Dismiss Lead
          </GuideText>
        </FlexWrapper>
        <GuideText className="small">
          If the current scenario is determined to not require mitigating action, the lead can be dismissed.
        </GuideText>
        <GuideText className="small">
          All records of changes made or actions taken are saved alongside the mortgage.
        </GuideText>
      </FlexWrapper>
    </>
  )
}

export default ActiveLeadGuide
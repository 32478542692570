import React, { useContext, useRef, useState } from "react"
import axios from 'axios'
import ErrorIcon from "@mui/icons-material/Error";
import FmdBadIcon from '@mui/icons-material/FmdBad';
import { useTheme } from "@mui/styles";
import { AppState } from "../../../AppState";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { setNavSelection } from "../../../slices/navSelectionSlice";
import { setDashPerspective } from "../../../slices/dashPerspectiveSlice";
import { setShowWelcomeModal } from "../../../slices/sessionDataSlice";
import { setAdminNavSelection } from "../../../slices/adminNavSelectionSlice";
import { useDispatch, useSelector } from "react-redux";
import { setTeamId, setUserId, setUserInfo } from "../../../slices/userInfoSlice";
import { selectNewcomerFormEnabled, setNewcomerFormEnabled } from "../../../slices/functionAvailabilitySlice";
import { useCreateGuestAccountMutation, useEstablishRequestLocationMutation, useInitialLoadMutation } from "../../../slices/api/apiSlice";
import { AlertWrapper, Button, FlexWrapper, InputWrapper, Label, StatusAndLabelWrapper, StyledInputElement, VerticalContainer } from "../../../StyledComponents"

const NewcomerForm = ({ isFetching, setIsFetching, setIPAquired, setIPError, setUserConfirmed }) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { saveUserState } = useContext(AppState)

  const newcomerFormEnabled = useSelector(selectNewcomerFormEnabled)

  const [createGuest, {}] = useCreateGuestAccountMutation()
  const [initialLoad, {}] = useInitialLoadMutation()
  const [establishRequestLocation, {}] = useEstablishRequestLocationMutation()

  const [launchRequired, setLaunchRequired] = useState(null)
  const [existingEmail, setExistingEmail] = useState(null)
  const [statusReqIP, setStatusReqIP] = useState(null)
  const [statusNewcomerFirstName, setStatusNewcomerFirstName] = useState(null)
  const newcomerFirstName = useRef()
  const [statusNewcomerLastName, setStatusNewcomerLastName] = useState(null)
  const newcomerLastName = useRef()
  const [statusNewcomerEmail, setStatusNewcomerEmail] = useState(null)
  const newcomerEmail = useRef()

  const handleValidateForm = async () => {
    setIsFetching(true)
    let formValidated = true
    if (newcomerFirstName.current.value.includes('<') || newcomerFirstName.current.value.includes('>') || newcomerFirstName.current.value.length > 30) {
      if (newcomerFirstName.current.value.length > 30) {
        setStatusNewcomerFirstName("tooLong")
      }
      if (newcomerFirstName.current.value.includes('<') || newcomerFirstName.current.value.includes('>')) {
        setStatusNewcomerFirstName("invalid")
      }
      formValidated = false
    }
    if (newcomerLastName.current.value.includes('<') || newcomerLastName.current.value.includes('>') || newcomerLastName.current.value.length > 30) {
      if (newcomerLastName.current.value.length > 30) {
        setStatusNewcomerLastName("tooLong")
      }
      if (newcomerLastName.current.value.includes('<') || newcomerLastName.current.value.includes('>')) {
        setStatusNewcomerLastName("invalid")
      }
      formValidated = false
    }
    let validEmail = newcomerEmail.current.value.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
    if (!validEmail) {
      setStatusNewcomerEmail('invalid')
      formValidated = false
    }
    if (formValidated) {
      const reqIP = await axios.get("https://api.ipify.org/?format=json")
      if (reqIP.data.ip.includes('<') || reqIP.data.ip.includes('>') || reqIP.data.ip.length > 30) {
        setStatusReqIP("invalid")
      } else if (reqIP.status === 200) {
        let reqLocation = 'N/A'
        let reqISP = 'N/A'
        let reqMobile = 'N/A'
        let reqProxy = 'N/A'
        const ipLocation = await establishRequestLocation({reqIP: reqIP.data.ip})
        if (ipLocation.data.message.includes('success')) {
          reqLocation = ipLocation.data.data.reqLocation
          reqISP = ipLocation.data.data.reqISP
          reqMobile = ipLocation.data.data.reqMobile
          reqProxy = ipLocation.data.data.reqProxy
        }
        let resp = await createGuest({
          ignorDuplicateEmails: false,
          firstName: newcomerFirstName.current.value,
          lastName: newcomerLastName.current.value,
          email: newcomerEmail.current.value,
          reqIP: reqIP.data.ip,
          reqLocation: reqLocation,
          reqISP: reqISP,
          reqProxy: reqProxy,
          reqMobile: reqMobile,
          reqUserAgent: window.navigator.userAgent,
          reqUserAgentData: window.navigator.userAgentData,
        })
        if (resp.data.message.includes('Launch')) {
          dispatch( setNewcomerFormEnabled(false) )
          setLaunchRequired(true)
        } else if (resp.data.message.includes('Email')) {
          setExistingEmail(true)
        } else {
          setUserConfirmed(true)
          await initialLoad({userId: resp.data.data.guest.userId, authToken: resp.data.data.accessToken})
          dispatch( setNewcomerFormEnabled(false) )
          saveUserState({userId: resp.data.data.guest.userId, accessToken: resp.data.data.accessToken})
          dispatch( setUserInfo({
            userId: resp.data.data.guest.userId,
            role: resp.data.data.guest.role,
            firstName: resp.data.data.guest.firstName,
            fullName: resp.data.data.guest.fullName,
            initials:  resp.data.data.guest.initials,
            teamId: resp.data.data.guest.team,
          }) )
          dispatch( setDashPerspective('team') )
          dispatch( setUserId(resp.data.data.guest.userId) )
          dispatch( setTeamId(resp.data.data.guest.team) )
          dispatch( setNavSelection('guide') )
          dispatch( setAdminNavSelection(null) )
          dispatch( setShowWelcomeModal(true) )
          navigate("/dashboard")
        }
      } else {
        setIPAquired(false)
        setIPError(true)
      }
    }
    setIsFetching(false)
  }

  return (
    <FlexWrapper style={{justifyContent: 'space-around'}}>
      <VerticalContainer style={{width: '30rem', margin: '0'}}>
        <FlexWrapper className="column" style={{margin: '3rem auto 0 auto', height: 'fit-content', width: 'fit-content'}}>
          {launchRequired ?
            <AlertWrapper className="caution medium thin" style={{margin: '1rem 0 1rem 0'}}>
              <FmdBadIcon />
              Launch required
            </AlertWrapper>
          :
            <>
              <span style={{fontSize: '2rem', fontFamily: 'exo-bold', fontWeight: '600', color: `${theme.palette.common.grayBorderDark}`}}>
                Access Granted!
              </span>
              <span style={{fontSize: '1.2rem', fontFamily: 'exo-mediumItalic', fontWeight: '600', color: `${theme.palette.common.grayBorderDark}`, marginTop: '1rem'}}>
                please provide some info before proceeding
              </span>
            </>
          }
        </FlexWrapper>
        <InputWrapper style={{margin: '0rem auto 2rem auto'}}>
          <StatusAndLabelWrapper>
            <Label className="colorLight" htmlFor="newcomerFirstName">First Name</Label>
            {statusNewcomerFirstName === "invalid" && (
              <AlertWrapper className="error">
                <ErrorIcon />
                {'Invalid Characters (< or >)'}
              </AlertWrapper>
            )}
            {statusNewcomerFirstName === "tooLong" && (
              <AlertWrapper className="error">
                <ErrorIcon />
                {'Too Long (>30)'}
              </AlertWrapper>
            )}
            {statusReqIP === 'invalid' && (
              <AlertWrapper className="caution">
                <FmdBadIcon />
                Unreadable IP Address
              </AlertWrapper>
            )}
          </StatusAndLabelWrapper>
          <StyledInputElement
            autoFocus
            autoComplete="off"
            type="text"
            name="newcomerFirstName"
            id="newcomerFirstName"
            className="auth"
            ref={newcomerFirstName}
            onChange={() => {
              if (statusNewcomerFirstName !== null) {
                setStatusNewcomerFirstName(null)
              }
              if (statusReqIP !== null) {
                setStatusReqIP(null)
              }
            }}
          />
        </InputWrapper>
        <InputWrapper style={{margin: '0rem auto 2rem auto'}}>
          <StatusAndLabelWrapper>
            <Label className="colorLight" htmlFor="newcomerLastName">Last Name</Label>
            {statusNewcomerLastName === "invalid" && (
              <AlertWrapper className="error">
                <ErrorIcon />
                {'Invalid Characters (< or >)'}
              </AlertWrapper>
            )}
            {statusNewcomerLastName === "tooLong" && (
              <AlertWrapper className="error">
                <ErrorIcon />
                {'Too Long (>30)'}
              </AlertWrapper>
            )}
          </StatusAndLabelWrapper>
          <StyledInputElement
            autoComplete="off"
            type="text"
            name="newcomerLastName"
            id="newcomerLastName"
            className="auth"
            ref={newcomerLastName}
            onChange={() => {
              if (statusNewcomerLastName !== null) {
                setStatusNewcomerLastName(null)
              }
            }}
          />
        </InputWrapper>
        <InputWrapper style={{margin: '0rem auto 2rem auto'}}>
          <StatusAndLabelWrapper>
            <Label className="colorLight" htmlFor="newcomerEmail">Email</Label>
            {statusNewcomerEmail === "invalid" && (
              <AlertWrapper className="error">
                <ErrorIcon />
                Invalid Email
              </AlertWrapper>
            )}
            {existingEmail && (
              <AlertWrapper className="caution">
                <FmdBadIcon />
                Email already registered
              </AlertWrapper>
            )}
          </StatusAndLabelWrapper>
          <StyledInputElement
            autoComplete="off"
            type="text"
            name="newcomerEmail"
            id="newcomerEmail"
            className="auth"
            ref={newcomerEmail}
            onChange={() => {
              if (statusNewcomerEmail !== null) {
                setStatusNewcomerEmail(null)
              }
              if (existingEmail) {
                setExistingEmail(null)
              }
            }}
          />
        </InputWrapper>
        <Button 
          className={isFetching ? 'primaryLoading' : !newcomerFormEnabled ? "primaryDisabled" : "primary"}
          disabled={isFetching || !newcomerFormEnabled}
          onClick={handleValidateForm}
          style={{marginBottom: '2.5rem'}}
        >
          {isFetching ? (
            <CircularProgress
              sx={{color: `${theme.palette.common.white}`}}
              size={12}
            />
          ) : (
            "Login"
          )}
        </Button>
      </VerticalContainer>
    </FlexWrapper>
  )
}

export default NewcomerForm
import React, { useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { selectActionsAndErrorsLog } from "../../../../slices/teamAdminDataSlice";
import { ActionIconWrapper, ColumnContent, ComponentBorder, ComponentContainer, ComponentSectionRow, RowsContainer, ScrollWindow } from "../../../../StyledComponents";
import { useDeleteActionOrErrorLogMutation } from "../../../../slices/api/apiSlice";

const ActionsAndErrorsLog = () => {
  const theme = useTheme()
  
  const rows = useSelector(selectActionsAndErrorsLog)

  const [deleteLog] = useDeleteActionOrErrorLogMutation()  

  const [confirmButtons, setConfirmButtons] = useState(false)

  const handleOpenDeleteLogModal = async (logId) => {
    await deleteLog({logId: logId})
    setConfirmButtons(false)
  }

  return (
    <ComponentContainer style={{height: '100%'}}>
      <ScrollWindow className="horizontalOnly" style={{maxWidth: 'calc(100vw - 23.3rem)'}}>
        <ComponentSectionRow className="header actionsAndErrorsLog">
          <ColumnContent className="header" style={{gridColumn: '1'}}>
            index
          </ColumnContent>
          <ColumnContent className="header" style={{gridColumn: '2'}}>
            Type
          </ColumnContent>
          <ColumnContent className="header" style={{gridColumn: '3'}}>
            Time
          </ColumnContent>
          <ColumnContent className="header" style={{gridColumn: '4'}}>
            Subject
          </ColumnContent>
          <ColumnContent className="header" style={{gridColumn: '5'}}>
            Location
          </ColumnContent>
          <ColumnContent className="header" style={{gridColumn: '6'}}>
            User
          </ColumnContent>
          <ColumnContent className="header" style={{gridColumn: '7'}}>
            Details
          </ColumnContent>
        </ComponentSectionRow>
        <RowsContainer style={{height: '100%', maxHeight: 'calc(100vh - 15.1rem)'}}>
          {rows.toReversed().map((row, index) => (
            <ComponentSectionRow key={index} className={(index%2 === 1 ? 'even ' : '') + (row.type === 'Error' ? 'error ' : '') + 'actionsAndErrorsLog'}>
              <ColumnContent className="detail" style={{gridColumn: '1'}}>
                {confirmButtons ?
                  <>
                    <ActionIconWrapper className={(index%2 === 1 ? 'even ' : '') + 'primary'}>
                      <CheckIcon onClick={() => handleOpenDeleteLogModal(row._id)} style={{height: '2rem', width: '2rem'}} />
                    </ActionIconWrapper>
                    <ActionIconWrapper className={(index%2 === 1 ? 'even ' : '') + 'hover'}>
                      <CloseIcon onClick={() => setConfirmButtons(false)} style={{height: '2rem', width: '2rem'}} />
                    </ActionIconWrapper>
                  </>
                :
                  <>
                    {index + 1}
                    <ActionIconWrapper className={(index%2 === 1 ? 'even ' : '') + 'hover'}>
                      <DeleteIcon onClick={() => setConfirmButtons(true)} style={{height: '2rem', width: '2rem'}} />
                    </ActionIconWrapper>
                  </>
                }
              </ColumnContent>
              <ColumnContent className="detail" style={{gridColumn: '2'}}>
                {row.type}
              </ColumnContent>
              <ColumnContent className="detail" style={{gridColumn: '3'}}>
                {row.time}
              </ColumnContent>
              <ColumnContent className="detail" style={{gridColumn: '4'}}>
                {row.subject}
              </ColumnContent>
              <ColumnContent className="detail" style={{gridColumn: '5'}}>
                {row.location}
              </ColumnContent>
              <ColumnContent className="detail" style={{gridColumn: '6'}}>
                {row.user}
              </ColumnContent>
                {row.type === 'Error' ?
                  <ColumnContent className="detail" style={{gridColumn: '7'}}>
                    {row.message}
                  </ColumnContent>
                :
                  row.details.map((detail, index) => (
                    <ColumnContent key={index} className="detail" style={{gridColumn: '7'}}>
                      {detail.type}: {detail.detail}
                    </ColumnContent>
                  ))
                }
            </ComponentSectionRow>
          ))}
        </RowsContainer>
      </ScrollWindow>

      <ComponentBorder className="top" style={{gridRow:'1', gridColumn: '1/4'}} />
      <ComponentBorder className="left" style={{gridRow:'2', gridColumn: '1'}} />
      <ComponentBorder className="right"  style={{gridRow:'2', gridColumn: '3'}} />
      <ComponentBorder className="bottom" style={{gridRow:'3', gridColumn: '1/4'}} />
    </ComponentContainer>
  )
}

export default ActionsAndErrorsLog;
import React from "react";
import Team from "./components/Team";
import Users from "./components/Users";
import Reports from "./components/Reports";
import MonthlyStats from "./components/MonthlyStats";
import PublicRecords from "./components/PublicRecords";
import SweepParameters from "./components/SweepParameters";
import { useSelector } from "react-redux";
import { selectDataBase } from "../../../../slices/teamAdminDataSlice";
import NotificationSchedules from "./components/NotificationSchedules";
import { ComponentBorder, ComponentContainer, FlexWrapper } from "../../../../StyledComponents";

const DataBase = ({ userId }) => {

  let data = useSelector(selectDataBase)

  return (
    <ComponentContainer className="fitWindow">
      <FlexWrapper className="column">
        <Team teamData={data.team} />
        <Users userData={data.users} userId={userId} />
        <Reports reportData={data.reportIds} />
        <NotificationSchedules notificationScheduleData={data.notificationSchedules} />
        <PublicRecords publicRecordData={data.publicRecords} />
        <SweepParameters />
        <MonthlyStats monthlyStatsData={data.monthlyStats} />
      </FlexWrapper>

      <ComponentBorder className="top" style={{gridRow:'1', gridColumn: '1/4'}} />
      <ComponentBorder className="left" style={{gridRow:'2', gridColumn: '1'}} />
      <ComponentBorder className="right"  style={{gridRow:'2', gridColumn: '3'}} />
      <ComponentBorder className="bottom" style={{gridRow:'3', gridColumn: '1/4'}} />
    </ComponentContainer>
  )
}

export default DataBase;
export default async function handleLeadTypeFilterChanges(eventValue, leadsAwaitingAction, teamInvestigatingLeads, teamClosingLeads, leadTierFilter, leadNewFilter) {
    let awaitingAction = await handleAwaitingAction(leadsAwaitingAction, leadTierFilter, leadNewFilter);
    let investigating = await handleInvestigating(eventValue, teamInvestigatingLeads, leadTierFilter, leadNewFilter);
    let closing = await handleClosing(eventValue, teamClosingLeads, leadTierFilter, leadNewFilter);
    return {awaitingAction, investigating, closing}
  }

  let handleAwaitingAction = async (leadsAwaitingAction, leadTierFilter, leadNewFilter) => {
    let filteredLeadsAwaitingAction = leadsAwaitingAction
    if (leadNewFilter === 'false') {
      if (leadTierFilter === 'All' || !leadTierFilter) {
  
      } else if (leadTierFilter === 'Manual') {
        filteredLeadsAwaitingAction = [...leadsAwaitingAction].filter((lead) => lead.tier === 0)
      } else if (leadTierFilter === 'Tier 1') {
        filteredLeadsAwaitingAction = [...leadsAwaitingAction].filter((lead) => lead.tier === 1)
      } else if (leadTierFilter === 'Tier 2') {
        filteredLeadsAwaitingAction = [...leadsAwaitingAction].filter((lead) => lead.tier === 2)
      } else {
        filteredLeadsAwaitingAction = [...leadsAwaitingAction].filter((lead) => lead.tier === 3)
      }
    } else {
      if (leadTierFilter === 'All' || !leadTierFilter) {
        filteredLeadsAwaitingAction = [...leadsAwaitingAction].filter((lead) => lead.newLeadLabel === true)
      } else if (leadTierFilter === 'Manual') {
        filteredLeadsAwaitingAction = [...leadsAwaitingAction].filter((lead) => lead.tier === 0 && lead.newLeadLabel === true)
      } else if (leadTierFilter === 'Tier 1') {
        filteredLeadsAwaitingAction = [...leadsAwaitingAction].filter((lead) => lead.tier === 1 && lead.newLeadLabel === true)
      } else if (leadTierFilter === 'Tier 2') {
        filteredLeadsAwaitingAction = [...leadsAwaitingAction].filter((lead) => lead.tier === 2 && lead.newLeadLabel === true)
      } else {
        filteredLeadsAwaitingAction = [...leadsAwaitingAction].filter((lead) => lead.tier === 3 && lead.newLeadLabel === true)
      }
    }
    return filteredLeadsAwaitingAction
  }
  let handleInvestigating = async (eventValue, teamInvestigatingLeads, leadTierFilter, leadNewFilter) => {
    let filteredInvestigatingLeads = teamInvestigatingLeads
    if (eventValue === 'renegotiation') {
      if (leadNewFilter === 'false' || !leadNewFilter) {
        if (leadTierFilter === 'All' || !leadTierFilter) {
          filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.targetOutcome === 'renegotiation')
        } else if (leadTierFilter === 'Manual') {
          filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.targetOutcome === 'renegotiation' && lead.tier === 0)
        } else if (leadTierFilter === 'Tier 1') {
          filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.targetOutcome === 'renegotiation' && lead.tier === 1)
        } else if (leadTierFilter === 'Tier 2') {
          filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.targetOutcome === 'renegotiation' && lead.tier === 2)
        } else {
          filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.targetOutcome === 'renegotiation' && lead.tier === 3)
        }
      } else {
        if (leadTierFilter === 'All' || !leadTierFilter) {
          filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.targetOutcome === 'renegotiation' && lead.newAssignmentNotification === true)
        } else if (leadTierFilter === 'Manual') {
          filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.targetOutcome === 'renegotiation' && lead.newAssignmentNotification === true && lead.tier === 0)
        } else if (leadTierFilter === 'Tier 1') {
          filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.targetOutcome === 'renegotiation' && lead.newAssignmentNotification === true && lead.tier === 1)
        } else if (leadTierFilter === 'Tier 2') {
          filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.targetOutcome === 'renegotiation' && lead.newAssignmentNotification === true && lead.tier === 2)
        } else {
          filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.targetOutcome === 'renegotiation' && lead.newAssignmentNotification === true && lead.tier === 3)
        }
      }
    } else if (eventValue === 'refinance') {
      if (leadNewFilter === 'false' || !leadNewFilter) {
        if (leadTierFilter === 'All' || !leadTierFilter) {
          filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.targetOutcome === 'refinance')
        } else if (leadTierFilter === 'Manual') {
          filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.targetOutcome === 'refinance' && lead.tier === 0)
        } else if (leadTierFilter === 'Tier 1') {
          filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.targetOutcome === 'refinance' && lead.tier === 1)
        } else if (leadTierFilter === 'Tier 2') {
          filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.targetOutcome === 'refinance' && lead.tier === 2)
        } else {
          filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.targetOutcome === 'refinance' && lead.tier === 3)
        }
      } else {
        if (leadTierFilter === 'All' || !leadTierFilter) {
          filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.targetOutcome === 'refinance' && lead.newAssignmentNotification === true)
        } else if (leadTierFilter === 'Manual') {
          filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.targetOutcome === 'refinance' && lead.newAssignmentNotification === true && lead.tier === 0)
        } else if (leadTierFilter === 'Tier 1') {
          filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.targetOutcome === 'refinance' && lead.newAssignmentNotification === true && lead.tier === 1)
        } else if (leadTierFilter === 'Tier 2') {
          filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.targetOutcome === 'refinance' && lead.newAssignmentNotification === true && lead.tier === 2)
        } else {
          filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.targetOutcome === 'refinance' && lead.newAssignmentNotification === true && lead.tier === 3)
        }
      }
    } else {
      if (leadNewFilter === 'false' || !leadNewFilter) {
        if (leadTierFilter === 'All' || !leadTierFilter) {
  
        } else if (leadTierFilter === 'Manual') {
          filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.tier === 0)
        } else if (leadTierFilter === 'Tier 1') {
          filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.tier === 1)
        } else if (leadTierFilter === 'Tier 2') {
          filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.tier === 2)
        } else {
          filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.tier === 3)
        }
      } else {
        if (leadTierFilter === 'All' || !leadTierFilter) {
          filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.newAssignmentNotification === true)
        } else if (leadTierFilter === 'Manual') {
          filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.tier === 0 && lead.newAssignmentNotification === true)
        } else if (leadTierFilter === 'Tier 1') {
          filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.tier === 1 && lead.newAssignmentNotification === true)
        } else if (leadTierFilter === 'Tier 2') {
          filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.tier === 2 && lead.newAssignmentNotification === true)
        } else {
          filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.tier === 3 && lead.newAssignmentNotification === true)
        }
      }
    }
    return filteredInvestigatingLeads
  }
  let handleClosing = async (eventValue, teamClosingLeads, leadTierFilter, leadNewFilter) => {
    let filteredClosingLeads = teamClosingLeads
    if (eventValue === 'renegotiation') {
      if (leadNewFilter === 'false' || !leadNewFilter) {
        if (leadTierFilter === 'All' || !leadTierFilter) {
          filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.targetOutcome === 'renegotiation')
        } else if (leadTierFilter === 'Manual') {
          filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.targetOutcome === 'renegotiation' && lead.tier === 0)
        } else if (leadTierFilter === 'Tier 1') {
          filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.targetOutcome === 'renegotiation' && lead.tier === 1)
        } else if (leadTierFilter === 'Tier 2') {
          filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.targetOutcome === 'renegotiation' && lead.tier === 2)
        } else {
          filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.targetOutcome === 'renegotiation' && lead.tier === 3)
        }
      } else {
        if (leadTierFilter === 'All' || !leadTierFilter) {
          filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.targetOutcome === 'renegotiation'  && lead.newAssignmentNotification === true)
        } else if (leadTierFilter === 'Manual') {
          filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.targetOutcome === 'renegotiation'  && lead.newAssignmentNotification === true && lead.tier === 0)
        } else if (leadTierFilter === 'Tier 1') {
          filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.targetOutcome === 'renegotiation'  && lead.newAssignmentNotification === true && lead.tier === 1)
        } else if (leadTierFilter === 'Tier 2') {
          filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.targetOutcome === 'renegotiation'  && lead.newAssignmentNotification === true && lead.tier === 2)
        } else {
          filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.targetOutcome === 'renegotiation'  && lead.newAssignmentNotification === true && lead.tier === 3)
        }
      }
    } else if (eventValue === 'refinance') {
      if (leadNewFilter === 'false' || !leadNewFilter) {
        if (leadTierFilter === 'All' || !leadTierFilter) {
          filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.targetOutcome === 'refinance')
        } else if (leadTierFilter === 'Manual') {
          filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.targetOutcome === 'refinance' && lead.tier === 0)
        } else if (leadTierFilter === 'Tier 1') {
          filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.targetOutcome === 'refinance' && lead.tier === 1)
        } else if (leadTierFilter === 'Tier 2') {
          filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.targetOutcome === 'refinance' && lead.tier === 2)
        } else {
          filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.targetOutcome === 'refinance' && lead.tier === 3)
        }
      } else {
        if (leadTierFilter === 'All' || !leadTierFilter) {
          filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.targetOutcome === 'refinance'  && lead.newAssignmentNotification === true)
        } else if (leadTierFilter === 'Manual') {
          filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.targetOutcome === 'refinance'  && lead.newAssignmentNotification === true && lead.tier === 0)
        } else if (leadTierFilter === 'Tier 1') {
          filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.targetOutcome === 'refinance'  && lead.newAssignmentNotification === true && lead.tier === 1)
        } else if (leadTierFilter === 'Tier 2') {
          filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.targetOutcome === 'refinance'  && lead.newAssignmentNotification === true && lead.tier === 2)
        } else {
          filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.targetOutcome === 'refinance'  && lead.newAssignmentNotification === true && lead.tier === 3)
        }
      }
    } else {
      if (leadNewFilter === 'false' || !leadNewFilter) {
        if (leadTierFilter === 'All' || !leadTierFilter) {
  
        } else if (leadTierFilter === 'Manual') {
          filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.tier === 0)
        } else if (leadTierFilter === 'Tier 1') {
          filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.tier === 1)
        } else if (leadTierFilter === 'Tier 2') {
          filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.tier === 2)
        } else {
          filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.tier === 3)
        }
      } else {
        if (leadTierFilter === 'All' || !leadTierFilter) {
          filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.newAssignmentNotification === true)
        } else if (leadTierFilter === 'Manual') {
          filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.tier === 0  && lead.newAssignmentNotification === true)
        } else if (leadTierFilter === 'Tier 1') {
          filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.tier === 1  && lead.newAssignmentNotification === true)
        } else if (leadTierFilter === 'Tier 2') {
          filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.tier === 2  && lead.newAssignmentNotification === true)
        } else {
          filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.tier === 3  && lead.newAssignmentNotification === true)
        }
      }
    }
    return filteredClosingLeads
  }
import React from "react";
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from "@mui/icons-material/Error";
import { useTheme } from "@mui/styles";
import CircularProgress from "@mui/material/CircularProgress";
import 'react-csv-importer/dist/index.css';
import { Button, ButtonWrapper, ModalContentContainer, ComponentHeader, SubHeader } from "../../../../../StyledComponents";
import { useDismissLeadMutation, useReloadRefinanceQuarterlyBreakdownMutation, useReloadRenegotiationQuarterlyBreakdownMutation } from "../../../../../slices/api/apiSlice";
const DismissLead = ({ handleCloseColdModal, userFullName, leadStatusFilter, teamId, leadData, userId, dashPerspective, leadUserNotifications }) => {
  const theme = useTheme();

  const [dismissLead, {isFetching, isSuccess, isError}] = useDismissLeadMutation()
  const [realoadRenegotiationQuarterBreakdown] = useReloadRenegotiationQuarterlyBreakdownMutation()
  const [realoadRefinanceQuarterBreakdown] = useReloadRefinanceQuarterlyBreakdownMutation()

  const handleDismissLead = async () => {
    dismissLead({
      leadId: leadData._id,
      userId: userId,
      dashPerspective: dashPerspective,
      leadFilter: leadStatusFilter,
      teamId: teamId,
      mortgageId: leadData.belongsToMortgage,
      userFullName: userFullName,
      userNotifications: leadUserNotifications,
    })
    if (leadData.targetOutcome === 'renegotiation') {
      await realoadRenegotiationQuarterBreakdown({
        userId: userId,
        teamId: teamId,
        leadTier: leadData.tier,
        actionTaken: 'dismissed',
        closureType: leadData.targetOutcome,
      })
    } else {
      await realoadRefinanceQuarterBreakdown({
        userId: userId,
        teamId: teamId,
        leadTier: leadData.tier,
        actionTaken: 'dismissed',
        closureType: leadData.targetOutcome,
      })
    }
  }

  return (
    <>
      {isSuccess ?
        <ComponentHeader className="modal green"><CheckIcon style={{height: '2.5rem', width: '2.5rem', margin: '0 0.5rem 0.2rem 0'}} />Success</ComponentHeader>
      : isError ?
        <ComponentHeader className="modal red"><ErrorIcon style={{height: '2.5rem', width: '2.5rem', margin: '0 0.5rem 0.2rem 0'}} />failed... try again or contact support@lancastersweep.com</ComponentHeader>
      :
        <ComponentHeader className="modal">Dismiss Lead</ComponentHeader>
      }

      <ModalContentContainer className="centerColumn leadActions">
        {isSuccess ?
          <SubHeader className="modal" style={{margin: '0 auto 3rem auto'}}>
            <span>This mortgage has been removed as an active lead.</span>
          </SubHeader>
        :
          <SubHeader className="modal" style={{margin: '0 auto 3rem auto', padding: '0 1rem', width: '35rem'}}>
            <span>Removing the "Active Lead" status from this mortgage will place it back in the pool to be queried against the public records.</span>
          </SubHeader>
        }

        {isSuccess ?
          <ButtonWrapper>
            <Button className="gray" onClick={() => handleCloseColdModal()}>Continue</Button>
          </ButtonWrapper>
        :
          <ButtonWrapper>
            {isFetching ?
              <Button className="purpleLoading">
                <CircularProgress
                  sx={{ color: `${theme.palette.common.white}` }}
                  size={18}
                />
              </Button>
            :
              <Button className="purple" onClick={() => handleDismissLead()}>Continue</Button>
            }
            <Button className="gray" onClick={() => handleCloseColdModal()}>Cancel</Button>
          </ButtonWrapper>
        }
      </ModalContentContainer>
    </>
  )
}

export default DismissLead;
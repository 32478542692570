import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { ContextWrapper } from './AppState';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './rootReducer.js'
import { apiSlice } from './slices/api/apiSlice'

ReactDOM.render(
  <ContextWrapper>
    <Provider store={configureStore({
      reducer: rootReducer,
      middleware: getDefaultMiddleware =>
        getDefaultMiddleware().concat(apiSlice.middleware)
    })}>
      <App />
    </Provider>
  </ContextWrapper>,
  document.getElementById('root')
)
import React from "react";
import CancelIcon from '@mui/icons-material/Cancel';
import { useDeleteUploadReportMutation } from "../../../../../../../slices/api/apiSlice";
import { ModalContainer, ComponentBorder, Button, SubHeader, FlexWrapper, ActionIconWrapper } from "../../../../../../../StyledComponents";

const DeleteModal = ({ closeModal, viewingReport, userId, teamId }) => {

  const [deleteReport, { isFetching, isSuccess }] = useDeleteUploadReportMutation()

  const handleDelete = async () => {
    await deleteReport({
      reportId: viewingReport._id,
      userId: userId,
      teamId: teamId,
    })
  }

  return (
    <ModalContainer className="onePanel">
      <ComponentBorder className="top" style={{gridRow: '1', gridColumn: '1/5'}}>
        <ActionIconWrapper className="medium grayText circle hoverBorder hover" style={{margin: 'auto 2rem auto 0'}}>
          <CancelIcon onClick={() => closeModal()} style={{height: '100%', width: '100%'}} />  
        </ActionIconWrapper>
      </ComponentBorder>

      <FlexWrapper className="column alignCenter justifyBetween" style={{gridRow: '2', gridColumn: '2'}}>
        {isSuccess ?
          <SubHeader className="modal">
            Report removed.
          </SubHeader>
        :
          <SubHeader className="modal">
            Delete Report?
          </SubHeader>
        }
        {isFetching ?
          <Button disabled className="deleteLoading">
            Delete
          </Button>
        : isSuccess ?
          <Button onClick={() => closeModal()} className="secondary">
            Close
          </Button>
        :
          <Button onClick={() => handleDelete()} className="delete">
            Delete
          </Button>
        }
      </FlexWrapper>

      <ComponentBorder className="left" style={{gridrow: '2', gridColumn: '1'}} />
      <ComponentBorder className="right" style={{gridRow: '2', gridColumn: '3'}} />
      <ComponentBorder className="bottom" style={{gridRow: '3', gridColumn: '1/4'}} />
    </ModalContainer>
  )
}

export default DeleteModal;
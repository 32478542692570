import React from "react";
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from "@mui/styles";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { selectLoadData } from "../../../../../slices/loadDataSlice";
import { selectTeamRefinances } from "../../../../../slices/teamStatsSlice";
import { HeaderContainer, ColumnContent, HeaderText, HorizontalContainer, ComponentContainer, VerticalContainer, MainContent, SubContent, FlexWrapper, ComponentBorder } from "../../../../../StyledComponents";

const TeamStats = () => {
  const theme = useTheme();

  const teamRefinances = useSelector(selectTeamRefinances)
  const dataLoaded = useSelector(selectLoadData)

  return (
    <ComponentContainer className="justifyStart thinBorders" style={{gridRow: '1', gridColumn: '3', height: '100%'}}>
      <FlexWrapper className="column">
        <HeaderContainer>
          <HeaderText>Team Stats</HeaderText>
        </HeaderContainer>
        <HorizontalContainer>
          {teamRefinances !== null && dataLoaded ?
            <>
              <VerticalContainer className="noGap">
                <ColumnContent className="bottomBorder">
                  Closed Refinances
                </ColumnContent>
                <ColumnContent>
                  <MainContent>
                    {teamRefinances.closedRefinances}
                  </MainContent>
                </ColumnContent>
              </VerticalContainer>
              <VerticalContainer className="noGap">
                <ColumnContent className="bottomBorder">
                  Gross Profit
                  <Tooltip
                    componentsProps={{tooltip: {sx: {
                      color: `${theme.palette.common.white}`,
                      backgroundColor: `${theme.palette.common.black}`,
                      fontSize: "1.8rem",
                      margin: '0 0 0 0',
                    }}}}
                    PopperProps={{modifiers: [{
                      name: "offset",
                      options: {
                        offset: [0, -4],
                      },
                    }]}}
                    title={<div style={{textAlign: "center"}}><p style={{fontSize: "1rem", fontWeight: "600", padding: "0 0.5rem"}}>Value in parentheses is the average profit generated for each refinance.</p></div>} 
                    placement="top"
                    arrow
                  >
                    <span style={{color: `${theme.palette.common.grayText}`, margin: "0 0 0 0.3rem", height: "1.5rem", width: "1.5rem"}}>
                      <HelpOutlineIcon />
                    </span>
                  </Tooltip>
                </ColumnContent>
                <ColumnContent>
                  <MainContent>
                    $ {teamRefinances.grossProfitNumber.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}
                  </MainContent>
                  <SubContent>
                    ($ {teamRefinances.averageProfitNumber.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})})
                  </SubContent>
                </ColumnContent>
              </VerticalContainer>
              <VerticalContainer className="noGap">
                <ColumnContent className="bottomBorder">
                  Gross Revenue Increase
                  <Tooltip
                    componentsProps={{tooltip: {sx: {
                      color: `${theme.palette.common.white}`,
                      backgroundColor: `${theme.palette.common.black}`,
                      fontSize: "1.8rem",
                      margin: '0 0 0 0',
                    }}}}
                    PopperProps={{modifiers: [{
                      name: "offset",
                      options: {
                        offset: [0, -4],
                      },
                    }]}}
                    title={<div style={{textAlign: "center"}}><p style={{fontSize: "1rem", fontWeight: "600", padding: "0 0.5rem"}}>Value in parentheses is the average revenue increase generated for each refinance.</p></div>} 
                    placement="top"
                    arrow
                  >
                    <span style={{color: `${theme.palette.common.grayText}`, margin: "0 0 0 0.3rem", height: "1.5rem", width: "1.5rem"}}>
                      <HelpOutlineIcon />
                    </span>
                  </Tooltip>
                </ColumnContent>
                <ColumnContent>
                  <MainContent>
                    {teamRefinances.grossProfitPercent.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})} %
                  </MainContent>
                  <SubContent>
                    ({teamRefinances.averageProfitPercent.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})} %)
                  </SubContent>
                </ColumnContent>
              </VerticalContainer>
              <VerticalContainer className="noGap">
                <ColumnContent className="bottomBorder">
                  Last Refinance
                </ColumnContent>
                <ColumnContent>
                  {teamRefinances.lastRefinance ? 
                    <MainContent>
                      {teamRefinances.lastRefinance}
                    </MainContent>
                  :
                    <MainContent>
                      N/A
                    </MainContent>
                  }
                </ColumnContent>
              </VerticalContainer>
              <VerticalContainer className="noGap">
                <ColumnContent className="bottomBorder">
                  Refinance Frequency
                  <Tooltip
                    componentsProps={{tooltip: {sx: {
                      color: `${theme.palette.common.white}`,
                      backgroundColor: `${theme.palette.common.black}`,
                      fontSize: "1.8rem",
                      margin: '0 0 0 0',
                    }}}}
                    PopperProps={{modifiers: [{
                      name: "offset",
                      options: {
                        offset: [0, -4],
                      },
                    }]}}
                    title={<div style={{textAlign: "center"}}><p style={{fontSize: "1rem", fontWeight: "600", padding: "0 0.5rem"}}>Average day's between the team closing a refinance.</p></div>} 
                    placement="top"
                    arrow
                  >
                    <span style={{color: `${theme.palette.common.grayText}`, margin: "0 0 0 0.3rem", height: "1.5rem", width: "1.5rem"}}>
                      <HelpOutlineIcon />
                    </span>
                  </Tooltip>
                </ColumnContent>
                <ColumnContent>
                  <MainContent>
                    {teamRefinances.refinanceFrequency} <span style={{fontWeight: "500"}}>days</span>
                  </MainContent>
                </ColumnContent>
              </VerticalContainer>
            </>
          :
            <ColumnContent className="single">
              <CircularProgress
                sx={{ color: `${theme.palette.primary.main}`}}
                size={18}
              />
            </ColumnContent>    
          }
        </HorizontalContainer>
      </FlexWrapper>
      <ComponentBorder className="top" style={{gridRow:'1', gridColumn: '1/4'}} />
      <ComponentBorder className="left" style={{gridRow:'2', gridColumn: '1'}} />
      <ComponentBorder className="right"  style={{gridRow:'2', gridColumn: '3'}} />
      <ComponentBorder className="bottom" style={{gridRow:'3', gridColumn: '1/4'}} />
    </ComponentContainer>
  )
}

export default TeamStats;
export default async function handleLeadTierFilterChanges (eventValue, leadsAwaitingAction, teamInvestigatingLeads, teamClosingLeads, leadTypeFilter, leadNewFilter) {
  let awaitingAction = await handleAwaitingAction(eventValue, leadsAwaitingAction, leadTypeFilter, leadNewFilter);
  let investigating = await handleInvestigating(eventValue, teamInvestigatingLeads, leadTypeFilter, leadNewFilter);
  let closing = await handleClosing(eventValue, teamClosingLeads, leadTypeFilter, leadNewFilter);
  return {awaitingAction, investigating, closing}
}
  
let handleAwaitingAction = async (eventValue, leadsAwaitingAction, leadTypeFilter, leadNewFilter) => {
  let filteredLeadsAwaitingAction = leadsAwaitingAction
  if (leadNewFilter === 'false' || !leadNewFilter) {
    if (eventValue === 'Manual') {
      if (leadTypeFilter === 'All' || !leadTypeFilter) {
        filteredLeadsAwaitingAction = [...leadsAwaitingAction].filter((lead) => lead.tier === 0)
      } else if (leadTypeFilter === 'Renegotiation') {
        filteredLeadsAwaitingAction = [...leadsAwaitingAction].filter((lead) => lead.tier === 0 && lead.targetOutcome === 'renegotiation')
      } else {
        filteredLeadsAwaitingAction = [...leadsAwaitingAction].filter((lead) => lead.tier === 0 && lead.targetOutcome === 'refinance')
      } 
    } else if (eventValue === 'Tier 1') {
      if (leadTypeFilter === 'All' || !leadTypeFilter) {
        filteredLeadsAwaitingAction = [...leadsAwaitingAction].filter((lead) => lead.tier === 1)
      } else if (leadTypeFilter === 'Renegotiation') {
        filteredLeadsAwaitingAction = [...leadsAwaitingAction].filter((lead) => lead.tier === 1 && lead.targetOutcome === 'renegotiation')
      } else {
        filteredLeadsAwaitingAction = [...leadsAwaitingAction].filter((lead) => lead.tier === 1 && lead.targetOutcome === 'refinance')
      }
    } else if (eventValue === 'Tier 2') {
      if (leadTypeFilter === 'All' || !leadTypeFilter) {
        filteredLeadsAwaitingAction = [...leadsAwaitingAction].filter((lead) => lead.tier === 2)
      } else if (leadTypeFilter === 'Renegotiation') {
        filteredLeadsAwaitingAction = [...leadsAwaitingAction].filter((lead) => lead.tier === 2 && lead.targetOutcome === 'renegotiation')
      } else {
        filteredLeadsAwaitingAction = [...leadsAwaitingAction].filter((lead) => lead.tier === 2 && lead.targetOutcome === 'refinance')
      }
    } else if (eventValue === 'Tier 3') {
      if (leadTypeFilter === 'All' || !leadTypeFilter) {
        filteredLeadsAwaitingAction = [...leadsAwaitingAction].filter((lead) => lead.tier === 3)
      } else if (leadTypeFilter === 'Renegotiation') {
        filteredLeadsAwaitingAction = [...leadsAwaitingAction].filter((lead) => lead.tier === 3 && lead.targetOutcome === 'renegotiation')
      } else {
        filteredLeadsAwaitingAction = [...leadsAwaitingAction].filter((lead) => lead.tier === 3 && lead.targetOutcome === 'refinance')
      }
    } else {
      if (leadTypeFilter === 'All' || !leadTypeFilter) {
  
      } else if (leadTypeFilter === 'Renegotiation') {
        filteredLeadsAwaitingAction = [...leadsAwaitingAction].filter((lead) => lead.targetOutcome === 'renegotiation')
      } else {
        filteredLeadsAwaitingAction = [...leadsAwaitingAction].filter((lead) => lead.targetOutcome === 'refinance')
      }
    }
  } else {
    if (eventValue === 'Manual') {
      if (leadTypeFilter === 'All' || !leadTypeFilter) {
        filteredLeadsAwaitingAction = [...leadsAwaitingAction].filter((lead) => lead.tier === 0 && lead.newLeadLabel === true)
      } else if (leadTypeFilter === 'Renegotiation') {
        filteredLeadsAwaitingAction = [...leadsAwaitingAction].filter((lead) => lead.tier === 0 && lead.newLeadLabel === true && lead.targetOutcome === 'renegotiation')
      } else {
        filteredLeadsAwaitingAction = [...leadsAwaitingAction].filter((lead) => lead.tier === 0 && lead.newLeadLabel === true && lead.targetOutcome === 'refinance')
      } 
    } else if (eventValue === 'Tier 1') {
      if (leadTypeFilter === 'All' || !leadTypeFilter) {
        filteredLeadsAwaitingAction = [...leadsAwaitingAction].filter((lead) => lead.tier === 1 && lead.newLeadLabel === true)
      } else if (leadTypeFilter === 'Renegotiation') {
        filteredLeadsAwaitingAction = [...leadsAwaitingAction].filter((lead) => lead.tier === 1 && lead.newLeadLabel === true && lead.targetOutcome === 'renegotiation')
      } else {
        filteredLeadsAwaitingAction = [...leadsAwaitingAction].filter((lead) => lead.tier === 1 && lead.newLeadLabel === true && lead.targetOutcome === 'refinance')
      }
    } else if (eventValue === 'Tier 2') {
      if (leadTypeFilter === 'All' || !leadTypeFilter) {
        filteredLeadsAwaitingAction = [...leadsAwaitingAction].filter((lead) => lead.tier === 2 && lead.newLeadLabel === true)
      } else if (leadTypeFilter === 'Renegotiation') {
        filteredLeadsAwaitingAction = [...leadsAwaitingAction].filter((lead) => lead.tier === 2 && lead.newLeadLabel === true && lead.targetOutcome === 'renegotiation')
      } else {
        filteredLeadsAwaitingAction = [...leadsAwaitingAction].filter((lead) => lead.tier === 2 && lead.newLeadLabel === true && lead.targetOutcome === 'refinance')
      }
    } else if (eventValue === 'Tier 3') {
      if (leadTypeFilter === 'All' || !leadTypeFilter) {
        filteredLeadsAwaitingAction = [...leadsAwaitingAction].filter((lead) => lead.tier === 3 && lead.newLeadLabel === true)
      } else if (leadTypeFilter === 'Renegotiation') {
        filteredLeadsAwaitingAction = [...leadsAwaitingAction].filter((lead) => lead.tier === 3 && lead.newLeadLabel === true && lead.targetOutcome === 'renegotiation')
      } else {
        filteredLeadsAwaitingAction = [...leadsAwaitingAction].filter((lead) => lead.tier === 3 && lead.newLeadLabel === true && lead.targetOutcome === 'refinance')
      }
    } else {
      if (leadTypeFilter === 'All' || !leadTypeFilter) {
        filteredLeadsAwaitingAction = [...leadsAwaitingAction].filter((lead) => lead.newLeadLabel === true)
      } else if (leadTypeFilter === 'Renegotiation') {
        filteredLeadsAwaitingAction = [...leadsAwaitingAction].filter((lead) => lead.targetOutcome === 'renegotiation' && lead.newLeadLabel === true)
      } else {
        filteredLeadsAwaitingAction = [...leadsAwaitingAction].filter((lead) => lead.targetOutcome === 'refinance' && lead.newLeadLabel === true)
      }
    }
  }
  return filteredLeadsAwaitingAction
}
let handleInvestigating = async (eventValue, teamInvestigatingLeads, leadTypeFilter, leadNewFilter) => {
  let filteredInvestigatingLeads = teamInvestigatingLeads
  if (leadNewFilter === 'false') {
    if (eventValue === 'Manual') {
      if (leadTypeFilter === 'All' || !leadTypeFilter) {
        filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.tier === 0)
      } else if (leadTypeFilter === 'Renegotiation') {
        filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.tier === 0 && lead.targetOutcome === 'renegotiation')
      } else {
        filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.tier === 0 && lead.targetOutcome === 'refinance')
      } 
    } else if (eventValue === 'Tier 1') {
      if (leadTypeFilter === 'All' || !leadTypeFilter) {
        filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.tier === 1)
      } else if (leadTypeFilter === 'Renegotiation') {
        filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.tier === 1 && lead.targetOutcome === 'renegotiation')
      } else {
        filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.tier === 1 && lead.targetOutcome === 'refinance')
      }
    } else if (eventValue === 'Tier 2') {
      if (leadTypeFilter === 'All' || !leadTypeFilter) {
        filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.tier === 2)
      } else if (leadTypeFilter === 'Renegotiation') {
        filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.tier === 2 && lead.targetOutcome === 'renegotiation')
      } else {
        filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.tier === 2 && lead.targetOutcome === 'refinance')
      }
    } else if (eventValue === 'Tier 3') {
      if (leadTypeFilter === 'All' || !leadTypeFilter) {
        filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.tier === 3)
      } else if (leadTypeFilter === 'Renegotiation') {
        filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.tier === 3 && lead.targetOutcome === 'renegotiation')
      } else {
        filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.tier === 3 && lead.targetOutcome === 'refinance')
      }
    } else {
      if (leadTypeFilter === 'All' || !leadTypeFilter) {
  
      } else if (leadTypeFilter === 'Renegotiation') {
        filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.targetOutcome === 'renegotiation')
      } else {
        filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.targetOutcome === 'refinance')
      }
    }
  } else {
    if (eventValue === 'Manual') {
      if (leadTypeFilter === 'All' || !leadTypeFilter) {
        filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.tier === 0 && lead.newAssignmentNotification === true)
      } else if (leadTypeFilter === 'Renegotiation') {
        filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.tier === 0 && lead.newAssignmentNotification === true && lead.targetOutcome === 'renegotiation')
      } else {
        filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.tier === 0 && lead.newAssignmentNotification === true && lead.targetOutcome === 'refinance')
      } 
    } else if (eventValue === 'Tier 1') {
      if (leadTypeFilter === 'All' || !leadTypeFilter) {
        filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.tier === 1 && lead.newAssignmentNotification === true)
      } else if (leadTypeFilter === 'Renegotiation') {
        filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.tier === 1 && lead.newAssignmentNotification === true && lead.targetOutcome === 'renegotiation')
      } else {
        filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.tier === 1 && lead.newAssignmentNotification === true && lead.targetOutcome === 'refinance')
      }
    } else if (eventValue === 'Tier 2') {
      if (leadTypeFilter === 'All' || !leadTypeFilter) {
        filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.tier === 2 && lead.newAssignmentNotification === true)
      } else if (leadTypeFilter === 'Renegotiation') {
        filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.tier === 2 && lead.newAssignmentNotification === true && lead.targetOutcome === 'renegotiation')
      } else {
        filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.tier === 2 && lead.newAssignmentNotification === true && lead.targetOutcome === 'refinance')
      }
    } else if (eventValue === 'Tier 3') {
      if (leadTypeFilter === 'All' || !leadTypeFilter) {
        filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.tier === 3 && lead.newAssignmentNotification === true)
      } else if (leadTypeFilter === 'Renegotiation') {
        filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.tier === 3 && lead.newAssignmentNotification === true && lead.targetOutcome === 'renegotiation')
      } else {
        filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.tier === 3 && lead.newAssignmentNotification === true && lead.targetOutcome === 'refinance')
      }
    } else {
      if (leadTypeFilter === 'All' || !leadTypeFilter) {
        filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.newAssignmentNotification === true)
      } else if (leadTypeFilter === 'Renegotiation') {
        filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.newAssignmentNotification === true && lead.targetOutcome === 'renegotiation')
      } else {
        filteredInvestigatingLeads = [...teamInvestigatingLeads].filter((lead) => lead.newAssignmentNotification === true && lead.targetOutcome === 'refinance')
      }
    }
  }
  return filteredInvestigatingLeads
}
let handleClosing = async (eventValue, teamClosingLeads, leadTypeFilter, leadNewFilter) => {
  let filteredClosingLeads = teamClosingLeads
  if (leadNewFilter === 'false') {
    if (eventValue === 'Manual') {
      if (leadTypeFilter === 'All' || !leadTypeFilter) {
        filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.tier === 0)
      } else if (leadTypeFilter === 'Renegotiation') {
        filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.tier === 0 && lead.targetOutcome === 'renegotiation')
      } else {
        filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.tier === 0 && lead.targetOutcome === 'refinance')
      } 
    } else if (eventValue === 'Tier 1') {
      if (leadTypeFilter === 'All' || !leadTypeFilter) {
        filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.tier === 1)
      } else if (leadTypeFilter === 'Renegotiation') {
        filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.tier === 1 && lead.targetOutcome === 'renegotiation')
      } else {
        filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.tier === 1 && lead.targetOutcome === 'refinance')
      }
    } else if (eventValue === 'Tier 2') {
      if (leadTypeFilter === 'All' || !leadTypeFilter) {
        filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.tier === 2)
      } else if (leadTypeFilter === 'Renegotiation') {
        filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.tier === 2 && lead.targetOutcome === 'renegotiation')
      } else {
        filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.tier === 2 && lead.targetOutcome === 'refinance')
      }
    } else if (eventValue === 'Tier 3') {
      if (leadTypeFilter === 'All' || !leadTypeFilter) {
        filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.tier === 3)
      } else if (leadTypeFilter === 'Renegotiation') {
        filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.tier === 3 && lead.targetOutcome === 'renegotiation')
      } else {
        filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.tier === 3 && lead.targetOutcome === 'refinance')
      }
    } else {
      if (leadTypeFilter === 'All' || !leadTypeFilter) {
  
      } else if (leadTypeFilter === 'Renegotiation') {
        filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.targetOutcome === 'renegotiation')
      } else {
        filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.targetOutcome === 'refinance')
      }
    }
  } else {
    if (eventValue === 'Manual') {
      if (leadTypeFilter === 'All' || !leadTypeFilter) {
        filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.tier === 0 && lead.newAssignmentNotification === true)
      } else if (leadTypeFilter === 'Renegotiation') {
        filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.tier === 0 && lead.newAssignmentNotification === true && lead.targetOutcome === 'renegotiation')
      } else {
        filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.tier === 0 && lead.newAssignmentNotification === true && lead.targetOutcome === 'refinance')
      } 
    } else if (eventValue === 'Tier 1') {
      if (leadTypeFilter === 'All' || !leadTypeFilter) {
        filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.tier === 1 && lead.newAssignmentNotification === true)
      } else if (leadTypeFilter === 'Renegotiation') {
        filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.tier === 1 && lead.newAssignmentNotification === true && lead.targetOutcome === 'renegotiation')
      } else {
        filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.tier === 1 && lead.newAssignmentNotification === true && lead.targetOutcome === 'refinance')
      }
    } else if (eventValue === 'Tier 2') {
      if (leadTypeFilter === 'All' || !leadTypeFilter) {
        filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.tier === 2 && lead.newAssignmentNotification === true)
      } else if (leadTypeFilter === 'Renegotiation') {
        filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.tier === 2 && lead.newAssignmentNotification === true && lead.targetOutcome === 'renegotiation')
      } else {
        filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.tier === 2 && lead.newAssignmentNotification === true && lead.targetOutcome === 'refinance')
      }
    } else if (eventValue === 'Tier 3') {
      if (leadTypeFilter === 'All' || !leadTypeFilter) {
        filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.tier === 3 && lead.newAssignmentNotification === true)
      } else if (leadTypeFilter === 'Renegotiation') {
        filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.tier === 3 && lead.newAssignmentNotification === true && lead.targetOutcome === 'renegotiation')
      } else {
        filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.tier === 3 && lead.newAssignmentNotification === true && lead.targetOutcome === 'refinance')
      }
    } else {
      if (leadTypeFilter === 'All' || !leadTypeFilter) {
        filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.newAssignmentNotification === true)
      } else if (leadTypeFilter === 'Renegotiation') {
        filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.newAssignmentNotification === true && lead.targetOutcome === 'renegotiation')
      } else {
        filteredClosingLeads = [...teamClosingLeads].filter((lead) => lead.newAssignmentNotification === true && lead.targetOutcome === 'refinance')
      }
    }
  }
  return filteredClosingLeads
}
import { createSlice } from '@reduxjs/toolkit'
import { apiSlice } from './api/apiSlice'

const initialState = {
  data: {
    auth: {
      launchTeam: false,
      newcomerForm: false,
      login: false,
    },
    duplicatesCheck: true,
    updateDefaultTargets: false,
  },
  status: 'idle',
  error: null
}

export const functionAvailabilitySlice = createSlice({
  name: 'functionAvailability',
  initialState,
  reducers: {
    setNewcomerFormEnabled(state, action) { state.data.auth.newcomerForm = action.payload },
    setDuplicatesCheckEnabled(state, action) { state.data.duplicatesCheck = action.payload },
    setUpdateDefaultTargetsEnabled(state, action) { state.data.updateDefaultTargets = action.payload },
    setLaunchTeamEnabled(state, action) { state.data.auth.launchTeam = action.payload },
    setLoginEnabled(state, action) { state.data.auth.login = action.payload },
  },
  extraReducers: (builder) => {builder
    .addMatcher(apiSlice.endpoints.createGuestAccount.matchFulfilled, (state, {payload}) => {
      state.data.duplicatesCheck = false
      state.data.updateDefaultTargets = false
    })
    .addMatcher(apiSlice.endpoints.establishRequestCredentials.matchFulfilled, (state, {payload}) => {
      if (payload.data.existingTeam) {
        state.data.auth.launchTeam = false
      }
    })
  }
})

export const { setDuplicatesCheckEnabled, setUpdateDefaultTargetsEnabled, setLaunchTeamEnabled, setLoginEnabled, setNewcomerFormEnabled } = functionAvailabilitySlice.actions

export default functionAvailabilitySlice.reducer

export const selectLaunchTeamEnabled = state => state.functionAvailability.data.auth.launchTeam
export const selectLoginEnabled = state => state.functionAvailability.data.auth.login
export const selectDuplicatesCheckEnabled = state => state.functionAvailability.data.duplicatesCheck
export const selectUpdateDefaultTargetsEnabled = state => state.functionAvailability.data.updateDefaultTargets
export const selectNewcomerFormEnabled = state => state.functionAvailability.data.auth.newcomerForm
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: 'dashboard',
  status: 'idle',
  error: null
}

export const pageSelectionSlice = createSlice({
  name: 'pageSelection',
  initialState,
  reducers: {
    setPageSelection(state, action) { state.data = action.payload },
  },
})

export const { setPageSelection } = pageSelectionSlice.actions

export default pageSelectionSlice.reducer

export const selectPageSelection = state => state.pageSelection.data

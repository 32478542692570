import React, { useState } from "react";
import Tooltip from '@mui/material/Tooltip';
import CircleIcon from '@mui/icons-material/Circle';
import { Dialog } from "@mui/material";
import { useTheme } from "@mui/styles";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { GridToolbar } from '@mui/x-data-grid';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CircularProgress from "@mui/material/CircularProgress";
import { StripedDataGrid } from "../../../../componentThemes";
import MortgageActionsModal from "../../../../components/Modals/MortgageActions/MortgageActionsModal";
import { ActionIconWrapper, ComponentBorder, ComponentContainer, FlexWrapper } from "../../../../StyledComponents";
import { selectUserFullName } from "../../../../slices/userInfoSlice";
import { setViewingMortgage } from "../../../../slices/viewingMortgageSlice";
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { selectTeamMortgages } from "../../../../slices/teamMortgagesSlice";
import { selectAllTeamReports } from "../../../../slices/teamReportsSlice";
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { selectDashPerspective } from "../../../../slices/dashPerspectiveSlice";
import { selectMembersDataArray } from "../../../../slices/membersDataArraySlice";
import { useSelector, useDispatch } from "react-redux";
import { setMortgageActionNavMenuSelection } from "../../../../slices/navMenuSelectionsSlice";
import { selectTeamAwaitingActionLeads, selectTeamClosingLeads, selectTeamInvestigatingLeads } from "../../../../slices/teamActiveLeadsSlice";

const MortgageRepository = ({ userId, teamId }) => {
  const theme = useTheme();
  const dispatch = useDispatch()

  const memberData = useSelector(selectMembersDataArray)
  const teamReports = useSelector(selectAllTeamReports)
  const userFullName = useSelector(selectUserFullName)
  const teamMortgages = useSelector(selectTeamMortgages)
  const dashPerspective = useSelector(selectDashPerspective)
  const awaitingActionLeads = useSelector(selectTeamAwaitingActionLeads)
  const investigatingLeads = useSelector(selectTeamInvestigatingLeads)
  const closingLeads = useSelector(selectTeamClosingLeads)

  const [leadData, setLeadData] = useState({});
  const [populatedReports, setPopulatedReports] = useState(false);
  const [openMortgageActionsModal, setOpenMortgageActionsModal] = useState(false);
  const handleOpenActionModal = (mortgage, destination) => {
    let populatedReports = []
    for (let i = 0; i < mortgage.reports.length; i++) {
      let match = teamReports.find(report => report._id === mortgage.reports[i])
      populatedReports.push(match)
    }
    if (mortgage.activeLead) {
      let thisLead = {}
      thisLead = awaitingActionLeads.find(lead => lead._id === mortgage.activeLead.toString())
      if (!thisLead) {
        thisLead = investigatingLeads.find(lead => lead._id === mortgage.activeLead)
        if (!thisLead) {
          thisLead = closingLeads.find(lead => lead._id === mortgage.activeLead)
        }
      }
      setLeadData(thisLead)
    }
    if (destination === 'investigateLead') {
      dispatch( setMortgageActionNavMenuSelection('investigateLead') )
    } else {
      dispatch( setMortgageActionNavMenuSelection('compareRecords') )
    }
    setPopulatedReports(populatedReports)
    dispatch( setViewingMortgage(mortgage) )
    setOpenMortgageActionsModal(true)
  }
  const handleCloseMortgageActionsModal = () => {
    setOpenMortgageActionsModal(false);
  }

  const renderActionButtons = (cellValues) => {
    return (
      <div style={{display: 'flex', height: '100%'}}>
        <ActionIconWrapper onClick={() => handleOpenActionModal(cellValues.row)} className='hover' >
          <Tooltip
            componentsProps={{tooltip: {sx: {
              color: `${theme.palette.common.white}`,
              backgroundColor: `${theme.palette.common.black}`,
              fontSize: "2rem",
              margin: '3rem 0 0 0'
            }}}}
            PopperProps={{modifiers: [{
              name: "offset",
              options: {
                offset: [0, 0],
              },
            }]}}
            title={<div style={{textAlign: "center"}}><p style={{fontSize: "1rem", fontWeight: "600", padding: "0"}}>More details</p></div>} 
            placement="top"
            arrow
          >
            <OpenInNewIcon style={{height: '1.6rem', width: '1.6rem'}} />
          </Tooltip>
        </ActionIconWrapper>
      </div>
    )
  }
  const renderStatusButton = (cellValues) => {
    return (
      <div style={{display: 'flex', height: '100%'}}>
        {cellValues.row.status === 'inactive' ?
          <Tooltip
            componentsProps={{tooltip: {sx: {
              color: `${theme.palette.common.white}`,
              backgroundColor: `${theme.palette.common.black}`,
              fontSize: "2rem",
              margin: '3rem 0 0 0'
            }}}}
            PopperProps={{modifiers: [{
              name: "offset",
              options: {
                offset: [0, 0],
              },
            }]}}
            title={<div style={{textAlign: "center"}}><p style={{fontSize: "1rem", fontWeight: "600", padding: "0"}}>Open Investigation</p></div>} 
            placement="top"
            arrow
          >
            <ActionIconWrapper onClick={() => handleOpenActionModal(cellValues.row, 'investigateLead')} className="hover">
              <RadioButtonUncheckedIcon style={{color: `${theme.palette.common.grayText}`}} />
              <ArrowForwardIcon style={{height: '1.2rem', width: '1.2rem'}} />
            </ActionIconWrapper>
          </Tooltip>
        : cellValues.row.status === 'awaitingAction' ?
          <Tooltip
            componentsProps={{tooltip: {sx: {
              color: `${theme.palette.common.white}`,
              backgroundColor: `${theme.palette.common.black}`,
              fontSize: "2rem",
              margin: '3rem 0 0 0'
            }}}}
            PopperProps={{modifiers: [{
              name: "offset",
              options: {
                offset: [0, 0],
              },
            }]}}
            title={<div style={{textAlign: "center"}}><p style={{fontSize: "1rem", fontWeight: "600", padding: "0"}}>currently <span style={{fontWeight: '900', fontSize: '1.2rem'}}>Awaiting Action</span></p></div>} 
            placement="top"
            arrow
          >
            <ActionIconWrapper className="noHover">
              <RadioButtonUncheckedIcon />
            </ActionIconWrapper>
          </Tooltip>
        : cellValues.row.status === 'investigating' ?
          <Tooltip
            componentsProps={{tooltip: {sx: {
              color: `${theme.palette.common.white}`,
              backgroundColor: `${theme.palette.common.black}`,
              fontSize: "2rem",
              margin: '3rem 0 0 0'
            }}}}
            PopperProps={{modifiers: [{
              name: "offset",
              options: {
                offset: [0, 0],
              },
            }]}}
            title={<div style={{textAlign: "center"}}><p style={{fontSize: "1rem", fontWeight: "600", padding: "0"}}>Investigating</p></div>} 
            placement="top"
            arrow
          >
            <ActionIconWrapper className="noHover">
              <RadioButtonCheckedIcon />
            </ActionIconWrapper>
          </Tooltip>
        :
           <Tooltip
            componentsProps={{tooltip: {sx: {
              color: `${theme.palette.common.white}`,
              backgroundColor: `${theme.palette.common.black}`,
              fontSize: "2rem",
              margin: '3rem 0 0 0'
            }}}}
            PopperProps={{modifiers: [{
              name: "offset",
              options: {
                offset: [0, 0],
              },
            }]}}
            title={<div style={{textAlign: "center"}}><p style={{fontSize: "1rem", fontWeight: "600", padding: "0"}}>Closing</p></div>} 
            placement="top"
            arrow
          >
            <ActionIconWrapper className="noHover">
              <CircleIcon />
            </ActionIconWrapper>
          </Tooltip>
        }
      </div>
    )
  }

  const columns = [
    {
      field: 'id' , 
      headerName: '#', 
      filterable: false,
      renderCell:(index) => (index.api.getRowIndex(index.row._id) + 1),
      width: 50,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'details',
      headerName: 'Details',
      width: 50,
      renderCell: renderActionButtons,
      disableClickEventBubbling: true,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 50,
      renderCell: renderStatusButton,
      disableClickEventBubbling: true,
      headerAlign: 'center',
      align: 'center',
    },
    {field: "recordScans", headerName: "Scans", align: 'center', width: 50, headerAlign: 'center'},
    {field: "propertyType", headerName: "Property Type", width: 90},
    {field: "streetAddress", headerName: "Street Address", width: 175},
    {field: "city", headerName: "City", width: 110},
    {field: "state", headerName: "State", width: 15},
    {field: "originationDateLabel", headerName: "Origination Date", width: 85},
    {field: "endDateLabel", headerName: "End Date", width: 85},
    {field: "monthsRemaining", headerName: "Months Remaining", align: 'center', width: 100},
    {field: "owner1", headerName: "Primary Owner", width: 140},
    {field: "owner2", headerName: "Co-Owner", width: 140},
  ]

  return (
    <>
      <ComponentContainer className="fitWindow">
        <FlexWrapper className="column" style={{gridRow: '2', gridColumn: '2'}}>
          {teamMortgages !== undefined ?
            <StripedDataGrid
              sx={{
                '& .MuiSvgIcon-root': {
                  color: 'primary.main',
                },
                '& .MuiTablePagination-select': {
                  color: 'primary.main',
                },
                '& .MuiDataGrid-virtualScrollerContent': {
                  overflow: 'scroll'
                },
                '& .MuiDataGrid-row': {
                  background: `#fff`
                },
                '&.MuiDataGrid-root': {
                  background: `#fff`
                },
              }}
              style={{width: '100%', height: '100%', minHeight: '20rem', borderRadius: '0', background: `${theme.light.component.background}`}}
              rows={teamMortgages}
              rowHeight={40}
              columns={columns}
              getRowId={(row) =>  row._id}
              components={{
                Toolbar: GridToolbar,
              }}
              getRowClassName={(params) => params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'}
            />
          :
            <CircularProgress
            sx={{ color: `${theme.palette.primary.main}`}}
            size={48}
            />
          }
          </FlexWrapper>
          <ComponentBorder className="top" style={{gridRow:'1', gridColumn: '1/4'}} />
          <ComponentBorder className="left" style={{gridRow:'2', gridColumn: '1'}} />
          <ComponentBorder className="right"  style={{gridRow:'2', gridColumn: '3'}} />
          <ComponentBorder className="bottom" style={{gridRow:'3', gridColumn: '1/4'}} />
        </ComponentContainer>

        <Dialog open={openMortgageActionsModal} onClose={handleCloseMortgageActionsModal} 
          PaperProps={{ sx: {width: "95%", minWidth: "100rem", maxWidth: "150rem", height: "fit-content", minHeight: "66rem", maxHeight: "95%"}}}
        >
          <MortgageActionsModal handleCloseMortgageActionsModal={handleCloseMortgageActionsModal} userId={userId} teamId={teamId} userFullName={userFullName} memberData={memberData} dashPerspective={dashPerspective} reports={populatedReports} leadData={leadData} />
        </Dialog>
    </>
  )
}

export default MortgageRepository;
import { createSlice } from '@reduxjs/toolkit'
import { apiSlice } from './api/apiSlice'

const initialState = {
  data: {
    notifications: [],
    newNotifications: 0,
    newLeadNotifications: 0,
    newAwaitingAction: 0,
    newInvestigating: 0,
    newClosing: 0,
    newReports: 0,
    newReportRemoved: false,
  },
  status: 'idle',
  error: null
}

export const userNotificationsSlice = createSlice({
  name: 'userNotifications',
  initialState,
  reducers: {
    setNewReportRemoved(state, action) { 
      state.data.newReportRemoved = action.payload
    },
    removeNewLeadNotification(state, action) {
      state.data.newLeadNotifications--
      if (action.payload === 'awaitingAction') {
        state.data.newAwaitingAction--
      } else if (action.payload === 'investigating') {
        state.data.newInvestigating--
      } else {
        state.data.newClosing--
      }
    },
  },
  extraReducers: (builder) => {
    builder
    .addMatcher(apiSlice.endpoints.initialLoad.matchFulfilled, (state, {payload}) => {
      if (payload.data) {
        state.data = payload.data.notifications
      }
    })
    .addMatcher(apiSlice.endpoints.changeTargetOutcome.matchFulfilled, (state, {payload}) => {
      if (payload.data.updateNotificationBadge) {
        state.data.newLeadNotifications--
        if (payload.data.leadStatus === 'investigating') {
          state.data.newInvestigating--
        } else if (payload.data.leadStatus === 'closing') {
          state.data.newClosing--
        } else {
          state.data.newAwaitingAction--
        }
      }
    })
    .addMatcher(apiSlice.endpoints.removeReportNotifyUser.matchFulfilled, (state, {payload}) => {
      state.data.newReports--
    })
    .addMatcher(apiSlice.endpoints.updateNotificationStatus.matchFulfilled, (state, {payload}) => {
      let thisNotification = state.data.notifications.find(notification => notification._id === payload.data.notificationId);
      thisNotification.status = payload.data.updatedStatus;
      state.data.newNotifications = payload.data.newNotifications;
    })
    .addMatcher(apiSlice.endpoints.deleteNotification.matchFulfilled, (state, {payload}) => {
      let thisNotification = state.data.notifications.find(notification => notification._id === payload.data);
      let thisIndex = state.data.notifications.indexOf(thisNotification)
      state.data.notifications.splice(thisIndex, 1)
    })
    .addMatcher(apiSlice.endpoints.recordSweep.matchFulfilled, (state, {payload}) => {
      if (payload.data.newNotification) {
        state.data.notifications.unshift(payload.data.newNotification)
        state.data.newNotifications++
      }
    })
  }
})

export const { setNewReportRemoved, removeNewLeadNotification } = userNotificationsSlice.actions

export default userNotificationsSlice.reducer

export const selectNewReportRemoved = state => state.userNotifications.data.newReportRemoved
export const selectAllUserNotifications = state => state.userNotifications.data.notifications
export const selectUserNewNotifications = state => state.userNotifications.data.newNotifications
export const selectUserNewLeadNotifications = state => state.userNotifications.data.newLeadNotifications
export const selectUserNewAwaitingActionLeadNotifications = state => state.userNotifications.data.newAwaitingAction
export const selectUserNewInvestigatingLeadNotifications = state => state.userNotifications.data.newInvestigating
export const selectUserNewClosingLeadNotifications = state => state.userNotifications.data.newClosing
export const selectUserNewReports = state => state.userNotifications.data.newReports
export const selectNotificationById = (state, notificationId) =>
  state.userNotifications.data.notifications.find(post => post._id === notificationId)
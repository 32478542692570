import React, { useEffect, createContext, useState } from 'react'
import axios from 'axios'
import { API_URL } from './config'
import useLocalStorage from './utils/useLocalStrorage'

const initialState = {
  sessionRestored: false,
  user: null, //{userId,token}
}

export const AppState = createContext(initialState)

export const ContextWrapper = props => {
  const [session, saveSession] = useLocalStorage('SweepSession', initialState)
  const [state, setState] = useState(initialState)
  
  useEffect(() => {
    (async () => {
      //restoring session here
      if (session) { // saved session is available
        setState(prevState => ({ ...prevState, user: session.user, sessionRestored: true }))
      } else { // no saved session
        setState(prevState => ({ ...prevState, sessionRestored: true }))
      }
    })()
  }, [session])

  const asyncActions = {
    saveUserState: async ({ userId, accessToken }) => {
      try {
        const user = {userId: userId, token: accessToken}
        saveSession({...session, user: user})
        setState({...state, user: user})
        return
      } catch (error) {
        return error.response.data
      }
    },
    signOut: async (reqCredentials) => {
      const headers = { 'Content-Type': 'application/json' }
      try {
        let oldSesssion = {
          fullName: session.user.fullName,
          reqIP: reqCredentials.protocol,
          reqLocation: reqCredentials.location,
          reqISP: reqCredentials.isp,
          reqProxy: reqCredentials.proxy,
        }
        await axios.post(API_URL + '/auth/logout', { oldSesssion }, { headers })
        saveSession(initialState)
        setState(initialState)
        window.location.href = "/";
      } catch (error) {
        return error.response.data;
      }
    },
  }

  return (
    <AppState.Provider value={{ ...state, ...asyncActions }}>
      {props.children}
    </AppState.Provider>
  )
}

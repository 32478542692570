import React, { useState } from "react";
import Badge from '@mui/material/Badge';
import styled from "@emotion/styled";
import ListIcon from '@mui/icons-material/List';
import ArticleIcon from '@mui/icons-material/Article';
import { useTheme } from "@mui/styles";
import SettingsIcon from '@mui/icons-material/Settings';
import InsightsIcon from '@mui/icons-material/Insights';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { selectLoadData } from "../../../../../slices/loadDataSlice";
import { selectFirstTeamReport } from "../../../../../slices/teamReportsSlice";
import { useSelector, useDispatch } from "react-redux";
import { useRemoveReportNotifyUserMutation } from "../../../../../slices/api/apiSlice";
import { selectNavSelection, setNavSelection } from "../../../../../slices/navSelectionSlice";
import { NavigationContainer, NavText, NavIndex, SideBarNavHeader } from "../../../../../StyledComponents";
import { selectNewReportRemoved, selectUserNewLeadNotifications, selectUserNewNotifications, selectUserNewReports, setNewReportRemoved } from "../../../../../slices/userNotificationsSlice";

const Navigation = ({ userId }) => {
  const theme = useTheme()
  const dispatch = useDispatch()

  const navSelection = useSelector(selectNavSelection)
  const newNotifications = useSelector(selectUserNewNotifications)
  const newReports = useSelector(selectUserNewReports)
  const dataLoaded = useSelector(selectLoadData)
  const leadNotifications = useSelector(selectUserNewLeadNotifications)
  const firstReport = useSelector(selectFirstTeamReport)
  const newReportRemoved = useSelector(selectNewReportRemoved)

  const [removeUserNotification] = useRemoveReportNotifyUserMutation() 
  
  const [lastOverview, setLastOverview] = useState('leadGeneration');
  const [reportsSelected, setReportsSelected] = useState(false);

  const changeSelection = (remember, content) => {
    if (remember) {
      if (content === 'refinancesOverview') {
        setLastOverview('refinance')
      } else if (content === 'renegotiationsOverview') {
        setLastOverview('renegotiation')
      } else if (content === 'leadGeneration') {
        setLastOverview('leadGeneration')
      }
    }
    dispatch( setNavSelection(content) )
    if (reportsSelected) {
      if (firstReport) {
        let notifyUser = firstReport.notifyUser
        if (notifyUser) {
          if (!newReportRemoved) {
            removeUserNotification({
              userId: userId,
              reportId: firstReport._id
            })
            dispatch( setNewReportRemoved(true) )
          }
        }
        setReportsSelected(false)
      }
    }
    if (content === 'reports') {
      setReportsSelected(true)
    }
  }

  const handleShowOverview = () => {
    if (lastOverview === 'refinance') {
      changeSelection(true, 'refinancesOverview')
    } else if (lastOverview === 'renegotiation') {
      changeSelection(true, 'renegotiationsOverview')
    } else {
      changeSelection(true, 'leadGeneration')
    }
  }

  const badgeStyle = {
    "& .MuiBadge-badge": {
      color: `${theme.palette.common.white}`,
      backgroundColor: `${theme.palette.common.error}`,
      fontWeight: '800',
      fontSize: "1rem",
      height: "1.4rem",
      padding: "0 0.5rem",
      borderRadius: "1rem",
      minWidth: "1.2rem"
    }
  }

  return (
    <NavigationContainer>
      <SideBarNavHeader>Navigation</SideBarNavHeader>
      {dataLoaded ?
        <>
          {navSelection === 'activeLeads' ?
            <ListItemNav selected>
              <NavIndex>
                {leadNotifications !== undefined ?
                  leadNotifications > 0 ?
                    <Badge badgeContent={leadNotifications} color="error" sx={badgeStyle}>
                      <ArrowForwardIcon fontSize="medium" />
                    </Badge>
                  :
                    <ArrowForwardIcon fontSize="medium" />
                :
                  <ArrowForwardIcon fontSize="medium" />
                }
              </NavIndex>
              <NavText className="main">Active Leads</NavText>
            </ListItemNav>
          :
            <ListItemNav onClick={() => changeSelection(false, 'activeLeads')}>
              <NavIndex>
                {leadNotifications !== undefined ?
                  leadNotifications > 0 ?
                    <Badge badgeContent={leadNotifications} color="error" sx={badgeStyle}>
                      <ArrowForwardIcon fontSize="medium" />
                    </Badge>
                  :
                    <ArrowForwardIcon fontSize="medium" />
                :
                  <ArrowForwardIcon fontSize="medium" />
                }
                </NavIndex>
              <NavText className="main">Active Leads</NavText>
            </ListItemNav>
          }
          {navSelection === 'refinancesOverview' || navSelection === 'renegotiationsOverview' || navSelection === 'leadGeneration' ?
            <ListItemNav selected>
              <NavIndex>
                <QueryStatsIcon fontSize="medium" />
              </NavIndex>
              <NavText className="main">Performance</NavText>
            </ListItemNav>
          :
            <ListItemNav onClick={handleShowOverview}>
              <NavIndex>
                <QueryStatsIcon fontSize="medium" />
              </NavIndex>
              <NavText className="main">Performance</NavText>
            </ListItemNav>
          }
          {navSelection === 'leadGeneration' ?
            <SubListItemNav subCat selected>
              <NavIndex className="selected">
                i.
              </NavIndex>
              <NavText className="selected">Lead Generation</NavText>
            </SubListItemNav>
          :
            <SubListItemNav subCat onClick={() => changeSelection(true, 'leadGeneration')}>
              <NavIndex className="notSelected">
                i.
              </NavIndex>
              <NavText className="notSelected">Lead Generation</NavText>
            </SubListItemNav>
          }
          {navSelection === 'renegotiationsOverview' ?
            <SubListItemNav subCat selected>
              <NavIndex className="selected">
                ii.
              </NavIndex>
              <NavText className="selected">Renegotiations</NavText>
            </SubListItemNav>
          :
          <SubListItemNav subCat onClick={() => changeSelection(true, 'renegotiationsOverview')}>
              <NavIndex className="notSelected">
                ii.
              </NavIndex>
              <NavText className="notSelected">Renegotiations</NavText>
            </SubListItemNav>
          }
          {navSelection === 'refinancesOverview' ?
            <SubListItemNav subCat selected>
              <NavIndex className="selected">
                iii.
              </NavIndex>
              <NavText className="selected">Refinances</NavText>
            </SubListItemNav>
          :
            <SubListItemNav subCat onClick={() => changeSelection(true, 'refinancesOverview')}>
              <NavIndex className="notSelected">
                iii.
              </NavIndex>
              <NavText className="notSelected">Refinances</NavText>
            </SubListItemNav>
          }
          {navSelection === 'mortgageRepository' ?
            <ListItemNav selected>
              <NavIndex>
                <ListIcon fontSize="medium" />
              </NavIndex>
              <NavText className="main">Mortgage Repository</NavText>
            </ListItemNav>
          :
            <ListItemNav onClick={() => changeSelection(false, 'mortgageRepository')}>
              <NavIndex>
                <ListIcon fontSize="medium" />
              </NavIndex>
              <NavText className="main">Mortgage Repository</NavText>
            </ListItemNav>
          }
          {navSelection === 'reports' ?
            <ListItemNav selected>
              <NavIndex>
                <Badge badgeContent={newReports} color="error" sx={badgeStyle}>
                  <ArticleIcon fontSize="medium" sx={{color: `${theme.palette.primary.main}`}} />
                </Badge>
              </NavIndex>
              <NavText className="main">Reports</NavText>
            </ListItemNav>
          :
            <ListItemNav onClick={() => changeSelection(false, 'reports')}>
              <NavIndex>
                <Badge badgeContent={newReports} color="error" sx={badgeStyle}>
                  <ArticleIcon fontSize="medium" sx={{color: `${theme.palette.primary.main}`}} />
                </Badge>
              </NavIndex>
                <NavText className="main">Reports</NavText>
            </ListItemNav>
          }
          {navSelection === 'notifications' && newNotifications !== undefined ?
            <ListItemNav selected>
              <NavIndex>
                  <Badge badgeContent={newNotifications} color="error" sx={badgeStyle}>
                    <NotificationsIcon fontSize="medium" sx={{color: `${theme.palette.primary.main}`}} />
                  </Badge>
              </NavIndex>
              <NavText className="main">Notifications</NavText>
            </ListItemNav>
          : newNotifications !== undefined ?
            <ListItemNav onClick={() => changeSelection(false, 'notifications')}>
              <NavIndex>
                  <Badge badgeContent={newNotifications} color="error" sx={badgeStyle}>
                    <NotificationsIcon fontSize="medium" sx={{color: `${theme.palette.primary.main}`}} />
                  </Badge>
              </NavIndex>
                <NavText className="main">Notifications</NavText>
            </ListItemNav>
          :
            <ListItemNav onClick={() => changeSelection(false, 'notifications')}>
              <NavIndex>
                  <Badge color="error" sx={badgeStyle}>
                    <NotificationsIcon fontSize="medium" sx={{color: `${theme.palette.primary.main}`}} />
                  </Badge>
              </NavIndex>
                <NavText className="main">Notifications</NavText>
            </ListItemNav>
          }
          {navSelection === 'insights' ?
            <ListItemNav selected>
              <NavIndex>
                <InsightsIcon fontSize="medium" />
              </NavIndex>
              <NavText className="main">Insights</NavText>
            </ListItemNav>
          :
            <ListItemNav onClick={() => changeSelection(false, 'insights')}>
              <NavIndex>
                <InsightsIcon fontSize="medium" />
              </NavIndex>
              <NavText className="main">Insights</NavText>
            </ListItemNav>
          }
          {navSelection === 'settings' ?
            <ListItemNav selected>
              <NavIndex>
                <SettingsIcon fontSize="medium" />
              </NavIndex>
              <NavText className="main">Settings</NavText>
            </ListItemNav>
          :
            <ListItemNav onClick={() => changeSelection(false, 'settings')}>
              <NavIndex>
                <SettingsIcon fontSize="medium" />
              </NavIndex>
              <NavText className="main">Settings</NavText>
            </ListItemNav>
          }
          {navSelection === 'guide' ?
            <ListItemNav selected>
              <NavIndex>
                <AutoStoriesIcon fontSize="medium" />
              </NavIndex>
              <NavText className="main">Guide</NavText>
            </ListItemNav>
          :
            <ListItemNav onClick={() => changeSelection(false, 'guide')}>
              <NavIndex>
                <AutoStoriesIcon fontSize="medium" />
              </NavIndex>
              <NavText className="main">Guide</NavText>
            </ListItemNav>
          }
        </>
      :
        <>
          <ListItemNav disabled>
            <NavIndex>
              <ArrowForwardIcon fontSize="medium" />
            </NavIndex>
            <NavText className="disabled">Active Leads</NavText>
          </ListItemNav>
          <ListItemNav disabled>
            <NavIndex>
              <QueryStatsIcon fontSize="medium" />
            </NavIndex>
            <NavText className="disabled">Performance</NavText>
          </ListItemNav>
          <SubListItemNav subCat disabled>
            <NavIndex className="selected">
              i.
            </NavIndex>
            <NavText className="disabled">Lead Generation</NavText>
          </SubListItemNav>
          <SubListItemNav subCat disabled>
            <NavIndex className="selected">
              ii.
            </NavIndex>
            <NavText className="disabled">Renegotiations</NavText>
          </SubListItemNav>
          <SubListItemNav subCat disabled>
            <NavIndex className="selected">
              iii.
            </NavIndex>
            <NavText className="disabled">Refinances</NavText>
          </SubListItemNav>
          <ListItemNav disabled>
            <NavIndex>
              <ListIcon fontSize="medium" />
            </NavIndex>
            <NavText className="disabled">Mortgage Repository</NavText>
          </ListItemNav>
          <ListItemNav disabled>
            <NavIndex>
              <Badge badgeContent={newReports} color="error" sx={badgeStyle}>
                <ArticleIcon fontSize="medium" />
              </Badge>
            </NavIndex>
            <NavText className="disabled">Reports</NavText>
          </ListItemNav>
          <ListItemNav disabled>
            <NavIndex>
              <NotificationsIcon fontSize="medium" />
            </NavIndex>
            <NavText className="disabled">Notifications</NavText>
          </ListItemNav>
          <ListItemNav disabled>
            <NavIndex>
              <InsightsIcon fontSize="medium" />
            </NavIndex>
            <NavText className="disabled">Insights</NavText>
          </ListItemNav>
          <ListItemNav disabled>
            <NavIndex>
              <SettingsIcon fontSize="medium" />
            </NavIndex>
            <NavText className="disabled">Settings</NavText>
          </ListItemNav>
          <ListItemNav disabled>
            <NavIndex>
              <AutoStoriesIcon fontSize="medium" />
            </NavIndex>
            <NavText className="disabled">Guide</NavText>
          </ListItemNav>
        </>
      }
    </NavigationContainer>
  )
}

const ListItemNav = styled.a`
  display: flex;
  align-items: center;
  font-weight: 400;
  border-radius: 0;
  height: ${(props) => props.selected ? "3.5rem" : "3.5rem"};
  border-right: ${(props) => props.selected ? "0.1rem solid #F7F7F7" : "0.1rem solid #c8cbcf"};
  border-top: ${(props) => props.selected ? "0.1rem solid #c8cbcf" : "none"};
  border-bottom: ${(props) => props.selected ? "0.1rem solid #c8cbcf" : "none"};
  padding: 0.5rem 0 0.5rem 1.2rem;
  margin: 0;
  background-color: ${(props) => props.selected && !props.subCat ? "#F7F7F7" : props.subCat || props.disabled ? "none" : "none"};
  cursor: ${(props) => props.selected || props.noCursor || props.disabled ? "default" : "pointer"};
  color: ${(props) => props.disabled ? '#939599' : '#00B812'};
  &:hover {
    background-color: ${(props) => props.selected && !props.subCat ? "#c8cbcf" : props.subCat && !props.selected ? "#4B14752B" : props.subCat && (props.selected || props.disabled) ? "#4B14752B" : "#4B14752B"};
  }
`
const SubListItemNav = styled.a`
  display: flex;
  align-items: center;
  font-weight: 400;
  border-radius: 0;
  padding: 0.5rem 0 0.5rem 1.2rem;
  margin: 0;
  background-color: ${(props) => props.selected && !props.subCat ? "#4B14752B" : props.subCat ? "none" : "none"};
  cursor: ${(props) => props.selected || props.noCursor || props.disabled ? "default" : "pointer"};
  color: ${(props) => props.subCat && !props.selected ? "#939599" : props.subCat && props.selected ? "#00B812" : "none"};
  border-right: ${(props) => props.selected ? "0.1rem solid #c8cbcf" : "0.1rem solid #c8cbcf"};
  &:hover {
    background-color: ${(props) => props.selected && !props.subCat ? "#4B147533" : props.subCat && !props.selected ? "none" : props.subCat && props.selected ? "none" : "#F2F2F2"};
    color: ${(props) => props.subCat && !props.selected && !props.disabled ? "#414042" : props.disabled ? '#939599' : "#00B812"};
  }
`

export default Navigation;
import React from 'react';
import CircleIcon from '@mui/icons-material/Circle';
import { useTheme } from "@mui/styles";
import { useSelector } from 'react-redux';
import { selectTier2PieChartData } from '../../../../../../slices/teamStatsSlice';
import { AccentInfo, ComponentBorder, ComponentContainer } from '../../../../../../StyledComponents';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';

const Tier2 = () => {
  const theme = useTheme();

  const pieChartData = useSelector(selectTier2PieChartData)
  
  const COLORS = [`${theme.light.closures.closure}`, `${theme.light.closures.dismissal}`];
  
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
  
    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };
  
  return (
    <ComponentContainer className='thinBorders' style={{gridRow: '5', gridColumn: '1', height: '100%'}}>
      <div style={{display: 'flex', flexDirection: 'column', height: '100%', width: '100%', padding: '0.5rem'}}>
        <div style={{width: '100%', textAlign: 'center', color: `${theme.palette.common.grayText}`}}>Tier 2 Leads</div>
        {pieChartData[0].size === 0 && pieChartData[1].size === 0 ?
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%'}}>
            <AccentInfo>none closed</AccentInfo>
          </div>
        :
          <ResponsiveContainer width="100%" height="60%">
            <PieChart width={400} height={300}>
              <Pie
                label={renderCustomizedLabel}
                dataKey="size"
                startAngle={180}
                endAngle={0}
                data={pieChartData}
                cx="50%"
                cy="90%"
                outerRadius={60}
                fill="#8884d8"
                labelLine={false}
                animationDuration={500}
              >
                {pieChartData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        }
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', margin: 'auto 0'}}>
          <div style={{display: 'flex', padding: '0.3rem 0.8rem 0.1rem 0.6rem', justifyContent: 'space-between'}}>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <CircleIcon style={{color: `${theme.light.closures.closure}`, width: '1rem', margin: '0 0.3rem 0 0'}} />
              <span>Closed</span>
            </div>
            <div>
              {pieChartData !== undefined ?
                pieChartData[0].name
              :
                <></>
              }
            </div>
          </div>
          <div style={{display: 'flex', padding: '0 0.8rem 0.1rem 0.6rem', justifyContent: 'space-between'}}>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <CircleIcon style={{color: `${theme.light.closures.dismissal}`, width: '1rem', margin: '0 0.3rem 0 0'}} />
              <span>Dismissed</span>
            </div>
            <div>
              {pieChartData !== undefined ?
                pieChartData[1].name
              :
                <></>
              }
            </div>
          </div>
        </div>
      </div>
      <ComponentBorder className="top" style={{gridRow:'1', gridColumn: '1/4'}} />
      <ComponentBorder className="left" style={{gridRow:'2', gridColumn: '1'}} />
      <ComponentBorder className="right"  style={{gridRow:'2', gridColumn: '3'}} />
      <ComponentBorder className="bottom" style={{gridRow:'3', gridColumn: '1/4'}} />
    </ComponentContainer>
  )
}

export default Tier2;
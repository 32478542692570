import React from "react";
import { useTheme } from "@mui/styles";
import { Detail, DetailContainer, DetailInfoContainer, DetailListContainer } from "../../../StyledComponents";

const TargetsUpdated = ({milestone}) => {
  const theme = useTheme()

  return (
    <DetailContainer className="fitContent">
      <Detail className="timelineHeader">{milestone.milestone}{milestone.notify ? <span style={{color: `${theme.palette.primary.main}`, margin: '0.25rem 0 0 0.5rem', fontWeight: '400'}}>new</span> : <></>}</Detail>
      <DetailListContainer className='timeline'>
        <DetailInfoContainer className="item timeline">
          <Detail className="timelineDetail">updated by:</Detail>
          <Detail className="timelineDetail" style={{margin: '0 0 0.5rem 0'}}>date:</Detail>
          {milestone.details.map((detail, index) => (
            <Detail key={index}>{detail.field}:</Detail>
          ))}
          <Detail className="timelineDetail">Target Profit:</Detail>
          <Detail style={{height: '1.7rem'}}></Detail>
          <Detail className="timelineDetail">Target Difference:</Detail>
          <Detail style={{height: '1.7rem'}}></Detail>
          <Detail className="timelineDetail">Target Monthly Payments:</Detail>
          <Detail style={{height: '1.7rem'}}></Detail>
        </DetailInfoContainer>
        <DetailInfoContainer className="space">
          <Detail className="timelineItem">{milestone.contributor}</Detail>
          <Detail className="timelineItem" style={{margin: '0 0 0.5rem 0'}}>{milestone.date}</Detail>
          {milestone.details.map((detail, index) => (
            <div key={index}>
              {detail.field.includes('Rate') ?
                <>
                  <span style={{fontWeight: '600'}}>{detail.old.toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2})}%</span> to <span style={{fontWeight: '600'}}>{detail.new.toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2})}%</span>
                </>
              : detail.field.includes('Term') ?
                <>
                  <span style={{fontWeight: '600'}}>{detail.old.toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 0})}</span> to <span style={{fontWeight: '600'}}>{detail.new.toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 0})} years</span>
                </>
              :
                <>
                  <span style={{fontWeight: '600'}}>${detail.old.toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 0})}</span> to <span style={{fontWeight: '600'}}>${detail.new.toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 0})}</span>
                </>
              }
            </div>
          ))}
          <Detail className="timelineItem">
            <div>
              <span style={{fontWeight: '600'}}>${milestone.newProfits.profitNumber.old.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}</span><span style={{fontWeight: '400'}}> to </span><span style={{fontWeight: '600'}}>${milestone.newProfits.profitNumber.new.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}</span>
            </div>
          </Detail>
          <Detail className="timelineItem">
            {milestone.newProfits.profitNumber.difference === 0 ?
              <Detail className="wrappedGray">no change</Detail> 
            : milestone.newProfits.profitNumber.new > milestone.newProfits.profitNumber.old ?
              <Detail className="wrapped">${milestone.newProfits.profitNumber.difference.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})} increase</Detail> 
            :
              <Detail className="wrappedRed">${milestone.newProfits.profitNumber.difference.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})} decrease</Detail>     
            }
          </Detail>
          <Detail className="timelineItem">
            <div>
              <span style={{fontWeight: '600'}}>{milestone.newProfits.profitPercent.old.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}%</span><span style={{fontWeight: '400'}}> to </span><span>{milestone.newProfits.profitPercent.new.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}%</span>
            </div>
          </Detail>
          <Detail>
            {milestone.newProfits.profitPercent.difference === 0 ?
              <Detail className="wrappedGray">no change</Detail> 
            : milestone.newProfits.profitPercent.new > milestone.newProfits.profitPercent.old ?
              <Detail className="wrapped">{milestone.newProfits.profitPercent.difference.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}% increase</Detail> 
            :
              <Detail className="wrappedRed">{milestone.newProfits.profitPercent.difference.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}% decrease</Detail>     
            }
          </Detail>
          <Detail className="timelineItem">
            <div>
              <span style={{fontWeight: '600'}}>${milestone.newProfits.monthlyPayments.old.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}</span><span style={{fontWeight: '400'}}> to </span><span>${milestone.newProfits.monthlyPayments.new.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}</span>
            </div>
          </Detail>
          <Detail>
            {milestone.newProfits.monthlyPayments.difference === 0 ?
              <Detail className="wrappedGray">no change</Detail> 
            : milestone.newProfits.monthlyPayments.new > milestone.newProfits.monthlyPayments.old ?
              <Detail className="wrapped">${milestone.newProfits.monthlyPayments.difference.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})} increase</Detail> 
            :
              <Detail className="wrappedRed">${milestone.newProfits.monthlyPayments.difference.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})} decrease</Detail>     
            }
          </Detail>
        </DetailInfoContainer>
      </DetailListContainer>
    </DetailContainer>
  )
}

export default TargetsUpdated
import React from "react";
import Notes from "./components/Notes";
import EditTags from "./components/EditTags/EditTags";
import CancelIcon from '@mui/icons-material/Cancel';
import EditTargets from "./components/EditTargets";
import DismissLead from "./components/LeadStatus/DismissLead";
import ReportsModal from "./components/ReportsModal";
import BeginClosing from "./components/LeadStatus/BeginClosing";
import OwnerDetails from "./components/OwnerDetails";
import LeadTimeline from "../../Timeline/LeadTimeline";
import FinalizeLead from "./components/LeadStatus/FinalizeLead";
import CompareRecords from "./components/CompareRecords";
import LeadActionsNav from "./LeadActionsNav";
import { useSelector } from "react-redux";
import PropertyDetails from "./components/PropertyDetails";
import InvestigateLead from "./components/LeadStatus/InvestigateLead";
import AddAssigneeModal from "./components/AddAssigneeModal";
import { selectViewingLead } from "../../../slices/viewingLeadSlice";
import { selectViewingMortgage } from "../../../slices/viewingMortgageSlice";
import { selectViewingLeadReports } from "../../../slices/viewingLeadReportsSlice";
import { selectLeadActionsNavSelection } from "../../../slices/navMenuSelectionsSlice";
import { ActionIconWrapper, ModalContainer, ComponentBorder, TimelineContainer , ComponentHeader} from "../../../StyledComponents";
import ConfirmUpdate from "./components/LeadStatus/ConfirmUpdate";

const LeadActionsModal = ({ handleCloseLeadActionsModal, userId, userFullName, dashPerspective, leadUserNotifications, setLeadUserNotifications, teamId, memberData, leadStatusFilter }) => {

  const leadReports = useSelector(selectViewingLeadReports)
  const mortgageData = useSelector(selectViewingMortgage)
  const navSelection = useSelector(selectLeadActionsNavSelection)
  const leadData = useSelector(selectViewingLead)

  return (
    <ModalContainer className="threePanel">
      <ComponentBorder className="top" style={{gridRow: '1', gridColumn: '1/6'}}>
        <ActionIconWrapper className="medium grayText circle hoverBorder hover" style={{margin: 'auto 2rem auto 0'}}>
          <CancelIcon onClick={() => handleCloseLeadActionsModal()} style={{height: '100%', width: '100%'}} />  
        </ActionIconWrapper>
      </ComponentBorder>
      <LeadActionsNav mortgageData={mortgageData} reportsNumber={mortgageData.reports.length} leadStatus={leadData.status} />
      {navSelection === 'reports' ?
        <ReportsModal handleCloseNotesModal={handleCloseLeadActionsModal} reports={leadReports} />
      : navSelection === 'notes' ?
        <Notes handleCloseNotesModal={handleCloseLeadActionsModal} userId={userId} userFullName={userFullName} leadData={leadData} dashPerspective={dashPerspective} mortgageData={mortgageData} />
      : navSelection === 'compareRecords' ?
        <CompareRecords mortgageData={mortgageData} userId={userId} dashPerspective={dashPerspective} userFullName={userFullName} leadUserNotifications={leadUserNotifications} setLeadUserNotifications={setLeadUserNotifications} leadData={leadData} teamId={teamId} location={'activeLead'} />
      : navSelection === 'propertyDetails' ?
        <PropertyDetails handleCloseLeadActionsModal={handleCloseLeadActionsModal} mortgageData={mortgageData} />
      : navSelection === 'ownerDetails' ?
        <OwnerDetails handleCloseLeadActionsModal={handleCloseLeadActionsModal} mortgageData={mortgageData} />
      : navSelection === 'editTargets' ?
        <EditTargets handleCloseEditTargetModal={handleCloseLeadActionsModal} leadData={leadData} userId={userId} userFullName={userFullName} dashPerspective={dashPerspective} mortgageData={mortgageData} />
      : navSelection === 'editTags' ?
        <EditTags leadData={leadData} userFullName={userFullName} teamId={teamId} dashPerspective={dashPerspective} userId={userId} activeLead={mortgageData.activeLead} mortgageData={mortgageData} />
      : navSelection === 'addAssignees' ?
        <AddAssigneeModal memberData={memberData} handleCloseLeadActionsModal={handleCloseLeadActionsModal} leadData={leadData} userId={userId} userFullName={userFullName} leadUserNotifications={leadUserNotifications} activeLead={mortgageData.activeLead} />
      : navSelection === 'investigateLead' ?
        <InvestigateLead handleCloseInvestigateLead={handleCloseLeadActionsModal} userId={userId} teamId={teamId} userFullName={userFullName} memberData={memberData} mortgageId={mortgageData._id} leadData={leadData} dashPerspective={dashPerspective} requestOrigin={'activeLeads'} />
      : navSelection === 'dismissLead' ?
        <DismissLead handleCloseColdModal={handleCloseLeadActionsModal} userFullName={userFullName} leadStatusFilter={leadStatusFilter} teamId={teamId} userId={userId} dashPerspective={dashPerspective} leadData={leadData} leadUserNotifications={leadUserNotifications} />
      : navSelection === 'beginClosingLead' ?
        <BeginClosing handleCloseLeadActionsModal={handleCloseLeadActionsModal} leadData={leadData} userId={userId} userFullName={userFullName} dashPerspective={dashPerspective} teamId={teamId} leadUserNotifications={leadUserNotifications} />
      : navSelection === 'finalizeLead' ?
        <FinalizeLead handleCloseLeadActionsModal={handleCloseLeadActionsModal} leadData={leadData} userId={userId} userFullName={userFullName} dashPerspective={dashPerspective} teamId={teamId} leadUserNotifications={leadUserNotifications} mortgageData={mortgageData} />
      : navSelection === 'confirmUpdate' ?
        <ConfirmUpdate handleCloseLeadActionsModal={handleCloseLeadActionsModal} leadData={leadData} userId={userId} userFullName={userFullName} dashPerspective={dashPerspective} teamId={teamId} mortgageData={mortgageData} />
      :
        <></>
      }
      <ComponentHeader className="modal timeline">Timeline</ComponentHeader>
      <TimelineContainer className="actionsModal">
        <LeadTimeline />
      </TimelineContainer>
      <ComponentBorder className="left" style={{gridRow:'2/5', gridColumn: '1'}}></ComponentBorder>
      <ComponentBorder className="right"  style={{gridRow:'2/5', gridColumn: '5'}}></ComponentBorder>
      <ComponentBorder className="bottom" style={{gridRow:'5', gridColumn: '1/6'}}></ComponentBorder>
    </ModalContainer>
  )
}

export default LeadActionsModal;
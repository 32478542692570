import React, { useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import dayjs from "dayjs";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { API_URL } from '../../../../config';
import { ActionMenuItem, Button, CardButton, ComponentContainer } from "../../../../StyledComponents";
import { useCreateCronTickerMutation } from "../../../../slices/api/apiSlice";

const ScheduledTasks = () => {

    // const [isFetchingEvents, setIsFetchingEvents] = useState(false);
    const [events, setEvents] = useState([]);
  
    async function handleGetEvents() {
      const res = await fetch(`${API_URL}/cron/list-events`);
      const data = await res.json();
      setEvents(data);
    }
  
    async function handleTriggerEvent(event_id) {
      const res = await fetch(`${API_URL}/cron/trigger-event`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ event_id }),
      });
      const data = await res.json();
      await handleGetEvents();
    }
  
    async function handleDisableEvent(event_id) {
      const res = await fetch(
        `${API_URL}/cron/disable-event?event_id=${event_id}`,
        { method: "post" }
      );
      const data = await res.json();
      await handleGetEvents();
    }
  
    async function handleEnableEvent(event_id) {
      const res = await fetch(
        `${API_URL}/cron/enable-event?event_id=${event_id}`,
        { method: "post" }
      );
      const data = await res.json();
      await handleGetEvents();
    }
  
    async function handleDeleteEvent(event_id) {
      const res = await fetch(`${API_URL}/cron/delete-event`, {
        method: "post",
        body: { event_id },
      });
      const data = await res.json();
      await handleGetEvents();
    }

    const [menuActive, setMenuActive] = useState(false)
    const [actionMenuAnchorEl, setActionMenuAnchorEl] = useState(null)
    const openActionsMenu = Boolean(actionMenuAnchorEl)
    const handleOpenActions = (event) => {
      setActionMenuAnchorEl(event.currentTarget)
    };
    const handleCloseActions = () => {
      setActionMenuAnchorEl(null)
    };
  
    useEffect(() => {
      handleGetEvents();
    }, []);

    const [createCronTicker] = useCreateCronTickerMutation()
    const handleCreateTicker = async () => {
      await createCronTicker()
    }
  
  if (events.length < 1) {
    return (
      <div>
        <p>No events have been scheduled.</p>
        <Button onClick={() => handleCreateTicker()}>Create Cron Ticker</Button>
      </div>
    )
  }

  return (
    <ComponentContainer className="justifyStart" style={{height: 'fit-content'}}>
      <table className="wc-event-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Event Name</th>
            <th>Cycle</th>
            <th>Executions</th>
            <th>Next Execution</th>
            <th>Status</th>
            <th>Created</th>
            {/* <th>Time</th> */}
            <th></th>
          </tr>
        </thead>
        <tbody>
          {events.map((ev, i) => (
            <tr key={ev._id}>
              <td>#{i + 1}</td>
              <td className="event-name">{ev.name}</td>
              <td className="event-cycle">
                {`${ev.cycle.value} ${ev.cycle.unit}` ?? "-"}
              </td>
              <td>{ev.executionCount ?? 0}</td>
              <td>
                {ev.nextExecution
                  ? dayjs(ev.nextExecution).format("h:mm A MMM D, YYYY")
                  : "Not applicable"}
              </td>
              <td>
                <span
                  className={
                    "event-status " + (ev.enabled ? "enabled" : "disabled")
                  }
                >
                  {ev.enabled ? "Enabled" : "Disabled"}
                </span>
              </td>
              {/* <td>{dayjs(ev.createdAt).toISOString()}</td> */}
              <td>{dayjs(ev.createdAt).format("h:mm A  MMM D, YYYY")}</td>

              <td>
                <CardButton className={`${menuActive ? "active" : "actions"} horizontal`} onClick={handleOpenActions}>
                  Action
                </CardButton>
                <StyledMenu
                  anchorEl={actionMenuAnchorEl}
                  open={openActionsMenu}
                  onClose={handleCloseActions}
                >
                  <MenuItem onClick={handleCloseActions} disableRipple className="border topRadius">
                    <ActionMenuItem onClick={() => 
                        ev.enabled
                          ? handleDisableEvent(ev._id)
                          : handleEnableEvent(ev._id)
                      }
                    >
                      {ev.enabled ? "Disable" : "Enable"}
                    </ActionMenuItem>
                  </MenuItem>
                  <MenuItem onClick={handleCloseActions} disableRipple className="border">
                    <ActionMenuItem onClick={() => handleTriggerEvent(ev._id)}>
                      Trigger Event
                    </ActionMenuItem>
                  </MenuItem>
                  <MenuItem onClick={handleCloseActions} disableRipple className="border">
                    <ActionMenuItem onClick={() => handleDeleteEvent(ev._id)}>
                      Delete Event
                    </ActionMenuItem>
                  </MenuItem>
                </StyledMenu>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </ComponentContainer>
  )
}

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '1rem',
    minWidth: 150,
    background: theme.palette.common.grayBorder,
    borderRight: `1rem solid ${theme.palette.common.grayBorder}`,
    borderLeft: `1rem solid ${theme.palette.common.grayBorder}`,
    borderTop: `0.4rem solid ${theme.palette.common.grayBorder}`,
    borderBottom: `0.4rem solid ${theme.palette.common.grayBorder}`,
    marginLeft: '0.5rem',
    '& .MuiMenu-list:hover': {
    },
    '& .MuiMenuItem-root': {
      background: theme.palette.common.pageBackground,
      '&.buttonTop': {
        padding: '1rem 1rem 0.5rem 1rem',
      },
      '&.border': {
        borderBottom: `0.1rem solid ${theme.palette.common.grayBorder}`
      },
      '&.topRadius': {
        borderRadius: '0.6rem 0.6rem 0 0',
      },
      '&.bottomRadius': {
        borderRadius: '0 0 0.6rem 0.6rem',
        padding: '0.5rem 1rem 1rem 1rem',
      }
    },
    '& .MuiMenuItem-root:hover': {
      background: theme.palette.common.grayHover,
      '&.button': {
        background: theme.palette.common.pageBackground,
      },
    },
  },
}));

export default ScheduledTasks
import React, { useState } from "react";
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from "@mui/icons-material/Error";
import { useTheme } from "@mui/styles";
import CircularProgress from "@mui/material/CircularProgress";
import AssigneeDropdown from "../../../DropDowns/AssigneeDropdown";
import { useAddAssigneesMutation } from "../../../../slices/api/apiSlice";
import { SubHeader, Button, ButtonWrapper, FlexWrapper, ErrorWrapper, Label, InputWrapper, ModalContentContainer, ComponentHeader, StatusAndLabelWrapper } from "../../../../StyledComponents";

const AddAssigneeModal = ({ memberData, handleCloseLeadActionsModal, leadData, userId, userFullName, leadUserNotifications, activeLead }) => {
  const theme = useTheme();

  const [assigneeIds, setAssigneeIds] = useState(leadData.assigneeIds)
  const [addedUsers, setAddedUsers] = useState('')
  const [removedUsers, setRemovedUsers] = useState('')
  const [statusEmpty, setStatusEmpty] = useState(false)
  const [noneAdded, setNoneAdded] = useState(false)
  const [usersAdded, setUsersAdded] = useState(0)
  const [usersRemoved, setUsersRemoved] = useState(0)

  const[addAssignees, {isFetching, isSuccess, isError}] = useAddAssigneesMutation()

  const handleSetAssignee = (event) => {
    setAssigneeIds(event.target.value);
    setStatusEmpty(false);
  }

  const handleAddAssignee = async () => {
    let previousAssignees = [...leadData.assigneeIds];
    let newAssignees = []
    let newAssigneesBool = false
    let assigneeNames = [];
    let previousAssigneeNames = [];
    let previousAssigneeIds = [];
    let newAssigneeNames = [];
    let newAssigneeIds = [];
    let removedAssigneesNames = [];
    let removedAssigneeIds = [];
    let usersRemoved = 0;
    let usersAdded = 0;
    for (let i = 0; i < assigneeIds.length; i++) {
      let assigneeMatch = previousAssignees.find(assignee => assignee === assigneeIds[i])
      if (!assigneeMatch) {
        newAssignees.push(assigneeIds[i])
        newAssigneesBool = true
      } else {
        let assignee = memberData.find(assignee => assignee.memberId === assigneeIds[i])
        assigneeNames.push(assignee.fullName)
        let thisIndex = previousAssignees.indexOf(assigneeMatch)
        previousAssignees.splice(thisIndex, 1)
        previousAssigneeNames.push(assignee.fullName)
        previousAssigneeIds.push(assignee.memberId)
      }
    }
    for (let i = 0; i < previousAssignees.length; i++) {
      let remainingAssignee = assigneeIds.find(assignee => assignee === previousAssignees[i])
      if (!remainingAssignee) {
        let assignee = memberData.find(assignee => assignee.memberId === previousAssignees[i])
        removedAssigneesNames.push(assignee.fullName)
        removedAssigneeIds.push(assignee.memberId)
        usersRemoved++
      }
    }
    if (!newAssigneesBool && usersRemoved === 0) {
      setNoneAdded(true)
    } else if (assigneeIds.length === 0 && usersRemoved === 0) {
      setStatusEmpty(true)
    } else {
      for (let i = 0; i < newAssignees.length; i++) {
        let assignee = memberData.find(member => member.memberId === newAssignees[i])
        usersAdded++
        assigneeNames.push(assignee.fullName)
        newAssigneeNames.push(assignee.fullName)
        newAssigneeIds.push(assignee.memberId)
      }
      addAssignees({
        userId: userId,
        timeline: leadData.timeline,
        userFullName: userFullName,
        newAssigneeNames: newAssigneeNames,
        removedAssigneesNames: removedAssigneesNames,
        previousAssigneeNames: previousAssigneeNames,
        leadId: leadData._id,
        assigneeIds: assigneeIds,
        assigneeNames: assigneeNames,
        newAssigneeIds: newAssigneeIds,
        removedAssigneeIds: removedAssigneeIds,
        previousAssigneeIds: previousAssigneeIds,
        userNotifications: leadUserNotifications,
      })
      setAddedUsers(newAssigneeNames);
      setRemovedUsers(removedAssigneesNames)
      setUsersAdded(usersAdded);
      setUsersRemoved(usersRemoved);
    }
  }

  return (
    <>
      {isSuccess ?
        <ComponentHeader className="modal green"><CheckIcon style={{height: '2.5rem', width: '2.5rem', margin: '0 0.5rem 0.2rem 0'}} />Success</ComponentHeader>
      : isError ?
        <ComponentHeader className="modal red"><ErrorIcon style={{height: '2.5rem', width: '2.5rem', margin: '0 0.5rem 0.2rem 0'}} />failed... try again or contact support@lancastersweep.com</ComponentHeader>
      :
        <ComponentHeader className="modal">Add Assignees</ComponentHeader>
      }

      <ModalContentContainer className="centerColumn leadActions">
        {isSuccess ?
          <SubHeader className="modal" style={{margin: '0 auto'}}>
            <div style={{display: 'flex'}}>
              {addedUsers.map((user, index) => (
                index === 0 ?
                  <span key={index} style={{margin: 'auto', fontWeight: '600'}}>{user}</span>
                : index === 1 && index === (addedUsers.length - 1) ?
                  <span key={index} style={{margin: '0 0 0 0.3rem'}}>and <span style={{margin: 'auto', fontWeight: '600'}}>{user}</span></span>
                : index !== (addedUsers.length - 1) ?
                  <span key={index}>, <span style={{margin: 'auto', fontWeight: '600'}}>{user}</span></span>
                : index === (addedUsers - 1) ?
                  <span key={index}>, and <span style={{margin: 'auto', fontWeight: '600'}}>{user}</span></span>
                : 
                  <span key={index}></span>
              ))}
            </div>
            {usersAdded === 1 ?
              <span> has been assigned to the lead.</span>
              : usersAdded > 1 ?
              <span> have been assigned to the lead.</span>
              : 
              <></>
            }
            <div style={{display: 'flex'}}>
              {removedUsers.map((user, index) => (
                index === 0 ?
                  <span key={index} style={{margin: 'auto', fontWeight: '600'}}>{user}</span>
                : index === 1 && index === (removedUsers.length - 1) ?
                  <span key={index} style={{margin: '0 0 0 0.3rem'}}>and <span style={{margin: 'auto', fontWeight: '600'}}>{user}</span></span>
                : index !== (removedUsers.length - 1) ?
                  <span key={index}>, <span style={{margin: 'auto', fontWeight: '600'}}>{user}</span></span>
                : index === (removedUsers.length - 1) ?
                  <span key={index}>, and <span style={{margin: 'auto', fontWeight: '600'}}>{user}</span></span>
                : 
                  <span key={index}></span>
              ))}
            </div>
            {usersRemoved === 1 ?
              <span> has been removed from the lead.</span>
            : usersRemoved > 1 ?
              <span> have been removed from the lead.</span>
            : 
              <></>
            }
          </SubHeader>
        : noneAdded ?
          <SubHeader className="modal" style={{margin: '0 auto'}}>
            <span>No changes were made.</span>
          </SubHeader>
        : activeLead ?
          <>
            <SubHeader className="modal" style={{margin: '0 auto 1rem auto'}}>
              <span>Select one or more team members to assign to the lead.</span>
            </SubHeader>
            <InputWrapper style={{width: '25rem', margin: '0 auto'}}>
              <StatusAndLabelWrapper>
                <Label>Team Members</Label>
                <FlexWrapper className="fitContentWidth alignCenter" style={{height: '2rem'}}>
                  {statusEmpty === true && (
                    <ErrorWrapper>
                      <ErrorIcon />
                      Select an assignee
                    </ErrorWrapper>
                  )}
                </FlexWrapper>
              </StatusAndLabelWrapper>
              <AssigneeDropdown
                value={assigneeIds}
                handleChange={handleSetAssignee}
                items={memberData}
              />
            </InputWrapper>
          </>
        :
          <>
            <SubHeader className="modal" style={{margin: '0 auto 1rem auto'}}>
              <span>Select one or more team members to assign to the lead.</span>
            </SubHeader>
            <InputWrapper style={{width: '25rem', margin: '0 auto'}}>
              <StatusAndLabelWrapper>
                <Label>Team Members</Label>
                <FlexWrapper className="fitContentWidth alignCenter" style={{height: '2rem'}} />
              </StatusAndLabelWrapper>
              <AssigneeDropdown
                disabled={true}
                value={assigneeIds}
                handleChange={handleSetAssignee}
                items={memberData}
              />
            </InputWrapper>
          </>
        }
        {isFetching ?
          <ButtonWrapper className="justifyCenter" style={{margin: '2rem auto 0 auto'}}>
            <Button className="primaryLoading" >
              <CircularProgress
                sx={{ color: `${theme.palette.common.white}` }}
                size={18}
              />
            </Button>
          </ButtonWrapper>
        : isSuccess ?
          <ButtonWrapper className="justifyCenter" style={{margin: '2rem auto 0 auto'}}>
            <Button autoFocus className="gray" onClick={() => handleCloseLeadActionsModal()}>Close</Button>
          </ButtonWrapper>
        : noneAdded ?
          <ButtonWrapper className="justifyCenter" style={{margin: '2rem auto 0 auto'}}>
            <Button autoFocus className="gray" onClick={() => setNoneAdded(false)}>Go Back</Button>
            <Button className="gray" onClick={() => handleCloseLeadActionsModal()}>Close</Button>
          </ButtonWrapper>
        : activeLead ?
          <ButtonWrapper className="justifyCenter" style={{margin: '2rem auto 0 auto'}}>
            <Button className="gray" onClick={() => handleCloseLeadActionsModal()}>Cancel</Button>
            <Button className="primary" onClick={() => handleAddAssignee()}>Confirm</Button>
          </ButtonWrapper>
        :
          <ButtonWrapper className="justifyCenter" style={{margin: '2rem auto 0 auto'}}>
            <Button className="gray" onClick={() => handleCloseLeadActionsModal()}>Cancel</Button>
            <Button className="primaryDisabled">Confirm</Button>
          </ButtonWrapper>
        }
      </ModalContentContainer>
    </>
  )
}

export default AddAssigneeModal;
import React, { useState } from "react";
import 'react-csv-importer/dist/index.css';
import Stack from '@mui/material/Stack';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from "@mui/icons-material/Error";
import { useTheme } from "@mui/styles";
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import AssigneeDropdown from "../../../../DropDowns/AssigneeDropdown";
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { StyledToggleButton } from "../../../../../componentThemes";
import { selectTeamDefaultInterest } from "../../../../../slices/teamAndUserSettingsSlice";
import { useSetLeadStatusInvestigatingMutation } from "../../../../../slices/api/apiSlice";
import { Button, ButtonWrapper, FlexWrapper, Label, ModalContentContainer, ComponentHeader, SubHeader } from "../../../../../StyledComponents";

const InvestigateLead = ({ handleCloseInvestigateLead, userId, teamId, userFullName, memberData, mortgageId, leadData, dashPerspective, requestOrigin }) => {
  const theme = useTheme();

  const targetInterestRate = useSelector(selectTeamDefaultInterest)

  const [assigneeIds, setAssigneeIds] = useState(leadData.assigneeIds);
  const [targetOutcome, setTargetOutcome] = useState('renegotiation');

  const handleSetAssignee = (event) => {
    setAssigneeIds(event.target.value);
  };

  const [setStatusInvestigating, {isLoading, isSuccess, isError}] = useSetLeadStatusInvestigatingMutation()
  
  let handleSetInvestigating = async () => {
    let assigneeNames = [];
    for (let i = 0; i < assigneeIds.length; i++) {
      let teamMember = memberData.find(selected => selected.memberId === assigneeIds[i]);
      assigneeNames.push(teamMember.fullName);
    }
    await setStatusInvestigating({
      userId: userId,
      dashPerspective: dashPerspective,
      mortgageId: mortgageId,
      timeline: leadData.timeline,
      userFullName: userFullName,
      assigneeNames: assigneeNames,
      leadId: leadData._id,
      assigneeIds: assigneeIds,
      targetOutcome: targetOutcome,
      teamId: teamId,
      targetInterestRate: targetInterestRate,
      requestOrigin: requestOrigin,
    })
  }

  const handleTargetOutcome = (event) => {
    setTargetOutcome(event.target.value);
  }

  return (
    <>
      {isSuccess ?
        <ComponentHeader className="modal green"><CheckIcon style={{height: '2.5rem', width: '2.5rem', margin: '0 0.5rem 0.2rem 0'}} />Success</ComponentHeader>
      : isError ?
        <ComponentHeader className="modal red"><ErrorIcon style={{height: '2.5rem', width: '2.5rem', margin: '0 0.5rem 0.2rem 0'}} />failed... try again or contact support@lancastersweep.com</ComponentHeader>
      :
        <ComponentHeader className="modal">Open Investigation</ComponentHeader>
      }

      <ModalContentContainer className="centerColumn leadActions">
        {isSuccess ?
          <SubHeader className="modal" style={{margin: '0 auto 1.5rem auto'}}>
            {targetOutcome === "renegotiation" ?
              <span>Renegotiating this mortgage is now under investigation.</span>
            :
              <span>Refinancing this mortgage is now under investigation.</span>
            }
          </SubHeader>
        :
          <>
            <SubHeader className="modal" style={{margin: '0 auto 1.5rem auto'}}>
              <span>Select the target outcome and assign</span>
              <span>one or more team members to the investigation.</span>
            </SubHeader>
            <FlexWrapper style={{gridColumn: '1', gridRow: '3', height: 'fit-content', margin: '1rem 0'}}>
              <Stack style={{margin: '0 auto'}}>
                <ToggleButtonGroup
                  value={targetOutcome}
                  exclusive
                  onChange={handleTargetOutcome}
                  style={{height: '3rem', margin: '0 auto 1.5rem auto'}}
                >
                  <StyledToggleButton value="renegotiation">
                    RENEGOTIATION
                  </StyledToggleButton>
                  <StyledToggleButton value="refinance">
                    REFINANCE
                  </StyledToggleButton>
                </ToggleButtonGroup>
              </Stack>
            </FlexWrapper>
            <FlexWrapper className="column justifyStart alignStart fitContentWidth fitContentHeight" style={{width: '25rem', margin: '0 auto 1.5rem auto'}}>
              <Label style={{margin: '0 0 0 2rem'}}>Assignee(s)</Label>
              <AssigneeDropdown
                value={assigneeIds}
                handleChange={handleSetAssignee}
                items={memberData}
              />
            </FlexWrapper>
          </>
        }
        {isSuccess || isError ?
          <ButtonWrapper>
            <Button className="gray" onClick={() => handleCloseInvestigateLead()}>Close</Button>
          </ButtonWrapper>
        :
          <ButtonWrapper>
            {isLoading ?
              <Button className="primaryLoading" style={{margin: '2.5rem 0.5rem 0 0'}}>
                <CircularProgress
                  sx={{ color: `${theme.palette.common.white}` }}
                  size={18}
                />
              </Button>
            :
              <Button className="primary" onClick={() => handleSetInvestigating()} style={{margin: '2.5rem 0.5rem 0 0'}}>Confirm</Button>
            }
            <Button className="gray" onClick={() => handleCloseInvestigateLead()} style={{margin: '2.5rem 0 0 0.5rem'}}>Cancel</Button>
          </ButtonWrapper>
        }
      </ModalContentContainer>
    </>
  )
}

export default InvestigateLead;
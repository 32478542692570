import React from "react";
import Tooltip from '@mui/material/Tooltip';
import EmailIcon from '@mui/icons-material/Email';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTheme } from "@mui/styles";
import { CardIndex, CompareLink, Detail, DetailContainer, DetailHeaderWrapper, DetailInfoContainer, DetailListContainer } from "../../../../../../StyledComponents";

const NewLeads = ({ notification, handleStatusChange, handleOpenDeleteModal, cardIndex }) => {
  const theme = useTheme();

  return (
    <>
      <div style={{display: 'flex', minWidth: '25rem', maxWidth: '25rem'}}>
        <CardIndex style={{paddingRight: '1rem'}}>
          {cardIndex+1}
        </CardIndex>
        <DetailContainer className="column">
          <div style={{width: '6rem', margin: '0 0 0 0.5rem', display: 'flex', justifyContent: 'space-between'}}>
            {cardIndex === 0 ?
              <CompareLink autoFocus onClick={() => handleOpenDeleteModal()} style={{margin: '0'}} className="notification">
                <DeleteIcon style={{height: '1rem'}} />
              </CompareLink>
            :
              <CompareLink onClick={() => handleOpenDeleteModal()} style={{margin: '0'}} className="notification">
                <DeleteIcon style={{height: '1rem'}} />
              </CompareLink>
            }
            {notification.status !== "new" ?
              <Tooltip
                componentsProps={{tooltip: {sx: {color: `${theme.palette.common.white}`, backgroundColor: `${theme.palette.common.black}`}}}}
                title={<div><p style={{margin: "0.2rem"}}>Mark as "New"</p></div>} 
                placement="top"
                arrow
              >
                <CompareLink onClick={() => handleStatusChange(notification.status, true)} style={{margin: '0'}} className="notification">
                  <EmailIcon style={{height: '1rem'}} />
                </CompareLink>
              </Tooltip>
            :
              <></>
            }
          </div>
          <div style={{display: 'flex', margin: '0.5rem 0 0 0'}}>
            <DetailInfoContainer className="notificationTitle">
              <Detail className="subject">subject:</Detail>
            </DetailInfoContainer>
            <DetailInfoContainer className="notificationTitle">
              <Detail className="notificationTitle">New Public Records Scan</Detail>
            </DetailInfoContainer>
          </div>
          <div style={{display: 'flex'}}>
            <DetailInfoContainer className="notificationTitle">
              <Detail className="date">date:</Detail>
            </DetailInfoContainer>
            <DetailInfoContainer className="notificationTitle">
              <Detail className="date">{notification.date}</Detail>
            </DetailInfoContainer>
          </div>
        </DetailContainer>
      </div>
      <DetailContainer style={{minWidth: '25rem', maxWidth: '25rem'}}>
        <DetailHeaderWrapper className="simple" style={{margin: '0 0 0.3rem 0'}}>
          <Detail className="header" style={{gridColumn: '1'}}>Scan Overview</Detail>
        </DetailHeaderWrapper>
        <DetailListContainer>
          <DetailInfoContainer className="item">
            <Detail className="item">Mismatched Loans Discovered:</Detail>
            <Detail className="item">Total Discrepancies Detected:</Detail>
          </DetailInfoContainer>
          <DetailInfoContainer>
            <Detail>{notification.leadsDiscovered}</Detail>
            <Detail>{notification.discrepancyCount}</Detail>
          </DetailInfoContainer>
        </DetailListContainer>
      </DetailContainer>
      <DetailContainer style={{minWidth: '20rem', maxWidth: '20rem'}}>
        <DetailHeaderWrapper className="simple" style={{margin: '0 0 0.3rem 0'}}>
          <Detail className="header" style={{gridColumn: '1'}}>New Leads</Detail>
        </DetailHeaderWrapper>
        <DetailListContainer>
          <DetailInfoContainer className="item">
            <Detail className="tier one">Tier 1</Detail>
            <Detail className="tier two">Tier 2</Detail>
            <Detail className="tier three">Tier 3</Detail>
          </DetailInfoContainer>
          <DetailInfoContainer>
            <Detail className="tier">{notification.newTier1Count}</Detail>
            <Detail className="tier">{notification.newTier2Count}</Detail>
            <Detail className="tier">{notification.newTier3Count}</Detail>
          </DetailInfoContainer>
        </DetailListContainer>
      </DetailContainer>
      <DetailContainer style={{minWidth: '25rem', maxWidth: '25rem'}}>
        <DetailHeaderWrapper className="simple" style={{margin: '0 0 0.3rem 0'}}>
          <Detail className="header" style={{gridColumn: '1'}}>Leads with New Discrepancies</Detail>
        </DetailHeaderWrapper>
        <DetailListContainer>
          <DetailInfoContainer className="item">
            <Detail className="tier one">Upgraded Leads</Detail>
            <Detail className="tier two">Same Tier</Detail>
            <Detail className="tier three">Downgraded Leads</Detail>
          </DetailInfoContainer>
          <DetailInfoContainer>
            <Detail className="tier">{notification.upgradedLeads}</Detail>
            <Detail className="tier">{notification.updatedLeads}</Detail>
            <Detail className="tier">{notification.downgradedLeads}</Detail>
          </DetailInfoContainer>
        </DetailListContainer>
      </DetailContainer>
    </>
  )
}

export default NewLeads;
import React, { useState } from "react";
import Controls from "./components/Controls";
import { useTheme } from "@mui/styles";
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import NotificationCard from "./components/Card/NotificationCard";
import { selectAllUserNotifications } from "../../../../slices/userNotificationsSlice";
import { CardControlsContainer, CardListContainer, CardListHeader, PageContent } from "../../../../StyledComponents";

const Notifications = ({ userId }) => {
  const theme = useTheme();
  
  const userNotifications = useSelector(selectAllUserNotifications)

  const [notificationStatusFilter, setNotificationStatusFilter] = useState('All');
  const handleNotificationStatusFilterChange = (event) => {
    setNotificationStatusFilter(event.target.value);
  }

  return (
    <PageContent className="grid notifications">
      {notificationStatusFilter === 'All' ?
        <>
          <CardListHeader>Your Notifications</CardListHeader>
            {userNotifications !== undefined ? 
              <CardListContainer>
                {userNotifications.map((notification, index) => (
                  <NotificationCard key={index} cardIndex={index} notification={notification} userId={userId} />
                ))}
              </CardListContainer>
            :
              <CardListContainer className="loading">
                <CircularProgress
                  sx={{ color: `${theme.palette.primary.main}`}}
                  size={48}
                />
              </CardListContainer>
            }
        </>
      :
        <></>
      }
      <CardControlsContainer>
        <Controls notificationStatusFilter={notificationStatusFilter} handleNotificationStatusFilterChange={handleNotificationStatusFilterChange} />
      </CardControlsContainer>
    </PageContent>
  )
}

export default Notifications;
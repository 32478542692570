import React from "react";
import { useTheme } from "@mui/styles";
import FlagCircleIcon from '@mui/icons-material/FlagCircle';
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { MilestoneConnector, MilestonePathContainer } from "../../../StyledComponents";

const PathConnectors = ({milestone, mapIndex, timeline, origin}) => {
  const theme = useTheme()

  return (
    <>
      {milestone.milestone === 'Property Uploaded Successfully' ?
        <MilestonePathContainer>
          <div style={{margin: '0.4rem 0 0 0'}}>
            <RadioButtonCheckedIcon style={{color: milestone.notify ? `${theme.palette.primary.main}` : ''}} />
          </div>
          {(mapIndex + 1) !== timeline.length ?
              <MilestoneConnector />
            :
              <></>
          }
        </MilestonePathContainer>
      : milestone.milestone === 'Lead Discovered' || milestone.milestone === 'Manual Investigation' ?
        <MilestonePathContainer>
          {origin === 'lead' ?
            <div style={{margin: '0.3rem 0 0 0'}}>
              <FlagCircleIcon style={{color: milestone.notify ? `${theme.palette.primary.main}` : ''}} />
            </div>
          :
            <>
              <MilestoneConnector className='small' />
              <div style={{margin: '0.4rem 0 0 0'}}>
                <FlagCircleIcon style={{color: milestone.notify ? `${theme.palette.primary.main}` : ''}} />
              </div>
            </>
          }
          {(mapIndex + 1) !== timeline.length ?
              <MilestoneConnector />
            :
              <></>
          }
        </MilestonePathContainer>
      : milestone.milestone === 'Finalized' || milestone.milestone === 'Lead Dismissed' ?
        <MilestonePathContainer>
          <MilestoneConnector className='small' />
          <SportsScoreIcon style={{color: milestone.notify ? `${theme.palette.primary.main}` : ''}} />
        </MilestonePathContainer>
      :
        <MilestonePathContainer>
          <MilestoneConnector className='small' />
          <RadioButtonCheckedIcon style={{color: milestone.notify ? `${theme.palette.primary.main}` : ''}} />
          {(mapIndex + 1) !== timeline.length ?
              <MilestoneConnector />
            :
              <></>
          }
        </MilestonePathContainer>
      }
    </>
  )
}

export default PathConnectors
import React, { useState, useContext } from "react";
import { selectPageSelection, setPageSelection } from "../../../../../slices/pageSelectionSlice";
import { selectUserInitials, selectUserRequestCredentials, selectUserRole } from "../../../../../slices/userInfoSlice";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/styles";
import { AppState } from "../../../../../AppState";
import Logout from "@mui/icons-material/Logout";
import Avatar from "@mui/material/Avatar";
import MuiMenu from "@mui/material/Menu";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import GridViewIcon from '@mui/icons-material/GridView';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { FlexWrapper } from "../../../../../StyledComponents";

const Menu = ({ dataLoaded }) => {
  const theme = useTheme();
  const navigate = useNavigate();  
  const { signOut } = useContext(AppState);
  const dispatch = useDispatch()

  const reqCredentials = useSelector(selectUserRequestCredentials)
  const userInitials = useSelector(selectUserInitials)
  const userRole = useSelector(selectUserRole)
  const pageSelection = useSelector(selectPageSelection)
  
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePageSelection = (page) => {
    dispatch( setPageSelection(page) )
    navigate(`/${page}`)
  }

  return (
    <div style={{width: '2rem', height: '2rem'}}>
      {dataLoaded ?
        <FlexWrapper style={{ height: "2rem"}}>
          <Tooltip title={<p style={{fontSize: "1.2rem", margin: "0px"}}>Menu</p>}>
            <IconButton
              onClick={handleClick}
              size="small"
            >
            <Avatar sx={{ width: 30, height: 30, fontSize: "1.6rem", backgroundColor: `${theme.light.panel.secpmdaryBorder}`, fontWeight: "600", color: `${theme.light.panel.primaryText}` }}>
              {userInitials}
            </Avatar>
            </IconButton>
          </Tooltip>
        </FlexWrapper>
      :
        <FlexWrapper style={{ height: "2rem"}}>
          <Tooltip title={<p style={{fontSize: "1.2rem", margin: "0px"}}>Page is loading...</p>}>
            <IconButton
              size="small"
            >
            <Avatar sx={{ width: 30, height: 30, fontSize: "1.6rem", backgroundColor: `${theme.light.panel.secpmdaryBorder}`, fontWeight: "600", color: `${theme.light.panel.primaryText}` }}>
              {userInitials}
            </Avatar>
            </IconButton>
          </Tooltip>
        </FlexWrapper>
      }
      {userRole === 'super' && dataLoaded ?
        <MuiMenu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: 0,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          {pageSelection === 'dashboard' ?
            <MenuItem disabled style={{fontSize: "1.4rem", background: `${theme.palette.common.cardBackground}`}}>
              <ListItemIcon>
                <GridViewIcon fontSize="large" />
              </ListItemIcon>
              Dashboard
            </MenuItem>
          :
            <MenuItem onClick={() => handlePageSelection('dashboard')} style={{fontSize: "1.4rem", background: `${theme.palette.common.cardBackground}`}}>
              <ListItemIcon>
                <GridViewIcon fontSize="large" />
              </ListItemIcon>
              Dashboard
            </MenuItem>
          }
          {pageSelection === 'admin' ?
            <MenuItem disabled style={{fontSize: "1.4rem"}}>
              <ListItemIcon>
                <AdminPanelSettingsIcon fontSize="large" />
              </ListItemIcon>
              Admin
            </MenuItem>
          :
            <MenuItem onClick={() => handlePageSelection('admin')} style={{fontSize: "1.4rem", background: `${theme.palette.common.cardBackground}`}}>
              <ListItemIcon>
                <AdminPanelSettingsIcon fontSize="large" />
              </ListItemIcon>
              Admin
            </MenuItem>
          }
          <MenuItem onClick={() => signOut(reqCredentials)} style={{fontSize: "1.4rem"}}>
            <ListItemIcon>
              <Logout fontSize="large" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </MuiMenu>
      : dataLoaded ?
        <MuiMenu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: 0,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          {pageSelection === 'dashboard' ?
            <MenuItem disabled style={{fontSize: "1.4rem", background: `${theme.palette.common.cardBackground}`}}>
              <ListItemIcon>
                <GridViewIcon fontSize="large" />
              </ListItemIcon>
              Dashboard
            </MenuItem>
          :
            <MenuItem onClick={() => handlePageSelection('dashboard')} style={{fontSize: "1.4rem", background: `${theme.palette.common.cardBackground}`}}>
              <ListItemIcon>
                <GridViewIcon fontSize="large" />
              </ListItemIcon>
              Dashboard
            </MenuItem>
          }
          <MenuItem onClick={() => signOut(reqCredentials)} style={{fontSize: "1.4rem"}}>
            <ListItemIcon>
              <Logout fontSize="large" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </MuiMenu>
      :
        <></>
      }
    </div>
  )
}

export default Menu;
import React, { useEffect } from "react";
import Guide from "./components/Guide/Guide";
import NavBar from "./components/NavBar/NavBar";
import SideBar from "./components/SideBar/SideBar";
import Reports from "./components/Reports/Reports";
import IconLogo from '../../icon.logo.black.png';
import Settings from "./components/Settings/Settings";
import Insights from "./components/Insights/Insights";
import { Dialog } from "@mui/material";
import LoadingLogo from '../../icon.logo.green.png';
import ActiveLeads from "./components/ActiveLeads/ActiveLeads";
import WelcomeModal from "./components/WelcomeModal";
import Notifications from "./components/Notifications/Notifications";
import MortgageRepository from "./components/MortgageRepository/MortgageRepository";
import RefinancesOverview from "./components/RefinancesOverview/RefinancesOverview";
import { setPageSelection } from "../../slices/pageSelectionSlice";
import RenegotiationsOverview from "./components/RenegotiationsOverview/RenegotiationsOverview";
import { selectNavSelection } from "../../slices/navSelectionSlice";
import LeadGenerationOverview from "./components/LeadGenerationOverview/LeadGenerationOverview";
import { selectUserFullName } from "../../slices/userInfoSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectShowWelcomeModal, setShowWelcomeModal } from "../../slices/sessionDataSlice";
import { PageContainer, PageContentContainer, Footer, PageContentWrapper, ScreenSaverContainer, ImageElements, FlexWrapper } from "../../StyledComponents";

const Dashboard = ({ teamId, userId, page }) => {
  const dispatch = useDispatch()

  const navSelection = useSelector(selectNavSelection)
  const userFullName = useSelector(selectUserFullName)
  const showWelcomeModal = useSelector(selectShowWelcomeModal)

  const handleCloseWelcomeModal = () => {
    dispatch( setShowWelcomeModal(false) )
  }

  useEffect(() => (
    dispatch( setPageSelection(page) )
  ), [dispatch, page])

  return (
    <>
      <PageContainer className="dashboard">

        <ScreenSaverContainer className="login fadeOut">
          <ImageElements src={IconLogo} className="centerLogo opaque" />
          <ImageElements src={LoadingLogo} className="centerLogo loaded one" style={{right: '10rem'}} />
          <ImageElements src={LoadingLogo} className="centerLogo loaded two" style={{right: '10rem'}} />
          <ImageElements src={LoadingLogo} className="centerLogo loaded three" style={{right: '5rem'}} />
          <ImageElements src={LoadingLogo} className="centerLogo loaded four" style={{right: '5rem'}} />
          <ImageElements src={LoadingLogo} className="centerLogo loaded five" style={{}} />
          <ImageElements src={LoadingLogo} className="centerLogo loaded six" style={{}} />
          <ImageElements src={LoadingLogo} className="centerLogo loaded seven" style={{left: '5rem'}} />
          <ImageElements src={LoadingLogo} className="centerLogo loaded eight" style={{left: '5rem'}} />
          <ImageElements src={LoadingLogo} className="centerLogo loaded nine" style={{left: '10rem'}} />
          <ImageElements src={LoadingLogo} className="centerLogo loaded ten" style={{left: '10rem'}} />
        </ScreenSaverContainer>

        <NavBar />
        <SideBar userId={userId} />

        <PageContentContainer>
          <PageContentWrapper>
            {navSelection === 'refinancesOverview' ?
              <RefinancesOverview teamId={teamId} />
            : navSelection === 'renegotiationsOverview' ?
              <RenegotiationsOverview />
            : navSelection === 'leadGeneration' ?
              <LeadGenerationOverview />
            : navSelection === 'activeLeads' ?
              <ActiveLeads teamId={teamId} userId={userId} />
            : navSelection === 'mortgageRepository' ?
              <MortgageRepository userId={userId} teamId={teamId} />
            : navSelection === 'reports' ?
              <Reports userId={userId} />
            : navSelection === 'notifications' ?
              <Notifications userId={userId} />
            : navSelection === 'insights' ?
              <Insights />
            : navSelection === 'settings' ?
              <Settings teamId={teamId} userId={userId} userFullName={userFullName} />
            : navSelection === 'guide' ?
              <Guide />
            :
              <></>
            }
          </PageContentWrapper>
          <Footer><img src={IconLogo} style={{height: '100%', width: 'auto'}} />Lancaster Sweep<span style={{fontSize: '0.4rem', paddingBottom: '0.5rem'}}>TM</span></Footer>
        </PageContentContainer>
      </PageContainer>
    
      <Dialog open={showWelcomeModal} onClose={handleCloseWelcomeModal}
        PaperProps={{ sx: {width: "30%", minWidth: "40rem", maxWidth: "160rem", height: "fit-content", minHeight: "20rem", maxHeight: "95%"}}}
      >
        <WelcomeModal closeModal={handleCloseWelcomeModal} />
      </Dialog>
    </>
  )
}

export default Dashboard;
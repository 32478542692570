import React from "react";
import CancelIcon from '@mui/icons-material/Cancel';
import UploadErrors from "./components/UploadErrors";
import UploadSummary from "./components/UploadSummary";
import { useSelector } from "react-redux";
import UploadReportsNav from "./UploadReportsNav";
import { selectUploadReportNavSelection } from "../../../../../../../slices/navMenuSelectionsSlice";
import { ActionIconWrapper, ModalContainer, ComponentBorder } from "../../../../../../../StyledComponents";

const UploadReportsModal = ({ handleCloseUploadReportModal, viewingReport }) => {

  const navSelection = useSelector(selectUploadReportNavSelection)

  return (
    <ModalContainer className="twoPanel">
      <ComponentBorder className="top" style={{gridRow: '1', gridColumn: '1/5'}}>
        <ActionIconWrapper className="medium grayText circle hoverBorder hover" style={{margin: 'auto 2rem auto 0'}}>
          <CancelIcon onClick={() => handleCloseUploadReportModal()} style={{height: '100%', width: '100%'}} />  
        </ActionIconWrapper>
      </ComponentBorder>
      <UploadReportsNav />
      {navSelection === 'summary' ?
        <UploadSummary viewingReport={viewingReport} />
      : navSelection === 'errors' ?
        <UploadErrors rows={viewingReport.uploadErrors} />
      :
        <></>
      }
      <ComponentBorder className="left" style={{gridRow: '2/4', gridColumn: '1'}} />
      <ComponentBorder className="right" style={{gridRow: '2/4', gridColumn: '4'}} />
      <ComponentBorder className="bottom" style={{gridRow: '4', gridColumn: '1/5'}} />
    </ModalContainer>
  )
}

export default UploadReportsModal;
import { createSlice } from '@reduxjs/toolkit'
import { apiSlice } from './api/apiSlice'

const initialState = {
  data: {},
  status: 'idle',
  error: null
}

export const viewingLeadSlice = createSlice({
  name: 'viewingLead',
  initialState,
  reducers: {
    setViewingLead(state, action) {
      state.data = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
    .addMatcher(apiSlice.endpoints.resolveAllLeadDiscrepancies.matchFulfilled, (state, {payload}) => {
      console.log(payload.data)
      if (payload.data.mission === 'single') {
        if (payload.data.newLeads.length > 0) {
          state.data = payload.data.newLeads[0]
        }
      }
      console.log('here')
    })
    .addMatcher(apiSlice.endpoints.confirmPublicRecords.matchFulfilled, (state, {payload}) => {
      console.log(payload.data)
      state.data.timeline = payload.data.newTimeline
    })
    .addMatcher(apiSlice.endpoints.setLeadStatusFinalized.matchFulfilled, (state, {payload}) => {
      console.log(payload.data)
      if (payload.data.activeDiscrepancies > 0) {
        state.data = payload.data.newLead
      } else {
        state.data.timeline.push(payload.data.timelineAddition)
      }
    })
    .addMatcher(apiSlice.endpoints.addLeadTag.matchFulfilled, (state, {payload}) => {
      state.data = payload.data.newLead
    })
    .addMatcher(apiSlice.endpoints.addAssignees.matchFulfilled, (state, {payload}) => {
      state.data = payload.data.newLead
    })
    .addMatcher(apiSlice.endpoints.addAssignees.matchFulfilled, (state, {payload}) => {
      state.data = payload.data.newLead
    })
    .addMatcher(apiSlice.endpoints.addMortgageNote.matchFulfilled, (state, {payload}) => {
      state.data = payload.data.newLead
    })
    .addMatcher(apiSlice.endpoints.updateMortgageDetails.matchFulfilled, (state, {payload}) => {
      state.data = payload.data.newLead
    })
    .addMatcher(apiSlice.endpoints.saveLeadTargetUpdates.matchFulfilled, (state, {payload}) => {
      state.data = payload.data.newLead
    })
    .addMatcher(apiSlice.endpoints.setLeadStatusInvestigating.matchFulfilled, (state, {payload}) => {
      state.data = payload.data.newLead
    })
    .addMatcher(apiSlice.endpoints.setLeadStatusClosing.matchFulfilled, (state, {payload}) => {
      state.data = payload.data.newLead
    })
    .addMatcher(apiSlice.endpoints.changeTargetOutcome.matchFulfilled, (state, {payload}) => {
      state.data = payload.data.newLead
    })
  }
})

export const { setViewingLead } = viewingLeadSlice.actions

export default viewingLeadSlice.reducer

export const selectViewingLead = state => state.viewingLead.data
export const selectViewingLeadTimeline = state => state.viewingLead.data.timeline

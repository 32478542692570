import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: {
    dashboard: 'activeLeads',
    leadActions: '',
    mortgageActions: '',
    uploadReport: '',
    databaseActions: '',
    adminRepository: '',
  },
  status: 'idle',
  error: null
}

export const navMenuSelectionSlice = createSlice({
  name: 'navMenuSelection',
  initialState,
  reducers: {
    setDashboardNavMenuSelection(state, action) { state.data.dashboard = action.payload },
    setLeadActionNavMenuSelection(state, action) { state.data.leadActions = action.payload },
    setMortgageActionNavMenuSelection(state, action) { state.data.mortgageActions = action.payload },
    setUploadReportNavMenuSelection(state, action) { state.data.uploadReport = action.payload },
    setDatabaseActionsNavMenuSelection(state, action) { state.data.databaseActions = action.payload },
    setAdminRepositoryNavMenuSelection(state, action) { state.data.adminRepository = action.payload },
  },
})

export const { setDashboardNavMenuSelection, setLeadActionNavMenuSelection, setMortgageActionNavMenuSelection, setUploadReportNavMenuSelection, setDatabaseActionsNavMenuSelection, setAdminRepositoryNavMenuSelection } = navMenuSelectionSlice.actions

export default navMenuSelectionSlice.reducer

export const selectDashboardNavMenuSelection = state => state.navMenuSelection.data.dashboard
export const selectLeadActionsNavSelection = state => state.navMenuSelection.data.leadActions
export const selectMortgageActionsNavSelection = state => state.navMenuSelection.data.mortgageActions
export const selectUploadReportNavSelection = state => state.navMenuSelection.data.uploadReport
export const selectDatabaseActionsNavSelection = state => state.navMenuSelection.data.databaseActions
export const selectAdminRepositoryNavSelection = state => state.navMenuSelection.data.adminRepository

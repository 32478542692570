import { createSlice } from '@reduxjs/toolkit'
import { apiSlice } from './api/apiSlice'


const initialState = {
  data: {
    loading: false
  },
  status: 'idle',
  error: null
}

export const loadDataSlice = createSlice({
  name: 'loadData',
  initialState,
  reducers: {
    setDataIsLoaded(state, action) { 
      state.data.loading = action.payload 
    },
  },
  extraReducers: (builder) => {
    builder
    .addMatcher(apiSlice.endpoints.initialLoad.matchFulfilled, (state, {payload}) => {
      if (payload.data) {
        if (payload.data.missingUser) {
          state.data.loading = false
        } else {
          state.data.loading = true
        }
      }
    })
  }
})

export const { setDataIsLoaded } = loadDataSlice.actions

export default loadDataSlice.reducer

export const selectLoadData = state => state.loadData.data.loading
import React from "react";
import { useTheme } from "@mui/styles";
import { AccentInfo, Detail, DetailContainer, DetailInfoContainer, DetailListContainer, FlexWrapper } from "../../../StyledComponents";

const DiscrepanciesAllResolved = ({milestone}) => {
  const theme = useTheme()

  return (
    <DetailContainer className="fitContent">
      <Detail className="timelineHeader">{milestone.milestone}{milestone.notify ? <span style={{color: `${theme.palette.primary.main}`, margin: '0.25rem 0 0 0.5rem', fontWeight: '400'}}>new</span> : <></>}</Detail>
      <DetailListContainer className='timeline'>
        <DetailInfoContainer className="item timeline">
          <Detail className="timelineDetail">updated by:</Detail>
          <Detail className="timelineDetail">date:</Detail>
        </DetailInfoContainer>
        <DetailInfoContainer className="space">
          <Detail className="timelineItem">{milestone.contributor}</Detail>
          <Detail className="timelineItem">{milestone.date}</Detail>
        </DetailInfoContainer>
      </DetailListContainer>
      <DetailListContainer className='timeline column'>
        {milestone.details.map((detail, index) => (
          <FlexWrapper key={index}>
            <DetailInfoContainer className="item timeline" style={{minWidth: '16.5rem'}}>
              <Detail key={index}>{detail.label}:</Detail>
            </DetailInfoContainer>
            <DetailInfoContainer className="space">
              <div key={index} style={{textWrap: 'pretty'}}>
                {(detail.field.includes('Rate') || detail.field.includes('%')) && detail.old && detail.new  ?
                  <>
                    <span style={{fontWeight: '600'}}>%{detail.old.toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2})}</span> to <span style={{fontWeight: '600'}}>%{detail.new.toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2})}</span>
                  </>
                : detail.field.includes('AssessedValue') && detail.old && detail.new  ?
                  <>
                    <span style={{fontWeight: '600'}}>${detail.old.toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 0})}</span> to <span style={{fontWeight: '600'}}>${detail.new.toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 0})}</span>
                  </>
                : detail.old && detail.new ?
                  <>
                    <span style={{fontWeight: '600'}}>{detail.old}</span> to <span style={{fontWeight: '600'}}>{detail.new}</span>
                  </>
                : detail.old && !detail.new ?
                  <FlexWrapper>
                    <span style={{fontWeight: '600'}}>{detail.old}</span> <span style={{margin: 'auto 0.5rem'}}>to</span> <AccentInfo style={{minWidth: '4.1rem'}}>-empty-</AccentInfo>
                  </FlexWrapper>
                :
                  <FlexWrapper>
                    <AccentInfo style={{minWidth: '4.1rem'}}>-empty-</AccentInfo> <span style={{margin: 'auto 0.5rem'}}>to</span> <span style={{fontWeight: '600'}}>{detail.new}</span>
                  </FlexWrapper>
                }
              </div>
            </DetailInfoContainer>
          </FlexWrapper>
        ))}
      </DetailListContainer>
    </DetailContainer>
  )
}

export default DiscrepanciesAllResolved
import React from "react";
import { useTheme } from "@mui/styles";
import CircularProgress from "@mui/material/CircularProgress";
import { useChangeTargetOutcomeMutation } from "../../../../slices/api/apiSlice";
import { SubHeader, Button, ButtonWrapper, Divider, ModalContainer, ComponentHeader, ModalHeaderMessage, FlexWrapper } from "../../../../StyledComponents";

const ChangeTargetOutcome = ({ userId, userFullName, changeOutcomeDetails, dashPerspective }) => {
  const theme = useTheme();

  const [changeOutcome, {isFetching, isSuccess, isError}] = useChangeTargetOutcomeMutation()

  const handleChangeTargetOutcome = () => {
    changeOutcome({
      userId: userId,
      dashPerspective: dashPerspective,
      timeline: changeOutcomeDetails.timeline,
      userFullName: userFullName,
      newOutcome: changeOutcomeDetails.newOutcome,
      leadId: changeOutcomeDetails.leadId,
      userNotifications: changeOutcomeDetails.userNotifications,
    })
  }

  return (
    <ModalContainer style={{background: `${theme.palette.common.pageBackground}`}} className="leadActions">
      {isSuccess ?
        <>
          <ModalHeaderMessage className="green" style={{fontSize: '1.2rem', margin: '0 0 0 0.5rem'}}>success</ModalHeaderMessage>
          <Divider className='green' style={{margin: '0 0 0 0'}} />
        </>
      : isError ?
        <>
          <ModalHeaderMessage className="red" style={{fontSize: '1.2rem', margin: '0 0 0 0.5rem'}}>failed</ModalHeaderMessage>
          <Divider className='red' style={{margin: '0 0 0 0'}} />
        </>
      :
        <>
          <ComponentHeader style={{margin: "0 auto", fontSize: '1.2rem', color: `${theme.palette.common.grayText}`}}>Change Target Outcome?</ComponentHeader>
          <Divider style={{margin: '0 0 0 0'}}/>
        </>
      }
      {isError ?
        <SubHeader className="modal" style={{margin: '0 0 0 0'}}>
          <span>Something has gone wrong. Please contact solutions@lancastersweep.com if the proplem persists</span>
        </SubHeader>
      :
        <></>
      }
      {isSuccess || isError ?
        <></>
      : isFetching ?
        <ButtonWrapper className="justifyCenter" style={{margin: '2rem auto 0 auto'}}>
          <Button className="primaryLoading" >
            <CircularProgress
              sx={{ color: `${theme.palette.common.white}` }}
              size={18}
            />
          </Button>
        </ButtonWrapper>
      :
        <ButtonWrapper className="justifyCenter" style={{margin: '2rem auto 0 auto'}}>
          {isFetching ?
            <Button autoFocus className="primaryDisabled">Confirm</Button>
          :
            <Button autoFocus className="primary" onClick={() => handleChangeTargetOutcome()}>Confirm</Button>
          }
        </ButtonWrapper>
      }
    </ModalContainer>
  )
}

export default ChangeTargetOutcome;
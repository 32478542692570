import React from "react";
import { useTheme } from "@mui/styles";
import { AccentInfo, Detail, DetailContainer, DetailListContainer, FlexWrapper } from "../../../StyledComponents";

const NewDiscrepanciesDiscovered = ({milestone}) => {
  const theme = useTheme()

  return (
    <DetailContainer className="fitContent" style={{padding: '0.5rem 0 0 0'}}>
      <Detail className="timelineHeader">{milestone.milestone}{milestone.notify ? <span style={{color: `${theme.palette.primary.main}`, margin: '0.25rem 0 0 0.5rem', fontWeight: '400'}}>new</span> : <></>}</Detail>
      <div>
        <Detail style={{margin: '0 0 0 1rem'}}>{milestone.date}</Detail>
        {milestone.tier1Discrepancies.length > 0 || milestone.tier2Discrepancies.length > 0 || milestone.tier3Discrepancies.length > 0 ?
          <>
            <Detail className="timelineDetail" style={{margin: '0.5rem 0 0.5rem 0', fontWeight: '400', gridRow: '2'}}>Discrepancies Identified:</Detail>
            <DetailListContainer className="timeline column" style={{gridRow: '3'}}>
              {milestone.tier1Discrepancies.map((discrepancy, index) => (
                discrepancy.publicRecordValue !== null ?
                  <FlexWrapper key={index} className="alignCenter">
                    <div style={{width: '19rem', display: 'flex', justifyContent: 'flex-end'}}>
                      <Detail className="timelineDetail tier one">{discrepancy.label}</Detail>
                    </div>
                    <Detail style={{padding: '0 0 0 0.3rem'}}>{discrepancy.publicRecordValue}</Detail>
                  </FlexWrapper>
                :
                  <FlexWrapper key={index}>
                    <Detail className="timelineDetail tier one">{discrepancy.label}</Detail>
                    <Detail><AccentInfo>-empty-</AccentInfo></Detail>
                  </FlexWrapper>
              ))}
              {milestone.tier2Discrepancies.map((discrepancy, index) => (
                discrepancy.publicRecordValue !== null ?
                  <FlexWrapper key={index} className="alignCenter">
                    <div style={{width: '19rem', display: 'flex', justifyContent: 'flex-end'}}>
                      <Detail className="timelineDetail tier two">{discrepancy.label}</Detail>
                    </div>
                    <Detail style={{padding: '0 0 0 0.3rem'}}>{discrepancy.publicRecordValue}</Detail>
                  </FlexWrapper>
                :
                  <FlexWrapper key={index}>
                    <Detail className="timelineDetail tier one">{discrepancy.label}</Detail>
                    <Detail><AccentInfo>-empty-</AccentInfo></Detail>
                  </FlexWrapper>
              ))}
              {milestone.tier3Discrepancies.map((discrepancy, index) => (
                discrepancy.publicRecordValue !== null ?
                  <FlexWrapper key={index} className="alignCenter">
                    <div style={{width: '19rem', display: 'flex', justifyContent: 'flex-end'}}>
                      <Detail className="timelineDetail tier three">{discrepancy.label}</Detail>
                    </div>
                    <Detail style={{padding: '0 0 0 0.3rem'}}>{discrepancy.publicRecordValue}</Detail>
                  </FlexWrapper>
                :
                  <FlexWrapper key={index}>
                    <Detail className="timelineDetail tier one">{discrepancy.label}</Detail>
                    <Detail><AccentInfo>-empty-</AccentInfo></Detail>
                  </FlexWrapper>
              ))}
            </DetailListContainer>
          </>
        :
          <></>
        }
        {milestone.newLeadTags.length > 0 ?
          milestone.newLeadTags.length > 1 ?
            <>
              <Detail><AccentInfo>New Lead Tags</AccentInfo></Detail>
              <DetailListContainer>
                {milestone.newLeadTags.map((leadTag, index) => (
                  <FlexWrapper key={index} className="column">
                    <div style={{width: '19rem', display: 'flex', justifyContent: 'flex-end'}}>
                      <Detail className="timelineDetail tier three">{leadTag.label}</Detail>
                    </div>
                    <FlexWrapper key={index}>
                      {leadTag.discrepancyFields.map((field, index) => (
                        index === (leadTag.discrepancyFields.length - 1) ?
                          <AccentInfo key={index}>{field}</AccentInfo>
                        :
                          <AccentInfo key={index}>{field},</AccentInfo>
                      ))}
                    </FlexWrapper>
                  </FlexWrapper>
                ))}
              </DetailListContainer>
            </>
          :
            <>
              <Detail><AccentInfo>New Lead Tag</AccentInfo></Detail>
              <DetailListContainer>
                <FlexWrapper className="column">
                  <div style={{width: '19rem', display: 'flex', justifyContent: 'flex-end'}}>
                    <Detail className="timelineDetail tier three">{milestone.newLeadTags[0].label}</Detail>
                  </div>
                  <FlexWrapper>
                    {milestone.newLeadTags[0].discrepancyFields.map((field, index) => (
                      index === (milestone.newLeadTags[0].discrepancyFields.length - 1) ?
                        <AccentInfo key={index}>{field}</AccentInfo>
                      :
                        <AccentInfo key={index}>{field},</AccentInfo>
                    ))}
                  </FlexWrapper>
                </FlexWrapper>
              </DetailListContainer>
            </>
            
        :
          <></>
        }
        {milestone.newMortgageTags.length > 0 ?
          milestone.newMortgageTags.length > 1 ?
            <>
              <Detail><AccentInfo>New Mortgage Tags</AccentInfo></Detail>
              <DetailListContainer>
                {milestone.newMortgageTags.map((mortgageTag, index) => (
                  <FlexWrapper key={index} className="column">
                    <div style={{width: '19rem', display: 'flex', justifyContent: 'flex-end'}}>
                      <Detail className="timelineDetail tier three">{mortgageTag.label}</Detail>
                    </div>
                    <FlexWrapper key={index}>
                      {mortgageTag.discrepancyFields.map((field, index) => (
                        index === (mortgageTag.discrepancyFields.length - 1) ?
                          <AccentInfo key={index}>{field}</AccentInfo>
                        :
                          <AccentInfo key={index}>{field},</AccentInfo>
                      ))}
                    </FlexWrapper>
                  </FlexWrapper>
                ))}
              </DetailListContainer>
            </>
          :
            <>
              <Detail><AccentInfo>New Mortgage Tag</AccentInfo></Detail>
              <DetailListContainer>
                <FlexWrapper className="column">
                  <div style={{width: '19rem', display: 'flex', justifyContent: 'flex-end'}}>
                    <Detail className="timelineDetail tier three">{milestone.newMortgageTags[0].label}</Detail>
                  </div>
                  <FlexWrapper>
                    {milestone.newMortgageTags[0].discrepancyFields.map((field, index) => (
                      index === (milestone.newMortgageTags[0].discrepancyFields.length - 1) ?
                        <AccentInfo key={index}>{field}</AccentInfo>
                      :
                        <AccentInfo key={index}>{field},</AccentInfo>
                    ))}
                  </FlexWrapper>
                </FlexWrapper>
              </DetailListContainer>
            </>
            
        :
          <></>
        }
      </div>
    </DetailContainer>
  )
}

export default NewDiscrepanciesDiscovered
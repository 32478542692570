import React from "react";
import GroupsIcon from '@mui/icons-material/Groups';
import { useTheme } from "@mui/styles";
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import CopyrightIcon from '@mui/icons-material/Copyright';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import BorderOuterIcon from '@mui/icons-material/BorderOuter';
import ConstructionIcon from '@mui/icons-material/Construction';
import ArchitectureIcon from '@mui/icons-material/Architecture';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { FlexWrapper, GuideText } from "../../../../../StyledComponents";

const LeadOverview = () => {
  const theme = useTheme()

  return (
    <>
      <FlexWrapper className="column" style={{padding: '2rem 3rem 0 3rem'}}>
        <FlexWrapper>
          <GuideText className="large">
            Violation Detection with Lancaster Sweep
          </GuideText>
          <div style={{height: '2rem'}}>
            <CopyrightIcon />
          </div>
        </FlexWrapper>
        <GuideText className="medium">
          monitors your mortgages to provide your team with early notice of contractual violations.
        </GuideText>
      </FlexWrapper>
      <FlexWrapper className="column" style={{padding: '0.5rem 3rem'}}>
        <GuideText className="sub">
          * Public records are swept on a monthly basis. The date of the "Last Record Sweep" is shown at the bottom of the sidebar.
        </GuideText>
      </FlexWrapper>

      <FlexWrapper className="column" style={{padding: '2.5rem 3rem'}}>
        <GuideText className="large">
          Maintain property oversight with up to date information.
        </GuideText>
        <GuideText className="medium">
          All changes found in the public records are reflected in your mortgage data.
        </GuideText>
        <GuideText className="small">
          If a situation requires attention, an "Active Lead" will be created and your team will be notified via email.
        </GuideText>
        <GuideText className="small">
          Previous and current states are retained so you can compare differences and better understand the situation.
        </GuideText>
        <GuideText className="small">
          A two-tiered system is used to denote urgency, and tags are assigned to easily identify and group leads.
        </GuideText>
      </FlexWrapper>

      <div style={{width: '80%', margin: 'auto', minHeight: '0.1rem', background: `${theme.palette.common.grayBorder}`}} />

      <FlexWrapper className="column" style={{padding: '2rem 3rem 1.5rem 3rem'}}>
        <GuideText className="medium">
          A specific set of fields are swept for discrepancies, with each field pointing to an assigned Tier.
        </GuideText>
        <GuideText className="small">
          The "Settings" component shows the current configuration, but this can be customized at any time by contacting your App Admin.
        </GuideText>
        <GuideText className="small">
          Metrics on the discrepancies that lead to actionable outcomes are recorded in order to optimize the system's performance over time.
        </GuideText>
      </FlexWrapper>

      <FlexWrapper className="column" style={{padding: '1rem 3rem 0 3rem'}}>
        <GuideText className="header large one">
          Tier 1
        </GuideText>
        <GuideText className="small">
          Tier 1 leads are created when a likely violation is detected.
        </GuideText>
      </FlexWrapper>
      <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr'}}>
        <FlexWrapper className="alignCenter justifyCenter" style={{gridColumn: '1', fontFamily: 'Gill Sans, sans-serif', fontWeight: '400', fontSize: '1.8rem', color: `${theme.palette.common.black}`, width: '100%'}}>
          <GroupsIcon style={{height: '4rem', width: '4rem', margin: '0 1rem'}} /> Ownership changes
        </FlexWrapper>
        <FlexWrapper className="alignCenter justifyCenter" style={{gridColumn: '2', fontFamily: 'Gill Sans, sans-serif', fontWeight: '400', fontSize: '1.8rem', color: `${theme.palette.common.black}`, width: '100%'}}>
          <ArchitectureIcon style={{height: '4rem', width: '4rem', margin: '0 1rem'}} /> Rezoning
        </FlexWrapper>
        <FlexWrapper className="alignCenter justifyCenter" style={{gridColumn: '3', fontFamily: 'Gill Sans, sans-serif', fontWeight: '400', fontSize: '1.8rem', color: `${theme.palette.common.black}`, width: '100%'}}>
          <AccountBalanceIcon style={{height: '4rem', width: '4rem', margin: '0 1rem'}} /> New Mortgage
        </FlexWrapper>
      </div>
      <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', padding: '1rem 0 0 0'}}>
        <FlexWrapper className="alignCenter justifyCenter" style={{gridColumn: '1', fontFamily: 'Gill Sans, sans-serif', fontWeight: '400', fontSize: '1.8rem', color: `${theme.palette.common.black}`, width: '100%'}}>
          <LocalOfferIcon style={{height: '4rem', width: '4rem', margin: '0 1rem'}} /> Listed for Sale
        </FlexWrapper>
        <FlexWrapper className="alignCenter justifyCenter" style={{gridColumn: '2', fontFamily: 'Gill Sans, sans-serif', fontWeight: '400', fontSize: '1.8rem', color: `${theme.palette.common.black}`, width: '100%'}}>
          <GroupsIcon style={{height: '4rem', width: '4rem', margin: '0 1rem'}} /> Ownership changes
        </FlexWrapper>
        <FlexWrapper className="alignCenter justifyCenter" style={{gridColumn: '3', fontFamily: 'Gill Sans, sans-serif', fontWeight: '400', fontSize: '1.8rem', color: `${theme.palette.common.black}`, width: '100%'}}>
          <AccountBalanceIcon style={{height: '4rem', width: '4rem', margin: '0 1rem'}} /> New Mortgage
        </FlexWrapper>
      </div>

      <FlexWrapper className="column" style={{padding: '2rem 3rem 0 3rem'}}>
        <GuideText className="header large two">
          Tier 2
        </GuideText>
        <div style={{fontFamily: 'Gill Sans, sans-serif', fontWeight: '400', fontSize: '1.4rem', color: `${theme.palette.common.black}`}}>
          Tier 2 leads are created when detected changes show a potential for significant changes in property value.
        </div>
      </FlexWrapper>
      <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr'}}>
        <FlexWrapper className="alignCenter justifyCenter" style={{gridColumn: '1', fontFamily: 'Gill Sans, sans-serif', fontWeight: '400', fontSize: '1.8rem', color: `${theme.palette.common.black}`, width: '100%'}}>
          <ConstructionIcon style={{height: '4rem', width: '4rem', margin: '0 1rem'}} /> Improvements
        </FlexWrapper>
        <FlexWrapper className="alignCenter justifyCenter" style={{gridColumn: '2', fontFamily: 'Gill Sans, sans-serif', fontWeight: '400', fontSize: '1.8rem', color: `${theme.palette.common.black}`, width: '100%'}}>
          <DomainAddIcon style={{height: '4rem', width: '4rem', margin: '0 1rem'}} /> Units Added/Removed
        </FlexWrapper>
        <FlexWrapper className="alignCenter justifyCenter" style={{gridColumn: '3', fontFamily: 'Gill Sans, sans-serif', fontWeight: '400', fontSize: '1.8rem', color: `${theme.palette.common.black}`, width: '100%'}}>
          <BorderOuterIcon style={{height: '4rem', width: '4rem', margin: '0 1rem'}} /> Subdivision
        </FlexWrapper>
      </div>

      <FlexWrapper className="column" style={{padding: '2rem 3rem 2rem 3rem'}}>
        <GuideText className="header large three">
          Tier 3
        </GuideText>
        <div style={{fontFamily: 'Gill Sans, sans-serif', fontWeight: '400', fontSize: '1.4rem', color: `${theme.palette.common.black}`}}>
          Tier 3 discrepancies are inconsequential changes. Records are updated without creating a lead. 
        </div>
      </FlexWrapper>

      <div style={{width: '80%', margin: 'auto', minHeight: '0.1rem', background: `${theme.palette.common.grayBorder}`}} />

    </>
  )
}

export default LeadOverview
import React from "react";
import { useTheme } from "@mui/styles";
import { FlexWrapper, GuideText } from "../../../../../StyledComponents";

const PerformanceGuide = () => {
  const theme = useTheme()

  return (
    <>
      <FlexWrapper className="column" style={{padding: '0 3rem'}}>
        <GuideText className="header large" style={{padding: '2rem 0 0 0'}}>
          "Performance" Components
        </GuideText>
        <GuideText className="medium" style={{padding: '1rem 0 0 0'}}>
          Detailed metrics are kept when using the system to manage your mortgage records.
        </GuideText>
        <GuideText className="small" style={{padding: '0.5rem 0 0 0'}}>
          Statistics are presented across months, quarters, and totals in order to observe the system's performance over time.
        </GuideText>
        <GuideText className="sub" style={{padding: '0.5rem 0 0 0'}}>
          * Contact your App Admin to customize the information displayed in these components.
        </GuideText>
      </FlexWrapper>
    </>
  )
}

export default PerformanceGuide
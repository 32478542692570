import { createTheme, responsiveFontSizes } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: { 
      main: "#00B812",
      mainTransparent: "#00B812BF",
      medium: "#00C914",
      light: "#00DB16",
      lightest: "#00EB17",
      dark: "#00940F",
      darker: "#00820D",
      darkShadow: "#00940FA5",
      transparent: "#00B81223",
      transparentHover: "#00B81284",
      labelTransparent: "rgb(19, 194, 37, 0.1)",
      pastelDark: "#306B36",
      pastelMedium: "#4CA855",
      pastelLight: "#ADE6B2",
      buttonColor: "#fff",
    },
    secondary: { 
      main: "#B486BF",
      mainTransparent: "#B487C0BF",
      light: "#E0CAE6",
      lightest: "#F1DAF7",
      dark: "#91679C",
      labelTransparent: "#91679C12",
      pastelDark: "#806387",
      pastelMedium: "#A982B3",
      pastelLight: "#DEABEB",
    },
    tertiary: {
      pastelDark: "#556A9E",
      pastelMedium: "#6E88CC",
      pastelLight: "#A0B7F2",
    },
    common: {
      offWhite: '#F2F2F2',
      pageBackground: "#F7F7F7",
      disabledGray: '#EBEBEBE0',
      cardBackground: "#ffffff",
      grayButtonFocus: "#D4D6DB",
      grayButtonFocusLight: "#EDEFF5",
      grayBorder: "#E3E5EB",
      grayBorderLight: "#EAECF2",
      grayBorderMedium: "#D4D6DB",
      grayBorderDark: "#BDBFC4",
      grayText: "#939599",
      grayDark: "#666C78",
      grayOpaque: "#93959921",
      grayTextLight: "#AEB0B5",
      grayHover: `#F2F2F2`,
      lightGray: `#F7F7F7`,
      purpleSelected: '#4B14752B',
      purpleSelectedHover: '#4B147533',
      purpleHover: '#4B147544',
      purpleBackground: '#F4F0F7',
      // black: "#414042",
      // black: "rgb(65, 64, 66)",
      black: "#1C1C1F",
      // black: "rgb(28, 28, 31)",
      blackLight: '#5A595C',
      white: "#fff",
      error: "#c71031",
      errorDark: "#A60D29",
      errorRow: "#EBDBDE",
      errorRowDark: "#EBCCD2",
      highlight: "#FFD54D1E",
      highlightHover: "#FFD54D3F",
      blue: "#01538F",
      red: "#d7372f",
      redTransparent: "#d7372f12",
      grayTransparent: "rgba(215, 220, 223, 0.2)",
      redDark: "#BF3029",
      redBright: "#FF413B",
      orange: "#F89500",
      orangeDark: "#D98200",
      orangeTransparent: "#F8EBD8",
      yellow: "#e9e639",
      activeWhite: "#E6E6E6DB",
      caution: "#F89500",
      cautionDark: "#E68A00",
      cautionDarkest: "#CC7B00",
    },
    logo: {
      teal: "#bbd5d4",
    },
  },
  light: {
    accent: {
      primary: '#00940F',
      primaryMedium: '#127A1D',
      cautionDark: '#CC7B00',
      error: '#c71031',
    },
    closures: {
      closure: '#00B812',
      dismissal: '#B486BF',
      refinances: "#00B812",
      renegotiation: "#6e88cc",
      renegotiationFullOpacity: "#E3EBFF",
    },
    tier1: {
      primary: "#BF3029",
    },
    tier2: {
      primary: '#CAABD1',
    },
    panel: {
      primaryBackground: '#272a2e',
      primaryBorder: '#72757a',
      secondaryBackground: '#9da1a8',
      secondaryBorder: '#c8cbcf',
      tertiaryBackground: '#e1e4e8',
      primaryText: '#FFF',
      secondaryText: '#9da1a8',
      
    },
    branding: {
      background: '#272a2e',
      border: '#72757a',
    },
    component: {
      border: '#BDBFC4',
      background: '#E3E5EB',
    },
    button: {
      primaryBackground: '#1FA32C',
      primaryBackgroundHover: '#228A2D',
      primaryBackgroundDisabled: '#214D26',
      primaryDisabledText: '#DBCDC1',
      primaryHoverText: '#FFFCFA',
      primaryHoverBorder: '#FFF3EB',
      secondaryBackground: '#BDBFC4',
      secondaryBackgroundHover: '#919396',
      actionColor: '#939599',
      actionColorActive: '#E68A00',
      actionBackground: '#E3E5EB',
      actionBackgroundShadow: '#D4D6DB',
      actionBackgroundActive: '#F1F4FA',
      actionBackgroundShadowActive: '#E1E3E8',
    },
    input: {
      authBorder: '#00940F',
    },
    auth: {
      containerBackground: 'rgb(28, 28, 31, 0.7)',
    }
  }
});

export default responsiveFontSizes(theme);

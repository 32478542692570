import { createSlice } from '@reduxjs/toolkit'
import { apiSlice } from './api/apiSlice'

const initialState = {
  data: {
    queryPerformances: [],
    uploadReports: [],
    actionsAndErrorsLog: [],
    database: {
      team: {},
      users: [],
      reportIds: [],
      notificationSchedules: [],
      publicRecords: [],
      monthlyStats: [],
    },
  },
  status: 'idle',
  error: null
}

export const teamAdminDataSlice = createSlice({
  name: 'teamAdminData',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addMatcher(apiSlice.endpoints.initialLoad.matchFulfilled, (state, {payload}) => {
      if (payload.data) {
        state.data = payload.data.teamAdminData
        if (payload.data.newLog) {
          state.data.actionsAndErrorsLog.push(payload.data.newLog)
        }
        if (payload.data.team) {
          state.data.database.team = payload.data.team
        }
        if (payload.data.users) {
          state.data.database.users = payload.data.users
        }
      }
    })
    .addMatcher(apiSlice.endpoints.uploadCSVMortgages.matchFulfilled, (state, {payload}) => {
      if (payload.data.newUploadReport) {
        state.data.uploadReports.push(payload.data.newUploadReport)
      }
      if (payload.data.newLog) {
        state.data.actionsAndErrorsLog.push(payload.data.newLog)
      }
      if (payload.data.newMortgages) {
        for (let i = 0; i < payload.data.newMortgages.length; i++) {
          state.data.database.publicRecords.push({mortgageId: payload.data.newMortgages[i]._id, publicRecords: []})
        }
      }
    })
    .addMatcher(apiSlice.endpoints.deleteUploadReport.matchFulfilled, (state, {payload}) => {
      if (payload.data.reportId) {
        let thisReport = state.data.uploadReports.find(report => report._id === payload.data.reportId);
        let thisIndex = state.data.uploadReports.indexOf(thisReport)
        state.data.uploadReports.splice(thisIndex, 1)
      }
      if (payload.data.newLog) {
        state.data.actionsAndErrorsLog.push(payload.data.newLog)
      }
    })
    //* Actions And Errors Logs
    //TODO: only return logs from api if user is super
    .addMatcher(apiSlice.endpoints.deleteActionOrErrorLog.matchFulfilled, (state, {payload}) => {
      if (payload.data.logId) {
        let thisLog = state.data.actionsAndErrorsLog.find(log => log._id === payload.data.logId)
        let thisIndex = state.data.actionsAndErrorsLog.indexOf(thisLog)
        state.data.actionsAndErrorsLog.splice(thisIndex, 1)
      }
    })
    .addMatcher(apiSlice.endpoints.addLeadTag.matchFulfilled, (state, {payload}) => {
      if (payload.data.newLog) {
        state.data.actionsAndErrorsLog.push(payload.data.newLog)
      }
    })
    .addMatcher(apiSlice.endpoints.resolveAllLeadDiscrepancies.matchFulfilled, (state, {payload}) => {
      console.log(payload.data)
      if (payload.data.newLog) {
        state.data.actionsAndErrorsLog.push(payload.data.newLog)
      }
      console.log('here')
    })
    .addMatcher(apiSlice.endpoints.deleteMortgage.matchFulfilled, (state, {payload}) => {
      if (payload.data.newLog) {
        state.data.actionsAndErrorsLog.push(payload.data.newLog)
      }
    })
    .addMatcher(apiSlice.endpoints.checkForMortgageDuplicates.matchFulfilled, (state, {payload}) => {
      if (payload.data.newLog) {
        state.data.actionsAndErrorsLog.push(payload.data.newLog)
      }
    })
    .addMatcher(apiSlice.endpoints.deleteUserNewAssignmentNotification.matchFulfilled, (state, {payload}) => {
      if (payload.data.newLog) {
        state.data.actionsAndErrorsLog.push(payload.data.newLog)
      }
    })
    .addMatcher(apiSlice.endpoints.deleteUserLeadNotification.matchFulfilled, (state, {payload}) => {
      if (payload.data.newLog) {
        state.data.actionsAndErrorsLog.push(payload.data.newLog)
      }
    })
    .addMatcher(apiSlice.endpoints.removeReportNotifyUser.matchFulfilled, (state, {payload}) => {
      if (payload.data.newLog) {
        state.data.actionsAndErrorsLog.push(payload.data.newLog)
      }
    })
    .addMatcher(apiSlice.endpoints.updateTeamDefaultTargetParameters.matchFulfilled, (state, {payload}) => {
      if (payload.data.newLog) {
        state.data.actionsAndErrorsLog.push(payload.data.newLog)
      }
    })
    .addMatcher(apiSlice.endpoints.updateTeamDiscrepancyParamterSelection.matchFulfilled, (state, {payload}) => {
      if (payload.data.newLog) {
        state.data.actionsAndErrorsLog.push(payload.data.newLog)
      }
    })
    .addMatcher(apiSlice.endpoints.recordSweep.matchFulfilled, (state, {payload}) => {
      if (payload.data.newLog) {
        state.data.actionsAndErrorsLog.push(payload.data.newLog)
      }
      if (payload.data.newQueryPerformance) {
        state.data.queryPerformances.push(payload.data.newQueryPerformance)
      }
      if (payload.data.newPublicRecords) {
        for (let i = 0; i < payload.data.newPublicRecords.length; i++) {
          let thisMortgage = state.data.database.publicRecords.find(mortgage => mortgage.mortgageId === payload.data.newPublicRecords[i].mortgageId)
          thisMortgage.publicRecords.push(payload.data.newPublicRecords[i].recordId)
        }
      }
    })
    .addMatcher(apiSlice.endpoints.updateNotificationStatus.matchFulfilled, (state, {payload}) => {
      if (payload.data.newLog) {
        state.data.actionsAndErrorsLog.push(payload.data.newLog)
      }
    })
    .addMatcher(apiSlice.endpoints.deleteNotification.matchFulfilled, (state, {payload}) => {
      if (payload.data.newLog) {
        state.data.actionsAndErrorsLog.push(payload.data.newLog)
      }
    })
    .addMatcher(apiSlice.endpoints.addMortgageNote.matchFulfilled, (state, {payload}) => {
      if (payload.data.newLog) {
        state.data.actionsAndErrorsLog.push(payload.data.newLog)
      }
    })
    .addMatcher(apiSlice.endpoints.updateMortgageDetails.matchFulfilled, (state, {payload}) => {
      if (payload.data.newLog) {
        state.data.actionsAndErrorsLog.push(payload.data.newLog)
      }
    })
    .addMatcher(apiSlice.endpoints.dismissLead.matchFulfilled, (state, {payload}) => {
      if (payload.data.newLog) {
        state.data.actionsAndErrorsLog.push(...payload.data.newLog)
      }
    })
    .addMatcher(apiSlice.endpoints.setLeadStatusInvestigating.matchFulfilled, (state, {payload}) => {
      if (payload.data.newLog) {
        state.data.actionsAndErrorsLog.push(payload.data.newLog)
      }
    })
    .addMatcher(apiSlice.endpoints.setLeadStatusClosing.matchFulfilled, (state, {payload}) => {
      if (payload.data.newLog) {
        state.data.actionsAndErrorsLog.push(payload.data.newLog)
      }
    })
    .addMatcher(apiSlice.endpoints.setLeadStatusFinalized.matchFulfilled, (state, {payload}) => {
      console.log(payload.data)
      if (payload.data.newLog) {
        state.data.actionsAndErrorsLog.push(payload.data.newLog)
      }
    })
    .addMatcher(apiSlice.endpoints.confirmPublicRecords.matchFulfilled, (state, {payload}) => {
      console.log(payload.data)
      if (payload.data.newLog) {
        state.data.actionsAndErrorsLog.push(payload.data.newLog)
      }
    })
    .addMatcher(apiSlice.endpoints.saveLeadTargetUpdates.matchFulfilled, (state, {payload}) => {
      if (payload.data.newLog) {
        state.data.actionsAndErrorsLog.push(payload.data.newLog)
      }
    })
    .addMatcher(apiSlice.endpoints.changeTargetOutcome.matchFulfilled, (state, {payload}) => {
      if (payload.data.newLog) {
        state.data.actionsAndErrorsLog.push(payload.data.newLog)
      }
    })
    .addMatcher(apiSlice.endpoints.addAssignees.matchFulfilled, (state, {payload}) => {
      if (payload.data.newLog) {
        state.data.actionsAndErrorsLog.push(payload.data.newLog)
      }
    })
    .addMatcher(apiSlice.endpoints.createNewTeamLoanType.matchFulfilled, (state, {payload}) => {
      if (payload.data.newLog) {
        state.data.actionsAndErrorsLog.push(payload.data.newLog)
      }
    })
    .addMatcher(apiSlice.endpoints.updateSweepParamterSettings.matchFulfilled, (state, {payload}) => {
      if (payload.data.newLog) {
        state.data.actionsAndErrorsLog.push(payload.data.newLog)
      }
    })
    .addMatcher(apiSlice.endpoints.updateTeamDiscrepancyParamterSelection.matchFulfilled, (state, {payload}) => {
      if (payload.data.newLog) {
        state.data.actionsAndErrorsLog.push(payload.data.newLog)
      }
    })
    .addMatcher(apiSlice.endpoints.editUserEmailSchedule.matchFulfilled, (state, {payload}) => {
      if (payload.data.newLog) {
        state.data.actionsAndErrorsLog.push(payload.data.newLog)
      }
    })
    .addMatcher(apiSlice.endpoints.loginExistingSuper.matchFulfilled, (state, {payload}) => {
      if (payload.data.newLog) {
        state.data.actionsAndErrorsLog.push(payload.data.newLog)
      }
    })
    .addMatcher(apiSlice.endpoints.deleteUser.matchFulfilled, (state, {payload}) => {
      state.data.actionsAndErrorsLog.push(payload.data.newLog)
      let thisInstance = state.data.database.users.find(user => user._id === payload.data.deletedUserId)
      let thisIndex = state.data.database.users.indexOf(thisInstance)
      state.data.database.users.splice(thisIndex, 1)
    })
  }
})

export default teamAdminDataSlice.reducer

export const selectTeamQueryPerformances = state => state.teamAdminData.data.queryPerformances
export const selectTeamUploadReports = state => state.teamAdminData.data.uploadReports
export const selectActionsAndErrorsLog = state => state.teamAdminData.data.actionsAndErrorsLog
export const selectDataBase = state => state.teamAdminData.data.database
import React from "react";
import FullLogo from './../../../../../Full.logo.teal.png';
import { ColumnContent, ComponentBorder, ComponentContainer, ComponentSectionRow, Detail, DetailContainer, DetailInfoContainer, DetailListContainer, FlexWrapper, RowsContainer } from "../../../../../StyledComponents";
import { useSelector } from "react-redux";
import { selectTeamDetails } from "../../../../../slices/teamAndUserSettingsSlice";
import { APP_VERSION } from "../../../../../config";

const TeamDetails = () => {

  const teamDetails = useSelector(selectTeamDetails)

  return (
    <ComponentContainer className="justifyStart" style={{gridRow: '1', gridColumn: '1', margin: 'auto'}}>
      <FlexWrapper className="column" style={{gridRow: '2', gridColumn: '2', padding: '1rem'}}>
        <ComponentSectionRow className="">
          <ColumnContent className="detail small lowercase thin" style={{width: 'fit-content', paddingLeft: '2rem'}}>
            my team:
          </ColumnContent>
        </ComponentSectionRow>
        <img src={FullLogo} style={{width: '90%', height: 'auto', margin: '0 auto'}} />
        <DetailContainer style={{width: 'fit-content', padding: '1.5rem 0 0 2rem'}}>
          <DetailListContainer>
            <DetailInfoContainer className="item">
              <Detail className="thin">date joined:</Detail>
              <Detail className="thin">team accounts:</Detail>
              <Detail className="thin">app admin:</Detail>
              <Detail className="thin">app version:</Detail>
            </DetailInfoContainer>
            <DetailInfoContainer>
              <Detail>{teamDetails.dateCreated}</Detail>
              <Detail>{teamDetails.numberOfUsers}</Detail>
              <Detail>{teamDetails.appAdminName}, {teamDetails.appAdminEmail}</Detail>
              <Detail>{APP_VERSION}</Detail>
            </DetailInfoContainer>
          </DetailListContainer>
        </DetailContainer>
        <ComponentSectionRow style={{padding: '2rem 0 0.5rem 0'}}>
          <ColumnContent className="detail small lowercase thin" style={{width: 'fit-content', paddingLeft: '2rem'}}>
            <span style={{fontWeight: '400'}}>Have questions or feature requests?</span>
            Contact your App Admin or reach out to our IT department at solutions@lancastersweep.com
          </ColumnContent>
        </ComponentSectionRow>
      </FlexWrapper>
      <ComponentBorder className="top" style={{gridRow:'1', gridColumn: '1/4'}} />
      <ComponentBorder className="left" style={{gridRow:'2', gridColumn: '1'}} />
      <ComponentBorder className="right"  style={{gridRow:'2', gridColumn: '3'}} />
      <ComponentBorder className="bottom" style={{gridRow:'3', gridColumn: '1/4'}} />
    </ComponentContainer>
  )
}

export default TeamDetails
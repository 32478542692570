import React from "react";
import { useTheme } from "@mui/styles";
import { Detail, DetailContainer, DetailInfoContainer, DetailListContainer } from "../../../StyledComponents";

const ManualInvestigation = ({milestone}) => {
  const theme = useTheme()

  return (
    <DetailContainer className="fitContent">
      <Detail className="timelineHeader">{milestone.milestone}{milestone.notify ? <span style={{color: `${theme.palette.primary.main}`, margin: '0.25rem 0 0 0.5rem', fontWeight: '400'}}>new</span> : <></>}</Detail>
      <DetailListContainer className='timeline'>
        <DetailInfoContainer className="item timeline">
          <Detail className="timelineDetail">initiated by:</Detail>
          <Detail className="timelineDetail">date:</Detail>
          {milestone.details.length === 1 ?
            <Detail className="timelineDetail">assignee:</Detail>
          :
            milestone.details.map((index) => (
              index === 0 ?
                <Detail key={index} className="timelineDetail">assignees:</Detail>
              :
                <Detail key={index}></Detail>
            ))
          }
          {milestone.notes.length > 0 ?
            milestone.notes.length === 1 ?
              <Detail className="timelineDetail">note since last report:</Detail>
            :
              <Detail className="timelineDetail">notes since last report:</Detail>
          :
            <></>
          }
        </DetailInfoContainer>
        <DetailInfoContainer className="space">
          <Detail className="timelineItem">{milestone.contributor}</Detail>
          <Detail className="timelineItem">{milestone.date}</Detail>
          {milestone.details.map((detail, index) => (
            <Detail className="timelineItem" key={index}>{detail}</Detail>
          ))}
          {milestone.notes.length > 0 ?
            <ul style={{padding: '0 0 0 1.5rem'}}>
              {milestone.notes.map((note, index) => (
                <li key={index}>
                  <Detail className="timelineItem note">{note.details}</Detail>
                  <Detail className="timelineItem" style={{padding: '0 0 0 1rem'}}>- {note.contributor}, {note.date}</Detail>
                </li>
              ))}
            </ul>
          :
            <></>
          }
        </DetailInfoContainer>
      </DetailListContainer>
    </DetailContainer>
  )
}

export default ManualInvestigation
import React, { useState } from "react";
import 'react-csv-importer/dist/index.css';
import { useTheme } from "@mui/styles";
import CircularProgress from "@mui/material/CircularProgress";
import { useDeleteUserMutation } from "../../../../../../../slices/api/apiSlice";
import { HeaderContainer, Button, ButtonWrapper, ModalContentContainer, ComponentHeader, ModalHeaderMessage, FlexWrapper, SubHeader } from "../../../../../../../StyledComponents";

const Delete = ({ handleCloseActionsModal, data, userId }) => {
  const theme = useTheme();

  const [deleteUser, {isFetching, isSuccess, isError}] = useDeleteUserMutation()
  const [isInvalid, setIsInvalid] = useState(false)

  const handleDismissLead = async (deletedUserId) => {
    if (userId === deletedUserId.toString()) {
      setIsInvalid(true)
    } else {
      deleteUser({
        userId: userId,
        deletedUserId: deletedUserId,
      })
    }
  }

  return (
    <>
      <HeaderContainer className="modal">
        {isSuccess ?
          <FlexWrapper className="alignCenter borderBottom">
            <ComponentHeader className="modal">Delete User?</ComponentHeader>
            <ModalHeaderMessage className="green">success</ModalHeaderMessage>
          </FlexWrapper>
        : isError ?
          <FlexWrapper className="alignCenter borderBottom">
            <ComponentHeader className="modal">Delete User?</ComponentHeader>
            <ModalHeaderMessage className="red">failed</ModalHeaderMessage>
          </FlexWrapper>
        :
          <FlexWrapper className="alignCenter borderBottom">
            <ComponentHeader className="modal">Delete User?</ComponentHeader>
          </FlexWrapper>
        }
      </HeaderContainer>

      <ModalContentContainer className="centerColumn">
        {isSuccess ?
          <SubHeader className="modal" style={{margin: '0 auto 3rem auto'}}>
            <span>The user has been deleted.</span>
          </SubHeader>
        : isFetching ?
          <SubHeader className="modal" style={{margin: '0 auto 3rem auto'}}>
            <span>Working on it...</span>
          </SubHeader>
        : isError ?
          <SubHeader className="modal" style={{margin: '0 auto 3rem auto', padding: '0 1rem'}}>
            <span>Something has gone wrong. Please reach out to support@lancastersweep.com</span>
          </SubHeader>
        : isInvalid ?
          <SubHeader className="modal" style={{margin: '0 auto 3rem auto', padding: '0 1rem', width: '35rem'}}>
            <span>Cannot delete the user when logged in with their account.</span>
          </SubHeader>
        :
          <SubHeader className="modal" style={{margin: '0 auto 3rem auto', padding: '0 1rem', width: '35rem'}}>
            <span>Deleting the user will remove its instance, but a historical record will be created and saved to the database.</span>
          </SubHeader>
        }

        {isSuccess || isError || isInvalid ?
          <ButtonWrapper>
            <Button className="gray" onClick={() => handleCloseActionsModal()}>Close</Button>
          </ButtonWrapper>
        :
          <ButtonWrapper>
            {isFetching ?
              <Button className="purpleLoading">
                <CircularProgress
                  sx={{ color: `${theme.palette.common.white}` }}
                  size={18}
                />
              </Button>
            :
              <Button className="red" onClick={() => handleDismissLead(data._id)}>Continue</Button>
            }
            <Button className="gray" onClick={() => handleCloseActionsModal()}>Close</Button>
          </ButtonWrapper>
        }
      </ModalContentContainer>
    </>
  )
}

export default Delete;
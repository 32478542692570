import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/styles";
import Stack from '@mui/material/Stack';
import Pagination from '@mui/material/Pagination';
import CircularProgress from "@mui/material/CircularProgress";
import { ReportContainer, ComponentHeader, ReportsContentContainer, ModalContentContainer, TreeContainer } from "../../../../StyledComponents";
import TreeItem from "../../../../pages/Dashboard/components/Reports/components/TreeItem";
import ReportView from "../../../../pages/Dashboard/components/Reports/components/ReportView";

const ReportsModal = ({ reports, report }) => {
  const theme = useTheme();

  const [reportsLoaded, setReportsLoaded] = useState(false)
  const [reportLoaded, setReportLoaded] = useState(false)
  const [selectedReport, setSelectedReport] = useState(null)
  const [selectedReportId, setSelectedReportId] = useState(reports[0]._id)
  const [page, setPage] = useState(1)

  const handleChangePage = (event) => {
    let pageNumber = parseInt(event.target.innerText)
    let thisReport = reports[(pageNumber - 1)]
    setPage(pageNumber)
    setSelectedReport(thisReport)
  }

  const selectReport = (reportId) => {
    let thisReport = reports.find(report => report._id === reportId)
    setSelectedReport(thisReport)
    setSelectedReportId(thisReport._id)
  }

  useEffect(() => {
    if (reports !== undefined) {
      setSelectedReport(reports[0])
      setReportsLoaded(true)
    } else if (report !== undefined) {
      setSelectedReport(report)
      setReportLoaded(true)
    }
  }, [reports, report])

  return (
    <>
      {reportLoaded ?
        <ReportContainer>
          <ReportView report={selectedReport} />
        </ReportContainer>
      : reportsLoaded ?
        <>
          <ComponentHeader className="modal" style={{padding: '0.7rem 3.5rem'}}>
            Reports
            <Stack spacing={2} style={{gridRow: '1', gridColumn: '2', width: 'fit-content', margin: '0 auto', padding: '0 12.1rem 0 0'}}>
              <Pagination onClick={(event) => handleChangePage(event)} count={reports.length} page={page} variant="outlined" color='secondary' hidePrevButton hideNextButton siblingCount={2} boundaryCount={1} />
            </Stack>
          </ComponentHeader>
          <ModalContentContainer className="topColumn noScroll leadActions">
            <ReportsContentContainer>
              {/* <TreeContainer style={{gridRow: '1', gridColumn: '2'}}>
                <span style={{padding: '0.5rem 0 0 3rem', width: '100%', color: `${theme.palette.common.grayText}`}}>
                  Total: {reports.length}
                </span>
                <ReportTreeListContainer>
                  {reports.map((report, index) => (
                    <TreeItem key={index} itemIndex={index} borrower={report.Owner1FullName} selectReport={selectReport} reportId={report._id} selectedReportId={selectedReportId} date={report.dateGenerated} isNew={report.notifyUser} address={report.streetAddress} city={report.City} />
                  ))}
                </ReportTreeListContainer>
              </TreeContainer> */}
              <ReportContainer>
                <ReportView report={selectedReport} />
              </ReportContainer>
            </ReportsContentContainer>
          </ModalContentContainer>
        </>
      :
        <div style={{height: '100%', width: "100%", display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <CircularProgress
            sx={{ color: `${theme.palette.primary.main}`}}
            size={48}
          />
        </div>
      }
    </>
  )
}

export default ReportsModal;
import React from "react";
import { useTheme } from "@mui/styles";
import { Detail, DetailContainer, DetailInfoContainer, DetailListContainer } from "../../../StyledComponents";

const DiscrepancyResolved = ({milestone}) => {
  const theme = useTheme()

  return (
    <DetailContainer className="fitContent">
      <Detail className="timelineHeader">{milestone.milestone}{milestone.notify ? <span style={{color: `${theme.palette.primary.main}`, margin: '0.25rem 0 0 0.5rem', fontWeight: '400'}}>new</span> : <></>}</Detail>
      <DetailListContainer className='timeline'>
        <DetailInfoContainer className="item timeline">
          <Detail className="timelineDetail">updated by:</Detail>
          <Detail className="timelineDetail">date:</Detail>
          <Detail>{milestone.details.label}:</Detail>
        </DetailInfoContainer>
        <DetailInfoContainer className="space">
          <Detail className="timelineItem">{milestone.contributor}</Detail>
          <Detail className="timelineItem">{milestone.date}</Detail>
          <div>
            {milestone.details.field.includes('Rate') || milestone.details.field.includes('%') ?
              <>
                <span style={{fontWeight: '600'}}>%{milestone.details.old.toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2})}</span> to <span style={{fontWeight: '600'}}>%{milestone.details.new.toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2})}</span>
              </>
            : milestone.details.field.includes('AssessedValue') ?
              <>
                <span style={{fontWeight: '600'}}>${milestone.details.old.toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 0})}</span> to <span style={{fontWeight: '600'}}>${milestone.details.new.toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 0})}</span>
              </>
            :
              milestone.details.old && milestone.details.new ?
                <>
                  resolved from <span style={{fontWeight: '600'}}>{milestone.details.old}</span> to <span style={{fontWeight: '600'}}>{milestone.details.new}</span>
                </>
              : milestone.details.old && !milestone.details.new ?
                <>
                  resolved from <span style={{fontWeight: '600'}}>{milestone.details.old}</span> to <span style={{fontWeight: '600', fontStyle: 'italic'}}>-empty-</span>
                </>
              :
                <>
                  resolved from <span style={{fontWeight: '600', fontStyle: 'italic'}}>-empty-</span> to <span style={{fontWeight: '600'}}>{milestone.details.old}</span>
                </>
            }
          </div>
        </DetailInfoContainer>
      </DetailListContainer>
    </DetailContainer>
  )
}

export default DiscrepancyResolved
import React, { useState } from "react";
import IconLogo from "../../icon.logo.black.png";
import LoadingLogo from '../../icon.logo.green.png';
import CityScape from "../../cityscape.jpg";
import AccessForm from "./components/AccessForm";
import { useTheme } from "@mui/styles";
import NewcomerForm from "./components/NewcomerForm";
import { AuthContainer, PageContainer, ScreenSaverContainer, ImageElements, ScannerOverlay, FlexWrapper } from "../../StyledComponents";

const Login = ({ userHistory, setIPAquired, setIPError, setUserConfirmed }) => {
  const theme = useTheme()

  const [isFetching, setIsFetching] = useState(false)
  const [showNewcomerForm, setShowNewcomerForm] = useState(false)

  return (
    <PageContainer className="login">
      <ImageElements src={CityScape} className="loginBackground" />

      <ScreenSaverContainer className="login fadeOut">
        <ImageElements src={IconLogo} className="centerLogo opaque" />
        <ImageElements src={LoadingLogo} className="centerLogo loaded one" style={{right: '10rem'}} />
        <ImageElements src={LoadingLogo} className="centerLogo loaded two" style={{right: '10rem'}} />
        <ImageElements src={LoadingLogo} className="centerLogo loaded three" style={{right: '5rem'}} />
        <ImageElements src={LoadingLogo} className="centerLogo loaded four" style={{right: '5rem'}} />
        <ImageElements src={LoadingLogo} className="centerLogo loaded five" style={{}} />
        <ImageElements src={LoadingLogo} className="centerLogo loaded six" style={{}} />
        <ImageElements src={LoadingLogo} className="centerLogo loaded seven" style={{left: '5rem'}} />
        <ImageElements src={LoadingLogo} className="centerLogo loaded eight" style={{left: '5rem'}} />
        <ImageElements src={LoadingLogo} className="centerLogo loaded nine" style={{left: '10rem'}} />
        <ImageElements src={LoadingLogo} className="centerLogo loaded ten" style={{left: '10rem'}} />
      </ScreenSaverContainer>

      <ScreenSaverContainer className="login" >
        <ImageElements src={IconLogo} className="centerLogo opaque" />
      </ScreenSaverContainer>

      <ScannerOverlay className="radial" style={{gridRow: '1', gridColumn: '1/4'}} />
      <ScannerOverlay className="fadeOverlay" style={{gridRow: '1', gridColumn: '1/4'}} />
      <ScannerOverlay className="linearScan" style={{gridRow: '1', gridColumn: '1/4'}} />
      <ScannerOverlay className="linearScan thin" style={{gridRow: '1', gridColumn: '1/4', animation: 'bg 0.15s linear infinite'}} />
      <ScannerOverlay className="linearScan thin" style={{gridRow: '1', gridColumn: '1/4', animation: 'bg 0.45s linear infinite'}} />
      <ScannerOverlay className="linearScan thin" style={{gridRow: '1', gridColumn: '1/4', animation: 'bg 0.75s linear infinite'}} />

      <AuthContainer style={{gridColumn: '3', gridRow: '1', width: userHistory === 'newcomer' ? '40rem' : '35rem'}}>
        {!showNewcomerForm ?
          <AccessForm userHistory={userHistory} isFetching={isFetching} setIsFetching={setIsFetching} setShowNewcomerForm={setShowNewcomerForm} setIPAquired={setIPAquired} setIPError={setIPError} setUserConfirmed={setUserConfirmed} />
        :
          <NewcomerForm isFetching={isFetching} setIsFetching={setIsFetching} setIPAquired={setIPAquired} setIPError={setIPError} setUserConfirmed={setUserConfirmed} />
        }
      </AuthContainer>
    </PageContainer>
  );
};

export default Login;

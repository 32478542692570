import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: {
    selected: 'team',
    fullName: '',
  },
  status: 'idle',
  error: null
}

export const dashPerspectiveSlice = createSlice({
  name: 'dashPerspective',
  initialState,
  reducers: {
    setDashPerspective(state, action) {
      state.data.selected = action.payload
    },
    setDashUserFullName(state, action) {
      state.data.fullName = action.payload
    }
  },
})

export const { setDashPerspective, setDashUserFullName } = dashPerspectiveSlice.actions

export default dashPerspectiveSlice.reducer

export const selectDashPerspective = state => state.dashPerspective.data.selected
export const selectDashUserFullName = state => state.dashPerspective.data.fullName
import React from "react";
import { ComponentHeader, ComponentSectionRow, StatusAndLabelWrapper, Button, ButtonWrapper, Label, InputWrapper, ModalContentContainer, AccentInfo, DetailText, Divider } from "../../../../StyledComponents";

const OwnerDetails = ({ handleCloseLeadActionsModal, mortgageData }) => {

  return (
    <>
      <ComponentHeader className="modal">Owner Details</ComponentHeader>
      <ModalContentContainer className="column leadActions">
        <div style={{width: '80%', margin: '0 auto'}}>

          <DetailText>Primary Mortgage</DetailText>
          <Divider className="gray" />
          <ComponentSectionRow style={{margin: '1rem 0'}}>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Mortgage Amount</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.primaryMortgage.PrimaryMortgageAmount.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Lender Name</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.primaryMortgage.PrimaryLenderName.currentValue}
              </DetailText>
            </InputWrapper>

          </ComponentSectionRow>
          <ComponentSectionRow style={{margin: '1rem 0'}}>
            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Mortgage Date</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.primaryMortgage.PrimaryMortgageRecordingDate.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Loan Type Code</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.primaryMortgage.PrimaryLoanType.currentValue}
              </DetailText>
            </InputWrapper>

          </ComponentSectionRow>
          <ComponentSectionRow style={{margin: '1rem 0'}}>
            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Mortgage Term</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.primaryMortgage.PrimaryMortgageTerm.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Mortgage Due Date</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.primaryMortgage.PrimaryMortgageDueDate.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Title Company</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.primaryMortgage.TitleCompany.currentValue}
              </DetailText>
            </InputWrapper>

          </ComponentSectionRow>

          <DetailText>Secondary Mortgage</DetailText>
          <Divider className="gray" />
          <ComponentSectionRow style={{margin: '1rem 0'}}>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Mortgage Amount</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.secondaryMortgage.SecondaryMortgageAmount.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Lender Last Name</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.secondaryMortgage.SecondaryLenderName.currentValue}
              </DetailText>
            </InputWrapper>

          </ComponentSectionRow>
          <ComponentSectionRow style={{margin: '1rem 0'}}>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Mortgage Date</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.secondaryMortgage.SecondaryMortgageRecordingDate.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Loan Type Code</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.secondaryMortgage.SecondaryLoanType.currentValue}
              </DetailText>
            </InputWrapper>

          </ComponentSectionRow>
          <ComponentSectionRow style={{margin: '1rem 0'}}>
            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Mortgage Term</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.secondaryMortgage.SecondaryMortgageTerm.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Mortgage Due Date</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.secondaryMortgage.SecondaryMortgageDueDate.currentValue}
              </DetailText>
            </InputWrapper>

          </ComponentSectionRow>

          <DetailText>Primary Owner</DetailText>
          <Divider className="gray" />
          <ComponentSectionRow style={{margin: '1rem 0'}}>
            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Corporate Indicator</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.owner1.CorporateIndicator.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Corporate Type</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.owner1.CorporateType.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Corporate Description</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.owner1.CorporateDescription.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Owner 1 Ownership Rights</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.owner1.Owner1OwnershipRights.currentValue}
              </DetailText>
            </InputWrapper>

          </ComponentSectionRow>
          <ComponentSectionRow style={{margin: '1rem 0'}}>
            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Owner 1 Is Corporation</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.owner1.Owner1IsCorporation.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Owner 1 Full Name</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.owner1.Owner1FullName.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Owner 1 Last Name</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.owner1.Owner1LastName.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Owner 1 First Name</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.owner1.Owner1FirstName.currentValue}
              </DetailText>
            </InputWrapper>

          </ComponentSectionRow>

          <DetailText>Secondary Owner</DetailText>
          <Divider className="gray" />
          <ComponentSectionRow style={{margin: '1rem 0'}}>
            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Owner 2 Is Corporation</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.owner2.Owner2IsCorporation.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Owner 2 Full Name</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.owner2.Owner2FullName.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Owner 2 Last Name</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.owner2.Owner2LastName.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Owner 2 First Name</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.owner2.Owner2FirstName.currentValue}
              </DetailText>
            </InputWrapper>

          </ComponentSectionRow>

          <DetailText>Tax Details</DetailText>
          <Divider className="gray" />
          <ComponentSectionRow style={{margin: '1rem 0'}}>
            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Tax Initial Deliquency Year</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.tax.TaxInitialDeliquencyYear.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Tax Annual Amount</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.tax.TaxAnnualAmount.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Tax Year</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.tax.TaxYear.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Tax Exemption Homestead</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.tax.TaxExemptionHomestead.currentValue}
              </DetailText>
            </InputWrapper>

          </ComponentSectionRow>
          <ComponentSectionRow style={{margin: '1rem 0'}}>
            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Tax Exemption Veteran</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.tax.TaxExemptionVeteran.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Tax Exemption Disabled</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.tax.TaxExemptionDisabled.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Tax Exemption Widow</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.tax.TaxExemptionWidow.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Tax Exemption Senior</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.tax.TaxExemptionSenior.currentValue}
              </DetailText>
            </InputWrapper>

          </ComponentSectionRow>
          <ComponentSectionRow style={{margin: '1rem 0'}}>
            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Tax Exemption School/College</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.tax.TaxExemptionSchoolCollege.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Tax Exemption Religious</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.tax.TaxExemptionReligious.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Tax Exemption Welfare</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.tax.TaxExemptionWelfare.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Tax Exemption Public Utility</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.tax.TaxExemptionPublicUtility.currentValue}
              </DetailText>
            </InputWrapper>

          </ComponentSectionRow>
          <ComponentSectionRow style={{margin: '1rem 0'}}>
            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Tax Exemption Cemetery</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.tax.TaxExemptionCemetery.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Tax Exemption Hospital</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.tax.TaxExemptionHospital.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Tax Exemption Library</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.tax.TaxExemptionLibrary.currentValue}
              </DetailText>
            </InputWrapper>

          </ComponentSectionRow>

          <DetailText>Assessment</DetailText>
          <Divider className="gray" />
          <ComponentSectionRow style={{margin: '1rem 0'}}>
            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Market Value</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.assessment.MarketValue.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Market Land Value</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.assessment.MarketLandValue.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Market Improvement Value</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.assessment.MarketImprovementValue.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Assessed Year</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.assessment.AssessedYear.currentValue}
              </DetailText>
            </InputWrapper>

          </ComponentSectionRow>
          <ComponentSectionRow style={{margin: '1rem 0'}}>
            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Assessors Map Reference</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.assessment.AssessorsMapReference.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Assessed Improvement Value</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.assessment.AssessedImprovementValue.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Improvements Value</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.assessment.ImprovementsValue.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Assessed Land Value</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.assessment.AssessedLandValue.currentValue}
              </DetailText>
            </InputWrapper>

          </ComponentSectionRow>
          <ComponentSectionRow style={{margin: '1rem 0'}}>
            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Land Value</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.assessment.LandValue.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Assessed Value</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.assessment.AssessedValue.currentValue}
              </DetailText>
            </InputWrapper>

          </ComponentSectionRow>

          <DetailText>Previous Sale</DetailText>
          <Divider className="gray" />
          <ComponentSectionRow style={{margin: '1rem 0'}}>
            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Leasehold Or Fee Simple</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.sale.LeaseholdOrFeeSimple.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Last Sale Recording Date</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.sale.LastSaleRecordingDate.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Last Sale Contract Date</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.sale.LastSaleContractDate.currentValue}
              </DetailText>
            </InputWrapper>

          </ComponentSectionRow>
          <ComponentSectionRow style={{margin: '1rem 0'}}>
            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Last Sale Date</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.sale.LastSaleDate.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Last Sale Price</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.sale.LastSalePrice.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Close Price</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.sale.ClosePrice.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Close Price Description</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.sale.ClosePriceDescription.currentValue}
              </DetailText>
            </InputWrapper>

          </ComponentSectionRow>
          <ComponentSectionRow style={{margin: '1rem 0'}}>
            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Last Sale Type</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.sale.LastSaleType.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Sale Transaction Type</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.sale.SaleTransType.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Last Sale Buyer Name</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.sale.LastSaleBuyerName.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Sale Document Type</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.sale.SaleDocType.currentValue}
              </DetailText>
            </InputWrapper>

          </ComponentSectionRow>
          <ComponentSectionRow style={{margin: '1rem 0'}}>
            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Last Sale Recording Document ID</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.sale.LastSaleRecordingDocumentId.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Last Sale Document Type</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.sale.LastSaleDocumentType.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Last Sale Seller 2 Full Name</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.sale.LastSaleSeller2FullName.currentValue}
              </DetailText>
            </InputWrapper>

          </ComponentSectionRow>
          <ComponentSectionRow style={{margin: '1rem 0'}}>
            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Last Sale Recording Document ID</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.sale.LastSaleRecordingDocumentId.currentValue}
              </DetailText>
            </InputWrapper>


            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Last Sale Seller 1 Full Name</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.sale.LastSaleSeller1FullName.currentValue}
              </DetailText>
            </InputWrapper>

            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Last Sale Seller Name</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.sale.LastSaleSeller1FullName.currentValue}
              </DetailText>
            </InputWrapper>
            <InputWrapper className="alignStart" style={{width: 'fit-content'}}>
              <StatusAndLabelWrapper>
                <Label>Last Sale Seller Name</Label>
              </StatusAndLabelWrapper>
              <DetailText>
                {mortgageData.recordDetails.sale.LastSaleSeller2FullName.currentValue}
              </DetailText>
            </InputWrapper>

          </ComponentSectionRow>
          

        </div>
        
        <ButtonWrapper className="selfAlignEnd marginAuto">
          <Button className="gray" onClick={() => handleCloseLeadActionsModal()}>Close</Button>
        </ButtonWrapper>
      </ModalContentContainer>
    </>
  )
}

export default OwnerDetails;
import React, { useRef, useState } from "react";
import ErrorIcon from "@mui/icons-material/Error";
import { useSelector } from "react-redux";
import ClearIcon from '@mui/icons-material/Clear';
import CircularProgress from "@mui/material/CircularProgress";
import SaveIcon from '@mui/icons-material/Save';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { selectTeamDetails } from "../../../../slices/teamAndUserSettingsSlice";
import { AddButton, Button, ButtonWrapper, Detail, ErrorWrapper, FlexWrapper, InputWrapper, Label, StatusAndLabelWrapper } from "../../../../StyledComponents";
import { StyledTextArea } from "../../../../componentThemes";
import { useCreateNewTeamLoanTypeMutation } from "../../../../slices/api/apiSlice";
import { useTheme } from "@mui/styles";

const TeamDetails = ({ userId, teamId }) => {
  const theme = useTheme()

  let [createNewLoanType, {isFetching, isSuccess, isError}] = useCreateNewTeamLoanTypeMutation()

  const [addNewLoanType, setAddNewLoanType] = useState(false)

  const [loanTypeValue, setLoanTypeValue] = useState('')
  const [loanTypeCount, setLoanTypeCount] = useState(0)
  const [statusLoanTypeLength, setStatusLoanTypeLength] = useState(null)
  const [statusLoanTypeCharacter, setStatusLoanTypeCharacter] = useState(false)
  const newLoanType = useRef()

  const teamDetails = useSelector(selectTeamDetails)

  const handleLoanTypeCount = () => {
    setLoanTypeCount(newLoanType.current.value.length)
		if (newLoanType.current.value.includes('<') || newLoanType.current.value.includes('>')) {
			setStatusLoanTypeCharacter(true)
		} else {
			setStatusLoanTypeCharacter(false)
		}
		if (newLoanType.current.value.length > 60) {
			setStatusLoanTypeLength("tooLong")
		} else {
			setStatusLoanTypeLength(null)
    }
  }

  const handleCreateNewLoanType = async () => {
    if (newLoanType.current.value.includes('<') || newLoanType.current.value.includes('>') || newLoanType.current.value.length > 60 || newLoanType.current.value.length < 1) {
      if (newLoanType.current.value.includes('<') || newLoanType.current.value.includes('>')) {
        setStatusLoanTypeCharacter(true)
      }
      if (newLoanType.current.value.length > 60) {
        setStatusLoanTypeLength("tooLong")
      }
      if (newLoanType.current.value.length < 1) {
        setStatusLoanTypeLength("tooShort")
      }
    } else {
      let requestObj = {
        userId: userId,
        teamId: teamId,
        loanType: loanTypeValue,
      }
      let response = await createNewLoanType(requestObj)
      setLoanTypeValue('')
      setLoanTypeCount(0)
      setAddNewLoanType(false)
    }
  }

  return (
    <>
      <Detail>team:</Detail>
      <Detail className="header">{teamDetails.teamName}</Detail>

      <Detail>Loan Types</Detail>
      {teamDetails.loanTypes.map((loanType, index) => (
        <Detail key={index}>{loanType.label}</Detail>
      ))}
      <AddButton onClick={() => setAddNewLoanType(true)}>
        <AddCircleOutlineIcon style={{margin: "0", height: "100%", width: "100%"}} />
      </AddButton>
      {addNewLoanType ?
        <>
          <InputWrapper className="notes" style={{width: '100%'}}>
            <StatusAndLabelWrapper className="notes">
              <Label>New Loan Type</Label>
              <FlexWrapper className="fitContentWidth alignCenter" style={{height: '2rem'}}>
                {statusLoanTypeCharacter === true && (
                  <ErrorWrapper>
                    <ErrorIcon />
                    {`Cannot include the characters " < " or " > "`}
                  </ErrorWrapper>
                )}
                {statusLoanTypeLength === "tooLong" && (
                  <ErrorWrapper>
                    <ErrorIcon />
                    {`Too long`}
                  </ErrorWrapper>
                )}
                {statusLoanTypeLength === "tooShort" && (
                  <ErrorWrapper>
                    <ErrorIcon />
                    {`Cannot be blank`}
                  </ErrorWrapper>
                )}
                <Label className="count">{loanTypeCount}/60</Label>
              </FlexWrapper>
            </StatusAndLabelWrapper>
            <StyledTextArea
              value={loanTypeValue}
              type="text"
              name="loanType"
              id="loanType"
              ref={newLoanType}
              onChange={() => {
                if (statusLoanTypeLength === "tooShort" || statusLoanTypeLength === "tooLong") {
                  setStatusLoanTypeLength(null)
                }
                if (statusLoanTypeCharacter) {
                  setStatusLoanTypeCharacter(false)
                }
                setLoanTypeValue(newLoanType.current.value)
                handleLoanTypeCount()
              }}
            />
          </InputWrapper>
          <ButtonWrapper className="justifyStart" style={{margin: '0.3rem 0 0 0'}}>
            <Button className={`primary notes ${statusLoanTypeLength} || ${statusLoanTypeCharacter} || ${loanTypeCount > 60} || ${loanTypeCount < 1} ? disabled`} style={{marginRight: '0.5rem'}} onClick={() => handleCreateNewLoanType()}>
              {isFetching ?
                <CircularProgress
                  sx={{ color: `${theme.palette.common.white}` }}
                  size={18}
                />
              :
                <SaveIcon />
              }
            </Button>
            <Button className="gray notes" onClick={() => setAddNewLoanType(false)}>
              <ClearIcon />
            </Button>
          </ButtonWrapper>
        </>
      :
        <></>
      }
    </>
  )
}

export default TeamDetails;
import React, { useState } from "react";
import Tooltip from '@mui/material/Tooltip';
import Checkbox from '@mui/material/Checkbox';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from "@mui/styles";
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import FormControlLabel from '@mui/material/FormControlLabel';
import { selectUserEmailSchedule } from "../../slices/teamAndUserSettingsSlice";
import { useEditUserEmailScheduleMutation } from "../../slices/api/apiSlice";
import { FlexWrapper, ButtonWrapper, Button } from "../../StyledComponents";
import { tooltipComponentProps, tooltipPopperProps } from "../../componentThemes";

const NotificationScheduleSelection = ({ currentSchedule, setEditEmailSchedule, userFullName, userId }) => {
  const theme = useTheme()

  const [editUserEmailSchedule, { isLoading }] = useEditUserEmailScheduleMutation()

  const currentScheduleIds = useSelector(selectUserEmailSchedule)

  const [scheduleSelection, setScheduleSelection] = useState({
    leadsUpdated: true,
    weeklyPerformance: true,
    monthlyScans: true,
  })

  const handleSelectionChange = (event) => {
    setScheduleSelection({
      ...scheduleSelection,
      [event.target.name]: event.target.checked,
    })
  }

  const handleEditEmailSchedule = async () => {
    await editUserEmailSchedule({
      userFullName: userFullName,
      userId: userId,
      newSchedule: scheduleSelection,
      currentScheduleIds: currentScheduleIds,
    })
    setEditEmailSchedule(false)
  }

  return (
    <FlexWrapper>
      <FormControlLabel
        control={
          <Checkbox checked={scheduleSelection.leadsUpdated} onChange={handleSelectionChange} name="leadsUpdated" />
        }
        label="Leads Updated"
      />
      <FormControlLabel
        control={
          <Checkbox checked={scheduleSelection.weeklyPerformance} onChange={handleSelectionChange} name="weeklyPerformance" />
        }
        label="Weekly Performance"
      />
      <FormControlLabel
        control={
          <Checkbox checked={scheduleSelection.monthlyScans} onChange={handleSelectionChange} name="monthlyScans" />
        }
        label="Monthly Scan Results"
      />
      <ButtonWrapper>
        {isLoading ?
          <Button className="primaryLoading notes" style={{margin: 'auto 0.5rem auto 0'}}>
            <CircularProgress
              sx={{ color: `${theme.palette.primary.buttonColor}` }}
              size={12}
            />
          </Button>
        : scheduleSelection.leadsUpdated === currentSchedule.leadsUpdated && scheduleSelection.weeklyPerformance === currentSchedule.weeklyPerformance && scheduleSelection.monthlyScans === currentSchedule.monthlyScans ?
          <Tooltip
            componentsProps={tooltipComponentProps}
            PopperProps={tooltipPopperProps}
            title={<FlexWrapper><span style={{padding: '0', margin: '0', fontWeight: '600'}}>no changes made</span></FlexWrapper>} 
            placement="top"
            arrow
          >
            <Button className="primaryDisabled notes" style={{margin: 'auto 0.5rem auto 0'}}>
              <SaveIcon />
            </Button>
          </Tooltip>
        :
          <Button className="primary notes" onClick={() => handleEditEmailSchedule()} style={{margin: 'auto 0.5rem auto 0'}}>
            <SaveIcon />
          </Button>
        }
        <Button className="grayDisabled notes" onClick={() => setEditEmailSchedule(false)} style={{margin: 'auto 0'}}><CloseIcon /></Button>
      </ButtonWrapper>
    </FlexWrapper>
  )
}

export default NotificationScheduleSelection
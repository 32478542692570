import React from "react";
import { useTheme } from "@mui/styles";
import { useSelector } from "react-redux";
import { selectUserRole } from "../../../slices/userInfoSlice";
import { AccentInfo, Detail, DetailContainer, DetailInfoContainer, DetailListContainer, FlexWrapper } from "../../../StyledComponents";

const RecordScannedSuccessfully = ({milestone}) => {
  const theme = useTheme()

  const userRole = useSelector(selectUserRole)

  return (
    <DetailContainer className="fitContent">
      <Detail className="timelineHeader">{milestone.milestone}{milestone.notify ? <span style={{color: `${theme.palette.primary.main}`, margin: '0.25rem 0 0 0.5rem', fontWeight: '400'}}>new</span> : <></>}</Detail>
      {milestone.milestone === 'Record Scanned Successfully' &&
        <Detail><AccentInfo>No discrepancies detected</AccentInfo></Detail>
      }
      <DetailListContainer className='timeline'>
        <DetailInfoContainer className="item timeline">
          <Detail className="timelineDetail">date:</Detail>
          {userRole === 'super' ?
            <Detail className="timelineDetail">Queries:</Detail>
          :
            <></>
          }
        </DetailInfoContainer>
        <DetailInfoContainer className="space">
          <Detail className="timelineItem">{milestone.date}</Detail>
          {userRole === 'super' ?
            <Detail className="timelineItem">{milestone.details}</Detail>
          :
            <></>
          }
        </DetailInfoContainer>
      </DetailListContainer>
      {milestone.newMortgageTags.length > 0 ?
        milestone.newMortgageTags.length > 1 ?
          <>
            <Detail><AccentInfo>New Mortgage Tags</AccentInfo></Detail>
            <DetailListContainer style={{width: '100%'}}>
              {milestone.newMortgageTags.map((mortgageTag, index) => (
                <FlexWrapper className="column" style={{width: '50%'}} key={index}>
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <Detail className="timelineDetail tier three">{mortgageTag.label}</Detail>
                  </div>
                  <FlexWrapper>
                    {mortgageTag.discrepancyFields.map((field, index) => (
                      index === (mortgageTag.discrepancyFields.length - 1) ?
                        <AccentInfo key={index}>{field}</AccentInfo>
                      :
                        <AccentInfo key={index}>{field},</AccentInfo>
                    ))}
                  </FlexWrapper>
                </FlexWrapper>
              ))}
            </DetailListContainer>
          </>
        :
          <>
            <Detail><AccentInfo>New Mortgage Tag</AccentInfo></Detail>
            <DetailListContainer style={{width: '100%'}}>
              <FlexWrapper className="column" style={{width: '100%'}}>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                  <Detail className="timelineDetail tier three">{milestone.newMortgageTags[0].label}</Detail>
                </div>
                <FlexWrapper>
                  {milestone.newMortgageTags[0].discrepancyFields.map((field, index) => (
                    index === (milestone.newMortgageTags[0].discrepancyFields.length - 1) ?
                      <AccentInfo key={index}>{field}</AccentInfo>
                    :
                      <AccentInfo key={index}>{field},</AccentInfo>
                  ))}
                </FlexWrapper>
              </FlexWrapper>
            </DetailListContainer>
          </>
          
      :
        <></>
      }
    </DetailContainer>
  )
}

export default RecordScannedSuccessfully
import React from "react";
import { useTheme } from "@mui/styles";
import { ColumnContent, ComponentSectionRow, RowsContainer, ScrollWindow } from "../../../../../../../../StyledComponents";

const UploadSummary = ({ viewingReport }) => {
  const theme = useTheme()

  return (
    <ScrollWindow className="horizontalOnly" style={{maxWidth: 'calc(100vw - 23.3rem)', borderTop: `0.1rem solid ${theme.palette.common.grayText}`, gridRow: '2/4'}}>
      <ComponentSectionRow className="header uploadSuccess">
        <ColumnContent className="header" style={{gridColumn: '1'}}>
        Date
        </ColumnContent>
        <ColumnContent className="header" style={{gridColumn: '2'}}>
        Successful
        </ColumnContent>
        <ColumnContent className="header" style={{gridColumn: '3'}}>
        Errors
        </ColumnContent>
      </ComponentSectionRow>
      <RowsContainer style={{height: '100%'}}>
        <ComponentSectionRow className="uploadSuccess">
          <ColumnContent className="detail" style={{gridColumn: '1'}}>
            {viewingReport.date}
          </ColumnContent>
          <ColumnContent className="detail" style={{gridColumn: '2'}}>
            {viewingReport.numberSuccessess}
          </ColumnContent>
          <ColumnContent className="detail" style={{gridColumn: '3'}}>
            {viewingReport.numberErrors}
          </ColumnContent>
        </ComponentSectionRow>
      </RowsContainer>
    </ScrollWindow>
  )
}

export default UploadSummary;
import React from "react";
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { selectDuplicateCheckResponse } from "../../../../../slices/sessionDataSlice";
import { useCheckForMortgageDuplicatesMutation } from "../../../../../slices/api/apiSlice";
import { FlexWrapper, ComponentContainer, ColumnContent, Button, ComponentSectionRow, RowsContainer, ComponentBorder } from "../../../../../StyledComponents";
import { selectDuplicatesCheckEnabled, setDuplicatesCheckEnabled } from "../../../../../slices/functionAvailabilitySlice";
import { tooltipComponentProps, tooltipPopperProps } from "../../../../../componentThemes";

const Duplicates = ({ teamId, userFullName }) => {
  const theme = useTheme()
  const dispatch = useDispatch()

  const duplicatesReport = useSelector(selectDuplicateCheckResponse)
  const duplicatesCheckEnabled = useSelector(selectDuplicatesCheckEnabled)

  const [checkForMortgageDuplicates, { isLoading }] = useCheckForMortgageDuplicatesMutation()
  const handleClick = async () => {
    await checkForMortgageDuplicates({
      teamId: teamId,
      userFullName: userFullName,
    })
    dispatch( setDuplicatesCheckEnabled(false) )
  }

  return (
    <ComponentContainer className="justifyStart" style={{gridRow: '2', gridColumn: '2', height: '49%'}}>
      <FlexWrapper className="column" style={{gridRow: '2', gridColumn: '2'}}>
        <div style={{display: 'flex', justifyContent: 'center', margin: 'auto 0'}}>
          {isLoading ?
            <Button className="primaryLoading" style={{width: '22rem'}}>
              <CircularProgress
                sx={{color: `${theme.palette.common.white}`}}
                size={12}
              />
            </Button>
          : !duplicatesCheckEnabled ?
            <Tooltip
              componentsProps={tooltipComponentProps}
              PopperProps={tooltipPopperProps}
              title={<FlexWrapper><span style={{padding: '0', margin: '0', fontWeight: '600'}}>All mortgages have been checked</span></FlexWrapper>} 
              placement="top"
              arrow
            >
              <Button className="primaryDisabled" style={{width: '22rem'}}>Check For Mortgage Duplicates</Button>
            </Tooltip>
          :
            <Button className="primary" onClick={() => handleClick()} style={{width: '22rem'}}>Check For Mortgage Duplicates</Button>
          }
        </div>
        <ComponentSectionRow className="duplicateCheck header">
          <ColumnContent className="header" style={{gridColumn: '1'}}>
            Index
          </ColumnContent>
          <ColumnContent className="header" style={{gridColumn: '2'}}>
            Message
          </ColumnContent>
          <ColumnContent className="header" style={{gridColumn: '3'}}>
            Match
          </ColumnContent>
        </ComponentSectionRow>
        {duplicatesReport[1].length > 0 ?
          duplicatesReport[0].discovered ?
            <RowsContainer style={{height: '100%'}}>
              {duplicatesReport[1].map((row, index) => (
                <ComponentSectionRow key={index} className={(index%2 === 1 ? 'even ' : '') + 'duplicateCheck'}>
                  <ColumnContent className="detail small" style={{gridColumn: '1'}}>
                    {index + 1}
                  </ColumnContent>
                  <ColumnContent className="detail small" style={{gridColumn: '2'}}>
                    {row.message}
                  </ColumnContent>
                  <ColumnContent className="detail small" style={{gridColumn: '3'}}>
                    {row.match}
                  </ColumnContent>
                </ComponentSectionRow>
              ))}
            </RowsContainer>
          :
            <RowsContainer style={{height: '100%'}}>
              <FlexWrapper className="column alignCenter" style={{margin: '1rem 0'}}>
                <span>No duplicates detected</span>
                <span>Fields Checked: {duplicatesReport[1].map((row, index) => (<span key={index}>{row.checks}</span>))}</span>
              </FlexWrapper>
            </RowsContainer>
        :
          <RowsContainer style={{height: '100%'}}>
            <FlexWrapper className="column alignCenter" style={{margin: '1rem 0'}}>
              <span>No duplicates detected</span>
            </FlexWrapper>
          </RowsContainer>
        }
      </FlexWrapper>
      <ComponentBorder className="top" style={{gridRow:'1', gridColumn: '1/4'}} />
      <ComponentBorder className="left" style={{gridRow:'2', gridColumn: '1'}} />
      <ComponentBorder className="right"  style={{gridRow:'2', gridColumn: '3'}} />
      <ComponentBorder className="bottom" style={{gridRow:'3', gridColumn: '1/4'}} />
    </ComponentContainer>
  )
}

export default Duplicates;
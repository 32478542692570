import React, { useEffect, useRef, useState } from "react";
import Tooltip from '@mui/material/Tooltip';
import SaveIcon from '@mui/icons-material/Save';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from "@mui/icons-material/Error";
import CloseIcon from '@mui/icons-material/Close';
import CancelIcon from '@mui/icons-material/Cancel';
import { useTheme } from "@mui/styles";
import BackspaceIcon from '@mui/icons-material/Backspace';
import { useSelector } from "react-redux";
import MortgageTagDropdown from "../../../DropDowns/MortgageTagDropwdown";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { selectTeamMortgageTags } from "../../../../slices/teamAndUserSettingsSlice";
import { useAddMortgageTagMutation } from "../../../../slices/api/apiSlice";
import { StyledTextArea, tooltipComponentProps, tooltipPopperProps } from "../../../../componentThemes";
import { AccentInfo, ComponentSectionRow, DetailWrapper, ErrorWrapper, Label, InputWrapper, ModalContentContainer, ComponentHeader, FlexWrapper, StatusAndLabelWrapper, StyledInputElement, CardButton, Button, ButtonWrapper, ActionIconWrapper, Divider } from "../../../../StyledComponents";

const MortgageTags = ({ mortgageData, userFullName, teamId, dashPerspective, userId, handleCloseModal }) => {
  const theme = useTheme()

  const teamMortgageTags = useSelector(selectTeamMortgageTags)
  const [addMortgageTag, {isFetching, isSuccess, isError}] = useAddMortgageTagMutation()

  const [availableTags, setAvailableTags] = useState(teamMortgageTags)
  const [newTags, setNewTags] = useState([])
  const [statusEmpty, setStatusEmpty] = useState(false)
  const [descriptionCount, setDescriptionCount] = useState(0)
  const [titleCount, setTitleCount] = useState(0)
  const [tagTitleValue, setTagTitleValue] = useState('')
  const [tagDescriptionValue, setTagDescriptionValue] = useState('')
  const [createTag, setCreateTag] = useState(false)
  const [statusTitleLength, setStatusTitleLength] = useState(null)
  const [statusTitleCharacter, setStatusTitleCharacter] = useState(false)
  const [statusTitleExisting, setStatusTitleExisting] = useState(false)
  const tagTitle = useRef()
  const [statusDescriptionLength, setStatusDescriptionLength] = useState(null)
  const [statusDescriptionCharacter, setStatusDescriptionCharacter] = useState(false)
  const tagDescription = useRef()

  const handleSetNewTags = (event) => {
    setNewTags(event.target.value)
    setStatusEmpty(false)
  }

  const handleClearFields = () => {
    setTagTitleValue('')
    setTagDescriptionValue('')
    setTitleCount(0)
    setDescriptionCount(0)
    setStatusEmpty(false)
    setStatusTitleLength(null)
    setStatusTitleCharacter(false)
    setStatusTitleExisting(false)
    setStatusDescriptionLength(null)
    setStatusDescriptionCharacter(false)
  }

  const handleSaveNewTag = async (action) => {
    if (action === 'create') {
      if (tagDescription.current.value.includes('<') || tagDescription.current.value.includes('>') || tagDescription.current.value.length > 255 || tagDescription.current.value.length === 0 || tagTitle.current.value.includes('<') || tagTitle.current.value.includes('>') || tagTitle.current.value.length > 255 || tagTitle.current.value.length === 0) {
        if (tagDescription.current.value.includes('<') || tagDescription.current.value.includes('>')) {
          setStatusDescriptionCharacter(true)
        }
        if (tagDescription.current.value.length > 255) {
          setStatusDescriptionLength("tooLong")
        }
        if (tagDescription.current.value.length === 0) {
          setStatusDescriptionLength("tooShort")
        }
        if (tagTitle.current.value.includes('<') || tagTitle.current.value.includes('>')) {
          setStatusTitleCharacter(true)
        }
        if (tagTitle.current.value.length > 255) {
          setStatusTitleLength("tooLong")
        }
        if (tagTitle.current.value.length === 0) {
          setStatusTitleLength("tooShort")
        }
      } else {
        let matchedTag = teamMortgageTags.find(tag => tag.label.toUpperCase() === tagTitle.current.value.toUpperCase())
        if (matchedTag) {
          setStatusTitleExisting(true)
        } else {
          await addMortgageTag({
            action: action,
            tagDescription: tagDescription.current.value,
            tagTitle: tagTitle.current.value,
            userFullName: userFullName,
            teamId: teamId,
            mortgageId: mortgageData._id,
            dashPerspective: dashPerspective,
            userId: userId,
          })
          setTagTitleValue('')
          setTagDescriptionValue('')
          setTitleCount(0)
          setDescriptionCount(0)
          setCreateTag(false)
        }
      } 
    } else {
      if (newTags.length === 0) {
        setStatusEmpty(true)
      } else {
        await addMortgageTag({
          action: action,
          newTags: newTags,
          userFullName: userFullName,
          teamId: teamId,
          mortgageId: mortgageData._id,
          dashPerspective: dashPerspective,
          userId: userId,
        })
        setTagTitleValue('')
        setTagDescriptionValue('')
        setTitleCount(0)
        setDescriptionCount(0)
        setCreateTag(false)
      }
    }
  }

  const handleCreateTag = () => {
    setCreateTag(true)
  }

  const handleTitleCount = () => {
    setStatusTitleExisting(false)
    setTitleCount(tagTitle.current.value.length)
		if (tagTitle.current.value.includes('<') || tagTitle.current.value.includes('>')) {
			setStatusTitleCharacter(true)
		} else {
			setStatusTitleCharacter(false)
		}
		if (tagTitle.current.value.length > 45) {
			setStatusTitleLength("tooLong")
		} else {
			setStatusTitleLength(null)
    }
  }
  const handleDescriptionCount = () => {
    setDescriptionCount(tagDescription.current.value.length)
		if (tagDescription.current.value.includes('<') || tagDescription.current.value.includes('>')) {
			setStatusDescriptionCharacter(true)
		} else {
			setStatusDescriptionCharacter(false)
		}
		if (tagDescription.current.value.length > 255) {
			setStatusDescriptionLength("tooLong")
		} else {
			setStatusDescriptionLength(null)
    }
  }

  const handleRemoveTag = (tagId) => {

  }

  useEffect(() => {
    let newAvailableTags = [...teamMortgageTags]
    for (let i = 0; i < mortgageData.tags.length; i++) {
      let thisTag = newAvailableTags.find(tag => tag._id.toString() === mortgageData.tags[i].tagId.toString())
      let thisIndex = newAvailableTags.indexOf(thisTag)
      newAvailableTags.splice(thisIndex, 1)
    }
    setAvailableTags(newAvailableTags)
  }, [mortgageData])

  return (
    <>
      {isSuccess ?
        <ComponentHeader className="modal green"><CheckIcon style={{height: '2.5rem', width: '2.5rem', margin: '0 0.5rem 0.2rem 0'}} />Success</ComponentHeader>
      : isError ?
        <ComponentHeader className="modal red"><ErrorIcon style={{height: '2.5rem', width: '2.5rem', margin: '0 0.5rem 0.2rem 0'}} />failed... try again or contact support@lancastersweep.com</ComponentHeader>
      :
        <ComponentHeader className="modal">Edit Tags</ComponentHeader>
      }

      <ModalContentContainer className="topColumn leadActions">

        <ComponentHeader className="section top" style={{gridColumn: '1', gridRow: '1', margin: '1rem 0 1rem 3rem'}}>Assigned Tags</ComponentHeader>
          {mortgageData.tags.length > 0 ?
            mortgageData.tags.map((tag, index) => (
              <ComponentSectionRow key={index} style={{width: '90%', margin: '0 auto'}}>
                <ActionIconWrapper className="hover" onClick={() => handleRemoveTag(tag._id)}>
                  <CloseIcon style={{margin: '0 0 0 3rem'}} />
                </ActionIconWrapper>
                <FlexWrapper className="column" style={{margin: '0 0 0 1rem'}}>
                  <FlexWrapper className="alignCenter">
                    <div style={{marginRight: '1rem', fontWeight: '600'}}>
                      <CardButton className="horizontal noHover" style={{width: 'fit-content'}}>
                        <span>{tag.label}</span>
                      </CardButton>
                    </div>
                    <span style={{fontWeight: '600'}}>{tag.description}</span>
                  </FlexWrapper>
                </FlexWrapper>
              </ComponentSectionRow>
            ))
          :
            <ComponentSectionRow>
              <AccentInfo style={{margin: '1rem 0 0 9rem'}}>-no tags-</AccentInfo>
            </ComponentSectionRow>
          }

          <Divider className="gray" style={{width: '85%', margin: '2rem 0 0 0'}} />

          <ComponentSectionRow style={{margin: 'auto'}}>
            <FlexWrapper className="column">
              <FlexWrapper style={{width: 'fit-content', margin: '0 auto'}}>
                <InputWrapper>
                  <StatusAndLabelWrapper>
                    {mortgageData.tags.length > 0 ?
                      <Label>Assign Additional Tags</Label>
                    :
                      <Label>Assign Tags</Label>
                    }
                    <FlexWrapper className="fitContentWidth alignCenter" style={{height: '2rem'}}>
                      {statusEmpty === true && (
                        <ErrorWrapper>
                          <ErrorIcon />
                          Select a tag
                        </ErrorWrapper>
                      )}
                    </FlexWrapper>
                  </StatusAndLabelWrapper>
                  <MortgageTagDropdown
                    style={{width: '100%'}}
                    value={newTags}
                    handleChange={handleSetNewTags}
                    items={availableTags}
                  />
                </InputWrapper>
                {isFetching ?
                  <Button className="primaryLoading notes" style={{marginRight: '0.5rem'}}><SaveIcon /></Button>
                : newTags.length > 0 ?
                  <Tooltip
                    componentsProps={tooltipComponentProps}
                    PopperProps={tooltipPopperProps}
                    title={<FlexWrapper><span style={{padding: '0', margin: '0', fontWeight: '600'}}>Save</span></FlexWrapper>} 
                    placement="top"
                    arrow
                  >
                    <Button onClick={() => handleSaveNewTag('add')} className="primary notes" style={{marginRight: '0.5rem'}}><SaveIcon /></Button>
                  </Tooltip>
                :
                  <Tooltip
                    componentsProps={tooltipComponentProps}
                    PopperProps={tooltipPopperProps}
                    title={<FlexWrapper><span style={{padding: '0', margin: '0', fontWeight: '600'}}>No Tags Selected</span></FlexWrapper>} 
                    placement="top"
                    arrow
                  >
                    <Button className="primaryDisabled notes" style={{marginRight: '0.5rem'}}><SaveIcon /></Button>
                  </Tooltip>
                }
              </FlexWrapper>
              <DetailWrapper className={createTag ? 'addButton active' : 'addButton'} style={{margin: '3rem auto 0 auto', padding: '0.2rem 1rem', height: 'fit-content'}} onClick={() => handleCreateTag()}>
                <AddCircleOutlineIcon style={{height: '2rem', width: '2rem', color: `${theme.palette.primary.main}`, margin: '0 0.3rem 0 0'}} /> 
                Create a New Tag
              </DetailWrapper>
            </FlexWrapper>
          </ComponentSectionRow>

          {createTag ?
            <div style={{width: '100%', padding: '0 5rem', margin: 'auto'}}>
              <div style={{width: '30rem', margin: '0 auto'}}>
                <InputWrapper className="notes" style={{margin: '0 1rem 0.5rem 1rem', width: '18rem'}}>
                  <StatusAndLabelWrapper className="notes">
                    <Label>Tag Title</Label>
                    <FlexWrapper className="fitContentWidth alignCenter" style={{height: '2rem'}}>
                      {statusTitleCharacter && (
                        <ErrorWrapper>
                          <ErrorIcon />
                          {`Cannot include the characters " < " or " > "`}
                        </ErrorWrapper>
                      )}
                      {statusTitleLength === "tooLong" && (
                        <ErrorWrapper>
                          <ErrorIcon />
                          {`Too long`}
                        </ErrorWrapper>
                      )}
                      {statusTitleLength === "tooShort" && (
                        <ErrorWrapper>
                          <ErrorIcon />
                          {`Cannot be blank`}
                        </ErrorWrapper>
                      )}
                      {statusTitleExisting && (
                        <ErrorWrapper>
                          <ErrorIcon />
                          {`Tag already exists`}
                        </ErrorWrapper>
                      )}
                      <Label className="count">{titleCount}/45</Label>
                    </FlexWrapper>
                  </StatusAndLabelWrapper>
                  <StyledInputElement className="borderBottomOnly" style={{width: '100%'}}
                    autoFocus
                    value={tagTitleValue}
                    type="text"
                    name="TagTitle"
                    id="TagTitle"
                    ref={tagTitle}
                    onChange={() => {
                      if (statusTitleLength === "tooShort" || statusTitleLength === "tooLong") {
                        setStatusTitleLength(null)
                      }
                      if (statusTitleCharacter) {
                        setStatusTitleCharacter(null)
                      }
                      setTagTitleValue(tagTitle.current.value)
                      handleTitleCount()
                    }}
                  />
                </InputWrapper>
                <InputWrapper className="notes" style={{width: '100%'}}>
                  <StatusAndLabelWrapper className="notes">
                    <Label>Tag Description</Label>
                    <FlexWrapper className="fitContentWidth alignCenter" style={{height: '2rem'}}>
                      {statusDescriptionCharacter === true && (
                        <ErrorWrapper>
                          <ErrorIcon />
                          {`Cannot include the characters " < " or " > "`}
                        </ErrorWrapper>
                      )}
                      {statusDescriptionLength === "tooLong" && (
                        <ErrorWrapper>
                          <ErrorIcon />
                          {`Too long`}
                        </ErrorWrapper>
                      )}
                      {statusDescriptionLength === "tooShort" && (
                        <ErrorWrapper>
                          <ErrorIcon />
                          {`Cannot be blank`}
                        </ErrorWrapper>
                      )}
                      <Label className="count">{descriptionCount}/255</Label>
                    </FlexWrapper>
                  </StatusAndLabelWrapper>
                  <StyledTextArea
                    style={{height: '7rem'}}
                    value={tagDescriptionValue}
                    type="text"
                    name="tagDescription"
                    id="tagDescription"
                    ref={tagDescription}
                    onChange={() => {
                      if (statusDescriptionLength === "tooShort" || statusDescriptionLength === "tooLong") {
                        setStatusDescriptionLength(null)
                      }
                      if (statusDescriptionCharacter) {
                        setStatusDescriptionCharacter(false)
                      }
                      setTagDescriptionValue(tagDescription.current.value)
                      handleDescriptionCount()
                    }}
                  />
                </InputWrapper>
                <FlexWrapper style={{margin: '3rem auto 0 auto', width: 'fit-content'}}>
                  {isFetching ?
                    <Button className="primaryLoading notes" style={{marginRight: '0.5rem'}}><SaveIcon /></Button>
                  :
                    <Tooltip
                      componentsProps={tooltipComponentProps}
                      PopperProps={tooltipPopperProps}
                      title={<FlexWrapper><span style={{padding: '0', margin: '0', fontWeight: '600'}}>Save</span></FlexWrapper>} 
                      placement="top"
                      arrow
                    >
                      <Button onClick={() => handleSaveNewTag('create')} className="primary notes" style={{marginRight: '0.5rem'}}><SaveIcon /></Button>
                    </Tooltip>
                  }
                  <Tooltip
                    componentsProps={tooltipComponentProps}
                    PopperProps={tooltipPopperProps}
                    title={<FlexWrapper><span style={{padding: '0', margin: '0', fontWeight: '600'}}>Clear Inputs</span></FlexWrapper>} 
                    placement="top"
                    arrow
                  >
                    <Button onClick={() => handleClearFields()} className="grayDisabled notes" style={{margin: '0 0.5rem 0 0'}}><BackspaceIcon /></Button>
                  </Tooltip>
                  <Tooltip
                    componentsProps={tooltipComponentProps}
                    PopperProps={tooltipPopperProps}
                    title={<FlexWrapper><span style={{padding: '0', margin: '0', fontWeight: '600'}}>Cancel</span></FlexWrapper>} 
                    placement="top"
                    arrow
                  >
                    <Button onClick={() => setCreateTag(false)} className="grayDisabled notes"><CancelIcon /></Button>
                  </Tooltip>
                </FlexWrapper>
              </div>
            </div>
          :
            <></>
          }

          <ButtonWrapper style={{margin: '3rem auto'}}>
            <Button className="gray" onClick={() => handleCloseModal()}>Cancel</Button>
          </ButtonWrapper>

      </ModalContentContainer>
    </>
  )
}

export default MortgageTags;
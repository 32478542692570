import React , {useState} from "react";
import { useTheme } from "@mui/styles";
import { styled } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MuiBadge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import GroupsIcon from '@mui/icons-material/Groups';
import AdjustIcon from '@mui/icons-material/Adjust';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import EditNoteIcon from '@mui/icons-material/EditNote';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import SummarizeIcon from '@mui/icons-material/Summarize';
import FlagCircleIcon from '@mui/icons-material/FlagCircle';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ChangeTargetOutcome from "../../../../../../components/Modals/LeadActions/components/ChangeTargetOutcome";
import { Detail, DetailContainer, DetailInfoContainer, DetailListContainer, CardButton, ActionMenuItem, ActionMenuTitle, Badge, NewLabel, DetailHeaderWrapper, CardIndex, DetailTagContainer, AddButton, AccentInfo, FlexWrapper } from "../../../../../../StyledComponents";

const InvestigatingCard = ({ lead, userId, userFullName, dashPerspective, handleOpenLeadActionsModal, cardIndex }) => {
  const theme = useTheme();

  const badgeStyle = {
    "& .MuiBadge-badge": {
      color: `${theme.palette.common.white}`,
      backgroundColor: `${theme.palette.common.error}`,
      fontWeight: '800',
      fontSize: "1rem",
      height: "1.4rem",
      padding: "0 0.5rem",
      borderRadius: "1rem",
      minWidth: "1.2rem",
    }
  }

  const [changeOutcomeDetails, setChangeOutcomeDetails] = useState({})

  const [menuActive, setMenuActive] = useState(false)
  const [actionMenuAnchorEl, setActionMenuAnchorEl] = useState(null);
  const openActionsMenu = Boolean(actionMenuAnchorEl);
  const [outcomeMenuAnchorEl, setOutcomeMenuAnchorEl] = useState(null);
  const openOutcomeMenu = Boolean(outcomeMenuAnchorEl);

  const handleOpenActions = (event) => {
    setActionMenuAnchorEl(event.currentTarget);
    setMenuActive(true);
  };
  const handleCloseActions = () => {
    setActionMenuAnchorEl(null)
    setMenuActive(false)
    setOutcomeMenuAnchorEl(null)
  };
  
  const handleOpenOutcomeMenu = (event, leadId, timeline, oldOutcome, userNotifications) => {
    let newOutcome = ''
    if (oldOutcome === 'renegotiation') {
      newOutcome = 'refinance'
    } else {
      newOutcome = 'renegotiation'
    }
    setChangeOutcomeDetails({
      leadId: leadId, 
      timeline: timeline,
      newOutcome: newOutcome,
      userNotifications: userNotifications,
    })
    setOutcomeMenuAnchorEl(event.currentTarget);
  }
  const handleCloseOutcomeMenu = () => {
    setOutcomeMenuAnchorEl(null)
    setActionMenuAnchorEl(null)
    setMenuActive(false)
  };

  return (
    <>
      <CardIndex>
        {cardIndex+1}
      </CardIndex>
      <div style={{width: '6rem', margin: '0 0 0 0.5rem', display: 'flex', justifyContent: 'space-between'}}>
        {lead.tier === 0 ?
          <CardButton
            className="tier manualInvestigation"
            variant="contained"
            disableElevation
            onClick={() => handleOpenLeadActionsModal('compareRecords', lead, lead.userNotifications, lead.belongsToMortgage)}
          >
            <span style={{transform: "rotate(-90deg)", display: "flex", textWrap: 'nowrap', fontSize: '1.2rem'}}>
              Manual Investigation
            </span>
          </CardButton>
        : lead.tier === 1 ?
          <CardButton
            className="tier one"
            variant="contained"
            disableElevation
            onClick={() => handleOpenLeadActionsModal('compareRecords', lead, lead.userNotifications, lead.belongsToMortgage)}
          >
            <span style={{transform: "rotate(-90deg)", display: "flex"}}>
              {lead.activeDiscrepancies > 0 ?
                <Badge className="one">{lead.activeDiscrepancies}</Badge>
              :
                <></>
              }
              TIER
              <span style={{padding: "0 0 0 0.2rem"}}>1</span>
              <OpenInNewIcon style={{padding: "0 0 0.3rem 0"}} />
            </span>
          </CardButton>
        : lead.tier === 2 ?
          <CardButton
            className="tier two"
            variant="contained"
            disableElevation
            onClick={() => handleOpenLeadActionsModal('compareRecords', lead, lead.userNotifications, lead.belongsToMortgage)}
          >
            <span style={{transform: "rotate(-90deg)", display: "flex"}}>
              {lead.activeDiscrepancies > 0 ?
                <Badge className="two">{lead.activeDiscrepancies}</Badge>
              :
                <></>
              }
              TIER
              <span style={{padding: "0 0 0 0.2rem"}}>2</span>
              <OpenInNewIcon style={{padding: "0 0 0.3rem 0"}} />
            </span>
          </CardButton>
        :
          <CardButton
            className="tier three"
            variant="contained"
            disableElevation
            onClick={() => handleOpenLeadActionsModal('compareRecords', lead, lead.userNotifications, lead.belongsToMortgage)}
          >
            <span style={{transform: "rotate(-90deg)", display: "flex"}}>
              {lead.activeDiscrepancies > 0 ?
                <Badge className="three">{lead.activeDiscrepancies}</Badge>
              :
                <></>
              }
              TIER
              <span style={{padding: "0 0 0 0.2rem"}}>3</span>
              <OpenInNewIcon style={{padding: "0 0 0.3rem 0"}} />
            </span>
          </CardButton>
        }
        <CardButton
          variant="contained"
          disableElevation
          onClick={handleOpenActions}
          className={`${menuActive ? "active" : "actions"}`}
        >
          <span style={{transform: "rotate(-90deg)", display: "flex"}}>
            ACTIONS
            {lead.userNotifications > 0 ?
              <MuiBadge 
              badgeContent={lead.userNotifications} 
              color="error" 
              sx={badgeStyle}   
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              >
                <KeyboardArrowDownIcon style={{padding: "0 0 0.3rem 0"}} />
              </MuiBadge>
            :
              <KeyboardArrowDownIcon style={{padding: "0 0 0.3rem 0"}} />
            }
          </span>
        </CardButton>
        <StyledMenu
          anchorEl={actionMenuAnchorEl}
          open={openActionsMenu}
          onClose={handleCloseActions}
        >
          {lead.belongsToMortgage.reports.length === 0 ?
            <MenuItem onClick={handleCloseActions} disableRipple className="border topRadius" style={{background: `${theme.palette.common.disabledGray}`}}>
              <ActionMenuItem className="noHover topRadius">
                <SummarizeIcon style={{color: `${theme.palette.common.grayText}`, margin: "0", height: "2.2rem", width: "2.2rem"}} />
                <ActionMenuTitle>Reports ({lead.belongsToMortgage.reports.length})</ActionMenuTitle>
              </ActionMenuItem>
            </MenuItem>
          :
            <MenuItem onClick={handleCloseActions} disableRipple className="border topRadius">
              <ActionMenuItem onClick={() => handleOpenLeadActionsModal('reports', lead, lead.userNotifications, lead.belongsToMortgage)}>
                <SummarizeIcon style={{color: `${theme.palette.common.grayText}`, margin: "0", height: "2.2rem", width: "2.2rem"}} />
                <ActionMenuTitle>Reports ({lead.belongsToMortgage.reports.length})</ActionMenuTitle>
              </ActionMenuItem>
            </MenuItem>
          }
          <MenuItem onClick={handleCloseActions} disableRipple className="border">
            <ActionMenuItem onClick={() => handleOpenLeadActionsModal('notes', lead, lead.userNotifications, lead.belongsToMortgage)}>
              <EditNoteIcon style={{color: `${theme.palette.common.grayText}`, margin: "0", height: "2.2rem", width: "2.2rem"}} />
              <ActionMenuTitle>Notes ({lead.belongsToMortgage.mortgageNotes.length})</ActionMenuTitle>
            </ActionMenuItem>
          </MenuItem>
          <MenuItem onClick={(e) => handleOpenOutcomeMenu(e, lead._id, lead.timeline, lead.targetOutcome, lead.userNotifications)} disableRipple className="border">
            <ActionMenuItem>
              <FlagCircleIcon style={{color: `${theme.palette.common.grayText}`, margin: "0", height: "2.2rem", width: "2.2rem"}} />
              {lead.targetOutcome === 'renegotiation' ?
                  <ActionMenuTitle>Switch to Refinance</ActionMenuTitle>
                :
                  <ActionMenuTitle>Switch to Renegotiation</ActionMenuTitle>
              }
            </ActionMenuItem>
          </MenuItem>
          <MenuItem onClick={handleCloseActions} disableRipple className="border">
            <ActionMenuItem onClick={() => handleOpenLeadActionsModal('compareRecords', lead, lead.userNotifications, lead.belongsToMortgage)}>
              <FileCopyIcon style={{color: `${theme.palette.common.grayText}`, margin: "0", height: "2.2rem", width: "2.2rem"}} />
              <ActionMenuTitle>Compare Records</ActionMenuTitle>
            </ActionMenuItem>
          </MenuItem>
          <MenuItem onClick={handleCloseActions} disableRipple className="border">
            <ActionMenuItem onClick={() => handleOpenLeadActionsModal('propertyDetails', lead, lead.userNotifications, lead.belongsToMortgage)}>
              <HomeWorkIcon style={{color: `${theme.palette.common.grayText}`, margin: "0", height: "2.2rem", width: "2.2rem"}} />
              <ActionMenuTitle>Property Details</ActionMenuTitle>
            </ActionMenuItem>
          </MenuItem>
          <MenuItem onClick={handleCloseActions} disableRipple className="border">
            <ActionMenuItem onClick={() => handleOpenLeadActionsModal('ownerDetails', lead, lead.userNotifications, lead.belongsToMortgage)}>
              <GroupsIcon style={{color: `${theme.palette.common.grayText}`, margin: "0", height: "2.2rem", width: "2.2rem"}} />
              <ActionMenuTitle>Owner Details</ActionMenuTitle>
            </ActionMenuItem>
          </MenuItem>
          <MenuItem onClick={handleCloseActions} disableRipple className="border">
            <ActionMenuItem onClick={() => handleOpenLeadActionsModal('editTargets', lead, lead.userNotifications, lead.belongsToMortgage)}>
              <AdjustIcon style={{color: `${theme.palette.common.grayText}`, margin: "0", height: "2.2rem", width: "2.2rem"}} />
              <ActionMenuTitle>Change Targets</ActionMenuTitle>
            </ActionMenuItem>
          </MenuItem>
          <MenuItem onClick={handleCloseActions} disableRipple className="border">
            <ActionMenuItem onClick={() => handleOpenLeadActionsModal('addAssignees', lead, lead.userNotifications, lead.belongsToMortgage)}>
              <PersonAddIcon style={{color: `${theme.palette.common.grayText}`, margin: "0", height: "2.2rem", width: "2.2rem"}} />
              <ActionMenuTitle>Add Assignees</ActionMenuTitle>
            </ActionMenuItem>
          </MenuItem>
          <MenuItem onClick={handleCloseActions}  className="button buttonTop" disableRipple style={{cursor: "default"}}>
            <ActionMenuItem 
              className="advance" 
              style={{width: '14rem', margin: '0'}} 
              onClick={() => handleOpenLeadActionsModal('beginClosingLead', lead, lead.userNotifications, lead.belongsToMortgage)}
            >
              <ActionMenuTitle className="white">Begin Closing</ActionMenuTitle>
            </ActionMenuItem>
          </MenuItem>
          <MenuItem onClick={handleCloseActions} className="button bottomRadius" disableRipple style={{cursor: "default"}}>
            <ActionMenuItem 
              className="dismiss" 
              style={{width: '14rem', margin: '0'}} 
              onClick={() => handleOpenLeadActionsModal('dismissLead', lead, lead.userNotifications, lead.belongsToMortgage)}
            >              
              <ActionMenuTitle className="white">Dismiss</ActionMenuTitle>
            </ActionMenuItem>
          </MenuItem>
        </StyledMenu>
        <StyledMenu
          anchorEl={outcomeMenuAnchorEl}
          open={openOutcomeMenu}
          onClose={handleCloseOutcomeMenu}
        >
          <ChangeTargetOutcome userId={userId} userFullName={userFullName} changeOutcomeDetails={changeOutcomeDetails} dashPerspective={dashPerspective} />
        </StyledMenu>
      </div>

      <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
        <div style={{display: 'flex'}}>
          <DetailContainer style={{maxWidth: '25rem', minWidth: '25rem'}}>
            {lead.targetOutcome === "renegotiation" ?
              lead.newAssignmentNotification ?
                <DetailHeaderWrapper>
                  <NewLabel className="leadCard">Assigned</NewLabel>
                  <Detail style={{gridColumn: '4'}} className="header outcome renegotiation">
                    Renegotiation
                  </Detail>
                </DetailHeaderWrapper>
              :
                <DetailHeaderWrapper>
                  <Detail style={{gridColumn: '4'}} className="header outcome renegotiation">
                    Renegotiation
                  </Detail>
                </DetailHeaderWrapper>
            :
              lead.newAssignmentNotification ?
                <DetailHeaderWrapper>
                  <NewLabel className="leadCard">Assigned</NewLabel>
                  <Detail style={{gridColumn: '4'}} className="header outcome refinance">
                  Refinance
                  </Detail>
                </DetailHeaderWrapper>
              :
                <DetailHeaderWrapper>
                  <Detail style={{gridColumn: '4'}} className="header outcome refinance">
                    Refinance
                  </Detail>
                </DetailHeaderWrapper>
            }
            <DetailListContainer>
              <DetailInfoContainer className="item">
                <Detail className="item">Active Discrepancies:</Detail>
                <Detail className="item">Inactive Discrepancies:</Detail>
                <Detail className="item">Date Discovered:</Detail>
                <Detail className="item">Began Investigating:</Detail>
                {lead.assigneeNames.length === 1 ?
                  <Detail className="item">Assignee:</Detail>
                :
                  <Detail className="item">Assignees:</Detail>
                }
              </DetailInfoContainer>
              <DetailInfoContainer>
                <Detail>
                  {lead.activeDiscrepancies > 0 ?
                    <>
                      {lead.activeDiscrepancies}
                    </>
                  : 
                    <span style={{color: `${theme.palette.common.grayText}`}}>none</span>
                  }
                </Detail>
                {lead.rejectedDiscrepancies === 0 && lead.resolvedDiscrepancies === 0 ?
                  <span style={{color: `${theme.palette.common.grayText}`}}>none</span>
                : lead.rejectedDiscrepancies === 0 && lead.resolvedDiscrepancies > 0 ?
                  <Detail>{lead.resolvedDiscrepancies} resolved</Detail>
                : lead.rejectedDiscrepancies > 0 && lead.resolvedDiscrepancies === 0 ?
                  <Detail>{lead.rejectedDiscrepancies} rejected</Detail>
                :
                  <Detail>{lead.resolvedDiscrepancies} resolved, {lead.rejectedDiscrepancies} rejected</Detail>
                }
                <Detail>{lead.dateDiscoveredLabel}</Detail>
                <Detail>{lead.dateInvestigating}</Detail>
                {lead.assigneeNames.length === 0 ?
                  <Detail>
                    <span style={{color: `${theme.palette.common.grayText}`}}>none</span>
                  </Detail>
                : lead.assigneeNames.length === 1 ?
                  <Detail>{lead.assigneeNames[0]}</Detail>
                :
                  lead.assigneeNames.map((assignee, index) => (
                    <Detail key={index}>{assignee}</Detail>
                  ))
                }
              </DetailInfoContainer>
            </DetailListContainer>
          </DetailContainer>

          <DetailContainer style={{width: '100%', maxWidth: '35rem'}}>
            <DetailHeaderWrapper className="simple" style={{margin: '0 0 0.3rem 0'}}>
              <Detail className="header" style={{gridColumn: '1'}}>Loan Info</Detail>
            </DetailHeaderWrapper>
            <DetailListContainer>
              <DetailInfoContainer className="item">
                <Detail className="item">Address:</Detail>
                <Detail className="item"></Detail>
                {lead.belongsToMortgage.owner2 ?
                  <>
                    <Detail className="item">Borrowers:</Detail>
                    <Detail className="item"></Detail>
                  </>
                : lead.belongsToMortgage.owner1 ?
                  <Detail className="item">Borrower:</Detail>
                :
                  <Detail className="item">Borrowers:</Detail>
                }
                <Detail className="item">Term:</Detail>
                <Detail className="item">Remaining:</Detail>
              </DetailInfoContainer>
              <DetailInfoContainer>
                <Detail>{lead.belongsToMortgage.streetAddress}</Detail>
                <Detail>{lead.belongsToMortgage.recordDetails.address.City.currentValue}, {lead.belongsToMortgage.recordDetails.address.StateOrProvince.currentValue} {lead.belongsToMortgage.recordDetails.address.PostalCode.currentValue}</Detail>
                {lead.belongsToMortgage.owner1 ?
                  <>
                    <Detail>{lead.belongsToMortgage.owner1}</Detail>
                    <Detail>{lead.belongsToMortgage.owner2}</Detail>
                  </>
                : lead.belongsToMortgage.owner1 ?
                  <Detail>{lead.belongsToMortgage.owner1}</Detail>
                :
                  <Detail><AccentInfo>-empty-</AccentInfo></Detail>
                }
                <Detail>{lead.belongsToMortgage.originationDateLabel} to {lead.belongsToMortgage.endDateLabel}</Detail>
                <Detail>{lead.belongsToMortgage.remainingTerm}</Detail>
              </DetailInfoContainer>
            </DetailListContainer>
          </DetailContainer>

          <DetailContainer style={{maxWidth: '20rem', minWidth: '20rem'}}>
            <DetailHeaderWrapper className="simple" style={{margin: '0 0 0.3rem 0'}}>
              <Detail className="header" style={{gridColumn: '1'}}>Financials</Detail>
            </DetailHeaderWrapper>
            <DetailListContainer>
              <DetailInfoContainer className="item">
                <Detail className="item">Loan Amount:</Detail>
                <Detail className="item">Principal Remaining:</Detail>
                <Detail className="item">Original Interest Rate:</Detail>
                <Detail className="item">Original Interest Due:</Detail>
                <Detail className="item">Interest Remaining:</Detail>
              </DetailInfoContainer>
              <DetailInfoContainer>
                {lead.belongsToMortgage.originalLoanAmount ?
                  <>
                    <Detail>${lead.belongsToMortgage.originalLoanAmount.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}</Detail>
                    <Detail>${lead.belongsToMortgage.principalRemaining.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}</Detail>
                    <Detail>{lead.belongsToMortgage.originalInterestRate}%</Detail>
                    <Detail>${lead.belongsToMortgage.originalInterestDue.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}</Detail>
                    <Detail>${lead.belongsToMortgage.interestRemaining.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}</Detail>
                  </>
                :
                  <>
                    <Detail>N/A</Detail>
                    <Detail>N/A</Detail>
                    <Detail>N/A</Detail>
                    <Detail>N/A</Detail>
                    <Detail>N/A</Detail>
                  </>
                }
              </DetailInfoContainer>
            </DetailListContainer>
          </DetailContainer>

          <DetailContainer className="target" style={{maxWidth: '24rem', minWidth: '24rem'}}>
            <DetailHeaderWrapper className="simple" style={{margin: '0 0 0.3rem 0'}}>
              <Detail className="header" style={{gridColumn: '1'}}>Refinance Potential</Detail>
            </DetailHeaderWrapper>
            <DetailListContainer>
              <DetailInfoContainer className="potential item">
                <Detail className="item">Target Interest Rate:</Detail>
                <Detail className="item">Target Interest Due:</Detail>
                {lead.belongsToMortgage.originalLoanAmount ?
                  <>
                    <Detail className="potential" style={{margin: '0.5rem 0 0 0'}}>Change in Interest Owed:</Detail>
                    <Detail className="potential">Change in Revenue:</Detail>
                  </>
                :
                  <>
                    <Detail className="item">Change in Interest Owed:</Detail>
                    <Detail className="item">Change in Revenue:</Detail>
                  </>
                }
              </DetailInfoContainer>
              <DetailInfoContainer>
                <Detail>{lead.targetInterestRate}%</Detail>
                {lead.belongsToMortgage.originalLoanAmount ?
                  <>
                    <Detail>${lead.targetInterestDue.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}</Detail>
                    {parseInt(lead.targetProfitNumber) > 0 ?
                      <Detail className="target">${lead.targetProfitNumber.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}</Detail>
                    : parseInt(lead.targetProfitNumber) < 0 ?
                      <Detail className="target red">-${(lead.targetProfitNumber*-1).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}</Detail>
                    :
                      <Detail className="target">$0</Detail>
                    }
                    {parseInt(lead.targetProfitPercent) >= 0 ?
                      <Detail className="target">{lead.targetProfitPercent.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}%</Detail>
                    :
                      <Detail className="target red">{lead.targetProfitPercent.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}%</Detail>
                    }
                  </>
                :
                  <>
                    <Detail>N/A</Detail>
                    <Detail>N/A</Detail>
                    <Detail>N/A</Detail>
                  </>
                }
              </DetailInfoContainer>
            </DetailListContainer>
          </DetailContainer>
        </div>

        <div style={{width: '100%', display: 'flex'}}>
          {lead.tags.length === 0 ?
            <FlexWrapper style={{flexWrap: 'wrap', marginLeft: '1rem', padding: '0.2rem'}}>
              <span style={{color: `${theme.palette.common.grayText}`}}>no lead tags</span>
              <AddButton onClick={() => handleOpenLeadActionsModal('editTags', lead, lead.userNotifications, lead.belongsToMortgage)}>
                <AddCircleOutlineIcon style={{margin: "0", height: "100%", width: "100%"}} />
              </AddButton>
            </FlexWrapper>
          :
            <FlexWrapper style={{flexWrap: 'wrap', marginLeft: '1rem', padding: '0.2rem'}}>
              <span style={{margin: 'auto 0.5rem auto 0'}}>Lead Tags:</span>
              {lead.tags.map((tag, index) => (
                <CardButton key={index} className="horizontal" style={{margin: '0 0.2rem'}} onClick={() => handleOpenLeadActionsModal('editTags', lead, lead.userNotifications, lead.belongsToMortgage)}>
                  {tag.label}
                </CardButton>
              ))}
            </FlexWrapper>
          }
        </div>
        <div style={{width: '100%', display: 'flex'}}>
          {lead.tags.length === 0 ?
            <FlexWrapper style={{flexWrap: 'wrap', marginLeft: '1rem', padding: '0.2rem'}}>
              <span style={{color: `${theme.palette.common.grayText}`}}>no mortgage tags</span>
              <AddButton onClick={() => handleOpenLeadActionsModal('editTags', lead, lead.userNotifications, lead.belongsToMortgage)}>
                <AddCircleOutlineIcon style={{margin: "0", height: "100%", width: "100%"}} />
              </AddButton>
            </FlexWrapper>
          :
            <FlexWrapper style={{flexWrap: 'wrap', marginLeft: '1rem', padding: '0.2rem'}}>
              <span style={{margin: 'auto 0.5rem auto 0'}}>Mortgage Tags:</span>
              {lead.belongsToMortgage.tags.map((tag, index) => (
                <CardButton key={index} className="horizontal" style={{margin: '0 0.2rem'}} onClick={() => handleOpenLeadActionsModal('editTags', lead, lead.userNotifications, lead.belongsToMortgage)}>
                  {tag.label}
                </CardButton>
              ))}
            </FlexWrapper>
          }
        </div>

      </div>
    </>
  )
}

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '1rem',
    minWidth: 180,
    background: `${theme.palette.common.grayBorder}`,
    borderRight: `1rem solid ${theme.palette.common.grayBorder}`,
    borderLeft: `1rem solid ${theme.palette.common.grayBorder}`,
    borderTop: `0.4rem solid ${theme.palette.common.grayBorder}`,
    borderBottom: `0.4rem solid ${theme.palette.common.grayBorder}`,
    '& .MuiMenu-list:hover': {
    },
    '& .MuiMenuItem-root': {
      background: theme.palette.common.pageBackground,
      '&.buttonTop': {
        padding: '1rem 2rem 0.5rem 2rem',
      },
      '&.border': {
        borderBottom: `0.1rem solid ${theme.palette.common.grayBorder}`
      },
      '&.topRadius': {
        borderRadius: '0.6rem 0.6rem 0 0',
      },
      '&.bottomRadius': {
        borderRadius: '0 0 0.6rem 0.6rem',
        padding: '0.5rem 2rem 1rem 2rem',
      }
    },
    '& .MuiMenuItem-root:hover': {
      background: theme.palette.common.grayHover,
      '&.button': {
        background: theme.palette.common.pageBackground,
      },
    },
  },
}));

export default InvestigatingCard;
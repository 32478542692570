import React from "react";
import TeamStats from "./components/TeamStats";
import MainChart from "./components/MainChart/MainChart";
import { PageContent } from "../../../../StyledComponents";
import ClosuresPieChart from "../../../../components/PieCharts/ClosuresPieChart";
import TeamQuarterlyBreakdown from "./components/TeamQuarterlyBreakdown";
import RefinancesPieChart from "./components/RefinancesPieChart";

const RefinancesOverview = () => {

  return (
    <PageContent className="grid closure">
      <ClosuresPieChart />
      <RefinancesPieChart />
      <TeamQuarterlyBreakdown />
      <TeamStats />
      <MainChart />
    </PageContent>
  )
}

export default RefinancesOverview;
import { createSlice } from '@reduxjs/toolkit'
import { apiSlice } from './api/apiSlice'

const initialState = {
  data: [{
    memberId: '',
    fullName: '',
    role: '',
    email: '',
    emailScheduleLabel: '',
    awaitingActionLeads: [],
    investigatingLeads: [],
    closingLeads: [],
    awaitingUpdateLeads: [],
  }],
  status: 'idle',
  error: null
}

export const membersDataArraySlice = createSlice({
  name: 'membersDataArray',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addMatcher(apiSlice.endpoints.initialLoad.matchFulfilled, (state, {payload}) => {
      if (payload.data) {
        state.data = payload.data.membersDataArray
      }
    })
    .addMatcher(apiSlice.endpoints.editUserEmailSchedule.matchFulfilled, (state, {payload}) => {
      let thisInstance = state.data.find(member => member.memberId === payload.data.userId)
      let thisIndex = state.data.indexOf(thisInstance)
      state.data[thisIndex].emailScheduleLabel = payload.data.finalScheduleLabel
    })
    .addMatcher(apiSlice.endpoints.updateMortgageDetails.matchFulfilled, (state, {payload}) => {
      for (let i = 0; i < state.data.length; i++) {
        let memberToUpdate = payload.data.newLead.assigneeIds.find(assignee => assignee === state.data[i].memberId)
        if (memberToUpdate) {
          if (payload.data.leadStatus === 'investigating') {
            //* investigatingLeads
            let thisInvestigatingLead = state.data[i].investigatingLeads.find(lead => lead._id === payload.data.newLead._id)
            let thisInvestigatingIndex = state.data[i].investigatingLeads.indexOf(thisInvestigatingLead)
            state.data[i].investigatingLeads[thisInvestigatingIndex] = payload.data.newLead
          } else if (payload.data.leadStatus === 'closing') {
            //* closingLeads
            let thisClosingLead = state.data[i].closingLeads.find(lead => lead._id === payload.data.newLead._id)
            let thisClosingIndex = state.data[i].closingLeads.indexOf(thisClosingLead)
            state.data[i].closingLeads[thisClosingIndex] = payload.data.newLead
          } else {
            //* awaitingAction
            let thisAwaitingActionLead = state.data[i].awaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
            let thisAwaitingIndex = state.data[i].awaitingActionLeads.indexOf(thisAwaitingActionLead)
            state.data[i].awaitingActionLeads[thisAwaitingIndex] = payload.data.newLead
          }
        }
      }
    })
    .addMatcher(apiSlice.endpoints.addLeadTag.matchFulfilled, (state, {payload}) => {
      for (let i = 0; i < state.data.length; i++) {
        let memberToUpdate = payload.data.newLead.assigneeIds.find(assignee => assignee === state.data[i].memberId)
        if (memberToUpdate) {
          if (payload.data.leadStatus === 'investigating') {
            //* investigatingLeads
            let thisInvestigatingLead = state.data[i].investigatingLeads.find(lead => lead._id === payload.data.newLead._id)
            let thisInvestigatingIndex = state.data[i].investigatingLeads.indexOf(thisInvestigatingLead)
            state.data[i].investigatingLeads[thisInvestigatingIndex] = payload.data.newLead
          } else if (payload.data.leadStatus === 'closing') {
            //* closingLeads
            let thisClosingLead = state.data[i].closingLeads.find(lead => lead._id === payload.data.newLead._id)
            let thisClosingIndex = state.data[i].closingLeads.indexOf(thisClosingLead)
            state.data[i].closingLeads[thisClosingIndex] = payload.data.newLead
          } else {
            //* awaitingActionLeads
            let thisAwaitingActionLead = state.data[i].awaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
            let thisAwaitingActionIndex = state.data[i].awaitingActionLeads.indexOf(thisAwaitingActionLead)
            state.data[i].closingLeads[thisAwaitingActionIndex] = payload.data.newLead
          }
        }
      }
    })
    .addMatcher(apiSlice.endpoints.changeTargetOutcome.matchFulfilled, (state, {payload}) => {
      for (let i = 0; i < state.data.length; i++) {
        let memberToUpdate = payload.data.newLead.assigneeIds.find(assignee => assignee === state.data[i].memberId)
        if (memberToUpdate) {
          if (payload.data.leadStatus === 'investigating') {
            //* investigatingLeads
            let thisInvestigatingLead = state.data[i].investigatingLeads.find(lead => lead._id === payload.data.newLead._id)
            let thisInvestigatingIndex = state.data[i].investigatingLeads.indexOf(thisInvestigatingLead)
            state.data[i].investigatingLeads[thisInvestigatingIndex] = payload.data.newLead
          } else if (payload.data.leadStatus === 'closing') {
            //* closingLeads
            let thisClosingLead = state.data[i].closingLeads.find(lead => lead._id === payload.data.newLead._id)
            let thisClosingIndex = state.data[i].closingLeads.indexOf(thisClosingLead)
            state.data[i].closingLeads[thisClosingIndex] = payload.data.newLead
          } else {
            //* awaitingActionLeads
            let thisAwaitingActionLead = state.data[i].awaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
            let thisAwaitingActionIndex = state.data[i].awaitingActionLeads.indexOf(thisAwaitingActionLead)
            state.data[i].closingLeads[thisAwaitingActionIndex] = payload.data.newLead
          }
        }
      }
    })
    .addMatcher(apiSlice.endpoints.addAssignees.matchFulfilled, (state, {payload}) => {
      for (let i = 0; i < state.data.length; i++) {
        let memberToUpdate = payload.data.newLead.assigneeIds.find(assignee => assignee === state.data[i].memberId)
        if (memberToUpdate) {
          if (payload.data.leadStatus === 'investigating') {
            //* investigatingLeads
            let thisInvestigatingLead = state.data[i].investigatingLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisInvestigatingLead) {
              let thisInvestigatingIndex = state.data[i].investigatingLeads.indexOf(thisInvestigatingLead)
              state.data[i].investigatingLeads[thisInvestigatingIndex] = payload.data.newLead
            } else {
              state.data[i].investigatingLeads.unshift(payload.data.newLead)
            }
          } else if (payload.data.leadStatus === 'closing') {
            //* closingLeads
            let thisClosingLead = state.data[i].closingLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisClosingLead) {
              let thisClosingIndex = state.data[i].closingLeads.indexOf(thisClosingLead)
              state.data[i].closingLeads[thisClosingIndex] = payload.data.newLead
            } else {
              state.data[i].closingLeads.unshift(payload.data.newLead)
            }
          } else {
            //* awaitingAction
            let thisAwaitingActionLead = state.data[i].awaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisAwaitingActionLead) {
              let thisAwaitingIndex = state.data[i].awaitingActionLeads.indexOf(thisAwaitingActionLead)
              state.data[i].awaitingActionLeads[thisAwaitingIndex] = payload.data.newLead
            } else {
              state.data[i].awaitingActionLeads.unshift(payload.data.newLead)
            }
          }
        }
      }
    })
    .addMatcher(apiSlice.endpoints.saveLeadTargetUpdates.matchFulfilled, (state, {payload}) => {
      for (let i = 0; i < state.data.length; i++) {
        let memberToUpdate = payload.data.newLead.assigneeIds.find(assignee => assignee === state.data[i].memberId)
        if (memberToUpdate) {
          //* investigatingLeads
          if (payload.data.leadStatus === 'investigating') {
            let thisLead = state.data[i].investigatingLeads.find(lead => lead._id === payload.data.newLead._id)
            let thisIndex = state.data[i].investigatingLeads.indexOf(thisLead)
            state.data[i].investigatingLeads[thisIndex] = payload.data.newLead
          } else if (payload.data.leadStatus === 'closing') {
            //* closingLeads
            let thisLead = state.data[i].closingLeads.find(lead => lead._id === payload.data.newLead._id)
            let thisIndex = state.data[i].closingLeads.indexOf(thisLead)
            state.data[i].closingLeads[thisIndex] = payload.data.newLead
          } else {
            //* awaitingAction
            let thisLead = state.data[i].awaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
            let thisIndex = state.data[i].awaitingActionLeads.indexOf(thisLead)
            state.data[i].awaitingActionLeads[thisIndex] = payload.data.newLead
          }
        }
      }
    })
    .addMatcher(apiSlice.endpoints.addMortgageNote.matchFulfilled, (state, {payload}) => {
      if (payload.data.assigneeIds) {
        for (let i = 0; i < state.data.length; i++) {
          let memberToUpdate = payload.data.assigneeIds.find(assignee => assignee === state.data[i].memberId)
          if (memberToUpdate) {
            if (payload.data.leadStatus === 'investigating') {
              //* investigatingLeads
              let thisLead = state.data[i].investigatingLeads.find(lead => lead._id === payload.data.leadId)
              let thisIndex = state.data[i].investigatingLeads.indexOf(thisLead)
              state.data[i].investigatingLeads[thisIndex] = payload.data.newLead
            } else if (payload.data.leadStatus === 'closing') {
              //* closingLeads
              let thisLead = state.data[i].closingLeads.find(lead => lead._id === payload.data.leadId)
              let thisIndex = state.data[i].closingLeads.indexOf(thisLead)
              state.data[i].closingLeads[thisIndex] = payload.data.newLead
            } else {
              //* awaitingAction
              let thisLead = state.data[i].awaitingActionLeads.find(lead => lead._id === payload.data.leadId)
              let thisIndex = state.data[i].awaitingActionLeads.indexOf(thisLead)
              state.data[i].awaitingActionLeads[thisIndex] = payload.data.newLead
            }
          }
        }
      }
    })
    .addMatcher(apiSlice.endpoints.deleteMortgage.matchFulfilled, (state, {payload}) => {
      for (let i = 0; i < state.data.length; i++) {
        let memberToUpdate = payload.data.assigneeIds.find(assignee => assignee === state.data[i].memberId)
        if (memberToUpdate) {
          if (payload.data.leadStatus === 'investigating') {
            //* investigatingLeads
            let thisInvestigatingLead = state.data[i].investigatingLeads.find(lead => lead._id === payload.data.leadId)
            let thisInvestigatingIndex = state.data[i].investigatingLeads.indexOf(thisInvestigatingLead)
            state.data[i].investigatingLeads.splice(thisInvestigatingIndex, 1)
          } else if (payload.data.leadStatus === 'closing') {
            //* closingLeads
            let thisClosingLead = state.data[i].closingLeads.find(lead => lead._id === payload.data.leadId)
            let thisClosingIndex = state.data[i].closingLeads.indexOf(thisClosingLead)
            state.data[i].closingLeads.splice(thisClosingIndex, 1)
          } else {
            //* awaitingAction
            let thisAwaitingActionLead = state.data[i].awaitingActionLeads.find(lead => lead._id === payload.data.leadId)
            let thisAwaitingActionIndex = state.data[i].awaitingActionLeads.indexOf(thisAwaitingActionLead)
            state.data[i].awaitingActionLeads.splice(thisAwaitingActionIndex, 1)
          }
        }
      }
    })
    .addMatcher(apiSlice.endpoints.dismissLead.matchFulfilled, (state, {payload}) => {
      for (let i = 0; i < state.data.length; i++) {
        let memberToUpdate = payload.data.assigneeIds.find(assignee => assignee === state.data[i].memberId)
        if (memberToUpdate) {
          if (payload.data.leadStatus === 'investigating') {
            //* investigatingLeads
            let thisInvestigatingLead = state.data[i].investigatingLeads.find(lead => lead._id === payload.data.leadId)
            let thisInvestigatingIndex = state.data[i].investigatingLeads.indexOf(thisInvestigatingLead)
            state.data[i].investigatingLeads.splice(thisInvestigatingIndex, 1)
          } else if (payload.data.leadStatus === 'closing') {
            //* closingLeads
            let thisClosingLead = state.data[i].closingLeads.find(lead => lead._id === payload.data.leadId)
            let thisClosingIndex = state.data[i].closingLeads.indexOf(thisClosingLead)
            state.data[i].closingLeads.splice(thisClosingIndex, 1)
          } else {
            //* awaitingAction
            let thisAwaitingActionLead = state.data[i].awaitingActionLeads.find(lead => lead._id === payload.data.leadId)
            let thisAwaitingActionIndex = state.data[i].awaitingActionLeads.indexOf(thisAwaitingActionLead)
            state.data[i].awaitingActionLeads.splice(thisAwaitingActionIndex, 1)
          }
        }
      }
    })
    .addMatcher(apiSlice.endpoints.setLeadStatusInvestigating.matchFulfilled, (state, {payload}) => {
      for (let i = 0; i < state.data.length; i++) {
        let memberToUpdate = payload.data.assigneeIds.find(assignee => assignee === state.data[i].memberId)
        if (memberToUpdate) {
          //* awaitingAction
          let thisLead = state.data[i].awaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
          let thisIndex = state.data[i].awaitingActionLeads.indexOf(thisLead)
          state.data[i].awaitingActionLeads.splice(thisIndex, 1)
          //* investigatingLeads
          state.data[i].investigatingLeads.unshift(payload.data.newLead)
        }
      }
    })
    .addMatcher(apiSlice.endpoints.setLeadStatusClosing.matchFulfilled, (state, {payload}) => {
      for (let i = 0; i < state.data.length; i++) {
        let memberToUpdate = payload.data.assigneeIds.find(assignee => assignee === state.data[i].memberId)
        if (memberToUpdate) {
          //* investigatingLeads
          let thisLead = state.data[i].investigatingLeads.find(lead => lead._id === payload.data.newLead._id)
          let thisIndex = state.data[i].investigatingLeads.indexOf(thisLead)
          state.data[i].investigatingLeads.splice(thisIndex, 1)
          //* closingLeads
          state.data[i].closingLeads.unshift(payload.data.newLead)
        }
      }
    })
    .addMatcher(apiSlice.endpoints.setLeadStatusFinalized.matchFulfilled, (state, {payload}) => {
      console.log(payload.data)
      for (let i = 0; i < state.data.length; i++) {
        let memberToUpdate = payload.data.assigneeIds.find(assignee => assignee === state.data[i].memberId)
        if (memberToUpdate) {
          //* closingLeads
          let thisLead = state.data[i].closingLeads.find(lead => lead._id === payload.data.leadId)
          let thisIndex = state.data[i].closingLeads.indexOf(thisLead)
          state.data[i].closingLeads.splice(thisIndex, 1)
          if (payload.data.activeDiscrepancies > 0) {
            state.data[i].awaitingUpdateLeads.unshift(payload.data.leadId)
          }
        }
      }
      console.log('here')
    })
    .addMatcher(apiSlice.endpoints.confirmPublicRecords.matchFulfilled, (state, {payload}) => {
      console.log(payload.data)
      for (let i = 0; i < state.data.length; i++) {
        let memberToUpdate = payload.data.assigneeIds.find(assignee => assignee === state.data[i].memberId)
        if (memberToUpdate) {
          //* awaitingUpdateLeads
          let thisLead = state.data[i].awaitingUpdateLeads.find(lead => lead._id === payload.data.leadId)
          let thisIndex = state.data[i].awaitingUpdateLeads.indexOf(thisLead)
          state.data[i].awaitingUpdateLeads.splice(thisIndex, 1)
        }
      }
      console.log('here')
    })
    .addMatcher(apiSlice.endpoints.deleteUser.matchFulfilled, (state, {payload}) => {
      let thisInstance = state.data.find(member => member.memberId === payload.data.deletedUserId)
      let thisIndex = state.data.indexOf(thisInstance)
      state.data.splice(thisIndex, 1)
    })
  }
})

export default membersDataArraySlice.reducer

export const selectMembersDataArray = state => state.membersDataArray.data
import { createSlice } from '@reduxjs/toolkit'
import { apiSlice } from './api/apiSlice'

const initialState = {
  data: [],
  status: 'idle',
  error: null
}

export const viewingLeadReportslice = createSlice({
  name: 'viewingLeadReports',
  initialState,
  reducers: {
    setViewingLeadReports(state, action) {
      state.data = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
    .addMatcher(apiSlice.endpoints.setLeadStatusFinalized.matchFulfilled, (state, {payload}) => {
      console.log(payload.data)
      state.data.unshift(payload.data.newReport)
    })
  }
})

export const { setViewingLeadReports } = viewingLeadReportslice.actions

export default viewingLeadReportslice.reducer

export const selectViewingLeadReports = state => state.viewingLeadReports.data
import React from "react";
import styled from "@emotion/styled";
import SellIcon from '@mui/icons-material/Sell';
import GroupIcon from '@mui/icons-material/Group';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import EditNoteIcon from '@mui/icons-material/EditNote';
import SummarizeIcon from '@mui/icons-material/Summarize';
import { useDispatch, useSelector } from "react-redux";
import { selectMortgageActionsNavSelection, setMortgageActionNavMenuSelection } from "../../../slices/navMenuSelectionsSlice";
import { ActionMenuItem, ActionMenuTitle, NavIndex, NavText, NavigationContainer, SideBarNavHeader } from "../../../StyledComponents";

const MortgageActionsNav = ({ mortgageData, reportsNumber }) => {
  const dispatch = useDispatch()

  const navSelection = useSelector(selectMortgageActionsNavSelection)
  
  const changeSelection = (content) => {
    dispatch( setMortgageActionNavMenuSelection(content))
  }

  return (
    <NavigationContainer className="modal" style={{gridRow:'2/5'}}>
      <SideBarNavHeader className="modal">Actions</SideBarNavHeader>
      {navSelection ?
        <>
          {navSelection === 'reports' ?
            <ListItemNav selected top>
              <NavIndex>
                <SummarizeIcon fontSize="large" />
              </NavIndex>
              <NavText className="main">View Reports ({reportsNumber})</NavText>
            </ListItemNav>
          : reportsNumber > 0 ?
            <ListItemNav onClick={() => changeSelection('reports')} top>
              <NavIndex>
                <SummarizeIcon fontSize="large" />
              </NavIndex>
              <NavText className="main">View Reports ({reportsNumber})</NavText>
            </ListItemNav>
          :
            <ListItemNav top disabled>
              <NavIndex>
                <SummarizeIcon fontSize="large" />
              </NavIndex>
              <NavText className="main">View Reports ({reportsNumber})</NavText>
            </ListItemNav>
          }
          {navSelection === 'notes' ?
            <ListItemNav selected>
              <NavIndex>
                <EditNoteIcon fontSize="large" />
              </NavIndex>
              <NavText className="main">View/Add Notes ({mortgageData.mortgageNotes.length})</NavText>
            </ListItemNav>
          :
            <ListItemNav onClick={() => changeSelection('notes')}>
              <NavIndex>
                <EditNoteIcon fontSize="large" />
              </NavIndex>
              <NavText className="main">View/Add Notes ({mortgageData.mortgageNotes.length})</NavText>
            </ListItemNav>
          }
          {navSelection === 'compareRecords' ?
            <ListItemNav selected>
              <NavIndex>
                <FileCopyIcon fontSize="large" />
              </NavIndex>
              <NavText className="main">Compare Records</NavText>
            </ListItemNav>
          :
            <ListItemNav onClick={() => changeSelection('compareRecords')}>
              <NavIndex>
                <FileCopyIcon fontSize="large" />
              </NavIndex>
              <NavText className="main">Compare Records</NavText>
            </ListItemNav>
          }
          {navSelection === 'propertyDetails' ?
            <ListItemNav selected>
              <NavIndex>
                <HomeWorkIcon fontSize="large" />
              </NavIndex>
              <NavText className="main">View Property Details</NavText>
            </ListItemNav>
          :
            <ListItemNav onClick={() => changeSelection('propertyDetails')}>
              <NavIndex>
                <HomeWorkIcon fontSize="large" />
              </NavIndex>
              <NavText className="main">View Property Details</NavText>
            </ListItemNav>
          }
          {navSelection === 'mortgageDetails' ?
            <ListItemNav selected>
              <NavIndex>
                <GroupIcon fontSize="large" />
              </NavIndex>
              <NavText className="main">View Owner Details</NavText>
            </ListItemNav>
          :
            <ListItemNav onClick={() => changeSelection('mortgageDetails')}>
              <NavIndex>
                <GroupIcon fontSize="large" />
              </NavIndex>
              <NavText className="main">View Owner Details</NavText>
            </ListItemNav>
          }
          {navSelection === 'editTags' ?
            <ListItemNav selected>
              <NavIndex>
                <SellIcon fontSize="large" />
              </NavIndex>
              <NavText className="main">Mortgage Tags</NavText>
            </ListItemNav>
          :
            <ListItemNav onClick={() => changeSelection('editTags')}>
              <NavIndex>
                <SellIcon fontSize="large" />
              </NavIndex>
              <NavText className="main">Mortgage Tags</NavText>
            </ListItemNav>
          }
          {navSelection === 'investigateLead' ?
            <ActionMenuItem className="advance focus" style={{margin: '4rem auto 0 auto', width: '16rem'}}>
              <ActionMenuTitle className="white">Open Investigation</ActionMenuTitle>
            </ActionMenuItem>
          :
            <ActionMenuItem className="advance" style={{margin: '4rem auto 0 auto', width: '16rem'}} onClick={() => changeSelection('investigateLead')}>
              <ActionMenuTitle className="white">Open Investigation</ActionMenuTitle>
            </ActionMenuItem>
          }
        </>
      :
        <>
          <ListItemNav disabled>
            <NavIndex>
              <SummarizeIcon fontSize="medium" />
            </NavIndex>
            <NavText className="disabled">View Reports</NavText>
          </ListItemNav>
          <ListItemNav disabled>
            <NavIndex>
              <EditNoteIcon fontSize="medium" />
            </NavIndex>
            <NavText className="disabled">View/Add Notes</NavText>
          </ListItemNav>
          <ListItemNav disabled>
            <NavIndex>
              <FileCopyIcon fontSize="medium" />
            </NavIndex>
            <NavText className="disabled">Compare Records</NavText>
          </ListItemNav>
          <ListItemNav disabled>
            <NavIndex>
              <HomeWorkIcon fontSize="medium" />
            </NavIndex>
            <NavText className="disabled">View Property Details</NavText>
          </ListItemNav>
          <ListItemNav disabled>
            <NavIndex>
              <GroupIcon fontSize="medium" />
            </NavIndex>
            <NavText className="disabled">View Owner Details</NavText>
          </ListItemNav>
        </>
      }
    </NavigationContainer>
  )
}

const ListItemNav = styled.a`
  display: flex;
  align-items: center;
  font-weight: 400;
  border-radius: 0;
  padding: 0.5rem 0 0.5rem 0.3rem;
  margin: 0;
  background-color: ${(props) => props.selected && !props.disabled ? "#4B14752B" : props.disabled ? "none" : "none"};
  cursor: ${(props) => props.selected || props.noCursor ? "default" : props.disabled ? 'not-allowed' : "pointer"};
  color: ${(props) => props.disabled ? '#939599' : '#00B812'};
  border-top: ${(props) => props.top ? "0.2rem solid #939599" : "0.1rem solid #939599"};
  border-bottom: ${(props) => props.bottom ? "0.2rem solid #939599" : "0.1rem solid #939599"};
  &:hover {
    background-color: ${(props) => props.selected && !props.disabled ? "#4B147533" : props.disabled ? "#E0E0E0" : "#F2F2F2"};
  }
`

export default MortgageActionsNav;
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: 'uploads',
  status: 'idle',
  error: null
}

export const adminNavSelectionSlice = createSlice({
  name: 'adminNavSelection',
  initialState,
  reducers: {
    setAdminNavSelection(state, action) { state.data = action.payload },
  },
})

export const { setAdminNavSelection } = adminNavSelectionSlice.actions

export default adminNavSelectionSlice.reducer

export const selectAdminNavSelection = state => state.adminNavSelection.data

import React, { useState } from "react";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import { useTheme } from "@mui/styles";
import FormControlLabel from '@mui/material/FormControlLabel';
import { ComponentContainer, ComponentSectionRow, FlexWrapper, HeaderText, ComponentBorder } from "../../../../StyledComponents";
import { grayUnselectedRadioColorProps } from "../../../../componentThemes";
import ParameterPerformanceStats from "../../../../components/Insights/ParameterPerformanceStats";
import { useSelector } from "react-redux";
import { selectMortgageDataModel } from "../../../../slices/mortgageDataModelSlice";
import ParameterTags from "../../../../components/Insights/ParameterTags";
import LeadTagPerformance from "../../../../components/Insights/LeadTagPerformance";

const Insights = () => {
  const theme = useTheme()

  const mortgageDataModel = useSelector(selectMortgageDataModel)

  const [insightsView, setInsightsView] = useState('Parameter Performance')
  const [openSweepParameterModal, setOpenSweepParameterModal] = useState(false)

  const insightsViewToggleControlProps = (item) => ({
    checked: insightsView === item,
    onChange: handleInsightsViewChange,
    value: item,
    label: item,
    labelPlacement: "end",
    sx: {
      color: `${theme.palette.common.black}`,
    }
  });
  const handleInsightsViewChange = (event) => {
    setInsightsView(event.target.value)
  }

  const handleOpenSweepParameterModal = (sweepParameter) => {
    // dispatch( setViewingSweepParameter(sweepParameter) )
    setOpenSweepParameterModal(true)
  }
  const handleCloseSweepParameterModal = () => {
    setOpenSweepParameterModal(false)
  }

  return (
    <>
      <ComponentContainer className="fitWindow onePanel" style={{background: `${theme.palette.common.grayBorderLight}`}}>
        <FlexWrapper className="column" style={{gridRow: '2', gridColumn: '2', maxHeight: 'calc(100vh - 12.1rem)'}}>
          <ComponentSectionRow style={{justifyContent: 'center', padding: '1rem 0', background: `${theme.palette.common.grayBorderLight}`}}>
            <FlexWrapper className="fitContentWidth alignCenter">
              <HeaderText className="center" style={{margin: '0.2rem 0 0 0'}}>
                Entity Details:
              </HeaderText>
              <FormControl style={{margin: '0.1rem 0 0 2.5rem'}}>
                <RadioGroup row>
                  <FormControlLabel
                    control={<Radio {...grayUnselectedRadioColorProps()} />}
                    {...insightsViewToggleControlProps('Parameter Performance')} 
                  />
                  <FormControlLabel
                    control={<Radio {...grayUnselectedRadioColorProps()} />}
                    {...insightsViewToggleControlProps('Parameter Lead Tags')} 
                  />
                  <FormControlLabel
                    control={<Radio {...grayUnselectedRadioColorProps()} />}
                    {...insightsViewToggleControlProps('Lead Tag Performance')} 
                  />
                </RadioGroup>
              </FormControl>
            </FlexWrapper>
          </ComponentSectionRow>
          {insightsView === 'Parameter Performance' ?
            <ParameterPerformanceStats mortgageDataModel={mortgageDataModel} handleOpenSweepParameterModal={handleOpenSweepParameterModal} />
          : insightsView === 'Parameter Lead Tags' ?
            <ParameterTags mortgageDataModel={mortgageDataModel} handleOpenSweepParameterModal={handleOpenSweepParameterModal} />
          :
            <LeadTagPerformance mortgageDataModel={mortgageDataModel} handleOpenSweepParameterModal={handleOpenSweepParameterModal} />
          }
        </FlexWrapper>
        <ComponentBorder className="top" style={{gridRow:'1', gridColumn: '1/4'}} />
        <ComponentBorder className="left" style={{gridRow:'2', gridColumn: '1'}} />
        <ComponentBorder className="right"  style={{gridRow:'2', gridColumn: '3'}} />
        <ComponentBorder className="bottom" style={{gridRow:'3', gridColumn: '1/4'}} />
      </ComponentContainer>
    </>
  )
}

export default Insights
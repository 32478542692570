import React, { useContext, useEffect, useState } from "react"
import "./App.css"
import axios from 'axios'
import theme from "./theme"
import Login from "./pages/LoginPortal/Login"
import Launch from "./pages/LoginPortal/Launch"
import Dashboard from "./pages/Dashboard/Dashboard"
import SuperDash from "./pages/Super/SuperDash"
import ScreenSaver from "./pages/ScreenSaver"
import { AppState } from "./AppState"
import { selectLoadData } from "./slices/loadDataSlice"
import { setDashPerspective } from "./slices/dashPerspectiveSlice"
import { useDispatch, useSelector } from "react-redux"
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client'
import { CssBaseline, StyledEngineProvider, ThemeProvider } from "@mui/material"
import { setLaunchTeamEnabled, setLoginEnabled, setNewcomerFormEnabled } from "./slices/functionAvailabilitySlice"
import { useEstablishRequestCredentialsMutation, useInitialLoadMutation, useLogReturningUserMutation } from "./slices/api/apiSlice"
import { setUserReqCredentials, setUserFirstName, setUserInfo, setUserEmail, selectUserId, setUserId, selectTeamId, setTeamId } from "./slices/userInfoSlice"
import Error from "./pages/Error"


// const httpLink = createHttpLink({
//   uri: '/graphql',
// });

// const authLink = setContext((_, { headers }) => {
//   const token = localStorage.getItem('id_token');
//   return {
//     headers: {
//       ...headers,
//       authorization: token ? `Bearer ${token}` : '',
//     },
//   };
// });

const client = new ApolloClient({
  // Set up our client to execute the `authLink` middleware prior to making the request to our GraphQL API
  // link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

// export function UserHandler (props) {
//   const { getUser } = useContext(AppState)
//   useEffect(() => {
//     getUser()
//   }, [getUser])
//   return props.children
// }

const App = () => {
  const { user, sessionRestored } = useContext(AppState);
  const dispatch = useDispatch()

  const dataLoaded = useSelector(selectLoadData)

  const [userConfirmed, setUserConfirmed] = useState(false)

  const [establishRequestCredentials, {}] = useEstablishRequestCredentialsMutation()
  const [logReturningUser, {}] = useLogReturningUserMutation()

  const userId = useSelector(selectUserId)
  const teamId = useSelector(selectTeamId)

  const [width, setWidth] = useState(window.innerWidth);
  const [launchRequired, setLaunchRequired] = useState(false);
  const [userLoaded, setUserLoaded] = useState(false);
  const [ipAquired, setIPAquired] = useState(false)
  const [ipError, setIPError] = useState(false)
  const [appError, setAppError] = useState(false)
  const [userHistory, setUserHistory] = useState(null)
  const [refreshRequired, setRefreshRequired] = useState(false)

  const [initialLoad, {}] = useInitialLoadMutation()
  
  const fetchData = async (reqIP, reqCredentials) => {
    if (user) {
      let returningUser = await logReturningUser({
        userId: user.userId,
        reqIP: reqIP,
        reqLocation: reqCredentials.reqLocation,
        reqISP: reqCredentials.reqISP,
        reqProxy: reqCredentials.reqProxy,
        reqMobile: reqCredentials.reqMobile,
        reqUserAgent: window.navigator.userAgent,
        reqUserAgentData: window.navigator.userAgentData,
      })
      if (returningUser.data.message.includes("Failed")) {
        localStorage.clear()
        setRefreshRequired(true)
      } else {
        let resp = await initialLoad({userId: returningUser.data.data.userId, authToken: user.token})
        if (resp.data.message.includes("Web Token Expired")) {
          localStorage.clear()
          setRefreshRequired(true)
        } else {
          setUserConfirmed(true)
          dispatch( setUserInfo(returningUser.data.data))
          dispatch( setUserReqCredentials({protocol: reqIP, location: reqCredentials.reqLocation, isp: reqCredentials.reqISP, proxy: reqCredentials.reqProxy}) )
          dispatch( setDashPerspective('team') )
          dispatch( setUserId(returningUser.data.data.userId) )
          dispatch( setTeamId(returningUser.data.data.teamId) )
        }
      }
    }
    setIPAquired(true)
  }

  //TODO: make nav menu show selected page
  //TODO: must maintain sort after status or a lead is changed
  //TODO: change date from being done here to using Moment on the back end
  //TODO: figure out time issues 
  //TODO: team profit for closures needs work
  //TODO: Adjust calendar sizing and let it go into future for closing?
  //TODO: alter user facing privledge description to be system admins, business managers, and team members
  //TODO: websocket to refresh lead targets nightly
  //TODO: change "profitPercent" to "revenueIncrease". Should be clearer for users.
  //TODO: change "investigating" to "under review" in appropriate circumstances
  //TODO: every relevant list where order is important should be sorted from the addition of a new item
  //TODO: test negative refinances on stats overview
  //TODO: paginate the active leads list?
  //TODO: disable buttons when loading
  //TODO: 'if the problem persists'
  //TODO: add functionality to 'refresh' target stats based on elapsed time. Make it so that at certain workflow 'milestone', this is performed automatically (this already happens with refinances going to closing)

  useEffect(async () => {
    if (sessionRestored) {
      try {
        const reqIP = await axios.get("https://api.ipify.org/?format=json")
        const ipCredentials = await establishRequestCredentials({reqIP: reqIP.data.ip})
        if (ipCredentials.data.message.includes('success')) {
          if (!ipCredentials.data.data.existingTeam) {
            dispatch( setLaunchTeamEnabled(true) )
            setLaunchRequired(true)
          }
          if (ipCredentials.data.data.userHistory === 'N/A') {
            dispatch( setNewcomerFormEnabled(true) )
            setUserHistory('newcomer')
          } else if (ipCredentials.data.data.userHistory === 'existing') {
            if (ipCredentials.data.data.userEmail.length > 0) {
              dispatch( setUserEmail(ipCredentials.data.data.userEmail) )
            }
            dispatch( setLoginEnabled(true) )
            setUserHistory('existing')
            dispatch( setUserFirstName(ipCredentials.data.data.userFirstName) )
          }
          dispatch( setUserReqCredentials({protocol: reqIP.data.ip, location: ipCredentials.data.data.reqCredentials.reqLocation, isp: ipCredentials.data.data.reqCredentials.reqISP, mobile: ipCredentials.data.data.reqCredentials.reqMobile, proxy: ipCredentials.data.data.reqCredentials.reqProxy}) )
          fetchData(reqIP.data.ip, ipCredentials.data.data.reqCredentials)
        } else {
          setIPError(true)
          setIPAquired(false)
        }
      } catch (error) {
        setAppError(true)
      }
    }
  }, [sessionRestored, user])

  return (
    <ApolloProvider client={client}>
      <Router>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            {appError ? (
              <Routes>
                <Route
                  path="*"
                  element={<Error />}
                />
              </Routes>
            ) : ipError ? (
              <Routes>
                <Route
                  path="*"
                  element={<ScreenSaver ipError={ipError} />}
                />
              </Routes>
            ) : launchRequired ? (
              <Routes>
                <Route
                  path="*"
                  element={<Launch setLaunchRequired={setLaunchRequired} />}
                />
              </Routes>
            ) : refreshRequired ? (
              <Routes>
                <Route
                  path="*"
                  element={<Login userHistory={userHistory} setIPAquired={setIPAquired} setIPError={setIPError} user={user} isRefresh={true} setUserConfirmed={setUserConfirmed} />}
                />
              </Routes>
            ) : userConfirmed ? (
              <Routes>
                <Route
                  path="/admin"
                  element={<SuperDash teamId={teamId} userId={userId} page={'adminDash'}  />}
                />
                <Route
                  path="/dashboard"
                  element={<Dashboard teamId={teamId} userId={userId} page={'dashboard'} />}
                />
                <Route
                  path="*"
                  element={<Dashboard teamId={teamId} userId={userId} page={'dashboard'}  />}
                />
              </Routes>
            ) : ipAquired ? (
              <Routes>
                <Route
                  path="*"
                  element={<Login userHistory={userHistory} setIPAquired={setIPAquired} setIPError={setIPError} setUserConfirmed={setUserConfirmed} />}
                />
              </Routes>
            ) : (
              <Routes>
                <Route
                  path="*"
                  element={<ScreenSaver ipError={ipError} />}
                />
              </Routes>
            )}
          </ThemeProvider>
        </StyledEngineProvider>
      </Router>
    </ApolloProvider>
  );
}

export default App;
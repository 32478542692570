import { createSlice } from '@reduxjs/toolkit'
import { apiSlice } from './api/apiSlice'

const initialState = {
  data: {
    team: {
      teamName: '',
      dateCreated: '',
      appAdminName: '',
      appAdminEmail: '',
      numberOfUsers: 0,
      loanTypes: [],
      defaultTargets: {
        defaultTargetTerm: 0,
        defaultLoanTypeTarget: '',
        defaultTargetInterestRate: 0,
      },
      sweepParameters: {
        tier1Parameters: [],
        tier2Parameters: [],
        tier3Parameters: [],
      },
      emailSchedule: [],
      mortgageTags: [],
      mortgageTagIds: [],
      mortgageTags: [],
      leadTagIds: [],
      leadTags: [],
    },
    user: {
      notificationSchedule: [],
      emailScheduleLabel: '',
      emailSchedule: [],
    }
  },
  status: 'idle',
  error: null
}

export const teamAndUserSettingsSlice = createSlice({
  name: 'teamAndUserSettings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addMatcher(apiSlice.endpoints.initialLoad.matchFulfilled, (state, {payload}) => {
      if (payload.data) {
        state.data = payload.data.teamAndUserSettings
      }
    })
    .addMatcher(apiSlice.endpoints.createNewTeamLoanType.matchFulfilled, (state, {payload}) => {
      state.data.team.loanTypes.push(payload.data.newLoanType)
    })
    .addMatcher(apiSlice.endpoints.updateTeamDefaultTargetParameters.matchFulfilled, (state, {payload}) => {
      state.data.team.defaultTargets = payload.data.defaultTargets
    })
    .addMatcher(apiSlice.endpoints.editUserEmailSchedule.matchFulfilled, (state, {payload}) => {
      state.data.user.emailScheduleLabel = payload.data.finalScheduleLabel
      state.data.user.emailSchedule = payload.data.finalSchedule
    })
    .addMatcher(apiSlice.endpoints.updateTeamDiscrepancyParamterSelection.matchFulfilled, (state, {payload}) => {
      if (payload.data.tier === 1) {
        let thisParameter = state.data.team.discrepancyParamters.tier1Parameters.find(parameter => parameter._id === payload.data.parameterId);
        let thisIndex = state.data.team.discrepancyParamters.tier1Parameters.indexOf(thisParameter)
        state.data.team.discrepancyParamters.tier1Parameters[thisIndex].active = payload.data.activeStatus
      } else if (payload.data.tier === 2) {
        let thisParameter = state.data.team.discrepancyParamters.tier2Parameters.find(parameter => parameter._id === payload.data.parameterId);
        let thisIndex = state.data.team.discrepancyParamters.tier2Parameters.indexOf(thisParameter)
        state.data.team.discrepancyParamters.tier2Parameters[thisIndex].active = payload.data.activeStatus
      } else {
        let thisParameter = state.data.team.discrepancyParamters.tier3Parameters.find(parameter => parameter._id === payload.data.parameterId);
        let thisIndex = state.data.team.discrepancyParamters.tier3Parameters.indexOf(thisParameter)
        state.data.team.discrepancyParamters.tier3Parameters[thisIndex].active = payload.data.activeStatus
      }
    })
    .addMatcher(apiSlice.endpoints.addLeadTag.matchFulfilled, (state, {payload}) => {
      if (payload.data.action === 'add') {
        let thisTag = state.data.team.leadTags.find(tag => tag._id.toString() === payload.data.newTag._id.toString())
        let thisIndex = state.data.team.leadTags.indexOf(thisTag)
        state.data.team.leadTags[thisIndex] = payload.data.newTag
      } else if (payload.data.action === 'create') {
        state.data.team.leadTags.push(payload.data.newTag)
      }
    })
    .addMatcher(apiSlice.endpoints.addMortgageTag.matchFulfilled, (state, {payload}) => {
      if (payload.data.action === 'add') {
        let thisTag = state.data.team.mortgageTags.find(tag => tag._id.toString() === payload.data.newTag._id.toString())
        let thisIndex = state.data.team.mortgageTags.indexOf(thisTag)
        state.data.team.mortgageTags[thisIndex] = payload.data.newTag
      } else if (payload.data.action === 'create') {
        state.data.team.mortgageTags.push(payload.data.newTag)
      }
    })
  }
})

export default teamAndUserSettingsSlice.reducer

export const selectTeamDefaultInterest = state => state.teamAndUserSettings.data.team.defaultTargets.defaultTargetInterestRate
export const selectTeamDefaultTargets = state => state.teamAndUserSettings.data.team.defaultTargets
export const selectTeamLeadTags = state => state.teamAndUserSettings.data.team.leadTags
export const selectTeamMortgageTags = state => state.teamAndUserSettings.data.team.mortgageTags
export const selectTeamLeadTagIds = state => state.teamAndUserSettings.data.team.leadTagIds
export const selectTeamDetails = state => state.teamAndUserSettings.data.team
export const selectTeamEmailSchedule = state => state.teamAndUserSettings.data.team.emailSchedule
export const selectTeamLoanTypes = state => state.teamAndUserSettings.data.team.loanTypes
export const selectUserNotificationSchedule = state => state.teamAndUserSettings.data.user.notificationSchedule
export const selectUserEmailScheduleLabel = state => state.teamAndUserSettings.data.user.emailScheduleLabel
export const selectUserEmailSchedule = state => state.teamAndUserSettings.data.user.emailSchedule
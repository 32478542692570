import React from "react"
import { useTheme } from "@mui/styles";
import { useSelector } from "react-redux";
import { selectTeamLeadTags } from "../../slices/teamAndUserSettingsSlice";
import { ColumnContent, ComponentSectionRow, RowsContainer, ScrollWindow } from "../../StyledComponents";

const LeadTagPerformance = () => {
  const theme = useTheme()

  const teamLeadTags = useSelector(selectTeamLeadTags)

  return (
    <ScrollWindow className="horizontalOnly" style={{maxWidth: 'calc(100vw - 23.3rem)'}}>
      <ComponentSectionRow className="header gray" style={{display: 'grid', gridTemplateColumns: `7rem 22rem 10rem 10rem 10rem 10rem 10rem minmax(20rem, 1fr) 0.6rem`}}>
        <ColumnContent className="header" style={{gridColumn: '1', borderLeft: `0.1rem solid ${theme.palette.common.grayBorder}`}}>
          type
        </ColumnContent>  
        <ColumnContent className="header" style={{gridColumn: '2', borderLeft: `0.1rem solid ${theme.palette.common.grayBorder}`}}>
          label
        </ColumnContent>  
        <ColumnContent className="header" style={{gridColumn: '3', borderLeft: `0.1rem solid ${theme.palette.common.grayBorder}`}}>
          assignments
        </ColumnContent>
        <ColumnContent className="header" style={{gridColumn: '4', borderLeft: `0.1rem solid ${theme.palette.common.grayBorder}`}}>
          active leads
        </ColumnContent>
        <ColumnContent className="header" style={{gridColumn: '5', borderLeft: `0.1rem solid ${theme.palette.common.grayBorder}`}}>
          renegotiations
        </ColumnContent>
        <ColumnContent className="header" style={{gridColumn: '6', borderLeft: `0.1rem solid ${theme.palette.common.grayBorder}`}}>
          refinances
        </ColumnContent>
        <ColumnContent className="header" style={{gridColumn: '7', borderLeft: `0.1rem solid ${theme.palette.common.grayBorder}`}}>
          dismissals
        </ColumnContent>
        <ColumnContent className="header" style={{gridColumn: '8', borderLeft: `0.1rem solid ${theme.palette.common.grayBorder}`}}>
          triggers
        </ColumnContent>
      </ComponentSectionRow>
      <RowsContainer style={{height: 'calc(-20.2rem + 100vh)'}}>
        {teamLeadTags.map((tag, index) => (
          <ComponentSectionRow style={{padding: '0.1rem 0', background: index%2 === 1 ? `${theme.palette.common.grayBorderLight}` : '', display: 'grid', gridTemplateColumns: `7rem 22rem 10rem 10rem 10rem 10rem 10rem minmax(20rem, 1fr) 0.6rem`}}>
            {tag.origin === 'default' ?
              <ColumnContent style={{gridColumn: `1`, color: `${theme.palette.primary.main}`}} key={index} >
                DEFAULT 
              </ColumnContent>   
            :
              <ColumnContent style={{gridColumn: `1`, color: `${theme.palette.secondary.main}`}} key={index} >
                CUSTOM 
              </ColumnContent>   
            }
            <ColumnContent className="detail" style={{gridColumn: `2`}} key={index} >
              {tag.label}  
            </ColumnContent>   
            <ColumnContent style={{gridColumn: `3`, fontWeight: tag.assignments > 0 ? '600' : '400'}} key={index} >
              {tag.assignments}  
            </ColumnContent>   
            <ColumnContent style={{gridColumn: `4`, fontWeight: tag.activeLeads > 0 ? '600' : '400'}} key={index} >
              {tag.activeLeads}  
            </ColumnContent>   
            <ColumnContent style={{gridColumn: `5`, fontWeight: tag.renegotiations > 0 ? '600' : '400'}} key={index} >
              {tag.renegotiations} {tag.assignments > 0 && (((tag.renegotiations/tag.assignments)*100).toFixed(0) + '%')}
            </ColumnContent>   
            <ColumnContent style={{gridColumn: `6`, fontWeight: tag.refinances > 0 ? '600' : '400'}} key={index} >
              {tag.refinances} {tag.assignments > 0 && (((tag.refinances/tag.assignments)*100).toFixed(0) + '%')}
            </ColumnContent>   
            <ColumnContent style={{gridColumn: `7`, fontWeight: tag.dismissals > 0 ? '600' : '400'}} key={index} >
              {tag.dismissals} {tag.assignments > 0 && (((tag.dismissals/tag.assignments)*100).toFixed(0) + '%')}
            </ColumnContent>   
            <ColumnContent className="detail" style={{gridColumn: `8`, padding: '1rem'}} key={index} >
              {tag.fields.map((field, index) => (
                index === (tag.fields.length - 1) ?
                  <span key={index} style={{padding: '0 0 0 0.3rem'}}>{field}</span>
                :
                  <span key={index} style={{padding: '0 0 0 0.3rem'}}>{field},</span>
              ))}
            </ColumnContent>   
          </ComponentSectionRow>
        ))}
      </RowsContainer>
    </ScrollWindow>
  )
}

export default LeadTagPerformance;
import React from "react";  
import Upload from "./components/Upload/Upload.js";
import Reports from "./components/Reports/Reports";
import Duplicates from "./components/Duplicates";
import { FlexWrapper } from "../../../../StyledComponents.js";

const Uploads = ({ userId, teamId, userFullName }) => {

  return (
    <FlexWrapper style={{display: 'flex', maxHeight: '100%'}}>
      <Upload teamId={teamId} userId={userId} />
      <FlexWrapper className="column" style={{justifyContent: 'space-between', width: '50%', margin: '0 0 0 0.5rem', minHeight: '100%'}}>
        <Reports teamId={teamId} userId={userId} />
        <Duplicates teamId={teamId} userFullName={userFullName} />
      </FlexWrapper>
    </FlexWrapper>
  )
}

export default Uploads;
import React from "react"
import { useTheme } from "@mui/styles";
import { useSelector } from "react-redux";
import { selectTeamLeadTags } from "../../slices/teamAndUserSettingsSlice";
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { CardButton, ColumnContent, ComponentSectionRow, Detail, FlexWrapper, RowsContainer, ScrollWindow } from "../../StyledComponents";

const ParameterTags = ({ mortgageDataModel, handleOpenSweepParameterModal }) => {
  const theme = useTheme()

  const teamLeadTags = useSelector(selectTeamLeadTags)

  return (
    <ScrollWindow className="horizontalOnly" style={{maxWidth: 'calc(100vw - 23.3rem)'}}>
      <ComponentSectionRow className="header gray" style={{display: 'grid', gridTemplateColumns: `24rem repeat(${teamLeadTags.length}, 10rem) 0.6rem`, borderTop: `0.1rem solid ${theme.palette.common.grayText}`}}>
        <ColumnContent className="header" style={{gridColumn: '1', borderLeft: `0.1rem solid ${theme.palette.common.grayText}`}}>
          label
        </ColumnContent>  
        {teamLeadTags.map((tag, index) => (
          <ColumnContent className="header" style={{gridColumn: `${index+2}`, borderLeft: `0.1rem solid ${theme.palette.common.grayText}`}} key={index} >
            {tag.label}  
          </ColumnContent>   
        ))}
      </ComponentSectionRow>
      <RowsContainer style={{height: 'calc(100vh - 23.2rem)'}}>
        {mortgageDataModel.map((category, index) => (index !== 0 &&
          <div key={index}>
            <FlexWrapper className="fitContentHeight" style={{padding: '0.4rem 0 0.4rem 2rem', background: `${theme.palette.common.grayBorder}`, borderTop: index !== 1 ? `0.1rem solid ${theme.palette.common.grayText}` : 'none', borderBottom: `0.1rem solid ${theme.palette.common.grayText}`}}>
              <Detail className="timelineHeader">{category.subCat}</Detail>
            </FlexWrapper>
            <FlexWrapper className="column">
              {category.fields.map((sweepParameter, index) => (
                <ComponentSectionRow key={index} style={{padding: index === 0 ? '0.6rem 0 0.2rem 2rem' : index === (category.fields.length - 1) ? '0.2rem 0 0.6rem 2rem' : '0.2rem 0 0.2rem 2rem', background: index%2 === 1 ? `${theme.palette.common.grayBorderLight}` : '', display: 'grid', gridTemplateColumns: `22rem repeat(${teamLeadTags.length}, 10rem)`}}>
                  <CardButton className={sweepParameter.assignedTier === 'two' ? 'two' : sweepParameter.assignedTier === 'one' ? 'one' : 'three'} style={{width: 'fit-content', gridColumn: '1', padding: '0.1rem 0.6rem'}} onClick={() => handleOpenSweepParameterModal(sweepParameter)}>{sweepParameter.label}</CardButton>
                  {teamLeadTags.map((leadTag, tagIndex) => (
                    sweepParameter.tags.includes(leadTag.apiMapping)  ?
                      <Detail className="center" style={{gridColumn: `${tagIndex+2}`}} key={tagIndex}>
                        <RadioButtonCheckedIcon style={{color: `${theme.palette.primary.main}`}} />
                      </Detail>
                    :
                      <Detail className="center" style={{gridColumn: `${tagIndex+2}`}} key={tagIndex}>
                        <RadioButtonUncheckedIcon style={{color: `${theme.palette.common.grayBorderMedium}`}} />
                      </Detail>
                  ))}
                </ComponentSectionRow>
              ))}
            </FlexWrapper>
          </div>
        ))}
      </RowsContainer>
    </ScrollWindow>
  )
}

export default ParameterTags;
import styled from "@emotion/styled";

export const PageContainer = styled.div(({ theme }) => `
  width: 100vw;
  min-height: 100vh;
  height: fit-content;
  display: grid;
  background-color: ${theme.palette.common.pageBackground};
  grid-template-rows: 4rem 1fr;
  grid-template-columns: 19rem 1fr;
  overflow-x: hidden;
  
  &.login {
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr 50rem;
    background: white;
  }
  &.fadeOut {
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    background-color: ${theme.palette.common.black};

    animation: fadeInAnimation ease 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    @keyframes fadeInAnimation {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }
  &.screenSaver {
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    background-color: ${theme.palette.common.black};
  }
`)
export const PageContentContainer = styled.div(({ theme }) => `
  grid-row: 2;
  grid-column: 2;
  position: relative;
  min-height: 100%;
  max-height: calc(100vh - 4.5rem);
  background: ${theme.palette.common.pageBackground};
`)
export const PageContentWrapper = styled.div(`
  height: calc(100vh - 6.5rem);
  min-width: 100%;
  overflow: scroll;
  padding: 1rem 1rem 1rem 1rem;
  max-width: calc(100vw - 21rem);

  &.noScroll {
    overflow: hidden;
  }
`)
export const PageContent = styled.div(`
  height: 100%;
  width: 100%;

  &.grid {
    display: grid;
    
    &.activeLeads {
      grid-template-rows: 3rem calc(100vh - 12rem);
      grid-template-columns: 1fr 16rem;
    }
    &.closure {
      grid-template-rows: 8.2rem 0.3rem 2.4rem 2rem 0.5rem 12.7rem 0.5rem calc(100vh - 34.7rem);
      grid-template-columns: 15rem 1rem 2fr;
    }
    &.leadGeneration {
      grid-template-rows: 8.2rem 0.3rem calc(100vh - 16.6rem);
      grid-template-columns: 15rem 1rem 2fr;
    }
    &.reports {
      grid-template-columns: 34rem 5rem 70rem 1fr;
      grid-template-rows: 3rem calc(100vh - 20rem);
      justify-items: center;
    }
    &.guide {
      grid-template-columns: 22rem 1rem 1fr 1rem;
      grid-template-rows: 3rem calc(100vh - 20rem);
      justify-items: center;
    }
    &.notifications {
      grid-template-columns: 1fr 15rem;
      grid-template-rows: 3rem calc(100vh - 12rem);
    }
    &.settings {
      grid-template-columns: 1fr 1rem 1fr;
      grid-template-rows: 1fr 1rem 1fr;
    }
  }
`)
export const Footer = styled.div(({ theme }) => `
  height: 2.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${theme.palette.common.grayText};
  margin: auto 0;
  position: absolute;
  bottom: 0;
  border-top: 0.1rem solid ${theme.palette.common.grayBorder};
  box-shadow: 0rem -0.1rem 1rem ${theme.palette.common.grayText};
`)
export const NavBarContainer = styled.div(
  ({ theme }) => `
    width: 100%;
    height: 100%;
    grid-row: 1;
    grid-column: 2;
    display: grid;
    grid-template-columns: 12rem 1fr 6rem 6rem;
    align-items: center;
    justify-items: center;
    background-color: ${theme.light.panel.primaryBackground};
    border-bottom: 0.3rem solid ${theme.light.panel.primaryBorder};
`)
export const SideBarContainer = styled.div(
  ({ theme }) => `
    width: 100%;
    height: 100%;
    grid-row: 1/4;
    grid-column: 1;
    display: grid;
    grid-template-rows: 4rem 43rem 1fr;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    background: #fff;
    background-color: ${theme.light.panel.tertiaryBackground};
    z-index: 100;
`)
export const ComponentContainer = styled.div(({ theme }) => `
  width: 100%;
  height: fit-content;
  margin: 0 auto;
  padding: 0;
  display: grid;
  grid-template-columns: 0.5rem 1fr 0.5rem;
  grid-template-rows: 1.5rem 1fr 0.5rem;
  justify-content: center;
  align-items: center;
  background: ${theme.light.component.background};
  border: 0.1rem solid ${theme.light.component.border};
  border-radius: 1rem;
  overflow: hidden;

  &.thinBorders {
    grid-template-columns: 0.5rem 1fr 0.5rem;
    grid-template-rows: 1rem 1fr 0.5rem;
  }
  &.fitWindow {
    height: calc(100vh - 8.5rem);
  }
  &.first {
    grid-row: 1;
  }
  &.justifyStart {
    justify-content: flex-start;
  }
  &.alignStart {
    align-items: flex-start;
  }
  &.second {
    grid-row: 2;
    margin: 1rem auto;
  }
  &.overviewStats {
    grid-column: 3;
  }
  &.noBorder {
    border: none;
  }
  &.scroll {
    overflow: scroll;
  }
`)
export const ComponentWrapper = styled.div(`
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &.grid {
    grid-column: 2;
    display: grid;
    height: fit-content;
    grid-template-columns: 1fr;
  }
  &.first {
    grid-row: 2;
    grid-template-rows: 3rem 1fr;
  }
  &.second {
  }
  &.third {
  }
  &.fourth {
  }
`)
export const ComponentHeader = styled.div(({ theme }) => `
  font-size: 1.4rem;
  color: ${theme.palette.common.black};
  font-weight: 600;
  padding: 1rem 0 0 3.5rem;
  border-bottom: 0.1rem solid ${theme.palette.common.grayText};
  width: 100%;
  display: flex;
  align-items: center;
  
  &.green {
    color: ${theme.palette.primary.main}; 
  }
  &.red {
    font-weight: 400;
    color: ${theme.palette.common.redDark};
    border: 0.1rem solid ${theme.palette.common.redDark};
    background: ${theme.palette.common.redTransparent};
    padding: 0;
  }
  &.gray {
    color: ${theme.palette.common.grayText}; 
  }
  &.fontLarge {
    font-size: 2.0rem;
  }
  &.colorSecondary {
    color: ${theme.palette.common.grayText};
  }
  &.colorLight {
    color: ${theme.palette.common.grayBorder};
  }
  &.modal {
    font-size: 1.6rem;
    align-self: flex-start;
    height: 100%;

    &.main {
      border-right: 0.05rem solid ${theme.palette.common.grayText};
    }
    &.timeline {
      grid-column: 4;
      padding: 1rem 0 0 2rem;
      border-left: 0.1rem solid ${theme.palette.common.grayText};
    }
  }
  &.section {
    width: fit-content;
    padding: 5rem 1rem 0 1rem;
    border-bottom: none;
  }
  &.bottom {
    padding: 0 0 2rem 0;
  }
  &.top {
    padding: 0 1rem;
  }
  &.main {
    display: flex;
    grid-row: 1;
    grid-column: 2;
    align-items: center;
  }
  &.list {
    border-bottom: none;
    font-size: 1.0rem;
    font-weight: 400;
  }
  &.success {
    border-bottom: 0.1rem solid ${theme.palette.primary.main};
  }
  &.failed {
    border-bottom: 0.1rem solid ${theme.palette.common.orange};
  }
`)
export const HeaderText = styled.div(({ theme }) => `
  font-size: 1.4rem;
  width: fit-content;
  color: ${theme.palette.common.grayText};
  display: flex;

  &.chart {
    margin: 0 auto;
    font-size: 1.6rem;
    padding: 0 0 0 2rem;
  }
  &.center {
    justify-content: center;
  }
  &.borderRight {
    border-right: 0.1rem solid ${theme.palette.common.grayBorder};
  }
  &.reports {
    font-size: 1.6rem;
    color: ${theme.palette.common.grayText}; 
  }
  &.main {
    font-size: 2rem;
    font-weight: 600;
    color: ${theme.palette.common.black};
  }
  &.noPad {
    padding: 0
  }
  &.center {
    justify-content: center;
  }
  &.sub {
    padding: 0 0 0 2%;
  }
  &.green {
    color: ${theme.palette.primary.main}; 
  }
  &.orange {
    color: ${theme.palette.common.orange}; 
  }
  &.red {
    color: ${theme.palette.common.red}; 
  }
  &.gray {
    color: ${theme.palette.common.grayText}; 
  }
  &.modal {
    margin: 1rem 0 0.5rem 0.5rem;
  }
  &.small {
    font-size: 1.4rem;
    align-self: flex-end;
    font-weight: 300;
    margin: 0 0 0 1rem;
  }
  &.report {

  }
`)
export const ScrollWindow = styled.div(({ theme }) => `
  height: 100%;
  min-height: fit-content;
  overflow: scroll;  
  max-width: 100%;

  &.horizontalOnly {
    overflow-x: scroll;
    overflow-y: hidden;
  }
  &.verticalOnly {
    overflow-x: hidden;
    overflow-y: scroll;
  }
  &.column {
    display: flex;
    flex-direction: column;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 0.8rem;
    border: 0.1rem solid ${theme.palette.common.grayText};
    background-color: ${theme.palette.common.grayText};
  }
  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  ::-webkit-scrollbar:vertical {
    width: 0.4rem;
  }
  ::-webkit-scrollbar:horizontal {
    height: 0.4rem;
  }
`)
export const RowsContainer = styled.div(
  ({ theme }) => `
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: fit-content;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 0;
    background: ${theme.palette.common.cardBackground};
    
    &.noBorder {
      background-image: none;
      background-clip: border-box;
    }
    &.noScroll {
      overflow: hidden;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 8px;
      border: 0.1rem solid ${theme.palette.common.grayText};
      background-color: ${theme.palette.common.grayText};
    }
    ::-webkit-scrollbar {
      -webkit-appearance: none;
    }
    ::-webkit-scrollbar:vertical {
      width: 0.5rem;
    }
    ::-webkit-scrollbar:horizontal {
      width: 0rem;
    }
`)
export const ComponentSectionRow = styled.div(({ theme }) => `
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-width: fit-content;

  &.error {
    background: ${theme.palette.common.errorRow};
  }
  &.header {
    border-top: 0.1rem solid ${theme.palette.common.grayText};
    border-bottom: 0.2rem solid ${theme.palette.common.grayText};
    background: ${theme.palette.common.grayBorderMedium};
    
    &.section {
      border-top: 0.2rem solid ${theme.palette.common.grayText};
      background: ${theme.palette.common.grayOpaque};
    }
  }
  &.bottom {
    padding: 0 1rem 1rem 1rem;
  }
  &.even {
    background: ${theme.light.component.background};
    &.error {
      background: ${theme.palette.common.errorRowDark};
    }
  }
  &.gray {
    background: ${theme.palette.common.grayBorder};
  }
  &.twoColumn {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1.5rem 4rem 1fr;
    width: 100%;
  }
  &.parameterTags {
    display: grid;
    grid-template-columns: 5rem 22rem 5rem repeat(auto-fit, minmax(7rem,10rem));
    justify-content: flex-start
  }
  &.uploadSuccess {
    display: grid;
    grid-template-columns: 12rem 1fr 1fr;
  }
  &.uploadErrors {
    display: grid;
    grid-template-columns: 5rem 8rem 1fr;
  }
  &.actionsAndErrorsLog {
    display: grid;
    grid-template-columns: 5rem 4rem 16rem 20rem 10rem 25rem 1fr;
  }
  &.sweepFailures {
    display: grid;
    grid-template-columns: 5rem 8rem 8rem 16rem 20rem 1fr;
  }
  &.recordSweeps {
    width: 100%;
    max-width: 122rem;
    display: grid;
    grid-template-columns: 4rem 10rem 5rem 3rem 5rem 3rem 3rem 3rem 3rem 3rem 3rem 3rem 3rem 3rem 3rem 3rem 3rem 4rem 4rem 4rem 4rem 4rem 4rem 4rem 4rem 4rem 4rem 10rem;
    padding: 0.3rem 0;
  }
  &.duplicateCheck {
    display: grid;
    grid-template-columns: 4rem 14rem 1fr;
  }
  &.adminRepository {
    display: grid;
    grid-template-columns: 1fr 19rem 26rem 23rem 1fr;
  }
  &.listMembers {
    display: grid;
    grid-template-columns: 10rem 25rem 25rem 1fr;
    &.closed {
      grid-template-rows: 2.5rem 0;
      transition: grid-template-rows 0.7s ease-out;
      &.open {
        grid-template-rows: 2.5rem 5rem;
      }
    }
  }
  &.addTags {
    display: grid;
    grid-template-columns: 1fr;
    border-top: 0.3rem solid ${theme.palette.common.grayText};
    box-shadow: 0 0 0.8rem ${theme.palette.common.grayBorderDark};
    
    &.closed {
      grid-template-rows: 9.3rem 0;
      transition: grid-template-rows 0.7s ease-out;

      &.open {
        grid-template-rows: 9.3rem 25rem;
      }
    }
  }
  &.hidden {
    z-index: -1;
    font-size: 0;
    margin: 0;
    opacity: 0;
    padding: 0;
    max-height: 0;
    padding: 0;
    transition: opacity .20s,
    font-size .20s,
    margin .20s,
    padding .20s; 
  }
  &.onDisplay {
    z-index: 1;
    transition: font-size .50s,
    margin .30s .50s,
    padding .30s .50s,
    opacity .30s .50s;
  }
`)
export const SelectionContainer = styled.div(`
  width: 100%;
  padding: 0 5%;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
`)
export const Button = styled.button(
  ({ theme }) => `
    height: 3rem;
    width: 10rem;
    margin: 1rem 0.5rem;
    align-self: center;
    outline: none;
    border: none;
    border-radius: 1rem;
    font-size: 1.2rem;
    cursor: pointer;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s ease;

    &.round {
      height: fit-content;
      width: fit-content;
      padding: 0.7rem;
    }
    &.small {
      height: 1.5rem;
      width: 1.5rem;
      margin: 0;
    }
    &.blank {
      height: fit-content;
      width: fit-content;
      margin: 0;
      outline: none;
      border: none;
      background: none;
    }
    &.blank:hover {
      cursor: pointer;
    }
    &.fit {
      width: fit-content;
    }
    &.purple {
      color: ${theme.palette.common.grayBorder};
      background-color: ${theme.palette.secondary.main};
    }
    &.purple:hover {
      color: ${theme.palette.common.white};
      background-color: ${theme.palette.secondary.dark};
    }
    &.purpleLoading {
      background-color: ${theme.palette.secondary.dark};
      color: ${theme.palette.common.grayBorder};
    }
    &.primary {
      color: ${theme.palette.common.grayBorderDark};
      border: 0.05rem solid ${theme.light.button.primaryBackground};
      background-color: ${theme.light.button.primaryBackground};
      
      :hover {
        color: ${theme.light.button.primaryHoverText};
        border: 0.05rem solid ${theme.light.button.primaryHoverText};
        background-color: ${theme.light.button.primaryBackgroundHover};
      }
    }
    &.primary:focus {
      color: ${theme.palette.common.white};
      border: 0.05rem solid ${theme.palette.common.white};
      background-color: ${theme.palette.primary.main};
      box-shadow: 0 0 0.5rem ${theme.palette.primary.main};

      :hover {
        color: ${theme.palette.common.white};
        border: 0.05rem solid ${theme.palette.primary.dark};
      }
    }
    &.primaryDisabled {
      color: ${theme.light.button.primaryDisabledText};
      border: 0.05rem solid ${theme.light.button.primaryBackgroundDisabled};
      background-color: ${theme.light.button.primaryBackgroundDisabled};
    }
    &.primaryDisabled:hover {
      cursor: not-allowed;
    }
    &.primaryLoading {
      color: ${theme.light.button.primaryDisabledText};
      border: 0.05rem solid ${theme.light.button.primaryBackgroundDisabled};
      background-color: ${theme.light.button.primaryBackgroundDisabled};
    }
    &.primaryLoading:hover {
      cursor: wait;
    }
    &.delete {
      background-color: ${theme.palette.common.red};
      color: ${theme.palette.common.grayBorder};
      border: 0.05rem solid ${theme.palette.common.red};
    }
    &.delete:focus {
      background-color: ${theme.palette.common.redBright};
      box-shadow: 0 0 0.5rem ${theme.palette.common.red};
      color: ${theme.palette.common.white};
      border: 0.05rem solid ${theme.palette.common.white};
    }
    &.delete:hover {
      background-color: ${theme.palette.common.redBright};
      color: ${theme.palette.common.white};
      border: 0.05rem solid ${theme.palette.common.redDark};
    }
    &.deleteLoading {
      color: ${theme.palette.common.grayBorder};
      background-color: ${theme.palette.common.redDark};
    }
    &.deleteLoading:hover {
      cursor: wait;
    }
    &.secondary {
      color: ${theme.palette.common.black};
      background-color: ${theme.light.button.secondaryBackground};
    }
    &.secondary:focus {
      background-color: ${theme.palette.common.grayButtonFocus};
      box-shadow: 0 0 0.5rem ${theme.palette.common.black};
      border: 0.05rem solid ${theme.palette.common.white};
      color: ${theme.palette.common.black};
    }
    &.secondary:hover {
      background-color: ${theme.light.button.secondaryBackgroundHover};
    }
    &.orange {
      color: ${theme.palette.common.white};
      background-color: ${theme.palette.common.caution};

      :hover {
        background-color: ${theme.palette.common.cautionDark};
      }
    }
    &.secondaryLoading {
      color: ${theme.palette.common.blackLight};
      background-color: ${theme.palette.common.grayBorderDark};
    }
    &.secondaryLoading:hover {
      cursor: wait;
    }
    &.secondaryDisabled {
      background-color: ${theme.palette.common.grayBorderDark};
      color: ${theme.palette.common.grayBorder};

      &.thin {
        font-weight: 400;
      }
    }
    &.secondaryDisabled:hover {
      cursor: not-allowed;
    }
    &.confirm {
      width: 3rem;
      height: 3rem;
      margin: 0;
      align-self: flex-end;
      border-radius: 0.6rem;
    }
    &.black {
      color: ${theme.palette.common.black};
    }
    &.fit {
      width: fit-content;
    }
    &.notes {
      width: 3rem;
      height: 2.5rem;
      margin: 0;
      align-self: flex-end;
      border-radius: 0.6rem;
    }
    &.prompt {
      width: 3rem;
      height: 3rem;
      margin: 0;
      align-self: flex-end;
      border-radius: 0.6rem;
    }
    &.smallMargin {
      margin: 1rem 0;
    }
`)
export const FlexWrapper = styled.div(({ theme }) => `
  width: 100%;
  min-width: fit-content;
  height: 100%;
  min-height: fit-content;
  display: flex;

  &.fitContentWidth {
    width: fit-content;
  }
  &.fitContentHeight {
    height: fit-content;
  }
  &.column {
    flex-direction: column;
  }
  &.alignCenter {
    align-items: center;
  }
  &.alignStart {
    align-items: flex-start;
  }
  &.alignEnd {
    align-items: flex-end;
  }
  &.justifyCenter {
    justify-content: center;
  }
  &.justifyBetween {
    justify-content: space-between;
  }
  &.justifyStart {
    justify-content: flex-start;
  }
  &.justifyEnd {
    justify-content: flex-end;
  }
  &.borderBottom {
    border-bottom: 0.1rem solid ${theme.palette.common.grayText};
  }
`)
export const TitleContainer = styled.div(
  ({ theme }) => `
    width: 100%;
    height: 4rem;
    grid-row: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${theme.palette.common.white};
    font-size: 1.8rem;
    border-bottom: 0.3rem solid ${theme.light.panel.primaryBorder};
    background:  ${theme.light.panel.primaryBackground};
`)
export const NavigationContainer = styled.div(({ theme }) => `
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    
    &.modal {
      background: ${theme.palette.common.grayBorder};
      height: 100%;
      box-shadow: 0 0 1rem ${theme.palette.common.grayTextLight};
      border-right: 0.1rem solid ${theme.palette.common.grayText};
      z-index: 10;
      margin: 0;
    }
`)
export const SideBarNavHeader = styled.div(({ theme }) => `
  display: flex;
  flex-direction: column;
  padding: 2rem 0 0.5rem 0;
  width: 100%;
  font-size: 1.2rem;
  font-weight: 800;
  padding: 2rem 0 0.3rem 1.5rem;
  color: ${theme.light.panel.secondaryBackground};
  border-bottom: 0.1rem solid ${theme.light.panel.secondaryBorder};
  border-right: 0.1rem solid ${theme.light.panel.secondaryBorder};
  
  &.modal {
    color: ${theme.palette.common.grayText};
    margin: 4.7rem 0 0.5rem 0;
    border-right: none;
  }
`)
export const NavIndex = styled.p(({ theme }) => `
  margin: 0.4rem 0;
  width: 3.5rem;
  font-size: 1.2rem;
  font-weight: 900;
  display: flex;
  align-items: center;
  justify-content: center;

  &.guide {
    width: 2.5rem;
  }
  &.selected {
    margin: 0.4rem 0 0.4rem 2rem;
    font-weight: 500;
  }
  &.notSelected {
    margin: 0.4rem 0 0.4rem 2rem;
    font-weight: 500;
  }
  &.disabled {
    color: ${theme.palette.common.grayText}
  }
`)
export const NavText = styled.p(({ theme }) => `
  margin: 0.4rem 0;
  font-size: 1rem;
  font-weight: 500;

  &.main {
    color: ${theme.palette.common.black};
  }
  &.disabled {
    color: ${theme.palette.common.grayBorderDark}
  }
`)
export const PieChartContainer = styled.div(
  ({ theme }) => `
    grid-column: 1;
    grid-row: 1/5;
    width: 100%;
    height: 100%;
    border: 0.1rem solid ${theme.palette.common.grayBorder};
    background-color: ${theme.palette.common.cardBackground};
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;

    &.actionType {
      grid-row: 1;
    }
    &.tier1 {
      grid-row: 3;
    }
    &.tier2 {
      grid-row: 5;
    }
    &.tier3 {
      grid-row: 7;
    }
`)
export const HeaderContainer = styled.div(
  ({ theme }) => `
  width: 100%;
  height: 2.5rem;
  font-weight: 600;
  padding: 0.5rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${theme.palette.common.black};
  border-bottom: 0.1rem solid ${theme.palette.common.grayText};

  &.chart {
    padding: 0;
    height: 3.5rem;
  }
  &.modal {
    height: 4rem;
    flex-direction: column;
    justify-content: space-between;
    border-bottom: 0.1rem solid ${theme.palette.common.grayText};

    &.actionsModal {
      grid-row: 1/3;
      grid-column: 3;
    }
  }
`)
export const HorizontalContainer = styled.div(
  ({ theme }) => `
    width: 100%;
    height: fit-content;
    display: flex;
    overflow: scroll;
    
    &.grid {
      display: grid;
      
      &.notificationSchedules {
        grid-template-columns: 4rem 20rem 20rem 1fr;
      }
      &.uploadStats {
        width: fit-content;
        grid-template-columns: 6rem 5rem 20rem 10rem 10rem;
      }
      &.uploadErrors {
        grid-template-columns: 6rem 6rem 1fr;
      }
    }
    &.fitContent {
      height: fit-content;
    }
    &.targetFormGrid {
      align-items: center;
      justify-content: center;
      gap: 3rem;
      padding: 0 4rem;
      height: fit-content;
      grid-row: 2;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 5rem 4.5rem;
      column-gap: 3rem;
      row-gap: 0;
    }
  }
`)
export const VerticalContainer = styled.div(({ theme }) => `
  height: 100%;
  max-width: 100%;
  width: 100%;
  min-width: fit-content;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 auto;
  gap: 0.5rem;

  &.noGap {
    gap: 0;
  }
  &.fitContent {
    height: fit-content;
  }
  &.breakdown {
    justify-content: center;
  }
`)
export const ColumnContent = styled.div(({ theme }) => `
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  &.header {
    height: 100%;
    padding: 0.6rem;
    text-transform: uppercase;
    font-weight: 600;
    color: ${theme.palette.common.grayText};

    &.large {
      font-size: 1.4rem;
    }
    &.section {
      font-size: 1.2rem;
      grid-column: 3/5;
    }
  }
  &.detail {
    font-size: 1.3rem;
    text-wrap: pretty;
    text-align: center;
    text-transform: capitalize;
    flex-wrap: wrap;
    height: 100%;

    &.small {
      font-size: 1.0rem;
    }
  }
  &.lowercase {
    text-transform: none;
  }
  &.thin {
    font-weight: 300;
  }
  &.even {
    background: ${theme.palette.common.lightGray};
  }
  &.single {
    height: 100%;
    text-align: center;
  }
  &.bottomBorder {
    border-bottom: 0.1rem solid ${theme.palette.common.grayBorder};
    padding: 0 0.3rem;
  }
  &.gray {
    color: ${theme.palette.common.grayText};
  }
  &.breakdown {
    font-size: 2.4rem;
    font-weight: 600;
    color: ${theme.palette.common.black};
    height: fit-content;
  }
  &.breakdownSmall {
    font-weight: 600;
    color: ${theme.palette.common.black};
    height: 100%;
    flex-direction: column;
  }
  &.sub {
    height: fit-content;
  }
  &.highlight {
    font-size: 2.4rem;
    font-weight: 600;
    color: ${theme.palette.primary.main};
    flex-direction: column;
    height: 100%;
  }
  &.secondary {
    color: ${theme.palette.secondary.main};
  }
  &.renegotiations {
    color: ${theme.light.closures.renegotiation};
  }
  &.column {
    flex-direction: column;
  }
  &.quarter {
    justify-content: flex-start;
    font-size: 1.6rem;
    font-weight: 600;
    height: 3rem;
    overflow: hidden;
    padding: 0 0 0 1rem;
  }
`)
export const MainContent = styled.div(
  ({ theme }) => `
    font-size: 1.3rem;
    text-wrap: pretty;
    text-align: center;
    text-transform: capitalize;
    
    &.large {
      font-size: 1.6rem;
    }
`)
export const SubContent = styled.div(
  ({ theme }) => `
    font-size: 1.0rem;
    margin: 0 0 0 0.5rem;
`)
export const MainChartWrapper = styled.div(
  ({ theme }) => `
    width: 100%;
    height: 90%;
    grid-row: 3;
    grid-column: 1;
    margin: 1rem auto;
    border-top: none;
    border-radius: 0 0 .5rem .5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
`)
export const ListContainer = styled.div(({ theme }) => `
  grid-column: 1;
  grid-row: 2;
  height: 100%;
  width: 100%;
  display: block;
  padding: 0 2rem 0 1rem;
  overflow: scroll;

  &.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 0.1rem solid ${theme.palette.common.pageBackground};
    background-color: ${theme.palette.common.grayText};
  }
  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  ::-webkit-scrollbar:vertical {
    width: 11px;
  }
`)
export const CardListHeader = styled.div(({ theme }) => `
  grid-row: 1;
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 40rem 1fr 26rem;
  align-items: center;
  padding: 0 0 0 2rem;
  font-size: 1.6rem;
  border-bottom: 0.3rem solid ${theme.palette.common.grayBorder};
  color: ${theme.palette.common.grayText};

  &.reports {
    display: flex;
    height: fit-content;
  }
  &.flex {
    display: flex;
  }
`)
export const CardListDetail = styled.div(({ theme }) => `
  height: 100%;
  font-size: 1.2rem;
  color: ${theme.palette.common.grayText};
  margin: 0 auto;
  display: flex;
  align-items: flex-end;
  padding-bottom: 0.3rem;
`)
export const CardListContainer = styled.div(({ theme }) => `
  grid-column: 1;
  grid-row: 2;
  height: 100%;
  width: 100%;
  display: block;
  padding-right: 2rem;
  overflow: scroll;

  &.reports {
    grid-column: 4;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    padding: 0;
    margin: 0 0 6rem 0;
  }
  &.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  &.reportsLoading {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    grid-column: 2;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 0.1rem solid ${theme.palette.common.pageBackground};
    background-color: ${theme.palette.common.grayText};
  }
  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  ::-webkit-scrollbar:vertical {
    width: 11px;
  }
`)
export const CardControlsContainer = styled.div(
  ({ theme }) => `
    grid-column: 2;
    grid-row: 1/3;
    height: 100%;
    width: 100%;
    border-left: 0.1rem solid ${theme.palette.common.grayBorder};
    padding: 1rem;
`)
export const CardContainer = styled.div(
  ({ theme }) => `
    width: 100%;
    min-width: 95rem;
    height: fit-content;
    display: flex;
    border: 0.2rem solid ${theme.palette.common.grayBorder};
    background: ${theme.palette.common.cardBackground};
    border-radius: 1rem; 
    margin: 1rem 0;
    padding: 0.5rem;
    transition: box-shadow 0.3s ease;
    overflow: scroll;

    &.singleRow {
      padding: 1rem 0;
    }
    &.guide {
      overflow-x: hidden;
      overflow-y: scroll;
      grid-column: 3;
      flex-direction: column;
      border-radius: 0rem;
      padding: 0.5rem 0.5rem 2.5rem 0.5rem;
      min-width: 70rem;
      min-width: 90rem;
      max-width: 90rem;
      margin: 1rem auto 0 auto;
      max-height: calc(100vh - 10rem);
      ::-webkit-scrollbar-thumb {
        border-radius: 8px;
        border: 0.1rem solid ${theme.palette.common.pageBackground};
        background-color: ${theme.palette.common.grayText};
      }
      ::-webkit-scrollbar {
        -webkit-appearance: none;
      }
      ::-webkit-scrollbar:vertical {
        width: 11px;
      }
    }
    &.report {
      grid-column: 3;
      flex-direction: column;
      border-radius: 0rem;
      padding: 1.5rem;
      min-width: 70rem;
      max-width: 70rem;
      margin: 1rem auto 0 auto;
    }
    &.activeCard {
      margin: 1rem 3%;
      width: 94%;
      padding: 0 2.5rem 1.5rem 2.5rem;
      box-shadow: -0.1rem 0.1rem 0.3rem ${theme.palette.primary.darkShadow};
    }
    &.activeCard:hover {
      box-shadow: -0.1rem 0.1rem 0.3rem ${theme.palette.primary.main};
    }
    &.inactiveCard {
      margin: 1rem 3%;
      width: 94%;
      padding: 0 2.5rem 1.5rem 2.5rem;
      box-shadow: -0.1rem 0.1rem 0.3rem ${theme.palette.common.grayBorder};
    }
    &.inactiveCard:hover {
      box-shadow: -0.3rem 0.13em 0.9rem ${theme.palette.common.grayBorderDark};
    }
`)
export const CardIndex = styled.div(({ theme }) => `
  color: ${theme.palette.common.black};
  padding: 0 0 0 0.3rem;
`)
export const CardButton = styled.button(({ theme }) => `
  height 6rem;
  width: 3rem;
  padding: 0;
  color: ${theme.palette.primary.main};
  font-weight: 600;
  background: ${theme.palette.common.pageBackground};
  border: 0.1rem solid ${theme.palette.primary.main};
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0 0 0.3rem;
  transition: background 0.3s ease;
  transition: box-shadow 0.3s ease;

  &.horizontal {
    height: fit-content;
    min-width: fit-content;
    padding: 0.3rem 0.6rem;
  }
  &.actions {
    :hover {
      box-shadow: 0 0 0.5rem ${theme.palette.primary.main};
    }
  }
  
  &.manualInvestigation {
    background: ${theme.palette.common.grayBorder};
    height: 15rem;
    color: ${theme.palette.primary.dark};
    font-weight: 600;
    border: 0.1rem solid ${theme.palette.primary.dark};

    &:hover {
      cursor: hover;
      background: ${theme.palette.common.pageBackground};
      box-shadow: 0 0 0.5rem ${theme.palette.primary.dark};
    }
  }
  &.active {
    box-shadow: 0 0 0.5rem ${theme.palette.primary.main};
    background: ${theme.palette.common.cardBackground};
  }
  &.tier {
    width: 2rem;
  }
  &.one {
    color: ${theme.palette.common.red};
    font-weight: 600;
    border: 0.1rem solid ${theme.palette.common.red};

    &:hover {
      box-shadow: 0 0 0.5rem ${theme.palette.common.red};
    }
    &.noHover {
      &:hover {
        box-shadow: none;
      }
    }
  }
  &.two {
    color: ${theme.palette.secondary.dark};
    font-weight: 600;
    border: 0.1rem solid ${theme.palette.secondary.dark};

    &:hover {
      box-shadow: 0 0 0.5rem ${theme.palette.secondary.dark};
    }
    &.noHover {
      &:hover {
        box-shadow: none;
      }
    }
  }
  &.three {
    color: ${theme.palette.primary.dark};
    font-weight: 600;
    border: 0.1rem solid ${theme.palette.primary.dark};

    &:hover {
      box-shadow: 0 0 0.5rem ${theme.palette.primary.dark};
    }
    &.noHover {
      &:hover {
        box-shadow: none;
      }
    }
  }
  &:hover {
    cursor: pointer;
    background: ${theme.palette.common.cardBackground};
  }
  &.noHover {
    cursor: default;
    background: ${theme.palette.common.pageBackground};
  }
`)
  export const ActionIconWrapper = styled.div(
    ({ theme }) => `
    color: ${theme.palette.common.grayBorder};
    fill: ${theme.palette.common.grayBorder};
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: color 0.3s ease;

    &.even {
      color: ${theme.palette.common.cardBackground};
    }
    &.active {
      color: ${theme.palette.primary.main};
    }
    &.first {
      margin: auto 0.5rem auto 0;
    }
    &.medium {
      height: 3rem;
      width: 3rem;
    }
    &.grayText {
      color: ${theme.palette.common.grayText};
    }
    &.circle {
      border-radius: 2rem;
    }
    &.primary {
      color: ${theme.palette.primary.transparentHover};
      
      :hover {
        cursor: pointer;
        color: ${theme.palette.primary.dark};
      }
    }
    &.hover {

      :hover {
        cursor: pointer;
        color: ${theme.palette.common.grayTextLight};
  
        &.hoverBorder {
          border: 0.1rem solid ${theme.palette.common.grayText};
        }
      }
    }
    &.noHover {

      &:hover {
        cursor: default;
      }
    }
`)
export const ActionMenuItem = styled.div(({ theme}) => `
  height: 2rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition: background 0.2s ease;

  &.noHover {
    background: ${theme.palette.common.disabledGray};

    :hover {
      cursor: not-allowed;
    }
  }
  &.advance {
    background: ${theme.palette.primary.dark};
    height: 2.5rem;
    border-radius: 1.0rem;
    margin: auto;
    justify-content: center;
    cursor: pointer;
    padding: 0 0.5rem;
    
    &.focus {
      background: ${theme.palette.primary.main};
      cursor: default;
    }
    :hover {
      background: ${theme.palette.primary.main};
    }
  }
  &.dismiss {
    background: ${theme.palette.secondary.main};
    height: 2.5rem;
    border-radius: 1.0rem;
    margin: 0 auto;
    justify-content: center;
    cursor: pointer;
    padding: 0 0.5rem;

    &.focus {
      background: ${theme.palette.secondary.dark};
      cursor: default;
    }
  }
  &.dismiss:hover {
    background: ${theme.palette.secondary.dark};
  }
`)
export const ActionMenuTitle = styled.div(({ theme }) => `
  color: ${theme.palette.common.grayText};
  font-weight: 600;
  font-size: 1.1rem;
  height: fit-content;
  margin: 0 0 0 1rem;

  &.white {
    color: ${theme.palette.common.white};
    margin: 0;
  }
`)
export const DetailHeaderWrapper = styled.div(`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 6rem 1fr 10rem 1fr 6rem 1fr;
  justify-items: center;

  &.simple {
    grid-template-columns: 1fr;
  }
`)
export const DetailContainer = styled.div(({ theme }) => `
  display: grid;
  height: fit-content;
  align-items: center;
  justify-content: center;

  &.column {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  &.bigHeader {
    grid-template-rows: 4rem 1fr;
  }
  &.fitContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    background-color: ${theme.palette.common.white};
  }
  &.target {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: fit-content;
  }
  &.single {
    display: flex;
    justify-content: center;
  }
  &.large {
    width: fit-content;
  }
  &.singleRow {
    display: flex;
    height: fit-content;
  }
  &.thirds {
    width: 33%
  }
  &.mortgage {
    grid-template-rows: none;
  }
  &.memberPhoto {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    margin: 0 0 0 3rem;
  }
  &.member {
    margin: 0 2rem;
    display: flex;
    width: fit-content;
    line-height: 2.5rem;
  }
`)
export const DetailListContainer = styled.div(`
  display: flex;
  height: fit-content;
  justify-content: center;

  &.performance {
    height: 90%;
  }
  &.timeline {
    width: 100%;
    margin: 0 0 1rem 0;
    justify-content: flex-start;
  }
  &.column {
    flex-direction: column;
    align-items: center;
  }
`)
export const DetailTagContainer = styled.div(`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  height: fit-content;
  align-items: center;
  margin: 0 0 0 1rem;
  padding: 0.5rem;
`)
export const AddButton = styled.div(({ theme }) => `
  color: ${theme.palette.common.grayBorder};
  height: 2rem;
  width: 2rem;
  transition: color 0.3s ease;
  margin: 0 0.6rem;

  &:hover {
    cursor: pointer;
    color: ${theme.palette.primary.transparentHover};
  }
`)
export const DetailInfoContainer = styled.div(`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &.timeline {
    min-width: fit-content;
  }
  &.space {
    justify-content: space-around;
  }
  &.item {
    align-items: flex-end;
    margin: auto 0.5rem auto 0
  }
  &.potential {
    justify-content: space-around;
  }
  &.notificationTitle {
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  &.performance {
    justify-content: space-between
  }
`)
export const Badge = styled.div(
  ({ theme }) => `
    height: fit-content;
    padding: 0.1rem 0.4rem;
    border-radius: 1rem;
    color: ${theme.palette.common.white};
    position: relative;
    top: -0.1rem;
    right: 0.3rem;
    display: flex;
    align-items: center;
    justify-content: center;

    &.one {
      background-color: ${theme.palette.common.red}
    }
    &.two {
      background-color: ${theme.palette.secondary.dark}
    }
    &.three {
      background-color: ${theme.palette.primary.dark}
    }
`)
export const Detail = styled.div(
  ({ theme }) => `
    font-size: 1rem;
    display: flex;
    justify-content: flex-end;
    width: fit-content;

    &.center {
      margin: 0 auto;
    }
    &.thin {
      font-weight: 300;
    }
    &.timeline {
      width: 20rem
    }
    &.timelineHeader {
      font-size: 1.2rem;
      font-weight: 600;
      justify-self: flex-start;
      margin: 0.2rem 0 0 0;
    }
    &.header {
      font-size: 1.4rem;
      font-weight: 600;
    }
    &.outcome {
      text-decoration: underline;
      text-decoration-thickness: 0.2rem;
    }
    &.refinance {
      text-decoration-color: ${theme.palette.primary.main};
    }
    &.renegotiation {
      text-decoration-color: ${theme.light.closures.renegotiation};
    }

    &.item {
      font-weight: 600;
      height: 1.5rem;
    }
    &.highlight {
      color: ${theme.palette.common.grayText};
    }
    &.timelineItem {
      font-weight: 600;
      overflow-wrap: anywhere;
      padding: 0 0 0 0;
      justify-content: flex-start;
    }
    &.column {
      flex-direction: column;
      height: 3.2rem;
    }
    &.note {
      height: fit-content
    }
    &.bold {
      font-weight: 600;
    }
    &.notificationTitle {
      font-size: 1.4rem;
      font-weight: 600;
      color: ${theme.palette.common.black};
      height: fit-content;
    }
    &.potential {
      font-weight: 600;
      height: 2rem;
    }
    &.subject {
      font-size: 1.2rem;
      color: ${theme.palette.common.black};
      margin: 0.2rem 0.2rem 0 0;
      font-weight: 400;
    }
    &.memberName {
      font-size: 2.6rem;
      height: fit-content;
      color: ${theme.palette.common.black};
      font-weight: 600;
    }
    &.date {
      font-size: 1rem;
      color: ${theme.palette.common.black};
      margin: 0.2rem 0.2rem 0 0;
      font-weight: 400;
    }
    &.role {
      line-height: 1.5rem;
      height: 1rem;
    }
    &.target {
      font-weight: 600;
      color: ${theme.palette.primary.main};
      height: 2rem;
      font-size: 1.5rem;
    }
    &.red {
      color: ${theme.palette.common.red};
      font-weight: 600;
    }
    &.green {
      color: ${theme.palette.primary.main};
      font-weight: 600;
    }
    &.one {
      color: ${theme.palette.common.red};
      border: 0.1rem solid ${theme.palette.common.red};
      background: ${theme.palette.common.redTransparent};
    }
    &.two {
      color: ${theme.palette.secondary.dark};
      border: 0.1rem solid ${theme.palette.secondary.dark};
      background: ${theme.palette.secondary.labelTransparent};
    }
    &.three {
      color: ${theme.palette.primary.dark};
      border: 0.1rem solid ${theme.palette.primary.dark};
      background: ${theme.palette.primary.labelTransparent};
    }
    &.wrapped {
      margin: 0 0.5rem 0 0;
      padding: 0 1rem;
      font-weight: 600;
      border-radius: 1rem;
      border: 1px solid ${theme.palette.common.cardBackground};
      color: ${theme.palette.primary.dark};
      border: 0.1rem solid ${theme.palette.primary.medium};
    }
    &.wrappedRed {
      margin: 0 0.5rem 0 0;
      padding: 0 1rem;
      font-weight: 600;
      border-radius: 1rem;
      border: 1px solid ${theme.palette.common.cardBackground};
      color: ${theme.palette.common.redDark};
      border: 0.1rem solid ${theme.palette.common.red};
    }
    &.wrappedGray {
      margin: 0 0.5rem 0 0;
      padding: 0 1rem;
      font-weight: 600;
      border-radius: 1rem;
      border: 1px solid ${theme.palette.common.cardBackground};
      color: ${theme.palette.common.grayText};
      border: 0.1rem solid ${theme.palette.common.grayText};
    }
    &.targetPreview {
      height: 4rem;
      align-items: center;
      margin: 0;
    }
    &.targetAdjust {
      height: 2rem;
      align-items: center;
      margin: 0;
    }
    &.gray {
      border: 0.1rem solid ${theme.palette.common.grayBorder};
    }
    &.timelineDetail {
      justify-content: flex-end;
      min-width: 10rem;
      color: ${theme.palette.common.black};
    }
    &.tier {
      margin: 0.3rem 0;
      padding: 0 1rem;
      font-weight: 600;
      border-radius: 1rem;
      justify-content: center;
    }
`)
export const CompareLink = styled.button(
  ({ theme }) => `
    color: ${theme.palette.primary.main};
    background-color: ${theme.palette.common.grayBorder};
    border: 0.1rem solid ${theme.palette.common.pageBackground};
    border-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    transition: background-color 0.3s ease;
    transition: color 0.1s ease;
    padding: 0 1rem;
    min-width: 10rem;

    &.small {
      min-width: fit-content;
      padding: 0.3rem;
    }
    &.secondary {
      color: ${theme.palette.secondary.main};
      :hover {
        color: ${theme.palette.secondary.main};
        background-color: ${theme.palette.common.pageBackground};
        border: 0.1rem solid ${theme.palette.common.grayBorder};
      }
      :focus {
        background-color: ${theme.palette.common.grayButtonFocusLight};
        box-shadow: 0 0 0.3rem ${theme.palette.secondary.main};
        border: 0.1rem solid ${theme.palette.secondary.light};
        outline: none;
        
        :hover {
          color: ${theme.palette.secondary.main};
          background-color: ${theme.palette.common.pageBackground};
          border: 0.1rem solid ${theme.palette.common.grayBorder};
        }
      }
    }
    &:hover {
      background-color: ${theme.palette.common.pageBackground};
      border: 0.1rem solid ${theme.palette.common.grayBorder};
    }
    &:focus {
      background-color: ${theme.palette.common.grayButtonFocusLight};
      border: 0.1rem solid ${theme.palette.common.grayText};
      outline: none;
      color: ${theme.palette.common.black};
    }
    &.notification {
      height: 1.5rem;
      min-width: 4rem;
      color: ${theme.palette.common.grayText};
    }
    &.gray {
      background-color: ${theme.palette.common.grayBorder};
      color: ${theme.palette.common.black};

      &.incative {
        color: ${theme.palette.common.grayText};
      }
      
      :hover {
        color: ${theme.palette.common.black};
        background-color: ${theme.palette.common.grayBorderLight};
        border: 0.1rem solid ${theme.palette.common.grayTextLight};
      }
    }
    &.grayFocus {
      color: ${theme.palette.primary.main};
      background-color: ${theme.palette.common.pageBackground};
      border: 0.1rem solid ${theme.palette.common.grayBorder};
      box-shadow: 0 0 0.3rem ${theme.palette.primary.main};
      cursor: default;
    }
    &.tooltip:hover {
      cursor: alias;
    }
    &.disabled {
      :hover {
        cursor: not-allowed;
      }
    }
  `
)
export const ControlContainer = styled.div(`
  width: 100%;
  padding: 0rem 0 0 2rem;
  gap: 1rem;

  &.flex {
    padding: 0;
    display: flex;
    width: fit-content;
  }
`)
export const ControlHeader = styled.div(
  ({ theme }) => `
    font-size: 1.6rem;
    font-weight: 500;
    padding: 0 0 0.5rem 0;
`)
export const SubHeader = styled.div(({ theme }) => `
    font-size: 1.0rem;
    color: ${theme.palette.common.grayText};
    font-weight: 400;
    margin: 0 auto;

    &.secondary {
      color: ${theme.palette.secondary.main};
      font-weight: 600;
    }
    &.modal {
      display: flex;
      width: 90%;
      flex-direction: column;
      text-align: center;
      align-items: center;
      color: ${theme.palette.common.black}; 
      padding: 2rem 0 0 0;
      font-size: 1.2rem;
    
      &.xs {
        width: 100%;
      }
      &.border {
        border-bottom: 0.1rem solid ${theme.palette.common.grayBorder};
      }
      &.verticalCenter {
        margin: 2rem 0 6.5rem 0;
        height: 100%;
        justify-content: center;
      }
    }
`)
export const StyledInputElement = styled.input(({ theme, disabled }) => `
  width: 20rem;
  font-size: 1rem;
  font-family: IBM Plex Sans, sans-serif;
  font-weight: 400;
  line-height: 1.5;
  color: ${theme.palette.common.textWhite};
  background: ${theme.palette.common.cardBackground};
  border: none;
  border-radius: 0.6rem;
  padding: 0.4rem;
  height: 2.5rem;
  outline: none;
  border: 0.05rem solid ${theme.palette.common.grayText};
  
  &.auth {
    background: ${theme.palette.common.black};
    color: ${theme.palette.common.grayBorderDark};
    font-size: 1.4rem;
    padding: 1.6rem 0.8rem;
    font-weight: 300;
  }
  &.borderBottomOnly {
    outline: 0.1rem solid ${theme.palette.primary.dark};
    border-bottom: 0.05rem solid ${theme.palette.common.grayBorderDark};
    border-radius: 0;
  }
  &.true {
    background: ${theme.palette.common.grayOpaque};
    cursor: not-allowed;
  }
  &:hover {
    cursor: pointer;
    // border: 0.05rem solid ${theme.light.button.primaryBackground};

    &.borderBottomOnly {
      border-bottom: 0.05rem solid ${theme.palette.primary.dark};
    }
  }
  &:focus {
    border: 0.05rem solid ${theme.light.button.primaryBackground};

    &.borderBottomOnly {
      border-bottom: 0.05rem solid ${theme.palette.primary.dark};
    }
    :hover {
      cursor: text;
    }
  }
`)
export const ListItem = styled.div(({ theme }) => `
  width: 100%;
  height: 3rem;
  display: grid;
  grid-template-columns: 6rem 6rem 1fr 1fr;
  border-right: 0.1rem solid ${theme.palette.common.grayBorder};
  border-bottom: 0.1rem solid ${theme.palette.common.grayBorder};
  border-left: 0.1rem solid ${theme.palette.common.grayBorder};
  color: ${theme.palette.common.black};
  text-align: center;
  align-items: center;
  justify-items: center;
  padding: 0.2rem;

  &.header {
    border: 0.3rem solid ${theme.palette.common.grayBorder};
    font-weight: 600;
    color: ${theme.palette.common.grayText};
    padding: 0;
  }
  &.list:hover {
    background: ${theme.palette.common.grayHover};
  }
  &.user {
    background: ${theme.palette.common.highlight};
  }
  &.user:hover {
    background: ${theme.palette.common.highlightHover};
  }
`)
export const ActionContainer = styled.div(({ theme }) => `
  display: flex;
  align-items: center;
  padding-right: 0.2rem;
  color: ${theme.palette.common.grayText};
  transition: all .25s ease-in-out;

  &.edit {
    justify-content: center;
    border: 0.05rem solid ${theme.palette.common.grayText};
    border-radius: 2rem;
    height: 2rem;
    width: 2.5rem;
    padding: 0 0 0 0.1rem;
    opacity: 0.8;
    background: linear-gradient(${theme.light.button.actionBackground}, ${theme.light.button.actionBackground}, ${theme.light.button.actionBackground}, ${theme.light.button.actionBackground}, ${theme.light.button.actionBackgroundShadow}, ${theme.light.button.actionBackgroundShadow});
    
    :hover {
      color: ${theme.light.button.actionColorActive};
      cursor: pointer;
      opacity: 1;
    }
  }
  &.delete:hover {
    color: ${theme.palette.common.error};
    cursor: pointer;
  }
  &.disabled:hover {
    color: ${theme.palette.common.grayText};
    cursor: not-allowed;
  }
`)
export const AccentInfo = styled.div(({ theme }) => `
  color: ${theme.palette.common.grayText};
  font-style: italic;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  text-align: center;
  margin: 0 auto;
`)
export const ParameterControlWrapper = styled.div(`
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
  &.disabled {
    
    &:hover {
      cursor: not-allowed;
    }
  }
`)
export const ReportContainer = styled.div(({ theme }) => `
  height: fit-content;
  padding: 0 1rem;
  margin: 0 auto;
  overflow: scroll;
  grid-row: 1;
  grid-column: 4;

  height: 55rem;

  ::-webkit-scrollbar-thumb {
    border-radius: 0.8rem;
    border: 0.1rem solid ${theme.palette.common.pageBackground};
    background-color: ${theme.palette.common.grayText};
  }
  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  ::-webkit-scrollbar:vertical {
    width: 11px;
  }
`)
export const ReportsContentContainer = styled.div(({ theme }) => `
  grid-row: 3;
  grid-column: 4;
  width: 100%;
  height: fit-content;
  padding: 0 1rem;
  overflow-y: scroll;
  border-bottom: 0.1rem solid ${theme.palette.common.grayText};
  background: ${theme.palette.common.grayBorder};
`)
export const ReportRow = styled.div(`
  display: grid;
  grid-template-columns: 20rem 20rem;
`)
export const ReportDetail = styled.div(`
  display: flex;

  &.single {
    grid-column: 1/3;
  }
  &.one {
    grid-column: 1;
  }
  &.two {
    grid-column: 2;
  }
`)
export const ReportDetailHeader = styled.div(`
  font-size: 1rem;
  font-weight: 600;
  margin: 0 0.4rem 0 0;
`)
export const ReportDetailText = styled.div(`
  font-size: 1rem;
  font-weight: 400;
`)
export const TreeContainer = styled.div(({ theme }) => `
  grid-column: 1;
  grid-row: 2;
  width: 100%;
  height: fit-content;
  align-self: flex-start;
  max-height: 53rem;
  border: 0.5rem solid ${theme.palette.common.grayBorder};
  border-radius: 1rem;
  background-color: ${theme.palette.common.cardBackground};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 3rem 0;
`)
export const ReportTreeListContainer = styled.div(({ theme }) => `
  margin: 0.5rem 0 2rem 0;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 0.3rem solid ${theme.palette.common.grayBorder};
  border-bottom: 0.3rem solid ${theme.palette.common.grayBorder};
  background-color: ${theme.palette.common.cardBackground};
  overflow-y: scroll;
  width: 30rem;
  padding: 0 0 0 0.8rem;

  ::-webkit-scrollbar-thumb {
    border-radius: 0.8rem;
    border: 0.1rem solid ${theme.palette.common.pageBackground};
    background-color: ${theme.palette.common.grayText};
  }
  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  ::-webkit-scrollbar:vertical {
    width: 11px;
  }
`)
export const NewLabel = styled.div(({ theme }) => `
  width: 100%;
  height: fit-content;
  text-align: center;
  align-self: center;
  border: 0.1rem solid #fff;
  border-radius: 1rem;
  box-shadow: 0 0 0.3rem #00940F;
  background: ${theme.palette.primary.transparentHover};
  color: ${theme.palette.common.black};
  margin: 0;
  padding: 0.1rem 0.3rem;
  text-decoration: none;
  
  &.reportTree {
    grid-column: 2;
  }
  &.leadCard {
    grid-column: 2;
  }
`)
export const ModalContainer = styled.div(({theme}) => `
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: ${theme.palette.common.cardBackground};
  height: 100%;
  
  &.threePanel {
    display: grid;
    height: 66rem;
    max-height: 66rem;
    grid-template-columns: 1rem 20rem 1fr 40rem 1rem;
    grid-template-rows: 4rem 4rem 5.6rem 1fr 2rem;
  }
  &.twoPanel {
    display: grid;
    height: 66rem;
    max-height: 66rem;
    grid-template-rows: 4rem 4rem 1fr 2rem;
    grid-template-columns: 1rem 20rem 1fr 1rem;
  }
  &.onePanel {
    display: grid;
    grid-template-rows: 4rem 1fr 1rem;
    grid-template-columns: 1rem 1fr 1rem;

    &.small {
      height: 100%;
    }
  }
`)
export const ComponentBorder = styled.div(({theme}) => `
  width: 100%;
  height: 100%;
  z-index: 100;
  
  &.top {
    color: ${theme.palette.common.grayText};
    display: flex;
    justify-content: flex-end;
    border-bottom: 0.1rem solid ${theme.palette.common.grayText};
    box-shadow: 0 0 1rem ${theme.palette.common.grayTextLight};
    background-image: linear-gradient(${theme.palette.common.grayBorderMedium}, ${theme.palette.common.grayBorderMedium}, ${theme.palette.common.grayBorderMedium}, ${theme.palette.common.grayTextLight});
  }
  &.bottom {
    border-top: 0.1rem solid ${theme.palette.common.grayText};
    box-shadow: 0 0 0.5rem ${theme.palette.common.grayTextLight};
    background-image: linear-gradient(${theme.palette.common.grayBorder}, ${theme.palette.common.grayTextLight});
  }
  &.left {
    border-right: 0.1rem solid ${theme.palette.common.grayText};
    box-shadow: 0 0 0.5rem ${theme.palette.common.grayTextLight};
    background: ${theme.palette.common.grayBorder};
  }
  &.right {
    border-left: 0.1rem solid ${theme.palette.common.grayText};
    box-shadow: 0 0 0.5rem ${theme.palette.common.grayTextLight};
    background: ${theme.palette.common.grayBorder};

    &.border {
      border-left: 0.1rem solid ${theme.palette.common.grayText};
    }
  }
`)
export const ModalContentContainer = styled.div(
  ({ theme }) => `
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    grid-row: 3/4;
    grid-column: 3;

    &.darkBackground {
      background: ${theme.palette.common.grayBorder};
    }
    &.leadActions {
      grid-row: 3/5;
    }
    &.centerColumn {
      padding: 1rem 0 0 1rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      overflow-x: hidden;
    }
    &.topColumn {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
    }
    &.propertyDetails {
      grid-row: 2/4;
    }
    &.discrepancies {
      display: grid;
      grid-template-columns: 55rem 45rem;
    }
    &.noScroll {
      overflow: hidden;
    }
    &.column {
      flex-direction: column;
      justify-content: flex-start;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 0.6rem;
      border: 0.1rem solid red ${theme.palette.common.pageBackground};
      background-color: ${theme.palette.common.grayText};
    }
    ::-webkit-scrollbar {
      -webkit-appearance: none;
    }
    ::-webkit-scrollbar:vertical {
      width: 0.6rem;
    }
`)
export const ModalHeaderMessage = styled.div(({ theme }) => `
  font-size: 1.4rem;
  font-weight: 300;
  margin: 0 0 0 1rem;
  text-transform: none;
   
  &.green {
    color: ${theme.palette.primary.main}; 
  }
  &.success {
    color: ${theme.palette.primary.main}; 
  }
  &.red {
    color: ${theme.palette.common.red}; 
  }
  &.error {
    color: ${theme.palette.common.red}; 
  }
  &.gray {
    color: ${theme.palette.common.grayText}; 
  }
`)
export const Divider = styled.div(({ theme }) => `
  height: 0.1rem;
  width: 95%;
  align-self: center;
  background-color: ${theme.palette.common.grayBorder};

  &.green {
    background-color: ${theme.palette.primary.main};
  }
  &.red {
    background-color: ${theme.palette.common.red};
  }
  &.gray {
    background-color: ${theme.palette.common.grayBorder};
  }
  &.thick {
    border: 0.3rem solid ${theme.palette.common.grayBorder};
    width: 100%;
  }
  &.inScroll {
    border: 0.1rem solid ${theme.palette.common.grayBorder};
    width: 100%;
  }
`)
export const Label = styled.div(
  ({ theme }) => `
    display: flex;
    justify-content: center;
    color: ${theme.palette.common.black};
    white-space: nowrap;
    margin: 0 1rem 0 0;

    &.count {
      margin: 0 0 0 1rem;
    }
    &.colorLight {
      color: ${theme.palette.common.grayBorder};
    }
`)
export const ButtonWrapper = styled.div(`
  width: 20rem;
  display: flex;
  margin: 0 auto;
  width: fit-content;
  justify-content: space-between;
  align-items: center;

  &.justifyCenter {
    justify-content: center;
  }
  &.justifyStart {
    justify-content: flex-start;
  }
  &.selfAlignEnd {
    align-self: flex-end;
  }
  &.marginAuto {
    width: fit-content;
    margin: auto;
  }
  &.static {
    position: static;
  }
`)
export const InputWrapper = styled.div(`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-end;
  justify-content: center;
  width: 20rem;
  margin: 0 1rem;

  &.alignStart {
    align-items: flex-start;
  }
`)
export const DetailText = styled.div(`
  font-weight: 600;
  font-size: 1.4rem;
`)
export const StatusAndLabelWrapper = styled.div(`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  margin: 0;
  height: 3rem;
  
  &.center {
    justify-content: center;
  }
  &.notes {
    min-height: fit-content;
    height: 2rem;
    align-items: center;
  }
  &.column {
    flex-direction: column;
    align-items: flex-start;
    height: 2.5rem;
    justify-content: flex-end;
  }
  &.small {
    height: fit-content;
  }
`)
export const ErrorWrapper = styled.div(`
  display: flex;
  padding: 0.5rem;
  border-radius: 1.0rem;
  color: red;
  border: 0.1rem solid red;
  align-items: center;
  gap: 0.5rem;
  height: 2.0rem;
  font-size: 1.0rem;
  white-space: nowrap;
  
  &.column {
    border: none;
    padding: 0;
    gap: 0.2rem;
  }
`)
export const Typeography = styled.span(({ theme }) => `


  &.dark {
    color: ${theme.palette.common.grayBorderDark};
  }
  &.exoItalic {
    font-family: exo-mediumItalic;
  }
  &.exoBold {
    font-family: exo-bold;
  }
  &.medium {
    font-size: 1.4rem;
  }
  &.fader {
    animation: typeographyFader linear infinite 2s;
    @keyframes typeographyFader {
      0% {
        opacity: 0.1;
      }
      50% {
        opacity: 0.7;
      }
      100% {
        opacity: 0.1;
      }
    }
  }
`)
export const AlertWrapper = styled.div(({ theme }) => `
  display: flex;
  padding: 0.5rem;
  border-radius: 1.0rem;
  color: red;
  border: 0.1rem solid red;
  align-items: center;
  gap: 0.5rem;
  height: 2.0rem;
  font-size: 1.0rem;
  white-space: nowrap;
  width: fit-content;

  &.success {
    border: 0.1rem solid ${theme.light.accent.primaryMedium};
    color: ${theme.light.accent.primaryMedium};
  }
  &.caution {
    border: 0.1rem solid ${theme.light.accent.cautionDark};
    color: ${theme.light.accent.cautionDark};
  }
  &.error {
    border: 0.1rem solid ${theme.light.accent.error};
    color: ${theme.light.accent.error};
  }
  &.medium {
    height: 2.5rem;
    padding: 0.9rem;
    font-size: 1.4rem;
  }
  &.thin {
    font-weight: 300;
  }
  &.column {
    border: none;
    padding: 0;
    gap: 0.2rem;
  }
  &.twoLine {
    height: 4rem;
  }
`)
export const NoteContainer = styled.div(({ theme }) => `
  background: ${theme.palette.common.cardBackground};
  display: flex;
  flex-direction: column;
  height: 80%;
  border-radius: 0.5rem;
  overflow: scroll;

  &.center {
    align-items: center;
    justify-content: center;
  }
  &.actionModal {
    width: 90%;
    margin: 1rem auto 0 auto;
    border: 0.3rem solid ${theme.palette.common.grayText};
  }
`)
export const NoteContentContainer = styled.div(({ theme }) => `
  padding: 0rem 2rem 1rem 2rem;
  border-bottom: 0.1rem solid ${theme.palette.common.grayBorder};
  border-top: 0.1rem solid ${theme.palette.common.grayBorder};
  
  &.list {
    height: 100%;
    display: flex;
    justify-content: fllex-start;
    align-items: flex-start;
    overflow: scroll;

    ::-webkit-scrollbar-thumb {
      border-radius: 0.8rem;
      border: 0.1rem solid red ${theme.palette.common.pageBackground};
      background-color: ${theme.palette.common.grayText};
    }
    ::-webkit-scrollbar {
      -webkit-appearance: none;
    }
    ::-webkit-scrollbar:vertical {
      width: 0.5rem;
    }
  }
  &.input {
    display: flex;
    align-items: flex-end;
    width: 100%;
  }

`)
export const TimelineContainer = styled.div(({ theme }) => `
  width: 40rem;
  margin: 1rem 5rem 0 5rem;
  overflow: scroll;
  border-top: 1rem solid ${theme.palette.common.grayBorder};
  border-left: 1rem solid ${theme.palette.common.grayBorder};
  background-color: ${theme.palette.common.grayBorder};
  
  &.actionsModal {
    height: 100%;
    border-top: 0;
    border-left: 0.1rem solid ${theme.palette.common.grayText};
    padding: 0.5rem 0.5rem 0 0.5rem;
    background-color: ${theme.palette.common.grayBorder};
    grid-row: 3/5;
    grid-column: 4;
    margin: 0 auto;
  }
  &.report {
    width: 100%;
    margin: 0;
    height: fit-content;
    background: ${theme.palette.common.cardBackground};
    border: 1rem solid ${theme.palette.common.grayBorder};
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 0.8rem;
    border-top: none;
    border-left: none;
    border: 0.1rem solid red ${theme.palette.common.pageBackground};
    background-color: ${theme.palette.common.grayText};
  }
  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  ::-webkit-scrollbar:vertical {
    width: 0.5rem;
  }
`)
export const MilestonePathContainer = styled.div(({ theme }) => `
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0.5rem 0 0.5rem;
  background-color: ${theme.palette.common.white}
`)
export const MilestoneConnector = styled.div(({ theme }) => `
  width: 0.1rem;
  height: 100%;
  background-color: ${theme.palette.common.black};

  &.small {
    height: 0.4rem
  }
  &.notify {
    background-color: ${theme.palette.primary.main};
  }
`)
export const ListRow= styled.div(`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr minmax(14rem,18rem) 19rem 21rem 18rem 1fr;
  position: sticky;
  
  &.overview {
    grid-template-columns: 1fr 19rem 26rem 23rem 1fr;
  }
  &.resolve {
    grid-template-rows: auto 0rem;
  }
  &.compareLegend {
    width: 100%;
    grid-template-rows: 1.5rem 1.5rem;
    grid-template-columns: 1fr 11rem 24rem 12rem 1fr;

    &.noGrid {
      display: flex;
    }
  }
`)
export const CompareDetail = styled.div(({theme}) => `
  width: 100%;
  display: flex;
  margin: 0.1rem 0;
  height: 100%;
  align-items: center;
  text-align: center;

  &.recordRow {
    border-top: 0.1rem solid ${theme.palette.common.grayBorder};
    border-bottom: 0.1rem solid ${theme.palette.common.grayBorder};
  }
  &.resolve {
    justify-content: flex-start;
    align-items: flex-end;
    grid-row: 2;
    z-index: 1;
    position: relative;
    top: -1.7rem;
    flex-direction: column;
    background: white;
    height: fit-content;
  }
  &.header {
    font-weight: 600;
    justify-content: center;
    font-size: 1.2rem;
    margin: 0 0 0.5rem 0;
    border-top: none;
    border-bottom: none;
  }
  &.section {
    grid-column: 3/6;
    background: ${theme.palette.common.grayOpaque};
    border-top: 0.1rem solid ${theme.palette.common.grayBorderDark};
    border-bottom: 0.1rem solid ${theme.palette.common.grayBorderDark};
  }
`)
export const DetailWrapper = styled.div(({theme}) => `
  width: fit-content;
  height: fit-content;
  border-radius: 1rem;
  padding: 0 1rem;
  color: ${theme.palette.common.black};
  font-weight: 600;
  transition: background 0.1s ease;
  transition: border 0.3s ease;

  &.main {
    border: 0.1rem solid ${theme.palette.primary.main};
  }
  &.initial {
    font-weight: 600;
    border: 0.1rem solid ${theme.palette.common.grayBorderDark};
    background: ${theme.palette.common.grayOpaque};
  }
  &.one {
    font-weight: 600;
    border: 0.1rem solid ${theme.palette.common.red};
    background: ${theme.palette.common.redTransparent};
  }
  &.two {
    font-weight: 600;
    border: 0.1rem solid ${theme.palette.secondary.dark};
    background: ${theme.palette.secondary.labelTransparent};
  }
  &.three {
    font-weight: 600;
    border: 0.1rem solid ${theme.palette.primary.dark};
    background: ${theme.palette.primary.labelTransparent};
  }
  &.hover {
    :hover {
      border: 0.1rem solid ${theme.palette.primary.main};
      background: ${theme.palette.primary.labelTransparent};
      cursor: pointer;

      &.repo {
        background: ${theme.palette.common.white};
      }
    }
  }
  &.active {
    color: ${theme.palette.common.redDark};
  }
  &.rejected {
    color: ${theme.palette.common.orange};
  }
  &.resolved {
    color: ${theme.palette.primary.dark};
  }
  &.gray {
    color: ${theme.palette.common.grayText};
  }
  &.empty {
    font-style: italic;
  }
  &.fine {
    font-weight: 400;
  }
  &.addButton {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2rem;
    padding: 0.1rem 1.5rem;
    font-weight: 600;
    border-radius: 1rem;
    border: 0.1rem solid ${theme.palette.common.grayText};
    background: ${theme.palette.common.offWhite};
    transition: background 0.2s ease;
    
    :hover {
      border: 0.1rem solid ${theme.palette.primary.main};
      background: ${theme.palette.primary.labelTransparent};
      cursor: pointer;
    }
    &.active {
      border: 0.1rem solid ${theme.palette.primary.main};
      background: ${theme.palette.primary.labelTransparent};
      color: ${theme.palette.common.black};
    }
  }
  &.addButtonDisabled {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2rem;
    padding: 0.1rem 1.5rem;
    font-weight: 600;
    border-radius: 1rem;
    border: 0.1rem solid ${theme.palette.common.grayBorderLight};
    background: ${theme.palette.common.grayTransparent};
    color: ${theme.palette.common.grayText};
  
    :hover {
      cursor: not-allowed;  
    }
  }
`)
export const InlineLink = styled.button(({theme}) => `
  padding: 0 0.3rem;
  border: none;
  background: none;
  color: ${theme.palette.primary.dark};

  :hover {
    color: ${theme.palette.primary.main};
    cursor: pointer;
  }
`)
export const AuthContainer = styled.div(
  ({ theme }) => `
    height: fit-content;
    display: flex;
    margin: auto;
    flex-direction: column;
    align-items: center;
    background-color: ${theme.light.auth.containerBackground};
    z-index: 100;
    border-radius: 1rem;
    border: 0.3rem solid ${theme.palette.common.grayDark};
`)
export const ImageElements = styled.img(({theme}) => `
  width: 100vw;
  margin: auto;
  height: auto;
  
  &.centerLogo {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 100;
    margin: auto;
    left: 0;
    right: 0;
  }
  &.loading {
    width: 2rem;
    animation: fadeInAnimation ease-in-out 2.2s;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
    @keyframes fadeInAnimation {
      0% {opacity: 0;}
      50% {opacity: 1;}
      100% {opacity: 0;}
    }
  }
  &.loaded {
    width: 2rem;
    animation-name: spin, fadeIn;
    animation: spin 3.5s ease-in, fadeIn 3.0s linear;
    animation-iteration-count: 1, 1;
    animation-fill-mode: forwards, forwards;
    &.one {
      animation-delay: 1s, 0.3s;
    }
    &.two {
      animation-delay: 1.05s, 0.3s;
    }
    &.three {
      animation-delay: 1.2s, 0.4s;
    }
    &.four {
      animation-delay: 1.25s, 0.4s;
    }
    &.five {
      animation-delay: 1.4s, 0.5s;
    }
    &.six {
      animation-delay: 1.45s, 0.5s;
    }
    &.seven {
      animation-delay: 1.6s, 0.6s;
    }
    &.eight {
      animation-delay: 1.65s, 0.6s;
    }
    &.nine {
      animation-delay: 1.8s, 0.7s;
    }
    &.ten {
      animation-delay: 1.85s, 0.7s;
    }
    @keyframes spin {
      from {transform:rotate(0deg);}
      to {transform:rotate(1440deg);}
    }
    @keyframes fadeIn {
      0% {opacity: 0;}
      40% {opacity: 0;}
      100% {opacity: 1;}
    }
  }
  &.sacredGeometry {
    width: 4rem;

    &.one {
      animation-name: emerge;
      animation: emerge 5s ease-in-out;
      animation-iteration-count: infinite;
      animation-fill-mode: forwards;
      animation-delay: 2s;
      @keyframes emerge {
        from {transform:rotate(0deg);}
        to {transform:rotate(360deg);}
      }
    }
    &.two {
      animation-name: emerge;
      animation: emerge 4.5s ease-in-out;
      animation-iteration-count: infinite;
      animation-fill-mode: forwards;
      animation-delay: 2.12s;
      @keyframes emerge {
        from {transform:rotate(0deg);}
        to {transform:rotate(360deg);}
      }
    }
  }
  &.loginBackground {
    position: absolute;
    bottom: 0;
    height: 55rem;
    width: auto;
  }
  &.opaque {
    opacity: 0.3;
  }
`) 
export const ScreenSaverLogo = styled.img(({theme}) => `
  width: 100vw;
  margin: auto;
  height: auto;
  top: 4rem;
  position: absolute;

  &.opaque {
    opacity: 0.05;
  }
  &.dashboard {
    animation: spin 3s linear;
    animation-timing-function: ease-in;
    @keyframes spin {
      from {transform:rotate(0deg);}
      to {transform:rotate(360deg);}
    }
  }
`)
export const ScreenSaverContainer = styled.div(({theme}) => `
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;

  &.screenSaver {
    z-index: 101;
    background: ${theme.palette.common.black};
    grid-column: 1;
    grid-row: 1;
  }
  
  &.login {
    grid-column: 1/4;
    grid-row: 1/3;
    background: linear-gradient(to right, 
      rgb(28, 28, 31, 1) 0%,
      rgb(28, 28, 31, 0.1) 50%,
      rgb(28, 28, 31, 1) 100%
    ); 
  }
  &.fadeOut {
    z-index: 101;
    background: ${theme.palette.common.black};
  
    animation: fadeOut 2.5s;
    animation-fill-mode: forwards;
    animation-delay: 2.0s;
    @keyframes fadeOut {
      0% {
        opacity: 1;
      }
      90% {
        opacity: 0.9;
      }
      100% {
        opacity: 0;
      }
    }
  }
  &.fadeIn {
    z-index: 101;
    background: ${theme.palette.common.black};
  
    animation: fadeIn 2.5s;
    animation-fill-mode: forwards;
    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }

  &.dashboard {
    z-index: 101;
    background: ${theme.light.panel.primaryBackground};

    animation: fadeOut 2.5s;
    animation-fill-mode: forwards;
    animation-delay: 1.0s;
    @keyframes fadeOut {
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  }
`)

export const ScannerOverlay = styled.div(({theme}) => `
  z-index: 100;
  width: 100%;
  height: 100%;
  
  &.cover {
    z-index: 101;
    background: ${theme.palette.common.black};
  }
  &.fadeOverlay {
    background: ${theme.palette.common.black};
    animation: fader 5s ease-in-out infinite;
    animation-delay: 1.5s;
    opacity: 1;

    @keyframes fader {
      0% {
        opacity 1;
      }
      50% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }
  &.radial {
    background: radial-gradient(
      rgb(28, 28, 31, 0.2) 0%,
      rgb(28, 28, 31, 0.5) 5%,
      rgb(28, 28, 31, 0.7) 15%,
      rgb(28, 28, 31, 0.9) 40%,
      rgb(28, 28, 31, 0.99) 50%,
      rgb(28, 28, 31, 0.99) 100%
    );
  }
  &.linearScan {
    z-index: 99;
    background: linear-gradient(to right, 
      rgb(28, 28, 31, 0) 0%,
      rgb(28, 28, 31, 0) 48.1%,
      rgb(0, 255, 26, 0.4) 50%,
      rgb(28, 28, 31, 0) 51.9%,
      rgb(28, 28, 31, 0) 100%
    );
    &.thin {
      background: linear-gradient(to right, 
        rgb(28, 28, 31, 0) 0%,
        rgb(28, 28, 31, 0) 49.9%,
        rgb(227, 255, 230, 0.9) 50%,
        rgb(28, 28, 31, 0) 50.1%,
        rgb(28, 28, 31, 0) 100%
      );
    }

    background-size: 100vw;
    animation: bg 1.3s linear infinite;

    @keyframes bg {
      0% {
        background-position-x: 0;
      }
      100% {
        background-position-x: 100vw;
      }
    }
  }
`)

export const GuideText = styled.div(({theme}) => `
  color: ${theme.palette.common.black};
  font-family: Gill Sans, sans-serif;
  
  &.header {
    font-family: Courier;

    &.one {
      color: ${theme.palette.common.red};
    }
    &.two {
      color: ${theme.palette.secondary.main};
    }
    &.three {
      color: ${theme.palette.primary.dark};
    }
  }
  &.sub {
    font-size: 1.2rem;
    fontWeight: 400;
  }
  &.small {
    font-size: 1.4rem;
    fontWeight: 400;
  }
  &.subMedium {
    font-size: 1.6rem;
    fontWeight: 400;
  }
  &.medium {
    font-size: 1.8rem;
    fontWeight: 400;
  }
  &.large {
    font-size: 2.0rem;
    font-weight: 600;
  }
`)

export const LoadingWrapper = styled.div(({ theme }) => `
  display: flex;
  alignItems: center;

  animation-name: backgroundColorPalette;
	animation-duration: 5s;
	animation-iteration-count: infinite;
	animation-direction: alternate;
	animation-timing-function: linear; 
  @keyframes backgroundColorPalette {
    0% {
      color: #ee6055;
    }
    25% {
      color: #60d394;
    }
    50% {
      color: #aaf683;
    }
    75% {
      color: #ffd97d;
    }
    100% {
      color: #ff9b85;
    }
  }
`)
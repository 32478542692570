import React from "react"
import { useTheme } from "@mui/styles";
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { CardButton, ColumnContent, ComponentSectionRow, Detail, FlexWrapper, RowsContainer, ScrollWindow } from "../../StyledComponents";

const ParameterPerformanceStats = ({ mortgageDataModel, handleOpenSweepParameterModal }) => {
  const theme = useTheme()

  return (
    <ScrollWindow className="horizontalOnly" style={{maxWidth: 'calc(100vw - 23.3rem)'}}>
      <ComponentSectionRow className="header gray" style={{display: 'grid', gridTemplateColumns: `24rem 10rem 10rem 10rem 10rem 10rem 1fr 0.5rem`}}>
        <ColumnContent className="header" style={{gridColumn: '1', borderLeft: `0.1rem solid ${theme.palette.common.grayText}`, padding: '0.6rem 0'}}>
          label
        </ColumnContent>  
        <ColumnContent className="header" style={{gridColumn: '2', borderLeft: `0.1rem solid ${theme.palette.common.grayText}`, padding: '0.6rem 0'}}>
          active
        </ColumnContent>  
        <ColumnContent className="header" style={{gridColumn: '3', borderLeft: `0.1rem solid ${theme.palette.common.grayText}`, padding: '0.6rem 0'}}>
          discrepancies
        </ColumnContent>  
        <ColumnContent className="header" style={{gridColumn: '4', borderLeft: `0.1rem solid ${theme.palette.common.grayText}`, padding: '0.6rem 0'}}>
          renegotiations
        </ColumnContent>  
        <ColumnContent className="header" style={{gridColumn: '5', borderLeft: `0.1rem solid ${theme.palette.common.grayText}`, padding: '0.6rem 0'}}>
          refinances
        </ColumnContent>  
        <ColumnContent className="header" style={{gridColumn: '6', borderLeft: `0.1rem solid ${theme.palette.common.grayText}`, borderRight: `0.1rem solid ${theme.palette.common.grayText}`, padding: '0.6rem 0'}}>
          dismissals
        </ColumnContent>  
      </ComponentSectionRow>
      <RowsContainer style={{height: 'calc(100vh - 20.2rem)'}}>
        {mortgageDataModel.map((category, index) => (index !== 0 &&
          <div key={index}>
            <FlexWrapper className="fitContentHeight" style={{padding: '0.4rem 0 0.4rem 2rem', background: `${theme.palette.common.grayBorder}`, borderTop: index !== 1 ? `0.1rem solid ${theme.palette.common.grayText}` : 'none', borderBottom: `0.1rem solid ${theme.palette.common.grayText}`}}>
              <Detail className="timelineHeader">{category.subCat}</Detail>
            </FlexWrapper>
            <FlexWrapper className="column">
              {category.fields.map((sweepParameter, index) => (
                <ComponentSectionRow key={index} style={{padding: index === 0 ? '0.6rem 0 0.2rem 0' : index === (category.fields.length - 1) ? '0.2rem 0 0.6rem 0' : '0.2rem 0 0.2rem 0', background: index%2 === 1 ? `${theme.palette.common.grayBorderLight}` : '', display: 'grid', gridTemplateColumns: `24rem 10rem 10rem 10rem 10rem 10rem 1fr`}}>
                  <CardButton className={sweepParameter.assignedTier === 'two' ? 'two' : sweepParameter.assignedTier === 'one' ? 'one' : 'three'} style={{fontSize: '0.8rem', width: 'fit-content', gridColumn: '1', padding: '0.1rem 0.6rem', margin: '0 0 0 3rem'}} onClick={() => handleOpenSweepParameterModal(sweepParameter)}>
                    {sweepParameter.label.includes('Primary') ?
                      <>
                        {sweepParameter.label.replace('Primary', '').toUpperCase()}
                      </>
                    : sweepParameter.label.includes('Secondary') ?
                      <>
                        {sweepParameter.label.replace('Secondary', '').toUpperCase()}
                      </>
                    :
                      sweepParameter.label.toUpperCase()
                    }
                  </CardButton>
                  <Detail className="center" style={{gridColumn: '2'}}>
                    {sweepParameter.active ?
                        <RadioButtonCheckedIcon style={{color: `${theme.palette.primary.main}`}} />
                      :
                        <RadioButtonUncheckedIcon style={{color: `${theme.palette.common.grayBorderMedium}`}} />
                    }
                  </Detail>
                  {sweepParameter.discrepancies > 0 ?
                    <Detail className="center" style={{gridColumn: '3', fontWeight: sweepParameter.discrepancies > 0 ? '600' : '400'}}>{sweepParameter.discrepancies} ({(((sweepParameter.discrepancies/sweepParameter.totalQueries) * 100).toFixed(0) + '%')})</Detail>
                  :
                    <Detail className="center" style={{gridColumn: '3', fontWeight: sweepParameter.discrepancies > 0 ? '600' : '400'}}>-</Detail>
                  }
                  {sweepParameter.renegotiations > 0 ?
                    <Detail className="center" style={{gridColumn: '4', fontWeight: sweepParameter.renegotiations > 0 ? '600' : '400'}}>{sweepParameter.renegotiations} ({(((sweepParameter.renegotiations/sweepParameter.assignments) * 100).toFixed(0) + '%')})</Detail>
                  :
                    <Detail className="center" style={{gridColumn: '4', fontWeight: sweepParameter.renegotiations > 0 ? '600' : '400'}}>-</Detail>
                  }
                  {sweepParameter.refinances > 0 ?
                    <Detail className="center" style={{gridColumn: '5', fontWeight: sweepParameter.refinances > 0 ? '600' : '400'}}>{sweepParameter.refinances} ({(((sweepParameter.refinances/sweepParameter.assignments) * 100).toFixed(0) + '%')})</Detail>
                  :
                    <Detail className="center" style={{gridColumn: '5', fontWeight: sweepParameter.refinances > 0 ? '600' : '400'}}>-</Detail>
                  }
                  {sweepParameter.dismissals > 0 ?
                    <Detail className="center" style={{gridColumn: '6', fontWeight: sweepParameter.dismissals > 0 ? '600' : '400'}}>{sweepParameter.dismissals} ({(((sweepParameter.dismissals/sweepParameter.assignments) * 100).toFixed(0) + '%')})</Detail>
                  :
                    <Detail className="center" style={{gridColumn: '6', fontWeight: sweepParameter.dismissals > 0 ? '600' : '400'}}>-</Detail>
                  }
                </ComponentSectionRow>
              ))}
            </FlexWrapper>
          </div>
        ))}
      </RowsContainer>
    </ScrollWindow>
  )
}

export default ParameterPerformanceStats;
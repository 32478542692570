import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { API_URL } from '../../config';

//* Above each cache is the component location where the query or mutation is called from
//* Note that these are in addition to the pre-fetches that are done within the App.js component upon initial load

//TODO: add headers
//TODO: switch a lot of these pessimistic loads to optimistic
export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({ baseUrl: API_URL }),
  endpoints: builder => ({
    establishRequestCredentials: builder.mutation({
      query: body => ({
        url: `/auth/establishCredentials`,
        method: 'PATCH',
        body: body,
      })
    }),
    establishRequestLocation: builder.mutation({
      query: body => ({
        url: `/auth/establishLocation`,
        method: 'PATCH',
        body: body,
      })
    }),
    logReturningUser: builder.mutation({
      query: body => ({
        url: `/auth/log/returning`,
        method: 'PATCH',
        body: body,
      })
    }),
    launchTeam: builder.mutation({
      query: body => ({
        url: `/launch/team`,
        method: 'POST',
        body: body,
      })
    }),
    validateAccessToken: builder.mutation({
      query: body => ({
        url: `/auth/access/validate`,
        method: 'PATCH',
        body: body,
      })
    }),
    loginExistingSuper: builder.mutation({
      query: body => ({
        url: `/auth/login/existing/super`,
        method: 'PATCH',
        body: body
      }),
    }),
    loginReturningUser: builder.mutation({
      query: body => ({
        url: `/auth/login/existing/user`,
        method: 'PATCH',
        body: body
      }),
    }),
    createGuestAccount: builder.mutation({
      query: body => ({
        url: `/auth/create/guest`,
        method: 'POST',
        body: body
      }),
    }),
    initialLoad: builder.mutation({
      query: body => ({
        url: `/load/initial/${body.userId}`,
        method: 'GET',
        headers: { 'authorization': `${body.authToken}` }
      })
    }),
    uploadCSVMortgages: builder.mutation({
      query: body => ({
        url: `/uploads/mortgages/csv`,
        method: 'POST',
        body: body
      }),
    }),
    deleteUploadReport: builder.mutation({
      query: body => ({
        url: `/uploads/delete`,
        method: 'DELETE',
        body: body,
      })
    }),
    dismissLead: builder.mutation({
      query: body => ({
        url: `/activeLeads/dismiss`,
        method: 'PATCH',
        body: body
      }),
    }),
    setLeadStatusInvestigating: builder.mutation({
      query: body => ({
        url: `/activeLeads/set/investigating`,
        method: 'PATCH',
        body: body
      }),
    }),
    setLeadStatusClosing: builder.mutation({
      query: body => ({
        url: `/activeLeads/set/closing`,
        method: 'PATCH',
        body: body
      }),
    }),
    setLeadStatusFinalized: builder.mutation({
      query: body => ({
        url: `/activeLeads/set/finalized`,
        method: 'POST',
        body: body
      }),
    }),
    confirmPublicRecords: builder.mutation({
      query: body => ({
        url: `/activeLeads/confirmPublicRecords`,
        method: 'PATCH',
        body: body
      }),
    }),
    saveLeadTargetUpdates: builder.mutation({
      query: body => ({
        url: `/activeLeads/targets/save`,
        method: 'PATCH',
        body: body
      }),
    }),
    changeTargetOutcome: builder.mutation({
      query: body => ({
        url: `/activeLeads/changeOutcome`,
        method: 'PATCH',
        body: body
      }),
    }),
    addLeadTag: builder.mutation({
      query: body => ({
        url: `/activeLeads/addLeadTag`,
        method: 'PATCH',
        body: body
      }),
    }),
    addAssignees: builder.mutation({
      query: body => ({
        url: `/activeLeads/addAssignees`,
        method: 'PATCH',
        body: body
      }),
    }),
    resolveAllLeadDiscrepancies: builder.mutation({
      query: body => ({
        url: `/activeLeads/resolve/all`,
        method: 'PATCH',
        body: body
      }),
    }),
    reloadLeadGenerationQuarterlyBreakdown: builder.mutation({
      query: body => ({
        url: `/monthlyStats/team/quarterBreakdown/leadGeneration/reload`,
        method: 'POST',
        body: body
      })
    }),
    reloadRenegotiationQuarterlyBreakdown: builder.mutation({
      query: body => ({
        url: `/monthlyStats/team/quarterBreakdown/renegotiation/reload`,
        method: 'POST',
        body: body
      })
    }),
    reloadRefinanceQuarterlyBreakdown: builder.mutation({
      query: body => ({
        url: `/monthlyStats/team/quarterBreakdown/refinance/reload`,
        method: 'POST',
        body: body
      })
    }),
    addMortgageNote: builder.mutation({
      query: body => ({
        url: `/mortgages/notes/add`,
        method: 'POST',
        body: body
      }),
    }),
    updateMortgageDetails: builder.mutation({
      query: body => ({
        url: `/mortgages/update`,
        method: 'POST',
        body: body
      }),
    }),
    checkForMortgageDuplicates: builder.mutation({
      query: body => ({
        url: `/mortgages/duplicates`,
        method: 'POST',
        body: body
      }),
    }),
    deleteMortgage: builder.mutation({
      query: body => ({
        url: `/mortgages/delete`,
        method: 'DELETE',
        body: body,
      })
    }),
    addMortgageTag: builder.mutation({
      query: body => ({
        url: `/mortgages/tags/add`,
        method: 'PATCH',
        body: body,
      })
    }),
    updateNotificationStatus: builder.mutation({
      query: body => ({
        url: `/notifications/status`,
        method: 'PATCH',
        body: body
      })
    }),
    deleteNotification: builder.mutation({
      query: body => ({
        url: `/notifications/delete/${body.notificationId}/${body.userId}/${body.status}/${body.newNotifications}`,
        method: 'DELETE',
      })
    }),
    createNewTeamLoanType: builder.mutation({
      query: body => ({
        url: `/teams/create/loanType`,
        method: 'PATCH',
        body: body
      })
    }),
    updateTeamDefaultTargetParameters: builder.mutation({
      query: body => ({
        url: `/teams/settings/defaultTargets`,
        method: 'PATCH',
        body: body
      })
    }),
    updateTeamDiscrepancyParamterSelection: builder.mutation({
      query: body => ({
        url: `/teams/parameter/activeStatus`,
        method: 'PATCH',
        body: body
      })
    }),
    updateSweepParamterSettings: builder.mutation({
      query: body => ({
        url: `/teams/parameter/settings`,
        method: 'PATCH',
        body: body
      })
    }),
    removeReportNotifyUser: builder.mutation({
      query: body => ({
        url: `/users/removeNotification/report`,
        method: 'PATCH',
        body: body,
      })
    }),
    deleteUserLeadNotification: builder.mutation({
      query: body => ({
        url: `/users/removeNotification/lead`,
        method: 'PATCH',
        body: body,
      })
    }),
    deleteUserNewAssignmentNotification: builder.mutation({
      query: body => ({
        url: `/users/removeNotification/newAssignment`,
        method: 'PATCH',
        body: body,
      })
    }),
    deleteUser: builder.mutation({
      query: body => ({
        url: `/users/removeNotification/deleteUser`,
        method: 'DELETE',
        body: body,
      })
    }),
    editUserEmailSchedule: builder.mutation({
      query: body => ({
        url: `/users/emailSchedule/edit`,
        method: 'PATCH',
        body: body,
      })
    }),
    recordSweep: builder.mutation({
      query: body => ({
        url: `/queries/recordSweep`,
        method: 'POST',
        body: body,
      })
    }),
    deleteActionOrErrorLog: builder.mutation({
      query: body => ({
        url: `/teams/actionAndErrorLog/delete`,
        method: 'DELETE',
        body: body,
      })
    }),
    createCronTicker: builder.mutation({
      query: body => ({
        url: `/scheduledTasks/cronTicker/create`,
        method: 'POST',
        body: body,
      })
    }),
    sendSweepResultsEmails: builder.mutation({
      query: body => ({
        url: `/emails/sweepResults`,
        method: 'GET',
        body: body,
      })
    }),
  })
})

export const { usePrefetch, useUploadCSVMortgagesMutation, useSetLeadStatusInvestigatingMutation, useSetLeadStatusClosingMutation, useSetLeadStatusFinalizedMutation, useUpdateMortgageDetailsMutation, useGetTeamPieChartStatsQuery, useAddMortgageNoteMutation, useUpdateNotificationStatusMutation, useDeleteNotificationMutation, useUpdateTeamDefaultTargetParametersMutation, useUpdateTeamDiscrepancyParamterSelectionMutation, useSaveLeadTargetUpdatesMutation, useDismissLeadMutation, useAddAssigneesMutation, useChangeTargetOutcomeMutation, useReloadLeadGenerationQuarterlyBreakdownMutation, useReloadRenegotiationQuarterlyBreakdownMutation, useReloadRefinanceQuarterlyBreakdownMutation, useRemoveReportNotifyUserMutation, useAddLeadTagMutation, useRecordSweepMutation, useDeleteUserLeadNotificationMutation, useDeleteUserNewAssignmentNotificationMutation, useInitialLoadMutation, useLoginUserQuery, useDeleteUserMutation, useCheckForMortgageDuplicatesMutation, useDeleteUploadReportMutation, useDeleteMortgageMutation, useResolveAllLeadDiscrepanciesMutation, useDeleteActionOrErrorLogMutation, useCreateNewTeamLoanTypeMutation, useCreateCronTickerMutation, useAddMortgageTagMutation, useUpdateSweepParamterSettingsMutation, useEditUserEmailScheduleMutation, useCheckRequestIPMutation, useCreateGuestAccountMutation, useValidateAccessTokenMutation, useEstablishRequestCredentialsMutation, useLoginExistingSuperMutation, useLoginReturningUserMutation, useLaunchTeamMutation, useLogReturningUserMutation, useEstablishRequestLocationMutation, useSendSweepResultsEmailsMutation, useConfirmPublicRecordsMutation } = apiSlice
import React from "react";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import { useTheme } from "@mui/styles";
import FormControlLabel from '@mui/material/FormControlLabel';
import { FlexWrapper, HeaderText } from "../../../../../../../StyledComponents";
import { grayUnselectedRadioColorProps } from "../../../../../../../componentThemes";

const ChartToggle = ({ handleTimelineChange, chartTimeline, handleChartPerspectiveChange, chartPerspective }) => {
  const theme = useTheme();
  const perspectiveProps = (item) => ({
    checked: chartPerspective === item,
    onChange: handleChartPerspectiveChange,
    value: item,
    label: item,
    labelPlacement: "start",
    sx: {
      color: `${theme.palette.common.grayText}`,
      padding: "0",
    }
  });
  const timelineControlProps = (item) => ({
    checked: chartTimeline === item,
    onChange: handleTimelineChange,
    value: item,
    label: item,
    labelPlacement: "start",
    sx: {
      color: `${theme.palette.common.grayText}`,
      padding: "0",
    }
  });

  return (
    <FlexWrapper className="alignCenter justifyStart">
      <FlexWrapper className="alignCenter justifyStart" style={{maxWidth: '35rem'}}>
        <HeaderText className="center borderRight" style={{padding: '0 0.5rem 0 0'}}>
          Perspective
        </HeaderText>
        <FormControl>
          <RadioGroup row >
            <FormControlLabel
              control={<Radio {...grayUnselectedRadioColorProps()} />}
              {...perspectiveProps('Monthly Stats')} 
            />
          </RadioGroup>
        </FormControl>
      </FlexWrapper>
        {chartPerspective === 'Monthly Stats' ?
          <FlexWrapper className="alignCenter justifyCenter">
            <HeaderText className="center borderRight" style={{padding: '0 0.5rem 0 0'}}>
              Timeframe
            </HeaderText>
            <FormControl>
              <RadioGroup row >
                <FormControlLabel
                  control={<Radio {...grayUnselectedRadioColorProps()} />}
                  {...timelineControlProps('All')} 
                />
                <FormControlLabel
                  control={<Radio {...grayUnselectedRadioColorProps()} />}
                  {...timelineControlProps('1 Year')} 
                />
                <FormControlLabel
                  control={<Radio {...grayUnselectedRadioColorProps()} />}
                  {...timelineControlProps('2 Year')} 
                />
              </RadioGroup>
            </FormControl>
          </FlexWrapper>
        :
          <></>
        }
    </FlexWrapper>
  )
}

export default ChartToggle;
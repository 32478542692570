import React, { useState } from "react";
import NewLeads from "./NewLeads";
import Finalized from "./Finalized";
import CircleIcon from '@mui/icons-material/Circle';
import Notes from "../../../../../../components/Modals/LeadActions/components/Notes";
import { useTheme } from "@mui/styles";
import LeadDismissed from "./LeadDismissed";
import { useDispatch } from "react-redux";
import AssignmentRemoved from "./AssignmentRemoved";
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { setViewingMortgage } from "../../../../../../slices/viewingMortgageSlice";
import DeleteNotificationModel from "../../../../../../components/Modals/Notifications/DeleteNotificationModal";
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { CircularProgress, Dialog } from "@mui/material";
import { useUpdateNotificationStatusMutation } from "../../../../../../slices/api/apiSlice";
import { CardContainer, FlexWrapper } from "../../../../../../StyledComponents";

const NotificationCard = ({ notification, cardIndex, userId }) => {
  const theme = useTheme();
  const dispatch = useDispatch()

  const [updateNotificationStatus, {isFetching, isError}] = useUpdateNotificationStatusMutation()

  const [isHovering, setIsHovering] = useState(false)

  const handleStatusChange = (status, setNew) => {
    if (status === 'new') {
      handleUpdateStatus(true, 'read');
    } else if (status === 'read') {
      if (setNew) {
        handleUpdateStatus(true, 'new');
      } else {
        handleUpdateStatus(false, 'unread');
      }
    } else if (status === 'unread') {
      if (setNew) {
        handleUpdateStatus(true, 'new');
      } else {
        handleUpdateStatus(false, 'read');
      }
    }
  }
  const handleMouseEnter = () => {
    setIsHovering(true);
  }
  const handleMouseLeave = () => {
    setIsHovering(false);
  }

  const handleUpdateStatus = async (updateNewAmount, statusUpdate) => {
    updateNotificationStatus({
      notificationId: notification._id,
      status: statusUpdate,
      userId: userId,
      updateNewAmount: updateNewAmount
    })
  }

  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  }
  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  }

  const [openNotesModal, setOpenNotesModal] = useState(false)
  const handleOpenNotesModal = async (mortgage) => {
    dispatch( setViewingMortgage({
      mortgageId: mortgage._id,
      mortgageNotes: mortgage.mortgageNotes,
    }))
    setOpenNotesModal(true);
  }
  const handleCloseNotesModal = () => {
    setOpenNotesModal(false);
  }

  return (
    <>
      <FlexWrapper className="alignCenter" style={{height: 'fit-content'}}>
        <div onClick={() => handleStatusChange(notification.status)} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={{cursor: isHovering ? 'pointer' : 'default', margin: '0 1rem'}}>
          {notification.status === 'new' ?
            isHovering ?
              <RadioButtonCheckedIcon style={{color: `${theme.palette.primary.main}`, height: '2rem'}} />
            :
              <CircleIcon style={{color: `${theme.palette.primary.main}`, height: '2rem'}} />
          : notification.status === 'unread' ?
            isHovering ?
              <RadioButtonCheckedIcon style={{color: `${theme.palette.secondary.light}`, height: '2rem'}} />
            :
              <CircleIcon style={{color: `${theme.palette.secondary.light}`, height: '2rem'}} />
          : notification.status === 'read' ?
            isHovering ?
              <RadioButtonCheckedIcon style={{color: `${theme.palette.common.grayBorder}`, height: '2rem'}} />
            :
              <RadioButtonUncheckedIcon style={{color: `${theme.palette.common.grayBorder}`, height: '2rem'}} />
          :
            <></>
          }
        </div>
        <CardContainer>
          {isError ?
            <div style={{height: '10rem', display: 'flex', alignItems: 'center', justifyContent: 'center', gridColumn: '1/5'}}>
              There was an error changing the status of notification. Please reload the page and contact solutions@lancastersweep.com if the problem persists.
            </div>
          : isFetching ?
            <div style={{height: '10rem', display: 'flex', alignItems: 'center', justifyContent: 'center', gridColumn: '1/5'}}>
              <CircularProgress
                sx={{ color: `${theme.palette.primary.green}` }}
                size={18}
              />
            </div>
          : notification.category === "newPublicRecordsScan" ?
            <NewLeads notification={notification} handleStatusChange={handleStatusChange} handleOpenDeleteModal={handleOpenDeleteModal} cardIndex={cardIndex} />
          : notification.category === 'leadDismissed' ?
            <LeadDismissed notification={notification} handleStatusChange={handleStatusChange} handleOpenNotesModal={handleOpenNotesModal} handleOpenDeleteModal={handleOpenDeleteModal} cardIndex={cardIndex} />
          : notification.category === 'finalized' ?
            <Finalized notification={notification} handleStatusChange={handleStatusChange} handleOpenNotesModal={handleOpenNotesModal} handleOpenDeleteModal={handleOpenDeleteModal} cardIndex={cardIndex} />
          : notification.category === 'assignmentRemoved' ?
            <AssignmentRemoved notification={notification} handleStatusChange={handleStatusChange} handleOpenNotesModal={handleOpenNotesModal} handleOpenDeleteModal={handleOpenDeleteModal} cardIndex={cardIndex} />
          :
            <></>
          }
        </CardContainer>
      </FlexWrapper>
      <Dialog open={openNotesModal} onClose={handleCloseNotesModal}
        PaperProps={{ sx: {width: "80%", height: "95%", maxWidth: "65rem", maxHeight: "100rem", minWidth: "50rem", minHeight: "35rem"}}}
      >
        <Notes handleCloseNotesModal={handleCloseNotesModal} userId={userId} />
      </Dialog>
      <Dialog 
        open={openDeleteModal} onClose={handleCloseDeleteModal} 
        PaperProps={{ sx: {width: "40rem", height: "35rem"}}}
      >
        <DeleteNotificationModel notification={notification} userId={userId} handleCloseModal={handleCloseDeleteModal} />
      </Dialog>
    </>
  )
}

export default NotificationCard;
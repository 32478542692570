import React from "react";
import styled from "@emotion/styled";
import Tooltip from '@mui/material/Tooltip';
import SellIcon from '@mui/icons-material/Sell';
import GroupIcon from '@mui/icons-material/Group';
import AdjustIcon from '@mui/icons-material/Adjust';
import { useTheme } from "@mui/styles";
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import EditNoteIcon from '@mui/icons-material/EditNote';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import SummarizeIcon from '@mui/icons-material/Summarize';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { useDispatch, useSelector } from "react-redux";
import { selectLeadActionsNavSelection, setLeadActionNavMenuSelection } from "../../../slices/navMenuSelectionsSlice";
import { ActionMenuItem, ActionMenuTitle, NavIndex, NavText, NavigationContainer, SideBarNavHeader } from "../../../StyledComponents";

const LeadActionsNav = ({ mortgageData, reportsNumber, leadStatus }) => {
  const theme = useTheme()
  const dispatch = useDispatch()

  const navSelection = useSelector(selectLeadActionsNavSelection)
  
  const changeSelection = (content) => {
    dispatch( setLeadActionNavMenuSelection(content))
  }

  return (
    <NavigationContainer className="modal" style={{gridRow:'2/5'}}>
      <SideBarNavHeader className="modal">Actions</SideBarNavHeader>
      {navSelection ?
        <>
          {navSelection === 'reports' ?
            <ListItemNav selected top>
              <NavIndex>
                <SummarizeIcon fontSize="large" />
              </NavIndex>
              <NavText className="main">View Reports ({reportsNumber})</NavText>
            </ListItemNav>
          : reportsNumber > 0 ?
            <ListItemNav onClick={() => changeSelection('reports')} top>
              <NavIndex>
                <SummarizeIcon fontSize="large" />
              </NavIndex>
              <NavText className="main">View Reports ({reportsNumber})</NavText>
            </ListItemNav>
          :
            <ListItemNav top disabled>
              <NavIndex>
                <SummarizeIcon fontSize="large" />
              </NavIndex>
              <NavText className="main">View Reports ({reportsNumber})</NavText>
            </ListItemNav>
          }
          {navSelection === 'notes' ?
            <ListItemNav selected>
              <NavIndex>
                <EditNoteIcon fontSize="large" />
              </NavIndex>
              <NavText className="main">View/Add Notes ({mortgageData.mortgageNotes.length})</NavText>
            </ListItemNav>
          :
            <ListItemNav onClick={() => changeSelection('notes')}>
              <NavIndex>
                <EditNoteIcon fontSize="large" />
              </NavIndex>
              <NavText className="main">View/Add Notes ({mortgageData.mortgageNotes.length})</NavText>
            </ListItemNav>
          }
          {navSelection === 'compareRecords' ?
            <ListItemNav selected>
              <NavIndex>
                <FileCopyIcon fontSize="large" />
              </NavIndex>
              <NavText className="main">Compare and Update Records</NavText>
            </ListItemNav>
          :
            <ListItemNav onClick={() => changeSelection('compareRecords')}>
              <NavIndex>
                <FileCopyIcon fontSize="large" />
              </NavIndex>
              <NavText className="main">Compare and Update Records</NavText>
            </ListItemNav>
          }
          {navSelection === 'propertyDetails' ?
            <ListItemNav selected>
              <NavIndex>
                <HomeWorkIcon fontSize="large" />
              </NavIndex>
              <NavText className="main">View Property Details</NavText>
            </ListItemNav>
          :
            <ListItemNav onClick={() => changeSelection('propertyDetails')}>
              <NavIndex>
                <HomeWorkIcon fontSize="large" />
              </NavIndex>
              <NavText className="main">View Property Details</NavText>
            </ListItemNav>
          }
          {navSelection === 'ownerDetails' ?
            <ListItemNav selected>
              <NavIndex>
                <GroupIcon fontSize="large" />
              </NavIndex>
              <NavText className="main">View Owner Details</NavText>
            </ListItemNav>
          :
            <ListItemNav onClick={() => changeSelection('ownerDetails')}>
              <NavIndex>
                <GroupIcon fontSize="large" />
              </NavIndex>
              <NavText className="main">View Owner Details</NavText>
            </ListItemNav>
          }
          {mortgageData.originalLoanAmount && mortgageData.activeLead ?
            navSelection === 'editTargets' ?
              <ListItemNav selected>
                <NavIndex>
                  <AdjustIcon fontSize="large" />
                </NavIndex>
                <NavText className="main">Change Refinance Targets</NavText>
              </ListItemNav>
            :
              <ListItemNav onClick={() => changeSelection('editTargets')}>
                <NavIndex>
                  <AdjustIcon fontSize="large" />
                </NavIndex>
                <NavText className="main">Change Refinance Targets</NavText>
              </ListItemNav>
          : mortgageData.activeLead ?
            <Tooltip
              componentsProps={{tooltip: {sx: {
                color: `${theme.palette.common.white}`,
                backgroundColor: `${theme.palette.common.black}`,
                fontSize: "2rem",
                margin: ' 0'
              }}}}
              PopperProps={{modifiers: [{
                name: "offset",
                options: {
                  offset: [0, -8],
                },
              }]}}
              title={<div style={{display: 'flex', flexDirection: 'column', justifyContent: "center", width: '14rem'}}>
                <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                  <p style={{fontSize: "1rem", fontWeight: "600", padding: "0 0.5rem", margin: '0'}}>no financial data provided</p>
                </div>
              </div>} 
              placement="right"
              arrow
            >
              <ListItemNav disabled>
                <NavIndex>
                  <AdjustIcon fontSize="large" />
                </NavIndex>
                <NavText className="main">Change Refinance Targets</NavText>
              </ListItemNav>
            </Tooltip>
          :
            <ListItemNav disabled>
              <NavIndex>
                <AdjustIcon fontSize="large" />
              </NavIndex>
              <NavText className="main">Change Refinance Targets</NavText>
            </ListItemNav>
          }
          {navSelection === 'editTags' ?
            <ListItemNav selected>
              <NavIndex>
                <SellIcon fontSize="large" />
              </NavIndex>
              <NavText className="main">Edit Tags</NavText>
            </ListItemNav>
          :
            <ListItemNav onClick={() => changeSelection('editTags')}>
              <NavIndex>
                <SellIcon fontSize="large" />
              </NavIndex>
              <NavText className="main">Edit Tags</NavText>
            </ListItemNav>
          }
          {navSelection === 'addAssignees' ?
            <ListItemNav selected bottom>
              <NavIndex>
                <PersonAddIcon fontSize="large" />
              </NavIndex>
              <NavText className="main">Add Assignees</NavText>
            </ListItemNav>
          :
            <ListItemNav onClick={() => changeSelection('addAssignees')} bottom>
              <NavIndex>
                <PersonAddIcon fontSize="large" />
              </NavIndex>
              <NavText className="main">Add Assignees</NavText>
            </ListItemNav>
          }
          {leadStatus === 'awaitingAction' && navSelection === 'investigateLead' ?
            <ActionMenuItem className="advance focus" style={{margin: '4rem auto 0 auto', width: '16rem'}}>
              <ActionMenuTitle className="white">Open Investigation</ActionMenuTitle>
            </ActionMenuItem>
          : leadStatus === 'awaitingAction' ?
            <ActionMenuItem className="advance" style={{margin: '4rem auto 0 auto', width: '16rem'}} onClick={() => changeSelection('investigateLead')}>
              <ActionMenuTitle className="white">Open Investigation</ActionMenuTitle>
            </ActionMenuItem>
          :
            <></>
          }
          {leadStatus === 'investigating' && navSelection === 'beginClosingLead' ?
            <ActionMenuItem className="advance focus" style={{margin: '4rem auto 0 auto', width: '16rem'}}>
              <ActionMenuTitle className="white">Begin Closing</ActionMenuTitle>
            </ActionMenuItem>
          : leadStatus === 'investigating' ?
            <ActionMenuItem className="advance" style={{margin: '4rem auto 0 auto', width: '16rem'}} onClick={() => changeSelection('beginClosingLead')}>
              <ActionMenuTitle className="white">Begin Closing</ActionMenuTitle>
            </ActionMenuItem>
          :
            <></>
          }
          {leadStatus === 'closing' && navSelection === 'finalizeLead' ?
            <ActionMenuItem className="advance focus" style={{margin: '4rem auto 0 auto', width: '16rem'}}>
              <ActionMenuTitle className="white">Finalize</ActionMenuTitle>
            </ActionMenuItem>
          : leadStatus === 'closing' ?
            <ActionMenuItem className="advance" style={{margin: '4rem auto 0 auto', width: '16rem'}} onClick={() => changeSelection('finalizeLead')}>
              <ActionMenuTitle className="white">Finalize</ActionMenuTitle>
            </ActionMenuItem>
          :
            <></>
          }
          {leadStatus === 'awaitingUpdate' && navSelection === 'confirmUpdate' ?
            <ActionMenuItem className="advance focus" style={{margin: '4rem auto 0 auto', width: '16rem'}}>
              <ActionMenuTitle className="white">Confirm Updates</ActionMenuTitle>
            </ActionMenuItem>
          : leadStatus === 'awaitingUpdate' ?
            <ActionMenuItem className="advance" style={{margin: '4rem auto 0 auto', width: '16rem'}} onClick={() => changeSelection('confirmUpdate')}>
              <ActionMenuTitle className="white">Confirm Updates</ActionMenuTitle>
            </ActionMenuItem>
          :
            <></>
          }
          {navSelection === 'dismissLead' ?
            <ActionMenuItem className="dismiss focus" style={{margin: '1rem auto 0 auto', width: '16rem'}}>
              <ActionMenuTitle className="white">Dismiss</ActionMenuTitle>
            </ActionMenuItem>
          :
            <ActionMenuItem className="dismiss" style={{margin: '1rem auto 0 auto', width: '16rem'}} onClick={() => changeSelection('dismissLead')}>
              <ActionMenuTitle className="white">Dismiss</ActionMenuTitle>
            </ActionMenuItem>
          }
        </>
      :
        <>
          <ListItemNav disabled>
            <NavIndex>
              <SummarizeIcon fontSize="medium" />
            </NavIndex>
            <NavText className="disabled">View Reports</NavText>
          </ListItemNav>
          <ListItemNav disabled>
            <NavIndex>
              <EditNoteIcon fontSize="medium" />
            </NavIndex>
            <NavText className="disabled">View/Add Notes</NavText>
          </ListItemNav>
          <ListItemNav disabled>
            <NavIndex>
              <FileCopyIcon fontSize="medium" />
            </NavIndex>
            <NavText className="disabled">Compare and Update Records</NavText>
          </ListItemNav>
          <ListItemNav disabled>
            <NavIndex>
              <HomeWorkIcon fontSize="medium" />
            </NavIndex>
            <NavText className="disabled">View Property Details</NavText>
          </ListItemNav>
          <ListItemNav disabled>
            <NavIndex>
              <GroupIcon fontSize="medium" />
            </NavIndex>
            <NavText className="disabled">View Owner Details</NavText>
          </ListItemNav>
          <ListItemNav disabled>
            <NavIndex>
              <AdjustIcon fontSize="medium" />
            </NavIndex>
            <NavText className="disabled">Change Refinance Targets</NavText>
          </ListItemNav>
          <ListItemNav disabled>
            <NavIndex>
              <SellIcon fontSize="medium" />
            </NavIndex>
            <NavText className="disabled">Edit Tags</NavText>
          </ListItemNav>
          <ListItemNav disabled>
            <NavIndex>
              <PersonAddIcon fontSize="medium" />
            </NavIndex>
            <NavText className="disabled">Add Assignees</NavText>
          </ListItemNav>
        </>
      }
    </NavigationContainer>
  )
}

const ListItemNav = styled.a`
  display: flex;
  align-items: center;
  font-weight: 400;
  border-radius: 0;
  padding: 0.5rem 0 0.5rem 0.3rem;
  margin: 0;
  background-color: ${(props) => props.selected && !props.disabled ? "#4B14752B" : props.disabled ? "none" : "none"};
  cursor: ${(props) => props.selected || props.noCursor ? "default" : props.disabled ? 'not-allowed' : "pointer"};
  color: ${(props) => props.disabled ? '#939599' : '#00B812'};
  border-top: ${(props) => props.top ? "0.2rem solid #939599" : "0.1rem solid #939599"};
  border-bottom: ${(props) => props.bottom ? "0.2rem solid #939599" : "0.1rem solid #939599"};
  &:hover {
    background-color: ${(props) => props.selected && !props.disabled ? "#4B147533" : props.disabled ? "#E0E0E0" : "#F2F2F2"};
  }
`

export default LeadActionsNav;
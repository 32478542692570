import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: 'guide',
  status: 'idle',
  error: null
}

export const navSelectionSlice = createSlice({
  name: 'navSelection',
  initialState,
  reducers: {
    //* SideBar/Navigation
    setNavSelection(state, action) { state.data = action.payload },
  },
})

export const { setNavSelection } = navSelectionSlice.actions

export default navSelectionSlice.reducer

//* SideBar/Navigation
export const selectNavSelection = state => state.navSelection.data

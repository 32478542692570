import { createSlice } from '@reduxjs/toolkit'
import { apiSlice } from './api/apiSlice'

const initialState = {
  data: {
    _id: null,
    apiMapping: null,
    label: null,
    assignedTier: null,
    active: null,
    renegotiations: null,
    refinances: null,
    dismissals: null,
    empty: null,
    backupOnly: null,
    noBackup: null,
    populated: null,
    totalQueries: null,
    discrepancies: null,
  },
  status: 'idle',
  error: null
}

export const viewingSweepParameterSlice = createSlice({
  name: 'viewingSweepParameter',
  initialState,
  reducers: {
    setViewingSweepParameter(state, action) {
      state.data = action.payload
    },
  },
})

export const { setViewingSweepParameter } = viewingSweepParameterSlice.actions

export default viewingSweepParameterSlice.reducer

export const selectViewingSweepParameter = state => state.viewingSweepParameter.data

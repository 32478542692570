import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: 'leadOverview',
  status: 'idle',
  error: null
}

export const guideNavSelectionSlice = createSlice({
  name: 'guideNavSelection',
  initialState,
  reducers: {
    setGuideNavSelection(state, action) { state.data = action.payload },
  },
})

export const { setGuideNavSelection } = guideNavSelectionSlice.actions

export default guideNavSelectionSlice.reducer

export const selectGuideNavSelection = state => state.guideNavSelection.data

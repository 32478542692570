import React, { useState } from "react";
import styled from "@emotion/styled";
import NotificationsIcon from '@mui/icons-material/Notifications';
import CompanyLogo from "../../../../Full.logo.teal.png";
import InsightsIcon from '@mui/icons-material/Insights';
import FlagCircleIcon from '@mui/icons-material/FlagCircle';
import { useDispatch, useSelector } from "react-redux";
import ListIcon from '@mui/icons-material/List';
import { selectGuideNavSelection, setGuideNavSelection } from "../../../../slices/guideNavSelectionSlice";
import { CardListHeader, FlexWrapper, HeaderText, NavIndex, PageContent, TreeContainer, NavText, CardContainer } from "../../../../StyledComponents";
import LeadOverviewGuide from "./components/LeadOverviewGuide";
import ActiveLeadGuide from "./components/ActiveLeadGuide";
import PerformanceGuide from "./components/PerformanceGuide";

const Guide = () => {
  const dispatch = useDispatch()

  const navSelection = useSelector(selectGuideNavSelection)

  const [lastLeadGuide, setLastLeadGuide] = useState('overview');
  const changeSelection = (remember, content) => {
    if (remember) {
      if (content === 'leadOverview') {
        setLastLeadGuide('overview')
      } else if (content === 'activeLeads') {
        setLastLeadGuide('leads')
      }
    }
    dispatch( setGuideNavSelection(content) )
  }

  const handleShowLeadGuide = () => {
    if (lastLeadGuide === 'overview') {
      changeSelection(true, 'leadOverview')
    } else {
      changeSelection(true, 'activeLeads')
    }
  }

  return (
    <>
      <PageContent className="grid guide">
        <FlexWrapper className="column" style={{margin: '4.0rem 0 0 0', gridRow: '2', gridColumn: '1'}}>
          <CardListHeader className="reports">
            <HeaderText className="reports">Subjects</HeaderText>
          </CardListHeader>
          <FlexWrapper>
            <TreeContainer style={{margin: '1rem 0 0 1rem'}}>
              <FlexWrapper className="column" style={{padding: '1rem 1.5rem'}}>
                {navSelection === 'leadOverview' || navSelection === 'activeLeads' ?
                  <ListItemNav selected>
                    <NavIndex className="guide">
                      <FlagCircleIcon fontSize="medium" />
                    </NavIndex>
                    <NavText className="main">Lead Management</NavText>
                  </ListItemNav>
                :
                  <ListItemNav onClick={() => handleShowLeadGuide()}>
                    <NavIndex className="guide">
                      <FlagCircleIcon fontSize="medium" />
                    </NavIndex>
                    <NavText className="main">Lead Management</NavText>
                  </ListItemNav>
                }
                {navSelection === 'leadOverview' ?
                  <SubListItemNav subCat selected>
                    <NavIndex className="selected">
                      i.
                    </NavIndex>
                    <NavText className="selected">Overview</NavText>
                  </SubListItemNav>
                :
                  <SubListItemNav subCat onClick={() => changeSelection(true, 'leadOverview')}>
                    <NavIndex className="notSelected">
                      i.
                    </NavIndex>
                    <NavText className="notSelected">Overview</NavText>
                  </SubListItemNav>
                }
                {navSelection === 'activeLeads' ?
                  <SubListItemNav subCat selected>
                    <NavIndex className="selected">
                      ii.
                    </NavIndex>
                    <NavText className="selected">Active Leads</NavText>
                  </SubListItemNav>
                :
                  <SubListItemNav subCat onClick={() => changeSelection(true, 'activeLeads')}>
                    <NavIndex className="notSelected">
                      ii.
                    </NavIndex>
                    <NavText className="notSelected">Active Leads</NavText>
                  </SubListItemNav>
                }
                {navSelection === 'performanceMetrics' ?
                  <ListItemNav selected>
                    <NavIndex className="guide">
                      <InsightsIcon fontSize="medium" />
                    </NavIndex>
                    <NavText className="main">Performance Metrics</NavText>
                  </ListItemNav>
                :
                  <ListItemNav onClick={() => changeSelection(false, 'performanceMetrics')}>
                    <NavIndex className="guide">
                      <InsightsIcon fontSize="medium" />
                    </NavIndex>
                    <NavText className="main">Performance Metrics</NavText>
                  </ListItemNav>
                }
                {navSelection === 'repository' ?
                  <ListItemNav selected>
                    <NavIndex className="guide">
                      <ListIcon fontSize="medium" />
                    </NavIndex>
                    <NavText className="main">Mortgage Repository</NavText>
                  </ListItemNav>
                :
                  <ListItemNav onClick={() => changeSelection(false, 'repository')}>
                    <NavIndex className="guide">
                      <ListIcon fontSize="medium" />
                    </NavIndex>
                    <NavText className="main">Mortgage Repository</NavText>
                  </ListItemNav>
                }
                {navSelection === 'notifications' ?
                  <ListItemNav selected>
                    <NavIndex className="guide">
                      <NotificationsIcon fontSize="medium" />
                    </NavIndex>
                    <NavText className="main">Notification System</NavText>
                  </ListItemNav>
                :
                  <ListItemNav onClick={() => changeSelection(false, 'notifications')}>
                    <NavIndex className="guide">
                      <NotificationsIcon fontSize="medium" />
                    </NavIndex>
                    <NavText className="main">Notification System</NavText>
                  </ListItemNav>
                }
              </FlexWrapper>
            </TreeContainer>
          </FlexWrapper>
        </FlexWrapper>
        <CardContainer className="guide">
          <FlexWrapper className="column alignCenter" style={{backgroundColor: '#F4F4F4', maxHeight: 'fit-content', padding: '0.5rem 0'}}>
            <img alt="lancaster sweep guide" src={CompanyLogo} style={{width: '30rem', opacity: '0.6'}} />
          </FlexWrapper>
          {navSelection === 'leadOverview' ?
            <LeadOverviewGuide />
          : navSelection === 'activeLeads' ?
            <ActiveLeadGuide />
          :
            <PerformanceGuide />
          }
        </CardContainer>
      </PageContent>
    </>
  )
}

const ListItemNav = styled.a`
  display: flex;
  align-items: center;
  font-weight: 400;
  border-radius: 0;
  border-top: ${(props) => props.selected ? "0.1rem solid #B486BF" : "0.1rem solid #c8cbcf"};
  padding: 0.5rem 0 0.5rem 0.6rem;
  margin: 0;
  background-color: ${(props) => props.selected && !props.subCat ? "#F2E4F7" : props.subCat || props.disabled ? "none" : "none"};
  cursor: ${(props) => props.selected || props.noCursor || props.disabled ? "default" : "pointer"};
  color: ${(props) => props.disabled ? '#939599' : '#00B812'};
  &:hover {
    background-color: ${(props) => props.selected && !props.subCat ? "#EBC6F7" : props.subCat && !props.selected ? "#4B14752B" : props.subCat && (props.selected || props.disabled) ? "#4B14752B" : "#F2E4F7"};
  }
`
const SubListItemNav = styled.a`
  display: flex;
  align-items: center;
  font-weight: 400;
  border-radius: 0;
  padding: 0.5rem 0 0.5rem 0.2rem;
  margin: 0;
  background-color: ${(props) => props.selected && !props.subCat ? "#4B14752B" : props.subCat ? "none" : "none"};
  cursor: ${(props) => props.selected || props.noCursor || props.disabled ? "default" : "pointer"};
  color: ${(props) => props.subCat && !props.selected ? "#939599" : props.subCat && props.selected ? "#00B812" : "none"};
  &:hover {
    background-color: ${(props) => props.selected && !props.subCat ? "#4B147533" : props.subCat && !props.selected ? "none" : props.subCat && props.selected ? "none" : "#F2F2F2"};
    color: ${(props) => props.subCat && !props.selected && !props.disabled ? "#414042" : props.disabled ? '#939599' : "#00B812"};
  }
`

export default Guide;
exports.calculateTargetChanges = async function (reqBody) {
  let monthlyInterestRate = (((reqBody.interestRate)/12)/100);
  let monthlyPayments = (Math.round((((reqBody.loanAmount)*(monthlyInterestRate*((1+monthlyInterestRate)**((reqBody.loanTerm)*12))))/(((1+monthlyInterestRate)**((reqBody.loanTerm)*12))-1))*10000)/10000);
  let totalDue = ((monthlyPayments * ((reqBody.loanTerm)) * 12));
  let interestDue = (Math.round(((totalDue - (reqBody.loanAmount)))*10000)/10000);

  let newInterestDue = interestDue;
  let newInterestDueDifference = newInterestDue - reqBody.targetInterestDue;
  let newInterestDuePercent = ((1 - (newInterestDue/reqBody.targetInterestDue))*-100);

  let newMonthlyPaymentsDifference = monthlyPayments - reqBody.targetMonthlyPayments;
  let newMonthlyPaymentsPercent = ((1 - (monthlyPayments/reqBody.targetMonthlyPayments))*-100);
  
  let newProfitNumber = newInterestDue - reqBody.interestRemaining
  let newProfitNumberDifference = newProfitNumber - reqBody.targetProfitNumber
  let newProfitNumberPercent = 0
  if (reqBody.targetProfitNumber < 0) {
    newProfitNumberPercent = ((1 - (newProfitNumber/reqBody.targetProfitNumber))*100)
  } else {
    newProfitNumberPercent = ((1 - (newProfitNumber/reqBody.targetProfitNumber))*-100)
  }
  
  let newProfitPercent = ((1 - (newInterestDue/reqBody.interestRemaining))*-100)
  let newProfitPercentDifference = newProfitPercent - reqBody.targetProfitPercent
  let newProfitPercentPercent = 0
  if (reqBody.targetProfitPercent < 0) {
    newProfitPercentPercent = ((1 - (newProfitPercent/reqBody.targetProfitPercent))*100)
  } else {
    newProfitPercentPercent = ((1 - (newProfitPercent/reqBody.targetProfitPercent))*-100)
  }

  if (Math.round(newInterestDueDifference) === -0) {
    newInterestDueDifference = 0
  }
  if (Math.round(newMonthlyPaymentsDifference) === -0) {
    newMonthlyPaymentsDifference = 0
  }
  if (Math.round(newProfitNumberDifference) === -0) {
    newProfitNumberDifference = 0
  }
  if (Math.round(newProfitPercentDifference) === -0) {
    newProfitPercentDifference = 0
  }
  if (Math.round(newInterestDuePercent) === -0) {
    newInterestDuePercent = 0
  }
  if (Math.round(newMonthlyPaymentsPercent) === -0) {
    newMonthlyPaymentsPercent = 0
  }
  if (Math.round(newProfitNumberPercent) === -0) {
    newProfitNumberPercent = 0
  }
  if (Math.round(newProfitPercentPercent) === -0) {
    newProfitPercentPercent = 0
  }
  let newInterestDueDifferenceObj = {value: (Math.round(newInterestDueDifference*10000)/10000), negative: false}
  if (newInterestDueDifference < 0) {
    newInterestDuePercent = (-1*newInterestDuePercent)
    newInterestDueDifferenceObj.negative = true
    newInterestDueDifferenceObj.value = (newInterestDueDifferenceObj.value*-1)
  }
  let newMonthlyPaymentsDifferenceObj = {value: (Math.round(newMonthlyPaymentsDifference*10000)/10000), negative: false}
  if (newMonthlyPaymentsDifference < 0) {
    newMonthlyPaymentsPercent = (-1*newMonthlyPaymentsPercent)
    newMonthlyPaymentsDifferenceObj.negative = true
    newMonthlyPaymentsDifferenceObj.value = (newMonthlyPaymentsDifferenceObj.value*-1)
  }
  let newProfitNumberDifferenceObj = {value: (Math.round(newProfitNumberDifference*10000)/10000), negative: false}
  if (newProfitNumberDifference < 0) {
    newProfitNumberPercent = (-1*newProfitNumberPercent)
    newProfitNumberDifferenceObj.negative = true
    newProfitNumberDifferenceObj.value = (newProfitNumberDifferenceObj.value*-1)
  }
  let newProfitPercentDifferenceObj = {value: (Math.round(newProfitPercentDifference*10000)/10000), negative: false}
  if (newProfitPercentDifference < 0) {
    newProfitPercentPercent = (-1*newProfitPercentPercent)
    newProfitPercentDifferenceObj.negative = true
    newProfitPercentDifferenceObj.value = (newProfitPercentDifferenceObj.value*-1)
  }

  let returnObj = {
    newInterestDue: (Math.round((newInterestDue)*10000)/10000),
    newInterestDueDifference: newInterestDueDifferenceObj,
    newInterestDuePercent: (Math.round((newInterestDuePercent)*10000)/10000),
    newMonthlyPayments: (Math.round((monthlyPayments)*10000)/10000),
    newMonthlyPaymentsDifference: newMonthlyPaymentsDifferenceObj,
    newMonthlyPaymentsPercent: (Math.round((newMonthlyPaymentsPercent)*10000)/10000),
    newProfitNumber: (Math.round((newProfitNumber)*10000)/10000),
    newProfitNumberDifference: newProfitNumberDifferenceObj,
    newProfitNumberPercent: (Math.round((newProfitNumberPercent)*10000)/10000),
    newProfitPercent: (Math.round((newProfitPercent)*10000)/10000),
    newProfitPercentDifference: newProfitPercentDifferenceObj,
    newProfitPercentPercent: (Math.round((newProfitPercentPercent)*10000)/10000),
  }

  return returnObj
}
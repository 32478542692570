import React from "react"
import NoteAdded from "./components/NoteAdded";
import Finalized from "./components/Finalized";
import Unassigned from "./components/Unassigned";
import TagAssigned from "./components/TagAssigned";
import LeadDiscovered from "./components/LeadDiscovered";
import PathConnectors from "./components/PathConnectors";
import TargetsUpdated from "./components/TargetsUpdates";
import { FlexWrapper } from "../../StyledComponents";
import { useSelector } from "react-redux";
import MortgageUpdated from "./components/MortgageUpdated";
import AssigneesUpdated from "./components/AssigneesUpdated";
import UploadSuccessful from "./components/UploadSuccessful";
import UnderInvestigation from "./components/UnderInvestigation";
import DiscrepancyRejected from "./components/DiscrepancyRejected";
import DiscrepancyResolved from "./components/DiscrepancyResolved";
import ManualInvestigation from "./components/ManualInvestigation";
import TargetOutcomeUpdated from "./components/TargetOutcomeUpdated";
import DiscrepanciesAllResolved from "./components/DiscrepanciesAllResolved";
import RecordScannedSuccessfully from "./components/RecordScannedSuccessfully";
import NewDiscrepanciesDiscovered from "./components/NewDiscrepanciesDiscovered";
import DiscrepancyEditedOrRejected from "./components/DiscrepancyEditedOrCreated";
import { selectViewingLeadTimeline } from "../../slices/viewingLeadSlice";

const Timeline = ({ reportTimeline, mortgageTimeline }) => {

  const timeline = useSelector(selectViewingLeadTimeline)

  return (
    <>
      {reportTimeline ?
        <>
          {reportTimeline.map((milestone, index) => (
            <div key={index}>
              <FlexWrapper>
                <PathConnectors milestone={milestone} mapIndex={index} timeline={reportTimeline} />
                {milestone.milestone === 'Targets Updated' ?
                  <TargetsUpdated milestone={milestone} />
                : milestone.milestone === 'Target Outcome Updated' ?
                  <TargetOutcomeUpdated milestone={milestone} />
                : milestone.milestone === 'Under Investigation' ?
                  <UnderInvestigation milestone={milestone} />
                : milestone.milestone === 'Tag Created & Assigned' || milestone.milestone === 'Tag Assigned' ?
                  <TagAssigned milestone={milestone} />
                : milestone.milestone === 'Manual Investigation' ?
                  <ManualInvestigation milestone={milestone} />
                : milestone.milestone === 'Assignees Updated' ?
                  <AssigneesUpdated milestone={milestone} />
                : milestone.milestone === 'Note Added' ?
                  <NoteAdded milestone={milestone} />
                : milestone.milestone === 'Lead Discovered' ?
                  <LeadDiscovered milestone={milestone} />
                : milestone.milestone === 'New Discrepancies Discovered' ?
                  <NewDiscrepanciesDiscovered milestone={milestone} />
                : milestone.milestone === 'Mortgage Updated' ?
                  <MortgageUpdated milestone={milestone} />
                : milestone.milestone === 'Discrepancy Resolved' ?
                  <DiscrepancyResolved milestone={milestone} />
                : milestone.milestone === 'Discrepancy Rejected' ?
                  <DiscrepancyRejected milestone={milestone} />
                : milestone.milestone === 'Discrepancy Edited' || milestone.milestone === 'Discrepancy Created' ?
                  <DiscrepancyEditedOrRejected milestone={milestone} />
                : milestone.milestone === 'Finalized' ?
                  <Finalized milestone={milestone} />
                : milestone.milestone === 'All Discrepancies Resolved' ?
                  <DiscrepanciesAllResolved milestone={milestone} />
                : milestone.milestone === 'Record Scanned Successfully' || milestone.milestone === 'Initial Scan Successful' ?
                  <RecordScannedSuccessfully milestone={milestone} />
                : milestone.milestone === 'Property Uploaded Successfully' ?
                  <UploadSuccessful milestone={milestone} />
                :
                  <Unassigned milestone={milestone} />
                }
              </FlexWrapper>
            </div>
          ))}
        </>
      : mortgageTimeline ?
        <>
          {mortgageTimeline.map((milestone, index) => (
            <div key={index}>
              <FlexWrapper>
                <PathConnectors milestone={milestone} mapIndex={index} timeline={mortgageTimeline} />
                {milestone.milestone === 'Targets Updated' ?
                  <TargetsUpdated milestone={milestone} />
                : milestone.milestone === 'Target Outcome Updated' ?
                  <TargetOutcomeUpdated milestone={milestone} />
                : milestone.milestone === 'Under Investigation' ?
                  <UnderInvestigation milestone={milestone} />
                : milestone.milestone === 'Tag Created & Assigned' || milestone.milestone === 'Tag Assigned' ?
                  <TagAssigned milestone={milestone} />
                : milestone.milestone === 'Manual Investigation' ?
                  <ManualInvestigation milestone={milestone} />
                : milestone.milestone === 'Assignees Updated' ?
                  <AssigneesUpdated milestone={milestone} />
                : milestone.milestone === 'Note Added' ?
                  <NoteAdded milestone={milestone} />
                : milestone.milestone === 'Lead Discovered' ?
                  <LeadDiscovered milestone={milestone} />
                : milestone.milestone === 'New Discrepancies Discovered' ?
                  <NewDiscrepanciesDiscovered milestone={milestone} />
                : milestone.milestone === 'Mortgage Updated' ?
                  <MortgageUpdated milestone={milestone} />
                : milestone.milestone === 'Discrepancy Resolved' ?
                  <DiscrepancyResolved milestone={milestone} />
                : milestone.milestone === 'Discrepancy Rejected' ?
                  <DiscrepancyRejected milestone={milestone} />
                : milestone.milestone === 'Discrepancy Edited' || milestone.milestone === 'Discrepancy Created' ?
                  <DiscrepancyEditedOrRejected milestone={milestone} />
                : milestone.milestone === 'Finalized' ?
                  <Finalized milestone={milestone} />
                : milestone.milestone === 'All Discrepancies Resolved' ?
                  <DiscrepanciesAllResolved milestone={milestone} />
                : milestone.milestone === 'Record Scanned Successfully' || milestone.milestone === 'Initial Scan Successful' ?
                  <RecordScannedSuccessfully milestone={milestone} />
                :
                  <Unassigned milestone={milestone} />
                }
              </FlexWrapper>
            </div>
          ))}
        </>
      : timeline ?
        <>
          {timeline.map((milestone, index) => (
            <div key={index}>
              <FlexWrapper style={{paddingBottom: '1rem'}}>
                <PathConnectors milestone={milestone} mapIndex={index} timeline={timeline} origin={'lead'} />
                {milestone.milestone === 'Targets Updated' ?
                  <TargetsUpdated milestone={milestone} />
                : milestone.milestone === 'Target Outcome Updated' ?
                  <TargetOutcomeUpdated milestone={milestone} />
                : milestone.milestone === 'Under Investigation' ?
                  <UnderInvestigation milestone={milestone} />
                : milestone.milestone === 'Lead Tag Created & Assigned' || milestone.milestone === 'Lead Tag Assigned' || 'Mortgage Tag Created & Assigned' || milestone.milestone === 'Mortgage Tag Assigned' ?
                  <TagAssigned milestone={milestone} />
                : milestone.milestone === 'Manual Investigation' ?
                  <ManualInvestigation milestone={milestone} />
                : milestone.milestone === 'Assignees Updated' ?
                  <AssigneesUpdated milestone={milestone} />
                : milestone.milestone === 'Note Added' ?
                  <NoteAdded milestone={milestone} />
                : milestone.milestone === 'Lead Discovered' ?
                  <LeadDiscovered milestone={milestone} />
                : milestone.milestone === 'New Discrepancies Discovered' ?
                  <NewDiscrepanciesDiscovered milestone={milestone} />
                : milestone.milestone === 'Mortgage Updated' ?
                  <MortgageUpdated milestone={milestone} />
                : milestone.milestone === 'Discrepancy Resolved' ?
                  <DiscrepancyResolved milestone={milestone} />
                : milestone.milestone === 'Discrepancy Rejected' ?
                  <DiscrepancyRejected milestone={milestone} />
                : milestone.milestone === 'Discrepancy Edited' || milestone.milestone === 'Discrepancy Created' ?
                  <DiscrepancyEditedOrRejected milestone={milestone} />
                : milestone.milestone === 'Finalized' ?
                  <Finalized milestone={milestone} />
                : milestone.milestone === 'All Discrepancies Resolved' ?
                  <DiscrepanciesAllResolved milestone={milestone} />
                : milestone.milestone === 'Record Scanned Successfully' || milestone.milestone === 'Initial Scan Successful' ?
                  <RecordScannedSuccessfully milestone={milestone} />
                :
                  <Unassigned milestone={milestone} />
                }
              </FlexWrapper>
            </div>
          ))}
        </>
      :
        <></>
      }
    </>
  )
}

export default Timeline;
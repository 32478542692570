import React from "react";
import Tooltip from '@mui/material/Tooltip';
import ErrorIcon from '@mui/icons-material/Error';
import CancelIcon from '@mui/icons-material/Cancel';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import { useTheme } from "@mui/styles";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useSelector } from "react-redux";
import FormatClearIcon from '@mui/icons-material/FormatClear';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SignalWifiBadIcon from '@mui/icons-material/SignalWifiBad';
import WrongLocationIcon from '@mui/icons-material/WrongLocation';
import SpeakerNotesOffIcon from '@mui/icons-material/SpeakerNotesOff';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import { selectTeamQueryPerformances } from "../../../../../slices/teamAdminDataSlice";
import { ActionIconWrapper, ColumnContent, ComponentSectionRow, RowsContainer, ScrollWindow } from "../../../../../StyledComponents";

const SweepPerformances = ({ handleOpenViewSweepFailuresModal }) => {
  const theme = useTheme()

  const rows = useSelector(selectTeamQueryPerformances)

  return (
    <ScrollWindow className="horizontalOnly" style={{maxWidth: 'calc(100vw - 23.3rem)'}}>
      <ComponentSectionRow className="header recordSweeps" style={{borderBottom: 'none'}}>
        <Tooltip
          componentsProps={{tooltip: {sx: {
            color: `${theme.palette.common.white}`,
            backgroundColor: `${theme.palette.common.black}`,
            fontSize: "2rem",
            margin: ' 0'
          }}}}
          PopperProps={{modifiers: [{
            name: "offset",
            options: {
              offset: [0, -8],
            },
          }]}}
          title={<div style={{display: 'flex', flexDirection: 'column', justifyContent: "center", width: 'fit-content'}}>
            <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
              <p style={{fontSize: "1rem", fontWeight: "600", padding: "0 0.5rem", margin: '0'}}>Not Found</p>
            </div>
          </div>} 
          placement="top"
          arrow
        >
          <ColumnContent className="header" style={{gridColumn: '10/14', height: '100%', borderLeft: `0.1rem solid ${theme.palette.common.grayText}`, position: 'relative', right: '0.1rem'}}>
            <SignalWifiBadIcon style={{color: `${theme.palette.common.caution}`}} />
          </ColumnContent>
        </Tooltip>
        <Tooltip
          componentsProps={{tooltip: {sx: {
            color: `${theme.palette.common.white}`,
            backgroundColor: `${theme.palette.common.black}`,
            fontSize: "2rem",
            margin: ' 0'
          }}}}
          PopperProps={{modifiers: [{
            name: "offset",
            options: {
              offset: [0, -8],
            },
          }]}}
          title={<div style={{display: 'flex', flexDirection: 'column', justifyContent: "center", width: 'fit-content'}}>
            <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
              <p style={{fontSize: "1rem", fontWeight: "600", padding: "0 0.5rem", margin: '0'}}>Missing Records</p>
            </div>
          </div>} 
          placement="top"
          arrow
        >
          <ColumnContent className="header" style={{gridColumn: '14/18', height: '100%', borderLeft: `0.1rem solid ${theme.palette.common.grayText}`, position: 'relative', right: '0.2rem'}}>
            <WrongLocationIcon style={{color: `${theme.palette.common.caution}`}} />
          </ColumnContent>
        </Tooltip>
        <ColumnContent className="header" style={{gridColumn: '18/22', borderLeft: `0.1rem solid ${theme.palette.common.grayText}`, fontWeight: '400', position: 'relative', right: '0.3rem'}}>
          Discrepancies
        </ColumnContent>  
        <ColumnContent className="header" style={{gridColumn: '22/25', borderLeft: `0.1rem solid ${theme.palette.common.grayText}`, fontWeight: '400', position: 'relative', right: '0.4rem'}}>
          New Leads
        </ColumnContent>  
        <ColumnContent className="header" style={{gridColumn: '25/28', borderLeft: `0.1rem solid ${theme.palette.common.grayText}`, fontWeight: '400', position: 'relative', right: '0.4rem'}}>
          Updated Leads
        </ColumnContent>  
        <ColumnContent className="header" style={{gridColumn: '28', borderLeft: `0.1rem solid ${theme.palette.common.grayText}`, fontWeight: '400', position: 'relative', right: '0.4rem'}}>
          Upgraded
        </ColumnContent>  
      </ComponentSectionRow>
      <ComponentSectionRow className="recordSweeps header">
        <ColumnContent className="header" style={{gridColumn: '1', height: '100%', fontWeight: '400'}}>
          Index
        </ColumnContent>
        <ColumnContent className="header" style={{gridColumn: '2', height: '100%', fontWeight: '400'}}>
          Date
        </ColumnContent>
        <ColumnContent className="header" style={{gridColumn: '3', height: '100%', fontWeight: '400'}}>
          Queried
        </ColumnContent>
        <Tooltip
          componentsProps={{tooltip: {sx: {
            color: `${theme.palette.common.white}`,
            backgroundColor: `${theme.palette.common.black}`,
            fontSize: "2rem",
            margin: ' 0'
          }}}}
          PopperProps={{modifiers: [{
            name: "offset",
            options: {
              offset: [0, -8],
            },
          }]}}
          title={<div style={{display: 'flex', flexDirection: 'column', justifyContent: "center", width: 'fit-content'}}>
            <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
              <p style={{fontSize: "1rem", fontWeight: "600", padding: "0 0.5rem", margin: '0'}}>Successful Queries</p>
            </div>
          </div>} 
          placement="top"
          arrow
        >
          <ColumnContent className="header" style={{gridColumn: '4', height: '100%'}}>
            <CheckCircleIcon style={{color: `${theme.palette.primary.main}`}} />
          </ColumnContent>
        </Tooltip>
        <Tooltip
          componentsProps={{tooltip: {sx: {
            color: `${theme.palette.common.white}`,
            backgroundColor: `${theme.palette.common.black}`,
            fontSize: "2rem",
            margin: ' 0'
          }}}}
          PopperProps={{modifiers: [{
            name: "offset",
            options: {
              offset: [0, -8],
            },
          }]}}
          title={<div style={{display: 'flex', flexDirection: 'column', justifyContent: "center", width: 'fit-content'}}>
            <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
              <p style={{fontSize: "1rem", fontWeight: "600", padding: "0 0.5rem", margin: '0'}}>Failed Queries</p>
            </div>
          </div>} 
          placement="top"
          arrow
        >
          <ColumnContent className="header" style={{gridColumn: '5', height: '100%'}}>
            <CancelIcon style={{color: `${theme.palette.common.error}`}} />
          </ColumnContent>
        </Tooltip>
        <Tooltip
          componentsProps={{tooltip: {sx: {
            color: `${theme.palette.common.white}`,
            backgroundColor: `${theme.palette.common.black}`,
            fontSize: "2rem",
            margin: ' 0'
          }}}}
          PopperProps={{modifiers: [{
            name: "offset",
            options: {
              offset: [0, -8],
            },
          }]}}
          title={<div style={{display: 'flex', flexDirection: 'column', justifyContent: "center", width: '5rem'}}>
            <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
              <p style={{fontSize: "1rem", fontWeight: "600", padding: "0 0.5rem", margin: '0'}}>Inactive</p>
            </div>
          </div>} 
          placement="top"
          arrow
        >
          <ColumnContent className="header" style={{gridColumn: '6', height: '100%'}}>
            <ErrorIcon style={{color: `${theme.palette.common.grayBorder}`}} />
          </ColumnContent>
        </Tooltip>
        <Tooltip
          componentsProps={{tooltip: {sx: {
            color: `${theme.palette.common.white}`,
            backgroundColor: `${theme.palette.common.black}`,
            fontSize: "2rem",
            margin: ' 0'
          }}}}
          PopperProps={{modifiers: [{
            name: "offset",
            options: {
              offset: [0, -8],
            },
          }]}}
          title={<div style={{display: 'flex', flexDirection: 'column', justifyContent: "center", width: '10rem'}}>
            <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
              <p style={{fontSize: "1rem", fontWeight: "600", padding: "0 0.5rem", margin: '0'}}>Formatting Errors</p>
            </div>
          </div>} 
          placement="top"
          arrow
        >
          <ColumnContent className="header" style={{gridColumn: '7', height: '100%', borderLeft: `0.1rem solid ${theme.palette.common.grayText}`}}>
            <FormatClearIcon style={{color: `${theme.palette.common.orange}`}} />
          </ColumnContent>
        </Tooltip>
        <Tooltip
          componentsProps={{tooltip: {sx: {
            color: `${theme.palette.common.white}`,
            backgroundColor: `${theme.palette.common.black}`,
            fontSize: "2rem",
            margin: ' 0'
          }}}}
          PopperProps={{modifiers: [{
            name: "offset",
            options: {
              offset: [0, -8],
            },
          }]}}
          title={<div style={{display: 'flex', flexDirection: 'column', justifyContent: "center", width: '9rem'}}>
            <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
              <p style={{fontSize: "1rem", fontWeight: "600", padding: "0 0.5rem", margin: '0'}}>Improper Query</p>
            </div>
          </div>} 
          placement="top"
          arrow
        >
          <ColumnContent className="header" style={{gridColumn: '8', height: '100%'}}>
            <SpeakerNotesOffIcon style={{color: `${theme.palette.common.orange}`}} />
          </ColumnContent>
        </Tooltip>
        <Tooltip
          componentsProps={{tooltip: {sx: {
            color: `${theme.palette.common.white}`,
            backgroundColor: `${theme.palette.common.black}`,
            fontSize: "2rem",
            margin: ' 0'
          }}}}
          PopperProps={{modifiers: [{
            name: "offset",
            options: {
              offset: [0, -8],
            },
          }]}}
          title={<div style={{display: 'flex', justifyContent: "center", width: '12rem'}}>
            <p style={{fontSize: "1rem", fontWeight: "600", padding: "0 0.5rem", margin: '0'}}>Broken Connection</p>
          </div>} 
          placement="top"
          arrow
        >
          <ColumnContent className="header" style={{gridColumn: '9', height: '100%'}}>
            <WifiOffIcon style={{color: `${theme.palette.common.orange}`}} />
          </ColumnContent>
        </Tooltip>
        <ColumnContent className="header" style={{gridColumn: '10', height: '100%', fontWeight: '400', borderLeft: `0.1rem solid ${theme.palette.common.grayText}`, position: 'relative', right: '0.1rem'}}>
          <span style={{color: `${theme.palette.common.error}`}}>TOT</span>
        </ColumnContent>
        <ColumnContent className="header" style={{gridColumn: '11', height: '100%', fontWeight: '400'}}>
          <span style={{color: `${theme.palette.common.orange}`}}>PM</span>
        </ColumnContent>
        <ColumnContent className="header" style={{gridColumn: '12', height: '100%', fontWeight: '400'}}>
          <span style={{color: `${theme.palette.common.orange}`}}>AT</span>
        </ColumnContent>
        <ColumnContent className="header" style={{gridColumn: '13', height: '100%', fontWeight: '400'}}>
          <span style={{color: `${theme.palette.common.orange}`}}>CL</span>
        </ColumnContent>
        <ColumnContent className="header" style={{gridColumn: '14', height: '100%', fontWeight: '400', borderLeft: `0.1rem solid ${theme.palette.common.grayText}`, position: 'relative', right: '0.2rem'}}>
          <span style={{color: `${theme.palette.common.error}`}}>TOT</span>
        </ColumnContent>
        <ColumnContent className="header" style={{gridColumn: '15', height: '100%', fontWeight: '400'}}>
          <span style={{color: `${theme.palette.common.orange}`}}>PM</span>
        </ColumnContent>
        <ColumnContent className="header" style={{gridColumn: '16', height: '100%', fontWeight: '400'}}>
          <span style={{color: `${theme.palette.common.orange}`}}>AT</span>
        </ColumnContent>
        <ColumnContent className="header" style={{gridColumn: '17', height: '100%', fontWeight: '400'}}>
          <span style={{color: `${theme.palette.common.orange}`}}>CL</span>
        </ColumnContent>
        <ColumnContent className="header" style={{gridColumn: '18', height: '100%', fontWeight: '400', borderLeft: `0.1rem solid ${theme.palette.common.grayText}`, position: 'relative', right: '0.3rem'}}>
          Total
        </ColumnContent>
        <ColumnContent className="header" style={{gridColumn: '19', height: '100%', fontWeight: '400', fontSize: '0.8rem'}}>
          Tier 1
        </ColumnContent>
        <ColumnContent className="header" style={{gridColumn: '20', height: '100%', fontWeight: '400', fontSize: '0.8rem'}}>
          Tier 2
        </ColumnContent>
        <ColumnContent className="header" style={{gridColumn: '21', height: '100%', fontWeight: '400', fontSize: '0.8rem'}}>
          Tier 3
        </ColumnContent>
        <ColumnContent className="header" style={{gridColumn: '22', height: '100%', fontWeight: '400', borderLeft: `0.1rem solid ${theme.palette.common.grayText}`, position: 'relative', right: '0.4rem'}}>
          Total
        </ColumnContent>
        <ColumnContent className="header" style={{gridColumn: '23', height: '100%', fontWeight: '400', fontSize: '0.8rem'}}>
          Tier 1
        </ColumnContent>
        <ColumnContent className="header" style={{gridColumn: '24', height: '100%', fontWeight: '400', fontSize: '0.8rem'}}>
          Tier 2
        </ColumnContent>
        <ColumnContent className="header" style={{gridColumn: '25', height: '100%', fontWeight: '400', borderLeft: `0.1rem solid ${theme.palette.common.grayText}`, position: 'relative', right: '0.4rem'}}>
          Total
        </ColumnContent>
        <ColumnContent className="header" style={{gridColumn: '26', height: '100%', fontWeight: '400', fontSize: '0.8rem'}}>
          Tier 1
        </ColumnContent>
        <ColumnContent className="header" style={{gridColumn: '27', height: '100%', fontWeight: '400', fontSize: '0.8rem'}}>
          Tier 2
        </ColumnContent>
        <ColumnContent className="header" style={{gridColumn: '28', height: '100%', borderLeft: `0.1rem solid ${theme.palette.common.grayText}`, position: 'relative', right: '0.4rem'}}>
          <KeyboardDoubleArrowUpIcon style={{color: `${theme.palette.grayText}`}} />
          Tier 2 to 1
        </ColumnContent>
      </ComponentSectionRow>
      <RowsContainer style={{height: '100%'}}>
        {rows.toReversed().map((row, index) => (
          row.errored ?
            <ComponentSectionRow key={index} className={(index%2 === 1 ? 'even ' : '') + 'recordSweeps'}>
              <ColumnContent className="detail" style={{gridColumn: '1', fontSize: '1rem'}}>
                {index + 1}
              </ColumnContent>
              <ColumnContent className="detail" style={{gridColumn: '2', fontSize: '1rem'}}>
                {row.date}
              </ColumnContent>
              <ColumnContent className="detail" style={{gridColumn: '3/25'}}>
                -connection failed-
              </ColumnContent>
            </ComponentSectionRow>
          :
            <ComponentSectionRow key={index} className={(index%2 === 1 ? 'even ' : '') + 'recordSweeps'}>
              <ColumnContent className="detail" style={{gridColumn: '1', fontSize: '1rem'}}>
                {index + 1}
              </ColumnContent>
              <ColumnContent className="detail" style={{gridColumn: '2', fontSize: '1rem'}}>
                {row.date}
              </ColumnContent>
              <ColumnContent className="detail" style={{gridColumn: '3', fontSize: '1rem'}}>
                {row.totalQueried}
              </ColumnContent>
              <ColumnContent className="detail" style={{gridColumn: '4', fontSize: '1rem'}}>
                {row.totalSuccessfulQueries}
              </ColumnContent>
              <ColumnContent className="detail" style={{gridColumn: '5', fontSize: '1rem'}}>
                {row.totalFailedQueries}
              </ColumnContent>
              <ColumnContent className="detail" style={{gridColumn: '6', fontSize: '1rem'}}>
                {row.totalInactive}
              </ColumnContent>
              <ColumnContent className="detail" style={{gridColumn: '7', fontSize: '1rem', borderLeft: `0.1rem solid ${theme.palette.common.black}`}}>
                {row.formattingErrors.length}
                {row.formattingErrors.length > 0 ?
                  <ActionIconWrapper className={(index%2 === 1 ? 'even ' : '') + 'hover'} onClick={() => handleOpenViewSweepFailuresModal(row.formattingErrors, 'formattingErrors')}>
                    <OpenInNewIcon style={{height: '1.0rem', width: '1.0rem', color: `${theme.palette.primary.dark}`}} />
                  </ActionIconWrapper>
                :
                  <></>
                }
              </ColumnContent>
              <ColumnContent className="detail" style={{gridColumn: '8', fontSize: '1rem'}}>
                {row.improperQueries.length}
                {row.improperQueries.length > 0 ?
                  <ActionIconWrapper className={(index%2 === 1 ? 'even ' : '') + 'hover'} onClick={() => handleOpenViewSweepFailuresModal(row.improperQueries, 'improperQueries')}>
                    <OpenInNewIcon style={{height: '1.0rem', width: '1.0rem', color: `${theme.palette.primary.dark}`}} />
                  </ActionIconWrapper>
                :
                  <></>
                }
              </ColumnContent>
              <ColumnContent className="detail" style={{gridColumn: '9', fontSize: '1rem'}}>
                {row.brokenConnections.length}
                {row.brokenConnections.length > 0 ?
                  <ActionIconWrapper className={(index%2 === 1 ? 'even ' : '') + 'hover'} onClick={() => handleOpenViewSweepFailuresModal(row.brokenConnections, 'brokenConnections')}>
                    <OpenInNewIcon style={{height: '1.0rem', width: '1.0rem', color: `${theme.palette.primary.dark}`}} />
                  </ActionIconWrapper>
                :
                  <></>
                }
              </ColumnContent>
              <ColumnContent className="detail" style={{gridColumn: '10', fontSize: '1rem', borderLeft: `0.1rem solid ${theme.palette.common.black}`}}>
                {row.completeNotFound.length}
                {row.completeNotFound.length > 0 ?
                  <ActionIconWrapper className={(index%2 === 1 ? 'even ' : '') + 'hover'} onClick={() => handleOpenViewSweepFailuresModal(row.completeNotFound, 'failedQueries')}>
                    <OpenInNewIcon style={{height: '1.0rem', width: '1.0rem', color: `${theme.palette.primary.dark}`}} />
                  </ActionIconWrapper>
                :
                  <></>
                }
              </ColumnContent>
              <ColumnContent className="detail" style={{gridColumn: '11', fontSize: '1rem'}}>
                {row.propMixNotFound.length}
                {row.propMixNotFound.length > 0 ?
                  <ActionIconWrapper className={(index%2 === 1 ? 'even ' : '') + 'hover'} onClick={() => handleOpenViewSweepFailuresModal(row.propMixNotFound, 'failedQueries')}>
                    <OpenInNewIcon style={{height: '1.0rem', width: '1.0rem', color: `${theme.palette.primary.dark}`}} />
                  </ActionIconWrapper>
                :
                  <></>
                }
              </ColumnContent>
              <ColumnContent className="detail" style={{gridColumn: '12', fontSize: '1rem'}}>
                {row.attomNotFound.length}
                {row.attomNotFound.length > 0 ?
                  <ActionIconWrapper className={(index%2 === 1 ? 'even ' : '') + 'hover'} onClick={() => handleOpenViewSweepFailuresModal(row.attomNotFound, 'failedQueries')}>
                    <OpenInNewIcon style={{height: '1.0rem', width: '1.0rem', color: `${theme.palette.primary.dark}`}} />
                  </ActionIconWrapper>
                :
                  <></>
                }
              </ColumnContent>
              <ColumnContent className="detail" style={{gridColumn: '13', fontSize: '1rem'}}>
                {row.clCurrentMortgageNotFound.length}
                {row.clCurrentMortgageNotFound.length > 0 ?
                  <ActionIconWrapper className={(index%2 === 1 ? 'even ' : '') + 'hover'} onClick={() => handleOpenViewSweepFailuresModal(row.clCurrentMortgageNotFound, 'failedQueries')}>
                    <OpenInNewIcon style={{height: '1.0rem', width: '1.0rem', color: `${theme.palette.primary.dark}`}} />
                  </ActionIconWrapper>
                :
                  <></>
                }
              </ColumnContent>
              <ColumnContent className="detail" style={{gridColumn: '14', fontSize: '1rem', borderLeft: `0.1rem solid ${theme.palette.common.black}`}}>
                {row.completeMissingRecords.length}
                {row.completeMissingRecords.length > 0 ?
                  <ActionIconWrapper className={(index%2 === 1 ? 'even ' : '') + 'hover'} onClick={() => handleOpenViewSweepFailuresModal(row.completeMissingRecords, 'failedQueries')}>
                    <OpenInNewIcon style={{height: '1.0rem', width: '1.0rem', color: `${theme.palette.primary.dark}`}} />
                  </ActionIconWrapper>
                :
                  <></>
                }
              </ColumnContent>
              <ColumnContent className="detail" style={{gridColumn: '15', fontSize: '1rem'}}>
                {row.propMixMissingRecords.length}
                {row.propMixMissingRecords.length > 0 ?
                  <ActionIconWrapper className={(index%2 === 1 ? 'even ' : '') + 'hover'} onClick={() => handleOpenViewSweepFailuresModal(row.propMixMissingRecords, 'failedQueries')}>
                    <OpenInNewIcon style={{height: '1.0rem', width: '1.0rem', color: `${theme.palette.primary.dark}`}} />
                  </ActionIconWrapper>
                :
                  <></>
                }
              </ColumnContent>
              <ColumnContent className="detail" style={{gridColumn: '16', fontSize: '1rem'}}>
                {row.attomMissingRecords.length}
                {row.attomMissingRecords.length > 0 ?
                  <ActionIconWrapper className={(index%2 === 1 ? 'even ' : '') + 'hover'} onClick={() => handleOpenViewSweepFailuresModal(row.attomMissingRecords, 'failedQueries')}>
                    <OpenInNewIcon style={{height: '1.0rem', width: '1.0rem', color: `${theme.palette.primary.dark}`}} />
                  </ActionIconWrapper>
                :
                  <></>
                }
              </ColumnContent>
              <ColumnContent className="detail" style={{gridColumn: '17', fontSize: '1rem'}}>
                {row.clCurrentMortgageMissingRecords.length}
                {row.clCurrentMortgageMissingRecords.length > 0 ?
                  <ActionIconWrapper className={(index%2 === 1 ? 'even ' : '') + 'hover'} onClick={() => handleOpenViewSweepFailuresModal(row.clCurrentMortgageMissingRecords, 'failedQueries')}>
                    <OpenInNewIcon style={{height: '1.0rem', width: '1.0rem', color: `${theme.palette.primary.dark}`}} />
                  </ActionIconWrapper>
                :
                  <></>
                }
              </ColumnContent>
              <ColumnContent className="detail" style={{gridColumn: '18', fontSize: '1rem', borderLeft: `0.1rem solid ${theme.palette.common.black}`}}>
                {row.totalDiscrepancies}
              </ColumnContent>
              <ColumnContent className="detail" style={{gridColumn: '19', fontSize: '1rem'}}>
                {row.totalTier1Discrepancies}
              </ColumnContent>
              <ColumnContent className="detail" style={{gridColumn: '20', fontSize: '1rem'}}>
                {row.totalTier2Discrepancies}
              </ColumnContent>
              <ColumnContent className="detail" style={{gridColumn: '21', fontSize: '1rem'}}>
                {row.totalTier3Discrepancies}
              </ColumnContent>
              <ColumnContent className="detail" style={{gridColumn: '22', fontSize: '1rem', borderLeft: `0.1rem solid ${theme.palette.common.black}`}}>
                {row.totalNewLeads}
              </ColumnContent>
              <ColumnContent className="detail" style={{gridColumn: '23', fontSize: '1rem'}}>
                {row.tier1New}
              </ColumnContent>
              <ColumnContent className="detail" style={{gridColumn: '24', fontSize: '1rem'}}>
                {row.tier2New}
              </ColumnContent>
              <ColumnContent className="detail" style={{gridColumn: '25', fontSize: '1rem', borderLeft: `0.1rem solid ${theme.palette.common.black}`}}>
                {row.totalUpdatedLeads}
              </ColumnContent>
              <ColumnContent className="detail" style={{gridColumn: '26', fontSize: '1rem'}}>
                {row.tier1Updated}
              </ColumnContent>
              <ColumnContent className="detail" style={{gridColumn: '27', fontSize: '1rem'}}>
                {row.tier2Updated}
              </ColumnContent>
              <ColumnContent className="detail" style={{gridColumn: '28', fontSize: '1rem', borderLeft: `0.1rem solid ${theme.palette.common.black}`}}>
                {row.tier2Upgraded}
              </ColumnContent>
            </ComponentSectionRow>
        ))}
      </RowsContainer>
    </ScrollWindow>
  )
}

export default SweepPerformances
import React from "react"
import IconLogo from '../icon.logo.black.png';
import LoadingLogo from '../icon.logo.green.png';
import { useTheme } from "@mui/styles";
import { FlexWrapper, ImageElements, PageContainer, ScreenSaverContainer } from "../StyledComponents"

const Error = () => {
  const theme = useTheme()

  return (
    <PageContainer className="screenSaver">
      <ScreenSaverContainer className="screenSaver fadeIn">
        <ImageElements src={IconLogo} className="centerLogo opaque" />
          <FlexWrapper className="column alignCenter fitContentHeight fitContentWidth" style={{height: '15rem'}}>
            <FlexWrapper className="fitContentHeight fitContentWidth">
              <ImageElements src={LoadingLogo} className="centerLogo sacredGeometry one" />
              <ImageElements src={LoadingLogo} className="centerLogo sacredGeometry two" />
              <span style={{fontSize: '2rem', color: `${theme.palette.common.grayText}`, zIndex: '101', margin: 'auto 0'}}>This application is under maintenance.</span>
            </FlexWrapper>
            <span style={{fontSize: '1rem', color: `${theme.palette.common.grayText}`, zIndex: '101', margin: 'auto 0 0 0'}}>contact solutions@lancastersweep.com for immediate assistance</span>
          </FlexWrapper>
      </ScreenSaverContainer>
    </PageContainer>
  )
}

export default Error
import { createSlice } from '@reduxjs/toolkit'
import { apiSlice } from './api/apiSlice'

const initialState = {
  data: {
    duplicates: [{}, []],
    showWelcomeModal: false,
  },
  status: 'idle',
  error: null
}

export const sessionDataSlice = createSlice({
  name: 'sessionData',
  initialState,
  reducers: {
    setShowWelcomeModal(state, action) { state.data.showWelcomeModal = action.payload },
  },
  extraReducers: (builder) => {
    builder
    .addMatcher(apiSlice.endpoints.checkForMortgageDuplicates.matchFulfilled, (state, {payload}) => {
      state.data.duplicates = payload.data.duplicates
    })
  }
})

export const { setShowWelcomeModal } = sessionDataSlice.actions

export default sessionDataSlice.reducer

export const selectDuplicateCheckResponse = state => state.sessionData.data.duplicates
export const selectShowWelcomeModal = state => state.sessionData.data.showWelcomeModal

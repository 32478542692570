import React from "react";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import CircleIcon from '@mui/icons-material/Circle';
import FormControl from '@mui/material/FormControl';
import { useTheme } from "@mui/styles";
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { ControlContainer, ControlHeader, SubHeader } from "../../../../../StyledComponents";

const Controls = ({ notificationStatusFilter, handleNotificationStatusFilterChange }) => {
  const theme = useTheme();

  const filterStatusControlProps = (item) => ({
    checked: notificationStatusFilter === item,
    onChange: handleNotificationStatusFilterChange,
    value: item,
    label: item,
    labelPlacement: "end",
    sx: {
      color: `${theme.palette.common.black}`,
    }
  });
  const colorProps = () => ({
    sx: {
      padding: "0.5rem 0.5rem 0.5rem 0",
      color: `${theme.palette.common.grayBorder}`,
      '&.Mui-checked': {
        color: `${theme.palette.primary.main}`,
      },
    }
  });

  return (
    <>
      <ControlContainer>
        <ControlHeader>Filter</ControlHeader>
        <SubHeader>Status</SubHeader>
        <FormControl>
          <RadioGroup>
            <FormControlLabel
              control={<Radio {...colorProps()} />}
              {...filterStatusControlProps('All')} 
            />
          </RadioGroup>
        </FormControl>
      </ControlContainer>
      <ControlContainer>
        <ControlHeader>Legend</ControlHeader>
        <SubHeader>Status</SubHeader>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <CircleIcon style={{color: `${theme.palette.primary.main}`, height: '2rem'}} />
          <span style={{padding: '0 0 0 0.5rem'}}>New</span>
        </div>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <CircleIcon style={{color: `${theme.palette.secondary.light}`, height: '2rem'}} />
          <span style={{padding: '0 0 0 0.5rem'}}>Unread</span>
        </div>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <RadioButtonUncheckedIcon style={{color: `${theme.palette.common.grayBorder}`, height: '2rem'}} />
          <span style={{padding: '0 0 0 0.5rem'}}>Read</span>
        </div>
      </ControlContainer>
    </>
  )
}

export default Controls;
import React, { useEffect } from "react";
import NavBar from "../Dashboard/components/NavBar/NavBar";
import Uploads from "./components/Uploads/Uploads";
import SideBar from "./components/SideBar/SideBar";
import IconLogo from '../../icon.logo.black.png';
import DataBase from "./components/DataBase/DataBase";
import DataModel from "./components/DataModel/DataModel";
import TeamDetails from "./components/TeamDetails/TeamDetails";
import LoadingLogo from '../../icon.logo.green.png';
import RecordSweeps from "./components/RecordSweeps/RecordSweeps";
import ScheduledTasks from "./components/ScheduledTasks/ScheduledTasks";
import MortgageRepository from "./components/MortgageRepository/MortgageRepository";
import ActionsAndErrorsLog from "./components/ActionsLog/ActionsAndErrorsLog";
import { setPageSelection } from "../../slices/pageSelectionSlice";
import { selectUserFullName } from "../../slices/userInfoSlice";
import { selectAdminNavSelection } from "../../slices/adminNavSelectionSlice";
import { useDispatch, useSelector } from "react-redux";
import { PageContentContainer, PageContentWrapper, Footer, PageContainer, ScreenSaverContainer, ImageElements, FlexWrapper } from "../../StyledComponents";
import { selectLoadData } from "../../slices/loadDataSlice";

const SuperDash = ({ teamId, userId, page }) => {
  const dispatch = useDispatch()

  const userFullName = useSelector(selectUserFullName)
  const adminNavSelection = useSelector(selectAdminNavSelection)
  const dataLoaded = useSelector(selectLoadData)

  useEffect(() => (
    dispatch( setPageSelection(page) )
  ), [dispatch, page])

  return (
    <PageContainer>

      {dataLoaded ?
        <ScreenSaverContainer className="login fadeOut">
          <ImageElements src={IconLogo} className="centerLogo opaque" />
          <ImageElements src={LoadingLogo} className="centerLogo loaded one" style={{right: '10rem'}} />
          <ImageElements src={LoadingLogo} className="centerLogo loaded two" style={{right: '10rem'}} />
          <ImageElements src={LoadingLogo} className="centerLogo loaded three" style={{right: '5rem'}} />
          <ImageElements src={LoadingLogo} className="centerLogo loaded four" style={{right: '5rem'}} />
          <ImageElements src={LoadingLogo} className="centerLogo loaded five" style={{}} />
          <ImageElements src={LoadingLogo} className="centerLogo loaded six" style={{}} />
          <ImageElements src={LoadingLogo} className="centerLogo loaded seven" style={{left: '5rem'}} />
          <ImageElements src={LoadingLogo} className="centerLogo loaded eight" style={{left: '5rem'}} />
          <ImageElements src={LoadingLogo} className="centerLogo loaded nine" style={{left: '10rem'}} />
          <ImageElements src={LoadingLogo} className="centerLogo loaded ten" style={{left: '10rem'}} />
        </ScreenSaverContainer>
      :
        <ScreenSaverContainer className="screenSaver">
          <ImageElements src={IconLogo} className="centerLogo opaque" />
          <ImageElements src={LoadingLogo} className="centerLogo loading" style={{right: '10rem', animationDelay: '0.5s'}} />
          <ImageElements src={LoadingLogo} className="centerLogo loading" style={{right: '5rem', animationDelay: '0.7s'}} />
          <ImageElements src={LoadingLogo} className="centerLogo loading" style={{animationDelay: '0.9s'}} />
          <ImageElements src={LoadingLogo} className="centerLogo loading" style={{left: '5rem', animationDelay: '1.1s'}} />
          <ImageElements src={LoadingLogo} className="centerLogo loading" style={{left: '10rem', animationDelay: '1.3s'}} />
        </ScreenSaverContainer>
      }

      <NavBar />
      <SideBar />

      <PageContentContainer>
        <PageContentWrapper className="noScroll">
          {adminNavSelection === 'recordSweeps' ?
            <RecordSweeps teamId={teamId} userId={userId} />
          : adminNavSelection === 'repository' ?
            <MortgageRepository userId={userId} teamId={teamId} />
          : adminNavSelection === 'uploads' ?
            <Uploads userId={userId} teamId={teamId} userFullName={userFullName} />
          : adminNavSelection === 'log' ?
            <ActionsAndErrorsLog />
          : adminNavSelection === 'database' ?
            <DataBase userId={userId} />
          : adminNavSelection === 'scheduledTasks' ?
            <ScheduledTasks />
          : adminNavSelection === 'teamDetails' ?
            <TeamDetails teamId={teamId} userId={userId} />
          : adminNavSelection === 'dataModel' ?
            <DataModel userFullName={userFullName} />
          :
            <></>
          }
        </PageContentWrapper>
        <Footer><img src={IconLogo} style={{height: '100%', width: 'auto'}} />Lancaster Sweep<span style={{fontSize: '0.4rem', paddingBottom: '0.5rem'}}>TM</span></Footer>
      </PageContentContainer>
    </PageContainer>
  )
}

export default SuperDash;
import React, { useState, useRef, useEffect } from "react";
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from "@mui/icons-material/Error";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CircularProgress from "@mui/material/CircularProgress";
import { useTheme } from "@mui/styles";
import { useSelector } from "react-redux";
import { selectMortgageTargets, setMortgageTargets } from "../../../../slices/mortgageTargetsSlice";
import { useSaveLeadTargetUpdatesMutation } from "../../../../slices/api/apiSlice";
import { Detail, DetailContainer, DetailInfoContainer, DetailListContainer, ErrorWrapper, HeaderText, Label, InputWrapper, StatusAndLabelWrapper, StyledInputElement, VerticalContainer, Button, ButtonWrapper, ModalContentContainer, ComponentHeader } from "../../../../StyledComponents";
import { calculateTargetChanges } from "../../../../utils/calculateTargetsPreview";

const EditTargets = ({ leadData, handleCloseEditTargetModal, userId, userFullName, dashPerspective, mortgageData }) => {
  const theme = useTheme();

  const [previewChanges, setPreviewChanges] = useState(false)
  const [previewLoading, setPreviewLoading] = useState(false)
  const [noNew, setNoNew] = useState(false)

  const editedTargetsPreview = useSelector(selectMortgageTargets)
  const [saveTargetChanges, {isFetching: saveIsFetching, isSuccess: saveIsSuccess, isError: saveIsError}] = useSaveLeadTargetUpdatesMutation()

  const [oldTargets, setOldTargets] = useState({});
  const [loanAmount, setLoanAmount] = useState(leadData.targetLoanAmount);
  const [loanAmountStr, setLoanAmountStr] = useState(leadData.targetLoanAmount.toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2}));
  const [statusLoanAmount, setStatusLoanAmount] = useState(null);
  const targetLoanAmount = useRef();
  const [loanTerm, setLoanTerm] = useState(leadData.targetLoanTerm);
  const [statusLoanTerm, setStatusLoanTerm] = useState(null);
  const targetLoanTerm = useRef();
  const [interestRate, setInterestRate] = useState(leadData.targetInterestRate);
  const [statusInterestRate, setStatusInterestRate] = useState(null);
  const targetInterestRate = useRef();

  const validateFormInputs = () => {
    let validated = false;
    let negativeAmount = Math.sign(loanAmount)
    let zeroAmount = Math.sign(loanAmount)
    let negativeTerm = Math.sign(loanTerm)
    let zeroTerm = Math.sign(loanTerm)
    let negativeInterest = Math.sign(interestRate)
    let zeroInterest = Math.sign(interestRate)
    if (negativeAmount === -1 || zeroAmount === 0 || negativeTerm === -1 || zeroTerm === 0 || negativeInterest === -1 || zeroInterest === 0 || isNaN(loanAmount) || isNaN(loanTerm) || isNaN(interestRate) || loanAmount > 100000000 || loanTerm > 99 || interestRate > 99) {
      if (negativeAmount === -1) {
        setStatusLoanAmount('negative')
      }
      if (zeroAmount === 0) {
        setStatusLoanAmount('zero')
      }
      if (negativeTerm === -1) {
        setStatusLoanTerm('negative')
      }
      if (zeroTerm === 0) {
        setStatusLoanTerm('zero')
      }
      if (negativeInterest === -1) {
        setStatusInterestRate('negative')
      }
      if (zeroInterest === 0) {
        setStatusInterestRate('zero')
      }
      if (isNaN(loanAmount)) {
        setStatusLoanAmount('nonNumber')
      }
      if (isNaN(loanTerm)) {
        setStatusLoanTerm('nonNumber')
      }
      if (isNaN(interestRate)) {
        setStatusInterestRate('nonNumber')
      }
      if (loanAmount > 100000000) {
        setStatusLoanAmount('large')
      }
      if (loanTerm > 99) {
        setStatusLoanTerm('large')
      }
      if (interestRate > 99) {
        setStatusInterestRate('large')
      }
    } else {
      validated = true
    }
    return validated;
  }

  const handlePreviewChanges = async () => {
    let valid = validateFormInputs();
    if (valid) {
      if (
        (loanAmountStr === leadData.targetLoanAmount.toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2}))
        && (parseInt(loanTerm) === leadData.targetLoanTerm)
        && (parseFloat(interestRate) === leadData.targetInterestRate)
      ) {
        setNoNew(true);
      } else {
        setPreviewLoading(true)
        let previewValues = calculateTargetChanges({
          userId: userId,
          interestRate: interestRate,
          loanAmount: loanAmount,
          loanTerm: loanTerm,
          targetInterestDue: leadData.targetInterestDue,
          targetMonthlyPayments: leadData.targetMonthlyPayments,
          interestRemaining: mortgageData.interestRemaining,
          targetProfitNumber: leadData.targetProfitNumber,
          targetProfitPercent: leadData.targetProfitPercent,
        });
        setMortgageTargets(previewValues)
        setPreviewChanges(true);
        setPreviewLoading(false)
      }
    }
  }

  const handleSaveChanges = async () => {
    let valid = validateFormInputs();
    if (valid) {
      let updatedFields = [];
      if (loanAmount !== leadData.targetLoanAmount) {
        updatedFields.push({field: 'Target Loan Amount', old: leadData.targetLoanAmount, new: parseFloat(loanAmount)})
      }
      if (loanTerm !== leadData.targetLoanTerm) {
        updatedFields.push({field: 'Target Loan Term', old: leadData.targetLoanTerm, new: parseFloat(loanTerm)})
      }
      if (interestRate !== leadData.targetInterestRate) {
        updatedFields.push({field: 'Target Interest Rate', old: leadData.targetInterestRate, new: parseFloat(interestRate)})
      }
      saveTargetChanges({
        userId: userId,
        leadStatus: leadData.status,
        timeline: leadData.timeline,
        dashPerspective: dashPerspective,
        oldTargetProfitNumber: leadData.targetProfitNumber,
        targetProfitNumber: editedTargetsPreview.newProfitNumber,
        newProfitNumberDifference: editedTargetsPreview.newProfitNumberDifference,
        oldTargetProfitPercent: leadData.targetProfitPercent,
        targetProfitPercent: editedTargetsPreview.newProfitPercent,
        newProfitPercentDifference: editedTargetsPreview.newProfitPercentDifference,
        userFullName: userFullName,
        updatedFields: updatedFields,
        leadId: leadData._id,
        targetLoanAmount: loanAmount,
        targetLoanTerm: loanTerm,
        targetInterestRate: interestRate,
        targetInterestDue: editedTargetsPreview.newInterestDue,
        targetMonthlyPayments: editedTargetsPreview.newMonthlyPayments,
        newMonthlyPaymentsDifference: editedTargetsPreview.newMonthlyPaymentsDifference,
        oldMonthlyPayments: leadData.targetMonthlyPayments,
        userNotifications: leadData.userNotifications,
      });
    }
  }

  useEffect(() => {
    setOldTargets({
      oldTargetProfitPercent: leadData.targetProfitPercent,
      oldTargetProfitNumber: leadData.targetProfitNumber,
      oldTargetMonthlyPayments: leadData.targetMonthlyPayments,
      oldTargetInterestDue: leadData.targetInterestDue,
    })
  }, [])

   return (
      <>
        {saveIsSuccess ?
          <ComponentHeader className="modal green"><CheckIcon style={{height: '2.5rem', width: '2.5rem', margin: '0 0.5rem 0.2rem 0'}} />Success</ComponentHeader>
        : saveIsError ?
          <ComponentHeader className="modal red"><ErrorIcon style={{height: '2.5rem', width: '2.5rem', margin: '0 0.5rem 0.2rem 0'}} />failed... try again or contact support@lancastersweep.com</ComponentHeader>
        :
          <ComponentHeader className="modal">Refinance Targets</ComponentHeader>
        }

        <ModalContentContainer className="topColumn leadActions">
          <div style={{display: 'flex', width: '40rem', justifyContent: 'space-between', margin: '0 auto', height: 'fit-content'}}>
            <DetailContainer className="large bigHeader">
              <HeaderText className="main gray noPad center" style={{height: '5rem', display: 'flex', alignItems: 'flex-end', margin: '0.6rem auto 0 auto'}}>Finances</HeaderText>
              <DetailListContainer style={{height: '100%', padding: '2.5rem 0 0 0'}}>
                <DetailInfoContainer className="item" style={{gap: '1.4rem'}}>
                  <Detail className="item">Total Loan Amount</Detail>
                  <Detail className="item">Months Remaining</Detail>
                  <Detail className="item">Principal Paid</Detail>
                  <Detail className="item">Principal Remaining</Detail>
                  <Detail className="item">Interest Paid</Detail>
                  <Detail className="item">Interest Remaining</Detail>
                </DetailInfoContainer>
                <DetailInfoContainer style={{gap: '1.4rem'}}>
                  <Detail>${(mortgageData.principalRemaining + mortgageData.principalPaid).toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2})}</Detail>
                  <Detail>{leadData.remainingMonths}</Detail>
                  <Detail>${mortgageData.principalPaid.toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2})}</Detail>
                  <Detail>${mortgageData.principalRemaining.toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2})}</Detail>
                  <Detail>${mortgageData.interestPaid.toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2})}</Detail>
                  <Detail>${mortgageData.interestRemaining.toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2})}</Detail>
                </DetailInfoContainer>
              </DetailListContainer>
            </DetailContainer>

            <VerticalContainer style={{width: '20rem', alignItems: 'center'}}>
              <StatusAndLabelWrapper style={{alignItems: 'center', flexDirection: 'column', height: 'fit-content'}}>
                {noNew ?
                  <ErrorWrapper className="orange">
                    <WarningAmberIcon />
                    No Targets Changed
                  </ErrorWrapper>
                :
                  <div style={{height: '2.0rem'}}>                    
                  </div>
                }
                <HeaderText className="main gray noPad center">Targets</HeaderText>
              </StatusAndLabelWrapper>
              <InputWrapper style={{width: '10rem'}}>
                <StatusAndLabelWrapper style={{height: '2.4rem'}}>
                  <Label>Loan Amount ($)</Label>
                  {statusLoanAmount === "nonNumber" && (
                    <ErrorWrapper>
                      <ErrorIcon />
                      Must be a number
                    </ErrorWrapper>
                  )}
                  {statusLoanAmount === "negative" && (
                    <ErrorWrapper>
                      <ErrorIcon />
                      Can't be negative
                    </ErrorWrapper>
                  )}
                  {statusLoanAmount === "zero" && (
                    <ErrorWrapper>
                      <ErrorIcon />
                      Can't be zero
                    </ErrorWrapper>
                  )}
                  {statusLoanAmount === "large" && (
                    <ErrorWrapper>
                      <ErrorIcon />
                      {`Must be < 100m`}
                    </ErrorWrapper>
                  )}
                </StatusAndLabelWrapper>
                <StyledInputElement style={{width: '10rem'}}
                  value={loanAmountStr}
                  disabled={previewChanges}
                  type="text"
                  name="loanAmount"
                  id="loanAmount"
                  ref={targetLoanAmount}
                  className={`${previewChanges} border`}
                  onChange={() => {
                    if (statusLoanAmount === "nonNumber" || statusLoanAmount === "negative" || statusLoanAmount === "zero" || statusLoanAmount === "large") {
                      setStatusLoanAmount(null)
                    }
                    if (noNew) {
                      setNoNew(false)
                    }
                    setLoanAmountStr(targetLoanAmount.current.value.toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2}))
                    setLoanAmount(parseFloat(targetLoanAmount.current.value.replace(/[^0-9-.]/g, '')))
                  }}
                />
              </InputWrapper>
              <InputWrapper style={{width: '10rem'}}>
                <StatusAndLabelWrapper style={{height: '2.4rem'}}>
                  <Label>Loan Term (years)</Label>
                  {statusLoanTerm === "nonNumber" && (
                    <ErrorWrapper>
                      <ErrorIcon />
                      Must be a number
                    </ErrorWrapper>
                  )}
                  {statusLoanTerm === "decimal" && (
                    <ErrorWrapper>
                      <ErrorIcon />
                      No decimals
                    </ErrorWrapper>
                  )}
                  {statusLoanTerm === "negative" && (
                    <ErrorWrapper>
                      <ErrorIcon />
                      Can't be negative
                    </ErrorWrapper>
                  )}
                  {statusLoanTerm === "zero" && (
                    <ErrorWrapper>
                      <ErrorIcon />
                      Can't be zero
                    </ErrorWrapper>
                  )}
                  {statusLoanTerm === "large" && (
                    <ErrorWrapper>
                      <ErrorIcon />
                      {`Must be < 99`}
                    </ErrorWrapper>
                  )}
                </StatusAndLabelWrapper>
                <StyledInputElement style={{width: '10rem'}}
                  value={loanTerm}
                  disabled={previewChanges}
                  type="text"
                  name="loanTerm"
                  id="loanTerm"
                  ref={targetLoanTerm}
                  className={`${previewChanges} border`}
                  onChange={() => {
                    if (statusLoanTerm === "nonNumber" || statusLoanTerm === "negative" || statusLoanTerm === "zero" || statusLoanTerm === "large" || statusLoanTerm === "decimal") {
                      setStatusLoanTerm(null)
                    }
                    if (noNew) {
                      setNoNew(false)
                    }
                    setLoanTerm(targetLoanTerm.current.value)
                  }}
                />
              </InputWrapper>
              <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <InputWrapper style={{width: '10rem'}}>
                  <StatusAndLabelWrapper style={{height: '2.4rem'}}>
                    <Label>Interest Rate (%)</Label>
                    {statusInterestRate === "nonNumber" && (
                      <ErrorWrapper>
                        <ErrorIcon />
                        Must be a number
                      </ErrorWrapper>
                    )}
                    {statusInterestRate === "negative" && (
                      <ErrorWrapper>
                        <ErrorIcon />
                        Can't be negative
                      </ErrorWrapper>
                    )}
                    {statusInterestRate === "zero" && (
                      <ErrorWrapper>
                        <ErrorIcon />
                        Can't be zero
                      </ErrorWrapper>
                    )}
                    {statusInterestRate === "large" && (
                      <ErrorWrapper>
                        <ErrorIcon />
                        {`Must be < 99`}
                      </ErrorWrapper>
                    )}
                  </StatusAndLabelWrapper>
                  <StyledInputElement style={{width: '10rem'}}
                    value={interestRate}
                    disabled={previewChanges}
                    type="text"
                    name="interestRate"
                    id="interestRate"
                    ref={targetInterestRate}
                    className={`${previewChanges} border`}
                    onChange={() => {
                      if (statusInterestRate === "nonNumber" || statusInterestRate === "negative" || statusInterestRate === "zero" || statusInterestRate === "large") {
                        setStatusInterestRate(null)
                      }
                      if (noNew) {
                        setNoNew(false)
                      }
                      setInterestRate(targetInterestRate.current.value)
                    }}
                  />
                </InputWrapper>
              </div>
            </VerticalContainer>
          </div>

          <DetailContainer className="target" style={{padding: '2rem 2rem', margin: '0 auto'}}>
            <DetailListContainer>
              <DetailInfoContainer className="item">
                {previewChanges ?
                  <>
                    <Detail className="targetPreview">Target Interest Due:</Detail>
                    <Detail className="targetPreview" style={{borderTop: `solid 0.1rem ${theme.palette.common.grayBorder}`, marginTop: '1rem'}}>Target Monthly Payments:</Detail>
                    <Detail className="targetPreview" style={{borderTop: `solid 0.1rem ${theme.palette.common.grayBorder}`, marginTop: '1rem'}}>New Change in Interest Owed:</Detail>
                    <Detail className="targetPreview" style={{borderTop: `solid 0.1rem ${theme.palette.common.grayBorder}`, marginTop: '1rem'}}>New Change in Revenue:</Detail>
                  
                  </>
                :
                  <>
                    <Detail className="targetAdjust">Target Interest Due:</Detail>
                    <Detail className="targetAdjust">Target Monthly Payments:</Detail>
                    <Detail className="targetAdjust">Change in Interest Owed:</Detail>
                    <Detail className="targetAdjust">Change in Revenue:</Detail>
                  </>
                }
              </DetailInfoContainer>
              <DetailInfoContainer className="">
                {previewChanges ?
                  <>
                    <Detail className="targetPreview potential">
                      ${editedTargetsPreview.newInterestDue.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}
                      <div style={{display: 'flex', flexDirection: 'column'}}>
                        <DetailListContainer style={{padding: '2rem 0 0 1rem', fontWeight: '400'}}>
                          <DetailInfoContainer className="item"  style={{}}>
                            <Detail>before: </Detail>
                            <Detail>difference: </Detail>
                          </DetailInfoContainer>
                          <DetailInfoContainer>
                            <Detail>${oldTargets.oldTargetInterestDue.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}</Detail>
                            {!editedTargetsPreview.newInterestDueDifference.negative && editedTargetsPreview.newInterestDueDifference.value !== undefined ?
                              <Detail>${editedTargetsPreview.newInterestDueDifference.value.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}</Detail>
                            : editedTargetsPreview.newInterestDueDifference.value !== undefined ?
                              <Detail>-${editedTargetsPreview.newInterestDueDifference.value.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}</Detail>
                            :
                              <></>
                            }
                          </DetailInfoContainer>
                        </DetailListContainer>
                      </div>
                      {!editedTargetsPreview.newInterestDueDifference.negative ?
                        <Detail className="wrapped" style={{margin: '2rem 0 0 1.5rem'}}>{editedTargetsPreview.newInterestDuePercent.toLocaleString("en-US", {maximumFractionDigits: 1, minimumFractionDigits: 1})}% increase</Detail> 
                      :
                        <Detail className="wrappedRed" style={{margin: '2rem 0 0 1.5rem'}}>{editedTargetsPreview.newInterestDuePercent.toLocaleString("en-US", {maximumFractionDigits: 1, minimumFractionDigits: 1})}% decrease</Detail>     
                      }
                    </Detail>

                    <Detail className="targetPreview potential" style={{borderTop: `solid 0.1rem ${theme.palette.common.grayBorder}`, marginTop: '1rem'}}>
                      ${editedTargetsPreview.newMonthlyPayments.toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2})}
                      <div style={{display: 'flex', flexDirection: 'column'}}>
                        <DetailListContainer style={{padding: '2rem 0 0 1rem', fontWeight: '400'}}>
                          <DetailInfoContainer className="item"  style={{}}>
                            <Detail>before: </Detail>
                            <Detail>difference: </Detail>
                          </DetailInfoContainer>
                          <DetailInfoContainer>
                            <Detail>${oldTargets.oldTargetMonthlyPayments.toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2})}</Detail>
                            {!editedTargetsPreview.newMonthlyPaymentsDifference.negative && editedTargetsPreview.newMonthlyPaymentsDifference.value !== undefined ?
                              <Detail>${editedTargetsPreview.newMonthlyPaymentsDifference.value.toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2})}</Detail>
                            : editedTargetsPreview.newMonthlyPaymentsDifference.value !== undefined  ?
                              <Detail>-${editedTargetsPreview.newMonthlyPaymentsDifference.value.toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2})}</Detail>
                            :
                              <></>
                            }
                          </DetailInfoContainer>
                        </DetailListContainer>
                      </div>
                      {!editedTargetsPreview.newMonthlyPaymentsDifference.negative ?
                        <Detail className="wrapped" style={{margin: '2rem 0 0 1.5rem'}}>{editedTargetsPreview.newMonthlyPaymentsPercent.toLocaleString("en-US", {maximumFractionDigits: 1, minimumFractionDigits: 1})}% increase</Detail> 
                      :
                        <Detail className="wrappedRed" style={{margin: '2rem 0 0 1.5rem'}}>{editedTargetsPreview.newMonthlyPaymentsPercent.toLocaleString("en-US", {maximumFractionDigits: 1, minimumFractionDigits: 1})}% decrease</Detail>     
                      }
                    </Detail>

                    <Detail className="targetPreview target" style={{borderTop: `solid 0.1rem ${theme.palette.common.grayBorder}`, marginTop: '1rem'}}>
                      {parseInt(editedTargetsPreview.newProfitNumber) >= 0 ?
                        <span style={{fontSize: '1.8rem'}}>${editedTargetsPreview.newProfitNumber.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}</span>
                      :
                        <span style={{color: `${theme.palette.common.red}`, fontSize: '1.8rem'}}>-${editedTargetsPreview.newProfitNumber.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}</span>
                      }
                      <div style={{display: 'flex', flexDirection: 'column'}}>
                        <DetailListContainer style={{padding: '2.4rem 0 0 1rem', fontWeight: '400', color: `${theme.palette.common.black}`}}>
                          <DetailInfoContainer className="item"  style={{}}>
                            <Detail>before: </Detail>
                            <Detail>difference: </Detail>
                          </DetailInfoContainer>
                          <DetailInfoContainer>
                            <Detail>${oldTargets.oldTargetProfitNumber.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}</Detail>
                            {!editedTargetsPreview.newProfitNumberDifference.negative && editedTargetsPreview.newProfitNumberDifference.value !== undefined ?
                              <Detail>${editedTargetsPreview.newProfitNumberDifference.value.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}</Detail>
                            : editedTargetsPreview.newProfitNumberDifference.value !== undefined ?
                              <Detail>-${editedTargetsPreview.newProfitNumberDifference.value.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}</Detail>
                            :
                              <></>
                            }
                          </DetailInfoContainer>
                        </DetailListContainer>
                      </div>
                      {!editedTargetsPreview.newProfitNumberDifference.negative ?
                        <Detail className="wrapped" style={{margin: '2rem 0 0 1.5rem'}}>{editedTargetsPreview.newProfitNumberPercent.toLocaleString("en-US", {maximumFractionDigits: 1, minimumFractionDigits: 1})}% increase</Detail> 
                      :
                        <Detail className="wrappedRed" style={{margin: '2rem 0 0 1.5rem'}}>{editedTargetsPreview.newProfitNumberPercent.toLocaleString("en-US", {maximumFractionDigits: 1, minimumFractionDigits: 1})}% decrease</Detail>     
                      }
                    </Detail>

                    <Detail className="targetPreview target" style={{borderTop: `solid 0.1rem ${theme.palette.common.grayBorder}`, marginTop: '1rem'}}>

                      {parseInt(editedTargetsPreview.newProfitPercent) >= 0 ?
                        <span style={{fontSize: '1.8rem'}}>{editedTargetsPreview.newProfitPercent.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}%</span>
                      :
                        <span style={{color: `${theme.palette.common.red}`, fontSize: '1.8rem'}}>-{editedTargetsPreview.newProfitPercent.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}%</span>
                      }
                      <div style={{display: 'flex', flexDirection: 'column'}}>
                        <DetailListContainer style={{padding: '2.4rem 0 0 1rem', fontWeight: '400', color: `${theme.palette.common.black}`}}>
                          <DetailInfoContainer className="item"  style={{}}>
                            <Detail>before: </Detail>
                            <Detail>difference: </Detail>
                          </DetailInfoContainer>
                          <DetailInfoContainer>
                            <Detail>{oldTargets.oldTargetProfitPercent.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}%</Detail>
                            {!editedTargetsPreview.newProfitPercentDifference.negative && editedTargetsPreview.newProfitPercentDifference.value !== undefined ?
                              <Detail>{editedTargetsPreview.newProfitPercentDifference.value.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}%</Detail>
                            : editedTargetsPreview.newProfitPercentDifference.value !== undefined ?
                              <Detail>-{editedTargetsPreview.newProfitPercentDifference.value.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}%</Detail>
                            :
                              <></>
                            }
                          </DetailInfoContainer>
                        </DetailListContainer>
                      </div>
                      {!editedTargetsPreview.newProfitPercentDifference.negative && editedTargetsPreview !== null ?
                        <Detail className="wrapped" style={{margin: '2rem 0 0 1.5rem'}}>{editedTargetsPreview.newProfitPercentPercent.toLocaleString("en-US", {maximumFractionDigits: 1, minimumFractionDigits: 1})}% increase</Detail> 
                      : editedTargetsPreview !== null ?
                        <Detail className="wrappedRed" style={{margin: '2rem 0 0 1.5rem'}}>{editedTargetsPreview.newProfitPercentPercent.toLocaleString("en-US", {maximumFractionDigits: 1, minimumFractionDigits: 1})}% decrease</Detail>  
                      :
                        <></>   
                      }
                    </Detail>
                  </>
                :
                  <>
                    <Detail className="targetAdjust potential">${leadData.targetInterestDue.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}</Detail>
                    <Detail className="targetAdjust potential">${leadData.targetMonthlyPayments.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}</Detail>
                    {leadData.targetProfitNumber < 0 ?
                      <Detail className="targetAdjust target red">-${(leadData.targetProfitNumber * -1).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}</Detail>
                    :
                      <Detail className="targetAdjust target">${leadData.targetProfitNumber.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}</Detail>
                    }
                    {leadData.targetProfitPercent < 0 ?
                      <Detail className="targetAdjust target red">{leadData.targetProfitPercent.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}%</Detail>
                    :
                      <Detail className="targetAdjust target">{leadData.targetProfitPercent.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}%</Detail>
                    }
                  </>
                }
              </DetailInfoContainer>
            </DetailListContainer>
          </DetailContainer>

          <ButtonWrapper className="marginAuto">
            {saveIsSuccess ?
              <Button className="gray" onClick={() => handleCloseEditTargetModal()}>Close</Button>
            : previewChanges || saveIsError ?
              <>
                <Button className="primary" onClick={() => handleSaveChanges()}>Save</Button>
                <Button className="gray" onClick={() => setPreviewChanges(false)}>Go Back</Button>
              </>
            : previewLoading || saveIsFetching ?
              <>
                <Button className="primaryLoading" >
                  <CircularProgress
                    sx={{ color: `${theme.palette.common.white}` }}
                    size={18}
                  />
                </Button>
                <Button className="grayLoading" >
                  <CircularProgress
                    sx={{ color: `${theme.palette.common.black}` }}
                    size={18}
                  />
                </Button>
              </>
            :
              <>
                <Button className="primary fit" onClick={() => handlePreviewChanges()}>Preview Changes</Button>
                <Button className="gray" onClick={() => handleCloseEditTargetModal()}>Cancel</Button>
              </>
            }
          </ButtonWrapper>
        </ModalContentContainer>
      </>
   )
}

export default EditTargets;
import React from "react";
import InfoIcon from '@mui/icons-material/Info';
import { useTheme } from "@mui/styles";
import { AccentInfo, Detail, DetailContainer, DetailInfoContainer, DetailListContainer, FlexWrapper } from "../../../StyledComponents";

const Finalized = ({milestone}) => {
  const theme = useTheme()

  return (
    <DetailContainer className="fitContent">
      <Detail className="timelineHeader">{milestone.milestone}{milestone.notify ? <span style={{color: `${theme.palette.primary.main}`, margin: '0.25rem 0 0 0.5rem', fontWeight: '400'}}>new</span> : <></>}</Detail>
      <DetailListContainer className='timeline'>
        <DetailInfoContainer className="item timeline">
          <Detail className="timelineDetail">initiated by:</Detail>
          <Detail className="timelineDetail" style={{margin: '0 0 0.5rem 0'}}>date:</Detail>
          {milestone.details.map((detail, index) => (
            <Detail key={index}>{detail.field}:</Detail>
          ))}
          {milestone.newProfits ?
            <>
              <Detail className="timelineDetail">finalized profit:</Detail>
              <Detail className="timelineDetail">profit difference:</Detail>
            </>
          :
            <></>
          }
        </DetailInfoContainer>
        <DetailInfoContainer className="space">
          <Detail className="timelineItem">{milestone.contributor}</Detail>
          <Detail className="timelineItem" style={{margin: '0 0 0.5rem 0'}}>{milestone.date}</Detail>
          {milestone.details.map((detail, index) => (
            <Detail key={index} className="timelineItem">
              {detail.field.includes('Rate') || detail.field.includes('%') ?
                <>
                  <span style={{fontWeight: '600'}}>%{detail.old.toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2})}</span><span style={{margin: '0 0.4rem'}}>to</span><span style={{fontWeight: '600'}}>%{detail.new.toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2})}</span>
                </>
              : detail.field.includes('Parcel') || detail.field.includes('Term') || detail.field.includes('Owner') || detail.field.includes('Date') || detail.field.includes('Unit') || detail.field.includes('Street') || detail.field.includes('City') || detail.field.includes('Postal') || detail.field.includes('State') ?
                !detail.old ?
                  <>
                    <AccentInfo style={{fontWeight: '400'}}>-empty-</AccentInfo><span style={{margin: '0 0.4rem', fontWeight: '400'}}>to</span><span style={{fontWeight: '600'}}>{detail.new}</span>
                  </>
                : !detail.new ?
                  <>
                    <span style={{fontWeight: '600'}}>{detail.old}</span><span style={{margin: '0 0.4rem', fontWeight: '400'}}>to</span><AccentInfo style={{fontWeight: '400'}}>-empty-</AccentInfo>
                  </>
                :
                  <>
                    <span style={{fontWeight: '600'}}>{detail.old}</span><span style={{margin: '0 0.4rem', fontWeight: '400'}}>to</span><span style={{fontWeight: '600'}}>{detail.new}</span>
                  </>
              :
                !detail.old ?
                  <>
                    <AccentInfo style={{fontWeight: '400'}}>-empty-</AccentInfo><span style={{margin: '0 0.4rem', fontWeight: '400'}}>to</span><span style={{fontWeight: '600'}}>${detail.new.toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 0})}</span>
                  </>
                : !detail.new ?
                  <>
                    <span style={{fontWeight: '600'}}>${detail.old.toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 0})}</span><span style={{margin: '0 0.4rem', fontWeight: '400'}}>to</span><AccentInfo style={{fontWeight: '400'}}>-empty-</AccentInfo>
                  </>
                :
                  <>
                    <span style={{fontWeight: '600'}}>${detail.old.toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 0})}</span><span style={{margin: '0 0.4rem', fontWeight: '400'}}>to</span><span style={{fontWeight: '600'}}>${detail.new.toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 0})}</span>
                  </>
              }
            </Detail>
          ))}
          {milestone.newProfits ?
            <>
              {!milestone.newProfits.profitNumber.negative ?
                <Detail className="timelineItem green">${milestone.newProfits.profitNumber.new.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}</Detail> 
              :
                <Detail className="timelineItem red">-${(milestone.newProfits.profitNumber.new*-1).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}</Detail>     
              }
              {!milestone.newProfits.profitPercent.negative ?
                <Detail className="timelineItem green">{milestone.newProfits.profitPercent.new.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}%</Detail> 
              :
                <Detail className="timelineItem red">{(milestone.newProfits.profitPercent.new*-1).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}%</Detail>     
              }
            </>
          :
            <></>
          }
        </DetailInfoContainer>
      </DetailListContainer>
      {milestone.activeDiscrepancies > 0 ?
        <FlexWrapper className="alignCenter" style={{height: 'fit-content', paddingRight: '2rem', margin: '0.25rem 0 1rem 0'}}>
          <FlexWrapper style={{border: `0.2rem solid ${theme.palette.common.grayDark}`, padding: '0.5rem', width: '2.7rem', borderRadius: '1rem 0 0 1rem', background: `${theme.palette.common.grayText}`}}>
            <InfoIcon style={{height: '2.5rem', width: '2.5rem', color: `${theme.palette.common.white}`}} />
          </FlexWrapper>
          <span style={{border: `0.1rem solid ${theme.palette.common.grayDark}`, padding: '0.3rem 1rem 0.3rem 1rem', borderRadius: '0 1rem 1rem 0', background: `${theme.palette.common.grayBorder}`, borderLeft: 'none', color: `${theme.palette.common.grayDark}`}}>
            Our internal records are currently awaiting the expected changes to be reflected in the public records.
          </span>
        </FlexWrapper>
      :
        <></>
      }
    </DetailContainer>
  )
}

export default Finalized
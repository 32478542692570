import React from "react";
import { useTheme } from "@mui/styles";
import { Detail, DetailContainer, DetailInfoContainer, DetailListContainer } from "../../../StyledComponents";

const MortgageUpdated = ({milestone}) => {
  const theme = useTheme()

  return (
    <DetailContainer className="fitContent">
      <Detail className="timelineHeader">{milestone.milestone}{milestone.notify ? <span style={{color: `${theme.palette.primary.main}`, margin: '0.25rem 0 0 0.5rem', fontWeight: '400'}}>new</span> : <></>}</Detail>
      <DetailListContainer className='timeline'>
        <DetailInfoContainer className="item timeline">
          <Detail className="timelineDetail">updated by:</Detail>
          <Detail className="timelineDetail">date:</Detail>
          <Detail>{milestone.details.label}:</Detail>
        </DetailInfoContainer>
        <DetailInfoContainer className="space">
          <Detail className="timelineItem">{milestone.contributor}</Detail>
          <Detail className="timelineItem">{milestone.date}</Detail>
          <div>
            {milestone.details.field.includes('Rate') ?
              <>
                <span style={{fontWeight: '600'}}>%{milestone.details.old.toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2})}</span><span style={{margin: '0 0.4rem'}}>to</span><span style={{fontWeight: '600'}}>%{milestone.details.new.toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2})}</span>
              </>
            : milestone.details.field.includes('Parcel') || milestone.details.field.includes('Term') || milestone.details.field.includes('Owner') || milestone.details.field.includes('Date') || milestone.details.field.includes('Unit') || milestone.details.field.includes('Street') || milestone.details.field.includes('City') || milestone.details.field.includes('Postal') || milestone.details.field.includes('State')?
              !milestone.details.old && milestone.details.new ?
                <>
                  <span style={{fontStyle: 'italic'}}>none</span><span style={{margin: '0 0.4rem'}}>to</span><span style={{fontWeight: '600'}}>{milestone.details.new}</span>
                </>
              : milestone.details.old && !milestone.details.new ?
                <>
                  <span style={{fontWeight: '600'}}>{milestone.details.old}</span><span style={{margin: '0 0.4rem'}}>to</span><span style={{fontStyle: 'italic'}}>none</span>
                </>
              :
                <>
                  <span style={{fontWeight: '600'}}>{milestone.details.old}</span><span style={{margin: '0 0.4rem'}}>to</span><span style={{fontWeight: '600'}}>{milestone.details.new}</span>
                </>
            :
              <>
                <span style={{fontWeight: '600'}}>${milestone.details.old.toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 0})}</span><span style={{margin: '0 0.4rem'}}>to</span><span style={{fontWeight: '600'}}>${milestone.details.new.toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 0})}</span>
              </>
            }
          </div>
        </DetailInfoContainer>
      </DetailListContainer>
    </DetailContainer>
  )
}

export default MortgageUpdated
import { createSlice } from '@reduxjs/toolkit'
import { apiSlice } from './api/apiSlice'

const initialState = {
  data: {
    newInterestDue: 0,
    newInterestDueDifference: 0,
    newInterestDuePercent: 0,
    newMonthlyPayments: 0,
    newMonthlyPaymentsDifference: 0,
    newMonthlyPaymentsPercent: 0,
    newProfitNumber: 0,
    newProfitNumberDifference: 0,
    newProfitNumberPercent: 0,
    newProfitPercent: 0,
    newProfitPercentDifference: 0,
    newProfitPercentPercent: 0,
  },
  status: 'idle',
  error: null
}

export const mortgageTargetsSlice = createSlice({
  name: 'mortgageTargets',
  initialState,
  reducers: {
    setMortgageTargets(state, action) { state.data = action.payload },
  },
  extraReducers: () => {},
})

export const { setMortgageTargets } = mortgageTargetsSlice.actions

export default mortgageTargetsSlice.reducer

export const selectMortgageTargets = state => state.mortgageTargets.data

import React from "react";
import 'react-csv-importer/dist/index.css';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from "@mui/icons-material/Error";
import { useTheme } from "@mui/styles";
import CircularProgress from "@mui/material/CircularProgress";
import { useSetLeadStatusClosingMutation } from "../../../../../slices/api/apiSlice";
import { Button, ButtonWrapper, ModalContentContainer, ComponentHeader, ModalHeaderMessage, FlexWrapper, SubHeader } from "../../../../../StyledComponents";

const BeginClosing = ({ handleCloseLeadActionsModal, userId, teamId, userFullName, leadData, dashPerspective, leadUserNotifications }) => {
  const theme = useTheme();

  const [setStatusClosing, {isLoading, isSuccess, isError}] = useSetLeadStatusClosingMutation()

  const handleSetClosing = async () => {
    await setStatusClosing({
      leadId: leadData._id,
      teamId: teamId,
      userId: userId,
      dashPerspective: dashPerspective,
      timeline: leadData.timeline,
      userFullName: userFullName,
      userNotifications: leadUserNotifications,
    })
  }

  return (
    <>
      {isSuccess ?
        <ComponentHeader className="modal green"><CheckIcon style={{height: '2.5rem', width: '2.5rem', margin: '0 0.5rem 0.2rem 0'}} />Success</ComponentHeader>
      : isError ?
        <ComponentHeader className="modal red"><ErrorIcon style={{height: '2.5rem', width: '2.5rem', margin: '0 0.5rem 0.2rem 0'}} />failed... try again or contact support@lancastersweep.com</ComponentHeader>
      :
        <ComponentHeader className="modal">Initiate Closing</ComponentHeader>
      }

      <ModalContentContainer className="centerColumn leadActions">
        {isSuccess ?
          <SubHeader className="modal" style={{margin: '0 auto 4rem auto'}}>
            <span>This mortgage has been designating as 'closing'.</span>
          </SubHeader>
        :
          <SubHeader className="modal" style={{margin: '0 auto 4rem auto'}}>
            <span>Confirm this lead is in the closing</span>
            <span>stages of being {leadData.targetOutcome === 'renegotiation' ? 'renegotiated' : 'refinanced'}.</span>
          </SubHeader>
        }
        {isSuccess || isError ?
          <ButtonWrapper>
            <Button onClick={() => handleCloseLeadActionsModal()} className="gray">Continue</Button>
          </ButtonWrapper>
        :
          <ButtonWrapper>
            {isLoading ?
              <Button className="primaryLoading">
                <CircularProgress
                  sx={{ color: `${theme.palette.common.cardBackground}` }}
                  size={13}
                />
              </Button>
            :
              <Button onClick={() => handleSetClosing()} className="primary">
                Confirm
              </Button>
            }         
            <Button onClick={() => handleCloseLeadActionsModal()} className="gray">Cancel</Button>
          </ButtonWrapper>
        }
      </ModalContentContainer>
    </>
  )
}

export default BeginClosing;
import React, { useState } from "react";
import CircleIcon from '@mui/icons-material/Circle';
import { styled } from '@mui/material/styles';
import { useTheme } from "@emotion/react";
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { ActionIconWrapper, ColumnContent, ComponentWrapper, HeaderText, HorizontalContainer, MainContent, VerticalContainer } from "../../../../../StyledComponents";
import { useSelector } from "react-redux";
import { selectTier1Parameters, selectTier2Parameters, selectTier3Parameters } from "../../../../../slices/teamSweepParametersSlice";

const SweepParameters = ({ sweepParameterData }) => {
  const theme = useTheme()

  let tier1Parameters = useSelector(selectTier1Parameters)
  let tier2Parameters = useSelector(selectTier2Parameters)
  let tier3Parameters = useSelector(selectTier3Parameters)

  const [expanded, setExpanded] = useState('')

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  return (
    <ComponentWrapper>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} style={{border: 'none', borderBottom: `0.1rem solid ${theme.palette.common.grayText}`}}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" style={{background: `${theme.palette.common.grayBorder}`}}>
          <HeaderText className="sub">Sweep Parameters</HeaderText>
        </AccordionSummary>
        <AccordionDetails style={{borderTop: `0.1rem solid ${theme.palette.common.grayText}`}}>
          <HorizontalContainer className='fitContent'>
            <VerticalContainer className="fitContent">
              <ColumnContent className="bottomBorder">
                index
              </ColumnContent>
              {tier1Parameters.map((data, index) => (
                <ColumnContent key={index}>
                  <MainContent>{index + 1}</MainContent>
                </ColumnContent>
              ))}
              {tier2Parameters.map((data, index) => (
                <ColumnContent key={index}>
                  <MainContent>{index + 1}</MainContent>
                </ColumnContent>
              ))}
              {tier3Parameters.map((data, index) => (
                <ColumnContent key={index}>
                  <MainContent>{index + 1}</MainContent>
                </ColumnContent>
              ))}
            </VerticalContainer>
            <VerticalContainer className="fitContent">
              <ColumnContent className="bottomBorder">
                ID
              </ColumnContent>
              {tier1Parameters.map((data, index) => (
                <ColumnContent key={index}>
                  <MainContent>{data._id}</MainContent>
                </ColumnContent>
              ))}
              {tier2Parameters.map((data, index) => (
                <ColumnContent key={index}>
                  <MainContent>{data._id}</MainContent>
                </ColumnContent>
              ))}
              {tier3Parameters.map((data, index) => (
                <ColumnContent key={index}>
                  <MainContent>{data._id}</MainContent>
                </ColumnContent>
              ))}
            </VerticalContainer>
            <VerticalContainer className="fitContent">
              <ColumnContent className="bottomBorder">
                Label
              </ColumnContent>
              {tier1Parameters.map((data, index) => (
                <ColumnContent key={index}>
                  <MainContent>{data.label}</MainContent>
                </ColumnContent>
              ))}
              {tier2Parameters.map((data, index) => (
                <ColumnContent key={index}>
                  <MainContent>{data.label}</MainContent>
                </ColumnContent>
              ))}
              {tier3Parameters.map((data, index) => (
                <ColumnContent key={index}>
                  <MainContent>{data.label}</MainContent>
                </ColumnContent>
              ))}
            </VerticalContainer>
            <VerticalContainer className="fitContent">
              <ColumnContent className="bottomBorder">
                Active
              </ColumnContent>
                {tier1Parameters.map((data, index) => (
                  <ColumnContent key={index}>
                    {data.active ?
                      <ActionIconWrapper className="noHover active">
                        <CircleIcon />
                      </ActionIconWrapper>
                    :
                      <ActionIconWrapper className="noHover">
                        <RadioButtonUncheckedIcon />
                      </ActionIconWrapper>
                    }
                  </ColumnContent>
                ))}
                {tier2Parameters.map((data, index) => (
                  <ColumnContent key={index}>
                    {data.active ?
                      <ActionIconWrapper className="noHover active">
                        <CircleIcon />
                      </ActionIconWrapper>
                    :
                      <ActionIconWrapper className="noHover">
                        <RadioButtonUncheckedIcon />
                      </ActionIconWrapper>
                    }
                  </ColumnContent>
                ))}
                {tier3Parameters.map((data, index) => (
                  <ColumnContent key={index}>
                    {data.active ?
                      <ActionIconWrapper className="noHover active">
                        <CircleIcon />
                      </ActionIconWrapper>
                    :
                      <ActionIconWrapper className="noHover">
                        <RadioButtonUncheckedIcon />
                      </ActionIconWrapper>
                    }
                  </ColumnContent>
                ))}
            </VerticalContainer>
            <VerticalContainer className="fitContent">
              <ColumnContent className="bottomBorder">
                Suggested Tier
              </ColumnContent>
              {tier1Parameters.map((data, index) => (
                <ColumnContent key={index}>
                  <MainContent key={index}>{data.suggestedTier}</MainContent>
                </ColumnContent>
              ))}
              {tier2Parameters.map((data, index) => (
                <ColumnContent key={index}>
                  <MainContent key={index}>{data.suggestedTier}</MainContent>
                </ColumnContent>
              ))}
              {tier3Parameters.map((data, index) => (
                <ColumnContent key={index}>
                  <MainContent key={index}>{data.suggestedTier}</MainContent>
                </ColumnContent>
              ))}
            </VerticalContainer>
            <VerticalContainer className="fitContent">
              <ColumnContent className="bottomBorder">
                Assigned Tier
              </ColumnContent>
              {tier1Parameters.map((data, index) => (
                <ColumnContent key={index}>
                  <MainContent key={index}>{data.assignedTier}</MainContent>
                </ColumnContent>
              ))}
              {tier2Parameters.map((data, index) => (
                <ColumnContent key={index}>
                  <MainContent key={index}>{data.assignedTier}</MainContent>
                </ColumnContent>
              ))}
              {tier3Parameters.map((data, index) => (
                <ColumnContent key={index}>
                  <MainContent key={index}>{data.assignedTier}</MainContent>
                </ColumnContent>
              ))}
            </VerticalContainer>
          </HorizontalContainer>
        </AccordionDetails>
      </Accordion>
    </ComponentWrapper>
  )
}

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `0.1rem solid ${theme.palette.common.grayBorder}`,
  width: '100%',
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'none',
  flexDirection: 'row-reverse',
  minHeight: '3rem',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    margin: '0',
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: '1rem',
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default SweepParameters;
import React from "react";
import { useTheme } from "@mui/styles";
import { Detail, DetailContainer, DetailInfoContainer, DetailListContainer } from "../../../StyledComponents";

const AssigneesUpdated = ({milestone}) => {
  const theme = useTheme()

  return (
    <DetailContainer className="fitContent">
      <Detail className="timelineHeader">{milestone.milestone}{milestone.notify ? <span style={{color: `${theme.palette.primary.main}`, margin: '0.25rem 0 0 0.5rem', fontWeight: '400'}}>new</span> : <></>}</Detail>
      <DetailListContainer className='timeline'>
        <DetailInfoContainer className="item timeline">
          <Detail className="timelineDetail">updated by:</Detail>
          <Detail className="timelineDetail">date:</Detail>
          {milestone.details[0].newAssignees.length === 1 ?
            <Detail className="timelineDetail">new assignee:</Detail>
          : milestone.details[0].newAssignees.length > 1 ?
            <Detail className="timelineDetail">new assignees:</Detail>
          :
            <></>
          }
          {milestone.details[0].removedAssignees.length === 1 ?
            <Detail className="timelineDetail">removed assignee:</Detail>
          : milestone.details[0].removedAssignees.length > 1 ?
            <Detail className="timelineDetail">removed assignees:</Detail>
          :
            <></>
          }
        </DetailInfoContainer>
        <DetailInfoContainer className="space">
          <Detail className="timelineItem">{milestone.contributor}</Detail>
          <Detail className="timelineItem">{milestone.date}</Detail>
          <Detail className="timelineItem column">
            {milestone.details[0].newAssignees.map((assignee, index) => (
              <span key={index}>{assignee}</span>
            ))}
          </Detail>
          {milestone.details[0].removedAssignees.map((assignee, index) => (
            <Detail className="timelineItem" key={index}>{assignee}</Detail>
          ))}
        </DetailInfoContainer>
        <DetailContainer>
          <Detail className="timelineItem"></Detail>
        </DetailContainer>
      </DetailListContainer>
    </DetailContainer>
  )
}

export default AssigneesUpdated
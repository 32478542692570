import React, { useEffect, useState } from "react";
import Radio from '@mui/material/Radio';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import CancelIcon from '@mui/icons-material/Cancel';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import { useTheme } from "@mui/styles";
import CircularProgress from "@mui/material/CircularProgress";
import FormControlLabel from '@mui/material/FormControlLabel';
import { useUpdateSweepParamterSettingsMutation } from "../../../slices/api/apiSlice";
import { ActionIconWrapper, ModalContainer, ComponentBorder, FlexWrapper, HeaderText, ButtonWrapper, Button, CardButton } from "../../../StyledComponents";
import { useSelector } from "react-redux";
import { selectViewingSweepParameter } from "../../../slices/viewingSweepParameterSlice";
import { primaryRadioColorProps } from "../../../componentThemes";

const SweepParameterModal = ({ closeModal, userFullName }) => {
  const theme = useTheme()

  const sweepParameter = useSelector(selectViewingSweepParameter)
  
  const [editParameterSettings, {isLoading}] = useUpdateSweepParamterSettingsMutation()

  const [workflowSubmit, setWorkflowSubmit] = useState(false)
  const [parameterLabel, setParameterLabel] = useState(sweepParameter.label)
  const [parameterStatus, setParameterStatus] = useState(null)
  const [parameterTier, setParameterTier] = useState(sweepParameter.assignedTier)


  const parameterStatusControlProps = (item) => ({
    checked: parameterStatus === item,
    onChange: handleParameterStatusChange,
    value: item,
    label: item,
    labelPlacement: "end",
    sx: {
      color: `${theme.palette.common.black}`,
    }
  });
  const handleParameterStatusChange = (event) => {
    setParameterStatus(event.target.value)
  }

  const parameterTierControlProps = (item) => ({
    checked: parameterTier === item,
    onChange: handleParameterTierChange,
    value: item,
    label: item,
    labelPlacement: "end",
    sx: {
      color: `${theme.palette.common.black}`,
    }
  });
  const handleParameterTierChange = (event) => {
    setParameterTier(event.target.value)
  }

  const handleSubmit = async () => {
    let newStatus = false
    if (parameterStatus === 'active') {
      newStatus = true
    }
    await editParameterSettings({
      parameterId: sweepParameter._id,
      userFullName: userFullName,
      status: newStatus,
      newTier: parameterTier,
      originalTier: sweepParameter.assignedTier,
      subCat: sweepParameter.subCategory,
    })
    setWorkflowSubmit(false)
    closeModal()
  }

  useEffect(() => {
    if (sweepParameter) {
      setParameterLabel(sweepParameter.label)
      setParameterTier(sweepParameter.assignedTier)
      if (sweepParameter.active) {
        setParameterStatus('active')
      } else {
        setParameterStatus('inactive')
      }
    }
  }, [sweepParameter])

  return (
    <ModalContainer className="onePanel small">
      <ComponentBorder className="top">
        <ActionIconWrapper className="medium grayText circle hoverBorder hover" style={{margin: 'auto 2rem auto 0'}}>
          <CancelIcon onClick={() => closeModal()} style={{height: '100%', width: '100%'}} />  
        </ActionIconWrapper>
      </ComponentBorder>

      <FlexWrapper className="column justifyBetween alignCenter" style={{height: '100%', width: 'fit-content', margin: '0 auto'}}>
        <FlexWrapper className="justifyCenter" style={{height: '2rem', margin: '1rem 0 0 0'}}>
          <CardButton className={(parameterTier === 'two' ? 'two' : parameterTier === 'one' ? 'one' : 'three') + ' noHover'} style={{width: 'fit-content', padding: '0.3rem 1rem', margin: '0'}}>
            {parameterLabel}
          </CardButton>
          {parameterStatus === 'active' ?
            <span style={{width: '5rem', margin: 'auto 0 auto 2rem', color: `${theme.palette.primary.main}`}}>ACTIVE</span>
          :
            <span style={{width: '5rem', margin: 'auto 0 auto 2rem', color: `${theme.palette.common.grayBorder}`}}>INACTIVE</span>
          }
        </FlexWrapper>
        <FlexWrapper className="column" style={{height: 'fit-content', margin: 'auto 0'}}>
          <FlexWrapper className="column" style={{height: '3rem'}}>
            <FlexWrapper>
              <HeaderText className="center" style={{padding: '0 0.5rem 0 0'}}>
                Status
              </HeaderText>
              <FormControl style={{margin: '0 0 0 3rem'}}>
                <RadioGroup row>
                  <FormControlLabel
                    control={<Radio {...primaryRadioColorProps()} />}
                    {...parameterStatusControlProps('active')} 
                  />
                  <FormControlLabel
                    control={<Radio {...primaryRadioColorProps()} />}
                    {...parameterStatusControlProps('inactive')} 
                  />
                </RadioGroup>
              </FormControl>
            </FlexWrapper>
          </FlexWrapper>
          <FlexWrapper className="column" style={{height: '3rem'}}>
            <FlexWrapper>
              <HeaderText className="center" style={{padding: '0 0.5rem 0 0'}}>
                Tier
              </HeaderText>
              <FormControl style={{margin: '0 0 0 3rem'}}>
                <RadioGroup row>
                  <FormControlLabel
                    control={<Radio {...primaryRadioColorProps()} />}
                    {...parameterTierControlProps('one')} 
                  />
                  <FormControlLabel
                    control={<Radio {...primaryRadioColorProps()} />}
                    {...parameterTierControlProps('two')} 
                  />
                  <FormControlLabel
                    control={<Radio {...primaryRadioColorProps()} />}
                    {...parameterTierControlProps('three')} 
                  />
                </RadioGroup>
              </FormControl>
            </FlexWrapper>
          </FlexWrapper>
        </FlexWrapper>
        {workflowSubmit ?
          <ButtonWrapper style={{margin: '0 auto 1rem auto'}}>
            <Button className="primary confirm" onClick={() => handleSubmit()} style={{margin: '0 0.25rem 0 0'}}>
              {isLoading ?
                <CircularProgress
                  sx={{color: `${theme.palette.common.white}`}}
                  size={12}
                />
              :
                <CheckIcon />
              }
            </Button>
            <Button className="secondary confirm" onClick={() => setWorkflowSubmit(false)} style={{margin: '0 0 0 0.25rem'}}>
              <ClearIcon />
            </Button>
          </ButtonWrapper>
        :
          <ButtonWrapper style={{margin: '0 auto 1rem auto'}}>
            <Button className="primary" onClick={() => setWorkflowSubmit(true)} style={{width: '8rem', margin: '0'}}>Submit</Button>
          </ButtonWrapper>
        }
      </FlexWrapper>

      <ComponentBorder className="left" style={{gridRow:'2/4'}}></ComponentBorder>
      <ComponentBorder className="right"  style={{gridRow:'2/4', gridColumn: '3'}}></ComponentBorder>
      <ComponentBorder className="bottom" style={{gridRow:'3'}}></ComponentBorder>
    </ModalContainer>
  )
}

export default SweepParameterModal;
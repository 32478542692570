import React, { useState, forwardRef, useRef, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import 'react-csv-importer/dist/index.css';
import "../../../../../styles.css";
import moment from "moment";
import ErrorIcon from "@mui/icons-material/Error";
import CheckIcon from '@mui/icons-material/Check';
import DatePicker from 'react-datepicker';
import { useTheme } from "@mui/styles";
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import {addDays, subMonths} from 'date-fns';
import { selectViewingLeadTimeline } from "../../../../../slices/viewingLeadSlice";
import { useSetLeadStatusFinalizedMutation, useChangeTargetOutcomeMutation, useDismissLeadMutation, useReloadRefinanceQuarterlyBreakdownMutation, useReloadRenegotiationQuarterlyBreakdownMutation } from "../../../../../slices/api/apiSlice";
import { ComponentHeader, ComponentSectionRow, Button, ButtonWrapper, ErrorWrapper, InlineLink, Label, InputWrapper, ModalContentContainer, SubHeader, RowsContainer, StatusAndLabelWrapper, StyledInputElement, FlexWrapper } from "../../../../../StyledComponents";

const FinalizeLead = ({ handleCloseLeadActionsModal, leadData, teamId, userId, userFullName, dashPerspective, leadUserNotifications, mortgageData }) => {
  const theme = useTheme()

  const [noFinalizingUpdates, setNoFinalizingUpdates] = useState(false)
  const timeline = useSelector(selectViewingLeadTimeline)

  const [changeOutcome] = useChangeTargetOutcomeMutation()

  const [setLeadFinalized, {isFetching, isSuccess, isError}] = useSetLeadStatusFinalizedMutation()
  const [realoadRenegotiationQuarterBreakdown] = useReloadRenegotiationQuarterlyBreakdownMutation()
  const [realoadRefinanceQuarterBreakdown] = useReloadRefinanceQuarterlyBreakdownMutation()
  const [dismissLead] = useDismissLeadMutation()

  const [startDate, setStartDate] = useState(new Date(mortgageData.originationDate.slice(5,5)+'-'+mortgageData.originationDate.slice(0,4)))
  const [closeDate, setCloseDate] = useState(new Date())
  const ExampleCustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="styled-calendar-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    />
  ))
  const [mortgageTermValue, setMortgageTermValue] = useState(0)
  const [mortgageTermValueStr, setMortgageTermValueStr] = useState(0)
  const [statusTerm, setStatusTerm] = useState(null)
  const updatedTerm = useRef()
  const [principalValue, setPrincipalValue] = useState(0)
  const [principalValueStr, setPrincipalValueStr] = useState(0)
  const [statusPrincipal, setStatusPrincipal] = useState(null)
  const updatedPrincipal = useRef()
  const [interestValue, setInterestValue] = useState(0)
  const [interestValueStr, setInterestValueStr] = useState(0)
  const [statusInterest, setStatusInterest] = useState(null)
  const updatedInterest = useRef()

  const handleDismissLead = async () => {
    dismissLead({
      leadId: leadData._id,
      userId: userId,
      dashPerspective: dashPerspective,
      leadFilter: 'closing',
      teamId: teamId,
      mortgageId: leadData.belongsToMortgage,
      userFullName: userFullName,
    })
  }

  const handleChangeTargetOutcome = async () => {
    let newOutcome = ''
    if (leadData.targetOutcome === 'renegotiation') {
      newOutcome = 'refinance'
    } else {
      newOutcome = 'renegotiation'
    }
    changeOutcome({
      userId: userId,
      dashPerspective: dashPerspective,
      timeline: timeline,
      userFullName: userFullName,
      newOutcome: newOutcome,
      leadId: leadData._id,
      userNotifications: leadUserNotifications,
    })
    setNoFinalizingUpdates(false)
  }

  const handleSubmit = async () => {
    let goExecutePost = true
    let updatedFields = []
    if (moment(startDate).format('MMM Do, YYYY') !== moment(mortgageData.originationDate).format('MMM Do, YYYY')) {
      updatedFields.push({field: 'Origination Date', old: moment(mortgageData.originationDate).format('MMM Do, YYYY'), new: moment(startDate).format('MMM Do, YYYY')})
    }
    if (mortgageTermValue !== mortgageData.mortgageTerm) {
      updatedFields.push({field: 'Mortgage Term', old: mortgageData.mortgageTerm, new: parseFloat(mortgageTermValue)})
    }
    if (principalValue !== mortgageData.originalLoanAmount) {
      updatedFields.push({field: 'Original Principal', old: mortgageData.originalLoanAmount, new: parseFloat(principalValue)})
    }
    if (interestValue !== mortgageData.originalInterestRate) {
      updatedFields.push({field: 'Interest Rate', old: mortgageData.originalInterestRate, new: parseFloat(interestValue)})
    }
    if (updatedFields.length === 0) {
      if (leadData.updates.length === 0) {
        setNoFinalizingUpdates(true)
        goExecutePost = false
      }
    }
    if (goExecutePost) {
      await setLeadFinalized({
        userId: userId,
        leadId: leadData._id,
        dashPerspective: dashPerspective,
        teamId: teamId,
        userNotifications: leadUserNotifications,
        closeDate: closeDate,
        updatedTerm: mortgageTermValue,
        updatedPrincipal: principalValue,
        updatedInterestRate: interestValue,
        updatedStartDate: startDate,
        updatedFields: updatedFields,
        userFullName: userFullName,
      })
      if (leadData.targetOutcome === 'renegotiation') {
        await realoadRenegotiationQuarterBreakdown({
          userId: userId,
          teamId: teamId,
          leadTier: leadData.tier,
          actionTaken: 'closed',
          closureType: leadData.targetOutcome,
        })
      } else {
        await realoadRefinanceQuarterBreakdown({
          userId: userId,
          teamId: teamId,
          leadTier: leadData.tier,
          actionTaken: 'closed',
          closureType: leadData.targetOutcome,
        })
      }
    }
  }

  useEffect(() => {
    if (leadData.targetOutcome === 'renegotiation') {
      if (isNaN((mortgageData.originalLoanAmount))) {
        setStatusPrincipal("nonNumber")
      } else if (Math.sign(parseFloat(mortgageData.originalLoanAmount)) === -1) {
        setStatusPrincipal("negative")
      } else if (Math.sign(parseFloat(mortgageData.originalLoanAmount)) === 0) {
        setStatusPrincipal("zero")
      } else if (parseFloat(mortgageData.originalLoanAmount) > 100000000) {
        setStatusPrincipal("large")
      }
      setPrincipalValue(parseFloat(mortgageData.originalLoanAmount))
      setPrincipalValueStr(mortgageData.originalLoanAmount.toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2}))
      setInterestValue(mortgageData.originalInterestRate)
      setInterestValueStr(mortgageData.originalInterestRate)
      setMortgageTermValue(mortgageData.mortgageTerm)
      setMortgageTermValueStr(mortgageData.mortgageTerm)
    } else {
      if (isNaN((leadData.targetLoanAmount))) {
        setStatusPrincipal("nonNumber")
      } else if (Math.sign(parseFloat(leadData.targetLoanAmount)) === -1) {
        setStatusPrincipal("negative")
      } else if (Math.sign(parseFloat(leadData.targetLoanAmount)) === 0) {
        setStatusPrincipal("zero")
      } else if (parseFloat(leadData.targetLoanAmount) > 100000000) {
        setStatusPrincipal("large")
      }
      if (isNaN((leadData.targetLoanTerm))) {
        setStatusTerm("nonNumber")
      } else if (Math.sign(parseFloat(leadData.targetLoanTerm)) === -1) {
        setStatusTerm("negative")
      } else if (Math.sign(parseFloat(leadData.targetLoanTerm)) === 0) {
        setStatusTerm("zero")
      } else if (parseFloat(leadData.targetLoanTerm) > 100000000) {
        setStatusTerm("large")
      }
      if (isNaN((leadData.targetInterestRate))) {
        setStatusInterest("nonNumber")
      } else if (Math.sign(parseFloat(leadData.targetInterestRate)) === -1) {
        setStatusInterest("negative")
      } else if (Math.sign(parseFloat(leadData.targetInterestRate)) === 0) {
        setStatusInterest("zero")
      } else if (parseFloat(leadData.targetInterestRate) > 100000000) {
        setStatusInterest("large")
      }
      if (leadData.targetLoanAmount && leadData.targetLoanAmount > 0) {
        setPrincipalValue(parseFloat(leadData.targetLoanAmount))
        setPrincipalValueStr(leadData.targetLoanAmount.toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2}))
      }
      if (leadData.targetInterestRate && leadData.targetInterestRate > 0) {
        setInterestValue(leadData.targetInterestRate)
        setInterestValueStr(leadData.targetInterestRate)
      }
      if (leadData.targetLoanTerm && leadData.targetLoanTerm > 0) {
        setMortgageTermValue(leadData.targetLoanTerm)
        setMortgageTermValueStr(leadData.targetLoanTerm)
      }
    }
  }, [leadData])

  return (
    <>
      {isSuccess ?
        <ComponentHeader className="modal green"><CheckIcon style={{height: '2.5rem', width: '2.5rem', margin: '0 0.5rem 0.2rem 0'}} />Success</ComponentHeader>
      : isError ?
        <ComponentHeader className="modal red">
          <FlexWrapper style={{border: `0.2rem solid ${theme.palette.common.redDark}`, padding: '0.5rem', width: '2.7rem', background: `${theme.palette.common.redBright}`}}>
            <ErrorIcon style={{height: '2.5rem', width: '2.5rem', color: `${theme.palette.common.white}`}} />
          </FlexWrapper>
          <span style={{padding: '0 0.5rem', fontSize: '1.4rem'}}>
            Failed... try again or contact support@lancastersweep.com
          </span>
        </ComponentHeader>
      :
        leadData.targetOutcome === 'renegotiation' ?
          <ComponentHeader className="modal">Finalize Renegotiation</ComponentHeader>
        :
          <ComponentHeader className="modal">Finalize Refinance</ComponentHeader>
      }

      <ModalContentContainer className="centerColumn leadActions">
        {isSuccess ?
          <></>
        :
          <>
            {leadData.targetOutcome === 'renegotiation' && !mortgageData.originalLoanAmount ?
              principalValueStr && principalValueStr.length > 0 && !statusPrincipal ?
                <div style={{height: '2.7rem'}}></div>
              : 
                <FlexWrapper className="alignCenter" style={{height: 'fit-content', paddingRight: '2rem', margin: '0.25rem 3rem'}}>
                  <FlexWrapper style={{border: `0.2rem solid ${theme.palette.common.redDark}`, padding: '0.5rem', width: '2.7rem', borderRadius: '1rem 0 0 1rem', background: `${theme.palette.common.redBright}`}}>
                    <ErrorIcon style={{height: '2.5rem', width: '2.5rem', color: `${theme.palette.common.white}`}} />
                  </FlexWrapper>
                  <span style={{display: 'flex', alignItems: 'center', height: '3.8rem', border: `0.1rem solid ${theme.palette.common.redDark}`, padding: '0.3rem 1rem 0.3rem 1rem', borderRadius: '0 1rem 1rem 0', background: `${theme.palette.common.redTransparent}`, textAlign: 'center', borderLeft: 'none', color: `${theme.palette.common.redDark}`}}>
                    Original Loan Amount is missing. Please provide this value before closing the lead.
                  </span>
                </FlexWrapper>
            :
              <></>
            }
            {leadData.targetOutcome === 'refinance' && !leadData.targetLoanAmount ?
              principalValueStr && principalValueStr.length > 0 && !statusPrincipal ?
                <div style={{height: '2.7rem'}}></div>
              :  
                <FlexWrapper className="alignCenter" style={{height: 'fit-content', paddingRight: '2rem', margin: '0.25rem 3rem'}}>
                  <FlexWrapper style={{border: `0.2rem solid ${theme.palette.common.redDark}`, padding: '0.5rem', width: '2.7rem', borderRadius: '1rem 0 0 1rem', background: `${theme.palette.common.redBright}`}}>
                    <ErrorIcon style={{height: '2.5rem', width: '2.5rem', color: `${theme.palette.common.white}`}} />
                  </FlexWrapper>
                  <span style={{display: 'flex', alignItems: 'center', height: '3.8rem', border: `0.1rem solid ${theme.palette.common.redDark}`, padding: '0.3rem 1rem 0.3rem 1rem', borderRadius: '0 1rem 1rem 0', background: `${theme.palette.common.redTransparent}`, textAlign: 'center', borderLeft: 'none', color: `${theme.palette.common.redDark}`}}>
                    New Loan Amount is missing. Please provide this value before closing the lead.
                  </span>
                </FlexWrapper>
            :
              <></>
            }
            {leadData.targetOutcome === 'refinance' && !leadData.targetLoanTerm ?
              mortgageTermValueStr && mortgageTermValueStr.length > 0 && !statusTerm ?
                <div style={{height: '2.7rem'}}></div>
              :
                <FlexWrapper className="alignCenter" style={{height: 'fit-content', paddingRight: '2rem', margin: '0.25rem 3rem'}}>
                  <FlexWrapper style={{border: `0.2rem solid ${theme.palette.common.redDark}`, padding: '0.5rem', width: '2.7rem', borderRadius: '1rem 0 0 1rem', background: `${theme.palette.common.redBright}`}}>
                    <ErrorIcon style={{height: '2.5rem', width: '2.5rem', color: `${theme.palette.common.white}`}} />
                  </FlexWrapper>
                  <span style={{display: 'flex', alignItems: 'center', height: '3.8rem', border: `0.1rem solid ${theme.palette.common.redDark}`, padding: '0.3rem 1rem 0.3rem 1rem', borderRadius: '0 1rem 1rem 0', background: `${theme.palette.common.redTransparent}`, textAlign: 'center', borderLeft: 'none', color: `${theme.palette.common.redDark}`}}>
                    New Loan Term is missing. Please provide this value before closing the lead.
                  </span>
                </FlexWrapper>
            :
              <></>
            }
            {leadData.targetOutcome === 'refinance' && !leadData.targetInterestRate ?
              interestValueStr && interestValueStr.length > 0 && !statusInterest ?
                <div style={{height: '2.7rem'}}></div>
              : 
                <FlexWrapper className="alignCenter" style={{height: 'fit-content', paddingRight: '2rem', margin: '0.25rem 3rem'}}>
                  <FlexWrapper style={{border: `0.2rem solid ${theme.palette.common.redDark}`, padding: '0.5rem', width: '2.7rem', borderRadius: '1rem 0 0 1rem', background: `${theme.palette.common.redBright}`}}>
                    <ErrorIcon style={{height: '2.5rem', width: '2.5rem', color: `${theme.palette.common.white}`}} />
                  </FlexWrapper>
                  <span style={{display: 'flex', alignItems: 'center', height: '3.8rem', border: `0.1rem solid ${theme.palette.common.redDark}`, padding: '0.3rem 1rem 0.3rem 1rem', borderRadius: '0 1rem 1rem 0', background: `${theme.palette.common.redTransparent}`, textAlign: 'center', borderLeft: 'none', color: `${theme.palette.common.redDark}`}}>
                    New Refinance Interest Rate is missing. Please provide this value before closing the lead.
                  </span>
                </FlexWrapper>
            :
              <></>
            }
            {leadData.activeDiscrepancies ?
              <FlexWrapper className="alignCenter" style={{height: 'fit-content', paddingRight: '2rem', margin: '0.25rem 3rem', width: 'fit-content'}}>
                <FlexWrapper style={{border: `0.2rem solid ${theme.palette.common.orangeDark}`, padding: '0.5rem', width: '2.7rem', borderRadius: '1rem 0 0 1rem', background: `${theme.palette.common.orange}`}}>
                  <ReportProblemIcon style={{height: '2.5rem', width: '2.5rem', color: `${theme.palette.common.white}`}} />
                </FlexWrapper>
                <div style={{border: `0.1rem solid ${theme.palette.common.orangeDark}`, padding: '0.3rem 5rem 0.3rem 1rem', borderRadius: '0 1rem 1rem 0', background: `${theme.palette.common.orangeTransparent}`, borderLeft: 'none', color: `${theme.palette.common.orangeDark}`}}>
                  The lead currently has active discrepencies.<br/>
                  <FlexWrapper style={{margin: '0.6rem 0'}}>
                    <div>•</div>
                    <div style={{marginLeft: '1rem'}}>If the current public records information is excepted, resolve all discrepancies before closing so the mortgage can be placed in the "inactive" pool upon closing.</div>
                  </FlexWrapper>
                  <FlexWrapper style={{margin: '0.6rem 0'}}>
                    <div>•</div>
                    <div style={{marginLeft: '1rem'}}>Otherwise, close the lead as-is to place the mortgage in the "awaiting updates" pool where your team will be notified once changes are detected in the piblic records.</div>
                  </FlexWrapper>
                </div>
              </FlexWrapper>
            : 
              <div style={{height: '2.7rem'}}></div>
            }
            <RowsContainer className="noBorder" style={{width: '76rem', padding: '0 0 1rem 0'}}>
              <ComponentHeader className="section">Mortgage Details</ComponentHeader>
              <ComponentSectionRow className="bottom">
                {leadData.targetOutcome === 'renegotiation' ?
                  <>
                    <InputWrapper style={{width: '10rem', alignItems: 'center'}}>
                      <StatusAndLabelWrapper className='column'>
                        <Label htmlFor="root-portal">Mortgage Start Date</Label>
                      </StatusAndLabelWrapper>
                      <span style={{cursor: 'default', fontWeight: '600'}}>
                        {mortgageData.originationDateLabel}
                      </span>
                    </InputWrapper>

                    <InputWrapper style={{width: '9rem'}}>
                      <StatusAndLabelWrapper className='column'>
                        <Label>Mortgage Term</Label>
                      </StatusAndLabelWrapper>
                      <span style={{cursor: 'default', fontWeight: '600'}}>
                        {mortgageData.mortgageTerm}
                        <span style={{marginLeft: '0.5rem', fontWeight: '400'}}>(years)</span>
                      </span>
                    </InputWrapper>

                    {mortgageData.originalLoanAmount ?
                      <InputWrapper style={{width: '9rem'}}>
                        <StatusAndLabelWrapper className='column'>
                          <Label>Loan Amount</Label>
                        </StatusAndLabelWrapper>
                        <span style={{cursor: 'default', fontWeight: '600'}}>
                          <span style={{color: `${theme.palette.primary.main}`, fontWeight: '400', marginRight: '0.3rem'}}>$</span>
                          {parseFloat(mortgageData.originalLoanAmount).toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2})}
                        </span>
                      </InputWrapper>
                    :
                      <InputWrapper style={{width: '10rem'}}>
                        <StatusAndLabelWrapper className='column' style={{justifyContent: 'flex-end'}}>
                          {statusPrincipal === "nonNumber" && (
                            <ErrorWrapper className='column'>
                              <ErrorIcon style={{width: '1.2rem', height: '1.2rem'}} />
                              Must be a number
                            </ErrorWrapper>
                          )}
                          {statusPrincipal === "negative" && (
                            <ErrorWrapper className='column'>
                              <ErrorIcon style={{width: '1.2rem', height: '1.2rem'}} />
                              Can't be negative
                            </ErrorWrapper>
                          )}
                          {statusPrincipal === "zero" && (
                            <ErrorWrapper className='column'>
                              <ErrorIcon style={{width: '1.2rem', height: '1.2rem'}} />
                              Can't be zero
                            </ErrorWrapper>
                          )}
                          {statusPrincipal === "large" && (
                            <ErrorWrapper className='column'>
                              <ErrorIcon style={{width: '1.2rem', height: '1.2rem'}} />
                              Larger than 100m
                            </ErrorWrapper>
                          )}
                          <Label>Loan Amount</Label>
                        </StatusAndLabelWrapper>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                          <span style={{color: `${theme.palette.primary.main}`, fontWeight: '600'}}>$</span>
                          <StyledInputElement className="borderBottomOnly" style={{width: '7.5rem'}}
                            value={principalValueStr ? principalValueStr : ""}
                            type="text"
                            name="principal"
                            id="principal"
                            ref={updatedPrincipal}
                            onChange={() => {
                              if (isNaN((updatedPrincipal.current.value))) {
                                setStatusPrincipal("nonNumber")
                              } else if (Math.sign(parseFloat(updatedPrincipal.current.value)) === -1) {
                                setStatusPrincipal("negative")
                              } else if (Math.sign(parseFloat(updatedPrincipal.current.value)) === 0) {
                                setStatusPrincipal("zero")
                              } else if (parseFloat(updatedPrincipal.current.value) > 100000000) {
                                setStatusPrincipal("large")
                              } else if (statusPrincipal === "nonNumber" || statusPrincipal === "negative" || statusPrincipal === "zero" || statusPrincipal === "large") {
                                setStatusPrincipal(null)
                              }
                              setPrincipalValueStr(updatedPrincipal.current.value.toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2}))
                              setPrincipalValue(parseFloat(updatedPrincipal.current.value.replace(/[^0-9-.]/g, '')))
                            }}
                          />
                        </div>
                      </InputWrapper>
                    }

                    <InputWrapper style={{width: '8rem'}}>
                      <StatusAndLabelWrapper className='column'>
                        <Label>Interest Rate</Label>
                      </StatusAndLabelWrapper>
                      <span style={{cursor: 'default', fontWeight: '600'}}>
                        {parseFloat(mortgageData.originalInterestRate).toFixed(2)}
                        <span style={{fontWeight: '400'}}>%</span>
                      </span>
                    </InputWrapper>
                  </>
                :
                  <>
                    <InputWrapper style={{width: '10rem', alignItems: 'center'}}>
                      <StatusAndLabelWrapper className='column'>
                        <Label htmlFor="root-portal">Mortgage Start Date</Label>
                      </StatusAndLabelWrapper>
                      <div>
                        <DatePicker 
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          maxDate={addDays(new Date(), 0)}
                          minDate={subMonths(new Date(), 360)}
                          showMonthDropdown
                          showYearDropdown
                          scrollableYearDropdown
                          yearDropdownItemNumber={10}
                          withPortal
                          portalId="root-portal"
                          customInput={<ExampleCustomInput />}
                          dayClassName={() => "example-datepicker-day-class"}
                          calendarClassName="example-datepicker-class"
                          todayButton="CLICK FOR TODAY'S DATE"
                        />
                      </div>
                    </InputWrapper>

                    <InputWrapper style={{width: '10rem'}}>
                      <StatusAndLabelWrapper className='column'>
                        {statusTerm === "nonNumber" && (
                          <ErrorWrapper className='column'>
                            <ErrorIcon style={{width: '1.2rem', height: '1.2rem'}} />
                            Must be a number
                          </ErrorWrapper>
                        )}
                        {statusTerm === "negative" && (
                          <ErrorWrapper className='column'>
                            <ErrorIcon style={{width: '1.2rem', height: '1.2rem'}} />
                            Can't be negative
                          </ErrorWrapper>
                        )}
                        {statusTerm === "zero" && (
                          <ErrorWrapper className='column'>
                            <ErrorIcon style={{width: '1.2rem', height: '1.2rem'}} />
                            Can't be zero
                          </ErrorWrapper>
                        )}
                        {statusTerm === "large" && (
                          <ErrorWrapper className='column'>
                            <ErrorIcon style={{width: '1.2rem', height: '1.2rem'}} />
                            More than 100
                          </ErrorWrapper>
                        )}
                        <Label>Mortgage Term</Label>
                      </StatusAndLabelWrapper>
                      <div style={{display: 'flex'}}>
                        <StyledInputElement className="borderBottomOnly" style={{width: '3rem'}}
                          value={mortgageTermValueStr}
                          type="text"
                          name="term"
                          id="term"
                          ref={updatedTerm}
                          onChange={() => {
                            if (isNaN(parseFloat(updatedTerm.current.value))) {
                              setStatusTerm("nonNumber")
                            } else if (Math.sign(parseFloat(updatedTerm.current.value)) === -1) {
                              setStatusTerm("negative")
                            } else if (Math.sign(parseFloat(updatedTerm.current.value)) === 0) {
                              setStatusTerm("zero")
                            } else if (parseFloat(updatedTerm.current.value) > 100) {
                              setStatusTerm("large")
                            } else if (statusTerm === "nonNumber" || statusTerm === "negative" || statusTerm === "zero" || statusTerm === "large") {
                              setStatusTerm(null)
                            }
                            setMortgageTermValueStr(updatedTerm.current.value.toString())
                            setMortgageTermValue(updatedTerm.current.value)
                          }}
                        />
                        <span style={{margin: 'auto 0 auto 0.5rem'}}>(years)</span>
                      </div>
                    </InputWrapper>

                    <InputWrapper style={{width: '10rem'}}>
                      <StatusAndLabelWrapper className='column'>
                        {statusPrincipal === "nonNumber" && (
                          <ErrorWrapper className='column'>
                            <ErrorIcon style={{width: '1.2rem', height: '1.2rem'}} />
                            Must be a number
                          </ErrorWrapper>
                        )}
                        {statusPrincipal === "negative" && (
                          <ErrorWrapper className='column'>
                            <ErrorIcon style={{width: '1.2rem', height: '1.2rem'}} />
                            Can't be negative
                          </ErrorWrapper>
                        )}
                        {statusPrincipal === "zero" && (
                          <ErrorWrapper className='column'>
                            <ErrorIcon style={{width: '1.2rem', height: '1.2rem'}} />
                            Can't be zero
                          </ErrorWrapper>
                        )}
                        {statusPrincipal === "large" && (
                          <ErrorWrapper className='column'>
                            <ErrorIcon style={{width: '1.2rem', height: '1.2rem'}} />
                            Larger than 100m
                          </ErrorWrapper>
                        )}
                        <Label>Loan Amount</Label>
                      </StatusAndLabelWrapper>
                      <div style={{display: 'flex'}}>
                        <span style={{color: `${theme.palette.primary.main}`, fontWeight: '600', margin: 'auto 0 auto 0'}}>$</span>
                        <StyledInputElement className="borderBottomOnly" style={{width: '7.5rem'}}
                          value={principalValueStr}
                          type="text"
                          name="principal"
                          id="principal"
                          ref={updatedPrincipal}
                          onChange={() => {
                            if (isNaN(parseFloat(updatedPrincipal.current.value.replace(/[^0-9-.]/g, '')))) {
                              setStatusPrincipal("nonNumber")
                            } else if (Math.sign(parseFloat(updatedPrincipal.current.value.replace(/[^0-9-.]/g, ''))) === -1) {
                              setStatusPrincipal("negative")
                            } else if (Math.sign(parseFloat(updatedPrincipal.current.value.replace(/[^0-9-.]/g, ''))) === 0) {
                              setStatusPrincipal("zero")
                            } else if (parseFloat(updatedPrincipal.current.value.replace(/[^0-9-.]/g, '')) > 100000000) {
                              setStatusPrincipal("large")
                            } else if (statusPrincipal === "nonNumber" || statusPrincipal === "negative" || statusPrincipal === "zero" || statusPrincipal === "large") {
                              setStatusPrincipal(null)
                            }
                            setPrincipalValueStr(updatedPrincipal.current.value.toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2}))
                            setPrincipalValue(parseFloat(updatedPrincipal.current.value.replace(/[^0-9-.]/g, '')))
                          }}
                        />
                      </div>
                    </InputWrapper>

                    <InputWrapper style={{width: '8rem'}}>
                      <StatusAndLabelWrapper className='column'>
                        {statusInterest === "nonNumber" && (
                          <ErrorWrapper className='column'>
                            <ErrorIcon style={{width: '1.2rem', height: '1.2rem'}} />
                            Must be a number
                          </ErrorWrapper>
                        )}
                        {statusInterest === "negative" && (
                          <ErrorWrapper className='column'>
                            <ErrorIcon style={{width: '1.2rem', height: '1.2rem'}} />
                            Can't be negative
                          </ErrorWrapper>
                        )}
                        {statusInterest === "zero" && (
                          <ErrorWrapper className='column'>
                            <ErrorIcon style={{width: '1.2rem', height: '1.2rem'}} />
                            Can't be zero
                          </ErrorWrapper>
                        )}
                        {statusInterest === "large" && (
                          <ErrorWrapper className='column'>
                            <ErrorIcon style={{width: '1.2rem', height: '1.2rem'}} />
                            More than 100
                          </ErrorWrapper>
                        )}
                        <Label>Interest Rate</Label>
                      </StatusAndLabelWrapper>
                      <div style={{display: 'flex'}}>
                        <StyledInputElement className="borderBottomOnly" style={{width: '4rem'}}
                          value={interestValueStr}
                          type="text"
                          name="interest"
                          id="interest"
                          ref={updatedInterest}
                          onChange={() => {
                            if (isNaN(parseFloat(updatedInterest.current.value))) {
                              setStatusInterest("nonNumber")
                            } else if (Math.sign(parseFloat(updatedInterest.current.value)) === -1) {
                              setStatusInterest("negative")
                            } else if (Math.sign(parseFloat(updatedInterest.current.value)) === 0) {
                              setStatusInterest("zero")
                            } else if (parseFloat(updatedInterest.current.value) > 100) {
                              setStatusInterest("large")
                            } else if (statusInterest === "nonNumber" || statusInterest === "negative" || statusInterest === "zero" || statusInterest === "large") {
                              setStatusInterest(null)
                            }
                            setInterestValueStr(updatedInterest.current.value)
                            setInterestValue(parseFloat(updatedInterest.current.value))
                          }}
                        />
                        <span style={{margin: 'auto 0 auto 0.5rem'}}>%</span>
                      </div>
                    </InputWrapper>
                  </>
                }
              </ComponentSectionRow>
              {leadData.targetOutcome === 'renegotiation' ?
                <div style={{width: '70%', minWidth: '65rem', display: 'flex', justifyContent: 'center', borderTop: `0.1rem solid ${theme.palette.common.grayBorder}`, alignSelf: 'center', margin: '1rem 0 0 0'}}><span style={{color: `${theme.palette.common.red}`}}>*</span>If the original loan terms have been renegotiated, <InlineLink onClick={() => handleChangeTargetOutcome()}>click here</InlineLink> to change the target outcome to a "Refinance" before closing</div>
              :
                <div style={{width: '70%', minWidth: '67rem', display: 'flex', justifyContent: 'center', padding: '0 0 0 2rem', borderTop: `0.1rem solid ${theme.palette.common.grayBorder}`, alignSelf: 'center', margin: '1rem 0 0 0'}}><span style={{color: `${theme.palette.common.red}`}}>*</span>If the original loan terms have not been renegotiated, <InlineLink onClick={() => handleChangeTargetOutcome()}>click here</InlineLink> to change the target outcome to a "Renegotiation" before closing</div>
              }
            </RowsContainer>
          </>
        }
        {isSuccess ?
          leadData.targetOutcome === 'renegotiation' ?
            <SubHeader className="modal" style={{margin: '0 auto 1rem auto'}}>
              <span style={{margin: 'auto'}}>The renegotiated mortgage has been saved.</span>
              {leadData.activeDiscrepancies > 0 ?
                <span style={{height: '1.5rem'}}>The lead will remain in the "await update" status until the expected arrangements are reflected in the public records.</span>
              :
                <span style={{height: '1.5rem'}}></span>
              }
              <span style={{height: '1.5rem'}}></span>
            </SubHeader>
          :
          <SubHeader className="modal" style={{margin: '0 auto 1rem auto'}}>
              <span style={{margin: 'auto'}}>The refinanced mortgage has been saved.</span>
              {leadData.activeDiscrepancies > 0 ?
                <span style={{height: '1.5rem'}}>The lead will remain in the "await update" status until the expected arrangements are reflected in the public records.</span>
              :
                <span style={{height: '1.5rem'}}></span>
              }
              <span style={{height: '1.5rem'}}></span>
            </SubHeader>
        : noFinalizingUpdates ?
          <>
            <SubHeader className="modal" style={{margin: '4rem auto 1.5rem auto'}}>
              <span>No updates have been made to the original mortgage.</span>
              <span>Finalizing without any updates will dismiss the lead without recording a new closure.</span>
              <span>Would you like to continue?</span>
            </SubHeader>
          </>
        :
          <>
            <SubHeader className="modal" style={{margin: '0 auto 1rem auto'}}>
              <span>Finalizing the lead will generate a report detailing the investigation.</span>
              <span>Make any necessary changes before continuing.</span>
              <span style={{height: '1.5rem'}}></span>
            </SubHeader>
          </>
        }

        {noFinalizingUpdates || isSuccess ?
          <div style={{margin: '0 auto 3rem auto'}}></div>
        :
          <div style={{margin: '0 auto 3rem auto'}}>
            <InputWrapper style={{width: 'fit-content', alignItems: 'center', height: '4.5rem'}}>
              <StatusAndLabelWrapper className='column'>
                {leadData.targetOutcome === 'renegotiation' ?
                  <Label htmlFor="root-portal">Renegotiation Close Date</Label>
                :
                  <Label htmlFor="root-portal">Refinance Close Date</Label>
                }
              </StatusAndLabelWrapper>
              <div>
                <DatePicker 
                  selected={closeDate}
                  onChange={(date) => setCloseDate(date)}
                  maxDate={addDays(new Date(), 0)}
                  minDate={subMonths(new Date(), 360)}
                  showMonthDropdown
                  showYearDropdown
                  scrollableYearDropdown
                  yearDropdownItemNumber={10}
                  withPortal
                  portalId="root-portal"
                  customInput={<ExampleCustomInput />}
                  dayClassName={() => "example-datepicker-day-class"}
                  calendarClassName="example-datepicker-class"
                  todayButton="CLICK FOR TODAY'S DATE"
                  style={{width: '9rem'}}
                />
              </div>
            </InputWrapper>
          </div>
        }

        <ButtonWrapper>
          {isFetching ?
            <>
              <Button className="primaryLoading">
                <CircularProgress sx={{ color: `${theme.palette.common.cardBackground}` }} size={13} />
              </Button>
              <Button onClick={() => handleCloseLeadActionsModal()} className="gray">Close</Button>
            </>
          : noFinalizingUpdates ?
            <>
              <Button onClick={() => handleDismissLead()} className="orange">Dismiss</Button>        
              <Button onClick={() => setNoFinalizingUpdates(false)} className="gray">Go Back</Button>
            </>
          : isSuccess ?
            <Button onClick={() => handleCloseLeadActionsModal()} className="gray">Close</Button>
          : !mortgageData.originalLoanAmount ?
            principalValueStr && principalValueStr.length > 0 && !statusPrincipal ?
              <>
                <Button onClick={() => handleSubmit()} className="primary">Confirm</Button>
                <Button onClick={() => handleCloseLeadActionsModal()} className="gray">Close</Button>
              </>
            :
              <>
                <Button className="primaryDisabled">Confirm</Button>
                <Button onClick={() => handleCloseLeadActionsModal()} className="gray">Close</Button>
              </>
          : statusPrincipal || statusInterest || statusTerm ?
              <>
                <Button className="primaryDisabled">Confirm</Button>
                <Button onClick={() => handleCloseLeadActionsModal()} className="gray">Close</Button>
              </>
          :
            <>
              <Button onClick={() => handleSubmit()} className="primary">Confirm</Button>
              <Button onClick={() => handleCloseLeadActionsModal()} className="gray">Close</Button>
            </>
          }         
        </ButtonWrapper>
      </ModalContentContainer>
    </>
  )
}

export default FinalizeLead;